import React, { useState } from "react"
import GoogleMapReact from "google-map-react"
import { get } from "lodash"

// Helpers
import { HospitalPreferenceContext } from "./hospitalPreference.context"
import CONFIG from "../../../../../../../../../config"

// Components
import { MapWrapper, GoogleMapsWrapper, MapSkeleton } from "./hospitalPreference.styles"
import HospitalPreferenceMapMarker from "./hospitalPreference.map.marker"
import ZoomButtons from "./hospitalPreference.map.zoom"

const DEFAULT_ZOOM_LEVEL = 10
const zoomToDistanceObj = {
  10: 30,
  9: 60,
  8: 90,
  7: 200,
  6: 450
}

const HospitalPreferenceMap = () => {
  const {
    centrePoint,
    clientGeoAddress,
    hospitalsWithDistance,
    handleApiLoaded,
    selectedHospital,
    isDataLoading
  } = React.useContext(HospitalPreferenceContext)

  const [zoomLevel, setZoomLevel] = useState(10)

  const getType = hospital => {
    if (hospital.hospital_provider_name === null) {
      return "disabled"
    }
    if (
      hospitalsWithDistance.indexOf(hospital) === 0 &&
      (!selectedHospital || (selectedHospital && selectedHospital.id !== hospital.id))
    ) {
      return "closest"
    }
    if (
      hospitalsWithDistance.indexOf(hospital) === 0 &&
      selectedHospital &&
      selectedHospital.id === hospital.id
    ) {
      return "selected"
    }
    if (selectedHospital && selectedHospital.id === hospital.id) {
      return "selected"
    }
    return "available"
  }

  const canView = hospital => {
    const minDistance =
      DEFAULT_ZOOM_LEVEL >= zoomLevel ? get(zoomToDistanceObj, zoomLevel, zoomLevel * 100) : 30
    return (
      hospital.distance <= minDistance || (selectedHospital && selectedHospital.id === hospital.id)
    )
  }

  const isDisableZoom = hospitalsWithDistance.every(hospital => canView(hospital))

  const renderMarkers = () =>
    hospitalsWithDistance.map((hospital, index) => {
      if (canView(hospital))
        return (
          <HospitalPreferenceMapMarker
            key={`hospital-${hospital.id}_marker`}
            type={getType(hospital)}
            lat={hospital.latitude}
            lng={hospital.longitude}
            data={hospital}
            index={index}
          />
        )

      return null
    })

  return (
    <MapWrapper>
      <GoogleMapsWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAPS_KEY }}
          center={centrePoint}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          defaultZoom={10}
          options={{
            scrollwheel: false,
            fullscreenControl: false,
            zoomControl: false
          }}
          unitSystem="IMPERIAL"
        >
          <HospitalPreferenceMapMarker
            key="client-address_marker"
            lat={clientGeoAddress.lat}
            lng={clientGeoAddress.lng}
            type="client"
          />
          {renderMarkers()}
        </GoogleMapReact>
        {!isDataLoading && (
          <ZoomButtons setZoomLevel={setZoomLevel} isDisableZoom={isDisableZoom} />
        )}
        {isDataLoading && <MapSkeleton borderRadius="0" />}
      </GoogleMapsWrapper>
    </MapWrapper>
  )
}

export default HospitalPreferenceMap
