/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import { get, find } from "lodash"
import moment from "moment"
import { object, string } from "yup"
import { Select, TextArea } from "@4cplatform/elements/Forms"
import { usePost } from "@4cplatform/elements/Api"
import { JourneyContext } from "../../../../../../journey.context"
import { currentProviders } from "./currentPolicyDetails.helpers"
import CurrentPolicyDetailsProvider from "./context/currentPolicyDetails.provider"
import { SelectWrapper } from "../ClaimsHistory/claimsHistory.styles"

const CurrentPolicyDetails = () => {
  const { data, formik, addToFormikValidationSchema, updateJourneyAuditData } =
    useContext(JourneyContext)
  const isLocked = get(data, "journey.locked", false)
  const [currentProducts, setCurrentProducts] = useState([])
  const [currentProductVariants, setCurrentProductVariants] = useState([])
  const [currentProductOptions, setCurrentProductOptions] = useState([])
  const [currentHospitalVariants, setCurrentHospitalVariants] = useState([])

  const [currentProvider, setCurrentProvider] = useState(
    get(data, "page.data.cp_current_insurer", null)
  )

  const [currentProduct, setCurrentProduct] = useState(
    get(data, "page.data.cp_current_product", null)
  )

  const [currentProductVariant, setCurrentProductVariant] = useState(
    get(data, "page.data.cp_current_product_variant", null)
  )

  const [currentHospitalVariant, setCurrentHospitalVariant] = useState(
    get(data, "page.data.cp_current_hospital_variant", null)
  )

  const [currentProductOutpatient, setCurrentProductOutpatient] = useState(
    get(data, "page.data.cp_current_product_outpatient", "")
  )

  const [currentProductTherapies, setCurrentProductTherapies] = useState(
    get(data, "page.data.cp_current_product_therapies", "")
  )

  const [currentProductCancer, setCurrentProductCancer] = useState(
    get(data, "page.data.cp_current_product_cancer", "")
  )

  const [currentProductPsychiatric, setCurrentProductPsychiatric] = useState(
    get(data, "page.data.cp_current_product_psychiatric", "")
  )

  const [currentProductTravel, setCurrentProductTravel] = useState(
    get(data, "page.data.cp_current_product_travel", "")
  )

  const [currentProductOda, setCurrentProductOda] = useState(
    get(data, "page.data.cp_current_product_oda", "")
  )

  const [currentPolicyNotes, setCurrentPolicyNotes] = useState(
    get(data, "page.data.cp_current_policy_notes", "")
  )

  const [fetchProducts] = usePost({
    endpoint: "/journeys/:slug/current-products",
    params: {
      slug: get(data, "journey.reference", ""),
      provider: currentProvider
    },
    onCompleted: res => {
      const result = get(res, "data", {})

      if (result && result.length > 0) {
        setCurrentProducts(result)
      }
    }
  })

  const [fetchProductOptions] = usePost({
    endpoint: "/journeys/:slug/current-product-options",
    params: {
      slug: get(data, "journey.reference", ""),
      product: currentProduct
    },
    onCompleted: res => {
      const result = get(res, "data", {})
      setCurrentProductOptions(result)
    }
  })

  const [fetchProductVariants] = usePost({
    endpoint: "/journeys/:slug/current-product-variants",
    params: {
      slug: get(data, "journey.reference", ""),
      product: currentProduct,
      outpatient: currentProductOutpatient,
      therapies: currentProductTherapies,
      cancer: currentProductCancer,
      psychiatric: currentProductPsychiatric,
      travel: currentProductTravel,
      oda: currentProductOda
    },
    onCompleted: res => {
      const result = get(res, "data", {})

      if (result && result.length > 0) {
        setCurrentProductVariants(result)
      }
    }
  })

  const [fetchHospitalVariants] = usePost({
    endpoint: "/journeys/:slug/current-hospital-variants",
    params: {
      slug: get(data, "journey.reference", ""),
      product: currentProduct,
      outpatient: currentProductOutpatient,
      therapies: currentProductTherapies,
      cancer: currentProductCancer,
      psychiatric: currentProductPsychiatric,
      travel: currentProductTravel,
      oda: currentProductOda
    },
    onCompleted: res => {
      const result = get(res, "data", {})

      if (result && result.length > 0) {
        setCurrentHospitalVariants(result)
      }
    }
  })

  useEffect(() => {
    // setting formik values and validationSchema
    if (get(formik, "values.cp_underwriting", "Don't know") !== "Don't know") {
      addToFormikValidationSchema(
        object({
          cp_current_insurer: string().required("MISSING_REQUIRED_FIELD").nullable()
        })
      )
    }

    if (
      get(formik, "values.cp_current_insurer", null) !== null &&
      get(formik, "values.cp_current_insurer", null) !== "Other"
    ) {
      addToFormikValidationSchema(
        object({
          cp_current_insurer: string().required("MISSING_REQUIRED_FIELD").nullable(),
          cp_current_product: string().required("MISSING_REQUIRED_FIELD").nullable()
        })
      )
    }

    if (
      get(formik, "values.cp_current_insurer", null) !== "Other" &&
      get(formik, "values.cp_current_product", null) !== null
    ) {
      addToFormikValidationSchema(
        object({
          cp_current_insurer: string().required("MISSING_REQUIRED_FIELD").nullable(),
          cp_current_product: string().required("MISSING_REQUIRED_FIELD").nullable(),
          cp_current_product_variant: string().required("MISSING_REQUIRED_FIELD").nullable(),
          cp_current_hospital_variant: string().required("MISSING_REQUIRED_FIELD").nullable()
        })
      )
    }

    if (get(formik, "values.cp_current_insurer", null) === "Other") {
      addToFormikValidationSchema(
        object({
          cp_current_insurer: string().required("MISSING_REQUIRED_FIELD").nullable()
        })
      )
    }

    formik.setFieldValue("cp_current_insurer", currentProvider)

    if (
      (get(formik, "values.cp_current_insurer", currentProvider) !== null &&
        get(formik, "values.cp_current_insurer", currentProvider) !== "Other") ||
      currentProduct !== null
    ) {
      formik.setFieldValue("cp_current_product", currentProduct)
    }

    if (
      (get(formik, "values.cp_current_insurer", currentProvider) !== null &&
        get(formik, "values.cp_current_insurer", currentProvider) !== "Other") ||
      currentProductVariant !== null
    ) {
      formik.setFieldValue("cp_current_product_variant", currentProductVariant)
    }

    if (
      (get(formik, "values.cp_current_insurer", currentProvider) !== null &&
        get(formik, "values.cp_current_insurer", currentProvider) !== "Other") ||
      currentHospitalVariant !== null
    ) {
      formik.setFieldValue("cp_current_hospital_variant", currentHospitalVariant)
    }

    const currentInsurer = get(data, "page.data.cp_current_insurer", null)

    if (currentInsurer === currentProvider) {
      fetchProducts({
        body: {
          provider: currentInsurer
        }
      })
      fetchProductVariants({
        body: {
          product: currentProduct
        }
      })
      fetchHospitalVariants({
        body: {
          provider: currentInsurer
        }
      })
    }

    formik.setFieldValue("cp_current_policy_notes", currentPolicyNotes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  function findLabelByValue(dataArray, targetValue) {
    const matchingObject = find(dataArray, { value: targetValue })

    // If a matching object is found, return its label; otherwise, return null.
    return matchingObject ? matchingObject.label : targetValue
  }
  const currentPolicyDetailsAudit = () => {
    const auditData = []
    const  cpCurrentInsurer = get(formik, "values.cp_current_insurer", "")
    const  isCurrentInsurerSelected = cpCurrentInsurer !== "Other" && cpCurrentInsurer !== null
    auditData.push({
      name: "Current Insurer",
      value: cpCurrentInsurer
    })

    if (isCurrentInsurerSelected) {
      const policyDetail = [
        currentProduct && {
          name: "Current Product",
          value: findLabelByValue(
            currentProducts,
            currentProduct
          ) 
        },

        currentProductOutpatient && {
          name: "Current Outpatient Treatment & Diagnostics",
          value: findLabelByValue(
            get(currentProductOptions, "outpatient", []),
            currentProductOutpatient
          )
        },
        currentProductTherapies && {
          name: "Current Therapies Cover",
          value: findLabelByValue(
            get(currentProductOptions, "therapies", []),
            currentProductTherapies
          )
        },
        currentProductCancer && {
          name: "Current Cancer Cover",
          value: findLabelByValue(get(currentProductOptions, "cancer", []), currentProductCancer)
        },
        currentProductPsychiatric && {
          name: "Current Psychiatric Cover",
          value: findLabelByValue(
            get(currentProductOptions, "psychiatric", []),
            currentProductPsychiatric
          )
        },
        currentProductTravel && {
          name: "Current Travel Cover",
          value: findLabelByValue(get(currentProductOptions, "travel", []), currentProductTravel)
        },
        currentProductOda && {
          name: "Current Optical, Dental & Audiological Cover",
          value: findLabelByValue(get(currentProductOptions, "oda", []), currentProductOda)
        },
        currentProductVariant && {
          name: "Current Product Variant",
          value: findLabelByValue(currentProductVariants, currentProductVariant)
        },
        currentHospitalVariant && {
          name: "Current Hospital Variant",
          value: findLabelByValue(currentHospitalVariants, currentHospitalVariant)
        },
        currentProductVariant === "other" &&
          currentPolicyNotes && {
            name: "Current Policy Note",
            value: currentPolicyNotes
          }
      ]
      return auditData.concat(policyDetail.filter(detail => detail))
    }

    auditData.push({
      name: "Current Policy Note",
      value: currentPolicyNotes
    })
    return auditData
  }

  useEffect(() => {
    const currentPolicy = get(formik, "values.cp_current_policy", false)
    const renewalDate = get(formik, "values.cp_renewal_date", "")
    const monthlyCost = `£${get(formik, "values.cp_monthly_cost", "")}`
    const excess = `£${get(formik, "values.cp_excess", "")}`
    if (!currentPolicy) {
      updateJourneyAuditData([
        {
          mode: "replace",
          data: [
            {
              name: "Does the client have a current policy in place?",
              value: "No"
            }
          ]
        }
      ])
    } else {
      updateJourneyAuditData([
        {
          mode: "custom",
          data: [
            ...(renewalDate
              ? [
                  {
                    name: "Renewal date of existing policy",
                    value: moment(renewalDate).format("DD/MM/YYYY")
                  },
                  {
                    name: "Current monthly cost",
                    value: monthlyCost
                  },
                  {
                    name: "Current Excess",
                    value: excess
                  }
                ]
              : [])
          ]
        },
        {
          mode: "append",
          data: currentPolicyDetailsAudit()
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values])

  useEffect(() => {
    const  currProduct = get(formik, "values.cp_current_product", "")
    if (currProduct) {
      fetchProductOptions({ body: { product: currProduct } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.cp_current_product])

  return (
    <>
      <CurrentPolicyDetailsProvider>
        <SelectWrapper>
          <Select
            name="cp_current_insurer"
            label="Current Insurer"
            required
            formik={formik}
            isDisabled={isLocked}
            labelWidth="50%"
            margin="0 0 2rem"
            isHorizontal
            isRequired
            shouldDisplayEmptyOption
            options={currentProviders}
            onChange={val => {
              setCurrentProvider(val)
              formik.setFieldValue("cp_current_insurer", val)
              fetchProducts({ body: { provider: val } })
              fetchHospitalVariants({ body: { provider: val } })
              setCurrentProduct(null)
              setCurrentProductVariant(null)
              setCurrentHospitalVariant(null)
              setCurrentProductOutpatient("")
              setCurrentProductTherapies("")
              setCurrentProductCancer("")
              setCurrentProductPsychiatric("")
              setCurrentProductTravel("")
              setCurrentProductOda("")
            }}
            value={currentProvider}
          />
          {currentProvider !== "Other" && currentProvider !== "" && currentProvider !== null && (
            <Select
              name="cp_current_product"
              label="Current Product"
              required
              formik={formik}
              isDisabled={isLocked}
              labelWidth="50%"
              margin="0 0 2rem"
              isHorizontal
              isRequired
              shouldDisplayEmptyOption
              options={currentProducts}
              onChange={val => {
                setCurrentProduct(val)
                formik.setFieldValue("cp_current_product", val)
                fetchProductVariants({
                  body: {
                    product: val,
                    outpatient: currentProductOutpatient,
                    therapies: currentProductTherapies,
                    cancer: currentProductCancer,
                    psychiatric: currentProductPsychiatric,
                    travel: currentProductTravel,
                    oda: currentProductOda
                  }
                })
                setCurrentProductOutpatient("")
                setCurrentProductTherapies("")
                setCurrentProductCancer("")
                setCurrentProductPsychiatric("")
                setCurrentProductTravel("")
                setCurrentProductOda("")
              }}
              value={currentProduct}
            />
          )}
          {currentProvider !== "Other" && currentProduct !== null && (
            <Select
              name="cp_current_product_outpatient"
              label="Current Outpatient Treatment & Diagnostics"
              formik={formik}
              isDisabled={isLocked}
              labelWidth="50%"
              margin="0 0 2rem"
              isHorizontal
              shouldDisplayEmptyOption
              options={get(currentProductOptions, "outpatient", [])}
              onChange={val => {
                setCurrentProductOutpatient(val)
                formik.setFieldValue("cp_current_product_outpatient", val)
                fetchProductVariants({
                  body: {
                    product: currentProduct,
                    outpatient: val,
                    therapies: currentProductTherapies,
                    cancer: currentProductCancer,
                    psychiatric: currentProductPsychiatric,
                    travel: currentProductTravel,
                    oda: currentProductOda
                  }
                })
              }}
              value={currentProductOutpatient}
            />
          )}
          {currentProvider !== "Other" &&
            currentProduct !== null &&
            get(currentProductOptions, "therapies", []).length > 0 && (
              <Select
                name="cp_current_product_therapies"
                label="Current Therapies Cover"
                formik={formik}
                isDisabled={isLocked}
                labelWidth="50%"
                margin="0 0 2rem"
                isHorizontal
                shouldDisplayEmptyOption
                options={get(currentProductOptions, "therapies", [])}
                onChange={val => {
                  setCurrentProductTherapies(val)
                  formik.setFieldValue("cp_current_product_therapies", val)
                  fetchProductVariants({
                    body: {
                      product: currentProduct,
                      outpatient: currentProductOutpatient,
                      therapies: val,
                      cancer: currentProductCancer,
                      psychiatric: currentProductPsychiatric,
                      travel: currentProductTravel,
                      oda: currentProductOda
                    }
                  })
                }}
                value={currentProductTherapies}
              />
            )}
          {currentProvider !== "Other" &&
            currentProduct !== null &&
            get(currentProductOptions, "cancer", []).length > 0 && (
              <Select
                name="cp_current_product_cancer"
                label="Current Cancer Cover"
                formik={formik}
                isDisabled={isLocked}
                labelWidth="50%"
                margin="0 0 2rem"
                isHorizontal
                shouldDisplayEmptyOption
                options={get(currentProductOptions, "cancer", [])}
                onChange={val => {
                  setCurrentProductCancer(val)
                  formik.setFieldValue("cp_current_product_cancer", val)
                  fetchProductVariants({
                    body: {
                      product: currentProduct,
                      outpatient: currentProductOutpatient,
                      therapies: currentProductTherapies,
                      cancer: val,
                      psychiatric: currentProductPsychiatric,
                      travel: currentProductTravel,
                      oda: currentProductOda
                    }
                  })
                }}
                value={currentProductCancer}
              />
            )}
          {currentProvider !== "Other" &&
            currentProduct !== null &&
            get(currentProductOptions, "psychiatric", []).length > 0 && (
              <Select
                name="cp_current_product_psychiatric"
                label="Current Psychiatric Cover"
                formik={formik}
                isDisabled={isLocked}
                labelWidth="50%"
                margin="0 0 2rem"
                isHorizontal
                shouldDisplayEmptyOption
                options={get(currentProductOptions, "psychiatric", [])}
                onChange={val => {
                  setCurrentProductPsychiatric(val)
                  formik.setFieldValue("cp_current_product_psychiatric", val)
                  fetchProductVariants({
                    body: {
                      product: currentProduct,
                      outpatient: currentProductOutpatient,
                      therapies: currentProductTherapies,
                      cancer: currentProductCancer,
                      psychiatric: val,
                      travel: currentProductTravel,
                      oda: currentProductOda
                    }
                  })
                }}
                value={currentProductPsychiatric}
              />
            )}
          {currentProvider !== "Other" &&
            currentProduct !== null &&
            get(currentProductOptions, "travel", []).length > 0 && (
              <Select
                name="cp_current_product_travel"
                label="Current Travel Cover"
                formik={formik}
                isDisabled={isLocked}
                labelWidth="50%"
                margin="0 0 2rem"
                isHorizontal
                shouldDisplayEmptyOption
                options={get(currentProductOptions, "travel", [])}
                onChange={val => {
                  setCurrentProductTravel(val)
                  formik.setFieldValue("cp_current_product_travel", val)
                  fetchProductVariants({
                    body: {
                      product: currentProduct,
                      outpatient: currentProductOutpatient,
                      therapies: currentProductTherapies,
                      cancer: currentProductCancer,
                      psychiatric: currentProductPsychiatric,
                      travel: val,
                      oda: currentProductOda
                    }
                  })
                }}
                value={currentProductTravel}
              />
            )}
          {currentProvider !== "Other" && currentProduct !== null && (
            <Select
              name="cp_current_product_oda"
              label="Current Optical, Dental & Audiological Cover"
              formik={formik}
              isDisabled={isLocked}
              labelWidth="50%"
              margin="0 0 2rem"
              isHorizontal
              shouldDisplayEmptyOption
              options={get(currentProductOptions, "oda", [])}
              onChange={val => {
                setCurrentProductOda(val)
                formik.setFieldValue("cp_current_product_oda", val)
                fetchProductVariants({
                  body: {
                    product: currentProduct,
                    outpatient: currentProductOutpatient,
                    therapies: currentProductTherapies,
                    cancer: currentProductCancer,
                    psychiatric: currentProductPsychiatric,
                    travel: currentProductTravel,
                    oda: val
                  }
                })
              }}
              value={currentProductOda}
            />
          )}
          {currentProvider !== "Other" && currentProduct !== null && (
            <Select
              name="cp_current_product_variant"
              label="Current Product Variant"
              required
              formik={formik}
              isDisabled={isLocked}
              labelWidth="50%"
              margin="0 0 2rem"
              isHorizontal
              isRequired
              shouldDisplayEmptyOption
              options={currentProductVariants}
              onChange={val => {
                setCurrentProductVariant(val)
                formik.setFieldValue("cp_current_product_variant", val)
              }}
              value={currentProductVariant}
            />
          )}
          {currentProvider !== "Other" && currentProduct !== null && (
            <Select
              name="cp_current_hospital_variant"
              label="Current Hospital Variant"
              required
              formik={formik}
              isDisabled={isLocked}
              labelWidth="50%"
              margin="0 0 2rem"
              isHorizontal
              isRequired
              shouldDisplayEmptyOption
              options={currentHospitalVariants}
              onChange={val => {
                setCurrentHospitalVariant(val)
                formik.setFieldValue("cp_current_hospital_variant", val)
              }}
              value={currentHospitalVariant}
            />
          )}
          {(currentProvider === "Other" || currentProductVariant === "other") && (
            <>
              <TextArea
                label="If your clients current policy can not be found, manually enter notes relating to their existing cover"
                name="cp_current_policy_notes"
                formik={formik}
                placeholder="Current policy notes"
                onChange={val => {
                  setCurrentPolicyNotes(val)
                  formik.setFieldValue("cp_current_policy_notes", val)
                }}
              />
            </>
          )}
        </SelectWrapper>
      </CurrentPolicyDetailsProvider>
    </>
  )
}

export default CurrentPolicyDetails
