import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useFormik } from "formik"
import { Button } from "@4cplatform/elements/Molecules"
import { Checkbox, TextArea, Input } from "@4cplatform/elements/Forms"
import { H4 } from "@4cplatform/elements/Typography"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { DealCodesContext } from "./deals.context"
import { editDealCodeModel as validationSchema } from "./deals.helpers"
import { EditFieldWrapper, EditFieldRow, ButtonsWrapper } from "./deals.styles"

const Form = ({ selectedDealCode }) => {
  const isAviva = React.useMemo(
    () => get(selectedDealCode, "provider.provider_key", "") === "AVIVA",
    [selectedDealCode]
  )
  const { setPanelStatus } = React.useContext(PageContext)
  const { onUpdateDealCodeSubmit, updateLoading } = React.useContext(DealCodesContext)
  const isDeleted = !!get(selectedDealCode, "deleted_at")
  // Define fields for Edit Deal Code form
  const editDealCodeFormik = useFormik({
    initialValues: {
      description: get(selectedDealCode, "description", ""),
      style_new: get(selectedDealCode, "style_new", false),
      style_switch: get(selectedDealCode, "style_switch", false),
      underwriting_fmu: get(selectedDealCode, "underwriting_fmu", false),
      underwriting_mori: get(selectedDealCode, "underwriting_mori", false),
      quoting: get(selectedDealCode, "quoting", false),
      onboarding: get(selectedDealCode, "onboarding", false),
      details: {
        incentive_parameter: get(selectedDealCode, "details.incentive_parameter", ""),
        incentive_type: get(selectedDealCode, "details.incentive_type", ""),
        onboard_deal_code: get(selectedDealCode, "details.onboard_deal_code", "")
      },
      provider_id: get(selectedDealCode, "provider_id", 0)
    },
    validationSchema,
    onSubmit: body => {
      if (!body.quoting) {
        body.details.incentive_parameter = ""
        body.details.incentive_type = ""
      }

      if (isAviva && !body.onboarding) {
        body.details.onboard_deal_code = ""
      }

      onUpdateDealCodeSubmit({ body })
    }
  })

  const { handleSubmit } = editDealCodeFormik
  const formik = { ...editDealCodeFormik, validationSchema }

  const renderDetails = () => {
    if (isAviva) {
      return (
        <>
          {get(formik, "values.quoting") && (
            <>
              <Input
                appearance="light"
                name="details.incentive_parameter"
                label="Incentive parameter"
                formik={formik}
              />
              <Input
                appearance="light"
                name="details.incentive_type"
                label="Incentive type"
                formik={formik}
              />
            </>
          )}
          {get(formik, "values.onboarding") && (
            <Input
              appearance="light"
              name="details.onboard_deal_code"
              label="Onboard deal code"
              formik={formik}
            />
          )}
        </>
      )
    }
    return (
      <Input
        appearance="light"
        name="details.onboard_deal_code"
        label="Onboard deal code"
        formik={formik}
      />
    )
  }

  return (
    <div data-testid="deal_codes-edit_deal_codes">
      {/* Journey Types */}
      <H4 appearance="light" margin="0 0 2rem">
        Journey Types
      </H4>
      <EditFieldRow>
        <EditFieldWrapper>
          <Checkbox
            appearance="light"
            label="New"
            name="style_new"
            formik={formik}
            isDisabled={isDeleted}
            margin="0"
          />
        </EditFieldWrapper>
        <EditFieldWrapper>
          <Checkbox
            appearance="light"
            label="Switch"
            name="style_switch"
            formik={formik}
            isDisabled={isDeleted}
            margin="0"
          />
        </EditFieldWrapper>
      </EditFieldRow>
      {/* Underwriting Types */}
      <H4 appearance="light" margin="0 0 2rem">
        Underwriting Styles
      </H4>
      <EditFieldRow>
        <EditFieldWrapper>
          <Checkbox
            appearance="light"
            label="Moratorium"
            name="underwriting_mori"
            formik={formik}
            isDisabled={isDeleted}
            margin="0"
          />
        </EditFieldWrapper>
        <EditFieldWrapper>
          <Checkbox
            appearance="light"
            label="FMU"
            name="underwriting_fmu"
            formik={formik}
            isDisabled={isDeleted}
            margin="0"
          />
        </EditFieldWrapper>
      </EditFieldRow>
      {/* Service Types */}
      <H4 appearance="light" margin="0 0 2rem">
        Service Type(s)
      </H4>
      <EditFieldRow>
        <EditFieldWrapper>
          <Checkbox
            appearance="light"
            label="Quoting"
            name="quoting"
            formik={formik}
            isDisabled={isDeleted}
            margin="0"
          />
        </EditFieldWrapper>
        <EditFieldWrapper>
          <Checkbox
            appearance="light"
            label="Onboarding"
            name="onboarding"
            formik={formik}
            isDisabled={isDeleted}
            margin="0"
          />
        </EditFieldWrapper>
      </EditFieldRow>
      {/* The Details section */}
      {renderDetails()}
      <TextArea
        label="Description"
        name="description"
        formik={formik}
        margin="0 0 2rem"
        appearance="light"
        rows={5}
      />
      {/* Submit/Cancel buttons */}
      <ButtonsWrapper>
        <Button
          appearance="error"
          trailingIcon="close"
          onClick={() => setPanelStatus("open")}
          name="edit_deal_codes_cancel"
        >
          Cancel
        </Button>
        <Button
          appearance="success"
          trailingIcon="check"
          onClick={handleSubmit}
          isLoading={updateLoading}
          isDisabled={isDeleted}
          name="edit_deal_codes_submit"
        >
          Save
        </Button>
      </ButtonsWrapper>
    </div>
  )
}

Form.defaultProps = {
  selectedDealCode: null
}

Form.propTypes = {
  selectedDealCode: PropTypes.object
}

export default Form
