import { boolean } from "yup"
import { get } from "lodash"
import { Toggle } from "@4cplatform/elements/Forms"
import { P, H4 } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"

export const config = data => ({
  title: "Hospital list confirmation",
  sections: [
    {
      key: "section_1",
      components: [
        {
          key: "instruction_text_prompt",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <H4 margin="0 0 1rem">Confirm hospital list</H4>
                <P margin="0">
                  {get(data, "journey.selected_quote.provider.provider_key") === "VITALITYHEALTH" &&
                  get(data, "journey.selected_quote.hospital_list") === "Consultant Select" ? (
                    <>
                      Access to the client's hospital preference is not guaranteed due to them
                      choosing the providers' guided option. Ensure the client is aware of the
                      providers' claims process when guided option has been chosen before
                      proceeding.
                    </>
                  ) : (
                    <>
                      The client's hospital preference is not included on the quoted hospital list.
                      Ensure the client is aware that the hospital list selected does not contain
                      the client's preferred hospital.
                    </>
                  )}
                </P>
              </>
            ),
            type: "warning"
          },
          skipDataMap: true
        },
        {
          key: "confirm_client_happy_with_selected_hospital_list",
          initialValue: get(
            data,
            "page.data.confirm_client_happy_with_selected_hospital_list",
            false
          ),
          validationSchema: boolean()
            .oneOf([true], "MUST_BE_TRUE")
            .required("MISSING_REQUIRED_FIELD"),
          label: "Client happy to proceed?",
          component: Toggle,
          componentProps: {
            isHorizontal: true,
            isRequired: true
          }
        }
      ]
    }
  ],
  disableNextCondition: {
    type: "formik",
    fieldKey: "confirm_client_happy_with_selected_hospital_list",
    fieldValue: false
  }
})
