import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { colours } from "@4cplatform/elements/Helpers"
import { DoughnutChartContainer, DoughnutChartWrapper } from "./miReports.style"

ChartJS.register(ArcElement, Tooltip, Legend)

const colors = [colours.networksRed, colours.paleGreen, colours.organisationsOrange, colours.purple]

const DoughnutChart = ({ labels, data, plugins, isHideDougnutBg }) => {
  const backgroundColor = useMemo(() => {
    let colorIndex = -1

    return labels.map(() => {
      colorIndex = colorIndex + 1 >= colors.length ? 0 : colorIndex + 1
      return colors[colorIndex]
    })
  }, [labels])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          padding: 16,
          font: {
            size: 16,
            weight: "bold"
          }
        }
      },
      ...(plugins.some(plugin => plugin.id === "radiusBackground")
        ? {
            radiusBackground: {
              enabled: true,
              color: (() => {
                if (!isHideDougnutBg) {
                  return colours.faintGrey
                }
                return isEmpty(data) ? colours.faintGrey : "transparent"
              })()
            }
          }
        : {}),
      datalabels: {
        color: colors.white,
        font: {
          size: 16,
          weight: "bold"
        }
      }
    }
  }

  return (
    <DoughnutChartContainer>
      <DoughnutChartWrapper>
        <Doughnut
          plugins={[ChartDataLabels, ...plugins]}
          options={options}
          data={{
            labels,
            datasets: [
              {
                data,
                backgroundColor
              }
            ]
          }}
        />
      </DoughnutChartWrapper>
    </DoughnutChartContainer>
  )
}

DoughnutChart.defaultProps = {
  labels: [],
  data: [],
  plugins: [],
  isHideDougnutBg: false
}

DoughnutChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.number),
  plugins: PropTypes.arrayOf(PropTypes.any),
  isHideDougnutBg: PropTypes.bool
}

export default DoughnutChart
