import { object, string, boolean } from "yup"
import { get } from "lodash"

// Helpers
import { requiredIfFalse } from "../../Helpers"

/**
 * The validationSchema used by the createDealCodeFormik instance
 */
export const createDealCodeModel = providers => {
  const avivaId = providers.find(p => p.provider_key.toLowerCase() === "aviva")?.id ?? 0
  const isAviva = context => parseInt(get(context.from[1], "value.provider_id")) === avivaId

  return object({
    product_type: string().required("MISSING_REQUIRED_FIELD"),
    provider_id: string().required("MISSING_REQUIRED_FIELD_WITH_PREFIX_PROVIDER"),
    product: string().required("MISSING_REQUIRED_FIELD"),
    name: string().required("MISSING_REQUIRED_FIELD").min(3, "Must be exactly 3 characters"),
    description: string(),
    start_date: string(),
    end_date: string(),
    style_new: boolean()
      .test("requiredIfFalse", "REQUIRED_IF_FALSE", (value, context) => {
        const values = get(context, "parent", {})
        return requiredIfFalse(values, ["style_new", "style_switch"])
      })
      .required("MISSING_REQUIRED_FIELD"),
    style_switch: boolean()
      .test("requiredIfFalse", "REQUIRED_IF_FALSE", (value, context) => {
        const values = get(context, "parent", {})
        return requiredIfFalse(values, ["style_new", "style_switch"])
      })
      .required("MISSING_REQUIRED_FIELD"),
    underwriting_fmu: boolean()
      .test("requiredIfFalse", "REQUIRED_IF_FALSE", (value, context) => {
        const values = get(context, "parent", {})
        return requiredIfFalse(values, ["underwriting_mori", "underwriting_fmu"])
      })
      .required("MISSING_REQUIRED_FIELD"),
    underwriting_mori: boolean()
      .test("requiredIfFalse", "REQUIRED_IF_FALSE", (value, context) => {
        const values = get(context, "parent", {})
        return requiredIfFalse(values, ["underwriting_mori", "underwriting_fmu"])
      })
      .required("MISSING_REQUIRED_FIELD"),
    quoting: boolean()
      .test("requiredIfFalse", "REQUIRED_IF_FALSE", (value, context) => {
        const values = get(context, "parent", {})
        return requiredIfFalse(values, ["quoting", "onboarding"])
      })
      .required("MISSING_REQUIRED_FIELD"),
    onboarding: boolean()
      .test("requiredIfFalse", "REQUIRED_IF_FALSE", (value, context) => {
        const values = get(context, "parent", {})
        return requiredIfFalse(values, ["quoting", "onboarding"])
      })
      .required("MISSING_REQUIRED_FIELD"),
    details: object({
      onboard_deal_code: string().test(
        "onboard deal code",
        "Must be at least 3 characters long",
        (value, context) => {
          if (
            value &&
            (!isAviva(context) || (isAviva(context) && context.from[1].value.onboarding))
          ) {
            return value.length >= 3
          }
          return true
        }
      ),
      incentive_parameter: string(),
      incentive_type: string()
    })
  })
}
