import React, { useContext } from "react"
import PropTypes from "prop-types"
import { get, capitalize } from "lodash"
import moment from "moment"
import { Table } from "@4cplatform/elements/Organisms"
import { Icon } from "@4cplatform/elements/Atoms"
import { colours } from "@4cplatform/elements/Helpers"
import { SmallText, H4, P } from "@4cplatform/elements/Typography"
import { Checkbox } from "@4cplatform/elements/Forms"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { ConfigContext } from "@4cplatform/elements/Config"

// Helpers
import { JourneyContext } from "../../../../../../journey.context"
import { ApplicantsContext } from "./applicants.context"
import { getName } from "../../../../../../../../Helpers"

// Components
import { AnswerWrapper } from "./applicants.styles"
import RowActions from "./applicants.table.actions"
import Audit from "./audit"

const ApplicantsTable = ({ onCheckboxChange }) => {
  const {
    applicantPaginatedData,
    applicants,
    setPage,
    setPerPage,
    pagination,
    applicantsLoading,
    includeApplicant,
    includeApplicantLoading,
    excludeApplicant,
    excludeApplicantLoading,
    checkIfChildOnlyQuote,
    included
  } = useContext(ApplicantsContext)
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { formik, data } = useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)

  return (
    <>
      {checkIfChildOnlyQuote() && (
        <ComplianceNote type="info" margin="0 0 2rem">
          <H4 margin="0.5rem 0 1rem">Child Only Quote</H4>
          <P margin="0 0 1rem">
            Not all providers offer a child only policy and therefore not all quotes may be
            returned.
          </P>
        </ComplianceNote>
      )}
      <Table
        data={applicantPaginatedData}
        isLoading={applicantsLoading}
        name="applicants"
        columns={[
          {
            label: "Incl.",
            data: "included",
            minWidth: "100px",
            render: row => {
              const slug = get(row, "data.slug", "")
              const isIncluded = get(row, "data.included", false)
              return (
                <Checkbox
                  name={`applicant_${get(row, "data.id", "")}_${
                    isIncluded ? "exclude" : "include"
                  }`}
                  label={null}
                  value={isIncluded}
                  margin="0"
                  onChange={() => {
                    if (isIncluded) {
                      if (
                        !Array.isArray(applicants) ||
                        applicants.filter(o => o.included).length <= 1
                      ) {
                        onCheckboxChange(true)
                      } else {
                        excludeApplicant(slug)
                        onCheckboxChange(false)
                      }
                    } else {
                      includeApplicant(slug)
                      onCheckboxChange(false)
                    }
                    formik.setFieldValue(
                      "applicants",
                      included.map(applicant => ({
                        name: getName({ data: applicant }),
                        age: moment().diff(
                          moment.utc(get(applicant, "date_of_birth"), "YYYY-MM-DD HH:mm").local(),
                          "years"
                        ),
                        type: capitalize(get(applicant, "type", "-"))
                      }))
                    )
                  }}
                  isDisabled={
                    includeApplicantLoading ||
                    excludeApplicantLoading ||
                    applicants.length === 1 ||
                    isLocked
                  }
                />
              )
            }
          },
          [
            {
              label: "Name",
              data: "first_name",
              minWidth: "180px",
              render: row =>
                getName({
                  data: get(row, "data"),
                  hasTitle: true,
                  globalTitles: GLOBAL_TITLES,
                  hideOtherTitle: true
                })
            },
            {
              label: "Details",
              data: "date_of_birth",
              minWidth: "180px",
              render: row => {
                const dob = moment.utc(get(row, "data.date_of_birth"), "YYYY-MM-DD HH:mm").local()
                const age = moment().diff(dob, "years")
                return (
                  <SmallText margin="0">
                    Age {age}
                    &nbsp;&nbsp;
                    {capitalize(get(row, "data.type", "-"))}
                  </SmallText>
                )
              }
            }
          ],
          {
            label: "Smoker",
            data: "data.tobacco_products_within_last_2_years",
            minWidth: "80px",
            render: row => {
              const isSmoker = get(row, "data.data.tobacco_products_within_last_2_years", false)
              return (
                <AnswerWrapper>
                  <Icon
                    icon={isSmoker ? "check-circle" : "close-circle"}
                    colour={isSmoker ? get(colours, "green") : get(colours, "red")}
                    size="2.5rem"
                  />
                </AnswerWrapper>
              )
            }
          },
          {
            label: "UK Resident",
            data: "data.permanent_uk_resident",
            minWidth: "80px",
            render: row => {
              const isResident = get(row, "data.data.permanent_uk_resident", false)
              return (
                <AnswerWrapper>
                  <Icon
                    icon={isResident ? "check-circle" : "close-circle"}
                    colour={isResident ? get(colours, "green") : get(colours, "red")}
                    size="2.5rem"
                  />
                </AnswerWrapper>
              )
            }
          },
          {
            label: "Visa",
            data: "data.pmi_required_to_fulfil_reqs_or_visa",
            minWidth: "80px",
            render: row => {
              const isRequiredForVisa = get(
                row,
                "data.data.pmi_required_to_fulfil_reqs_or_visa",
                false
              )
              return (
                <AnswerWrapper>
                  <Icon
                    icon={isRequiredForVisa ? "check-circle" : "close-circle"}
                    colour={isRequiredForVisa ? get(colours, "green") : get(colours, "red")}
                    size="2.5rem"
                  />
                </AnswerWrapper>
              )
            }
          },
          {
            label: "",
            data: "buttons",
            minWidth: "180px",
            render: row => <RowActions applicant={get(row, "data", {})} isLocked={isLocked} />
          }
        ]}
        hasActions={false}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        perPageOptions={{ max: 20, interval: 5 }}
        hasPerPage={false}
      />

      <Audit />
    </>
  )
}

ApplicantsTable.defaultProps = {
  onCheckboxChange: null
}

ApplicantsTable.propTypes = {
  onCheckboxChange: PropTypes.func
}

export default ApplicantsTable
