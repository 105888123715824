import React from "react"
import { Button } from "@4cplatform/elements/Molecules"
import { H3, SmallText } from "@4cplatform/elements/Typography"
import { PageContext } from "@4cplatform/elements/Organisms"
import { AuthContext } from "@4cplatform/elements/Auth"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { isEmpty } from "lodash"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"
import { formatOrganisationsAsEvents } from "../agencyCodes.helpers"

// Components
import { Tabs, Tab, Timeline } from "../../../Organisms"
import CommissionRates from "../components/CommisionRates"
import DealCodeAssignment from "../components/DealCodeAssignment"

const AgencyCodesPanelBody = () => {
  const { viewLoading, sharedOrganisations, bodyTabId, setBodyTabId } =
    React.useContext(AgencyCodesContext)
  const { setPanelStatus } = React.useContext(PageContext)
  const { canAccess } = React.useContext(AuthContext)

  return (
    <PanelBody>
      <Tabs
        type="panel"
        name="agency_codes_panel"
        isLoading={viewLoading}
        defaultIndex={bodyTabId}
        newActiveID={bodyTabId}
        margin="0 0 1rem"
        onTabChange={key => setBodyTabId(key)}
      >
        <Tab header="Commission">
          <CommissionRates shouldHideSecondaryCommissionRate />
        </Tab>
        <Tab header="Sharing">
          <H3 appearance="light" margin="2rem 0">
            Shared with
          </H3>
          {isEmpty(sharedOrganisations) && (
            <SmallText appearance="light">This agency code has not been shared.</SmallText>
          )}
          <Timeline events={formatOrganisationsAsEvents({ organisations: sharedOrganisations })} />
          <Button
            appearance="whiteGhost"
            trailingIcon="share-variant-outline"
            onClick={() => setPanelStatus("wide")}
            name="share"
          >
            Share
          </Button>
        </Tab>
        <Tab
          header="Deal code"
          isPresent={canAccess([
            "SYS_ADMIN",
            "SUPPORT_ADMIN",
            "PROVIDER_ADMIN",
            "ORG_ADMIN",
            "SALES_ADVISER",
            "NETWORK_ADMIN",
            "NETWORK_MEMBER_ADMIN"
          ])}
        >
          <DealCodeAssignment />
        </Tab>
      </Tabs>
    </PanelBody>
  )
}

export default AgencyCodesPanelBody
