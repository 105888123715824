import React from "react"

// Components
import OrganisationPoliciesProvider from "./context/policies.provider"
import PolicyAdmin, { PoliciesPanel } from "../../../../UI/Templates/PolicyAdmin"

const Policies = () => (
  <OrganisationPoliciesProvider>
    <PolicyAdmin />
    <PoliciesPanel />
  </OrganisationPoliciesProvider>
)

export default Policies
