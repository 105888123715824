import React, { useContext } from "react"
import moment from "moment"
import { get, isEmpty } from "lodash"
import { Input } from "@4cplatform/elements/Forms"
import { H2, SmallText } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { ConfigContext } from "@4cplatform/elements/Config"
import { PanelHeader, Button } from "@4cplatform/elements/Molecules"
import { AuthWrapper, AuthContext } from "@4cplatform/elements/Auth"

// Helpers
import { getName } from "../../Helpers"
import { PoliciesContext } from "./policies.context"
import { getIconDetails } from "./policies.helpers"

// Components
import { Messaging } from "../../Organisms"
import PolicyExclusions from "./components/PolicyExclusions"
import TermsButtons from "./components/TermsButtons"
import AcceptanceButtons from "./components/AcceptanceButtons"
import { ButtonsWrapper } from "./policies.styles"

const PoliciesPanelHeader = () => {
  const {
    viewData,
    viewLoading,
    reference,
    setReference,
    messagingModal,
    setMessagingModal,
    messages,
    messagesLoading,
    submitMessage,
    submitMessageLoading,
    generateAuditLog,
    generateAuditLogLoading
  } = useContext(PoliciesContext)
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { canAccess } = useContext(AuthContext)
  const t = useTranslations()

  const created = get(viewData, "created_at", "")
    ? moment
        .utc(get(viewData, "created_at", ""), "YYYY-MM-DDTHH:mmZ")
        .local()
        .format("DD/MM/YYYY HH:mm")
    : "-"

  const status = get(viewData, "status", false)
  const statusIcon = getIconDetails(status)

  const canMessageOrganisationUser =
    canAccess(["ORG_ADMIN", "SALES_ADVISER"]) && status === "AWAITING_ACCEPTANCE"
  const canMessageProviderUser =
    canAccess(["PROVIDER_ADMIN", "UNDERWRITER"]) &&
    ["AWAITING_TERMS", "AWAITING_ACCEPTANCE"].includes(status)
  const isNotProviderOrUnderWriter = !canAccess(["UNDERWRITER", "PROVIDER_ADMIN"])

  return (
    <PanelHeader>
      <H2 appearance="light" isLoading={viewLoading} margin="2rem 0 1rem">
        {getName({
          data: get(viewData, "client"),
          hasMiddle: true,
          hasTitle: true,
          globalTitles: GLOBAL_TITLES,
          hideOtherTitle: true
        })}
      </H2>
      <SmallText appearance="light" isLoading={viewLoading} margin="0 0 2rem">
        Created: {created}
      </SmallText>
      <IconWithText
        icon="pound"
        appearance="light"
        content={get(viewData, "reference", "-")}
        isLoading={viewLoading}
      />
      {!isEmpty(statusIcon) && (
        <IconWithText
          appearance="light"
          content={t(status)}
          {...statusIcon}
          isLoading={viewLoading}
        />
      )}
      <IconWithText
        icon="hospital-building"
        appearance="light"
        isLoading={viewLoading}
        content={get(viewData, "provider.name", "-")}
        margin="0"
      />
      <IconWithText
        margin="0 0 2rem 3rem"
        appearance="light"
        isLoading={viewLoading}
        icon="subdirectory-arrow-right"
        content={t(get(viewData, "product_name", "-"))}
      />
      <IconWithText
        icon="office-building-outline"
        appearance="light"
        isLoading={viewLoading}
        content={get(viewData, "sales_agent.parent.name", "-")}
        margin="0"
      />
      <IconWithText
        margin="0 0 2rem 3rem"
        isLoading={viewLoading}
        appearance="light"
        icon="subdirectory-arrow-right"
        content={getName({
          data: get(viewData, "sales_agent")
        })}
      />
      <IconWithText
        appearance="light"
        isLoading={viewLoading}
        icon="currency-gbp"
        content={`£${get(viewData, "annual_premium", "")} yearly, ${get(
          viewData,
          "months_of_cover",
          ""
        )} months of cover`}
      />
      <IconWithText
        icon="medical-bag"
        appearance="light"
        content={[
          get(viewData, "client.lead_type", ""),
          get(viewData, "client.lead_source", "")
        ].join(", ")}
        margin="0 0 2rem"
        isLoading={viewLoading}
      />
      <ButtonsWrapper className="row-order">
        {(canMessageOrganisationUser || canMessageProviderUser) && (
          <>
            <Button
              appearance="whiteGhost"
              trailingIcon="email"
              onClick={() => setMessagingModal(true)}
              name="messaging_modal"
              isLoading={viewLoading}
              isDisabled={viewLoading}
              margin="1rem 0"
            >
              Messaging
            </Button>
            <Messaging
              isOpen={messagingModal}
              setIsOpen={setMessagingModal}
              messages={messages}
              isMessagesLoading={messagesLoading}
              submitMessage={submitMessage}
              isSubmitMessageLoading={submitMessageLoading}
            />
          </>
        )}
        {isNotProviderOrUnderWriter && (
          <Button
            appearance="whiteGhost"
            trailingIcon="eye"
            onClick={() => generateAuditLog()}
            name="view_audit_log"
            isLoading={viewLoading || generateAuditLogLoading}
            isDisabled={viewLoading || generateAuditLogLoading}
            margin="1rem 0"
          >
            View Audit Log
          </Button>
        )}
      </ButtonsWrapper>
      {status === "AWAITING_TERMS" && (
        <>
          <PolicyExclusions />
          <AuthWrapper roles={["PROVIDER_ADMIN", "UNDERWRITER"]}>
            <TermsButtons />
          </AuthWrapper>
        </>
      )}
      {status === "AWAITING_ACCEPTANCE" && (
        <AuthWrapper roles={["PROVIDER_ADMIN", "SUPPORT_ADMIN", "SYS_ADMIN", "UNDERWRITER"]}>
          <Input
            appearance="light"
            name="policy_number"
            label="Add a policy number"
            margin="1rem 0 2rem"
            placeholder="Input policy number"
            value={reference}
            onChange={val => setReference(val)}
          />
          <AcceptanceButtons />
        </AuthWrapper>
      )}
    </PanelHeader>
  )
}

export default PoliciesPanelHeader
