import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useGet, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { PageContext } from "@4cplatform/elements/Organisms"
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../../../../../../../UI/Templates/AgencyCodes/SharedAgencyCodes"

// Helpers
import { getOrderBy } from "../../../../../../../UI/Helpers"
import { preserveProvider } from "../../../../../../../UI/Templates/AgencyCodes/agencyCodes.helpers"
import reducer from "./sharedAgencyCodes.reducer"

const MyAccountSharedAgencyCodesProvider = ({ children, isPending }) => {
  const { selfServiceData } = React.useContext(PageContext)
  const t = useTranslations()

  const [{ data, search, sorting, page, perPage, total, filter }, dispatch] = React.useReducer(
    reducer,
    {
      data: [],
      search: "",
      sorting: { direction: "asc", dataKey: "provider" },
      page: 1,
      perPage: 10,
      total: null,
      filter: "product_type"
    }
  )

  // Set the query search if search is truthy
  let querySearch = {}
  if (search) {
    querySearch = {
      [filter]: preserveProvider(search)
    }
  }

  // Shared Agency codes
  const { loading: sharedAgencyCodesLoading, error: sharedAgencyCodesError } = useGet({
    endpoint: "/users/:slug/inherited-agency-codes",
    skip: !get(selfServiceData, "slug", null),
    params: {
      slug: get(selfServiceData, "slug", "")
    },
    query: {
      limit: perPage,
      order_by: getOrderBy(sorting),
      pending: isPending,
      page,
      ...querySearch
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", {})
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("AGENCY_CODES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        sorting,
        page,
        perPage,
        total,
        sharedAgencyCodesLoading,
        search,
        setSearch,
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        pagination: { total, page, perPage },
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        filter,
        setFilter: val => dispatch({ type: "UPDATE_VALUE", key: "filter", value: val })
      }}
    >
      {children}
      <ApiError error={sharedAgencyCodesError} />
    </Provider>
  )
}

MyAccountSharedAgencyCodesProvider.defaultProps = {
  isPending: false,
  children: null
}

MyAccountSharedAgencyCodesProvider.propTypes = {
  isPending: PropTypes.bool,
  children: PropTypes.any
}

export default MyAccountSharedAgencyCodesProvider
