import React, { useEffect, useContext } from "react"
import { get, isEmpty, capitalize } from "lodash"
import { Address } from "@4cplatform/elements/Forms"
import moment from "moment"
import { JourneyContext } from "../../../../../../journey.context"

const AccountAddress = props => {
  const { formik, updateJourneyAuditData } = useContext(JourneyContext)

  const address = get(formik, "values.address", null)
  const dob = moment(get(formik, "values.date_of_birth")).format("DD/MM/YYYY")
  const gender = capitalize(get(formik, "values.gender_at_birth"))
  const title = capitalize(get(formik, "values.title"))

  useEffect(() => {
    if (!isEmpty(address)) {
      updateJourneyAuditData([
        {
          mode: "insert",
          name: "address",
          deleteCount: 1,
          data: [
            {
              name: "Address",
              value: [
                get(address, "line_one", ""),
                get(address, "line_two", ""),
                get(address, "city", ""),
                get(address, "county", ""),
                get(address, "postcode", "")
              ]
                .filter(adr => !!adr)
                .join(", ")
            }
          ]
        },
        {
          mode: "insert",
          name: "Date of birth",
          deleteCount: 1,
          data: [
            {
              name: "Date of birth",
              value: dob
            }
          ]
        },
        {
          mode: "insert",
          name: "Gender at birth",
          deleteCount: 1,
          data: [
            {
              name: "Gender at birth",
              value: gender
            }
          ]
        },
        {
          mode: "insert",
          name: "Title",
          deleteCount: 1,
          data: [
            {
              name: "Title",
              value: title
            }
          ]
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return <Address {...props} />
}

export default AccountAddress
