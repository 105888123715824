import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { cloneDeep, differenceBy } from "lodash"
import { useGet } from "@4cplatform/elements/Api"
import { Button } from "@4cplatform/elements/Molecules"
import { useTranslations } from "@4cplatform/elements/Translations"
import { H4 } from "@4cplatform/elements/Typography"
import { Select } from "@4cplatform/elements/Forms"
import { ConditionTypeHeadWrapper, FlexColumn } from "./miReports.style"
import { Typeahead } from "../../Forms"

const FilterItem = ({
  api,
  type,
  title,
  formik,
  queries,
  onSelect,
  formikKey,
  resultItems,
  keywordSearch,
  renderOption
}) => {
  const [keyword, setKeyword] = useState("")
  const [focusedIndex, setFocusedIndex] = useState(-1)
  const t = useTranslations()

  const handleBtnAddClick = useCallback(() => {
    if (!formik.values[formikKey].some(o => !o.key)) {
      if (type === "condition_typeahead") {
        formik.setFieldValue(formikKey, [
          ...formik.values[formikKey],
          { condition: "included", key: "", value: "" }
        ])
      } else {
        formik.setFieldValue(formikKey, [...formik.values[formikKey], { key: "", value: "" }])
      }
    }
  }, [formik, formikKey, type])

  const { data: responseArray, loading } = useGet({
    endpoint: api,
    query: {
      page: 1,
      limit: 10,
      [keywordSearch]: keyword,
      ...queries
    },
    skip: !keyword || !api
  })

  return (
    <div>
      <H4 appearance="light" margin="0 0 1.5rem 0">
        {title}
      </H4>
      <FlexColumn>
        {formik.values[formikKey]?.map((item, index) => {
          const currentItemsArray = cloneDeep(formik.values[formikKey])
          const currentItem = currentItemsArray[index]
          const isInputFocused = focusedIndex === index

          const currentItemFormValue = currentItem.value
          const currentItemConditionValue = currentItem.condition
          if (type === "condition_typeahead") {
            const tempResponseArray = responseArray?.map(
              renderOption ||
                (({ slug, name }) => ({
                  id: slug,
                  label: name
                }))
            )

            let filterResponseArray = tempResponseArray
            if (tempResponseArray && currentItemsArray) {
              const conditionKeys = currentItemsArray
                .filter(filterItem => filterItem.key)
                .map(condition => ({ id: condition.key }))

              filterResponseArray = differenceBy(tempResponseArray, conditionKeys, "id")
            }
            return (
              <ConditionTypeHeadWrapper>
                {type === "condition_typeahead" && (
                  <Select
                    margin="0"
                    className="condition-select"
                    name={`condition-${title}-${formikKey}-${index}`}
                    key={`condition-${title}-${formikKey}-${index}`}
                    value={currentItemConditionValue}
                    onChange={val => {
                      currentItem.condition = val
                      formik.setFieldValue(formikKey, currentItemsArray)
                      onSelect && onSelect(currentItemsArray)
                    }}
                  >
                    <option value="included">Include</option>
                    <option value="not included">Exclude</option>
                  </Select>
                )}
                <Typeahead
                  margin="0"
                  key={`${title}-${formikKey}-${index}`}
                  name={`${title}-${formikKey}-${index}`}
                  value={currentItemFormValue}
                  placeholder="Input Text"
                  suggestions={filterResponseArray}
                  isLoading={loading && isInputFocused}
                  hasCancel={currentItemFormValue || keyword}
                  onChange={setKeyword}
                  onCancel={() => {
                    currentItem.key = ""
                    currentItem.value = ""
                    formik.setFieldValue(formikKey, currentItemsArray)
                    setKeyword("")
                  }}
                  isDefaultOpen={isInputFocused}
                  onSelect={obj => {
                    currentItem.key = obj.id
                    currentItem.value = obj.label
                    formik.setFieldValue(formikKey, currentItemsArray)
                    onSelect && onSelect(currentItemsArray)
                  }}
                  onFocus={() => setFocusedIndex(index)}
                  onBlur={() => setFocusedIndex(-1)}
                />
              </ConditionTypeHeadWrapper>
            )
          }
          if (type === "typeahead") {
            return (
              <Typeahead
                margin="0"
                key={`${title}-${formikKey}-${index}`}
                name={`${title}-${formikKey}-${index}`}
                value={currentItemFormValue}
                placeholder="Input Text"
                suggestions={responseArray?.map(
                  renderOption ||
                    (({ slug, name }) => ({
                      id: slug,
                      label: name
                    }))
                )}
                isLoading={loading && isInputFocused}
                hasCancel={currentItemFormValue || keyword}
                onChange={setKeyword}
                onCancel={() => {
                  currentItem.key = ""
                  currentItem.value = ""
                  formik.setFieldValue(formikKey, currentItemsArray)
                  setKeyword("")
                }}
                isDefaultOpen={isInputFocused}
                onSelect={obj => {
                  currentItem.key = obj.id
                  currentItem.value = obj.label
                  formik.setFieldValue(formikKey, currentItemsArray)
                  onSelect && onSelect(currentItemsArray)
                }}
                onFocus={() => setFocusedIndex(index)}
                onBlur={() => setFocusedIndex(-1)}
              />
            )
          }

          if (type === "select") {
            return (
              <Select
                margin="0"
                name={`${title}-${formikKey}-${index}`}
                key={`${title}-${formikKey}-${index}`}
                value={formik.values[formikKey][index]?.key ?? ""}
                onChange={val => {
                  const obj = resultItems?.find(o => o.key === val)
                  currentItemsArray[index] = obj
                  formik.setFieldValue(formikKey, currentItemsArray)
                  onSelect && onSelect(obj)
                }}
              >
                <option value="">Select type</option>
                {resultItems?.map(({ key, value }) => (
                  <option key={key} value={key}>
                    {t(value)}
                  </option>
                ))}
              </Select>
            )
          }

          return null
        })}
        <Button
          iconSize="1rem"
          leadingIcon="plus"
          margin="0 auto 0 0"
          type="inline-button"
          appearance="whiteInline"
          onClick={handleBtnAddClick}
        >
          More filter options
        </Button>
      </FlexColumn>
    </div>
  )
}

FilterItem.defaultProps = {
  type: "typeahead",
  api: "",
  title: "",
  formik: {},
  queries: {},
  formikKey: "",
  onSelect: null,
  resultItems: [],
  keywordSearch: "name",
  renderOption: null
}

FilterItem.propTypes = {
  api: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  formik: PropTypes.object,
  onSelect: PropTypes.func,
  queries: PropTypes.object,
  formikKey: PropTypes.string,
  renderOption: PropTypes.func,
  keywordSearch: PropTypes.string,
  resultItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  )
}

export default FilterItem
