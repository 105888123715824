export const getStatus = status => {
  switch (status) {
    case "":
      return []
    case "SOLD":
      return ["ACCEPTED", "AWAITING_ACCEPTANCE"]
    default:
      return [status]
  }
}
