import React, { useContext, useMemo } from "react"
import { isEmpty } from "lodash"
import styled from "styled-components"

// Helpers
import { JourneyContext } from "../../../../../../journey.context"

const ApplicationFormMessage = () => {
  const { formik } = useContext(JourneyContext)

  const isError = useMemo(
    () => formik.submitCount >= 1 && !isEmpty(formik.errors),
    [formik.errors, formik.submitCount]
  )

  return (
    <Message data-testid="paper_application_form_error_message">
      {isError && (
        <>
          It is not possible to continue with the sales journey if the user has not confirmed that
          they have received an application form from the client and that it is completed correctly
        </>
      )}
    </Message>
  )
}

const Message = styled.p`
  min-height: 40px;
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #cc0135;
`

export default ApplicationFormMessage
