import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useGet, usePatch, ApiError } from "@4cplatform/elements/Api"
import { PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { Provider } from "../../../../../UI/Templates/NetworkOrganisations"
import { NetworkManageContext } from "../../../context/manage.context"
import reducer from "./members.reducer"
import { getOrderBy } from "../../../../../UI/Helpers"

const NetworkMembersProvider = ({ children }) => {
  const { network, networkLoading } = React.useContext(NetworkManageContext)
  const { resetPanel } = React.useContext(PageContext)
  const t = useTranslations()

  // State
  const [
    { page, perPage, search, selectedOrganisation, sorting, total, data, viewData },
    dispatch
  ] = React.useReducer(reducer, {
    page: 1,
    perPage: 5,
    search: "",
    selectedOrganisation: null,
    sorting: { direction: "asc", dataKey: "name" },
    viewData: {}
  })

  // Index Network Organisations
  const {
    loading,
    error: queryError,
    refetch
  } = useGet({
    endpoint: "/networks/:slug/organisations",
    skip: networkLoading || !get(network, "slug", null),
    params: {
      slug: get(network, "slug")
    },
    query: {
      order_by: getOrderBy(sorting),
      member_organisations: true
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    }
  })

  // Remove Network Organisation
  const [remove, { loading: removeLoading, error: removeError }] = usePatch({
    endpoint: "/networks/:slug/organisations/:organisation/remove",
    params: {
      slug: get(network, "slug"),
      organisation: get(selectedOrganisation, "slug")
    },
    onCompleted: () => {
      addAlert({
        type: "success",
        message: "Organisation successfully removed from the network",
        dismissible: true,
        timeout: 5
      })
      resetPanel()
      refetch()
    }
  })

  // Organisation data query
  const {
    loading: viewLoading,
    error: viewError,
    refetch: viewRefetch
  } = useGet({
    endpoint: "/organisations/:organisation",
    skip: !selectedOrganisation,
    params: {
      organisation: get(selectedOrganisation, "slug", "")
    },
    query: {
      with: ["address"]
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: newData })
    },
    onError: () => {
      addAlert({
        message: t("ORGANISATION_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  return (
    <Provider
      value={{
        data,
        network,
        queryLoading: loading || networkLoading,
        selectedOrganisation,
        onOrganisationSelect: row =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedOrganisation", value: row }),
        search,
        setSearch: val => {
          dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
          dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
        },
        sorting,
        pagination: { total, page, perPage },
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        onRemoveOrganisation: () => remove(),
        removeLoading,
        viewLoading,
        viewRefetch,
        viewData
      }}
    >
      {children}
      <ApiError error={queryError || removeError || viewError} />
    </Provider>
  )
}

NetworkMembersProvider.propTypes = {
  children: PropTypes.any
}

export default NetworkMembersProvider
