import styled, { css } from "styled-components"
import { Button } from "@4cplatform/elements/Molecules"
import { A } from "@4cplatform/elements/Typography"
import { get } from "lodash"

// Helpers
import { HEADER_HEIGHT_OFFSET } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/quotationSummary.helpers"

export const StyledButton = styled(Button)`
  font-size: 1.4rem;
`

export const Strong = styled.span`
  font-weight: bold;
`

export const ALink = styled(A)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${({ theme, colour }) =>
    colour ? get(theme, colour, get(theme, "blue", "blue")) : get(theme, "blue", "blue")};
`
export const ALabel = styled.span`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #8cbed1;
  cursor: not-allowed;

  svg {
    opacity: 50%;
  }
`
export const ALinkDummy = styled.span`
  display: flex;
  cursor: not-allowed;
  align-items: center;
  color: ${({ theme, colour }) =>
    colour ? get(theme, colour, "blue") : get(theme, "blue", "blue")};

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        opacity: 0.5;
      `
    }
  }}
`

export const QuotationSummaryTableWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-top: 1rem;
`

export const TableColumn = styled.div`
  width: 25rem;
  flex-basis: 25rem;
  flex-grow: 0;
  flex-shrink: 0;

  &:not(:last-child) {
    border-right: ${({ theme }) =>
      `1px solid ${get(theme, "tints.secondary.darkBlue.t70", "black")}`};
  }

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        opacity: 0.5;
      `
    }
  }}
`
export const TableCell = styled.div`
  font-size: 1.4rem;
  color: ${({ theme, colour }) =>
    colour
      ? get(theme, colour, get(theme, "tints.secondary.darkBlue.t20", "black"))
      : get(theme, "tints.secondary.darkBlue.t20", "black")};
  display: flex;
  justify-content: center;

  ${({ noFlex }) => {
    if (noFlex) {
      return css`
        display: block;
      `
    }
  }}

  ${({ flexColumn }) => {
    if (flexColumn) {
      return css`
        flex-direction: column;
        align-items: center;
      `
    }
  }}

  ${({ textTransform }) => {
    if (textTransform) {
      return css`
        text-transform: ${textTransform} !important;
      `
    }
  }}
`

export const TableRow = styled.div`
  padding: ${({ padding }) => padding || "0.6rem 1.2rem"};
  display: flex;
  width: 100%;
  flex-wrap: none;
  position: relative;
  overflow-x: hidden;

  background-color: ${({ theme, bgColour }) =>
    bgColour ? get(theme, bgColour, "white") : get(theme, "white", "white")};
  align-items: ${({ vAlign }) => vAlign || "center"};
  text-align: center;
  min-height: ${({ minHeight }) => minHeight || "5.5rem"};

  ${({ borderColour }) => {
    if (borderColour) {
      return css`
        border-bottom: ${({ theme }) => `1px solid ${get(theme, borderColour, "black")}`};
      `
    }

    return css`
      border-bottom: ${({ theme }) => `1px solid ${get(theme, "faintGrey", "black")}`};
    `
  }}

  ${({ isQuoteSelected }) => {
    if (isQuoteSelected) {
      return css`
        border-left: ${({ theme }) => `2px solid ${get(theme, "green", "green")}`};
        border-right: ${({ theme }) => `2px solid ${get(theme, "green", "green")}`};
      `
    }
  }}

  

  ${({ hasMultiCols }) => {
    if (hasMultiCols) {
      return css`
        padding: 0;

        ${TableCell} {
          padding: 0 1.2rem;
          min-height: 4.9rem;
          align-items: center;

          & + ${TableCell} {
            border-left: ${({ theme }) => `1px solid ${get(theme, "faintGrey", "black")}`};
          }
        }
      `
    }
  }}
  
  ${({ scrollableCol }) => {
    if (scrollableCol) {
      return css`
        overflow-y: auto;
        padding: 0rem 1rem;
        ${TableCell} {
          max-height: 5.4rem;
        }
      `
    }
  }}
`

export const TableHeaderRows = styled.div`
  border-bottom: ${({ theme }) =>
    `1px solid ${get(theme, "tints.secondary.darkBlue.t70", "black")}`};
  ${TableRow} {
    &:last-child {
      border-bottom: 0;
    }
  }

  ${({ isHeaderFixed }) => {
    if (isHeaderFixed) {
      return css`
        position: fixed;
        top: ${HEADER_HEIGHT_OFFSET}px;
        margin-left: -1px;
        margin-right: -1px;
        padding-top: calc(3rem + 21px);
        z-index: 9999;
        width: 201px;
        background-color: ${({ theme }) => get(theme, "white", "white")};
        border-right: ${({ theme }) =>
          `1px solid ${get(theme, "tints.secondary.darkBlue.t70", "black")}`};

        ${TableRow} {
          border-left: ${({ theme }) =>
            `1px solid ${get(theme, "tints.secondary.darkBlue.t70", "black")}`};

          &:first-of-type {
            border-radius: 0.5rem 0 0 0;
            border-top: ${({ theme }) =>
              `1px solid ${get(theme, "tints.secondary.darkBlue.t70", "black")}`};
          }
        }
      `
    }
  }}
`

// Table aside

export const TableAsideWrapper = styled.div`
  width: 20rem;
  flex-basis: 20rem;
  flex-grow: 0;
  flex-shrink: 0;
  border: ${({ theme }) => `1px solid ${get(theme, "tints.secondary.darkBlue.t70", "black")}`};
  border-right: 0;
  overflow: hidden;
  border-radius: 0.5rem 0 0 0.5rem;
  padding-bottom: var(--qs-scrollbar-height, 0);

  ${({ isHeaderFixed }) => {
    if (isHeaderFixed) {
      return css`
        border-radius: 0 0 0 0.5rem;
      `
    }
  }}

  ${TableRow} {
    text-align: left;
  }

  ${TableCell} {
    font-weight: 600;
  }
`

// Table content

export const TableMainWrapper = styled.div`
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  border: ${({ theme }) => `1px solid ${get(theme, "tints.secondary.darkBlue.t70", "black")}`};
  overflow-x: scroll;

  ${TableHeaderRows} {
    ${TableRow} {
      &:first-of-type {
        padding-top: 2rem;
        border-bottom: 0;
      }
    }
  }
`

export const TableHeaderColumnsWrapper = styled.div`
  display: flex;

  ${TableRow} {
    justify-content: center;
  }

  ${({ isHeaderFixed }) => {
    if (isHeaderFixed) {
      return css`
        border-radius: 0.5rem 0.5rem 0 0;
        overflow-x: scroll;
        position: fixed;
        z-index: 2;
        top: ${HEADER_HEIGHT_OFFSET}px;

        &::-webkit-scrollbar {
          display: none;
        }
      `
    }
  }}
`

export const TableBodyColumnsWrapper = styled.div`
  display: flex;

  ${TableRow} {
    justify-content: center;
  }
`

export const TableHeaderPlaceholder = styled.div`
  display: none;

  ${({ isVisible, pageGutterWidth }) => {
    if (isVisible) {
      return css`
        display: flex;

        &:before {
          content: "";
          width: ${pageGutterWidth / 2}px;
          top: 0;
          right: 100%;
          background: ${({ theme }) => get(theme, "white", "white")};
          display: block;
          height: 100%;
          position: absolute;
          z-index: 3;
        }

        &:after {
          content: "";
          width: ${pageGutterWidth / 2}px;
          top: 0;
          left: 100%;
          background: ${({ theme }) => get(theme, "white", "white")};
          display: block;
          height: 100%;
          position: absolute;
          z-index: 3;
        }
      `
    }
  }}
`

export const QuoteProviderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 20rem;
`

export const IconWrapper = styled.div`
  margin-top: 20px;
  margin-right: 10px;
  cursor: pointer;
`
export const ProviderLogo = styled.img`
  margin-top: 20px;
  max-width: 100%;
  display: flex;
  object-fit: contain;
  background-color: ${({ theme }) => get(theme, "white", "white")};
  justify-self: center;

  ${({ size }) => {
    if (size) {
      return css`
        width: ${size};
        height: ${size};
      `
    }
  }}
`

export const QuoteReference = styled.span`
  word-break: break-all;
`

export const IndicatorWrapper = styled.div`
  display: flex;
  &:has(.strong-text) {
    line-height: 1.8rem;
  }
  p {
    text-align: center;
  }
  svg {
    margin-right: 0.4rem;
  }
  .quote-data {
    font-size: 1.4rem;
    overflow-y: auto;
    margin: 0;
    max-height: 4rem;
  }
`
export const QuoteProviderInfo = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
