import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"
import { Redirect } from "react-router-dom"
import { get, isEmpty, omit, pick, find, mapKeys, set } from "lodash"
import { H1, P } from "@4cplatform/elements/Typography"
import { usePost, useGet } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"
import { addAlert, clearAlerts } from "@4cplatform/elements/Alerts"

// Helpers
import { LEAD_ADMIN, DASHBOARD } from "../../config/pages"
import reducer from "./leadAdd.reducer"

// Components
import { Breadcrumbs } from "../../UI/Molecules"
import { LeadCreate } from "../../UI/Templates"
import { Wrapper } from "./leadAdd.styles"

const LeadAdd = ({ isQuickQuote, isSendEmail, setAddLeadOpen, initialValues }) => {
  const t = useTranslations()

  // State
  const [{ redirect, cancelOpen, importErrors, importOpen, config, apiErrors }, dispatch] =
    React.useReducer(reducer, {
      redirect: null,
      cancelOpen: false,
      importErrors: [],
      importOpen: false,
      config: {},
      apiErrors: {}
    })

  // Leads Config
  const { loading: configLoading } = useGet({
    endpoint: "/lead-config",
    onCompleted: res => {
      const cfg = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "config", value: cfg })
    },
    onError: () => {
      addAlert({
        message: t("LEAD_CONFIG_FETCH_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Create Lead request
  const [create, { loading }] = usePost({
    endpoint: isQuickQuote ? "/quick-quote/add-lead" : "/leads",
    onCompleted: () => {
      // Display alert and set redirect
      addAlert({
        message: isSendEmail ? t("LEAD_ADD_SEND_EMAIL_SUCCESS") : t("LEAD_ADD_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })

      dispatch({ type: "UPDATE_VALUE", key: "redirect", value: LEAD_ADMIN.path })
    },
    onError: err => {
      if (isEmpty(err.validation)) {
        addAlert({
          message: t("LEAD_ADD_ERROR"),
          type: "error",
          dismissible: true,
          timeout: 5
        })
      } else if (isQuickQuote) {
        const filterValidation = mapKeys(get(err, "validation"), (value, key) =>
          key.replace(/^lead\./, "")
        )

        set(err, "validation", filterValidation)
        dispatch({ type: "UPDATE_VALUE", key: "apiErrors", value: err })
      } else {
        dispatch({ type: "UPDATE_VALUE", key: "apiErrors", value: err })
      }
    }
  })

  // Import Lead request
  const [importLead, { loading: importLoading }] = usePost({
    endpoint: "/lead-import",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onCompleted: response => {
      const { succeeded, failed } = get(response, "data", { succeeded: 0, failed: 0 })
      addAlert({
        message: (
          <>
            <div>{succeeded} lead(s) successfully imported</div>
            <div>{failed} lead(s) which failed to be imported</div>
            <div>You have been sent an email containing full details of the imported lead(s).</div>
          </>
        ),
        type: "info"
      })
      dispatch({ type: "CLEAR_IMPORT_MODAL" })
    },
    onError: err => {
      addAlert({
        message: t("LEAD_IMPORT_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })

      const validationErrors = get(err, "validation.file", [])

      // If something went wrong but it wasn't a validation error, close the modal
      // Otherwise, store errors in state and display them to the user
      if (isEmpty(validationErrors)) {
        dispatch({ type: "UPDATE_VALUE", key: "importOpen", value: false })
      } else {
        dispatch({ type: "UPDATE_VALUE", key: "importErrors", value: validationErrors })
      }
    }
  })

  const handleSubmit = body => {
    if (isQuickQuote) {
      const applicants = get(body, "applicants", [])
      const primaryApplicant = find(applicants, { type: "primary" })

      create({
        body: {
          payment_frequency: get(body, "payment_frequency"),
          send_as_email: get(body, "send_as_email"),
          lead: omit(body, ["payment_frequency", "applicants", "send_as_email", "quotes"]),
          quotes: get(body, "quotes", []).map(quote => ({
            ...get(quote, "data", {}),
            type: get(quote, "quoteType"),
            provider: pick(get(quote, "data.provider"), ["name", "logo"])
          })),
          applicants: [
            pick(primaryApplicant, ["date_of_birth"]),
            ...applicants
              .filter(applicant => applicant.type !== "primary")
              .map(applicant => pick(applicant, "date_of_birth"))
          ]
        }
      })
    } else {
      create({ body })
    }
  }

  // Redirect if truthy
  if (redirect) return <Redirect to={redirect} />

  return (
    <>
      {!isQuickQuote && (
        <Helmet>
          <title>Add lead</title>
        </Helmet>
      )}
      <Wrapper isPageContainer width="56rem" isQuickQuote={isQuickQuote}>
        {!isQuickQuote && (
          <>
            <Breadcrumbs
              trail={[
                { label: "Dashboard", link: DASHBOARD.path },
                { label: "Leads", link: LEAD_ADMIN.path },
                { label: "Add lead" }
              ]}
            />
            <H1 margin="0 0 6rem">Add lead</H1>
          </>
        )}

        {/* Lead Creation form */}
        <LeadCreate
          initialValues={initialValues}
          hasImport={!isQuickQuote}
          onSubmit={handleSubmit}
          isLoading={loading || configLoading}
          onCancel={() => dispatch({ type: "UPDATE_VALUE", key: "cancelOpen", value: true })}
          config={config}
          isOpenImport={importOpen}
          setImportOpen={val => dispatch({ type: "UPDATE_VALUE", key: "importOpen", value: val })}
          clearImport={() => dispatch({ type: "CLEAR_IMPORT_MODAL" })}
          onBack={() => {
            clearAlerts()
            dispatch({ type: "UPDATE_VALUE", key: "redirect", value: LEAD_ADMIN.path })
          }}
          handleClearFile={() => dispatch({ type: "CLEAR_FILE_IMPORT_MODAL" })}
          onImportSubmit={body => {
            const file = get(body, "file[0]", null)
            const data = new FormData()
            data.append("file", file, file.name)

            importLead({ body: data })
          }}
          isLoadingImport={importLoading}
          errors={importErrors}
          apiErrors={apiErrors}
        />

        {/* Cancel add confirmation dialog */}
        {cancelOpen && (
          <ConfirmationModal
            confirmIcon="cancel"
            confirmText="Yes"
            confirmAppearance="error"
            cancelAppearance="errorGhost"
            onClose={() => dispatch({ type: "UPDATE_VALUE", key: "cancelOpen", value: false })}
            onConfirm={() => {
              if (isQuickQuote) {
                setAddLeadOpen(false)
              } else {
                dispatch({
                  type: "UPDATE_VALUE",
                  key: "redirect",
                  value: LEAD_ADMIN.path
                })
              }
            }}
          >
            <P>Are you sure you want to cancel this action and return to the Leads page?</P>
          </ConfirmationModal>
        )}
      </Wrapper>
    </>
  )
}

LeadAdd.defaultProps = {
  isQuickQuote: false,
  isSendEmail: false,
  setAddLeadOpen: null,
  initialValues: {}
}

LeadAdd.propTypes = {
  isQuickQuote: PropTypes.bool,
  isSendEmail: PropTypes.bool,
  setAddLeadOpen: PropTypes.func,
  initialValues: PropTypes.object
}

export default LeadAdd
