import styled, { css } from "styled-components"
import { get } from "lodash"
import { Skeleton } from "@4cplatform/elements/Molecules"

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 1.5rem 0;
  padding: 1rem 1.5rem;
  border: 1px solid #b2bec0;
  border-radius: 0.3rem 0.3rem 0 0;
  box-shadow: 0 0 10px 0 rgba(0, 34, 43, 0.05);
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 2rem;
  background-color: ${({ appearance, theme }) =>
    appearance === "light" ? get(theme, "white") : "#4797B6"};
`

export const Wrapper = styled.div`
  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        opacity: 0.3;
      `
    }
  }}
`

export const LogoSkeleton = styled(Skeleton)`
  margin-right: 2rem;

  ${({ loadingMargin }) => {
    if (loadingMargin) {
      return css`
        margin: ${loadingMargin};
      `
    }
  }}
`

export const Logo = styled.img`
  max-width: 100%;
  object-fit: contain;
  background: ${({ theme, src }) =>
    !src || src.includes("https://") ? "transparent" : get(theme, "faintGrey", "lightgray")};
  margin-right: 2rem;
  ${({ size }) => {
    if (size) {
      return css`
        width: ${size};
        height: ${size};
      `
    }
  }}
`
