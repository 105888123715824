import styled, { css } from "styled-components"
import { get } from "lodash"
import { Button, Skeleton } from "@4cplatform/elements/Molecules"

export const PanelWrapper = styled.div`
  padding: 3rem;
`

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

export const TableActionsRight = styled.div`
  display: flex;
`

export const TableActionsButton = styled(Button)`
  height: 5rem;
  width: 17.5rem;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export const PanelBodyWrapper = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${get(theme, "tints.primary.blue.t20")}`};
  margin-bottom: 2rem;
`

export const LogoSkeleton = styled(Skeleton)`
  margin-right: 2rem;

  ${({ loadingMargin }) => {
    if (loadingMargin) {
      return css`
        margin: ${loadingMargin};
      `
    }
  }}
`

export const Logo = styled.img`
  max-width: 100%;
  object-fit: contain;
  background: ${({ theme, src }) =>
    !src || src.includes("https://") ? "transparent" : get(theme, "faintGrey", "lightgray")};
  margin-right: 2rem;
  ${({ size }) => {
    if (size) {
      return css`
        width: ${size};
        height: ${size};
      `
    }
  }}
`
