import React, { useContext, useEffect } from "react"
import { PageContext } from "@4cplatform/elements/Organisms"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"
import PropTypes from "prop-types"

// Components
import ViewAgencyCode from "./agencyCodes.panel.view"
import { AgencyCodesContext } from "../agencyCodes.context"

const AgencyCodesPanel = ({ pendingType }) => {
  const {
    panelStatusControls: { panelStatus }
  } = useContext(PageContext)
  const { setBodyTabId } = useContext(AgencyCodesContext)

  useEffect(() => {
    if (panelStatus === "closed" && setBodyTabId) setBodyTabId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelStatus])

  return (
    <FlyOutPanel
      body={() => <ViewAgencyCode pendingType={pendingType} />}
      name="agency_codes_panel"
    />
  )
}

AgencyCodesPanel.defaultProps = {
  pendingType: "false"
}

AgencyCodesPanel.propTypes = {
  pendingType: PropTypes.string
}

export default AgencyCodesPanel
