import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get } from "lodash"
import { SmallText } from "@4cplatform/elements/Typography"

// Components
import { DisclosureWrapper, DisclosureBody, DisclosureHeader, FlexRow } from "./policies.styles"

const questions = {
  // Switch Disclosures
  symptoms_consultations_tests_treatments_last_12_months:
    "Have you or any person to be covered by this policy experienced symptoms or had any consultations, diagnostic tests or treatment in the last 12 months or do you have appointments planned with a GP, specialist, or a hospital in the future?",
  consultations_tests_treatments_last_5_years_relating_to_cancer:
    "Have you or any person to be covered by this policy had any consultations, test, medication or treatment in the last 5 years relating to any type of cancer or suspected cancer (if the consultations or tests are part or routine NHS screening programmes and resulted in no further action then you do not need to tick yes to this question)?",
  consultations_tests_treatments_last_5_years_relating_to_heart_conditions:
    "Have you or any person to be covered by this policy had any consultations, test, medication or treatment in the last 5 years relating to any form of heart, circulatory or vascular conditions or symptoms (including stroke, hypertension and raised cholesterol), or diabetes?",
  consultations_tests_treatments_last_5_years_relating_to_mental_illness:
    "Have you or any person to be covered by this policy had any consultations, test, medication or treatment in the last 5 years relating to any psychiatric or mental illness or conditions (only relevant if selecting the mental health option)?"
}

const AvivaDisclosure = ({ data }) => (
  <DisclosureWrapper>
    <DisclosureHeader>
      <SmallText appearance="light" margin="0">
        {get(questions, `${data.field}`)}
      </SmallText>
    </DisclosureHeader>
    <DisclosureBody>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Condition / symptoms:
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.conditions_or_symptoms}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Date of consultation:
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.date_of_consultation
            ? moment.utc(data.date_of_consultation).local().format("DD/MM/YYYY")
            : "-"}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Date of last symptoms / treatment:
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.date_of_last_symptom_or_treatment
            ? moment.utc(data.date_of_last_symptom_or_treatment).local().format("DD/MM/YYYY")
            : "-"}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Treatment received:
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.treatment_received}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Present state of health:
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.present_state_of_health}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Further consultation needed?
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.foreseeable_consultation_or_treatment ? "Yes" : "No"}
        </SmallText>
      </FlexRow>
    </DisclosureBody>
  </DisclosureWrapper>
)

AvivaDisclosure.propTypes = {
  data: PropTypes.object.isRequired
}

export default AvivaDisclosure
