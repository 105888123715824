import React from "react"
import { get } from "lodash"
import moment from "moment"
import { Table } from "@4cplatform/elements/Organisms"
import { colours } from "@4cplatform/elements/Helpers"

// Helpers
import { QuickQuoteLogContext } from "./quickQuoteLog.context"
import { getName } from "../../Helpers"

// Components
import Actions from "./quickQuoteLog.actions"

const QuickQuoteLogAdmin = () => {
  const { data, queryLoading, pagination, setPage, setPerPage, onSort, sorting } =
    React.useContext(QuickQuoteLogContext)

  return (
    <>
      <Actions />
      <Table
        data={data}
        isLoading={queryLoading}
        name="quick-quote-log-table"
        columns={[
          {
            label: "Created",
            dataKey: "created_at",
            minWidth: "150px",
            sortable: true,
            render: row => {
              const createdAt = get(row, "data.created_at", "")
              return createdAt ? moment.utc(createdAt).local().format("DD/MM/YYYY HH:mm:ss") : ""
            }
          },
          {
            label: "Client names",
            dataKey: "client",
            minWidth: "60px",
            fieldColour: get(colours, "blue", "blue"),
            sortable: true
          },
          {
            label: "Date of Birth",
            dataKey: "date_of_birth",
            minWidth: "150px",
            sortable: true
          },
          {
            label: "Sales Organisation name",
            dataKey: "organisation",
            minWidth: "150px",
            sortable: true,
            render: row => get(row, "data.sales_agent.parent.name", "")
          },
          {
            label: "Sales Agent",
            dataKey: "sales_agent",
            minWidth: "150px",
            sortable: true,
            render: row => getName({ data: get(row, "data.sales_agent", "") })
          }
        ]}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        sorting={sorting}
      />
    </>
  )
}

export default QuickQuoteLogAdmin
