import React, { useContext } from "react"
import { Select } from "@4cplatform/elements/Forms"
import { AuthWrapper } from "@4cplatform/elements/Auth"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"

// Components
import { Search } from "../../../Molecules"
import { TableActionsWrapper } from "./agencyCodes.styles"

const Actions = () => {
  const { filter, setFilter, setSearch, search } = useContext(AgencyCodesContext)

  return (
    <TableActionsWrapper data-testid="agency_codes-actions-wrapper">
      <AuthWrapper roles={["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN"]}>
        <Select
          name="filter_search"
          onChange={val => setFilter(val)}
          margin="0 1rem 0 0"
          value={filter}
          label="Search by"
          labelWidth="auto"
          isHorizontal
        >
          <option value="product">Product</option>
          <AuthWrapper roles={["SYS_ADMIN", "SUPPORT_ADMIN"]}>
            <option value="provider_name">Provider</option>
          </AuthWrapper>
          <option value="owner_name">Owner</option>
        </Select>
      </AuthWrapper>
      <Search
        name="search_agency_codes"
        handleChange={setSearch}
        onCancel={() => setSearch("")}
        margin="0"
        defaultValue={search}
      />
    </TableActionsWrapper>
  )
}

export default Actions
