import React, { useContext } from "react"
import PropTypes from "prop-types"
import { get, isEmpty, find, toUpper } from "lodash"
import { H3 } from "@4cplatform/elements/Typography"

// Helpers
import { QuickQuoteContext } from "./quickQuote.context"

// Components
import {
  QuoteGroupWrapper,
  QuoteGroupHeader,
  QuoteGroupBody,
  QuoteGroupLabels,
  QuoteGroupLabel,
  QuoteLevelsWrapper
} from "./quickQuote.styles"
import ProviderLogo from "./quickQuote.quotes.group.logo"
import QuickQuoteLevel from "./quickQuote.quotes.group.level"

const QuoteGroup = ({ product, quotes }) => {
  const { formik } = useContext(QuickQuoteContext)
  const logoSrc = get(quotes, "0.data.provider.logo", null)
  const providerName = get(quotes, "0.data.provider.name", null)

  return (
    <QuoteGroupWrapper>
      <QuoteGroupHeader>
        <ProviderLogo isLoading={!logoSrc} src={logoSrc} />
        <H3 margin="0">{`${providerName} - ${product}`}</H3>
      </QuoteGroupHeader>
      <QuoteGroupBody>
        <QuoteGroupLabels>
          <QuoteGroupLabel isSecond>{`Per ${
            get(formik, "values.payment_frequency", "MONTHLY") === "MONTHLY" ? "month" : "year"
          }`}</QuoteGroupLabel>
          <QuoteGroupLabel>Cancer</QuoteGroupLabel>
          <QuoteGroupLabel>Excess</QuoteGroupLabel>
          <QuoteGroupLabel>Hospital list</QuoteGroupLabel>
          <QuoteGroupLabel>Outpatient limit</QuoteGroupLabel>
          <QuoteGroupLabel>Underwriting</QuoteGroupLabel>
        </QuoteGroupLabels>
        <QuoteLevelsWrapper>
          {["basic", "standard", "comprehensive"].map(level => {
            const levelData = find(quotes, ["quoteType", `QQ_${toUpper(level)}`])
            return (
              <QuickQuoteLevel
                key={level}
                level={level}
                data={!isEmpty(levelData) ? levelData : {}}
              />
            )
          })}
        </QuoteLevelsWrapper>
      </QuoteGroupBody>
    </QuoteGroupWrapper>
  )
}

QuoteGroup.propTypes = {
  product: PropTypes.string.isRequired,
  quotes: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default QuoteGroup
