import React from "react"
import { useLocation } from "react-router-dom"
import { A } from "@4cplatform/elements/Typography"

/**
 *  A helper function for generating anchor links. Returns <A /> component.
 * @param {string} type
 * @param {string} href
 * @param {string} appearance
 */
export const renderA = (type = "url", href = "", appearance = "dark") => {
  let hrefPrefix = ""
  if (type === "phone") hrefPrefix = "tel:"
  if (type === "email") hrefPrefix = "mailto:"

  return href ? (
    <A
      target="_blank"
      fontSize="1.6rem"
      href={`${hrefPrefix}${href}`}
      appearance={appearance}
      style={{ wordBreak: "break-word" }}
    >
      {href}
    </A>
  ) : (
    "-"
  )
}

export const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
