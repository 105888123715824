import React, { useContext } from "react"
import { AuthContext } from "@4cplatform/elements/Auth"

// Helpers
import { AgencyCodesContext } from "../../agencyCodes.context"

// Components
import { TableActionsButton } from "./addRequestCode.styles"
import AddRequestModal from "./addRequestCode.modal"

const AddRequestAgencyCode = () => {
  const { addRequestModal, setAddRequestModal } = useContext(AgencyCodesContext)
  const { canAccess } = useContext(AuthContext)

  return (
    <>
      <TableActionsButton
        onClick={() => setAddRequestModal(true)}
        trailingIcon="account-plus"
        name="add_request_agency_code"
      >
        {canAccess(["ORG_ADMIN", "NETWORK_ADMIN", "NETWORK_MEMBER_ADMIN"])
          ? "Add/Request Agency Code"
          : "Add Agency Code"}
      </TableActionsButton>
      {addRequestModal && <AddRequestModal />}
    </>
  )
}

export default AddRequestAgencyCode
