import React from "react"
import { Helmet } from "react-helmet-async"
import { Container } from "@4cplatform/elements/Atoms"

// Components
import ReportProvider from "./context/reports.provider"
import { Breadcrumbs } from "../../UI/Molecules"
import { Reports as ReportTemplate, ReportFilterPanel } from "../../UI/Templates"
import { DASHBOARD } from "../../config/pages"

const Reports = () => (
  <>
    <Helmet>
      <title>Reports</title>
    </Helmet>
    <ReportProvider>
      <Container isPageContainer width="80%">
        <Breadcrumbs trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: "Reports" }]} />
        <ReportTemplate />
        <ReportFilterPanel />
      </Container>
    </ReportProvider>
  </>
)

export default Reports
