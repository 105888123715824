import OnboardPolicyActions from "./onboardPolicyActions"

export const config = () => ({
  title: "Onboard policy",
  sections: [
    {
      key: "main",
      components: [
        {
          key: "aviva_onboard_actions",
          component: OnboardPolicyActions,
          skipDataMap: true
        }
      ]
    }
  ]
})
