import PropTypes from "prop-types"
import { capitalize } from "lodash"
import { Label } from "@4cplatform/elements/Typography"
import { FlexAlignCenterSmallGap, FormGroup, Radio, RadioCheckMark } from "./miReports.style"

const Radios = ({ name, items, selectedKey, onChange, appearance, isLoading, isDisabled }) => (
  <FlexAlignCenterSmallGap>
    {items.map(key => (
      <FormGroup key={`radio-${name}-${key}`}>
        <Radio
          id={key}
          value={key}
          type="radio"
          name={name}
          checked={key === selectedKey}
          onChange={e => onChange && onChange(e.target.value)}
          disabled={isLoading || isDisabled}
        />
        <Label margin="0 0 0 .5rem" htmlFor={key} appearance={appearance}>
          <RadioCheckMark appearance={appearance} /> {capitalize(key)}
        </Label>
      </FormGroup>
    ))}
  </FlexAlignCenterSmallGap>
)

Radios.defaultProps = {
  name: "",
  onChange: null,
  appearance: "dark",
  selectedKey: "",
  items: [],
  isLoading: false,
  isDisabled: false
}

Radios.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  appearance: PropTypes.string,
  selectedKey: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default Radios
