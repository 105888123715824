import React from "react"
import PropTypes from "prop-types"

// Components
import AgencyCodesPanelHeader from "./agencyCodes.panel.header"
import AgencyCodesPanelBody from "./agencyCodes.panel.body"

const AgencyCodeView = ({ pendingType }) => (
  <>
    <AgencyCodesPanelHeader pendingType={pendingType} />
    <AgencyCodesPanelBody />
  </>
)

AgencyCodeView.defaultProps = {
  pendingType: "false"
}

AgencyCodeView.propTypes = {
  pendingType: PropTypes.string
}

export default AgencyCodeView
