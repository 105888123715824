import React from "react"
import { get } from "lodash"
import { boolean } from "yup"
import { H4, P } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import DisclosureNotes from "../../../../../../DisclosureNotes"
import NoteFormTemplate from "./noteFormTemplate"
import NoteDisplayTemplate from "./noteDisplayTemplate"
import Audit from "./Audit"

export const config = data => ({
  title: "Bupa FMU Disclosures",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          key: "compliance_note",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <H4 margin="0 0 1rem">Obtaining medical reports from a GP</H4>
                <P>
                  For any of the medical conditions or symptoms listed in questions 1 to 20 please
                  indicate if:
                </P>
                <P margin="0">
                  You or anyone to be covered on your membership has seen a GP or other healthcare
                  professional within the last two years.
                </P>
                <P>
                  You or anyone to be covered on your membership has been admitted to hospital, had
                  an operation OR any investigations (for example scan, X-ray, blood test, biopsy)
                  within the last seven years.
                </P>
              </>
            ),
            type: "info"
          },
          skipDataMap: true
        },
        ...[
          {
            key: "heart_or_cardiovascular_disorders",
            label:
              "Heart or cardiovascular disorders eg coronary artery disease, chest pains, circulation problems, varicose veins, high blood pressure, venous ulcers"
          },
          {
            key: "glandular_disorders",
            label: "Glandular disorders eg diabetes, thyroid, hormonal problems"
          },
          {
            key: "breathing_or_respiratory_disorders",
            label:
              "Breathing or respiratory disorders eg asthma, bronchitis, shortness of breath, chest infections, colds, flu"
          },
          {
            key: "ears_nose_throat_or_eye_problems",
            label:
              "Ears, nose, throat, or eye problems eg hayfever, tonsillitis, sinusitis, cataracts, eye infections, deafness, ear infections"
          },
          {
            key: "stomach_intestines_liver_or_gallbladder",
            label:
              "Stomach, intestines, liver or gallbladder eg ulcer, colitis, repeated indigestion, irritable bowel, change in bowel habits, hepatitis, piles, rectal bleeding"
          },
          {
            key: "cancer_tumours_growths_cysts_or_moles_that_itch_or_bleed",
            label: "Cancer, tumours, growths, cysts, or moles that itch or bleed"
          },
          {
            key: "skin_problems",
            label: "Skin problems eg eczema, rashes, psoriasis, acne"
          },
          {
            key: "brain_or_nervous_system_disorders",
            label:
              "Brain or nervous system disorders eg stroke, migraines, repeated headaches, MS, epilepsy, nerve pain, fits"
          },
          {
            key: "muscle_or_skeletal_problems",
            label:
              "Muscle or skeletal problems eg arthritis, cartilage and ligament problems, back and neck problems, sprains, joint replacements, gout, sciatica"
          },
          {
            key: "urinary_problems",
            label:
              "Urinary problems eg bladder, kidney or prostate problems, urinary infections, incontinence"
          },
          {
            key: "blood_disorders",
            label: "Blood disorders eg anaemia, hepatitis, HIV, abnormal blood tests"
          },
          {
            key: "reproductive_system_problems",
            label:
              "Reproductive system problems eg pregnancy and/or childbirth problems, heavy or irregular periods, fibroids, endometriosis, infertility, abnormal smears, menopause, caesarean section, low testosterone, erectile dysfunction, low sperm count"
          },
          {
            key: "dental_problems",
            label: "Dental problems eg wisdom teeth, abscess, gingivitis"
          },
          {
            key: "allergies",
            label: "Allergies eg hayfever, pet allergies, food allergies"
          },
          {
            key: "psychological_disorders",
            label:
              "Psychological disorders eg depression, schizophrenia, anorexia, bulimia, compulsive disorders, stress, anxiety"
          },
          {
            key: "undiagnosed_symptoms",
            label:
              "Undiagnosed symptoms eg chest pain, fatigue, weight loss, dizziness, joint pain, change in bowel habit, shortness of breath, abdominal pain, rectal bleeding, lumps"
          },
          {
            key: "applicant_member_taking_any_medicines_prescribed_or_otherwise",
            label: "Are you or any applicant/member taking any medicines, prescribed or otherwise?"
          },
          {
            key: "covered_experienced_symptoms",
            label:
              "Within the last three months has anyone to be covered experienced symptoms of ANY health problems for which medical advice has not yet been sought?"
          },
          {
            key: "past_history_of_joint_replacements_heart_conditions_or_strokes",
            label:
              "Has anyone to be covered EVER had any past history of joint replacements, heart conditions, or strokes?"
          },
          {
            key: "other_information_relating",
            label:
              "Is there any other information relating to your health that has not yet been prompted by the questions listed 1 to 19?"
          }
        ].map((item, i) => ({
          ...item,
          label: `${i + 1}. ${item.label}`,
          name: item.key,
          initialValue: get(data, `page.data.${item.key}`, false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          component: DisclosureNotes,
          componentProps: {
            options: [
              { order: 0, label: "No", value: false },
              { order: 1, label: "Yes", value: true }
            ],
            noteFormTemplate: NoteFormTemplate,
            noteDisplayTemplate: NoteDisplayTemplate,
            isHorizontal: true,
            isRequired: true
          }
        }))
      ]
    }
  ]
})
