/* eslint-disable react/display-name */
import React, { memo, forwardRef, useContext } from "react"
import PropTypes from "prop-types"

// Components
import {
  TableAsideWrapper,
  TableHeaderRows,
  TableRow,
  TableCell
} from "./quotationSummaryTable.styles"
import { QuotationSummaryContext } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/context/quotationSummary.context"

const QuotationSummaryTableAside = forwardRef(({ isHeaderFixed }, ref) => {
  const { selectedQuote } = useContext(QuotationSummaryContext)

  return (
    <TableAsideWrapper isHeaderFixed={isHeaderFixed}>
      <TableHeaderRows isHeaderFixed={isHeaderFixed} ref={ref}>
        <TableRow bgColour="veryFaintGrey">
          <TableCell>Product</TableCell>
        </TableRow>
        <TableRow bgColour="veryFaintGrey">
          <TableCell>Reference</TableCell>
        </TableRow>
        <TableRow bgColour="veryFaintGrey">
          <TableCell>Underwriting</TableCell>
        </TableRow>
      </TableHeaderRows>
      <TableRow>
        <TableCell>Type</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Members</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Start date</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Payment frequency</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Brochure</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Demands &amp; needs</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Hospital list</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Excess</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Outpatient limit</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Cancer cover</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Dental cover</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Therapies cover</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Psychiatric cover</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Travel cover</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Protected NCD</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Six Week Options</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Guided Care</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>In budget</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Monthly</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Yearly</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Push to portal</TableCell>
      </TableRow>
      <TableRow bgColour="veryFaintGrey" minHeight={selectedQuote ? "12.35rem" : null}>
        <TableCell>Complete Application / Purchase Policy</TableCell>
      </TableRow>
      <TableRow bgColour="veryFaintGrey">
        <TableCell>Edit quote</TableCell>
      </TableRow>
      <TableRow bgColour="veryFaintGrey" minHeight="9.35rem">
        <TableCell>Copy quote</TableCell>
      </TableRow>
      <TableRow bgColour="veryFaintGrey">
        <TableCell>Delete quote</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Network documents</TableCell>
      </TableRow>
    </TableAsideWrapper>
  )
})

QuotationSummaryTableAside.defaultProps = {
  isHeaderFixed: false
}

QuotationSummaryTableAside.propTypes = {
  isHeaderFixed: PropTypes.bool
}

export default memo(QuotationSummaryTableAside)
