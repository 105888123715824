import React, { useEffect, useState, useContext } from "react"
import { get, some } from "lodash"
import { Button, Modal, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { P, H2 } from "@4cplatform/elements/Typography"
import { formatLabelForTestID } from "@4cplatform/elements/Helpers"

// Helpers
import { ApplicantsContext } from "./applicants.context"
import { JourneyContext } from "../../../../../../journey.context"

// Components
import { ActionsWrapper } from "./applicants.styles"
import ApplicantForm from "./applicants.applicant.form"

const Actions = () => {
  const { applicantModal, setApplicantModal, applicants, activeTabID, updateActiveTabID } =
    useContext(ApplicantsContext)
  const { data } = useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)

  const applicantHasPartner =
    some(applicants, { type: "PARTNER" }) || some(applicants, { type: "partner" })

  const [helperTextValue, setHelperTextValue] = useState("")

  useEffect(() => {
    activeTabID === "axa_questions"
      ? setHelperTextValue(
          "<p>If you choose the moratorium option, there are some other specified conditions that we will not cover (as listed below).</p><p>If you have diabetes, treatment of the specified conditions below are also not covered:</p><ul><li>Diabetes</li><li>Coronary heart disease (ischaemic heart disease)</li><li>Cataracts</li><li>Disease to the retina in the eye caused by diabetes (diabetic retinopathy)</li><li>Disease in the kidneys caused by diabetes (diabetic renal disease)</li><li>Arterial disease</li><li>Stroke</li></ul><p>If you are having treatment for high blood pressure, treatment of the specified conditions below are also not covered:</p><ul><li>Raised blood pressure (hypertension)</li><li>Coronary heart disease (ischaemic heart disease)</li><li>Stroke</li><li>Kidney failure caused by high blood pressure (hypertensive renal failure)</li></ul><p>If you are under investigation, having treatment or are undergoing monitoring as a result of a Prostate Specific Antigen (PSA) test, we will not cover treatment of:</p><ul><li>Any prostate conditions</li></ul>"
        )
      : setHelperTextValue("")
  }, [activeTabID])

  const CustomTitle = () => (
    <H2
      helperTitle="AXA Coverage Details"
      helperText={helperTextValue}
      data-testid="custom-modal-title"
    >
      {applicantModal.isEdit ? "Edit applicant" : `Add ${get(applicantModal, "type", "applicant")}`}
    </H2>
  )
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  return (
    <>
      <ActionsWrapper>
        {!applicantHasPartner && (
          <Button
            trailingIcon="account-plus"
            margin="0 1rem 0 0"
            onClick={() => {
              updateActiveTabID(formatLabelForTestID("Personal details"))
              setApplicantModal({ type: "partner", isOpen: true, isEdit: false })
            }}
            isDisabled={isLocked}
          >
            Add partner
          </Button>
        )}
        <Button
          trailingIcon="account-plus"
          margin="0"
          onClick={() => {
            updateActiveTabID(formatLabelForTestID("Personal details"))
            setApplicantModal({ type: "dependant", isOpen: true, isEdit: false })
          }}
          isDisabled={isLocked}
        >
          Add dependant
        </Button>
      </ActionsWrapper>
      {/* Add applicant */}
      {applicantModal.isOpen && (
        <Modal
          name="add-partner"
          CustomTitle={CustomTitle}
          onClose={() => {
            if (isFormDirty) {
              setIsConfirmationModalOpen(true)
            } else {
              setApplicantModal({ type: null, isOpen: false, isEdit: false, applicant: null })
            }
          }}
        >
          <ApplicantForm
            type={
              applicantModal.applicant
                ? get(applicantModal, "applicant.type")
                : get(applicantModal, "type")
            }
            setIsFormDirty={setIsFormDirty}
            isFormDirty={isFormDirty}
            isEdit={applicantModal.isEdit}
            applicant={applicantModal.applicant}
          />
        </Modal>
      )}

      {isConfirmationModalOpen && (
        <ConfirmationModal
          confirmIcon="cancel"
          confirmText="Yes"
          confirmAppearance="error"
          cancelAppearance="errorGhost"
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={() => {
            setIsConfirmationModalOpen(false)
            setApplicantModal({ type: null, isOpen: false, isEdit: false, applicant: null })
          }}
        >
          <P>
            Are you sure you want to exit? Unsaved changes will be lost after exiting the modal!
          </P>
        </ConfirmationModal>
      )}
    </>
  )
}

export default Actions
