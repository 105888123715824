import styled, { css } from "styled-components"
import { transparentize } from "polished"
import { get } from "lodash"

// Helpers
import { getBreakpoint } from "@4cplatform/elements/Helpers"

// Components
import { Skeleton as SkeletonComponent } from "@4cplatform/elements/Molecules"
import { P, SmallText } from "@4cplatform/elements/Typography"

export const ApplicantDetailsWrapper = styled.div`
  display: flex;
`

export const ApplicantsWrapper = styled.div`
  width: calc(100% - 27rem);
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`

export const Wrapper = styled.div`
  margin: ${({ margin }) => margin};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const InnerWrapper = styled.div`
  width: 100%;
  border: 0.1rem solid ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "lightgrey")};
  border-radius: 0.3rem;
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: ${({ theme }) => get(theme, "white", "white")};
  ${({ isLast }) => {
    if (isLast) {
      return css`
        margin: 0;
      `
    }
  }}
`

export const ItemHeader = styled.div`
  display: flex;
  height: 5rem;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: ${({ theme }) => get(theme, "veryFaintGrey", "lightgrey")};
  border-bottom: 0.1rem solid ${({ theme }) => get(theme, "faintGrey", "lightgrey")};
`

export const FieldsWrapper = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
`

export const FieldWrapper = styled.div`
  width: calc(calc(100% - 10rem) / 5);
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 5;

  > div {
    width: 100%;
  }
`

export const GenerateWrapper = styled.div`
  width: 25rem;
  height: 25rem;
  background: linear-gradient(180deg, #21879a 0%, #197da4 39.8%, #4a6db1 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
`

export const ProgressIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 1rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => get(theme, "faintGrey", "lightgray")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const ProgressBar = styled.div`
  width: ${({ progress }) => progress}%;
  height: 100%;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => get(theme, "blue", "blue")};
`

export const ProgressPercentage = styled(P)`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`

export const Divider = styled.div`
  height: 0.1rem;
  background-color: ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "lightgrey")};
`

export const QuotesContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7rem;
  background-color: ${({ theme }) => get(theme, "veryFaintGrey", "white")};

  ${getBreakpoint({ type: "height", max: "large" })} {
    margin-bottom: 2rem;
  }
`

export const QuoteGroupWrapper = styled.div`
  height: 50rem;
  width: 100%;
  flex-direction: column;
  padding: 2rem calc(10% + 1rem);
  border-bottom: 0.1rem solid
    ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "lightgrey")};
`

export const QuoteGroupHeader = styled.div`
  width: 100%;
  height: 6rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
`

export const QuoteGroupBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const QuoteGroupLabels = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`

export const QuoteGroupLabel = styled.div`
  width: fit-content;
  height: 5rem;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  ${({ isSecond }) => {
    if (isSecond) {
      return css`
        height: 6rem;
      `
    }
  }};
`

export const QuoteLevelsWrapper = styled.div`
  width: 80rem;
  display: flex;
  align-items: center;
`

export const LevelWrapper = styled.div`
  background: ${({ theme }) => get(theme, "white", "white")};
  border: 1px solid ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "lightgrey")};
  border-radius: 0.3rem;
  width: 33.33%;
  height: ${({ level }) => (level === "standard" ? "37rem" : "36rem")};
  z-index: ${({ level }) => (level === "standard" ? "2" : "1")};
  ${({ level }) => {
    if (level === "basic") {
      return css`
        border-right: none;
        border-radius: 0.3rem 0 0 0.3rem;
      `
    }
    if (level === "comprehensive") {
      return css`
        border-left: none;
        border-radius: 0 0.3rem 0.3rem 0;
      `
    }
  }};
`

export const QuoteErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 5rem);
  padding: 2rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const ErrorText = styled(SmallText)`
  word-break: break-word;
`

export const Cell = styled.div`
  height: 5rem;
  border-bottom: 1px solid ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "lightgrey")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        p {
          width: 75.29%;
        }
      `
    }
  }}
  ${({ isFirst, isSecond, isLast, level, theme }) => {
    const isStandard = level === "standard"
    if (isFirst && isStandard) {
      return css`
        background-color: ${get(theme, "blue", "blue")};
        height: 5.5rem;
      `
    }
    if (isSecond) {
      return css`
        height: 6rem;
      `
    }
    if (isFirst && !isStandard) {
      return css`
        height: 5rem;
        background-color: ${get(theme, level === "basic" ? "paleGreen" : "purple", "blue")};
      `
    }
    if (isLast && isStandard) {
      return css`
        height: 5rem;
        border-bottom: none;
        flex-direction: row;
      `
    }
    if (isLast && !isStandard) {
      return css`
        height: 5rem;
        border-bottom: none;
        flex-direction: row;
      `
    }
  }}
`

export const LogoSkeleton = styled(SkeletonComponent)`
  margin-right: 2rem;

  ${({ loadingMargin }) => {
    if (loadingMargin) {
      return css`
        margin: ${loadingMargin};
      `
    }
  }}
`

export const Logo = styled.img`
  max-width: 100%;
  object-fit: contain;
  background: ${({ theme, src }) =>
    !src || src.includes("https://") ? "transparent" : get(theme, "faintGrey", "lightgray")};
  margin-right: 2rem;
  ${({ size }) => {
    if (size) {
      return css`
        width: ${size};
        height: ${size};
      `
    }
  }}
`

export const NavigationWrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 7rem;
  background-color: ${({ theme }) => transparentize(0.1, get(theme, "darkBlue"))};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 4;
  padding: 0 10%;
`
