import styled, { createGlobalStyle } from "styled-components"
import { get } from "lodash"

export const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    width: auto;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  #page-wrapper {
    height: auto;
  }

  #page-content {
    overflow: hidden;
    border-radius: 0;
  }
`

export const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  height: 500vh;
`

const Panel = styled.section`
  width: 100vw;
  min-height: 100vh;
  padding: 7.5rem 0;
  display: flex;
  overflow: hidden;
  align-items: center;
`

export const FaqPanelWrap = styled(Panel)`
  background-color: ${({ theme }) => get(theme, "veryFaintGrey", "lightGrey")};
  background: linear-gradient(
    ${({ theme }) => get(theme, "veryFaintGrey", "lightGrey")} 30%,
    ${({ theme }) => get(theme, "white", "white")} 100%
  );
`

export const HeroPanel = styled(Panel)`
  background-color: ${({ theme }) => get(theme, "blue", "blue")};
  background: linear-gradient(
    to top left,
    ${({ theme }) => get(theme, "blue", "blue")} 0%,
    ${({ theme }) => get(theme, "darkBlue", "darkBlue")} 60%
  );
`

export const DiscoverPanelWrap = styled(Panel)`
  background-color: ${({ theme }) => get(theme, "lightBlue", "lightBlue")};
  background: linear-gradient(
    ${({ theme }) => get(theme, "lightBlue", "lightBlue")} 30%,
    ${({ theme }) => get(theme, "blue", "blue")} 100%
  );
`

export const FeaturePanelWrap = styled(Panel)`
  background-color: ${({ theme }) => get(theme, "darkBlue", "darkBlue")};
  background: linear-gradient(
    ${({ theme }) => get(theme, "darkBlue", "darkBlue")} 30%,
    ${({ theme }) => get(theme, "tints.secondary.darkBlue.t10", "darkBlue")} 100%
  );
`

export const ContactPanel = styled(Panel)`
  background-color: ${({ theme }) => get(theme, "purple", "purple")};
  background: linear-gradient(
    ${({ theme }) => get(theme, "purple", "purple")} 40%,
    ${({ theme }) => get(theme, "tints.secondary.purple.t20", "lightBlue")} 100%
  );
`
