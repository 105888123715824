import styled from "styled-components"

export const RowActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
