import React, { useContext } from "react"
import { get } from "lodash"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { AuthWrapper } from "@4cplatform/elements/Auth"

// Helpers
import { ClientsContext } from "./clients.context"

// Components
import { StartJourney } from "../../Organisms"
import { ButtonsWrapper } from "./clients.styles"
import ClientsPanelButtons from "./clients.panel.body.buttons"

const ClientsPanelBody = () => {
  const { startJourneyLoading, onStartJourney, viewData } = useContext(ClientsContext)
  const clientId = get(viewData, "id", null)

  return (
    <PanelBody>
      <ClientsPanelButtons />
      <AuthWrapper roles={["ORG_ADMIN", "SALES_ADVISER"]}>
        <ButtonsWrapper>
          <StartJourney
            clientId={clientId}
            onStartJourney={onStartJourney}
            isJourneyLoading={startJourneyLoading}
            isDataLoading={false}
            clientJourneys={get(viewData, "journeys_in_progress_count", 0)}
          />
        </ButtonsWrapper>
      </AuthWrapper>
    </PanelBody>
  )
}

export default ClientsPanelBody
