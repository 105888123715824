import React, { useContext, useEffect, useRef, useState } from "react"
import { get } from "lodash"
import { Button } from "@4cplatform/elements/Molecules"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { hasFormikErrorsField } from "@4cplatform/elements/Helpers"
import { JourneyContext } from "../../../../../../journey.context"
import { QuoteComparisonContext } from "./quoteComparison.context"

// Components
import {
  QuoteCompareContainer,
  QuotesContainerWrapper,
  QuotesContainer,
  QuotesContainerSkeleton,
  ErrorMessage
} from "./quoteComparison.styles"
import ProgressIndicator from "./quoteComparison.compare.progress"
import Quote from "./quoteComparison.quote"
import {
  getExcessHelperText,
  getPremiumHelperText,
  getUnderwritingHelperText
} from "./quoteComparison.helpers"
import { getPageAudit } from "../../../../../../../../Helpers"

const QuoteCompare = () => {
  const ref = useRef(null)
  const { formik, updateJourneyAuditData, data, submitJourneyAudit } = useContext(JourneyContext)
  const {
    isLoading,
    isDisabled,
    arrivedQuotes,
    openOptionsPanel,
    isJourneyLocked,
    selectedQuotes,
    getProgress
  } = useContext(QuoteComparisonContext)
  const t = useTranslations()
  const hasError = hasFormikErrorsField(formik, "selected_quotes")
  const [scroll, setScroll] = useState()
  const realProgress = getProgress()
  const progress = realProgress >= 100 ? 100 : realProgress

  useEffect(() => {
    if (!isJourneyLocked) {
      ref?.current?.scroll({
        top: 0,
        left: scroll,
        behavior: "smooth"
      })
    }
  }, [scroll, isJourneyLocked])

  const generateAuditData = quotes => {
    const auditData = []
    // eslint-disable-next-line no-shadow
    quotes.forEach(data => {
      const providerName = get(data, "data.provider.name", "Provider name")
      const productName = get(data, "data.product", "Product name")

      const isVitality = providerName === "VitalityHealth"
      const isSwitchSave = get(data, "data.quote_type", null) === "switch_save"
      const isMoriPlus = get(data, "flags.VITALITY_MORI_PLUS", false)

      const flags = get(data, "flags", {})

      const getQuoteError = () => {
        const hasQuoteError = get(data, "success") !== undefined && !get(data, "success")
        if (hasQuoteError) {
          return {
            message: t(`QUOTE_${get(data, "data.message")}`),
            details:
              get(data, "data.details") !== "TIMED_OUT"
                ? get(data, "data.details")
                : "The quote request has timed out"
          }
        }
      }
      let vitalityUnderWriting = `${t(get(data, "data.underwriting_style", ""))} ${t(
        get(data, "data.underwriting_type", "")
      )}`
      if (isMoriPlus) {
        vitalityUnderWriting = `${t(
          get(data, "data.underwriting_style", "")
        )} Moratorium+ Mori+ Discount Applied`
      }
      if (isSwitchSave) {
        vitalityUnderWriting = `${vitalityUnderWriting} Switch & Save`
      }

      let hospitalList = get(
        data,
        "data.options['Hospital List']",
        get(
          data,
          "data.options['Hospital Option']",
          get(data, "data.options['Hospital Network']", "-")
        )
      )

      if (providerName.includes("AXA")) {
        hospitalList = "Fixed List"
        if (
          productName.includes("Personal Health") &&
          get(data, "data.options['Guided Option']") === "Yes"
        ) {
          hospitalList = "Guided"
        }
      }

      auditData.push({
        name: providerName,
        value: [
          {
            name: "Provider Name",
            value: providerName
          },
          {
            name: "Product Name",
            value: t(productName, "-")
          },
          {
            name: "Logo",
            value: get(data, "data.provider.logo", null)
          },
          isVitality
            ? {
                name: "Underwriting",
                value: vitalityUnderWriting
              }
            : {
                name: "Underwriting",
                value: `${t(get(data, "data.underwriting_style", ""))} ${t(
                  get(data, "data.underwriting_type", "")
                )}`
              },
          {
            name: "Hospital List",
            value: hospitalList
          },
          {
            name: "Outpatient",
            value: t(
              get(
                data,
                "data.options['Outpatient Limit']",
                get(
                  data,
                  "data.options['Out-patient Cover']",
                  get(
                    data,
                    "data.options['Out-Patient Cover']",
                    get(data, "data.options['Out-Patient']", "")
                  )
                )
              )
            )
          },
          {
            name: "Excess",
            value: get(data, "data.options.Excess", "").replace(/\b[a-z]/g, function (excess) {
              return excess.toUpperCase()
            })
          },
          {
            name: "Premium Monthly",
            value: `£${parseFloat(get(data, "data.monthly_premium", "")).toFixed(2)}`
          },
          {
            name: "Premium Annually",
            value: `£${parseFloat(get(data, "data.annual_premium", "")).toFixed(2)}`
          },
          {
            name: "Underwriting Helper",
            value: getUnderwritingHelperText(flags)
          },
          {
            name: "Excess Helper",
            value: getExcessHelperText(flags)
          },
          {
            name: "Premium Helper",
            value: getPremiumHelperText(flags)
          },
          {
            name: "Quote over budget",
            value: get(flags, "QUOTE_OVER_BUDGET", false) ? "Yes" : "No"
          },
          {
            name: "Selected",
            value: selectedQuotes.some(
              quote => quote.quote_request_id === get(data, "quote_request_id", "")
            )
              ? "Yes"
              : "No"
          },
          {
            name: "Error",
            value: getQuoteError()
          }
        ]
      })
    })
    return auditData
  }
  useEffect(() => {
    if (!isJourneyLocked && arrivedQuotes?.length) {
      const auditData = generateAuditData(arrivedQuotes)
      updateJourneyAuditData([
        {
          mode: "replace",
          data: auditData
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivedQuotes, isJourneyLocked, selectedQuotes])

  useEffect(() => {
    if (!isJourneyLocked && arrivedQuotes?.length && progress === 100) {
      const auditData = [
        {
          mode: "replace",
          data: generateAuditData(arrivedQuotes)
        }
      ]
      const pageAudit = getPageAudit({
        journeyData: data,
        formikValues: get(formik, "values"),
        auditData
      })
      submitJourneyAudit(pageAudit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, isJourneyLocked, arrivedQuotes])

  return (
    <QuoteCompareContainer>
      <Button
        isLoading={isLoading}
        isDisabled={isDisabled || isJourneyLocked}
        width="max-content"
        trailingIcon="dots-horizontal"
        onClick={openOptionsPanel}
      >
        Configure quotes
      </Button>

      {isJourneyLocked || (
        <QuotesContainerWrapper>
          <Button
            onClick={() =>
              setScroll(ref?.current?.scrollLeft - 320 <= 0 ? 0 : ref?.current?.scrollLeft - 320)
            }
            name="quote_container_scroll_left"
            type="inline-button"
            appearance="primary"
            leadingIcon="chevron-left"
            iconSize="2.5rem"
            isDisabled={isDisabled}
          />
          <QuotesContainer ref={ref}>
            {arrivedQuotes.length === 0 && <QuotesContainerSkeleton />}
            {arrivedQuotes.length > 0 &&
              arrivedQuotes.map((quote, i) => (
                <Quote
                  key={get(quote, "quote_request_id")}
                  data={quote}
                  isFirst={i === 0}
                  isLast={i === arrivedQuotes.length - 1}
                />
              ))}
          </QuotesContainer>
          <Button
            onClick={() => setScroll(ref?.current?.scrollLeft + 320)}
            name="quote_container_scroll_left"
            type="inline-button"
            appearance="primary"
            trailingIcon="chevron-right"
            iconSize="2.5rem"
            isDisabled={isDisabled}
          />
        </QuotesContainerWrapper>
      )}

      <ProgressIndicator />
      {hasError && <ErrorMessage>A quote must be selected in order to proceed</ErrorMessage>}
    </QuoteCompareContainer>
  )
}

export default QuoteCompare
