import React, { useContext } from "react"
import queryString from "query-string"
import { get, isEmpty, find, filter } from "lodash"
import { ComplianceNote, Button } from "@4cplatform/elements/Molecules"
import { H4, P, H3 } from "@4cplatform/elements/Typography"
import { Table } from "@4cplatform/elements/Organisms"
import { usePost } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"
import moment from "moment"

// Helpers
import { CLIENT_ADMIN, POLICY_ADMIN } from "../../../../../../../../../config/pages"
import { JourneyContext } from "../../../../../../journey.context"

const OnboardPolicyActions = () => {
  const { data, refetchData, submitJourneyAudit } = useContext(JourneyContext)
  const t = useTranslations()

  const isLocked = get(data, "journey.locked", false)
  const onboardingHistory = get(data, "journey.onboardHistory", [])
  const erroredEvents = filter(onboardingHistory, ["status", 0])
  const successEvent = find(onboardingHistory, ["status", 1])
  const isSuccessful = !isEmpty(successEvent)
  const isVitality = get(data, "journey.selected_quote.provider.provider_key") === "VITALITYHEALTH"
  const successText = `Successfully onboarded your policy with ${get(
    data,
    "journey.policy.provider.name",
    ""
  )}, ref is ${get(successEvent, "policy_ref", "")}.`

  // Onboard Policy
  const [onboardPolicy, { loading: onboardPolicyLoading }] = usePost({
    endpoint: "/journeys/:journey/onboard-policy",
    params: {
      journey: get(data, "journey.slug", "")
    },
    onCompleted: () => {
      refetchData()
      submitJourneyAudit({
        page: "Onboard policy",
        responses: [
          {
            name: "Onboard policy",
            value: "Success"
          }
        ]
      })
    },
    onError: error => {
      refetchData()
      addAlert({
        message: t("POLICY_ONBOARD_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
      submitJourneyAudit({
        page: "Onboard policy",
        responses: [
          {
            name: "Onboard policy",
            value: "Failed"
          },
          {
            name: "Onboard failed reason",
            value: get(error, "__exception.message") || t("POLICY_ONBOARD_ERROR")
          }
        ]
      })
    }
  })

  // Generate Application Form
  const [generateApplicationFormDocument, { loading: generateApplicationFormDocumentLoading }] =
    usePost({
      endpoint: "/policies/:policy/generate-document/application_form",
      params: {
        policy: get(data, "journey.policy.slug", "")
      },
      onCompleted: res => {
        const filePath = get(res, "data.path", "")
        if (isSuccessful && filePath) {
          window.open(filePath, "_blank")
        }
      },
      onError: () => {
        addAlert({
          type: "error",
          message: t("ONBOARDED_DOCUMENTATION_GENERATE_ERROR"),
          dismissible: true,
          timeout: 5
        })
      }
    })

  return (
    <>
      {!isSuccessful && (
        <>
          <ComplianceNote type="info">
            <H4 margin="0 0 1rem">Purchase the policy</H4>
            <P margin="0">Press the button below to onboard the policy with the provider</P>
          </ComplianceNote>
          <Button
            name="submit"
            width="fit-content"
            onClick={() => onboardPolicy()}
            isLoading={onboardPolicyLoading}
            trailingIcon="chevron-right"
            isDisabled={isLocked}
          >
            Submit application
          </Button>
        </>
      )}

      {/* The following items are available when a policy has been successfully onboarded after the user has pressed the Submit Application button */}
      {isSuccessful && (
        <>
          <ComplianceNote type="success">
            <H4 margin="0 0 1rem">Policy successfully onboarded</H4>
            <P margin="0">{successText}</P>
          </ComplianceNote>
          <Button
            name="view_policy"
            width="fit-content"
            type="Link"
            to={{
              pathname: POLICY_ADMIN.path,
              search: `?${queryString.stringify({
                simulated: get(data, "journey.policy.simulation_mode", true) === 1,
                tab: "ALL_POLICIES",
                status: "SOLD",
                selected: get(data, "journey.policy.slug")
              })}`
            }}
            trailingIcon="eye"
            margin="0 0 2rem"
          >
            View policy
          </Button>
          {!isVitality && (
            <Button
              name="view_policy"
              width="fit-content"
              trailingIcon="document"
              margin="0 0 2rem"
              onClick={() => generateApplicationFormDocument()}
              isLoading={generateApplicationFormDocumentLoading}
            >
              Print application
            </Button>
          )}
          <Button
            name="back_to_clients"
            width="fit-content"
            type="Link"
            to={CLIENT_ADMIN.path}
            trailingIcon="undo-variant"
          >
            Back to clients
          </Button>
        </>
      )}
      {!isSuccessful && !isEmpty(erroredEvents) && (
        <>
          <H3>Previous attempts: {erroredEvents.length}</H3>
          <H4>Onboarding errors</H4>
          <Table
            data={erroredEvents}
            name="share_with"
            columns={[
              {
                label: "Quote Ref.",
                dataKey: "ref",
                minWidth: "100px",
                render: row => get(row, "data.policy_ref") || get(row, "data.quote_ref")
              },
              {
                label: "Error description",
                dataKey: "details",
                minWidth: "300px"
              },
              {
                label: "Recorded at",
                dataKey: "created_at",
                minWidth: "120px",
                render: row => {
                  const createdAt = get(row, "data.created_at")

                  return createdAt
                    ? moment.utc(createdAt).local().format("DD/MM/YYYY HH:mm:ss")
                    : ""
                }
              }
            ]}
            hasActions={false}
            appearance="light"
            sorting={{ direction: "desc", dataKey: "created_at" }}
          />
        </>
      )}
    </>
  )
}

export default OnboardPolicyActions
