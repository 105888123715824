import React from "react"
import { get } from "lodash"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { OrganisationDocumentsContext } from "./organisationDocuments.context"
import { formatVersionsAsEvents } from "./organisationDocuments.helpers"

// Components
import { Timeline } from "../../Organisms"
import OrganisationDocumentPanelHeader from "./organisationDocuments.panel.header"

const OrganisationDocumentView = () => {
  const { selectedDocument, onVersionClick } = React.useContext(OrganisationDocumentsContext)
  const versions = get(selectedDocument, "document_versions", [])
  const current = get(selectedDocument, "current_active_version")
  const documentLink = get(current, "document_links", "")

  return (
    <>
      <OrganisationDocumentPanelHeader selectedDocument={selectedDocument} context="open" />
      <PanelBody>
        <Timeline
          events={formatVersionsAsEvents({
            versions,
            current,
            onClick: () => onVersionClick(documentLink)
          })}
        />
      </PanelBody>
    </>
  )
}

export default OrganisationDocumentView
