import React from "react"
import { Helmet } from "react-helmet-async"
import { H2, H3, P, A, List } from "@4cplatform/elements/Typography"

// Components
import { BlankDocuments } from "../../UI/Templates"
import { Table, TD, LeftTD } from "./privacyPolicy.styles"

const dpoName = "Tom Parish"

const PrivacyPolicy = () => (
  <>
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>
    <BlankDocuments title="Privacy Policy">
      <P>
        We respect your personal privacy and will not use your personal information in any way that
        we would not accept ourselves. We have set out the following information about how we manage
        the personal information we do collect. If you have any comments or concerns regarding our
        privacy policy or our use of your information please contact us.
      </P>
      <H2>1. Who We Are</H2>
      <P>
        We are 4C Platform.com Limited (“4C“). We provide an online portal (“the Portal”) which
        insurance brokers can use to help provide insurance to their customers. We also make the
        contact details of customers that might be interested in insurance available for purchase on
        the Portal (“Leads”).
      </P>
      <P>
        We are a company registered in England and Wales under company number 7377957 and our
        registered company address is Usay House, 5 Hercules Court, Lakeside business Park, Broadway
        Lane, South Cerney Cirencester GL7 5XZ.
      </P>
      <H2>2. Our Contact Details</H2>
      <P>If you want to contact us you can do so by:</P>
      <List name="privacy-policy">
        <li>
          Writing to us at Usay House, 5 Hercules Court, Lakeside business Park, Broadway Lane,
          South Cerney, Cirencester, GL7 5XZ
        </li>
        <li>
          Telephoning us at{" "}
          <A href="tel:+441285705900" target="_blank" rel="noopener noreferrer">
            01285 705900
          </A>
        </li>
        <li>
          Faxing us at{" "}
          <A href="tel:+441285343039" target="_blank" rel="noopener noreferrer">
            01285 343039
          </A>
        </li>
        <li>
          Emailing us at{" "}
          <A href="mailto:info@4cplatform.com" target="_blank" rel="noopener noreferrer">
            info@4cplatform.com
          </A>
        </li>
      </List>
      <H2>Our Data Protection Officer</H2>
      <P>
        At 4C we take how we use your information seriously and so we have appointed a Data
        Protection Officer who has special responsibility for how we deal with the personal
        information of other people. If you have any queries about what we do with your information,
        or you have any other questions or request concerning your information (including if you
        want to exercise any of your rights under section 10 below) you can contact our Data
        Protection Officer using the details below:
      </P>
      <List name="privacy-policy">
        <li>Name of Data Protection Officer - {dpoName}</li>
        <li>
          Email -{" "}
          <A href="mailto:info@4cplatform.com" target="_blank" rel="noopener noreferrer">
            info@4cplatform.com
          </A>
        </li>
      </List>
      <P>
        Alternatively you may write to, fax or telephone him using the details in sections 2 above.
      </P>
      <H2>4. The information that we may hold</H2>
      <P>We hold the following different types of personal information</P>
      <P>
        Lead Information. When insurance brokers use the Portal they are responsible for the
        information that is placed on the Portal by them. If you have any queries about that
        information you should contact them directly. However, when we make Leads available on the
        Portal we are responsible for that information until the Leads are sold. This is the
        information that we hold about Leads.
      </P>

      <Table>
        <tbody>
          <tr>
            <LeftTD>
              <P>The type of information that we hold</P>
            </LeftTD>
            <TD>
              <List name="privacy-policy">
                <li>
                  Name and contact details - so we know who the Leads are and how to contact them
                </li>
                <li>
                  Type of insurance requested - so that insurance brokers can help locate an
                  appropriate insurance policy
                </li>
                <li>
                  Medical information - so that the Lead can apply for the correct insurance policy,
                  and so that insurance brokers can tell insurers about the customer's past medical
                  history
                </li>
                <li>
                  Bank details - so that these can be sent to an insurer for an insurer to take
                  payment
                </li>
                <li>
                  Employment details - so that insurance brokers can tell an insurer the customer's
                  occupation
                </li>
                <li>
                  Date of birth - so that insurance brokers can tell an insurer how old the customer
                  is
                </li>
              </List>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Why we hold it</P>
            </LeftTD>
            <TD>
              <P>
                We hold this information so that we can make the Leads available on the Portal for
                purchase by insurance brokers.
              </P>
              <P>
                We may then keep this information for our records and for legal and regulatory
                purposes.
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Why we are allowed to use the information</P>
            </LeftTD>
            <TD>
              <List name="privacy-policy">
                <li>
                  Consent - We do not obtain Leads unless the Lead has consented to sharing of their
                  information;
                </li>
                <li>
                  Public interest - the use of the information is in the public interest,
                  specifically the provision of insurance
                </li>
                <li>
                  Legal obligations - we have legal and regulatory obligations to show that we are
                  carrying out our business in a compliant manner
                </li>
                <li>
                  Legitimate interest - We have a legitimate interest in carrying out our business,
                  including that we keep a record of our activities both for our clients' benefit
                  and also for our own legal and regulatory reasons.
                </li>
              </List>
              <P>
                Where we use medical information we only rely on consent and public interest to use
                this information.
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Who we will share the information with</P>
            </LeftTD>
            <TD>
              <P>
                We will share information with insurance brokers, when they purchase a Lead on the
                Portal.
              </P>
              <P>We may also share your information as set out in section 5 below</P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>How long will we hold the information for</P>
            </LeftTD>
            <TD>
              <P>
                If the Lead is sold we will keep the information for 7 years from the date of sale.
              </P>
              <P>
                If the Lead is not sold we keep the information for a period of 6 months, although
                we may contact the Lead to extend this period with the Lead's consent.
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>How we obtain the information</P>
            </LeftTD>
            <TD>
              <P>
                We obtain the information from third party sellers of leads. If you are the Lead and
                would like to know the identity of the person who sold us your information please
                contact us.
              </P>
            </TD>
          </tr>
        </tbody>
      </Table>
      <H3>Third party medical details</H3>
      <Table>
        <tbody>
          <tr>
            <LeftTD>
              <P>The type of information that we hold</P>
            </LeftTD>
            <TD>
              <P>
                If a Lead applies for certain types of insurance they may be asked about the medical
                history of their close relatives, and this information may form part of the Lead.
                This will not include any other information about the relative, for example we do
                not keep the names of relatives.
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Why we hold it</P>
            </LeftTD>
            <TD>
              <P>
                We hold this information so that we can make the Leads available on the Portal for
                purchase.
              </P>
              <P>
                We may then keep this information for our records and for legal and regulatory
                purposes.
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Why we are allowed to use the information</P>
            </LeftTD>
            <TD>
              <P>
                <b>Public interest</b> - the use of the information is in the public interest,
                specifically the provision of insurance. However, we will not use the information if
                we believe that the relative would not consent to it (for example if they tell us
                that they do not consent)
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Who we will share the information with</P>
            </LeftTD>
            <TD>
              <P>
                We will share information with insurance brokers, when they purchase a Lead on the
                Portal.
              </P>
              <P>We may also share your information as set out in section 5 below</P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>How long will we hold the information for</P>
            </LeftTD>
            <TD>
              <P>
                If the Lead is sold we will keep the information for 7 years from the date of sale.
              </P>
              <P>
                If the Lead is not sold we keep the information for a period of 6 months, although
                we may contact the Lead to extend this period with the Lead's consent.
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>How we obtain the information</P>
            </LeftTD>
            <TD>
              <P>
                We obtain the information from third party sellers of leads. If you are the Lead and
                would like to know the identity of the person who sold us your information please
                contact us.
              </P>
            </TD>
          </tr>
        </tbody>
      </Table>
      <H3>Employee and potential employee information</H3>
      <Table>
        <tbody>
          <tr>
            <LeftTD>
              <P>The type of information that we hold</P>
            </LeftTD>
            <TD>
              <List name="privacy-policy">
                <li>
                  Personal Details - such as name and contact details, so that we know who our
                  employees are and how to contact them
                </li>
                <li>
                  Financial details - such as salary and bank details, so that we can pay our
                  employees
                </li>
                <li>
                  Employment history - so that we can obtain references and assess suitability
                </li>
                <li>
                  Doctors reports - so that we may assess reasons for absenteeism and to ensure we
                  provide a suitable workplace
                </li>
                <li>
                  Employment records - details of the role the employee does for us, and related
                  information such as disciplinary matters, grievance records, performance reports,
                  pension documents etc.
                </li>
                <li>
                  Criminal convictions - we may carry out a check for criminal convictions on our
                  employees.
                </li>
              </List>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Why we hold it</P>
            </LeftTD>
            <TD>
              <P>We hold this information so that we can run our business.</P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Why we are allowed to use the information</P>
            </LeftTD>
            <TD>
              <List name="privacy-policy">
                <li>
                  Consent - employees consent to us holding certain information about them in their
                  employment contract and/or in application forms and/or during their period of
                  employment
                </li>
                <li>
                  Contract - We need the information for the purposes of preparing for or carrying
                  out the employment contract
                </li>
                <li>Legitimate interest - we have a legitimate interest in running our business</li>
                <li>
                  Legal obligations - we need this information to comply with our legal obligations
                  (in particular we may need medical information for employment law purposes so that
                  we can make reasonable adjustments to how you work)
                </li>
              </List>
              <P>
                Where we use medical information we only rely on consent and employment law purposes
                to use this information. Where we use information about criminal convictions we rely
                on consent.
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Who we will share the information with</P>
            </LeftTD>
            <TD>
              <P>
                We will share your information with the Sage Group Plc, our payroll company, to
                ensure that the employee gets paid.
              </P>
              <P>
                We will share your information with third parties to fulfil our legal obligations
                such as HM Revenue and Customs, financial auditors and the Office for National
                Statistics.
              </P>
              <P>We may also share your information as set out in section 5 below</P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>How long will we hold the information for</P>
            </LeftTD>
            <TD>
              <P>
                We will keep information for employees for a period of 8 years after termination of
                the employment contract.
              </P>
              <P>
                We will keep information about prospective employee for a period of 6 months from
                last contact.
              </P>
              <P>
                We are required to keep any board minutes for 10 years, which may refer to the names
                of employees.
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>How we obtain the information</P>
            </LeftTD>
            <TD>
              <P>
                We normally obtain the information directly from the employee or applicant. However,
                in some circumstances we may ask previous employers or other third parties for
                references. We will also carry out checks at Creditsafe, a credit reference agency,
                for information about your past financial activity and your credit rating and may
                contact Gloucestershire County Council, who carries out a search for criminal
                convictions.
              </P>
            </TD>
          </tr>
        </tbody>
      </Table>
      <H3>Insurers, Intermediaries, other third party suppliers and business contacts</H3>
      <Table>
        <tbody>
          <tr>
            <LeftTD>
              <P>The type of information that we hold</P>
            </LeftTD>
            <TD>
              <P>
                We do business with many organisations, such as insurers, other insurance brokers,
                or other suppliers (such as legal advisers, accountants, office equipment
                suppliers). We will keep contact details for individuals at those organisations, and
                we may also keep financial information, such as bank details. If the third party
                uses the Portal we will have records of that use, and details of individuals who
                have a user name in order to use the Portal. We may also have other business
                contacts for who we keep contact details (such as shareholders, associated companies
                or business acquaintances)
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Why we hold it</P>
            </LeftTD>
            <TD>
              <P>We hold this information so that we can run our business.</P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Why we are allowed to use the information</P>
            </LeftTD>
            <TD>
              <List name="privacy-policy">
                <li>
                  Consent - if we have been provided with contact details by a person then that
                  person has consented to us having those contact details
                </li>
                <li>
                  Contract-theinformationmaybenecessary so that we can perform a contract with the
                  organisation
                </li>
                <li>
                  Legitimate interest - we have a legitimate interest in being able to conduct our
                  business
                </li>
              </List>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>Who we will share the information with</P>
            </LeftTD>
            <TD>
              <P>See section 5 below.</P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>How long will we hold the information for</P>
            </LeftTD>
            <TD>
              <P>
                We will retain contact details for as long as we carry on business, unless those
                contact details become out of date (in which case they will be deleted) We will keep
                bank details for a period of 2 years after the end of the applicable contract
              </P>
            </TD>
          </tr>
          <tr>
            <LeftTD>
              <P>How we obtain the information</P>
            </LeftTD>
            <TD>
              <P>
                We obtain the information directly from the insurer, intermediary or third party, or
                from publicly available information such as websites or phone directories.
              </P>
            </TD>
          </tr>
        </tbody>
      </Table>
      <H2>5. Other people that we may provide information to</H2>
      <P>
        In addition to what is set out in section 4 above we may also share information with the
        following people.
      </P>
      <H3>Usay Business Limited</H3>
      <P>
        We are part of the same group of companies as Usay Business Limited ("Usay"). Usay provides
        us with assistance in running our business (for example employees of Usay may do work for
        us). We will therefore share all the information that we have with Usay, although only on
        terms that requires Usay to keep that information confidential.
      </P>
      <H3>IT companies</H3>
      <P>
        We use the following third party suppliers to help us run our business. These suppliers may
        therefore have access to the information set out above:
      </P>
      <List name="privacy-policy">
        <li>
          We use a company called BOM Group Limited that provides us with IT services such as file
          storage and email;
        </li>
        <li>
          We use a company called UKFast.Net Limited that hosts an IT platform that we use for our
          business
        </li>
        <li>
          We use Google Cloud part of Google Incorporated to host other aspects of our IT platform.
        </li>
        <li>
          We use Rackspace Incorporated for support and maintenance of Google cloud resources.
        </li>
        <li>
          We use a company called GBE Conervage that provide us with IT services such as firewalls
          and internet security.
        </li>
      </List>
      <H3>Legal and regulatory bodies</H3>
      <P>
        We may also have to share your information with regulators, for example the information
        commissioner or the Financial Conduct Authority, or to other bodies that have a legal right
        to obtain it, such as the police.
      </P>
      <H3>Legal advice and disputes</H3>
      <P>
        We may need to share your information with our legal advisers. If there is a dispute between
        us or you make a complaint (or at third party makes a complaint or raises a dispute that
        involves you) we may also need to share your information with any person adjudicating on
        that dispute or complaint, such as a court or an ombudsman.
      </P>
      <H3>Business purchaser</H3>
      <P>
        If we decide to sell our business we may provide your information to any potential purchaser
        of our business.
      </P>
      <H3>Group members</H3>
      <P>
        We may share your details for other members of the Usay Group of companies. We will do this
        where you consent to us passing on your details in order for that other company to provide a
        quote or other service to you, but we may also do this where we have a legitimate interest
        such as sharing data for research or statistical purposes, for helping improve systems or to
        otherwise help us improve the running of a business of a group company.
      </P>
      <H2>6. Other uses of your information</H2>
      <P>In addition to what is set out in section 4 above we may also use your information:</P>
      <List name="privacy-policy">
        <li>for research, analysis or statistical purposes.</li>
        <li>
          for system administration or development purposes and to form personalised profiles and
          demographics that we may use to improve the content of our communications to you.
        </li>
        <P>
          We have a legitimate interest in doing this so that we can run our business more
          efficiently.
        </P>
      </List>
      <H2>7. Marketing</H2>
      <P>
        We may use your information to deliver personalised offers and promotions based upon your
        demographics and product purchase profile. We will only do so where:
      </P>
      <List name="privacy-policy">
        <li>We have your express consent; or</li>
        <li>
          You are a customer and the offers and promotions are about our own products and services
          which we believe will be of interest to you. We can do this because we have a legitimate
          interest in marketing our business.
        </li>
      </List>
      <P>
        It is possible to opt out of such communications or to withdraw such consent by contacting
        us by any of our contact details in section 2 above. There is also an unsubscribe function
        on our website which can be used to unsubscribe from email communications.
      </P>
      <P>We will not sell any of your information to third parties for the purpose of marketing.</P>
      <H2>8. Public interest processing</H2>
      <P>
        Where we rely on the public interest to use any of your information then you can object to
        us doing so. See the “right to object” in section 10 below.
      </P>
      <H2>9. Legitimate Interests</H2>
      <P>
        Where we have referred to having a legitimate interest to use any of your information then
        we have balanced our legitimate interest against your rights and interests to have your
        information protected. If you would like details about how we have done this please contact
        us. You may also object to us processing the information in this way - see the “right to
        object” in section 10 below.
      </P>
      <H2>10. Withdrawal of consent</H2>
      <P>
        Where we rely on your consent to use your information you may withdraw that consent at any
        time by contacting us using the details in section 2 above. We shall then stop using your
        information, unless there is another applicable reason for which we can use it set out in
        section 4 above. The withdrawal of consent shall not affect the lawfulness of any use of the
        information that occurred prior to the withdrawal of consent.
      </P>
      <H2>11. Your rights</H2>
      <P>
        If we hold information about you then you have the following rights in respect of our use of
        that information.
      </P>
      <List name="privacy-policy">
        <li>
          A right of access to your information and to details about how we use your information
        </li>
        <li>A right to rectification of inaccurate information</li>
        <li>A right to have information erased (a right to be forgotten)</li>
        <li>A right to restrict the use we can make of your information</li>
        <li>A right to information portability</li>
        <li>A right to object to the processing of your information</li>
      </List>
      <P>
        To exercise any of these rights contact either us or our data protection officer using the
        contact details in sections 2 or 3 above. You can find more detail about these rights below.
      </P>
      <P>
        We will comply with any of these rights as soon as possible and in any event no later than
        one month from the request (although if the request is complex or there are numerous
        requests we may extend this by up to two further months).
      </P>
      <P>
        We will not charge for any request, unless it is manifestly unfounded or excessive (for
        example because it is repetitive). If a request is manifestly unfounded or excessive then we
        will either charge our reasonable administrative costs to comply with it, or we tell you
        that we are not going to comply because it is manifestly unfounded or excessive.
      </P>
      <H3>Information on the Portal</H3>
      <P>
        Please note that if you are a customer whose information has been placed on the Portal by an
        insurance broker then it is the insurance broker who is responsible for that information.
        You should contact the insurance broker if you want to exercise any of the above rights in
        respect of that information.
      </P>
      <H3>Access to information</H3>
      <P>
        You may request access to the information that we hold about you, together with information
        about the purposes for which we use that information, the type of information we hold, who
        we disclose it to, and the length of time which we hold it for. This is commonly referred to
        as a subject access request.
      </P>
      <P>
        If you make a subject access request we will send you a form to complete so that we know who
        you are and so that we can locate your information. We will then comply with your request by
        providing you with the details set out above and with a copy of the information that we hold
        about you.
      </P>
      <H3>Right to rectification</H3>
      <P>
        If we hold any inaccurate information about you please let us know as soon as possible and
        we will correct it. You may also request that we supplement any incomplete information that
        we hold about you.
      </P>
      <P>
        If we rectify or supplement any information that we hold about you then we will let any
        third parties to who we have provided this information know, unless this is impossible or
        disproportionate. If you ask us to we will tell you to who we have disclosed this
        information.
      </P>
      <H3>Right to erasure</H3>
      <P>
        In certain circumstances you can ask that we erase the information that we hold about you:
      </P>
      <List name="privacy-policy">
        <li>We no longer need to keep it for the purposes for which it was collected or used</li>
        <li>Our only basis to process it is your consent and you withdraw that consent</li>
        <li>
          You object to our use of the information and we do not have any overriding legitimate
          grounds to continue using the information
        </li>
        <li>We are using the information unlawfully</li>
        <li>We have to erase it in order to comply with our legal obligations</li>
      </List>
      <P>
        If you wish for us to delete your information please let us know and we will delete it,
        unless we have a legitimate reason why we need to keep your information (for example we are
        in a dispute with you).
      </P>
      <P>
        If we erase any information that we hold about you then we will let any third parties to who
        we have provided this information know, unless this is impossible or disproportionate. If
        you ask us to, we will tell you to whom we have disclosed this information.
      </P>
      <H3>Right to data portability</H3>
      <P>
        Where our processing of your information is based on consent and the processing is carried
        out by automated means then you have the right to have that information provided to you in a
        commonly used format, provided that this is technically feasible.
      </P>
      <H3>Right to restrict processing</H3>
      <P>In certain circumstances you can ask that we restrict how we use your information:</P>
      <List name="privacy-policy">
        <li>
          You have made a request for rectification or to supplement inaccurate information. You can
          ask that we restrict your processing while we deal with your request
        </li>
        <li>
          The use of the information is unlawful, but you ask that we restrict our use of the
          information rather than delete it
        </li>
        <li>
          We no longer need to use it but you want us to keep it so that you can use it for a legal
          claim
        </li>
      </List>
      <P>
        In these circumstances we will stop using your information (although we may still store it)
        unless you consent to our use, we need it for legal claims, we need to use it to protect
        another person or where there is an important public interest in us doing so.
      </P>
      <P>
        If we restrict our use of any information that we hold about you then we will let any third
        parties to whom we have provided this information know, unless this is impossible or
        disproportionate. If you ask us to we will tell you to whom we have disclosed this
        information.
      </P>
      <H3>Right to object</H3>
      <P>
        You have the right to object to our using your information where we base our use on either
        it being in the public interest or it being for legitimate interests (see section 4 above).
        If you do so and we have no other basis to process it we can only continue to process it if
        we can demonstrate that we have compelling grounds to do so which override your interests,
        or we need it for legal claims.
      </P>
      <H2>12. Complaints</H2>
      <P>
        Our use of information is regulated by the Information Commissioner. If you would like to
        complain about us you can contact the Information Commissioner on 0303 123 1113. More
        information about your rights to complain can be found at{" "}
        <A href="https://ico.org.uk" target="_blank" rel="noopener noreferrer">
          https://ico.org.uk
        </A>
      </P>
    </BlankDocuments>
  </>
)

export default PrivacyPolicy
