import React, { useContext } from "react"
import PropTypes from "prop-types"
import { get, isEmpty, capitalize } from "lodash"
import { Icon } from "@4cplatform/elements/Atoms"
import { H3, H2, P } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers

import { colours } from "@4cplatform/elements/Helpers"
import { QuickQuoteContext } from "./quickQuote.context"

// Components
import { QuoteErrorWrapper, LevelWrapper, Cell } from "./quickQuote.styles"
import { resolveValueNames } from "../../Organisms/QuickQuoteSettings/quickQuoteSettings.helpers"

const QuickQuoteLevel = ({ level, data }) => {
  const t = useTranslations()
  const { requestQuotesLoading, formik, quickQuoteSettings } = useContext(QuickQuoteContext)

  const isLoading = requestQuotesLoading || isEmpty(data)
  const hasError = get(data, "success") !== undefined && !get(data, "success")

  const providerName = get(data, "data.provider.name", "Provider name")

  // Defining H3 text content here to prevent errors from propTypes (Passing Array instead of string)
  const quickQuoteTitle = `${capitalize(level)} Policy`
  const priceText = `£${
    get(formik, "values.payment_frequency", "MONTHLY") === "MONTHLY"
      ? get(data, "data.monthly_premium")
      : get(data, "data.annual_premium")
  }`
  const cancerText = "Full Cover"
  let excessText = `${get(data, "data.options.Excess")}`

  if (excessText === "Nil Cover" && providerName.includes("Vitality")) {
    excessText = "None"
  }

  const hospitalListText = React.useMemo(() => {
    if (!providerName.includes("AXA")) {
      return get(
        data,
        "data.options['Hospital List']",
        get(
          data,
          "data.options['Hospital Option']",
          get(data, "data.options['Hospital Network']", "-")
        )
      )
    }

    if (
      get(data, "data.product_key") === "PERSONALHEALTH" &&
      resolveValueNames(get(quickQuoteSettings, `${level}_hospital_list`)) === "Guided Option"
    ) {
      return "Guided"
    }

    return "Fixed List"
  }, [providerName, data, level, quickQuoteSettings])

  let outpatientLimitText = `${get(
    data,
    "data.options['Outpatient Limit']",
    t(
      get(
        data,
        "data.options['Out-patient Cover']",
        get(data, "data.options['Out-Patient Cover']", get(data, "data.options['Out-Patient']"))
      )
    )
  )}`

  if (outpatientLimitText === "Full Cover" && providerName.includes("Vitality")) {
    outpatientLimitText = "Full"
  }

  const underwritingText = `${t(get(data, "data.underwriting_type"))}`

  return (
    <LevelWrapper level={level}>
      <Cell level={level} isFirst>
        <H3 margin="0" appearance="light">
          {quickQuoteTitle}
        </H3>
      </Cell>
      {!hasError && (
        <>
          <Cell isSecond>
            <H2 margin="0" loadingWidth="20rem" isLoading={isLoading}>
              {priceText}
            </H2>
          </Cell>
          <Cell isLoading={isLoading}>
            <P
              margin="0"
              fontSize="1.6rem"
              loadingWidth="20rem"
              isLoading={isLoading}
              loadingLines={1}
              loadingMargin="0"
            >
              {cancerText}
            </P>
          </Cell>
          <Cell isLoading={isLoading}>
            <P
              margin="0"
              fontSize="1.6rem"
              loadingWidth="20rem"
              isLoading={isLoading}
              loadingLines={1}
              loadingMargin="0"
            >
              {excessText}
            </P>
          </Cell>
          <Cell isLoading={isLoading}>
            <P
              margin="0"
              fontSize="1.6rem"
              loadingWidth="20rem"
              isLoading={isLoading}
              loadingLines={1}
              loadingMargin="0"
            >
              {hospitalListText}
            </P>
          </Cell>
          <Cell isLoading={isLoading}>
            <P
              margin="0"
              fontSize="1.6rem"
              loadingWidth="20rem"
              isLoading={isLoading}
              loadingLines={1}
              loadingMargin="0"
            >
              {outpatientLimitText}
            </P>
          </Cell>
          <Cell isLast isLoading={isLoading}>
            <P
              margin="0"
              fontSize="1.6rem"
              loadingWidth="20rem"
              isLoading={isLoading}
              loadingLines={1}
              loadingMargin="0"
            >
              {underwritingText}
            </P>
          </Cell>
        </>
      )}
      {hasError && (
        <QuoteErrorWrapper>
          <Icon icon="alert" colour={get(colours, "red", "red")} transition="none" size="3rem" />
          <P align="center" colour={get(colours, "red", "red")}>
            <strong>{t(`QUOTE_${get(data, "data.message")}`)}</strong>
          </P>
        </QuoteErrorWrapper>
      )}
    </LevelWrapper>
  )
}

QuickQuoteLevel.propTypes = {
  level: PropTypes.oneOf(["basic", "standard", "comprehensive"]).isRequired,
  data: PropTypes.object.isRequired
}

export default QuickQuoteLevel
