import { get } from "lodash"

import { getSystemAdminDash, getSystemSupportAdminDash } from "./system"
import { getProviderAdminDash, getUnderwriterDash } from "./provider"
import { getNetworkAdminDash, getNetworkMemberAdminDash } from "./network"
import { getOrganisationAdminDash, getSalesAdviserDash } from "./organisation"

/**
 * This accepts a single parameter of the user's role and returns the appropriate dashboard object.
 * For use in the Navigation subcomponent within the Page Organism and within the Dashboard page.
 * @param role The user's role
 */
export const getUserDashboard = user => {
  const role = get(user, "role.name")
  const parentSlug = get(user, "parent.slug")

  switch (role) {
    case "NETWORK_ADMIN":
      return getNetworkAdminDash(parentSlug)
    case "NETWORK_MEMBER_ADMIN":
      return getNetworkMemberAdminDash(parentSlug)
    case "ORG_ADMIN":
      return getOrganisationAdminDash(parentSlug)
    case "SALES_ADVISER":
      return getSalesAdviserDash(parentSlug)
    case "PROVIDER_ADMIN":
      return getProviderAdminDash(parentSlug)
    case "UNDERWRITER":
      return getUnderwriterDash(parentSlug)
    case "SYS_ADMIN":
      return getSystemAdminDash()
    case "SUPPORT_ADMIN":
      return getSystemSupportAdminDash()
    default:
      return []
  }
}
