import React, { useRef, useContext, useMemo } from "react"
import { capitalize, isEmpty } from "lodash"
import { H1, H3 } from "@4cplatform/elements/Typography"
import { Icon } from "@4cplatform/elements/Atoms"
import { PageContext } from "@4cplatform/elements/Organisms"
import { Button } from "@4cplatform/elements/Molecules"
import { AuthContext } from "@4cplatform/elements/Auth"

// Components
import { Tabs, Tab } from "../../Organisms"
import BarChart from "./miReports.barChart"
import ReportHeader from "./miReports.header"
import ReportFooter from "./miReports.footer"
import DoughnutChart from "./miReports.doughnutChart"
import { MiReportContext } from "./miReports.context"
import { onHandlePDF, REPORT_CONSTANTS, getTabHeader, getTabIndexByKey } from "./miReports.helpers"
import { ExportedReportWrapper, GlobalStyles, SubTabWrapper } from "./miReports.style"
import ActiveFilters from "./miReports.activeFilters"
import { useQuery } from "../../Helpers/providers"

const { TABS, SALES_SUB_TABS, TABS_REPORT } = REPORT_CONSTANTS

// eslint-disable-next-line
const beforeDatasetsDraw =
  (xLabel = 0) =>
  (chart, args, _options) => {
    const { ctx, width, height } = chart

    const { innerRadius } = chart.getDatasetMeta(chart.data.datasets.length - 1).controller
    const { outerRadius } = chart.getDatasetMeta(0).controller
    const radiusLength = outerRadius - innerRadius

    if (_options.enabled) {
      const x = width / 2
      const y = height / 2

      ctx.beginPath()
      ctx.arc(x - xLabel, y, outerRadius - radiusLength / 2, 0, 2 * Math.PI)
      ctx.lineWidth = radiusLength
      ctx.strokeStyle = _options.color || "transparent"
      ctx.stroke()
    }
  }

const MiReports = () => {
  const query = useQuery()
  const {
    chartData,
    chartLabels,
    queryLoading,
    chartFormatter,
    activeTabIndex,
    setActiveTabIndex,
    activeSalesSubTabIndex,
    setActiveSalesSubTabIndex
  } = useContext(MiReportContext)
  const ref = useRef(null)
  const { setPanelStatus } = useContext(PageContext)
  const { canAccess } = useContext(AuthContext)
  const isSales = query.get("report_type") === "sales"
  const isSaleProduct = isSales && query.get("sales_sub_tab") === "products"
  const isConversions = query.get("report_type") === "quotation_and_conversions____"
  const isDoughnutChartShow = useMemo(
    () => isSaleProduct || isConversions,
    [isSaleProduct, isConversions]
  )

  let reportHeaderPDF = capitalize(`${getTabHeader(TABS[activeTabIndex])}`)
  if (isSales) {
    reportHeaderPDF += ` ${SALES_SUB_TABS[activeSalesSubTabIndex]}`
  }

  return (
    <>
      <GlobalStyles />
      <ExportedReportWrapper ref={ref}>
        <H1 id="reportHeaderPDF" style={{ display: "none" }}>
          {reportHeaderPDF}
        </H1>
        <span id="reportHeader">
          <H1>Reports</H1>
          <Tabs
            hasQueryControls
            name="report_type"
            isLoading={queryLoading}
            defaultIndex={activeTabIndex}
            onTabChange={key => {
              setActiveTabIndex(getTabIndexByKey(TABS, key))
              setActiveSalesSubTabIndex(key === TABS[0] ? 0 : -1)
            }}
          >
            {TABS_REPORT.map(tab => {
              const { value, label, excludes } = tab
              if (isEmpty(excludes) || !canAccess(excludes)) {
                return <Tab key={`tab-${value}`} header={label} />
              }

              return null
            })}
          </Tabs>
          {isSales && (
            <SubTabWrapper>
              <Icon icon="subdirectory-arrow-right" />
              <Tabs
                hasQueryControls
                name="sales_sub_tab"
                isLoading={queryLoading}
                defaultIndex={activeSalesSubTabIndex}
                onTabChange={key => {
                  setActiveSalesSubTabIndex(SALES_SUB_TABS.indexOf(key))
                }}
              >
                {SALES_SUB_TABS.map(key => (
                  <Tab key={`sales-sub-tab-${key}`} header={capitalize(key)} />
                ))}
              </Tabs>
            </SubTabWrapper>
          )}
        </span>

        <ReportHeader isLoading={queryLoading} />
        <Button
          isLoading={queryLoading}
          isDisabled={queryLoading}
          margin="1rem 0 1.5rem 0"
          data-html2canvas-ignore
          onClick={() => setPanelStatus("open")}
          appearance="primaryInline"
          leadingIcon="format-list-checks"
          type="inline-button"
        >
          More filter options
        </Button>
        <ActiveFilters />
        {isSales && <H3>Sales {SALES_SUB_TABS[activeSalesSubTabIndex]}</H3>}
        {!isDoughnutChartShow && (
          <BarChart labels={chartLabels} data={chartData} formatter={chartFormatter} />
        )}
        {isDoughnutChartShow && (
          <>
            {isSaleProduct && (
              <>
                <DoughnutChart
                  labels={chartLabels}
                  data={chartData}
                  plugins={[
                    {
                      id: "radiusBackground",
                      beforeDatasetsDraw: beforeDatasetsDraw(0),
                      onResize: beforeDatasetsDraw(0)
                    }
                  ]}
                  isHideDougnutBg
                />
              </>
            )}
            {isConversions && (
              <DoughnutChart
                labels={chartLabels}
                data={chartData}
                plugins={[
                  {
                    id: "radiusBackground",
                    beforeDatasetsDraw: beforeDatasetsDraw(85),
                    onResize: beforeDatasetsDraw(85)
                  }
                ]}
              />
            )}
          </>
        )}
      </ExportedReportWrapper>
      <ReportFooter
        isLoading={queryLoading}
        exportPDF={() => onHandlePDF(ref.current)}
        printPDF={() => onHandlePDF(ref.current, true)}
      />
    </>
  )
}

export default MiReports
