import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { logoSrc } from "@4cplatform/elements/Assets"
import { A } from "@4cplatform/elements/Typography"

// Components
import { Content, Nav, Wrapper } from "./navigation.styles"

const navigationLinks = [
  {
    title: "Discover",
    href: "#discover"
  },
  {
    title: "Features",
    href: "#features"
  },
  {
    title: "FAQs",
    href: "#faq"
  },
  {
    title: "Contact",
    href: "#contact"
  }
]

const Navigation = ({ additionalLinks }) => {
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset >= window.screen.height / 2) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const renderLink = ({ title, href }) => (
    <A key={title} href={href} fontSize="1.6rem" decoration="none" colour="white">
      {title}
    </A>
  )

  return (
    <Wrapper data-testid="landing-navigation" ref={ref} isSticky={isSticky}>
      <Content>
        <img src={logoSrc} alt="4C Platform.com Limited branding" height="40px" />
        {navigationLinks.length > 0 && (
          <Nav>{[...navigationLinks, ...additionalLinks].map(link => renderLink(link))}</Nav>
        )}
      </Content>
    </Wrapper>
  )
}

Navigation.defaultProps = {
  additionalLinks: []
}

Navigation.propTypes = {
  additionalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  )
}

export default Navigation
