import styled from "styled-components"

export const DivFlexCenter = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    margin: 0 1rem;
  }

  &:hover {
    background-color: rgb(249, 249, 249);
  }
`
