export const currentProviders = [
  { order: 0, label: "Aviva", value: "Aviva" },
  { order: 1, label: "AXA Health", value: "AXA Health" },
  { order: 2, label: "Bupa", value: "Bupa" },
  { order: 3, label: "Freedom", value: "Freedom" },
  { order: 4, label: "Health on Line", value: "Health on Line" },
  { order: 5, label: "The Exeter", value: "The Exeter" },
  { order: 6, label: "WPA", value: "WPA" },
  { order: 7, label: "Other", value: "Other" }
]

export const getCurrentProviders = currentProviders.map((value, i) => ({
  order: i,
  label: value,
  value
}))
