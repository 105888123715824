import React, { useEffect } from "react"
import moment from "moment"
import { get } from "lodash"
import { object, number, string } from "yup"
import { Select, TextArea, DatePicker } from "@4cplatform/elements/Forms"
import { DisclosureNotesContext } from "../../../../../../DisclosureNotes/disclosureNotes.context"
import { JourneyContext } from "../../../../../../../journey.context"

const NoteFormTemplate = () => {
  const {
    applicants,
    disclosureNoteFormik,
    setNoteValidationSchema,
    setAddEditModal,
    addEditModal,
    productName
  } = React.useContext(DisclosureNotesContext)
  const { data } = React.useContext(JourneyContext)
  const isLocked = get(data, "journey.locked", false)

  const formik = {
    ...disclosureNoteFormik
  }

  useEffect(() => {
    if (productName === "HEALTHFORYOU") {
      setNoteValidationSchema(
        object({
          journey_applicant_id: number().min(0).required("PLEASE_SPECIFY_AN_APPLICANT"),
          illness_disability: string().required("MISSING_REQUIRED_FIELD"),
          date_illness_began: string().required("MISSING_REQUIRED_FIELD"),
          date_illness_ended: string().nullable(),
          ...(addEditModal.field ===
          "have_any_treatment_consultations_investigations_or_diagnostic_tests_planned_or_pending"
            ? {
                cont_cover_terms_details: string()
              }
            : {
                treatment_received: string().required("MISSING_REQUIRED_FIELD"),
                present_state_of_health: string().required("MISSING_REQUIRED_FIELD")
              })
        })
      )
    } else {
      setNoteValidationSchema(
        object({
          journey_applicant_id: number().integer().positive().required("PLEASE_SPECIFY_A_MEMBER"),
          illness_disability_treatment_received: string().required("MISSING_REQUIRED_FIELD"),
          date_illness_began: string().required("MISSING_REQUIRED_FIELD"),
          present_state_of_health: string().required("MISSING_REQUIRED_FIELD")
        })
      )
    }

    if (addEditModal.type === "new")
      if (productName === "HEALTHFORYOU") {
        setAddEditModal({
          ...addEditModal,
          noteInitialValues: {
            journey_applicant_id: "",
            illness_disability: "",
            date_illness_began: "",
            date_illness_ended: "",
            ...(addEditModal.field ===
            "have_any_treatment_consultations_investigations_or_diagnostic_tests_planned_or_pending"
              ? {
                  cont_cover_terms_details: ""
                }
              : {
                  treatment_received: "",
                  present_state_of_health: ""
                })
          }
        })
      } else {
        setAddEditModal({
          ...addEditModal,
          noteInitialValues: {
            journey_applicant_id: "",
            illness_disability_treatment_received: "",
            date_illness_began: "",
            present_state_of_health: ""
          }
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const maxDateRange = moment().subtract(1, "day").format("DD/MM/YYYY")

  const getConsultationMinDate = React.useMemo(() => {
    if (formik.values && formik.values.journey_applicant_id) {
      const applicantData = applicants.find(
        applicant => applicant.id === JSON.parse(formik.values.journey_applicant_id)
      )
      return moment(applicantData.date_of_birth).format("DD/MM/YYYY")
    }
    return ""
  }, [applicants, formik.values])

  const getTreatmentMinDate = React.useMemo(() => {
    if (formik.values && formik.values.date_illness_began) {
      return moment(formik.values.date_illness_began).add(1, "days").format("DD/MM/YYYY")
    }
    return ""
  }, [formik.values])

  const getTreatmentMaxDate = React.useMemo(
    () => moment().subtract(1, "days").format("DD/MM/YYYY"),
    []
  )

  const disableTreatmentMaxDate = React.useMemo(() => {
    if (formik.values && formik.values.date_illness_began) {
      return moment(formik.values.date_illness_began).add(1, "days").isSame(moment().startOf("day"))
    }

    return true
  }, [formik.values])
  return (
    <>
      <Select
        formik={formik}
        label="Select Member"
        name="journey_applicant_id"
        isRequired
        isDisabled={isLocked}
        errorPrefix="none"
      >
        <option value="">Select</option>
        {applicants.map(applicant => (
          <option value={get(applicant, "id")} key={get(applicant, "id")}>
            {get(applicant, "first_name")} {get(applicant, "last_name")}
          </option>
        ))}
      </Select>

      {productName === "HEALTHFORYOU" ? (
        <>
          <TextArea
            name="illness_disability"
            formik={formik}
            label="Nature of illness/disability:"
            isRequired
            isDisabled={isLocked}
          />
          {addEditModal.field ===
            "had_treatment_in_hospital_or_consulted_a_specialist_in_the_last_12_months" && (
            <TextArea
              name="treatment_received"
              formik={formik}
              label="Treatment received:"
              isRequired
              isDisabled={isLocked}
            />
          )}
        </>
      ) : (
        <TextArea
          name="illness_disability_treatment_received"
          formik={formik}
          label="Nature of illness/disability and treatment received:"
          isRequired
          isDisabled={isLocked}
        />
      )}
      <DatePicker
        formik={formik}
        dateRangeMax={maxDateRange}
        name="date_illness_began"
        dateRangeMin={getConsultationMinDate}
        label="When did the illness/disability begin?"
        isRequired
        isDisabled={isLocked}
      />
      {productName === "HEALTHFORYOU" && (
        <DatePicker
          formik={formik}
          name="date_illness_ended"
          label="When did the illness/disability end?"
          dateRangeMin={getTreatmentMinDate}
          dateRangeMax={getTreatmentMaxDate}
          isDisabled={disableTreatmentMaxDate || isLocked}
        />
      )}
      {productName === "HEALTHFORYOU" &&
      addEditModal.field ===
        "have_any_treatment_consultations_investigations_or_diagnostic_tests_planned_or_pending" ? (
        <TextArea
          formik={formik}
          margin="0 0 2rem 0"
          label="Details of planned/pending consultations, tests or treatment"
          name="cont_cover_terms_details"
          isDisabled={isLocked}
        />
      ) : (
        <TextArea
          isRequired
          formik={formik}
          margin="0 0 2rem 0"
          label="Current state of health related to this illness"
          name="present_state_of_health"
          isDisabled={isLocked}
        />
      )}
    </>
  )
}

export default NoteFormTemplate
