import React from "react"
import { AuthContext } from "@4cplatform/elements/Auth"

// Components
import JourneysProvider from "./context/journeys.provider"
import { JourneyAdmin, JourneysPanel } from "../../../../UI/Templates"

const Journeys = () => {
  const { canAccess } = React.useContext(AuthContext)

  return (
    <JourneysProvider>
      <JourneyAdmin hasStatus hasOrganisation={canAccess(["SYS_ADMIN", "SUPPORT_ADMIN"])} />
      <JourneysPanel />
    </JourneysProvider>
  )
}

export default Journeys
