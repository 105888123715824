import styled from "styled-components"

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 2rem;
`

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
