import { object, string } from "yup"

function required() {
  return string().required("MISSING_REQUIRED_QUESTION_FIELD")
}

function requiredIf(present, val = "Y") {
  return string()
    .trim()
    .matches(
      /^[\w\s.,?!'"():;]+$/,
      "Please enter a valid sentence with letters, numbers, and common punctuation marks like .,?!'\"():; only."
    )
    .when(present, {
      is: value => value === val,
      then: schema => schema.required("MISSING_REQUIRED_QUESTION_SENTENCE"),
      otherwise: schema => schema.notRequired().nullable()
    })
}

export const newMoriSchema = object({
  "9c039e19-034c-4b86-ba17-5782b54ba426": required()
})

export const switchMoriSchema = object({
  "5e7ee828-0798-404d-a8d1-97275fcf0c84": required(),
  "1c7ce1a9-bb10-48de-9053-78fe225adb14": required(),
  "3d571e42-d36b-4b89-a40a-36e401b256de": requiredIf("1c7ce1a9-bb10-48de-9053-78fe225adb14"),
  "44877949-7f25-4223-b2fa-4542466914fe": required(),
  "d9adb6c7-01a4-48ad-9e9c-8ec625708ad4": requiredIf("44877949-7f25-4223-b2fa-4542466914fe"),
  "4edb61d3-e694-4776-b80c-031892089fb2": required(),
  "2ab03bf9-dc2b-494e-89b6-80fa4caa2a9c": requiredIf("4edb61d3-e694-4776-b80c-031892089fb2"),
  "5a346f78-a306-4dc6-96df-5dc1d9e1ced2": required(),
  "9580c571-266e-4473-9069-0f9450cb0114": requiredIf("5a346f78-a306-4dc6-96df-5dc1d9e1ced2"),
  "ff5a2ec2-4f55-4d0e-8c7c-3433526d3d93": required(),
  "ef893c65-c60d-4a64-b4a9-da4bbc99e42a": requiredIf("ff5a2ec2-4f55-4d0e-8c7c-3433526d3d93"),
  "e14385ca-2d14-4b5a-b2db-cdd97cf1f0b7": requiredIf("ef893c65-c60d-4a64-b4a9-da4bbc99e42a"),
  "cd21f566-3c8f-4819-802e-ebccab46ed0e": required(),
  "b24f6b0c-cb62-4fa0-b522-961868ec7b0d": requiredIf("cd21f566-3c8f-4819-802e-ebccab46ed0e"),
  "9488a8d6-d548-40d1-a1c4-5f62897497c5": required(),
  "4525d9c5-4285-4dda-84d3-5d79af75ff3f": requiredIf("9488a8d6-d548-40d1-a1c4-5f62897497c5")
})

export const newFmuSchema = object({
  "b501eb93-6e6f-4bde-bdee-f593d78c0207": required(),
  "ff92c551-d0f6-4271-8edd-c9698c693783": required(),
  "88e8f7cf-cc71-44e9-89b2-4086235469c7": required(),
  "89d9f984-3d79-447b-9949-148768b9de24": requiredIf("88e8f7cf-cc71-44e9-89b2-4086235469c7"),
  "21eb06f1-106d-412b-b3fc-b21279acb02b": required(),
  "a6a7130e-9a80-411b-a727-f262350027e9": requiredIf("21eb06f1-106d-412b-b3fc-b21279acb02b"),
  "485a3600-d028-43ce-910a-69d82d7ca465": required(),
  "3458072d-d1d5-47f1-b4f4-f326ee6050f0": requiredIf("485a3600-d028-43ce-910a-69d82d7ca465"),
  "5f2b39dd-a782-4292-9886-f6b03905f342": required(),
  "64365e55-9d97-4719-ae9a-f5358e32f5f4": requiredIf("5f2b39dd-a782-4292-9886-f6b03905f342"),
  "d898c082-7e90-47d3-8b91-c38849c94e84": required(),
  "b77b4f9f-25d9-4eaa-895e-c473d2825701": requiredIf("d898c082-7e90-47d3-8b91-c38849c94e84"),
  "390078b2-bbf7-488b-8b94-a920f2359880": required(),
  "70dca3ae-2617-458d-96a3-276f9d9e5d38": requiredIf("390078b2-bbf7-488b-8b94-a920f2359880"),
  "e05a38c4-935e-4f1f-bc20-5fcb8955ed8d": required(),
  "1499f334-93f7-4bcf-801a-3ce6e6ba97bb": requiredIf("e05a38c4-935e-4f1f-bc20-5fcb8955ed8d"),
  "2d37240e-90cb-4ee4-bb98-c9d8672d4f32": required(),
  "42011923-39d6-4883-a7a3-1de6f8922e75": requiredIf("2d37240e-90cb-4ee4-bb98-c9d8672d4f32"),
  "01d765e4-98fa-4339-ab27-8bc1ff73e1dc": required(),
  "cf71e1d0-d573-4505-a8ab-51a75edafe0c": requiredIf("01d765e4-98fa-4339-ab27-8bc1ff73e1dc"),
  "7334c41e-f917-4184-8c3b-3cfb3d95ba3a": required(),
  "db45977a-fdaa-407c-9535-bb4705f15730": requiredIf("7334c41e-f917-4184-8c3b-3cfb3d95ba3a"),
  "8972707b-7789-40dd-ab45-2e5a409810eb": requiredIf("db45977a-fdaa-407c-9535-bb4705f15730"),
  "3f6b0443-480f-476d-bd4a-d2a1d57a3809": required(),
  "a3119b3a-fb68-48e2-b75d-27972fda7fc8": requiredIf("3f6b0443-480f-476d-bd4a-d2a1d57a3809"),
  "ef2361ff-7d84-4aa6-a4a7-212dcd5ae6e7": required(),
  "a164ef0c-76ac-435d-b153-a803c78d1a87": requiredIf("ef2361ff-7d84-4aa6-a4a7-212dcd5ae6e7"),
  "87ac276f-df38-4b21-94c6-efa97f5c6a45": required(),
  "ac82a6e8-5fcc-4150-9b98-46108c24ed54": requiredIf("87ac276f-df38-4b21-94c6-efa97f5c6a45"),
  "4194fb92-cf16-45ab-adae-ef61ea5663ca": required(),
  "80f2f48b-a1bc-407b-8857-3394f3ca3a13": requiredIf("4194fb92-cf16-45ab-adae-ef61ea5663ca"),
  "14a4d9ed-a8b2-4b47-96f0-0965f996d3b5": required(),
  "c8e949e1-418a-4ea2-8e06-efca2f2e1f3f": requiredIf("14a4d9ed-a8b2-4b47-96f0-0965f996d3b5"),
  "0d4a550b-84d5-41a3-bcb1-813c3b927cab": required(),
  "57d4e06e-5658-4710-aff1-ff15470fe599": requiredIf("0d4a550b-84d5-41a3-bcb1-813c3b927cab"),
  "b576823c-22a5-4400-bf57-fced2829ab08": required(),
  "8e54704c-b373-4c64-975e-a119b8bf017f": requiredIf("b576823c-22a5-4400-bf57-fced2829ab08"),
  "5e4cfe4d-9f1f-4606-87d8-b2dcb04b9328": required(),
  "67839e9e-4b4f-41db-948d-12693dddb813": requiredIf("5e4cfe4d-9f1f-4606-87d8-b2dcb04b9328"),
  "0204ef77-ea39-4d5c-9679-948f10cf0114": required(),
  "0b822bd7-2957-4ef1-aa92-97a0cfcf7872": requiredIf("0204ef77-ea39-4d5c-9679-948f10cf0114"),
  "0e4f93b9-5e41-4cdc-a334-157858c2c403": required(),
  "3807c05f-a79c-42d8-a40a-2e7c22aab9da": requiredIf("0e4f93b9-5e41-4cdc-a334-157858c2c403"),
  "957dd6bb-c663-4272-bebb-66c4d9436075": required(),
  "b5c79564-f3ab-4362-be68-c144fef30b27": requiredIf("957dd6bb-c663-4272-bebb-66c4d9436075", "F"),
  "cb064408-7dfe-4c32-9261-8365bbed7285": requiredIf("b5c79564-f3ab-4362-be68-c144fef30b27"),
  "c0412ce3-9a31-432f-b0fb-b015b205a327": requiredIf("957dd6bb-c663-4272-bebb-66c4d9436075", "M"),
  "61a2d84a-ba70-45a8-9810-ff646cce9791": requiredIf("957dd6bb-c663-4272-bebb-66c4d9436075"),
  "7c23d62e-11bf-4a12-bf62-db802a5fda14": required(),
  "2401f569-566b-4e96-a48d-c62e20f631a1": requiredIf("7c23d62e-11bf-4a12-bf62-db802a5fda14"),
  "880db25f-55d0-410b-83d0-d038d87d75b9": requiredIf("2401f569-566b-4e96-a48d-c62e20f631a1"),
  "37818f95-1ca4-4e4c-905c-d9dd1545628f": required(),
  "7c754c1b-ab74-4405-bee3-af9bdf395712": requiredIf("37818f95-1ca4-4e4c-905c-d9dd1545628f"),
  "4757ce00-16ff-4621-afce-3706f65cd110": required(),
  "17a79efc-a32f-4448-ad6c-df22624a765e": requiredIf("4757ce00-16ff-4621-afce-3706f65cd110")
})

export const switchFmuSchema = object({
  "c2cc822e-09a3-4c1d-897c-739581f529ff": required(),
  "3afec110-8357-42f1-8875-70eacfca2bba": required(),
  "ac9b7fd1-5f19-48a6-8ae6-96ca2f7453c7": requiredIf("3afec110-8357-42f1-8875-70eacfca2bba"),
  "90f4c395-3921-448a-936d-73edd9d93037": required(),
  "e6e3ca5a-e1df-40cf-b511-6649628f883e": requiredIf("90f4c395-3921-448a-936d-73edd9d93037"),
  "fd01c2dd-dc36-43b1-a854-4fc23110dc19": required(),
  "ae9bec5d-b72a-4d35-92bc-c23e3c7ebcdb": requiredIf("fd01c2dd-dc36-43b1-a854-4fc23110dc19"),
  "8ef3d6b3-2a8b-4b42-a005-1e718cb1a6ff": required(),
  "9579e30e-c8f8-4982-828c-f3278b1e0b63": requiredIf("8ef3d6b3-2a8b-4b42-a005-1e718cb1a6ff"),
  "db284593-1f60-46a4-9d6f-9acf072e4d00": required(),
  "6e56b670-af84-4d7d-a67c-ed1e48b2e9fe": requiredIf("db284593-1f60-46a4-9d6f-9acf072e4d00"),
  "e92ce69c-02f9-4020-9ce3-69d4e0fbdc41": required(),
  "211d8f00-1f2d-4434-9447-32345763fd34": requiredIf("e92ce69c-02f9-4020-9ce3-69d4e0fbdc41"),
  "44431408-c032-4a6a-b148-db278e37534e": requiredIf("211d8f00-1f2d-4434-9447-32345763fd34"),
  "35ba60e3-391a-407b-a501-e82fdf953727": required(),
  "d439015d-8daf-45c2-bcdf-bfa33aa7c29b": requiredIf("35ba60e3-391a-407b-a501-e82fdf953727"),
  "2596eddf-7f11-492a-8a5d-2ffa5dca5eb7": required(),
  "411f803a-a47f-4687-9ec0-a29dbfc50ca6": requiredIf("2596eddf-7f11-492a-8a5d-2ffa5dca5eb7")
})

export const getValidationSchema = (underwritingStyle, underwritingType) => {
  if (underwritingStyle === "NEW" && underwritingType === "MORI") {
    return newMoriSchema
  }

  if (underwritingStyle === "SWITCH" && ["MORI", "CMORI"].includes(underwritingType)) {
    return switchMoriSchema
  }

  if (underwritingStyle === "NEW" && underwritingType === "FMU") {
    return newFmuSchema
  }

  if (underwritingStyle === "SWITCH" && ["FMU", "CME", "CPME"].includes(underwritingType)) {
    return switchFmuSchema
  }

  return {}
}
