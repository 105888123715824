import React, { useContext } from "react"

// Helpers
import { MyAccountQuickQuoteContext } from "./quickQuote.context"

// Components
import QQSettings from "../../Organisms/QuickQuoteSettings"

const QuickQuote = () => {
  const { quickQuoteData, quickQuoteLoading, updateQuickQuoteLoading, formik, handleSubmit } =
    useContext(MyAccountQuickQuoteContext)

  return (
    <QQSettings
      formik={formik}
      handleSubmit={handleSubmit}
      quickQuoteData={quickQuoteData}
      isLoading={quickQuoteLoading || updateQuickQuoteLoading}
    />
  )
}

export default QuickQuote
