import React, { useEffect, useContext, useReducer } from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import { useLocation, useHistory } from "react-router-dom"
import { get, sortBy, cloneDeep } from "lodash"
import { useGet, usePost, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { AuthContext } from "@4cplatform/elements/Auth"
import { OrganisationManageContext } from "../../../context/manage.context"
import {
  getOrderBy,
  canEditPolicyExclusionsRoles,
  getPolicyStatus
} from "../../../../../UI/Helpers"
import reducer from "./policies.reducer"

// Components
import { Provider } from "../../../../../UI/Templates/PolicyAdmin"

const OrganisationPoliciesProvider = ({ children }) => {
  const t = useTranslations()
  const { canAccess } = useContext(AuthContext)
  const { organisation } = useContext(OrganisationManageContext)

  const { search: lSearch } = useLocation()
  const history = useHistory()
  const qs = queryString.parse(lSearch)
  const queryStatus = get(qs, "status", "")
  const simulatedQuery = get(qs, "simulated", "")
  const tabQuery = get(qs, "tab", "")
  const statuses = getPolicyStatus(queryStatus)

  // State
  const [
    {
      data,
      viewData,
      showSimulated,
      hasShowSimulated,
      status,
      hasStatusFilter,
      search,
      filter,
      selectedPolicy,
      sorting,
      page,
      perPage,
      total,
      messages,
      messagingModal,
      exclusionsData,
      exclusionsModal,
      currentTab
    },
    dispatch
  ] = useReducer(reducer, {
    data: [],
    viewData: {},
    showSimulated: simulatedQuery === "true",
    hasShowSimulated: true,
    status: statuses,
    hasStatusFilter: true,
    search: "",
    selectedPolicy: null,
    sorting: { direction: "asc", dataKey: "created_at" },
    filter: "client_name",
    page: 1,
    perPage: 10,
    total: null,
    messagingModal: false,
    exclusionsModal: false,
    messages: [],
    exclusionsData: [],
    currentTab: tabQuery
  })

  const querySearch = {}
  if (search) querySearch[filter] = search
  if (status.length) querySearch.status = status

  // Index Policies query
  const {
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch
  } = useGet({
    endpoint: "/policies",
    query: {
      page,
      limit: perPage,
      order_by: getOrderBy(sorting),
      simulated: showSimulated,
      organisation_name: get(organisation, "name", ""),
      status
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () =>
      addAlert({
        message: t("POLICIES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Policy data query
  const {
    loading: viewLoading,
    error: viewError,
    refetch: viewRefetch
  } = useGet({
    endpoint: "/policies/:slug",
    skip: !selectedPolicy,
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    query: {
      with: [
        "organisation",
        "client",
        "salesAgent",
        "provider",
        "disclosureNotes",
        "medicalNotes",
        "applicants",
        "documentLinks"
      ]
    },
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: get(res, "data", {}) }),
    onError: () =>
      addAlert({
        message: t("POLICY_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  const onPolicyDeselect = () => {
    dispatch({ type: "UPDATE_VALUE", key: "viewData", value: {} })
    dispatch({ type: "UPDATE_VALUE", key: "selectedPolicy", value: null })
    dispatch({ type: "UPDATE_VALUE", key: "exclusionsData", value: [] })
    dispatch({ type: "UPDATE_VALUE", key: "messages", value: [] })
  }

  // Policy exclusions data query
  const {
    loading: exclusionsLoading,
    error: exclusionsError,
    refetch: exclusionsRefetch
  } = useGet({
    endpoint: "/policies/:slug/exclusions",
    skip:
      !selectedPolicy ||
      get(selectedPolicy, "status", "") !== "AWAITING_TERMS" ||
      !canAccess(canEditPolicyExclusionsRoles),
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "exclusionsData", value: get(res, "data", []) }),
    onError: () =>
      addAlert({
        message: t("EXCLUSIONS_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Get Policy messages
  const {
    loading: messagesLoading,
    error: messagesError,
    refetch: messagesRefetch
  } = useGet({
    endpoint: "/policies/:policy/messages",
    skip: !get(selectedPolicy, "slug") || !messagingModal,
    params: {
      policy: get(selectedPolicy, "slug", "")
    },
    onCompleted: res =>
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy(get(res, "data", {}), "sent_at")
      }),
    onError: () =>
      addAlert({
        message: t("MESSAGES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Create a message
  const [createMessage, { loading: submitMessageLoading, error: submitMessageError }] = usePost({
    endpoint: "/policies/:policy/message",
    params: {
      policy: get(selectedPolicy, "slug", "")
    },
    onCompleted: res => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy([...messages, res.data], "sent_at")
      })
    },
    onError: () => {
      messagesRefetch()
      addAlert({
        message: t("MESSAGE_SEND_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const submitMessage = ({ body, user }) => {
    const updatedMessages = [
      ...messages,
      {
        body_text: body.body_text,
        user_name: user
      }
    ]
    dispatch({
      type: "UPDATE_VALUE",
      key: "messages",
      value: updatedMessages
    })
    createMessage({ body })
  }

  useEffect(() => {
    dispatch({
      type: "UPDATE_VALUE",
      key: "status",
      value: queryStatus ? getPolicyStatus(queryStatus) : []
    })
  }, [queryStatus])

  useEffect(() => {
    dispatch({
      type: "UPDATE_VALUE",
      key: "showSimulated",
      value: simulatedQuery === "true"
    })
  }, [simulatedQuery])

  const [generateAuditLog, { loading: generateAuditLogLoading }] = usePost({
    endpoint: "/journeys/:slug/generate-audits",
    params: {
      slug: get(viewData, "journey_slug", "")
    },
    body: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      if (filePath) {
        window.open(filePath, "_blank")
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("AUDIT_LOG_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  const setSearch = React.useCallback(
    value => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        viewData,
        exclusionsData,
        showSimulated,
        hasShowSimulated,
        status,
        hasStatusFilter,
        search,
        selectedPolicy,
        sorting,
        page,
        perPage,
        total,
        messagingModal,
        exclusionsModal,
        queryLoading,
        queryRefetch,
        viewLoading,
        viewRefetch,
        exclusionsLoading,
        exclusionsRefetch,
        messages,
        messagesLoading,
        submitMessage,
        submitMessageLoading,
        generateAuditLogLoading,
        generateAuditLog,
        currentTab,
        setShowSimulated: value => {
          dispatch({ type: "UPDATE_VALUE", key: "showSimulated", value })
          history.replace({ search: queryString.stringify({ ...qs, simulated: value }) })
        },
        setFilter: value => dispatch({ type: "UPDATE_VALUE", key: "filter", value }),
        filter,
        setStatus: value => {
          if (!value) {
            dispatch({ type: "UPDATE_VALUE", key: "status", value: [] })
            const newQS = cloneDeep(qs)
            delete newQS.status
            history.replace({
              search: queryString.stringify(newQS)
            })
          } else {
            dispatch({ type: "UPDATE_VALUE", key: "status", value: getPolicyStatus(value) })
            history.replace({
              search: queryString.stringify({ ...qs, status: value })
            })
          }
        },
        setStatusFilter: value => dispatch({ type: "UPDATE_VALUE", key: "hasStatusFilter", value }),
        setSearch,
        onPolicySelect: value => dispatch({ type: "UPDATE_VALUE", key: "selectedPolicy", value }),
        onPolicyDeselect,
        onSort: value => dispatch({ type: "UPDATE_VALUE", key: "sorting", value }),
        pagination: { total, page, perPage },
        setPage: value => dispatch({ type: "UPDATE_VALUE", key: "page", value }),
        setPerPage: value => dispatch({ type: "UPDATE_VALUE", key: "perPage", value }),
        setMessagingModal: value =>
          dispatch({ type: "UPDATE_VALUE", key: "messagingModal", value }),
        setExclusionsModal: value =>
          dispatch({ type: "UPDATE_VALUE", key: "exclusionsModal", value })
      }}
    >
      {children}
      <ApiError
        error={queryError || viewError || exclusionsError || messagesError || submitMessageError}
      />
    </Provider>
  )
}

OrganisationPoliciesProvider.defaultProps = {
  children: null
}

OrganisationPoliciesProvider.propTypes = {
  children: PropTypes.any
}

export default OrganisationPoliciesProvider
