import React, { useContext, useCallback } from "react"
import moment from "moment"
import { get, isEmpty, isEqual, omit } from "lodash"
import { Select } from "@4cplatform/elements/Forms"
import { ComplianceNote, Button } from "@4cplatform/elements/Molecules"
import { H4, H3, P } from "@4cplatform/elements/Typography"

// Helpers
import { QuickQuoteContext } from "./quickQuote.context"

// Components
import { ApplicantDetailsWrapper, ApplicantsWrapper, GenerateWrapper } from "./quickQuote.styles"
import Applicants from "./quickQuote.details.applicants"

const ApplicantDetails = () => {
  const {
    requestQuotesLoading,
    requestQuotesError,
    previousBody,
    formik,
    arrivedQuotes,
    isDisabled
  } = useContext(QuickQuoteContext)

  const isChildOnlyQuote = useCallback(() => {
    const currentDateMinus18Years = moment().subtract(18, "years")

    const applicants = get(formik, "values.applicants", []).filter(
      applicant => applicant.date_of_birth
    )

    return (
      applicants.length > 0 &&
      applicants.every(applicant => {
        const applicantDateOfBirth = moment(applicant.date_of_birth)
        return applicantDateOfBirth.isAfter(currentDateMinus18Years)
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.applicants])

  const hasChanges =
    !isEmpty(previousBody) &&
    !isEqual(
      omit(get(formik, "values", {}), ["payment_frequency", "send_as_email"]),
      omit(previousBody, ["payment_frequency", "send_as_email"])
    )

  return (
    <ApplicantDetailsWrapper>
      <ApplicantsWrapper>
        <ComplianceNote type="error">
          <H4 margin="0 0 1rem">Compliance note</H4>
          <P margin="0">
            This page provides an indicative price only and does not constitute a formal quotation.
            <br />
            Additional information is required before a formal quotation can be offered.
          </P>
        </ComplianceNote>
        <P>
          Enter your client's name, date of birth and postcode to see a breakdown of basic,
          standard, and comprehensive cover prices.
        </P>
        <Applicants formik={formik} isDisabled={isDisabled} apiErrors={requestQuotesError} />
        {isChildOnlyQuote() && (
          <ComplianceNote type="info">
            <H4 margin="0 0 1rem">Child Only Quote</H4>
            <P margin="0">
              Not all providers offer a child only policy and therefore not all quotes may be
              returned.
            </P>
          </ComplianceNote>
        )}
      </ApplicantsWrapper>
      <GenerateWrapper>
        <H3 appearance="light" margin="0">
          Generate quotes
        </H3>
        <Select
          appearance="light"
          name="payment_frequency"
          label="Payment frequency"
          formik={formik}
          margin="0"
          width="100%"
          isDisabled={isDisabled}
        >
          <option value="MONTHLY">Monthly</option>
          <option value="YEARLY">Annually</option>
        </Select>
        <Button
          type="button"
          width="fit-content"
          appearance="whiteGhost"
          trailingIcon="autorenew"
          name="quick_quote_calculate"
          onClick={formik.handleSubmit}
          isLoading={requestQuotesLoading}
          isDisabled={
            isEmpty(requestQuotesError) && (isDisabled || (!isEmpty(arrivedQuotes) && !hasChanges))
          }
        >
          {!isEmpty(previousBody) ? "Recalculate" : "Calculate"}
        </Button>
      </GenerateWrapper>
    </ApplicantDetailsWrapper>
  )
}

export default ApplicantDetails
