import React from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import { useLocation, useHistory } from "react-router-dom"
import { get } from "lodash"
import { useGet, ApiError, usePost } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Components
import { Provider } from "../../../UI/Templates/JourneyAdmin"

// Helpers
import { getOrderBy } from "../../../UI/Helpers"
import reducer from "./journeys.reducer"

const JourneysProvider = ({ children }) => {
  const { search: lSearch } = useLocation()
  const qs = queryString.parse(lSearch)
  const t = useTranslations()

  const clientIdFilterParam = get(qs, "client_id", null)
  const history = useHistory()
  // State
  const [
    {
      data,
      viewData,
      status,
      hasStatusFilter,
      search,
      filter,
      selectedJourney,
      sorting,
      page,
      perPage,
      total,
      showSimulated,
      hasShowSimulated
    },
    dispatch
  ] = React.useReducer(reducer, {
    data: [],
    viewData: {},
    status: get(qs, "status", ""),
    hasStatusFilter: false,
    search: "",
    selectedJourney: null,
    filter: "reference",
    sorting: { direction: "desc", dataKey: "created_at" },
    page: 1,
    perPage: 10,
    total: null,
    showSimulated: get(qs, "simulated", "") === "true",
    hasShowSimulated: true
  })

  // Set the query search if search is truthy
  const querySearch = {}

  if (clientIdFilterParam) querySearch.client_id = clientIdFilterParam

  if (search) querySearch[filter] = search

  // Index Journeys query
  const { loading: queryLoading, error: queryError } = useGet({
    endpoint: "/journeys",
    query: {
      limit: perPage,
      order_by: getOrderBy(sorting),
      simulated: showSimulated,
      status,
      page,
      ...querySearch
    },
    onCompleted: res =>
      dispatch({
        type: "FETCH_COMPLETE",
        total: get(res, "pagination.totalItems"),
        data: get(res, "data", [])
      }),
    onError: () => {
      addAlert({
        message: t("JOURNEYS_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Journey data query
  const { loading: viewLoading, error: viewError } = useGet({
    endpoint: "/journeys/:slug",
    skip: !selectedJourney,
    params: {
      slug: get(selectedJourney, "slug", "")
    },
    query: {
      with: ["client", "salesAgent", "selectedQuote"]
    },
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: get(res, "data", {}) }),
    onError: () => {
      addAlert({
        message: t("JOURNEY_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const [generateAuditLog, { loading: generateAuditLogLoading }] = usePost({
    endpoint: "/journeys/:slug/generate-audits",
    headers: {
      "cache-control": "no-cache"
    },
    params: {
      slug: get(viewData, "journey.slug", "")
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      if (filePath) {
        window.open(filePath, "_blank")
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("AUDIT_LOG_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        viewData,
        status,
        hasStatusFilter,
        search,
        selectedJourney,
        sorting,
        page,
        perPage,
        total,
        queryLoading,
        viewLoading,
        filter,
        generateAuditLogLoading,
        generateAuditLog,
        setStatus: val => dispatch({ type: "UPDATE_VALUE", key: "status", value: val }),
        setHasStatusFilter: val =>
          dispatch({ type: "UPDATE_VALUE", key: "hasStatusFilter", value: val }),
        setSearch,
        onJourneySelect: row =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedJourney", value: row }),
        onJourneyDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedJourney", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "viewData", value: {} })
        },
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        pagination: { total, page, perPage },
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        setFilter: val => dispatch({ type: "UPDATE_VALUE", key: "filter", value: val }),
        setShowSimulated: val => {
          dispatch({ type: "UPDATE_VALUE", key: "showSimulated", value: val })
          history.replace({ search: queryString.stringify({ ...qs, simulated: val }) })
        },
        showSimulated,
        hasShowSimulated
      }}
    >
      {children}
      <ApiError error={queryError || viewError} />
    </Provider>
  )
}

JourneysProvider.defaultProps = {
  children: null
}

JourneysProvider.propTypes = {
  children: PropTypes.any
}

export default JourneysProvider
