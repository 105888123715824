import { get, isEmpty } from "lodash"

/**
 * This is a helper function which generates a human-readable name from an object with typical name keys inside
 * @param {data, hasMiddle, hasTitle} param0
 * data is the object from which the name is extracted
 * hasMiddle is a boolean which controls whether or not to include the middle name, if it exists
 * hasTitle is a boolean which controls whether or not to include the title, if it exists
 * hideOtherTitle is a boolean which controls whether or not to display title 'Other'
 */
export const getName = ({ data, hasMiddle, hasTitle, isContact, globalTitles, hideOtherTitle }) => {
  let first = get(data, "first_name")
  let last = get(data, "last_name")
  const middle = get(data, "middle_names", "")
  const title = (() => {
    let _title = get(
      data,
      "title.label",
      get(globalTitles, `data.${get(data, "title")}.title`, get(data, "title"))
    )
    if (_title === "Other" && hideOtherTitle) {
      _title = ""
    }

    return _title
  })()

  if (isContact) {
    first = get(data, "contact_first_name")
    last = get(data, "contact_last_name")
  }

  let value = ""

  if (!first || !last) {
    return "-"
  }

  if (!!hasMiddle && !!middle) {
    value = `${first} ${middle} ${last}`
  } else {
    value = `${first} ${last}`
  }

  if (!!hasTitle && !!title) {
    value = `${title} ${value}`
  }

  return value
}

/**
 * This returns a string for the order_by key in API hooks
 * @param {*} sorting
 * @returns a string for use in the order_by query
 */
export const getOrderBy = (sorting = {}) => {
  if (isEmpty(sorting)) return null

  const { dataKey, direction } = sorting
  // Only sort by the final key if sorting nested properties
  const splitKey = dataKey.split(".")
  if (direction === "desc") {
    return `${splitKey[splitKey.length - 1]}_desc`
  }
  return splitKey[splitKey.length - 1]
}

/**
 * This is a helper RegEx constant for valid names.
 */
export const validNameRegex = /^(?!')(?!.*'')(?!-)(?!.*--)[A-Za-z '-]+$/

/**
 * This is a helper RegEx constant for validating postcodes.
 */
export const validPostcodeRegex =
  /^(A[BL]|B[ABDFHLNRSTX]?|C[ABFHMORTVW]|D[ADEGHLNTY]|E[CHNX]?|F[KY]|G[LUY]?|H[ADGPRSUX]|I[GMPV]|JE|K[ATWY]|L[ADELNSU]?|M[EKL]?|N[EGNPRW]?|O[LX]|P[AEHLOR]|R[GHM]|S[AEGKLMNOPRSTWY]?|T[ADFNQRSW]|UB|W[ACDFNRSV]?|YO|ZE)(\d[\dA-Z]?) ?(\d)([A-Z]{2})$/i

/**
 * This is a helper RegEx constant for valid email following RFC and DNS standards.
 */
export const validEmailRegex =
  /^(?!\.)(""[\w-+\s]+""|""[\w\s]+""|[a-zA-Z0-9_.+-])+(?<!\.)@(?!\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)$/

/**
 * This is a helper RegEx constant for valid number with 2 decimal and greater than 0.
 */
export const validDecimalNumberGreaterThan0Regex = /^(?!0+(\.0{0,2})?$)\d+(?:\.\d{2})?$/
