import React from "react"
import { get } from "lodash"
import { P } from "@4cplatform/elements/Typography"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { AgencyCodesContext } from "../../agencyCodes.context"

const DeclineAgencyCode = () => {
  const { viewData, setDeclineModal, onDecline, declineLoading } =
    React.useContext(AgencyCodesContext)

  return (
    <>
      <ConfirmationModal
        title="Decline Agency Code"
        confirmText="Decline"
        confirmAppearance="error"
        confirmIcon="close"
        cancelAppearance="errorGhost"
        isLoadingConfirm={declineLoading}
        onClose={() => {
          setDeclineModal(false)
        }}
        onConfirm={onDecline}
        onCancel={() => {
          setDeclineModal(false)
        }}
      >
        <P>
          Are you sure you want to decline the <strong>{get(viewData, "agency_code")}</strong>{" "}
          Agency Code addition?
        </P>
      </ConfirmationModal>
    </>
  )
}

export default DeclineAgencyCode
