import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { v4 as uuid } from "uuid"
import { isEmpty, isNull, get } from "lodash"

import { nullFunc, colours } from "@4cplatform/elements/Helpers"
import { SmallText } from "@4cplatform/elements/Typography"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { Button } from "@4cplatform/elements/Molecules"
import { DivFlexCenter } from "./fileList.style"

const FileList = ({ files, emptyFilesMessage, isError, handleClearForm, isLoading }) => {
  // Return null if a null value is passed, to avoid trying to use .map on null
  if (isNull(files)) return null
  // Create an array from the file list if this is necessary
  const list = Array.isArray(files) ? files : Array.from(files)

  return (
    <>
      {isEmpty(list) ? (
        <SmallText>{emptyFilesMessage}</SmallText>
      ) : (
        <DivFlexCenter>
          {list.map(file => (
            <Fragment key={uuid()}>
              <IconWithText
                icon={isError ? "close-circle" : "check-circle"}
                content={get(file, "name", "-")}
                iconColour={isError ? get(colours, "red", "red") : get(colours, "green", "green")}
                iconSpacing="0.5rem"
                fontColour={get(colours, "tints.secondary.darkBlue.t20", "black")}
              />

              <Button
                onClick={handleClearForm}
                appearance="errorInline"
                trailingIcon="delete"
                isLoading={isLoading}
                isDisabled={isLoading}
                type="inline-button"
                name="delete_selected_hospital"
                margin="0 1rem 0"
              />
            </Fragment>
          ))}
        </DivFlexCenter>
      )}
    </>
  )
}

FileList.defaultProps = {
  files: null,
  emptyFilesMessage: "No files have been selected",
  handleClearForm: nullFunc,
  isLoading: false,
  isError: false
}

FileList.propTypes = {
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  emptyFilesMessage: PropTypes.string,
  handleClearForm: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool
}

export default FileList
