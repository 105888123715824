import React, { useContext } from "react"
import PropTypes from "prop-types"

// Helpers
import { ClientsContext } from "./clients.context"

// Components
import { RoundedButton, RoundedButtonsWrapper } from "./clients.styles"

const buttons = ["Journey", "Quotes", "Policies", "Notes"]

const ClientsPanelButtons = ({ margin }) => {
  const { panelBodyContent, setPanelBodyContent } = useContext(ClientsContext)

  return (
    <RoundedButtonsWrapper margin={margin}>
      {buttons.map((button, i) => (
        <RoundedButton
          key={`button_${i}`}
          className={panelBodyContent === button && "active"}
          appearance="whiteGhost"
          onClick={() => setPanelBodyContent(button)}
        >
          {button}
        </RoundedButton>
      ))}
    </RoundedButtonsWrapper>
  )
}

ClientsPanelButtons.defaultProps = {
  margin: ""
}
ClientsPanelButtons.propTypes = {
  margin: PropTypes.string
}

export default ClientsPanelButtons
