import React from "react"
import PropTypes from "prop-types"
import { Skeleton } from "@4cplatform/elements/Molecules"

// Helpers
import { CommissionRatesContext } from "./commissionRates.context"

// Components
import Actions from "./commissionRates.actions"
import AddConfirmationModal from "./commissionRates.add"
import { Wrapper } from "./commissionRates.styles"

const CommissionRatesAdmin = () => {
  const { data, queryLoading, isOpenAddModal } = React.useContext(CommissionRatesContext)

  return (
    <>
      <LoadingWraper isLoading={queryLoading} isNoData={!data?.length}>
        {data.map(provider => (
          <Actions key={provider.id} provider={provider} />
        ))}
      </LoadingWraper>

      {isOpenAddModal && <AddConfirmationModal />}
    </>
  )
}

const LoadingWraper = ({ isLoading, isNoData, children }) => {
  if (isLoading && isNoData) {
    return <Skeleton lineHeight="10rem" />
  }

  return <Wrapper isLoading={isLoading}>{children}</Wrapper>
}

LoadingWraper.propTypes = {
  isLoading: PropTypes.bool,
  isNoData: PropTypes.bool,
  children: PropTypes.any
}

export default CommissionRatesAdmin
