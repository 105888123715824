import React from "react"
import { AuthContext } from "@4cplatform/elements/Auth"
import { Select, Checkbox } from "@4cplatform/elements/Forms"
import { useTranslations } from "@4cplatform/elements/Translations"
import queryString from "query-string"
import { useLocation } from "react-router-dom"
import { get } from "lodash"

// Helpers
import { JourneysContext } from "./journeys.context"

// Components
import { Search } from "../../Molecules"
import { TableActionsWrapper, TableActionsLeft, TableActionsRight } from "./journeys.styles"

const Actions = () => {
  const {
    setSearch,
    hasStatusFilter,
    setStatus,
    status,
    setFilter,
    filter,
    setShowSimulated,
    showSimulated,
    hasShowSimulated
  } = React.useContext(JourneysContext)
  const { canAccess } = React.useContext(AuthContext)
  const { search } = useLocation()
  const qs = queryString.parse(search)
  const t = useTranslations()
  const statuses = ["IN_PROGRESS", "COMPLETE"]

  const clientIdFilterParam = get(qs, "client_id", null)
  const isSystemUser = canAccess(["SYS_ADMIN", "SUPPORT_ADMIN", "NETWORK_ADMIN"])
  return (
    <TableActionsWrapper
      data-testid="journeys-actions-wrapper"
      hasLeftActions={hasStatusFilter || hasShowSimulated}
    >
      <TableActionsLeft>
        {hasShowSimulated && (
          <Checkbox
            label="Show simulated policies"
            margin="0 1rem 0 0"
            name="showSimulated"
            value={showSimulated}
            onChange={setShowSimulated}
            data-testid="policy-admin-show-simulated"
          />
        )}
        {hasStatusFilter && (
          <Select
            name="filter_status"
            label="Filter status by"
            onChange={val => setStatus(val)}
            margin="0"
            value={status}
            labelWidth="auto"
            isHorizontal
          >
            {statuses.map(key => (
              <option key={key} value={key}>
                {t(key)}
              </option>
            ))}
          </Select>
        )}
      </TableActionsLeft>
      <TableActionsRight>
        <Select
          name="filter_search"
          onChange={val => setFilter(val)}
          margin="0 1rem 0 0"
          value={filter}
          label="Search by"
          labelWidth="auto"
          isHorizontal
        >
          {isSystemUser && <option value="organisation_name">Organisation name</option>}
          <option value="reference">Reference</option>
          {!clientIdFilterParam && <option value="client_name">Client name</option>}
          <option value="sales_agents_name">Sales agent name</option>
        </Select>
        <Search
          name="search_journeys"
          handleChange={setSearch}
          onCancel={() => setSearch("")}
          margin="0"
        />
      </TableActionsRight>
    </TableActionsWrapper>
  )
}

export default Actions
