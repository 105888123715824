import React, { useContext } from "react"
import { get } from "lodash"
import { H3, P } from "@4cplatform/elements/Typography"
import { TextArea } from "@4cplatform/elements/Forms"
import { useFormik } from "formik"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { PoliciesContext } from "../../policies.context"
import { getName } from "../../../../Helpers"

const DeclinePolicy = () => {
  const { viewData, setDeclinePolicyModal, onDeclinePolicy, declinePolicyLoading } =
    useContext(PoliciesContext)
  const { GLOBAL_TITLES } = useContext(ConfigContext)

  const name = getName({
    data: get(viewData, "client"),
    hasTitle: true,
    hasMiddle: true,
    globalTitles: GLOBAL_TITLES
  })
  const providerName = get(viewData, "provider.name")
  const monthly = get(viewData, "monthly_premium", "")
  const monthsOfCover = get(viewData, "months_of_cover", "")
  const reference = get(viewData, "reference", "-")
  const salesAgentParent = get(viewData, "sales_agent.parent.name")

  const declinePolicyFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      declined_reason: ""
    },
    onSubmit: body => onDeclinePolicy({ body })
  })

  const { handleSubmit } = declinePolicyFormik
  const formik = { ...declinePolicyFormik }

  return (
    <>
      <ConfirmationModal
        formik={formik}
        hasPadding={false}
        hasTrailingIcon={false}
        leadingIcon="arrow-left"
        confirmText="Go back"
        confirmAppearance="primary"
        cancelIcon="check"
        cancelText="Yes, decline"
        cancelAppearance="error"
        isLoadingCancel={declinePolicyLoading}
        onClose={() => {
          setDeclinePolicyModal(false)
        }}
        onConfirm={() => {
          setDeclinePolicyModal(false)
        }}
        onCancel={handleSubmit}
      >
        <H3 margin="0 0 1rem">{`Decline policy ${reference}`}</H3>
        <P>{`This action will decline the policy by ${salesAgentParent} for:`}</P>
        <P margin="0">{name}</P>
        <P margin="0">{providerName}</P>
        <P margin="0">£{monthly} per month</P>
        <P>{monthsOfCover} months of cover</P>

        <TextArea
          name="declined_reason"
          margin="0 0 3rem"
          isRequired
          formik={formik}
          placeholder="e.g Request more information"
          label="Reason for declining policy"
        />
      </ConfirmationModal>
    </>
  )
}

export default DeclinePolicy
