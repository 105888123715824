import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { Input } from "@4cplatform/elements/Forms"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { disableButton } from "./networkInvitations.helpers"

// Components
import { InviteOrganisationFormWrapper } from "./networkInvitations.styles"

const Form = ({ formik, handleSubmit }) => (
  <>
    <InviteOrganisationFormWrapper>
      {/* Email address */}
      <Input label="Email address" formik={formik} name="email_address" errorPrefix="none" />
      {/* Add New Invitation button */}
      {!disableButton(get(formik, "values", {})) && (
        <Button name="add_new" leadingIcon="plus" type="inline-button" onClick={handleSubmit}>
          Add another organisation
        </Button>
      )}
    </InviteOrganisationFormWrapper>
  </>
)

Form.propTypes = {
  formik: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default Form
