import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { Button } from "@4cplatform/elements/Molecules"
import { useGet } from "@4cplatform/elements/Api"
import { P } from "@4cplatform/elements/Typography"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"
import { OrganisationManageContext } from "../../../../pages/OrganisationManage/context/manage.context"
import { getName } from "../../../Helpers"

// Components
import CheckedItemModal from "../components/CheckedItemModal/checkedItemModal"

const AddUsers = ({ isDisabled, onConfirm }) => {
  const { selectedAgencyCode, sharedUsers } = React.useContext(AgencyCodesContext)
  const { organisation } = React.useContext(OrganisationManageContext)

  const [isOpen, setOpen] = React.useState(false)
  const [keyword, setKeyword] = React.useState("")
  const [selectedItems, setSelectedItems] = React.useState([])

  // The API caller should be here to prevent the UI flickering issue
  const { data, loading } = useGet({
    endpoint: "/organisations/:slug/agency-codes/:code/users-not-shared-with",
    params: {
      slug: get(organisation, "slug", ""),
      code: get(selectedAgencyCode, "slug", "")
    },
    query: {
      search: keyword
    }
  })

  React.useEffect(() => {
    if (isOpen && data?.length && selectedItems.length === 0) {
      setSelectedItems(
        data.map(u => ({ id: u.id, name: `${u.first_name} ${u.last_name}`, checked: false }))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data])

  return (
    <>
      <Button
        type="inline-button"
        appearance="whiteInline"
        leadingIcon="plus-circle-outline"
        onClick={() => setOpen(true)}
        isDisabled={isDisabled}
      >
        Share
      </Button>
      {isOpen && (
        <CheckedItemModal
          data={selectedItems}
          title="Add users to share"
          submitAppearance="success"
          onClose={() => {
            setOpen(false)
            setSelectedItems([])
          }}
          label={{
            name: "User",
            submit: "Share with selected"
          }}
          isLoading={loading}
          searchProps={{
            label: "Select user",
            onCancel: () => setKeyword(""),
            onChange: value => setKeyword(value),
            isLoading: !!keyword && loading,
            onSelect: ({ id, label }) => {
              setSelectedItems(values => {
                if (values.find(value => value.id === id)) {
                  return [
                    ...values.filter(value => value.checked && value.id !== id),
                    { id, name: label, checked: true }
                  ]
                }
                return [...values, { id, name: label, checked: true }]
              })
            },
            suggestions:
              data
                ?.filter(({ id }) => !sharedUsers?.find(user => user.id === id))
                .map(({ id, ...rest }) => ({ id, label: getName({ data: rest }) })) ?? []
          }}
          confirmationProps={{
            confirmIcon: "check",
            renderContent: checkedItem => (
              <P>
                {`Are you sure you want to share this agency code with ${
                  Object.values(checkedItem).filter(val => val === true).length > 1 &&
                  Object.values(checkedItem).every(isChecked => isChecked)
                    ? "all users"
                    : "the selected users"
                } ?`}
              </P>
            )
          }}
          onChecked={checkedItem => {
            setSelectedItems(values =>
              values.map(item => ({ ...item, checked: checkedItem[item.id] }))
            )
          }}
          onSubmit={async checkedItem => {
            await onConfirm(Object.keys(checkedItem).filter(key => checkedItem[key]))
            setOpen(false)
            setSelectedItems([])
          }}
        />
      )}
    </>
  )
}

AddUsers.propTypes = {
  isDisabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired
}

export default AddUsers
