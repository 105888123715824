import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { FieldArray, FormikProvider } from "formik"
import { get, find, findIndex, isEmpty } from "lodash"
import { Select, Input, DatePicker } from "@4cplatform/elements/Forms"
import { Button } from "@4cplatform/elements/Molecules"
import { H4 } from "@4cplatform/elements/Typography"

// Helpers
import { hasFormikErrorsField } from "@4cplatform/elements/Helpers"

// Components
import {
  Wrapper,
  ItemWrapper,
  FieldsWrapper,
  FieldWrapper,
  ItemHeader,
  InnerWrapper
} from "./quickQuote.styles"

const Applicants = ({ formik, isDisabled, margin, apiErrors }) => {
  const items = get(formik, "values.applicants", [])

  const renderTypes = index => {
    const primary = find(items, { type: "primary" })
    const primaryIndex = findIndex(items, { type: "primary" })
    const partner = find(items, { type: "partner" })
    const partnerIndex = findIndex(items, { type: "partner" })

    let options = [
      <option value="dependant" key="dependant">
        Dependant
      </option>
    ]
    if (!partner || partnerIndex === index) {
      options = [
        <option value="partner" key="partner">
          Partner
        </option>,
        ...options
      ]
    }
    if (!primary || primaryIndex === index) {
      options = [
        <option value="primary" key="primary">
          Primary
        </option>,
        ...options
      ]
    }
    return options
  }

  const handleTypeChange = (value, index, arrayHelpers) => {
    const primary =
      value === "primary"
        ? {
            first_name: "",
            last_name: "",
            postcode: ""
          }
        : {}

    arrayHelpers.replace(index, {
      type: value,
      date_of_birth: get(items, `[${index}].date_of_birth`, ""),
      ...primary
    })
  }

  return (
    <FormikProvider value={formik}>
      <Wrapper margin={margin} data-testid="applicants_fields-wrapper">
        <FieldArray
          name="applicants"
          render={arrayHelpers => (
            <>
              <InnerWrapper data-testid="applicants_fields-inner_wrapper">
                {items.map((item, index) => (
                  <ItemWrapper
                    index={index}
                    key={`applicant-${index}`}
                    data-testid={`applicants_fields-item_wrapper_${index}`}
                    isLast={index + 1 === items.length}
                  >
                    <ItemHeader>
                      <H4 margin="0">{`Applicant ${index + 1}`}</H4>
                      {/* Remove field button */}
                      {index > 0 && (
                        <Button
                          type="inline-button"
                          leadingIcon="trash-can"
                          appearance="errorInline"
                          onClick={() => {
                            arrayHelpers.remove(index)
                          }}
                          name={`applicants_fields-cancel_${index}`}
                          isDisabled={isDisabled}
                        />
                      )}
                    </ItemHeader>
                    <FieldsWrapper>
                      <FieldWrapper>
                        <DatePicker
                          name={`applicants.${index}.date_of_birth`}
                          label="Date of birth"
                          formik={formik}
                          isDisabled={isDisabled}
                          isDOBInput
                          dateRangeMax={
                            item.type === "partner"
                              ? moment().subtract(18, "years").format("DD/MM/YYYY")
                              : null
                          }
                          dateRangeMin={
                            item.type === "dependant"
                              ? moment().subtract(18, "years").format("DD/MM/YYYY")
                              : null
                          }
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <Select
                          name={`applicants.${index}.type`}
                          label="Type"
                          margin="0"
                          apiErrors={apiErrors}
                          width="100%"
                          onChange={val => handleTypeChange(val, index, arrayHelpers)}
                          value={item.type}
                          error={
                            // eslint-disable-next-line no-nested-ternary
                            hasFormikErrorsField(formik, `applicants.${index}.type`)
                              ? "is required"
                              : hasFormikErrorsField(formik, "applicants") &&
                                get(formik, "errors.applicants") === "true"
                              ? "of applicants must contain one primary applicant"
                              : null
                          }
                          isDisabled={isDisabled}
                          isRequired
                        >
                          <option value="">Select type</option>
                          {renderTypes(index)}
                        </Select>
                      </FieldWrapper>
                      {item.type === "primary" && (
                        <>
                          <FieldWrapper>
                            <Input
                              name={`applicants.${index}.first_name`}
                              label="First name"
                              formik={formik}
                              margin="0"
                              apiErrors={apiErrors}
                              isDisabled={isDisabled}
                              isRequired
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <Input
                              name={`applicants.${index}.last_name`}
                              label="Last name"
                              formik={formik}
                              margin="0"
                              apiErrors={apiErrors}
                              isDisabled={isDisabled}
                              isRequired
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <Input
                              name={`applicants.${index}.postcode`}
                              label="Postcode"
                              formik={formik}
                              margin="0"
                              apiErrors={apiErrors}
                              isDisabled={isDisabled}
                              isRequired
                            />
                          </FieldWrapper>
                        </>
                      )}
                    </FieldsWrapper>
                  </ItemWrapper>
                ))}
              </InnerWrapper>

              {/* Add new field to the array */}
              <Button
                type="inline-button"
                appearance="primaryInline"
                leadingIcon="plus"
                isDisabled={isDisabled}
                onClick={() => {
                  const hasPrimary = !isEmpty(find(items, { type: "primary" }))

                  arrayHelpers.push(
                    hasPrimary
                      ? {
                          type: "",
                          date_of_birth: ""
                        }
                      : {
                          type: "",
                          date_of_birth: "",
                          first_name: "",
                          last_name: "",
                          postcode: ""
                        }
                  )
                }}
                name="applicants_add_new"
                margin="1rem 0 0 0"
              >
                Add new applicant
              </Button>
            </>
          )}
        />
      </Wrapper>
    </FormikProvider>
  )
}

Applicants.defaultProps = {
  margin: "0 0 2rem",
  isDisabled: false,
  apiErrors: {}
}

Applicants.propTypes = {
  /**
   * The formik instance that controls this component
   */
  formik: PropTypes.object.isRequired,
  /**
   * The margin prop passed to the wrapper
   */
  margin: PropTypes.string,
  /**
   * The boolean that determines if the component is disabled or not
   */
  isDisabled: PropTypes.bool,
  /**
   * API errors, passed through for error handling
   */
  apiErrors: PropTypes.object
}

export default Applicants
