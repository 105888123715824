import React, { useContext, useState, useEffect, useCallback } from "react"
import moment from "moment"
import { get, map, isEmpty } from "lodash"
import { usePatch, PusherContext } from "@4cplatform/elements/Api"
import { Button, ComplianceNote, Modal, HelperText } from "@4cplatform/elements/Molecules"
import { H4, SmallText, P } from "@4cplatform/elements/Typography"
import { Toggle } from "@4cplatform/elements/Forms"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { JourneyContext } from "../../../../../../journey.context"

const SubmitToProviderButtons = () => {
  const [policy, setPolicy] = useState(null)
  const [exclusionModal, setExclusionModal] = useState({
    isOpen: false,
    personReference: "",
    exclusionText: ""
  })
  const { selfServiceData } = useContext(PageContext)
  const { client: pusher } = useContext(PusherContext)
  const [notificationText, setNotificationText] = useState(null)
  const {
    data,
    refetchData,
    onPageSubmit,
    setCustomPageSubmit,
    formik,
    isLoading,
    formik: { values },
    updateJourneyAuditData,
    submitJourneyAudit
  } = useContext(JourneyContext)

  const status = get(policy, "status", null)
  const isLocked = get(data, "journey.locked", false)

  const pageSubmit = () => {
    if (!values.client_happy_to_proceed_with_providers_terms) {
      addAlert({
        message:
          "It is not possible to continue with the sales journey if the client has not confirmed that they are happy to proceed.",
        type: "error",
        dismissible: true,
        timeout: 5
      })
      return
    }

    if (status === "IN_PROGRESS") {
      addAlert({
        message: "Unable to progress while policy is in in progress status.",
        type: "error",
        dismissible: true,
        timeout: 5
      })
      return
    }

    if (["AWAITING_TERMS", "MORE_INFO_REQUIRED"].includes(status)) {
      addAlert({
        message: "Unable to progress while policy is in awaiting underwriting status.",
        type: "error",
        dismissible: true,
        timeout: 5
      })
      return
    }

    onPageSubmit()
  }

  const onSubmitAudit = () => {
    const auditData = [
      {
        name: "Submit?",
        value: "Yes"
      },
      {
        name: "Client happy to proceed?",
        value: formik.values.client_happy_to_proceed_with_providers_terms ? "Yes" : "No"
      }
    ]

    submitJourneyAudit({
      page: "Submit to provider",
      responses: auditData
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    const value = get(data, "payload.policy", get(data, "journey.policy", null))

    if (value) {
      setPolicy(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (policy) {
      if (["AWAITING_TERMS"].includes(status)) {
        setNotificationText(<P margin="0">Policy has been submitted to provider.</P>)
      }

      if (["ACCEPTED_UNDERWRITING"].includes(status)) {
        setNotificationText(<P margin="0">Client journey has been cleared to proceed.</P>)
      }

      if (["ACCEPTED_UNDERWRITING_WITH_EXCLUSIONS"].includes(status)) {
        setNotificationText(
          <>
            <H4 margin="0 0 1rem">Client journey has been cleared to proceed.</H4>
            <P margin="0">
              The provider has accepted this policy with exclusions. A full table of these can be
              found below:
            </P>
          </>
        )
      }

      if (["MORE_INFO_REQUIRED"].includes(status)) {
        setNotificationText(
          <>
            <H4 margin="0 0 1rem">
              The provider has requested more information about this policy. Please view the notes
              as seen below, address them, and submit the policy again.
            </H4>
            <P margin="0 0 1rem">Note: {policy.more_info_required}</P>
            <SmallText margin="0">
              Date created:{" "}
              {policy.updated_at
                ? moment.utc(policy.updated_at).local().format("DD/MM/YYYY HH:mm:ss")
                : "-"}
            </SmallText>
          </>
        )
      }

      if (["DECLINED_UNDERWRITING"].includes(status)) {
        setNotificationText(
          <>
            <H4 margin="0 0 1rem">Policy underwriting has been declined by provider.</H4>
            <P margin="0">{policy.underwriting_declined_reason}</P>
          </>
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy])

  useEffect(() => {
    setCustomPageSubmit(() => pageSubmit())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, policy])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setCustomPageSubmit(null), [])

  // Submit To Provider request
  const [submitPolicy, { loading }] = usePatch({
    endpoint: "/journeys/:journey/policies/:policy/submit-to-provider",
    params: {
      journey: get(data, "journey.slug", ""),
      policy: get(policy, "slug", "")
    },
    onCompleted: () => {
      onSubmitAudit()
      refetchData()
    },
    onError: () => {
      addAlert({
        message: "There was an error submitting the policy.",
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const getToggleValue = row => {
    if (values.applicants && !isEmpty(values.applicants)) {
      return !isEmpty(
        values.applicants.filter(applicant => applicant.applicant_id === row.data.applicant_id)
      )
    }
    return false
  }
  const onProviderAnswer = useCallback(res => {
    const policyStatus = get(res, "policyStatus", "")
    if (policyStatus) {
      refetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pusher) {
      const channelName = `private-encrypted-user.${get(selfServiceData, "id")}`
      const eventName = "4cng.change_policy_status"

      if (get(data, "journey.product_type") && get(selfServiceData, "id")) {
        const channels = pusher.allChannels().map(ch => ch.name)

        const channel = channels.includes(channelName)
          ? pusher.allChannels().filter(ch => ch.name === channelName)[0]
          : pusher.subscribe(channelName)

        channel.bind(eventName, onProviderAnswer)
      }

      return () => {
        const channels = pusher.allChannels().map(ch => ch.name)

        const channel = channels.includes(channelName)

        if (channel) {
          pusher.unbind(eventName)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const auditData = [
      {
        name: "Submit?",
        value: "Yes"
      }
    ]

    if (policy && status === "ACCEPTED_UNDERWRITING_WITH_EXCLUSIONS") {
      const exclusionList = map(get(policy, "exclusions", []), exclusion => ({
        name: exclusion.applicant_name,
        value: exclusion.exclusion
      }))
      auditData.push({
        name: "Exclusions",
        value: exclusionList
      })
    }
    auditData.push({
      name: "Client happy to proceed?",
      value: formik.values.client_happy_to_proceed_with_providers_terms ? "Yes" : "No"
    })

    updateJourneyAuditData([
      {
        mode: "replace",
        data: auditData
      }
    ])

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, policy])
  return (
    <>
      {status === "IN_PROGRESS" && (
        <Button
          appearance="primary"
          trailingIcon="arrow-right"
          onClick={() => submitPolicy()}
          name="submit_policy_provider"
          width="fit-content"
          margin="2rem 4rem 0rem 0rem"
          isDisabled={loading || isLoading || isLocked}
        >
          Submit policy to provider
        </Button>
      )}
      {notificationText && (
        <ComplianceNote type="info" margin="2rem 0">
          {notificationText}
        </ComplianceNote>
      )}
      {policy && ["DECLINED_UNDERWRITING", "MORE_INFO_REQUIRED"].includes(status) && (
        <Button
          appearance="primary"
          margin="2rem 0 2rem"
          trailingIcon="arrow-right"
          onClick={() => submitPolicy()}
          name="resubmit_policy_provider"
          width="fit-content"
          isDisabled={loading || isLoading || isLocked}
        >
          Resubmit policy to provider
        </Button>
      )}
      {policy && status === "ACCEPTED_UNDERWRITING_WITH_EXCLUSIONS" && (
        <Table
          data={map(get(policy, "exclusions", []), exclusion => ({
            person: exclusion.applicant_name,
            exclusion: exclusion.exclusion,
            revert_exclusion: false,
            applicant_id: exclusion.policy_applicant_id
          }))}
          isLoading={false}
          columns={[
            {
              label: "Person",
              dataKey: "person"
            },
            {
              label: "Exclusion",
              dataKey: "exclusion",
              sortable: false,
              render: row => (
                <Button
                  type="inline-button"
                  leadingIcon="eye"
                  width="fit-content"
                  margin="0"
                  onClick={() =>
                    setExclusionModal({
                      isOpen: true,
                      personReference: get(row, "data.person"),
                      exclusionText: get(row, "data.exclusion")
                    })
                  }
                >
                  View exclusion
                </Button>
              )
            },
            {
              label: (
                <HelperText
                  title="Revert to original NCD due to exclusion"
                  content="The NCD level was calculated based on a claim whereby the condition has now been excluded from the cover. Therefore, to ensure the customer is treated fairly, the NCD level that would have been applied had this claim never occurred can be applied by clicking YES here. When you Save and Continue, this will return you to the Claims History page where you are able to amend the information entered to delete details for the claim relating to the excluded condition. You can then continue to generate a new quotation with a revised NCD level"
                >
                  Revert to original NCD due to exclusion
                </HelperText>
              ),
              dataKey: "revert_exclusion",
              minWidth: "140px",
              render: row => (
                <Toggle
                  margin="2rem 0"
                  name="display"
                  isHorizontal
                  labelWidth="40rem"
                  options={[
                    { order: 1, label: "No", value: false },
                    { order: 2, label: "Yes", value: true }
                  ]}
                  value={getToggleValue(row)}
                  isDisabled={isLocked}
                  onChange={value => {
                    if (value) {
                      const newValue = [
                        ...get(formik, "values.applicants", []),
                        {
                          applicant_id: row.data.applicant_id,
                          revert_to_original_ncd_due_to_exclusion: value
                        }
                      ]

                      formik.setFieldValue("applicants", newValue)
                    } else {
                      const newValue = get(formik, "values.applicants", []).filter(
                        val => val.applicant_id !== row.data.applicant_id
                      )
                      formik.setFieldValue("applicants", isEmpty(newValue) ? undefined : newValue)
                    }
                  }}
                />
              )
            }
          ]}
        />
      )}

      {exclusionModal.isOpen && (
        <Modal
          title={exclusionModal.personReference}
          onClose={() =>
            setExclusionModal({
              isOpen: false,
              personReference: "",
              exclusionText: ""
            })
          }
          name="view_exclusion"
          hasPadding={false}
        >
          <P margin="3rem">{exclusionModal.exclusionText}</P>
        </Modal>
      )}
    </>
  )
}

export default SubmitToProviderButtons
