import React from "react"
import PropTypes from "prop-types"

// Components
import { VideoWrapper, VideoIframe } from "./video.styles"

const Video = ({ src, title }) => (
  <VideoWrapper>
    <VideoIframe
      width="560"
      height="315"
      src={src}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </VideoWrapper>
)

Video.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string
}

export default Video
