import React from "react"
import { Helmet } from "react-helmet-async"
import { Gate } from "@4cplatform/elements/Auth"

// Components
import { LandingPage } from "../../UI/Templates"

const Login = () => (
  <>
    <Helmet>
      <title>Login</title>
    </Helmet>
    <LandingPage
      additionalLinks={[
        {
          title: "Login",
          href: "#page-content"
        }
      ]}
    >
      <Gate type="login" />
    </LandingPage>
  </>
)

export default Login
