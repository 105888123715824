import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { get } from "lodash"
import { useGet, usePatch, usePost, useDelete, ApiError } from "@4cplatform/elements/Api"
import { PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Components
import { Provider } from "../../../UI/Templates/ClientAdmin"

// Helpers
import { getOrderBy } from "../../../UI/Helpers"
import reducer from "./clients.reducer"

const ClientsProvider = ({ children }) => {
  const { setPanelStatus } = React.useContext(PageContext)
  const history = useHistory()

  const t = useTranslations()
  const initialTabValue = {
    viewData: {},
    showSimulated: true,
    quotesShowSimulated: true,
    policiesShowSimulated: true,
    search: "",
    quotesSearch: "",
    policiesSearch: "",
    quotesFilter: "reference",
    policiesFilter: "reference",
    selectedClient: null,
    sorting: { direction: "desc", dataKey: "created_at" },
    quotesSorting: { direction: "asc", dataKey: "reference" },
    policiesSorting: { direction: "asc", dataKey: "reference" },
    page: 1,
    quotesPage: 1,
    policiesPage: 1,
    notesPage: 1,
    perPage: 10,
    quotesPerPage: 10,
    policiesPerPage: 10,
    notesPerPage: 10,
    total: null,
    quotesTotal: null,
    policiesTotal: null,
    notesModal: false,
    panelBodyContent: "Journey",
    policySummaryModal: false,
    clientPolicies: [],
    quoteSummaryModal: false,
    clientQuotes: [],
    deleteQuoteModal: false,
    clientNotes: [],
    selectPolicySummary: {}
  }
  // State
  const [
    {
      data,
      viewData,
      showSimulated,
      quotesShowSimulated,
      policiesShowSimulated,
      search,
      quotesSearch,
      policiesSearch,
      filter,
      quotesFilter,
      policiesFilter,
      selectedClient,
      sorting,
      quotesSorting,
      policiesSorting,
      page,
      quotesPage,
      policiesPage,
      notesPage,
      perPage,
      quotesPerPage,
      policiesPerPage,
      notesPerPage,
      total,
      quotesTotal,
      policiesTotal,
      notesTotal,
      notesModal,
      panelBodyContent,
      policySummaryModal,
      clientPolicies,
      quoteSummaryModal,
      clientQuotes,
      deleteQuoteModal,
      clientNotes,
      selectPolicySummary
    },
    dispatch
  ] = React.useReducer(reducer, {
    data: [],
    ...initialTabValue
  })

  const [canRefetchQuotes, setCanRefetchQuotes] = React.useState(false)
  const [canRefetchPolicies, setCanRefetchPolicies] = React.useState(false)
  const [canRefetchNotes, setCanRefetchNotes] = React.useState(false)

  const selectedClientSlug = get(selectedClient, "slug", null)

  // Index Clients query
  const {
    loading: queryLoading,
    refetch: clientsRefetch,
    error
  } = useGet({
    endpoint: "/clients",
    query: {
      limit: perPage,
      order_by: getOrderBy(sorting),
      page,
      search
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("CLIENTS_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Client data query
  const {
    loading: viewLoading,
    refetch: viewRefetch,
    error: viewError
  } = useGet({
    endpoint: "/clients/:slug",
    skip: !selectedClient,
    params: {
      slug: selectedClientSlug
    },
    query: {
      with: ["salesAgent"]
    },
    onCompleted: res => {
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: get(res, "data", {}) })
    },
    onError: () => {
      addAlert({
        message: t("CLIENT_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Edit client mutation
  const [onUpdateClient, { loading: updateLoading, error: clientUpdateError }] = usePatch({
    endpoint: "/clients/:slug",
    params: {
      slug: selectedClientSlug
    },
    onCompleted: () => {
      addAlert({
        message: t("CLIENT_UPDATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      clientsRefetch()
      setPanelStatus("closed")
    },
    onError: () => {
      addAlert({
        message: t("CLIENT_UPDATE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Start Journey
  const [onStartJourney, { loading: startJourneyLoading, error: startJourneyError }] = usePost({
    endpoint: "/clients/:slug/journeys",
    params: {
      slug: selectedClientSlug
    },
    onCompleted: response => {
      addAlert({
        message: t("JOURNEY_START_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      setPanelStatus("closed")
      history.push(get(response, "data.page.route"))
    },
    onError: () => {
      addAlert({
        message: t("START_JOURNEY_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Get Policies
  const {
    loading: getPoliciesLoading,
    error: getPoliciesError,
    refetch: refetchPolicies
  } = useGet({
    endpoint: "/clients/:slug/policies",
    skip: !selectedClientSlug || panelBodyContent !== "Policies",
    params: {
      slug: selectedClientSlug
    },
    query: {
      simulated: policiesShowSimulated,
      limit: policiesPerPage,
      order_by: getOrderBy(policiesSorting),
      page: policiesPage,
      [policiesFilter]: policiesSearch || undefined
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "UPDATE_VALUE", key: "clientPolicies", value: newData })
      dispatch({ type: "UPDATE_VALUE", key: "policiesTotal", value: newTotal })
      if (!canRefetchPolicies) {
        setCanRefetchPolicies(true)
      }
    },
    onError: () => {
      addAlert({
        message: t("CLIENT_POLICIES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Get Notes
  const {
    loading: getNotesLoading,
    error: getNotesError,
    refetch: refetchNotes
  } = useGet({
    endpoint: "/clients/:slug/notes",
    params: {
      slug: selectedClientSlug
    },
    query: {
      page: notesPage,
      limit: notesPerPage
    },
    skip: !selectedClientSlug || panelBodyContent !== "Notes",
    simulated: showSimulated,
    onCompleted: res => {
      const newData = get(res, "data", [])
      dispatch({ type: "UPDATE_VALUE", key: "clientNotes", value: newData })
      dispatch({
        type: "UPDATE_VALUE",
        key: "notesTotal",
        value: get(res, "pagination.totalItems", 0)
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "totalPages",
        value: get(res, "pagination.totalPages", 1)
      })
      if (!canRefetchNotes) {
        setCanRefetchNotes(true)
      }
    },
    onError: () => {
      addAlert({
        message: t("NOTE_INDEX_ERROR")
      })
    }
  })

  // Get Quotes
  const {
    loading: getQuotesLoading,
    error: getQuotesError,
    refetch: refetchQuotes
  } = useGet({
    endpoint: "/clients/:slug/journeys",
    params: {
      slug: selectedClientSlug
    },
    query: {
      simulation_mode: quotesShowSimulated,
      limit: quotesPerPage,
      order_by: getOrderBy(quotesSorting),
      page: quotesPage,
      [quotesFilter]: quotesSearch || undefined
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "UPDATE_VALUE", key: "clientQuotes", value: newData })
      dispatch({ type: "UPDATE_VALUE", key: "quotesTotal", value: newTotal })
      if (!canRefetchQuotes) {
        setCanRefetchQuotes(true)
      }
    },
    onError: () => {
      addAlert({
        message: t("CLIENT_QUOTES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    },
    skip: !selectedClientSlug || panelBodyContent !== "Quotes"
  })

  // Add client note
  const [addNote, { loading: addNoteLoading, error: addNoteError }] = usePost({
    endpoint: "/clients/:slug/notes",
    params: {
      slug: selectedClientSlug
    },
    onCompleted: () => {
      addAlert({
        message: t("NOTE_ADD_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      refetchNotes()
      dispatch({ type: "UPDATE_VALUE", key: "notesModal", value: false })
    },
    onError: () => {
      addAlert({
        message: t("NOTE_ADD_ERROR")
      })
    },
    skip: !selectedClientSlug
  })

  // Delete Quote
  const [deleteQuote, { loading: deleteQuoteLoading, error: deleteQuoteError }] = useDelete({
    endpoint: "/journeys/:journey",
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("CLIENT_QUOTE_DELETE_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "deleteQuoteModal", value: false })
      setPanelStatus("closed")
      refetchQuotes()
    },
    onError: () => {
      addAlert({
        message: t("CLIENT_QUOTE_DELETE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  React.useEffect(() => {
    if (panelBodyContent === "Quotes" && canRefetchQuotes) {
      refetchQuotes()
    }
    if (panelBodyContent === "Notes" && canRefetchNotes) {
      refetchNotes()
    }
    if (panelBodyContent === "Policies" && canRefetchPolicies) {
      refetchPolicies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelBodyContent])

  return (
    <Provider
      value={{
        data,
        viewData,
        search,
        quotesSearch,
        policiesSearch,
        filter,
        quotesFilter,
        policiesFilter,
        showSimulated,
        quotesShowSimulated,
        policiesShowSimulated,
        selectedClient,
        sorting,
        quotesSorting,
        policiesSorting,
        page,
        quotesPage,
        policiesPage,
        perPage,
        quotesPerPage,
        policiesPerPage,
        total,
        quotesTotal,
        policiesTotal,
        notesModal,
        queryLoading,
        viewLoading,
        viewRefetch,
        updateLoading,
        clientUpdateError,
        onUpdateClient,
        startJourneyLoading,
        onStartJourney,
        panelBodyContent,
        policySummaryModal,
        clientPolicies,
        getPoliciesLoading,
        refetchPolicies,
        quoteSummaryModal,
        clientQuotes,
        getQuotesLoading,
        refetchQuotes,
        deleteQuoteModal,
        deleteQuoteLoading,
        getNotesLoading,
        clientNotes,
        refetchNotes,
        addNoteLoading,
        selectPolicySummary,
        updateQuotesPageValue: (key, value) =>
          [
            "quotesSorting",
            "quotesPage",
            "quotesPerPage",
            "quotesShowSimulated",
            "quotesFilter",
            "quotesSearch"
          ].includes(key) && dispatch({ type: "UPDATE_VALUE", key, value }),
        updatePoliciesPageValue: (key, value) =>
          [
            "policiesSorting",
            "policiesPage",
            "policiesPerPage",
            "policiesShowSimulated",
            "policiesFilter",
            "policiesSearch"
          ].includes(key) && dispatch({ type: "UPDATE_VALUE", key, value }),
        setShowSimulated: val =>
          dispatch({ type: "UPDATE_VALUE", key: "showSimulated", value: val }),
        setFilter: val => dispatch({ type: "UPDATE_VALUE", key: "filter", value: val }),
        setSearch,
        onClientSelect: row =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedClient", value: row }),
        onClientDeselect: () =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedClient", value: null }),
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        pagination: { total, page, perPage },
        notesPagination: { total: notesTotal, page: notesPage, perPage: notesPerPage },
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        setNotesPage: val => dispatch({ type: "UPDATE_VALUE", key: "notesPage", value: val }),
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        setNotesPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "notesPerPage", value: val }),
        setNotesModal: val => dispatch({ type: "UPDATE_VALUE", key: "notesModal", value: val }),
        setPanelBodyContent: val =>
          dispatch({ type: "UPDATE_VALUE", key: "panelBodyContent", value: val }),
        setPolicySummaryModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "policySummaryModal", value: val }),
        setSelectPolicySummary: val =>
          dispatch({ type: "UPDATE_VALUE", key: "selectPolicySummary", value: val }),
        setQuoteSummaryModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "quoteSummaryModal", value: val }),
        setDeleteQuoteModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "deleteQuoteModal", value: val }),
        onDeleteQuote: quote => {
          deleteQuote({
            params: {
              journey: get(quote, "slug", null)
            }
          })
        },
        onAddNote: val =>
          addNote({
            body: {
              body: val,
              type: "GENERAL"
            }
          })
      }}
    >
      {children}
      <ApiError
        error={
          error ||
          viewError ||
          clientUpdateError ||
          startJourneyError ||
          getPoliciesError ||
          getQuotesError ||
          deleteQuoteError ||
          getNotesError ||
          addNoteError
        }
      />
    </Provider>
  )
}

ClientsProvider.defaultProps = {
  children: null
}

ClientsProvider.propTypes = {
  children: PropTypes.any
}

export default ClientsProvider
