import moment from "moment"
import { object, string, array, date } from "yup"
import { isValidPhoneNumber } from "react-phone-number-input"
import { validNameRegex, validPostcodeRegex } from "../../Helpers"

export const createLeadModel = object({
  type: string().required("MISSING_REQUIRED_FIELD").nullable(),
  lead_source: string().required("MISSING_REQUIRED_FIELD").nullable(),
  date_of_birth: date()
    .max(moment().subtract(18, "years").format("YYYY-MM-DD"))
    .required("MISSING_REQUIRED_FIELD")
    .nullable(),
  gender_at_birth: string().required("MISSING_REQUIRED_FIELD").nullable(),
  title: string().required("MISSING_REQUIRED_FIELD").nullable(),
  first_name: string()
    .test("validNameTest", "CREATE_NEW_LEAD_INVALID_FIRST_NAME", name => validNameRegex.test(name))
    .required("MISSING_REQUIRED_FIELD_WITH_PREFIX_FIRST_NAME")
    .nullable(),
  last_name: string()
    .test("validNameTest", "CREATE_NEW_LEAD_INVALID_LAST_NAME", name => validNameRegex.test(name))
    .required("MISSING_REQUIRED_FIELD_WITH_PREFIX_LAST_NAME")
    .nullable(),
  email_address: string()
    .email("INVALID_EMAIL_ADDRESS")
    .required("MISSING_REQUIRED_FIELD_WITH_PREFIX_EMAIL")
    .nullable(),
  phone_numbers: array()
    .of(
      object().shape({
        type: string().required("MISSING_REQUIRED_FIELD"),
        number: string()
          .required("MISSING_REQUIRED_FIELD")
          .test("Phone invalid", "INVALID_UK_PHONE", val => {
            if (!val) return false
            return isValidPhoneNumber(val, "GB")
          })
      })
    )
    .required("MIN_LENGTH_NOT_MET")
    .min(1),
  address: object({
    line_one: string().required("MISSING_REQUIRED_FIELD"),
    line_two: string().nullable(),
    city: string().required("MISSING_REQUIRED_FIELD"),
    county: string().required("MISSING_REQUIRED_FIELD"),
    postcode: string()
      .test("correctUkPostcode", "CORRECT_UK_POSTCODE", no => validPostcodeRegex.test(no))
      .test("missingAddressField", "MISSING_POSTCODE_ADDRESS", (no, { parent }) => {
        // eslint-disable-next-line camelcase
        const { line_one, city, county } = parent
        // eslint-disable-next-line camelcase
        return line_one && city && county
      })
      .required("MISSING_REQUIRED_FIELD")
  })
})
