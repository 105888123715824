import React, { useContext } from "react"
import { Container } from "@4cplatform/elements/Atoms"
import { H1, P } from "@4cplatform/elements/Typography"

// Helpers
import { QuickQuoteContext } from "./quickQuote.context"
import { DASHBOARD } from "../../../config/pages"

// Components
import { Breadcrumbs } from "../../Molecules"
import ApplicantDetails from "./quickQuote.details"
import ProgressIndicator from "./quickQuote.progress"
import StickyNav from "./quickQuote.navigation"
import { Divider } from "./quickQuote.styles"
import QuotesContainer from "./quickQuote.quotes"

const QuickQuote = () => {
  const { quotesArrived } = useContext(QuickQuoteContext)

  return (
    <>
      <Container isPageContainer width="80%" padding="1rem 1rem 0 1rem">
        <Breadcrumbs
          trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: "Quick Quote" }]}
          margin="5rem 0 0.5rem 0"
        />
        <H1 margin="0.5rem 0 0.5rem">Quick Quote</H1>
        <P margin="0 0 4rem">Get a quick indicative price</P>
        <ApplicantDetails />
        <ProgressIndicator />
      </Container>
      <Divider />
      <QuotesContainer />
      {quotesArrived && <StickyNav />}
    </>
  )
}

export default QuickQuote
