import React from "react"
import PropTypes from "prop-types"

// Components
import { Logo, LogoSkeleton } from "./quickQuote.styles"

const ProviderLogo = ({ src, alt, size, isLoading, loadingMargin }) => {
  if (isLoading) return <LogoSkeleton isCircular circleSize={size} loadingMargin={loadingMargin} />
  return <Logo src={src} alt={alt} size={size} />
}

ProviderLogo.defaultProps = {
  src: null,
  alt: null,
  size: "6rem",
  isLoading: false,
  loadingMargin: null
}

ProviderLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingMargin: PropTypes.string
}

export default ProviderLogo
