import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { colours } from "@4cplatform/elements/Helpers"
import { useTranslations } from "@4cplatform/elements/Translations"
import { Icon } from "@4cplatform/elements/Atoms"

// Helpers
import moment from "moment"
import { UsersContext } from "./users.context"

// Components
import Actions from "./users.actions"

const UserAdmin = ({ context, shouldForceShowPagination }) => {
  const t = useTranslations()
  const {
    data,
    onUserSelect,
    onUserDeselect,
    setPerPage,
    pagination,
    setPage,
    onSort,
    sorting,
    queryLoading
  } = React.useContext(UsersContext)

  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)

  // If the context is manage-team, organisation is implied - do not add to table
  let parent = []
  if (context === "user-admin") {
    parent = [
      [
        { label: "Parent Type", dataKey: "parent.name", minWidth: "200px", sortable: true },
        {
          dataKey: "parent.type",
          minWidth: "200px",
          render: row => t(get(row, "data.parent.type", "-"))
        }
      ]
    ]
  }

  let activate = []
  if (context === "manage-team") {
    activate = [
      {
        label: "Active",
        dataKey: "active",
        minWidth: "80px",
        sortable: true,
        render: row => {
          const active = get(row, "data.active")
          return (
            <Icon icon={active ? "check" : "close"} colour={active ? colours.green : colours.red} />
          )
        }
      }
    ]
  }
  return (
    <>
      <Actions />
      <Table
        data={data}
        isLoading={queryLoading}
        name="users"
        shouldForceShowPagination={shouldForceShowPagination}
        columns={[
          [
            {
              label: "First Name",
              dataKey: "first_name",
              minWidth: "180px",
              fieldColour: get(colours, "blue", "blue"),
              sortable: true
            },
            {
              label: "Middle Names",
              dataKey: "middle_names",
              minWidth: "180px",
              render: row => {
                const {
                  data: { middle_names: middleNames }
                } = row
                if (middleNames) return middleNames
                return "-"
              }
            }
          ],
          {
            label: "Last Name",
            dataKey: "last_name",
            minWidth: "180px",
            fieldColour: get(colours, "blue", "blue"),
            sortable: true
          },
          {
            label: "Email Address",
            dataKey: "email",
            minWidth: "200px",
            sortable: true,
            charLimit: 24
          },
          {
            label: "Role",
            dataKey: "role",
            minWidth: "180px",
            render: row => t(get(row, "data.role.name", "-"))
          },
          ...parent,
          ...activate,
          {
            label: "Account start date",
            dataKey: "created_at",
            minWidth: "150px",
            sortable: true,
            render: row => {
              const lastLoggedIn = get(row, "data.created_at")
              return lastLoggedIn
                ? moment.utc(lastLoggedIn).local().format("DD/MM/YYYY HH:mm")
                : "-"
            }
          },
          {
            label: "Last logged in",
            dataKey: "last_logged_in_at",
            minWidth: "150px",
            sortable: true,
            render: row => {
              const lastLoggedIn = get(row, "data.last_logged_in_at")
              return lastLoggedIn
                ? moment.utc(lastLoggedIn).local().format("DD/MM/YYYY HH:mm")
                : "-"
            }
          }
        ]}
        hasActions
        onClick={row => {
          onUserSelect(row)
          setPanelStatus("open")
        }}
        onClose={() => {
          if (panelStatus !== "closed") {
            setPanelStatus("closed")
          }
          onUserDeselect()
        }}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        perPageOptions={{ max: 50, interval: 5 }}
        sorting={sorting}
        markAsGrey={user => !!get(user, "deleted_at", null)}
      />
    </>
  )
}

UserAdmin.defaultProps = {
  context: "user-admin",
  shouldForceShowPagination: true
}

UserAdmin.propTypes = {
  context: PropTypes.oneOf(["user-admin", "manage-team"]),
  shouldForceShowPagination: PropTypes.bool
}

export default UserAdmin
