import React from "react"
import PropTypes from "prop-types"
import { get, isEmpty } from "lodash"
import { Button } from "@4cplatform/elements/Molecules"
import { nullFunc } from "@4cplatform/elements/Helpers"

// Components
import { ButtonsWrapper } from "./import.styles"

const Actions = ({ type, handleSubmit, onBack, isLoading, clearImport, formik }) => (
  <ButtonsWrapper type={type}>
    {type === "import" && (
      <>
        <Button
          onClick={clearImport}
          isLoading={isLoading}
          trailingIcon="cancel"
          appearance="error"
          name="cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={isEmpty(get(formik, "values.file"))}
          trailingIcon="file-plus-outline"
          appearance="success"
          name="submit"
        >
          Import
        </Button>
      </>
    )}
    {type === "error" && (
      <Button
        onClick={onBack}
        isLoading={isLoading}
        trailingIcon="chevron-right"
        name="back_to_list"
      >
        OK
      </Button>
    )}
  </ButtonsWrapper>
)

Actions.defaultProps = {
  type: "import",
  handleSubmit: nullFunc,
  onBack: nullFunc,
  isLoading: false,
  clearImport: nullFunc
}

Actions.propTypes = {
  type: PropTypes.oneOf(["import", "error"]),
  handleSubmit: PropTypes.func,
  onBack: PropTypes.func,
  isLoading: PropTypes.bool,
  clearImport: PropTypes.func,
  formik: PropTypes.object
}

export default Actions
