import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { forIn, get, set } from "lodash"

import { Input, TextArea, Toggle } from "@4cplatform/elements/Forms"
import { breakpoints } from "@4cplatform/elements/Helpers"
import { Button, Modal } from "@4cplatform/elements/Molecules"
import { addAlert } from "@4cplatform/elements/Alerts"

import { usePost } from "@4cplatform/elements/Api"
import { P } from "@4cplatform/elements/Typography"
import { getValidationSchema } from "./exeter.questions.schema"
import { ButtonsWrapper } from "./exeter.questions.styles"

const ExeterQuestionsModal = ({
  applicant,
  data,
  handleCloseModal,
  underwritingStyle,
  underwritingType,
  onAnsweredQuestion
}) => {
  const isLocked = get(data, "journey.locked", false)

  const [submitApplicantAnswers, { loading: submitAnswersLoading }] = usePost({
    endpoint: "/journeys/:journey/exeter-questions/:applicant",
    onCompleted: res => {
      addAlert({
        message: "Answers successfully updated",
        type: "success",
        dismissable: true,
        timeout: 5
      })
      handleCloseModal(get(res, "data.success", false))
    },
    onError: () => {}
  })

  // Format boolean default values
  const answers = get(applicant, "answers", {})
  forIn(answers, (value, key) => {
    if (typeof value === "boolean") {
      set(answers, key, value ? "Y" : "N")
    }
  })

  const exeterQuestionsFormik = useFormik({
    initialValues: answers,
    onSubmit: body => {
      submitApplicantAnswers({
        body,
        params: {
          applicant: applicant.journey_applicant_id,
          journey: get(data, "journey.slug", "")
        }
      })
    },
    validationSchema: getValidationSchema(underwritingStyle, underwritingType)
  })

  const { handleSubmit } = exeterQuestionsFormik
  const formik = { ...exeterQuestionsFormik }
  const width = document.body.clientWidth >= breakpoints.medium ? "800px" : "100%"

  const common = name => ({
    error: formik.submitCount ? get(formik.errors, name, "") : "",
    errorPrefix: "none",
    formik,
    name
  })
  const inputProps = {
    isHorizontal: true,
    isRequired: true,
    labelWidth: "24rem",
    margin: "0 0 2rem"
  }
  const toggleProps = {
    isHorizontal: true,
    isRequired: true,
    labelWidth: "80%",
    options: [
      { order: 0, label: "No", value: "N" },
      { order: 1, label: "Yes", value: "Y" }
    ]
  }
  useEffect(() => {
    onAnsweredQuestion(formik.values)
  }, [formik.values, onAnsweredQuestion])

  return (
    <Modal
      width={width}
      title={`Questions for ${applicant.first_name} ${applicant.last_name}`}
      confirmIcon="cancel"
      confirmText="Yes"
      confirmAppearance="error"
      cancelAppearance="errorGhost"
      onClose={handleCloseModal}
      onConfirm={() => {}}
    >
      {underwritingStyle === "NEW" && underwritingType === "MORI" && (
        <Toggle
          label="Have you smoked or used nicotine replacement products in the last 12 months? (We may ask to carry out a simple test to verify your answer to this question)"
          {...{ ...toggleProps, ...common("9c039e19-034c-4b86-ba17-5782b54ba426") }}
        />
      )}
      {underwritingStyle === "SWITCH" && ["MORI", "CMORI"].includes(underwritingType) && (
        <>
          <Toggle
            label="1. Have you smoked or used nicotine replacement products in the last 12 months? (We may ask to carry out a simple test to verify your answer to this question)"
            {...{ ...toggleProps, ...common("5e7ee828-0798-404d-a8d1-97275fcf0c84") }}
          />
          <Toggle
            label="2.1 Do you have any consultations, investigations or treatment planned or pending in the next 12 months (NHS or private)?"
            {...{ ...toggleProps, ...common("1c7ce1a9-bb10-48de-9053-78fe225adb14") }}
          />
          {get(formik, "values.1c7ce1a9-bb10-48de-9053-78fe225adb14") === "Y" && (
            <TextArea
              label="2.1.1 Please provide full details."
              {...common("3d571e42-d36b-4b89-a40a-36e401b256de")}
            />
          )}
          <Toggle
            label="2.2 Have you had any consultations, investigations, symptoms (whether diagnosed or undiagnosed) or treatment in the last 12 months (NHS or Private)?"
            {...{ ...toggleProps, ...common("d8fc47ef-9297-47cf-a2eb-d641cbdfb9e8") }}
          />
          {get(formik, "values.d8fc47ef-9297-47cf-a2eb-d641cbdfb9e8") === "Y" && (
            <TextArea
              label="2.2.1 Please provide full details."
              {...common("5310e104-8812-40cc-a002-dc42b059b7d3")}
            />
          )}
          <P>
            3. Have you been treated for, diagnosed with, or advised that you have any of the
            following in the last 5 years:
          </P>
          <Toggle
            label="3.1 Heart Condition?"
            {...{ ...toggleProps, ...common("44877949-7f25-4223-b2fa-4542466914fe") }}
          />
          {get(formik, "values.44877949-7f25-4223-b2fa-4542466914fe") === "Y" && (
            <TextArea
              label="3.1.1 Please provide full details including dates, diagnosis and medication given."
              {...common("d9adb6c7-01a4-48ad-9e9c-8ec625708ad4")}
            />
          )}
          <Toggle
            label="3.2 Cancer?"
            {...{ ...toggleProps, ...common("4edb61d3-e694-4776-b80c-031892089fb2") }}
          />
          {get(formik, "values.4edb61d3-e694-4776-b80c-031892089fb2") === "Y" && (
            <TextArea
              label="3.2.1 Please provide full details including dates, diagnosis and medication given."
              {...common("2ab03bf9-dc2b-494e-89b6-80fa4caa2a9c")}
            />
          )}
          <Toggle
            label="3.3 Stroke or transient ischaemic attack (TIA)?"
            {...{ ...toggleProps, ...common("5a346f78-a306-4dc6-96df-5dc1d9e1ced2") }}
          />
          {get(formik, "values.5a346f78-a306-4dc6-96df-5dc1d9e1ced2") === "Y" && (
            <TextArea
              label="3.3.1 Please provide full details including dates, diagnosis and medication given."
              {...common("9580c571-266e-4473-9069-0f9450cb0114")}
            />
          )}
          <Toggle
            label="3.4 Would you like Mental Health Cover?"
            {...{ ...toggleProps, ...common("ff5a2ec2-4f55-4d0e-8c7c-3433526d3d93") }}
          />
          {get(formik, "values.ff5a2ec2-4f55-4d0e-8c7c-3433526d3d93") === "Y" && (
            <Toggle
              label="3.4.1 Anxiety, depression, stress or any other mental health issues (including but not limited to work stress, low mood, depression)?"
              {...{ ...toggleProps, ...common("ef893c65-c60d-4a64-b4a9-da4bbc99e42a") }}
            />
          )}
          {get(formik, "values.ff5a2ec2-4f55-4d0e-8c7c-3433526d3d93") === "Y" &&
            get(formik, "values.ef893c65-c60d-4a64-b4a9-da4bbc99e42a") === "Y" && (
              <TextArea
                label="3.4.1.1 Please provide full details including dates, diagnosis and any medication given."
                {...common("e14385ca-2d14-4b5a-b2db-cdd97cf1f0b7")}
              />
            )}
          <Toggle
            label="3.5 Joint replacement or joint resurfacing?"
            {...{ ...toggleProps, ...common("cd21f566-3c8f-4819-802e-ebccab46ed0e") }}
          />
          {get(formik, "values.cd21f566-3c8f-4819-802e-ebccab46ed0e") === "Y" && (
            <TextArea
              label="3.5.1 Please provide full details including dates, diagnosis and medication given."
              {...common("b24f6b0c-cb62-4fa0-b522-961868ec7b0d")}
            />
          )}
          <Toggle
            label="3.6 Diabetes or pre-diabetes?"
            {...{ ...toggleProps, ...common("9488a8d6-d548-40d1-a1c4-5f62897497c5") }}
          />
          {get(formik, "values.9488a8d6-d548-40d1-a1c4-5f62897497c5") === "Y" && (
            <TextArea
              label="3.6.1 Please provide full details including dates, diagnosis and medication given."
              {...common("4525d9c5-4285-4dda-84d3-5d79af75ff3f")}
            />
          )}
        </>
      )}
      {underwritingStyle === "NEW" && underwritingType === "FMU" && (
        <>
          <Input
            type="number"
            label="1. What is your age?"
            placeholder="Age"
            {...{ ...inputProps, ...common("b501eb93-6e6f-4bde-bdee-f593d78c0207") }}
          />
          <Toggle
            label="1.1 Have you smoked or used nicotine replacement products in the last 12 months? (We may ask to carry out a simple test to verify your answer to this question)"
            {...{ ...toggleProps, ...common("ff92c551-d0f6-4271-8edd-c9698c693783") }}
          />
          <P>2. Have you ever had any of the following:</P>
          <Toggle
            label="2.1 Diabetes, pre-diabetes or sugar in the urine?"
            {...{ ...toggleProps, ...common("88e8f7cf-cc71-44e9-89b2-4086235469c7") }}
          />
          {get(formik, "values.88e8f7cf-cc71-44e9-89b2-4086235469c7") === "Y" && (
            <TextArea
              label="2.1.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("89d9f984-3d79-447b-9949-148768b9de24")}
            />
          )}
          <Toggle
            label="2.2 A malignant condition such as tumour/cancer?"
            {...{ ...toggleProps, ...common("21eb06f1-106d-412b-b3fc-b21279acb02b") }}
          />
          {get(formik, "values.21eb06f1-106d-412b-b3fc-b21279acb02b") === "Y" && (
            <TextArea
              label="2.2.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("a6a7130e-9a80-411b-a727-f262350027e9")}
            />
          )}
          <Toggle
            label="2.3 Heart problems e.g. any arterial or cardiac surgery, chest pains, angina or irregular heartbeat?"
            {...{ ...toggleProps, ...common("485a3600-d028-43ce-910a-69d82d7ca465") }}
          />
          {get(formik, "values.485a3600-d028-43ce-910a-69d82d7ca465") === "Y" && (
            <TextArea
              label="2.3.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("3458072d-d1d5-47f1-b4f4-f326ee6050f0")}
            />
          )}
          <Toggle
            label="2.4 Stroke or transient ischaemic attack (TIA)?"
            {...{ ...toggleProps, ...common("5f2b39dd-a782-4292-9886-f6b03905f342") }}
          />
          {get(formik, "values.5f2b39dd-a782-4292-9886-f6b03905f342") === "Y" && (
            <TextArea
              label="2.4.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("64365e55-9d97-4719-ae9a-f5358e32f5f4")}
            />
          )}
          <Toggle
            label="2.5 Circulation problems such as peripheral vascular disease, claudication or varicose veins?"
            {...{ ...toggleProps, ...common("d898c082-7e90-47d3-8b91-c38849c94e84") }}
          />
          {get(formik, "values.d898c082-7e90-47d3-8b91-c38849c94e84") === "Y" && (
            <TextArea
              label="2.5.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("b77b4f9f-25d9-4eaa-895e-c473d2825701")}
            />
          )}
          <Toggle
            label="2.6 Surgery as a result of a bone or joint condition?"
            {...{ ...toggleProps, ...common("390078b2-bbf7-488b-8b94-a920f2359880") }}
          />
          {get(formik, "values.390078b2-bbf7-488b-8b94-a920f2359880") === "Y" && (
            <TextArea
              label="2.6.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("70dca3ae-2617-458d-96a3-276f9d9e5d38")}
            />
          )}
          <Toggle
            label="2.7 HIV, hepatitis B or C"
            {...{ ...toggleProps, ...common("e05a38c4-935e-4f1f-bc20-5fcb8955ed8d") }}
          />
          {get(formik, "values.e05a38c4-935e-4f1f-bc20-5fcb8955ed8d") === "Y" && (
            <TextArea
              label="2.7.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("1499f334-93f7-4bcf-801a-3ce6e6ba97bb")}
            />
          )}
          <Toggle
            label="2.8 Multiple Sclerosis or Epilepsy?"
            {...{ ...toggleProps, ...common("2d37240e-90cb-4ee4-bb98-c9d8672d4f32") }}
          />
          {get(formik, "values.2d37240e-90cb-4ee4-bb98-c9d8672d4f32") === "Y" && (
            <TextArea
              label="2.8.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("42011923-39d6-4883-a7a3-1de6f8922e75")}
            />
          )}
          <Toggle
            label="2.9 Stomach, intestine, liver, gall bladder or bowel disorders e.g. Crohn's disease, ulcerative colitis etc?"
            {...{ ...toggleProps, ...common("01d765e4-98fa-4339-ab27-8bc1ff73e1dc") }}
          />
          {get(formik, "values.01d765e4-98fa-4339-ab27-8bc1ff73e1dc") === "Y" && (
            <TextArea
              label="2.9.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("cf71e1d0-d573-4505-a8ab-51a75edafe0c")}
            />
          )}
          <Toggle
            label="2.10 Would you like Mental Health Cover?"
            {...{ ...toggleProps, ...common("7334c41e-f917-4184-8c3b-3cfb3d95ba3a") }}
          />
          {get(formik, "values.7334c41e-f917-4184-8c3b-3cfb3d95ba3a") === "Y" && (
            <Toggle
              label="2.10.1 Any mental health issue which has required a specialist, psychiatric or hospital referral, or inpatient stay?"
              {...common("db45977a-fdaa-407c-9535-bb4705f15730")}
              {...toggleProps}
            />
          )}
          {get(formik, "values.7334c41e-f917-4184-8c3b-3cfb3d95ba3a") === "Y" &&
            get(formik, "values.db45977a-fdaa-407c-9535-bb4705f15730") === "Y" && (
              <TextArea
                label="2.10.1.1 Please provide full details including dates, diagnosis and any medication given."
                {...common("8972707b-7789-40dd-ab45-2e5a409810eb")}
              />
            )}
          <P>3. In the last 5 years have you had:</P>
          <Toggle
            label="3.1 Migraines?"
            {...{ ...toggleProps, ...common("3f6b0443-480f-476d-bd4a-d2a1d57a3809") }}
          />
          {get(formik, "values.3f6b0443-480f-476d-bd4a-d2a1d57a3809") === "Y" && (
            <TextArea
              label="3.1.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("a3119b3a-fb68-48e2-b75d-27972fda7fc8")}
            />
          )}
          <Toggle
            label="3.2 Nerve pain, muscle, bone or joint conditions e.g. fractures, neck, hip, knee, back, tendon, cartilage or ligament problems?"
            {...{ ...toggleProps, ...common("ef2361ff-7d84-4aa6-a4a7-212dcd5ae6e7") }}
          />
          {get(formik, "values.ef2361ff-7d84-4aa6-a4a7-212dcd5ae6e7") === "Y" && (
            <TextArea
              label="3.2.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("a164ef0c-76ac-435d-b153-a803c78d1a87")}
            />
          )}
          <Toggle
            label="3.3 High blood pressure or high cholesterol?"
            {...{ ...toggleProps, ...common("87ac276f-df38-4b21-94c6-efa97f5c6a45") }}
          />
          {get(formik, "values.87ac276f-df38-4b21-94c6-efa97f5c6a45") === "Y" && (
            <TextArea
              label="3.3.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("ac82a6e8-5fcc-4150-9b98-46108c24ed54")}
            />
          )}
          <Toggle
            label="3.4 Any disease of the kidney, bladder or urinary system?"
            {...{ ...toggleProps, ...common("4194fb92-cf16-45ab-adae-ef61ea5663ca") }}
          />
          {get(formik, "values.4194fb92-cf16-45ab-adae-ef61ea5663ca") === "Y" && (
            <TextArea
              label="3.4.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("80f2f48b-a1bc-407b-8857-3394f3ca3a13")}
            />
          )}
          <Toggle
            label="3.5 Asthma, bronchitis or any other lung or respiratory problems?"
            {...{ ...toggleProps, ...common("14a4d9ed-a8b2-4b47-96f0-0965f996d3b5") }}
          />
          {get(formik, "values.14a4d9ed-a8b2-4b47-96f0-0965f996d3b5") === "Y" && (
            <TextArea
              label="3.5.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("c8e949e1-418a-4ea2-8e06-efca2f2e1f3f")}
            />
          )}
          <Toggle
            label="3.6 Any disease or disorder of the skin e.g. psoriasis, eczema, skin cancers or dermatitis?"
            {...{ ...toggleProps, ...common("0d4a550b-84d5-41a3-bcb1-813c3b927cab") }}
          />
          {get(formik, "values.0d4a550b-84d5-41a3-bcb1-813c3b927cab") === "Y" && (
            <TextArea
              label="3.6.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("57d4e06e-5658-4710-aff1-ff15470fe599")}
            />
          )}
          <Toggle
            label="3.7 Any disease or disorder of the eyes, ears, nose or throat?"
            {...{ ...toggleProps, ...common("b576823c-22a5-4400-bf57-fced2829ab08") }}
          />
          {get(formik, "values.b576823c-22a5-4400-bf57-fced2829ab08") === "Y" && (
            <TextArea
              label="3.7.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("8e54704c-b373-4c64-975e-a119b8bf017f")}
            />
          )}
          <Toggle
            label="3.8 Any glandular disorders e.g. thyroid, hormonal problems?"
            {...{ ...toggleProps, ...common("5e4cfe4d-9f1f-4606-87d8-b2dcb04b9328") }}
          />
          {get(formik, "values.5e4cfe4d-9f1f-4606-87d8-b2dcb04b9328") === "Y" && (
            <TextArea
              label="3.8.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("67839e9e-4b4f-41db-948d-12693dddb813")}
            />
          )}
          <Toggle
            label="3.9 Any blood disorders e.g. abnormal blood tests, anaemia?"
            {...{ ...toggleProps, ...common("0204ef77-ea39-4d5c-9679-948f10cf0114") }}
          />
          {get(formik, "values.0204ef77-ea39-4d5c-9679-948f10cf0114") === "Y" && (
            <TextArea
              label="3.9.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("0b822bd7-2957-4ef1-aa92-97a0cfcf7872")}
            />
          )}
          <Toggle
            label="3.10 Hernia e.g. umbilical hernia, inguinal hernia, hiatus hernia?"
            {...{ ...toggleProps, ...common("0e4f93b9-5e41-4cdc-a334-157858c2c403") }}
          />
          {get(formik, "values.0e4f93b9-5e41-4cdc-a334-157858c2c403") === "Y" && (
            <TextArea
              label="3.10.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("3807c05f-a79c-42d8-a40a-2e7c22aab9da")}
            />
          )}
          <Toggle
            {...toggleProps}
            label="3.11 Are you male or female?"
            {...common("957dd6bb-c663-4272-bebb-66c4d9436075")}
            options={[
              { order: 0, label: "Male", value: "M" },
              { order: 1, label: "Female", value: "F" }
            ]}
          />
          {get(formik, "values.957dd6bb-c663-4272-bebb-66c4d9436075") === "F" && (
            <Toggle
              label="3.11.1 Any gynaecological disorder or breast problem?"
              {...common("b5c79564-f3ab-4362-be68-c144fef30b27")}
              {...toggleProps}
            />
          )}
          {get(formik, "values.957dd6bb-c663-4272-bebb-66c4d9436075") === "F" &&
            get(formik, "values.b5c79564-f3ab-4362-be68-c144fef30b27") === "Y" && (
              <TextArea
                label="3.11.1.1 Please provide full details including dates, diagnosis and any medication given."
                {...common("cb064408-7dfe-4c32-9261-8365bbed7285")}
              />
            )}
          {get(formik, "values.957dd6bb-c663-4272-bebb-66c4d9436075") === "M" && (
            <Toggle
              label="3.11.1 Any prostate conditions including raised PSA test?"
              {...common("c0412ce3-9a31-432f-b0fb-b015b205a327")}
              {...toggleProps}
            />
          )}
          {get(formik, "values.957dd6bb-c663-4272-bebb-66c4d9436075") === "M" &&
            get(formik, "values.c0412ce3-9a31-432f-b0fb-b015b205a327") === "Y" && (
              <TextArea
                label="3.11.1.1 Please provide full details including dates, diagnosis and any medication given."
                {...common("61a2d84a-ba70-45a8-9810-ff646cce9791")}
              />
            )}
          <Toggle
            label="3.12 Would you like Mental Health Cover?"
            {...{ ...toggleProps, ...common("7c23d62e-11bf-4a12-bf62-db802a5fda14") }}
          />
          {get(formik, "values.7c23d62e-11bf-4a12-bf62-db802a5fda14") === "Y" && (
            <Toggle
              label="3.12.1 Anxiety, depression, stress or any other mental health issues (including but not limited to work stress, low mood, depression)?"
              {...common("2401f569-566b-4e96-a48d-c62e20f631a1")}
              {...toggleProps}
            />
          )}
          {get(formik, "values.7c23d62e-11bf-4a12-bf62-db802a5fda14") === "Y" &&
            get(formik, "values.2401f569-566b-4e96-a48d-c62e20f631a1") === "Y" && (
              <TextArea
                label="3.12.1.1 Please provide full details including dates, diagnosis and any medication given."
                {...common("880db25f-55d0-410b-83d0-d038d87d75b9")}
              />
            )}
          <Toggle
            label="4 Do you have any medical conditions or symptoms for which you are receiving treatment, seeing a Doctor or other Health Professional, or for which check-ups, investigations or treatment are considered necessary or advisable?"
            {...{ ...toggleProps, ...common("37818f95-1ca4-4e4c-905c-d9dd1545628f") }}
          />
          {get(formik, "values.37818f95-1ca4-4e4c-905c-d9dd1545628f") === "Y" && (
            <TextArea
              label="4.1 Please provide full details including dates, diagnosis and any medication given."
              {...common("7c754c1b-ab74-4405-bee3-af9bdf395712")}
            />
          )}
          <Toggle
            label="5 In the past year have you taken or been advised to take any medicines or drugs or any other type of treatment?"
            {...{ ...toggleProps, ...common("4757ce00-16ff-4621-afce-3706f65cd110") }}
          />
          {get(formik, "values.4757ce00-16ff-4621-afce-3706f65cd110") === "Y" && (
            <TextArea
              label="5.1 Please provide full details."
              {...common("17a79efc-a32f-4448-ad6c-df22624a765e")}
            />
          )}
        </>
      )}
      {underwritingStyle === "SWITCH" && ["FMU", "CME", "CPME"].includes(underwritingType) && (
        <>
          <Toggle
            label="Have you smoked or used nicotine replacement products in the last 12 months? (We may ask to carry out a simple test to verify your answer to this question)"
            {...{ ...toggleProps, ...common("c2cc822e-09a3-4c1d-897c-739581f529ff") }}
          />
          <Toggle
            label="2.1 Do you have any consultations, investigations or treatment planned or pending in the next 12 months (NHS or private)?"
            {...{ ...toggleProps, ...common("3afec110-8357-42f1-8875-70eacfca2bba") }}
          />
          {get(formik, "values.3afec110-8357-42f1-8875-70eacfca2bba") === "Y" && (
            <TextArea
              label="2.1.1 Please provide full details."
              {...common("ac9b7fd1-5f19-48a6-8ae6-96ca2f7453c7")}
            />
          )}
          <Toggle
            label="2.2 Have you had any consultations, investigations, symptoms (whether diagnosed or undiagnosed) or treatment in the last 12 months (NHS or Private)?"
            {...{ ...toggleProps, ...common("90f4c395-3921-448a-936d-73edd9d93037") }}
          />
          {get(formik, "values.90f4c395-3921-448a-936d-73edd9d93037") === "Y" && (
            <TextArea
              label="2.2.1 Please provide full details."
              {...common("e6e3ca5a-e1df-40cf-b511-6649628f883e")}
            />
          )}
          <P>
            3. Have you been treated for, diagnosed with, or advised that you have any of the
            following in the last 5 years:
          </P>
          <Toggle
            label="3.1 Heart condition?"
            {...{ ...toggleProps, ...common("fd01c2dd-dc36-43b1-a854-4fc23110dc19") }}
          />
          {get(formik, "values.fd01c2dd-dc36-43b1-a854-4fc23110dc19") === "Y" && (
            <TextArea
              label="3.1.1 Please provide full details."
              {...common("ae9bec5d-b72a-4d35-92bc-c23e3c7ebcdb")}
            />
          )}
          <Toggle
            label="3.2 Cancer?"
            {...{ ...toggleProps, ...common("8ef3d6b3-2a8b-4b42-a005-1e718cb1a6ff") }}
          />
          {get(formik, "values.8ef3d6b3-2a8b-4b42-a005-1e718cb1a6ff") === "Y" && (
            <TextArea
              label="3.2.1 Please provide full details including dates, diagnosis and medication given."
              {...common("9579e30e-c8f8-4982-828c-f3278b1e0b63")}
            />
          )}
          <Toggle
            label="3.3 Stroke or transient ischaemic attack (TIA)?"
            {...{ ...toggleProps, ...common("db284593-1f60-46a4-9d6f-9acf072e4d00") }}
          />
          {get(formik, "values.db284593-1f60-46a4-9d6f-9acf072e4d00") === "Y" && (
            <TextArea
              label="3.3.1 Please provide full details including dates, diagnosis and medication given."
              {...common("6e56b670-af84-4d7d-a67c-ed1e48b2e9fe")}
            />
          )}
          <Toggle
            label="3.4 Would you like Mental Health Cover?"
            {...{ ...toggleProps, ...common("e92ce69c-02f9-4020-9ce3-69d4e0fbdc41") }}
          />
          {get(formik, "values.e92ce69c-02f9-4020-9ce3-69d4e0fbdc41") === "Y" && (
            <Toggle
              label="3.4.1 Anxiety, depression, stress or any other mental health issues (including but not limited to work stress, low mood, depression)?"
              {...{ ...toggleProps, ...common("211d8f00-1f2d-4434-9447-32345763fd34") }}
            />
          )}
          {get(formik, "values.e92ce69c-02f9-4020-9ce3-69d4e0fbdc41") === "Y" &&
            get(formik, "values.211d8f00-1f2d-4434-9447-32345763fd34") === "Y" && (
              <TextArea
                label="3.4.1.1 Please provide full details including dates, diagnosis and any medication given."
                {...common("44431408-c032-4a6a-b148-db278e37534e")}
              />
            )}
          <Toggle
            label="3.5 Joint replacement or joint resurfacing?"
            {...{ ...toggleProps, ...common("35ba60e3-391a-407b-a501-e82fdf953727") }}
          />
          {get(formik, "values.35ba60e3-391a-407b-a501-e82fdf953727") === "Y" && (
            <TextArea
              label="3.5.1 Please provide full details including dates, diagnosis and medication given."
              {...common("d439015d-8daf-45c2-bcdf-bfa33aa7c29b")}
            />
          )}
          <Toggle
            label="3.6 Diabetes or pre-diabetes?"
            {...{ ...toggleProps, ...common("2596eddf-7f11-492a-8a5d-2ffa5dca5eb7") }}
          />
          {get(formik, "values.2596eddf-7f11-492a-8a5d-2ffa5dca5eb7") === "Y" && (
            <TextArea
              label="3.6.1 Please provide full details including dates, diagnosis and medication given."
              {...common("411f803a-a47f-4687-9ec0-a29dbfc50ca6")}
            />
          )}
        </>
      )}
      <ButtonsWrapper>
        <Button
          onClick={handleSubmit}
          isDisabled={isLocked}
          isLoading={submitAnswersLoading}
          type="Link"
          trailingIcon="check"
          name="answer_exeter_questions_modal"
        >
          Save answers
        </Button>
      </ButtonsWrapper>
    </Modal>
  )
}

ExeterQuestionsModal.propTypes = {
  applicant: PropTypes.object,
  data: PropTypes.object,
  handleCloseModal: PropTypes.func,
  underwritingStyle: PropTypes.string,
  underwritingType: PropTypes.string,
  onAnsweredQuestion: PropTypes.func
}

export default ExeterQuestionsModal
