import { isValidPhoneNumber } from "react-phone-number-input"
import { string, object } from "yup"

// Helpers
import { alphanumericRegex } from "@4cplatform/elements/Helpers"
import { validNameRegex, validPostcodeRegex } from "../../Helpers"

// Edit Network Details yup schema
export const editDetailsModel = object().shape({
  name: string().required("MISSING_REQUIRED_FIELD").nullable(),
  description: string().nullable(),
  address: object({
    postcode: string()
      .test("correctUkPostcode", "CORRECT_UK_POSTCODE", no => validPostcodeRegex.test(no))
      .required("MISSING_REQUIRED_FIELD")
      .nullable(),
    line_one: string().required("MISSING_REQUIRED_FIELD").nullable(),
    line_two: string().nullable(),
    city: string().required("MISSING_REQUIRED_FIELD").nullable(),
    county: string().required("MISSING_REQUIRED_FIELD").nullable()
  }),
  phone_number: string()
    .test("Phone invalid", "INVALID_PHONE", val => {
      if (!val) return false
      return isValidPhoneNumber(val, "GB")
    })
    .required("MISSING_REQUIRED_FIELD"),
  company_registration_number: string()
    .test("alphanumeric", "INVALID_ALPHANUMERIC", no => alphanumericRegex.test(no))
    .required("MISSING_REQUIRED_FIELD")
    .nullable(),
  fca_reference: string()
    .test("fcaReference", "ONLY_DIGITS_ALLOWED", ref => /^\d+$/.test(ref))
    .min(6)
    .max(7)
    .required("MISSING_REQUIRED_FIELD"),
  contact_first_name: string()
    .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
    .required("MISSING_REQUIRED_FIELD")
    .nullable(),
  contact_last_name: string()
    .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
    .required("MISSING_REQUIRED_FIELD")
    .nullable(),
  contact_email_address: string().email().required("MISSING_REQUIRED_FIELD").nullable()
})
