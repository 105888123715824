import React from "react"
import PropTypes from "prop-types"
import { get, keys, isEmpty } from "lodash"
import { useFormik } from "formik"
import { date, number, object, string } from "yup"
import moment from "moment"

import { AuthContext } from "@4cplatform/elements/Auth"
import { PageContext } from "@4cplatform/elements/Organisms"
import { useDelete, useGet, usePost } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../../../UI/Templates/CommissionRatesAdmin"

// Helpers
import reducer from "./commissionRates.reducer"

const validationSchema = object({
  user_id: string().required("PLEASE_SPECIFY_AN_APPLICANT"),
  commission_rate: number()
    .min(0)
    .max(100)
    .test("maxDigitsAfterDecimal", "number field must have 1 digits after decimal or less", no =>
      /^\d+(\.\d{1,1})?$/.test(no)
    )
    .required("MISSING_REQUIRED_FIELD"),
  start_date: date().required("COMMISSION_RATES_START_DATE_REQUIRED_FIELD"),
  end_date: date().required("COMMISSION_RATES_END_DATE_REQUIRED_FIELD")
})

const CommissionRatesProvider = ({ children }) => {
  const t = useTranslations()

  // State
  const [
    {
      sorting,
      data,
      isOpenAddModal,
      isOpenDeleteModal,
      providers,
      selectedProvider,
      selectedCommissionRate,
      userVal
    },
    dispatch
  ] = React.useReducer(reducer, {
    sorting: { direction: "asc", dataKey: "user.full_name" },
    data: [],
    isOpenAddModal: false,
    isOpenDeleteModal: false,
    providers: null,
    selectedProvider: null,
    selectedCommissionRate: null,
    userVal: ""
  })

  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)
  const { canAccess, user: currentUser } = React.useContext(AuthContext)

  const isSale = React.useMemo(() => canAccess(["SALES_ADVISER"]), [canAccess])

  const setIsOpenAddModal = React.useCallback(
    val => dispatch({ type: "UPDATE_VALUE", key: "isOpenAddModal", value: val }),
    []
  )

  const getErrMessage = React.useCallback(
    (keyField, keyErr) => {
      if (keyErr === "MISSING_REQUIRED_FIELD") {
        if (keyField === "start_date") {
          return t("COMMISSION_RATES_START_DATE_REQUIRED_FIELD")
        }
        if (keyField === "end_date") {
          return t("COMMISSION_RATES_END_DATE_REQUIRED_FIELD")
        }
      }

      return t(keyErr)
    },
    [t]
  )

  // Index commission rates query
  const { loading: queryLoading, refetch: queryRefetch } = useGet({
    endpoint: "/commission-rates",
    query: {
      page: 1
    },
    onCompleted: res => {
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", data: newData })
    },
    onError: () => {
      addAlert({
        message: t("COMMISSION_RATES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Index users to add commission rate
  const {
    data: users,
    loading: usersLoading,
    error: usersError
  } = useGet({
    endpoint: "/commission-rate-users",
    skip: isSale
  })

  // Add commission rate
  const [addCommissionRate, { loading: addCommissionRateLoading }] = usePost({
    endpoint: "/commission-rates",
    onCompleted: () => {
      addAlert({
        message: t("COMMISSION_RATE_CREATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      setIsOpenAddModal(false)
      queryRefetch()
    },
    onError: error => {
      const validation = get(error, "validation", {})

      const keysValidation = keys(validation)
      if (!isEmpty(keysValidation)) {
        const objError = {}
        keysValidation.forEach(keyField => {
          const firstError = get(validation, `${keyField}[0]`)

          if (firstError) {
            objError[keyField] = getErrMessage(keyField, firstError)
          }
        })
        // eslint-disable-next-line no-use-before-define
        formik?.setErrors(objError)
      }

      addAlert({
        message: t("COMMISSION_RATE_CREATE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const commissionRatesFormik = useFormik({
    initialValues: {
      user_id: get(currentUser, "role.name", "") === "SALES_ADVISER" ? "all" : "",
      start_date: moment().format("YYYY-MM-DD")
    },
    validationSchema,
    onSubmit: body =>
      addCommissionRate({
        body: {
          ...body,
          provider_id: get(selectedProvider, "id", null)
        }
      })
  })

  const formik = { ...commissionRatesFormik, validationSchema }

  // Delete commission rate
  const [deleteCommissionRate, { loading: deleteCommissionRateLoading }] = useDelete({
    endpoint: "/commission-rates/:id",
    params: {
      id: get(selectedCommissionRate, "id", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("COMMISSION_RATE_DELETE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      queryRefetch()
      if (panelStatus !== "closed") {
        setPanelStatus("closed")
      }
    },
    onError: () => {
      addAlert({
        message: t("COMMISSION_RATE_DELETE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const onSort = React.useCallback(
    newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
    []
  )

  const onProviderSelect = React.useCallback(
    provider => dispatch({ type: "UPDATE_VALUE", key: "selectedProvider", value: provider }),
    []
  )

  const onProviderDeselect = React.useCallback(() => {
    dispatch({ type: "UPDATE_VALUE", key: "selectedProvider", value: null })
  }, [])

  const onCommissionRateSelect = React.useCallback(
    row => dispatch({ type: "UPDATE_VALUE", key: "selectedCommissionRate", value: row }),
    []
  )

  const setUserVal = React.useCallback(
    val => dispatch({ type: "UPDATE_VALUE", key: "userVal", value: val }),
    []
  )

  const setOpenDeleteModal = React.useCallback(
    val => dispatch({ type: "UPDATE_VALUE", key: "isOpenDeleteModal", value: val }),
    [dispatch]
  )

  return (
    <Provider
      value={{
        providers,
        sorting,
        data,
        isOpenAddModal,
        isOpenDeleteModal,
        formik,
        onSort,
        onProviderSelect,
        onProviderDeselect,
        onCommissionRateSelect,
        setIsOpenAddModal,
        setOpenDeleteModal,
        selectedProvider,
        selectedCommissionRate,
        queryLoading,
        userVal,
        setUserVal,
        dispatch,
        currentUser,
        users,
        usersLoading,
        usersError,
        addCommissionRate,
        addCommissionRateLoading,
        deleteCommissionRate,
        deleteCommissionRateLoading
      }}
    >
      {children}
    </Provider>
  )
}

CommissionRatesProvider.propTypes = {
  children: PropTypes.any
}

export default CommissionRatesProvider
