import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get, isEmpty } from "lodash"
import { useFormik } from "formik"
import { object, string } from "yup"
import { v4 as uuid } from "uuid"
import { Avatar } from "@4cplatform/elements/Atoms"
import { Button, Modal, Skeleton } from "@4cplatform/elements/Molecules"
import { AuthContext } from "@4cplatform/elements/Auth"
import { TextArea } from "@4cplatform/elements/Forms"
import { P, SmallText } from "@4cplatform/elements/Typography"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { getName } from "../../Helpers"

// Components
import {
  AvatarWrapper,
  NameWrapper,
  MessageDateWrapper,
  MessageBody,
  MessageContainer,
  MessageIsSending,
  MessageMeta,
  Message,
  Messages,
  MessageWrapper,
  NewMessagesWrapper,
  SvgWrapper,
  TextAreaControls,
  NoMessagesTextWraoper
} from "./messaging.styles"

const Messaging = ({
  setIsOpen,
  isOpen,
  messages,
  isMessagesLoading,
  submitMessage,
  isSubmitMessageLoading
}) => {
  const { user } = useContext(AuthContext)
  const [scrollBehaviour, setScrollBehaviour] = useState("auto")
  const latestMessageRef = useRef(null)

  const userName = getName({
    data: user,
    hasMiddle: true,
    hasTitle: false
  })

  // New Message Formik instance
  const newMessageFormik = useFormik({
    initialValues: {
      body_text: ""
    },
    validationSchema: object({
      body_text: string().required("MISSING_REQUIRED_FIELD").nullable()
    }),
    onSubmit: (values, { resetForm }) => {
      submitMessage({
        body: values,
        user: userName
      })
      resetForm({})
    }
  })

  const { handleSubmit } = newMessageFormik
  const formik = { ...newMessageFormik }

  // this useEffect is used to delay the smoothnest of the scroll, so that when component has mounted,
  // the latest message is available to the user straight away, instead of seeing the div scroll all the way down
  useEffect(() => {
    const timeout = setTimeout(() => {
      setScrollBehaviour("smooth")
    }, 5000)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (latestMessageRef?.current)
      latestMessageRef.current.scrollIntoView({ behavior: scrollBehaviour })
  })

  const getMessages = () => {
    if (isMessagesLoading) return <Skeleton count={5} wrapper={P} lineHeight="7.5rem" />
    if (isEmpty(messages) && !isMessagesLoading)
      return (
        <NoMessagesTextWraoper>
          <SmallText colour={get(colours, "lightGrey", "lightgrey")}>
            No messages to display
          </SmallText>
        </NoMessagesTextWraoper>
      )
    return messages?.map(message => {
      const isProviderMessage = message?.parent?.type
        ? message.parent.type === "PROVIDER"
        : user?.parent?.type === "PROVIDER"

      const userNameMesage = get(message, "user_name", "")
      const journeyRef = get(message, "journey_ref", "")
      const quoteIdMesage = journeyRef ? `Quote ID ${journeyRef}` : ""

      return (
        <MessageContainer isProviderMessage={isProviderMessage} key={uuid()}>
          <MessageWrapper>
            <MessageBody isProviderMessage={isProviderMessage}>
              <Message appearance="light" margin="0">
                {message.body_text}
              </Message>
            </MessageBody>
            <SvgWrapper isProviderMessage={isProviderMessage}>
              <svg viewBox="146.945 107.499 104.831 104.928" width="12" height="12">
                <path
                  style={{ fill: !isProviderMessage ? colours.purple : colours.paleGreen }}
                  d="M 146.945 107.504 L 148.124 212.427 C 153.981 164.796 154.534 127.262 251.776 107.499 L 146.945 107.504 Z"
                />
              </svg>
            </SvgWrapper>
          </MessageWrapper>

          <MessageMeta isProviderMessage={isProviderMessage}>
            <MessageDateWrapper>
              {!message?.sent_at && isSubmitMessageLoading ? (
                <MessageIsSending isProviderMessage={isProviderMessage} />
              ) : (
                <SmallText colour={colours.lightGrey} margin="0 13px">
                  {moment.utc(message?.sent_at).local().format("DD/MM/YYYY HH:mm:ss")}
                </SmallText>
              )}
            </MessageDateWrapper>

            <AvatarWrapper isProviderMessage={isProviderMessage}>
              <Avatar
                first={userNameMesage?.split(" ")[0]}
                last={userNameMesage?.split(" ").pop()}
                name={userNameMesage}
              />
              <NameWrapper isProviderMessage={isProviderMessage}>
                <SmallText margin="0 0.5rem" fontSize="1.1rem">
                  {quoteIdMesage}
                </SmallText>
                <SmallText margin="0 0.5rem" fontSize="1.3rem">
                  {userNameMesage}
                </SmallText>
              </NameWrapper>
            </AvatarWrapper>
          </MessageMeta>
        </MessageContainer>
      )
    })
  }

  return (
    <>
      {isOpen && (
        <Modal title="Messages" onClose={() => setIsOpen(false)} name="messages" hasPadding={false}>
          <Messages id="messages">
            {getMessages()}
            <div ref={latestMessageRef} />
          </Messages>
          <NewMessagesWrapper>
            <TextArea
              maxlength="256"
              name="body_text"
              formik={formik}
              isDisabled={isSubmitMessageLoading || isMessagesLoading}
              onChange={val => (val.length <= 256 ? formik.setFieldValue("body_text", val) : null)}
            />
            <TextAreaControls>
              <SmallText m="0" colour={colours.lightGrey}>
                {formik.values.body_text.length} of 256 characters
              </SmallText>
              <Button
                trailingIcon="send"
                appearance="success"
                onClick={handleSubmit}
                isDisabled={
                  isSubmitMessageLoading || isMessagesLoading || !formik.values.body_text.length
                }
                isLoading={isSubmitMessageLoading}
              >
                Send
              </Button>
            </TextAreaControls>
          </NewMessagesWrapper>
        </Modal>
      )}
    </>
  )
}

Messaging.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  isMessagesLoading: PropTypes.bool.isRequired,
  submitMessage: PropTypes.func.isRequired,
  isSubmitMessageLoading: PropTypes.bool.isRequired
}

export default Messaging
