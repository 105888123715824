import { Input } from "@4cplatform/elements/Forms"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { P, H4 } from "@4cplatform/elements/Typography"
import { get } from "lodash"
import { string } from "yup"

export const config = data => ({
  title: "GMC/DMC number",
  sections: [
    {
      key: "bupa_gmc_dmc_note",
      components: [
        {
          key: "compliance_note",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <H4 margin="0 0 1rem">Discount applied</H4>
                <P margin="0">
                  As the client has disclosed that they are a member of the General Medical Council
                  or Dental Medical Council a discount has been applied to the policy. In order for
                  your client to be eligible for the discount applied you will need to obtain the
                  clients GMC or DMC number.
                </P>
              </>
            ),
            type: "info"
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "bupa_gmc_dmc_number_section",
      components: [
        {
          key: "bupa_gmc_dmc_number",
          initialValue: get(data, "page.data.bupa_gmc_dmc_number", ""),
          validationSchema: string()
            .test("validGMCCharactersRegex", "INVALID_GMC_CHARACTERS", (_, { originalValue }) =>
              /[a-zA-Z0-9]$/.test(originalValue)
            )
            .test("validGMCLengthTest", "INVALID_GMC_LENGTH", (_, { originalValue }) =>
              /^[a-zA-Z0-9]{7}$/.test(originalValue)
            ),
          label: "GMC/DMC number",
          component: Input,
          componentProps: {
            name: "GMC/DMC number",
            placeholder: "Enter here",
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true
          }
        }
      ]
    }
  ]
})
