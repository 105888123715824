import React, { useContext } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { Button, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"
import { formatLabelForTestID } from "@4cplatform/elements/Helpers"

// Helpers
import { ApplicantsContext } from "./applicants.context"

// Components
import { ButtonsWrapper } from "./applicants.styles"

const RowActions = ({ applicant: theApplicant, isLocked }) => {
  const {
    setApplicantModal,
    setDeleteApplicantModal,
    updateActiveTabID,
    deleteApplicantModal,
    deleteApplicant,
    deleteApplicantLoading
  } = useContext(ApplicantsContext)
  const applicant = { ...theApplicant, type: theApplicant.type.toLowerCase() }
  const isPrimary = get(applicant, "type") === "primary"

  if (isPrimary) return null

  return (
    <>
      <ButtonsWrapper>
        <Button
          type="inline-button"
          appearance="primaryInline"
          onClick={() => {
            setApplicantModal({ isOpen: true, applicant, isEdit: true })
            updateActiveTabID(formatLabelForTestID("Personal details"))
          }}
          isDisabled={isLocked}
        >
          Edit
        </Button>
        <Button
          type="inline-button"
          appearance="errorInline"
          onClick={() => setDeleteApplicantModal({ isOpen: true, applicant })}
          isDisabled={isLocked}
        >
          Delete
        </Button>
      </ButtonsWrapper>

      {/* Delete applicant modal */}
      {deleteApplicantModal.isOpen &&
        get(deleteApplicantModal, "applicant.slug") === get(applicant, "slug") && (
          <ConfirmationModal
            confirmIcon="delete"
            confirmText="Yes"
            confirmAppearance="error"
            cancelAppearance="errorGhost"
            onClose={() => setDeleteApplicantModal({ isOpen: false, applicant: null })}
            onConfirm={() => deleteApplicant(applicant)}
            isLoadingConfirm={deleteApplicantLoading}
          >
            <P>Are you sure you want to remove the current applicant from the applicant list?</P>
          </ConfirmationModal>
        )}
    </>
  )
}

RowActions.defaultProps = {
  isLocked: false
}

RowActions.propTypes = {
  applicant: PropTypes.object.isRequired,
  isLocked: PropTypes.bool
}

export default RowActions
