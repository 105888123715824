import React from "react"

// Components
import ClientsPanelHeader from "./clients.panel.header"
import ClientsPanelBody from "./clients.panel.body"

const clientView = () => (
  <>
    <ClientsPanelHeader />
    <ClientsPanelBody />
  </>
)

export default clientView
