import React, { useContext } from "react"
import { get, sortBy, filter, isEmpty, findIndex } from "lodash"

// Helpers
import { QuickQuoteContext } from "./quickQuote.context"

// Components
import QuoteGroup from "./quickQuote.quotes.group"
import { QuotesContainerWrapper } from "./quickQuote.styles"

const QuotesContainer = () => {
  const { arrivedQuotes } = useContext(QuickQuoteContext)

  const groupedQuotes = sortBy(
    filter(arrivedQuotes, q => q.quoteType === "QQ_STANDARD"),
    q => findIndex(arrivedQuotes, q)
  )

  if (isEmpty(arrivedQuotes)) return null

  return (
    <QuotesContainerWrapper>
      {groupedQuotes.map(group => (
        <QuoteGroup
          key={get(group, "data.product")}
          product={get(group, "data.product")}
          quotes={filter(arrivedQuotes, q => get(q, "data.product") === get(group, "data.product"))}
        />
      ))}
    </QuotesContainerWrapper>
  )
}

export default QuotesContainer
