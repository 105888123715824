import styled from "styled-components"
import { Column } from "@4cplatform/elements/Atoms"
import { H3 } from "@4cplatform/elements/Typography"

export const Feature = styled(Column)`
  gap: 2rem;
`

export const Header = styled(H3)`
  text-transform: uppercase;
`

export const Image = styled.img`
  max-height: 100px;
`
