import React from "react"

// Components
import PoliciesPanelHeader from "./policies.panel.header"
import PoliciesPanelBody from "./policies.panel.body"

const PolicyView = () => (
  <>
    <PoliciesPanelHeader />
    <PoliciesPanelBody />
  </>
)

export default PolicyView
