import React from "react"
import PropTypes from "prop-types"

// Components
import BankDetailsProvider from "./context/bankDetails.provider"
import BankDetailsFields from "./bankDetails.fields"
import BankAddressFields from "./bankDetails.address.fields"
import { Wrapper } from "./bankDetails.styles"

const BankDetails = ({
  margin,
  labelWidth,
  formik,
  isHorizontal,
  appearance,
  isDisabled,
  apiErrors,
  provider
}) => (
  <BankDetailsProvider formik={formik}>
    <Wrapper margin={margin}>
      <BankDetailsFields
        labelWidth={provider === "EXETER" ? "30rem" : labelWidth}
        isHorizontal={isHorizontal}
        appearance={appearance}
        isDisabled={isDisabled}
        apiErrors={apiErrors}
        provider={provider}
      />
      <BankAddressFields
        labelWidth={provider === "EXETER" ? "30rem" : labelWidth}
        isHorizontal={isHorizontal}
        appearance={appearance}
        isDisabled={isDisabled}
        apiErrors={apiErrors}
      />
    </Wrapper>
  </BankDetailsProvider>
)

BankDetails.defaultProps = {
  margin: "0 0 2rem",
  labelWidth: "18rem",
  isHorizontal: false,
  appearance: "dark",
  isDisabled: false,
  apiErrors: {},
  provider: ""
}

BankDetails.propTypes = {
  /**
   * This prop handles the margin property of the wrapper
   */
  margin: PropTypes.string,
  /**
   * Width of the label element while the component is in Horizontal mode
   */
  labelWidth: PropTypes.string,
  /**
   * The formik instance
   */
  formik: PropTypes.object.isRequired,
  /**
   * Display the component in Horizontal mode
   */
  isHorizontal: PropTypes.bool,
  /**
   * The light/dark appearance of the label text
   */
  appearance: PropTypes.oneOf(["dark", "light"]),
  /**
   * This controls the disabled state of the Select
   */
  isDisabled: PropTypes.bool,
  /**
   * API errors
   */
  apiErrors: PropTypes.object,
  /**
   * Selected quote provider
   */
  provider: PropTypes.string
}

export default BankDetails
