import React from "react"

// Components
import { Tabs, Tab } from "../../../../UI/Organisms"
import NetworkAgencyCodes from "./pages/NetworkAgencyCodes"

const AgencyCodesTabs = props => (
  <Tabs hasQueryControls type="table" name="agency_codes_type" {...props}>
    <Tab header="Agency codes">
      <NetworkAgencyCodes key="agency_codes" />
    </Tab>
    <Tab header="Shared with organisations">
      <NetworkAgencyCodes isSharedWith key="shared_with_organisations" />
    </Tab>
  </Tabs>
)

export default AgencyCodesTabs
