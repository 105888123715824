/**
 * Pages
 */
import {
  MaintenanceLogin,
  Login,
  ForgottenPassword,
  ResetPassword,
  TwoFactorAuth,
  ActivateUser,
  Register,
  PasswordChangeConfirmation,
  EmailChangeConfirmation,
  Notifications,
  Dashboard,
  MyAccount,
  Leads,
  LeadAdd,
  Clients,
  NetworkAdd,
  NetworkManage,
  Networks,
  PrivacyPolicy,
  Policies,
  ProviderAdd,
  ProviderManage,
  OrganisationManage,
  Organisations,
  QuickQuote,
  SystemSettings,
  TermsConditions,
  ApiStatus,
  Providers,
  Users,
  UserAdd,
  Journey,
  Journeys,
  CommissionRates,
  Forbidden,
  NotFound,
  AgencyCodes,
  QuickQuoteLog,
  Reports
} from "../pages"

/**
 * Page definitions
 *
 * Centralised for ease of management and re-usability
 */
import {
  MAINTENANCE_LOGIN,
  LOGIN,
  FORGOTTEN_PASSWORD,
  RESET_PASSWORD,
  TWO_FACTOR_AUTH,
  ACTIVATE_USER,
  REGISTER,
  PASSWORD_CHANGE_CONFIRMATION,
  EMAIL_CHANGE_CONFIRMATION,
  TERMS_CONDITIONS,
  PRIVACY_POLICY,
  NOTIFICATION_ADMIN,
  MY_ACCOUNT,
  SYSTEM_SETTINGS,
  API_STATUS,
  ORGANISATION_ADMIN,
  ORGANISATION_MANAGE,
  ORGANISATION_USER_CREATE,
  PROVIDER_ADMIN,
  PROVIDER_MANAGE,
  PROVIDER_CREATE,
  PROVIDER_USER_CREATE,
  PROVIDER_CONTACT_DETAILS,
  NETWORK_ADMIN,
  NETWORK_MANAGE,
  NETWORK_USER_CREATE,
  NETWORK_CREATE,
  QUICK_QUOTE,
  USER_ADMIN,
  USER_CREATE,
  LEAD_ADMIN,
  LEAD_CREATE,
  CLIENT_ADMIN,
  AGENCY_CODE_ADMIN,
  JOURNEY,
  JOURNEY_ADMIN,
  POLICY_ADMIN,
  POLICY_ADMIN_AWAITING_TERMS,
  POLICY_ADMIN_AWAITING_ACCEPTANCE,
  POLICY_ADMIN_FAILED_ONBOARDING,
  COMMISSION_RATES,
  QUICK_QUOTE_LOG,
  FORBIDDEN,
  DASHBOARD,
  NOT_FOUND,
  REPORTS
} from "./pages"

const routes = [
  /**
   * Reports
   */
  { isExact: true, component: Reports, isAuthenticatedRoute: true, ...REPORTS },
  /**
   * Authentication Gate
   */
  {
    isExact: true,
    component: MaintenanceLogin,
    isAuthenticatedRoute: false,
    ...MAINTENANCE_LOGIN
  },
  {
    isExact: true,
    component: Login,
    isAuthenticatedRoute: false,
    ...LOGIN
  },
  {
    isExact: true,
    component: ForgottenPassword,
    isAuthenticatedRoute: false,
    ...FORGOTTEN_PASSWORD
  },
  {
    isExact: true,
    component: ResetPassword,
    isAuthenticatedRoute: false,
    ...RESET_PASSWORD
  },
  {
    isExact: true,
    component: TwoFactorAuth,
    isAuthenticatedRoute: false,
    ...TWO_FACTOR_AUTH
  },
  {
    isExact: true,
    component: ActivateUser,
    isAuthenticatedRoute: false,
    ...ACTIVATE_USER
  },
  {
    isExact: true,
    component: Register,
    isAuthenticatedRoute: false,
    ...REGISTER
  },
  {
    isExact: false,
    component: PasswordChangeConfirmation,
    isAuthenticatedRoute: false,
    ...PASSWORD_CHANGE_CONFIRMATION
  },
  {
    isExact: false,
    component: EmailChangeConfirmation,
    isAuthenticatedRoute: false,
    ...EMAIL_CHANGE_CONFIRMATION
  },
  /**
   * Legal
   */
  {
    isExact: true,
    component: PrivacyPolicy,
    ...PRIVACY_POLICY
  },
  {
    isExact: true,
    component: TermsConditions,
    ...TERMS_CONDITIONS
  },
  /**
   * Self-service
   */
  {
    isExact: true,
    component: MyAccount,
    isAuthenticatedRoute: true,
    ...MY_ACCOUNT
  },
  /**
   * Notification Administration
   */
  {
    isExact: true,
    component: Notifications,
    isAuthenticatedRoute: true,
    ...NOTIFICATION_ADMIN
  },
  /**
   * System Administration
   */
  {
    isExact: true,
    component: SystemSettings,
    isAuthenticatedRoute: true,
    ...SYSTEM_SETTINGS
  },
  {
    isExact: true,
    component: ApiStatus,
    isAuthenticatedRoute: true,
    ...API_STATUS
  },
  /**
   * Provider Administration
   */
  {
    isExact: true,
    component: UserAdd,
    isAuthenticatedRoute: true,
    ...PROVIDER_USER_CREATE
  },
  {
    isExact: true,
    component: ProviderAdd,
    isAuthenticatedRoute: true,
    ...PROVIDER_CREATE
  },
  {
    isExact: true,
    component: ProviderManage,
    isAuthenticatedRoute: true,
    ...PROVIDER_MANAGE
  },
  {
    isExact: true,
    component: Providers,
    isAuthenticatedRoute: true,
    ...PROVIDER_ADMIN
  },
  {
    isExact: true,
    component: Providers,
    isAuthenticatedRoute: true,
    ...PROVIDER_CONTACT_DETAILS
  },

  /**
   * Network Administration
   */
  {
    isExact: true,
    component: NetworkAdd,
    isAuthenticatedRoute: true,
    ...NETWORK_CREATE
  },
  {
    isExact: true,
    component: UserAdd,
    isAuthenticatedRoute: true,
    ...NETWORK_USER_CREATE
  },
  {
    isExact: true,
    component: NetworkManage,
    isAuthenticatedRoute: true,
    ...NETWORK_MANAGE
  },
  {
    isExact: true,
    component: Networks,
    isAuthenticatedRoute: true,
    ...NETWORK_ADMIN
  },
  /**
   * Organisation Administration
   */
  {
    isExact: true,
    component: UserAdd,
    isAuthenticatedRoute: true,
    ...ORGANISATION_USER_CREATE
  },
  {
    isExact: true,
    component: OrganisationManage,
    isAuthenticatedRoute: true,
    ...ORGANISATION_MANAGE
  },
  {
    isExact: true,
    component: Organisations,
    isAuthenticatedRoute: true,
    ...ORGANISATION_ADMIN
  },
  /**
   * Quick Quote
   */
  { isExact: true, component: QuickQuote, isAuthenticatedRoute: true, ...QUICK_QUOTE },
  /**
   * User Administration
   */
  {
    isExact: true,
    component: UserAdd,
    isAuthenticatedRoute: true,
    ...USER_CREATE
  },
  {
    isExact: true,
    component: Users,
    isAuthenticatedRoute: true,
    ...USER_ADMIN
  },
  /**
   * Lead Administration
   */
  {
    isExact: true,
    component: LeadAdd,
    isAuthenticatedRoute: true,
    ...LEAD_CREATE
  },
  {
    isExact: true,
    component: Leads,
    isAuthenticatedRoute: true,
    ...LEAD_ADMIN
  },
  /**
   * Client Administration
   */
  {
    isExact: true,
    component: Clients,
    isAuthenticatedRoute: true,
    ...CLIENT_ADMIN
  },
  /**
   * Agency Codes and Commission Rates
   */
  {
    isExact: true,
    component: AgencyCodes,
    isAuthenticatedRoute: true,
    ...AGENCY_CODE_ADMIN
  },
  /**
   * Client Journey
   */
  {
    isExact: true,
    component: Journey,
    isAuthenticatedRoute: true,
    ...JOURNEY
  },
  {
    isExact: true,
    component: Journeys,
    isAuthenticatedRoute: true,
    ...JOURNEY_ADMIN
  },
  /**
   * Policies
   */
  {
    isExact: true,
    component: Policies,
    isAuthenticatedRoute: true,
    ...POLICY_ADMIN
  },
  {
    isExact: true,
    component: Policies,
    isAuthenticatedRoute: true,
    ...POLICY_ADMIN_AWAITING_TERMS
  },
  {
    isExact: true,
    component: Policies,
    isAuthenticatedRoute: true,
    ...POLICY_ADMIN_AWAITING_ACCEPTANCE
  },
  {
    isExact: true,
    component: Policies,
    isAuthenticatedRoute: true,
    ...POLICY_ADMIN_FAILED_ONBOARDING
  },
  {
    isExact: true,
    component: QuickQuoteLog,
    isAuthenticatedRoute: true,
    ...QUICK_QUOTE_LOG
  },
  /**
   * Commission Rates
   */
  {
    isExact: true,
    component: CommissionRates,
    isAuthenticatedRoute: true,
    ...COMMISSION_RATES
  },
  /**
   * Something Went Wrong
   */
  {
    isExact: true,
    component: Forbidden,
    isAuthenticatedRoute: false,
    ...FORBIDDEN
  },
  /**
   * Dashboard
   */
  {
    isExact: true,
    isStrict: true,
    component: Dashboard,
    isAuthenticatedRoute: true,
    ...DASHBOARD
  },
  {
    component: NotFound,
    isAuthenticatedRoute: false,
    ...NOT_FOUND
  }
]

export default routes
