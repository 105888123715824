import React from "react"

// Components
import NetworkPoliciesProvider from "./context/policies.provider"
import PolicyAdmin, { PoliciesPanel } from "../../../../UI/Templates/PolicyAdmin"

const Policies = () => (
  <NetworkPoliciesProvider>
    <PolicyAdmin />
    <PoliciesPanel />
  </NetworkPoliciesProvider>
)

export default Policies
