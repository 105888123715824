import styled from "styled-components"

// Helpers
import { getBreakpoint } from "@4cplatform/elements/Helpers"

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 17.77%);
  margin-left: ${({ isNavigationCollapsed }) => (isNavigationCollapsed ? "5rem" : "10rem")};

  ${getBreakpoint({ type: "height", max: "large" })} {
    margin-left: 2.5rem;
  }
`

export const LockedWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.black};
  padding: 1rem;
  border-radius: 0.3rem;
  margin-bottom: 3rem;

  ${getBreakpoint({ type: "height", max: "large" })} {
    margin-bottom: 1rem;
  }
`

export const LockedIcon = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export const PageComponentWrapper = styled.div`
  display: ${({ isLoading }) => (isLoading ? "none" : "block")}; ;
`
