import React, { useContext, Fragment } from "react"
import { get, isEmpty } from "lodash"
import moment from "moment"
import { Button, Modal, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { H2, H3, P } from "@4cplatform/elements/Typography"

// Helpers
import { JourneyContext } from "../../../../../../journey.context"
import { MedicalHistoryContext } from "./medicalHistory.context"
import { getName } from "../../../../../../../../Helpers"

// Components
import {
  NotesWrapper,
  NotesHeader,
  NotesTitleWrapper,
  NotesNumberWrapper
} from "./medicalHistory.styles"
import NoteForm from "./medicalHistory.notes.form"
import { TextSetting } from "../../../../../../../../Organisms"

const AddNoteModalTitle = () => (
  <H2
    margin="0"
    helperTitle="Note"
    helperText={`
  <p>Where possible, please obtain the following information from the client in relation to the condition disclosed:</p>
  <ul>
    <li>Diagnosis.</li>
    <li>Nature of symptoms (if no diagnosis made).</li>
    <li>Date of first symptoms and when saw GP/Specialist.</li>
    <li>Nature of treatment received.</li>
    <li>Date of last symptoms/treatment.</li>
    <li>Any known underlying cause.</li>
    <li>Specific location suffered on the body (including the left or right side).</li>
    <li>Any prognosis.</li>
  </ul>
`}
  >
    Add Medical Note
  </H2>
)

const Notes = () => {
  const {
    notes,
    applicants,
    notesLoading,
    setNotesModal,
    notesModal,
    addNoteLoading,
    updateNoteLoading,
    onDeleteNote,
    onCancelEditForm,
    saveChangesForNoteModal,
    setSaveChangesForNoteModal,
    deleteNoteModal,
    setDeleteNoteModal,
    deleteNoteLoading,
    onEditHandleSubmit,
    setSelectedNote,
    selectedNote,
    isEdit,
    setEdit
  } = useContext(MedicalHistoryContext)
  const { data } = useContext(JourneyContext)
  const selectedNoteId = get(selectedNote, "id")
  const isLocked = get(data, "journey.locked", false)

  return (
    <NotesWrapper>
      <NotesHeader>
        <NotesTitleWrapper hasItems={notes.length > 0}>
          <H3
            margin="0 0 2rem"
            helperTitle="Note"
            helperText="Where possible, please obtain the following information from the client in relation to the condition disclosed:
            <li>Diagnosis.</li>
            <li>Nature of symptoms (if no diagnosis made).</li>
            <li>Date of first symptoms and when saw GP/Specialist.</li>
            <li>Nature of treatment received.</li>
            <li>Date of last symptoms/treatment.</li>
            <li>Any known underlying cause.</li>
            <li>Specific location suffered on the body (including the left or right side).</li>
            <li>Any prognosis.</li>"
          >
            Medical Notes
          </H3>
          {notes.length && !notesLoading && !updateNoteLoading && !deleteNoteLoading ? (
            <NotesNumberWrapper>
              <P margin="0" appearance="light">{`${notes.length}`}</P>
            </NotesNumberWrapper>
          ) : (
            <P
              margin="0.5rem 0 0 2rem"
              isLoading={addNoteLoading || notesLoading || updateNoteLoading || deleteNoteLoading}
            >
              No medical notes exist
            </P>
          )}
        </NotesTitleWrapper>
        {selectedNote === null && (
          <Button
            name="add_new_note"
            trailingIcon="message-plus"
            isLoading={addNoteLoading || notesLoading || updateNoteLoading || deleteNoteLoading}
            onClick={setNotesModal}
            isDisabled={isLocked}
          >
            Add Note
          </Button>
        )}
      </NotesHeader>

      {notesModal && (
        <Modal name="add_note_modal" CustomTitle={AddNoteModalTitle} hasClose={false}>
          <NoteForm />
        </Modal>
      )}

      {saveChangesForNoteModal && (
        <ConfirmationModal
          hasTrailingIcon={false}
          confirmText="Discard changes"
          confirmAppearance="error"
          cancelAppearance="errorGhost"
          cancelText="Cancel"
          onConfirm={() => {
            selectedNote === null ? setNotesModal(false) : setSelectedNote(null)
            setSaveChangesForNoteModal(false)
          }}
          onCancel={() => setSaveChangesForNoteModal(false)}
          onClose={() => setSaveChangesForNoteModal(false)}
          name="save_changes_for_note_modal"
          hasPadding={false}
        >
          <P>Are you sure you want to lose unsaved changes?</P>
        </ConfirmationModal>
      )}

      {applicants.map(applicant => {
        const applicantNotes = notes.filter(note => applicant.id === note.journey_applicant_id)
        if (isEmpty(applicantNotes)) return null

        const applicantName = getName({ data: applicant })
        const applicantId = get(applicant, "id", "")

        return (
          <Fragment
            data-testid={`applicant_${applicantId}-notes_wrapper`}
            key={`applicant_${applicantId}-notes_wrapper`}
          >
            <H3
              isLoading={notesLoading || addNoteLoading || updateNoteLoading || deleteNoteLoading}
            >
              {applicantName}
            </H3>
            {applicantNotes.map(note => {
              const updatedAt = get(note, "updated_at")
              const updatedAtTimeStamp = moment
                .utc(updatedAt, "YYYY-MM-DDTHH:mmZ")
                .local()
                .format("DD/MM/YYYY HH:mm")
              const condition = get(note, "condition")
              const noteText = get(note, "note")
              const noteId = get(note, "id")
              const noteSlug = get(note, "slug")

              return (
                <Fragment key={noteId}>
                  <TextSetting
                    name={`applicant_${applicantId}-note_${noteId}`}
                    title={`${condition} - ${updatedAtTimeStamp}`}
                    content={
                      !(selectedNoteId === noteId) ? (
                        <P
                          name="note"
                          isLoading={
                            notesLoading || addNoteLoading || updateNoteLoading || deleteNoteLoading
                          }
                        >
                          {noteText}
                        </P>
                      ) : (
                        <NoteForm
                          noteData={{ applicantId, condition, noteText, noteSlug }}
                          isEdit
                        />
                      )
                    }
                    canEditHTML={false}
                    isCustomContent
                    isLoading={
                      notesLoading || addNoteLoading || updateNoteLoading || deleteNoteLoading
                    }
                    isHeaderButtonDisabled={selectedNoteId === noteId}
                    isEdit={isEdit && selectedNoteId === noteId}
                    setEdit={() => {
                      setEdit(true)
                      setSelectedNote(note)
                    }}
                    onDelete={() => {
                      setDeleteNoteModal(true)
                      setSelectedNote(note)
                    }}
                    onEdit={onEditHandleSubmit}
                    onCancel={onCancelEditForm}
                    shouldShowDeleteOnEdit
                    isDisabled={isLocked}
                  />
                  {deleteNoteModal && (
                    <ConfirmationModal
                      confirmIcon="delete"
                      confirmText="Yes"
                      confirmAppearance="error"
                      cancelAppearance="errorGhost"
                      onClose={() => {
                        if (!isEdit) {
                          setSelectedNote(null)
                        }
                        setDeleteNoteModal(false)
                      }}
                      onConfirm={onDeleteNote}
                      name="delete_note_modal"
                      hasPadding={false}
                      isLoadingConfirm={deleteNoteLoading}
                      isLoadingCancel={deleteNoteLoading}
                    >
                      <P>Are you sure you want to delete this note?</P>
                    </ConfirmationModal>
                  )}
                </Fragment>
              )
            })}
          </Fragment>
        )
      })}
    </NotesWrapper>
  )
}
export default Notes
