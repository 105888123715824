import React, { useContext } from "react"
import { Helmet } from "react-helmet-async"
import { AuthContext } from "@4cplatform/elements/Auth"
import { H1, P } from "@4cplatform/elements/Typography"

// Components
import { Breadcrumbs } from "../../UI/Molecules"
import { Tabs, Tab } from "../../UI/Organisms"
import Details from "./pages/Details"
import Settings from "./pages/Settings"
import AgencyCodes from "./pages/AgencyCodes"
import QuickQuote from "./pages/QuickQuote"

// Helpers
import { DASHBOARD } from "../../config/pages"

const MyAccountTabs = () => {
  const { canAccess } = useContext(AuthContext)

  return (
    <>
      <Helmet>
        <title>My Account</title>
      </Helmet>
      <Breadcrumbs
        trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: "My Account" }]}
      />
      <H1 margin="0 0 6rem">My Account</H1>
      <P>View and manage your 4C Platform account details and settings.</P>
      <Tabs linksMargin="0 0 2rem" hasQueryControls name="my-account">
        <Tab header="Details">
          <Details />
        </Tab>
        <Tab header="Settings">
          <Settings />
        </Tab>
        <Tab header="Quick Quote" isPresent={canAccess(["SALES_ADVISER", "ORG_ADMIN"])}>
          <QuickQuote />
        </Tab>
        <Tab header="Agency codes" isPresent={canAccess(["SALES_ADVISER"])}>
          <AgencyCodes />
        </Tab>
      </Tabs>
    </>
  )
}

export default MyAccountTabs
