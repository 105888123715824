import React from "react"

// Components
import PoliciesProvider from "./context/policies.provider"
import PolicyAdmin, { PoliciesPanel } from "../../../../UI/Templates/PolicyAdmin"

const Policies = () => (
  <PoliciesProvider>
    <PolicyAdmin />
    <PoliciesPanel />
  </PoliciesProvider>
)

export default Policies
