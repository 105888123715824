import React, { useContext } from "react"
import { get } from "lodash"
import { useFormik } from "formik"
import { TextArea } from "@4cplatform/elements/Forms"
import { H3, P } from "@4cplatform/elements/Typography"
import { Button, Modal } from "@4cplatform/elements/Molecules"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { QuotationSummaryContext } from "./context/quotationSummary.context"
import { demandsNeedsModel as validationSchema } from "./quotationSummary.helpers"

// Components
import {
  DemandsNeedsModalHeader,
  DemandsNeedsModalHeaderContent,
  DemandsNeedsModalBody,
  DemandsNeedsModalFooter
} from "./quotationSummary.styles"
import QuoteProviderLogo from "../../../../../QuotationSummaryTable/quotationSummaryTable.providerLogo"
import { JourneyContext } from "../../../../../../journey.context"

const DemandsNeedsForm = () => {
  const t = useTranslations()
  const {
    demandsNeeds,
    updateDemandsNeedsLoading,
    demandsNeedsModal,
    onDemandsNeedsClose,
    onDemandsNeedsUpdate
  } = useContext(QuotationSummaryContext)
  const { data } = useContext(JourneyContext)
  const isLocked = get(data, "journey.locked", false)

  const demandsNeedsFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quote_id: get(demandsNeeds, "id", null),
      demands_and_needs: get(demandsNeeds, "demands_and_needs_custom_text", ""),
      reasons_for_recommendation: get(demandsNeeds, "reasons_for_recommendation_custom_text", ""),
      advantages_and_disadvantages: get(
        demandsNeeds,
        "advantages_and_disadvantages_custom_text",
        ""
      ),
      medical_conditions: get(demandsNeeds, "medical_conditions_custom_text", "")
    },
    validationSchema,

    onSubmit: body => onDemandsNeedsUpdate(body)
  })

  const { handleSubmit } = demandsNeedsFormik
  const formik = { ...demandsNeedsFormik, validationSchema }
  const fieldProps = {
    formik,
    isDisabled: updateDemandsNeedsLoading || isLocked
  }

  return demandsNeedsModal ? (
    <Modal
      title="Demands and needs"
      onClose={() => onDemandsNeedsClose()}
      name="demands_and_needs_modal"
      hasPadding={false}
    >
      <DemandsNeedsModalHeader>
        <QuoteProviderLogo src={get(demandsNeeds, "provider.logo_file_path", "")} />
        <DemandsNeedsModalHeaderContent>
          <H3 margin="0">{t(get(demandsNeeds, "product_name", "-"))}</H3>
          <P>#{get(demandsNeeds, "reference", "")}</P>
        </DemandsNeedsModalHeaderContent>
      </DemandsNeedsModalHeader>

      <DemandsNeedsModalBody>
        <TextArea
          label="Demands and needs"
          name="demands_and_needs"
          placeholder="Input text"
          {...fieldProps}
        />

        <TextArea
          label="Reasons for recommendation"
          name="reasons_for_recommendation"
          placeholder="Input text"
          {...fieldProps}
        />

        <TextArea
          label="Advantages and disadvantages"
          name="advantages_and_disadvantages"
          placeholder="Input text"
          {...fieldProps}
        />

        <TextArea
          label="Medical conditions"
          name="medical_conditions"
          placeholder="Input text"
          {...fieldProps}
        />
      </DemandsNeedsModalBody>

      <DemandsNeedsModalFooter>
        <Button
          appearance="primary"
          onClick={handleSubmit}
          trailingIcon="chevron-right"
          name="demands_needs_submit"
          isLoading={updateDemandsNeedsLoading}
          isDisabled={updateDemandsNeedsLoading || isLocked}
        >
          Submit
        </Button>
      </DemandsNeedsModalFooter>
    </Modal>
  ) : null
}

export default DemandsNeedsForm
