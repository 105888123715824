import React, { useReducer, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { useFormik } from "formik"
import { get, isEmpty } from "lodash"
import moment from "moment"
import { object, string } from "yup"
import { ApiError, useGet } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts/Toastify"
import { Provider } from "../../../UI/Templates/Reports"
import reducer from "./reports.reducer"

import {
  getChartKey,
  getChartLabel,
  getDefaultTabIndexes,
  REPORT_CONSTANTS
} from "../../../UI/Templates/Reports/miReports.helpers"

const {
  TABS,
  MONTHS,
  FILTER_BY,
  SALES_SUB_TABS,
  METRIC_BEST_MONTH,
  METRIC_ANNUAL_TOTAL,
  METRIC_TOTAL,
  METRIC_POLICIES_SOLD,
  METRIC_MONTHLY_AVERAGE
} = REPORT_CONSTANTS

const SALES_INDEX = TABS.findIndex(tab => tab === "sales")
const CONVERSIONS_INDEX = TABS.findIndex(tab => tab === "conversions")
const COMMISSION_INDEX = TABS.findIndex(tab => tab === "commission")

const defaultMetrics = {
  [METRIC_BEST_MONTH.KEY]: {
    subTitle: METRIC_BEST_MONTH.TITLE
  },
  [METRIC_ANNUAL_TOTAL.KEY]: {
    subTitle: METRIC_ANNUAL_TOTAL.TITLE,
    alternativeSubTitle: METRIC_TOTAL.TITLE
  },
  [METRIC_POLICIES_SOLD.KEY]: {
    subTitle: METRIC_POLICIES_SOLD.TITLE
  },
  [METRIC_MONTHLY_AVERAGE.KEY]: {
    subTitle: METRIC_MONTHLY_AVERAGE.TITLE
  }
}

const ReportProvider = ({ children }) => {
  const location = useLocation()
  const queryParsed = queryString.parse(location.search)
  const t = useTranslations()
  const [
    {
      metrics,
      filterBy,
      chartData,
      chartLabels,
      chartFormatter,
      activeTabIndex,
      activeSalesSubTabIndex,
      apiErrors
    },
    dispatch
  ] = useReducer(reducer, {
    chartData: [],
    chartLabels: [],
    metrics: defaultMetrics,
    filterBy: FILTER_BY.YEAR,
    chartFormatter: undefined,
    ...getDefaultTabIndexes(queryParsed),
    apiErrors: {}
  })

  const formik = useFormik({
    initialValues: {
      toDate: moment().format("YYYY-MM-DD"),
      fromDate: moment().add(-1, "d").format("YYYY-MM-DD"),
      filterRegion: "",
      filterCounty: "",
      filterPostcode: "",
      underwritingTypeFmu: false,
      underwritingStyleNew: false,
      underwritingStyleSwitch: false,
      underwritingTypeMoratorium: false,
      filterUsers: [{ condition: "included", key: "", value: "" }],
      filterProducts: [{ key: "", value: "" }],
      filterProviders: [{ key: "", value: "" }],
      filterOrganisations: [{ condition: "included", key: "", value: "" }],
      filterNetworks: [{ condition: "included", key: "", value: "" }]
    },
    validationSchema: object({
      toDate: string(),
      fromDate: string()
    }),
    onSubmit: () => {}
  })

  const tabKey = useMemo(
    () =>
      TABS[activeTabIndex] +
      (activeSalesSubTabIndex > -1 ? `_${SALES_SUB_TABS[activeSalesSubTabIndex]}` : ""),
    [activeTabIndex, activeSalesSubTabIndex]
  )

  const getChartData = useCallback(
    function (key, arr) {
      const result = {
        data: [],
        labels: [],
        formatter: undefined
      }

      if (!arr) return result

      return arr.reduce((prev, cur) => {
        switch (key) {
          case "MINI_CHART":
          case `${TABS[SALES_INDEX]}_${SALES_SUB_TABS[0]}`:
          case TABS[COMMISSION_INDEX]:
            prev.labels.push(getChartLabel(filterBy, cur))
            prev.data.push(parseFloat(cur.total_premium).toFixed(2) ?? 0)
            prev.formatter = val =>
              `£${parseFloat(val).toLocaleString(undefined, { minimumFractionDigits: 2 })}`
            return prev

          case TABS[CONVERSIONS_INDEX]:
            prev.labels.push(cur.title)
            prev.data.push(parseInt(cur.number_of_policies) ?? 0)
            return prev

          case `${TABS[SALES_INDEX]}_${SALES_SUB_TABS[1]}`:
            prev.labels.push(getChartLabel(filterBy, cur))
            prev.data.push(parseInt(cur.policy_sold) ?? 0)
            return prev

          case `${TABS[SALES_INDEX]}_${SALES_SUB_TABS[2]}`:
            prev.labels.push(t(cur.product_name))
            prev.data.push(parseInt(cur.policy_sold) ?? 0)
            return prev

          default:
            return prev
        }
      }, result)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterBy]
  )

  const updateData = response => {
    // Get mini chart data
    let chart = getChartData("MINI_CHART", get(response, "data.sale_chart_data", []))
    const getMetricValue = key => {
      const responseData = get(response, `data.${key}`, 0)
      return responseData ? parseFloat(responseData) : 0
    }

    const monthlyAverage = get(response, `data.${METRIC_MONTHLY_AVERAGE.KEY}`, "").toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2
      }
    )

    const bestMonthTotalPremium = getMetricValue(
      `${METRIC_BEST_MONTH.KEY}.total_premium`
    ).toLocaleString(undefined, { minimumFractionDigits: 2 })

    const _metrics = {
      [METRIC_ANNUAL_TOTAL.KEY]: {
        ...defaultMetrics[METRIC_ANNUAL_TOTAL.KEY],
        data: chart.data,
        labels: chart.labels,
        title: `£${getMetricValue(`${METRIC_ANNUAL_TOTAL.KEY}.total_premium`).toLocaleString(
          undefined,
          { minimumFractionDigits: 2 }
        )}`
      },
      [METRIC_MONTHLY_AVERAGE.KEY]: {
        ...defaultMetrics[METRIC_MONTHLY_AVERAGE.KEY],
        ...(monthlyAverage && monthlyAverage !== "0.00"
          ? { title: `£${monthlyAverage}` }
          : { title: "No Data" })
      },
      [METRIC_BEST_MONTH.KEY]: {
        ...defaultMetrics[METRIC_BEST_MONTH.KEY],
        ...(bestMonthTotalPremium && bestMonthTotalPremium !== "0.00"
          ? {
              title: `£${bestMonthTotalPremium}`,
              bigTitle: MONTHS[getMetricValue(`${METRIC_BEST_MONTH.KEY}.month`)]
            }
          : { title: "No Data" })
      },
      [METRIC_POLICIES_SOLD.KEY]: {
        ...defaultMetrics[METRIC_POLICIES_SOLD.KEY],
        title: `${get(response, "data.policy_values.quantity") || "0"}`,
        bigTitle: `£${getMetricValue("policy_values.total_premium" || "0").toLocaleString(
          undefined,
          { minimumFractionDigits: 2 }
        )}`
      }
    }

    chart = getChartData(tabKey, get(response, `data.${getChartKey(tabKey)}`, []))

    const updateitems = [
      ["metrics", _metrics],
      ["chartData", chart.data],
      ["chartLabels", chart.labels],
      ["chartFormatter", chart.formatter]
    ]

    updateitems.forEach(item =>
      dispatch({
        type: "UPDATE_VALUE",
        key: item[0],
        value: item[1]
      })
    )
    // eslint-disable-next-line no-use-before-define
    const clientPostcode = get(query, "client_postcode")
    if (clientPostcode && get(formik, "values.filterPostcode") !== clientPostcode) {
      formik.setFieldValue("filterPostcode", clientPostcode)
    }
  }

  const query = useMemo(() => {
    const { fromDate, toDate } = formik.values
    if (!fromDate || !toDate) return undefined

    const parameters = {
      chart: tabKey
    }

    switch (filterBy) {
      case FILTER_BY.YEAR:
        parameters.year = fromDate.split("-").at(0)
        break
      case FILTER_BY.MONTH:
        parameters.year = fromDate.split("-").at(0)
        parameters.month = parseInt(fromDate.split("-").at(1))
        break
      case FILTER_BY.CUSTOM:
        parameters.from_date = fromDate
        parameters.to_date = toDate
        break
      default:
        break
    }

    const filterKeys = [
      // 1st index is filter form fields' names 2nd is api accepted parameter names
      // and the 3rd is the values will be parsed into parameter (checkbox select field only)
      ["filterUsers", "condition", "sales_agents_included", "sales_agents_excluded"],
      ["filterProviders", "providers"],
      ["filterProducts", "product_name"],
      ["filterOrganisations", "condition", "organisations_included", "organisations_excluded"],
      ["filterNetworks", "condition", "networks_included", "networks_excluded"],
      ["underwritingStyleNew", "underwriting_style", "NEW"],
      ["underwritingStyleSwitch", "underwriting_style", "SWITCH"],
      ["underwritingTypeFmu", "underwriting_type", "FMU"],
      ["underwritingTypeMoratorium", "underwriting_type", "MORI"]
    ]
    // This is to convert submitted filter form fields' values into approriate parameter
    filterKeys.forEach(item => {
      if (Array.isArray(formik.values[item[0]])) {
        // if the filter form fields' values is an array  (dropdown select field) convert it's data into new array with new parameter name
        if (formik.values[item[0]] && formik.values[item[0]].filter(o => !!o.key).length > 0) {
          if (item[1] === "condition") {
            parameters[item[2]] = formik.values[item[0]]
              .filter(o => !!o.key && o.condition === "included")
              .map(o => o.key)

            parameters[item[3]] = formik.values[item[0]]
              .filter(o => !!o.key && o.condition === "not included")
              .map(o => o.key)
          } else {
            parameters[item[1]] = formik.values[item[0]].filter(o => !!o.key).map(o => o.key)
          }
        }
      } else if (formik.values[item[0]]) {
        // if the filter form fields' values is boolean (checkbox select field) and has true value (checked) group them into new parameter name
        parameters[item[1]] = parameters[item[1]]
          ? [...parameters[item[1]], ...[item[2]]]
          : [item[2]]
      }
    })

    const locationKeys = [
      ["filterCounty", "client_county"],
      ["filterRegion", "client_region"],
      ["filterPostcode", "client_postcode"]
    ]

    locationKeys.forEach(item => {
      if (formik.values[item[0]]) {
        parameters[item[1]] = formik.values[item[0]]

        if (item[0] === "filterPostcode") {
          let searchVal = formik.values[item[0]].trim().replace(/\s\s+/g, " ").toUpperCase()
          // Check for white space
          if (searchVal.length > 3 && !/\s/.test(searchVal)) {
            searchVal = [...searchVal] // convert to array of characters
            searchVal.splice(searchVal.length - 3, 0, " ")
            searchVal = searchVal.join("") // convert back to string

            parameters[item[1]] = searchVal
          }
        }
      }
    })

    return parameters
  }, [filterBy, formik.values, tabKey])

  // Get report data by the filters
  const { loading: queryLoading } = useGet({
    endpoint: "/mi-report",
    query,
    onCompleted: response => updateData(response),
    onError: err => {
      if (!isEmpty(err.validation)) {
        const toDateError = get(err, "validation.to_date[0]")
        if (toDateError) {
          addAlert({
            message: t("MI_NOT_GREATER_THAN_DATE"),
            type: "error",
            dismissible: true,
            timeout: 5
          })
        }
      } else {
        dispatch({ type: "UPDATE_VALUE", key: "apiErrors", value: err })
      }
    }
  })

  // Get location (counties) data
  const { data: counties } = useGet({
    endpoint: "/mi-report/counties"
  })

  // Get location (regions) data
  const { data: regions } = useGet({
    endpoint: "/mi-report/regions"
  })

  return (
    <Provider
      value={{
        formik,
        metrics,
        regions,
        counties,
        filterBy,
        chartData,
        chartLabels,
        queryLoading,
        chartFormatter,
        activeTabIndex,
        activeSalesSubTabIndex,
        setActiveTabIndex: value =>
          dispatch({ type: "UPDATE_VALUE", key: "activeTabIndex", value }),
        setActiveSalesSubTabIndex: value =>
          dispatch({ type: "UPDATE_VALUE", key: "activeSalesSubTabIndex", value }),
        setFilterBy: value => dispatch({ type: "UPDATE_VALUE", key: "filterBy", value })
      }}
    >
      {children}
      <ApiError error={apiErrors} />
    </Provider>
  )
}

ReportProvider.defaultProps = {
  children: null
}

ReportProvider.propTypes = {
  children: PropTypes.any
}

export default ReportProvider
