import React, { useContext } from "react"
import { get } from "lodash"
import { useFormik } from "formik"
import { H2, P } from "@4cplatform/elements/Typography"
import { TextArea } from "@4cplatform/elements/Forms"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { PoliciesContext } from "../../policies.context"
import { getName } from "../../../../Helpers"

const DeclineUnderwriting = () => {
  const {
    viewData,
    setDeclineUnderwritingModal,
    onDeclineUnderwriting,
    declineUnderwritingLoading,
    submitJourneyAudit
  } = useContext(PoliciesContext)
  const { GLOBAL_TITLES } = useContext(ConfigContext)

  const name = getName({
    data: get(viewData, "client"),
    hasTitle: true,
    hasMiddle: true,
    globalTitles: GLOBAL_TITLES
  })
  const providerName = get(viewData, "provider.name")
  const monthly = get(viewData, "monthly_premium", "")
  const monthsOfCover = get(viewData, "months_of_cover", "")
  const reference = get(viewData, "reference", "-")
  const salesAgentParent = get(viewData, "sales_agent.parent.name")

  const declineUnderwritingFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      underwriting_declined_reason: ""
    },
    onSubmit: body => {
      submitJourneyAudit({
        page: "Submit to provider",
        responses: [
          {
            name: "Response",
            value: "Declined"
          },
          {
            name: "Submit failure reason",
            value: get(body, "underwriting_declined_reason")
          }
        ]
      })
      onDeclineUnderwriting({ body })
    }
  })

  const { handleSubmit } = declineUnderwritingFormik
  const formik = { ...declineUnderwritingFormik }

  return (
    <>
      <ConfirmationModal
        formik={formik}
        hasPadding={false}
        hasTrailingIcon={false}
        leadingIcon="arrow-left"
        confirmText="Go back"
        confirmAppearance="primary"
        cancelIcon="check"
        cancelText="Yes, decline"
        cancelAppearance="error"
        isLoadingCancel={declineUnderwritingLoading}
        onClose={() => {
          setDeclineUnderwritingModal(false)
        }}
        onConfirm={() => {
          setDeclineUnderwritingModal(false)
        }}
        onCancel={handleSubmit}
      >
        <H2 margin="0 0 1rem" fontSize="22px">
          {`Decline quote ${reference} underwriting`}
        </H2>
        <P margin="0 0 1rem">{`This action will decline the quote by ${salesAgentParent} for:`}</P>
        <P margin="0">{name}</P>
        <P margin="0">{providerName}</P>
        <P margin="0">£{monthly} per month</P>
        <P>{monthsOfCover} months of cover</P>

        <TextArea
          name="underwriting_declined_reason"
          margin="0 0 3rem"
          isRequired
          formik={formik}
          placeholder="e.g. Request more information"
          label="Reason for declining underwriting"
        />
      </ConfirmationModal>
    </>
  )
}

export default DeclineUnderwriting
