import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { get, set, find, cloneDeep } from "lodash"
import { useFormik } from "formik"
import { object, string } from "yup"

import { AuthContext } from "@4cplatform/elements/Auth"
import { PageContext } from "@4cplatform/elements/Organisms"
import { Button } from "@4cplatform/elements/Molecules"

// Components
import StartJourneyModal from "./startJourney.modal"

const StartJourney = ({
  clientId,
  onStartJourney,
  isJourneyLoading,
  clientJourneys,
  isDataLoading
}) => {
  const { user } = useContext(AuthContext)
  const { selfServiceData, selfServiceUpdate } = React.useContext(PageContext)

  const [modal, setModal] = useState(false)

  const isSimulationMode = get(
    find(user.settings, ({ key }) => key === "SIMULATION_MODE"),
    "data.value",
    false
  )

  const validationSchema = object({
    product_type: string().required("MISSING_REQUIRED_FIELD")
  })
  const selectJourneyFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      product_type: "PMI",
      user_id: selfServiceData.id
    },
    validationSchema,
    onSubmit: body => onStartJourney({ body })
  })
  const { handleSubmit } = selectJourneyFormik
  const formik = { ...selectJourneyFormik, validationSchema }

  const updateValue = res => {
    const newData = cloneDeep(selfServiceData)
    set(
      find(get(newData, "settings", []), ["key", get(res, "data.key", {})]),
      "data.value",
      get(res, "data.data.value", false)
    )
    selfServiceUpdate({ ...newData })
  }

  return (
    <>
      <Button
        appearance="success"
        trailingIcon="arrow-right"
        isLoading={isDataLoading}
        margin="2rem 0"
        onClick={() => {
          setModal(true)
        }}
        name="start_journey"
      >
        Start journey
      </Button>
      {modal && (
        <StartJourneyModal
          clientId={clientId}
          isJourneyLoading={isJourneyLoading}
          clientJourneys={clientJourneys}
          handleSubmit={handleSubmit}
          setModal={setModal}
          isSimulationMode={isSimulationMode}
          formik={formik}
          slug={get(selfServiceData, "slug", "")}
          settingID={get(
            find(get(selfServiceData, "settings", []), ["key", "SIMULATION_MODE"]),
            "id",
            null
          )}
          updateValue={updateValue}
        />
      )}
    </>
  )
}

StartJourney.propTypes = {
  /**
   * Selected client id
   */
  clientId: PropTypes.number,
  /**
   * Loading prop for journey
   */
  isJourneyLoading: PropTypes.bool.isRequired,
  /**
   * The submit function for starting the journey
   */
  onStartJourney: PropTypes.func.isRequired,
  /**
   * The selectedClient journeys
   */
  clientJourneys: PropTypes.number.isRequired,
  /**
   * Loading data to start a journey
   */
  isDataLoading: PropTypes.bool.isRequired
}

export default StartJourney
