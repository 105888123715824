import React from "react"
import PropTypes from "prop-types"

// Components
import { AgencyCodeAdmin } from "../../../../../../UI/Templates"

import AgencyCodesProvider from "./context/usersAgencyCodes.provider"

const AgencyCodes = ({ pendingType }) => (
  <AgencyCodesProvider pendingType={pendingType}>
    <AgencyCodeAdmin hasActions={false} />
  </AgencyCodesProvider>
)

AgencyCodes.defaultProps = {
  pendingType: "false"
}

AgencyCodes.propTypes = {
  pendingType: PropTypes.string
}
export default AgencyCodes
