import styled from "styled-components"
import { get } from "lodash"
import { Container as ElementsContainer } from "@4cplatform/elements/Atoms"

export const Container = styled(ElementsContainer)`
  display: flex;
  flex-direction: column;
  gap: 7rem;
`

export const HorizontalRule = styled.hr`
  border-color: ${({ theme, colour }) => get(theme, colour || "darkBlue", colour || "darkBlue")};
  border-style: solid;
  border-width: 0.2rem;
  width: 30rem;
`
