import { useEffect, useContext } from "react"
import PropTypes from "prop-types"

import { JourneyContext } from "../../../../../../../journey.context"

const Audit = ({ isDocumentSent }) => {
  const { updateJourneyAuditData } = useContext(JourneyContext)

  useEffect(() => {
    updateJourneyAuditData([
      {
        mode: "unshift",
        data: [
          {
            name: "Send client the document notification successfully",
            value: "Success"
          }
        ]
      },
      {
        mode: "append",
        data: [
          {
            name: "File uploaded",
            value: ""
          }
        ]
      }
    ])

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentSent])

  return null
}

Audit.defaultProps = {
  isDocumentSent: false
}

Audit.propTypes = {
  isDocumentSent: PropTypes.bool
}

export default Audit
