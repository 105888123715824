import React, { useReducer, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { get, isEmpty, find } from "lodash"
import { useGet, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { Provider } from "../hospitalPreference.context"
import reducer from "./hospitalPreference.reducer"
import { JourneyContext } from "../../../../../../../journey.context"

const HospitalPreferenceProvider = ({ children }) => {
  const t = useTranslations()
  const {
    data: { journey, page: pageData },
    formik,
    updateJourneyAuditData
  } = useContext(JourneyContext)

  // State centrePoint initial coordinates are set to London
  const [
    {
      hospitals,
      selectedHospital,
      centrePoint,
      clientAddress,
      clientGeoAddress,
      hospitalsWithDistance,
      tablePaginatedData,
      isMapsApiLoaded,
      isDataLoading,
      mapData,
      total,
      page,
      perPage,
      mapApi,
      mapsApi
    },
    dispatch
  ] = useReducer(reducer, {
    hospitals: [],
    selectedHospital: get(pageData, "data.preferred_hospital", null),
    clientAddress: {},
    clientGeoAddress: {},
    centrePoint: {
      lat: 51.5072,
      lng: 0.1276
    },
    hospitalsWithDistance: [],
    tablePaginatedData: [],
    isMapLoading: true,
    isMapsApiLoaded: false,
    mapData: null,
    isDataLoading: true,
    page: 1,
    perPage: 10,
    total: null,
    mapApi: null,
    mapsApi: null
  })

  // Index Hospitals query
  const { loading: hospitalsLoading, error: hospitalsError } = useGet({
    endpoint: "/hospitals",
    onCompleted: res => {
      const data = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: data.length, data })
    },
    onError: () => {
      addAlert({
        message: t("HOSPITALS_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    },
    skip: !get(journey, "slug") || !isEmpty(hospitals)
  })

  const [postcode, setPostcode] = useState()

  // Client Data query
  const { loading: clientDataLoading, error: clientDetailsError } = useGet({
    endpoint: "/journeys/:journey/client-details",
    params: {
      journey: get(journey, "slug", "")
    },
    onCompleted: res => {
      // fetch Hospitals and make one call to the DistanceMatrixApi
      const applicants = get(res, "data.journey.applicants")
      const client = get(res, "data.journey.client")
      const primaryApplicant = find(applicants, { type: "primary" })
      const primaryApplicantAddress = get(primaryApplicant, "address", "")

      dispatch({
        type: "UPDATE_VALUE",
        key: "clientAddress",
        value: primaryApplicantAddress || get(client, "address", "")
      })
    },
    onError: () => {
      addAlert({
        message: t("CLIENT_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    },
    skip: !get(journey, "slug") || !isEmpty(clientAddress)
  })

  useGet({
    endpoint: "/latitude-longitude",
    query: {
      postcode
    },
    skip: !postcode,
    onCompleted: res => {
      const { data } = res
      dispatch({
        type: "UPDATE_VALUE",
        key: "centrePoint",
        value: {
          lat: data.latitude,
          lng: data.longitude
        }
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "clientGeoAddress",
        value: {
          lat: data.latitude,
          lng: data.longitude
        }
      })
      setPostcode(null)
    }
  })

  const getGeocode = async (addressToConvertToGeo, geocoderApi) => {
    let geocode
    try {
      geocode = await geocoderApi.geocode({ address: addressToConvertToGeo })
    } catch (error) {
      setPostcode(addressToConvertToGeo)
    }
    if (!geocode) return

    const { results } = geocode
    if (results) {
      if (results[0].geometry.location) {
        dispatch({
          type: "UPDATE_VALUE",
          key: "centrePoint",
          value: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
        })
        dispatch({
          type: "UPDATE_VALUE",
          key: "clientGeoAddress",
          value: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
        })
      }
    }
  }
  const getDistance = (originLat1, originLon1, destinationLat2, destinationLon2) => {
    const p = 0.017453292519943295 // Math.PI / 180
    const c = Math.cos
    const a =
      0.5 -
      c((destinationLat2 - originLat1) * p) / 2 +
      (c(originLat1 * p) * c(destinationLat2 * p) * (1 - c((destinationLon2 - originLon1) * p))) / 2
    const distance = Math.round(7917.5117 * Math.asin(Math.sqrt(a)) * 100) / 100 // 2 * R; R = 7917.5117 miles
    return distance.toFixed(2)
  }

  useEffect(() => {
    // initialize services
    if (isMapsApiLoaded && mapData && clientAddress.postcode) {
      dispatch({
        type: "UPDATE_VALUE",
        key: "mapsApi",
        value: mapData.maps
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "mapApi",
        value: mapData.map
      })

      const geocoderApi = new mapData.maps.Geocoder()
      getGeocode(clientAddress.postcode, geocoderApi)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapsApiLoaded, mapData, clientAddress])

  useEffect(() => {
    if (!isEmpty(clientGeoAddress) && !isEmpty(hospitals)) {
      const withDistance = hospitals
        .map(hospital => ({
          ...hospital,
          distance: getDistance(
            clientGeoAddress.lat,
            clientGeoAddress.lng,
            hospital.latitude,
            hospital.longitude
          )
        }))
        .sort((a, b) => a.distance - b.distance)

      if (formik && formik.values.preferred_hospital) {
        dispatch({
          type: "UPDATE_VALUE",
          key: "selectedHospital",
          value: find(withDistance, { id: formik.values.preferred_hospital })
        })
      }
      dispatch({
        type: "UPDATE_VALUE",
        key: "isDataLoading",
        value: false
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "hospitalsWithDistance",
        value: withDistance
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientGeoAddress, hospitals])

  useEffect(() => {
    if (!isEmpty(hospitalsWithDistance)) {
      dispatch({
        type: "UPDATE_VALUE",
        key: "tablePaginatedData",
        value: hospitalsWithDistance.slice((page - 1) * perPage, page * perPage)
      })
    }
  }, [page, perPage, hospitalsWithDistance])

  useEffect(() => {
    // initialize services
    if (selectedHospital) {
      // checking if the selected hospital is on a different page in table
      let newPage = Math.ceil(
        Math.abs((hospitalsWithDistance.indexOf(selectedHospital) + 1) / perPage)
      )
      newPage = newPage === 0 ? 1 : newPage

      if (newPage !== page) dispatch({ type: "UPDATE_VALUE", key: "page", value: newPage })

      if (mapApi && mapsApi)
        mapApi.panTo(new mapsApi.LatLng(selectedHospital.latitude, selectedHospital.longitude))
    } else if (mapApi && mapsApi && clientGeoAddress)
      mapApi.panTo(new mapsApi.LatLng(clientGeoAddress.lat, clientGeoAddress.lng))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHospital, perPage])

  useEffect(() => {
    updateJourneyAuditData([
      {
        mode: "remove",
        data: [{ name: "Prefered hospital" }]
      },
      {
        mode: "append",
        data: [
          {
            name: "Your preferred hospital",
            value: !isEmpty(selectedHospital)
              ? `${selectedHospital.name} - ${[
                  selectedHospital.address_line_one,
                  selectedHospital.address_line_two
                ]
                  .filter(l => !!l)
                  .join(", ")}, ${selectedHospital.city}, ${selectedHospital.postcode}`
              : "No hospital was selected."
          }
        ]
      }
    ])

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHospital])

  return (
    <Provider
      value={{
        hospitals,
        selectedHospital,
        centrePoint,
        clientAddress,
        clientGeoAddress,
        hospitalsWithDistance,
        hospitalsLoading,
        clientDataLoading,
        isDataLoading,
        total,
        page,
        perPage,
        tablePaginatedData,
        mapApi,
        mapsApi,
        handleApiLoaded: (map, maps) => {
          dispatch({ type: "UPDATE_VALUE", key: "mapData", value: { map, maps } })
          dispatch({ type: "UPDATE_VALUE", key: "isMapsApiLoaded", value: true })
        },
        pagination: { total, page, perPage },
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        onHospitalPreferenceSelect: row => {
          if (!row) {
            formik.setFieldValue("preferred_hospital", null)
          } else {
            formik.setFieldValue("preferred_hospital", row.id)
          }
          dispatch({ type: "UPDATE_VALUE", key: "selectedHospital", value: row })
        },
        onHospitalPreferenceDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedHospital", value: null })
        }
      }}
    >
      {children}
      <ApiError error={hospitalsError || clientDetailsError} />
    </Provider>
  )
}

HospitalPreferenceProvider.defaultProps = {
  children: null
}

HospitalPreferenceProvider.propTypes = {
  children: PropTypes.any
}

export default HospitalPreferenceProvider
