/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { isEmpty } from "lodash"
import { H4, P } from "@4cplatform/elements/Typography"
import { Icon } from "@4cplatform/elements/Atoms"
import { MiReportContext } from "./miReports.context"
import MetricCard from "./miReports.metricCard"
import {
  ReportHeaderWrapper,
  FilterWrapper,
  MetricCardHeader,
  MetricCardFooter,
  FlexAlignCenter,
  FlexStretch,
  ButtonWrapper,
  SelectWrapper as Select,
  DatePickerWrapper as DatePicker
} from "./miReports.style"
import { getYearList, REPORT_CONSTANTS } from "./miReports.helpers"
import Radios from "./miReports.radios"

const { MONTHS, FILTER_BY } = REPORT_CONSTANTS
const filterByValues = Object.keys(FILTER_BY).map(k => FILTER_BY[k])
const YEARS = getYearList()

const ReportHeader = ({ isLoading }) => {
  const [year, setYear] = useState(YEARS[0])
  const [isShowingChart, setIsShowingChart] = useState(true)
  const [month, setMonth] = useState(MONTHS[1])
  const { metrics, formik, filterBy, setFilterBy } = useContext(MiReportContext)

  useEffect(() => {
    const { fromDate } = formik.values
    if (typeof fromDate !== "string") return

    const datePaths = fromDate.split("-")
    if (isEmpty(datePaths)) return

    setYear(datePaths[0])
    setMonth(MONTHS[parseInt(datePaths[1])])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy])

  const setDate = (fromDate, toDate) => {
    formik.setFieldValue("fromDate", fromDate)
    formik.setFieldValue("toDate", toDate)
  }

  useEffect(() => {
    const { fromDate, toDate } = formik.values
    const difference = moment(toDate).diff(moment(fromDate), "month")
    if (difference > 12 && filterBy === FILTER_BY.CUSTOM) {
      setIsShowingChart(false)
    } else {
      setIsShowingChart(true)
    }
  }, [formik.values.fromDate, formik.values.toDate, filterBy])

  return (
    <>
      <P id="dateRangeForPDF" style={{ display: "none" }}>
        Date range: {moment(formik.values.fromDate).format("DD/MM/YYYY")} -{" "}
        {moment(formik.values.toDate).format("DD/MM/YYYY")}
      </P>
      <ReportHeaderWrapper>
        <FilterWrapper data-html2canvas-ignore>
          <MetricCardHeader>
            <H4 margin="1.5rem 0 1rem 0">Date range</H4>
            <Radios
              name="filter_by"
              items={filterByValues}
              selectedKey={filterBy}
              onChange={setFilterBy}
              isLoading={isLoading}
              isDisabled={isLoading}
            />
          </MetricCardHeader>
          <MetricCardFooter style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
            {filterBy === FILTER_BY.YEAR && (
              <FlexAlignCenter>
                <Select
                  name="year"
                  value={year}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onChange={value => {
                    setYear(value)

                    setDate(
                      moment(new Date(value, 0, 1)).format("YYYY-MM-DD"),
                      moment(new Date(value, 11, 31)).format("YYYY-MM-DD")
                    )
                  }}
                >
                  {YEARS.map(item => (
                    <option key={`year-option${item}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <ButtonWrapper
                  isLoading={isLoading}
                  isDisabled={isLoading || +year === YEARS[YEARS.length - 1]}
                  trailingIcon="chevron-left"
                  name="date-slider__previous"
                  appearance="primaryGhost"
                  onClick={() => {
                    const val = +year - 1
                    setYear(val)

                    setDate(
                      moment(new Date(val, 0, 1)).format("YYYY-MM-DD"),
                      moment(new Date(val, 11, 31)).format("YYYY-MM-DD")
                    )
                  }}
                />
                <ButtonWrapper
                  isLoading={isLoading}
                  isDisabled={isLoading || +year === YEARS[0]}
                  trailingIcon="chevron-right"
                  name="date-slider__next"
                  appearance="primaryGhost"
                  onClick={() => {
                    const val = +year + 1
                    setYear(val)

                    setDate(
                      moment(new Date(val, 0, 1)).format("YYYY-MM-DD"),
                      moment(new Date(val, 11, 31)).format("YYYY-MM-DD")
                    )
                  }}
                />
              </FlexAlignCenter>
            )}
            {filterBy === FILTER_BY.MONTH && (
              <FlexAlignCenter>
                <Select
                  name="year"
                  value={year}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onChange={value => {
                    setYear(value)

                    const monthIndex = MONTHS.indexOf(month) - 1
                    const startOfMonth = new Date(value, monthIndex, 1)

                    setDate(
                      moment(startOfMonth).format("YYYY-MM-DD"),
                      moment(startOfMonth).endOf("month").format("YYYY-MM-DD")
                    )
                  }}
                >
                  {YEARS.map(item => (
                    <option key={`year-option${item}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <Select
                  name="month"
                  value={month}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onChange={value => {
                    setMonth(value)

                    const monthIndex = MONTHS.indexOf(value) - 1
                    const startOfMonth = new Date(year, monthIndex, 1)

                    setDate(
                      moment(startOfMonth).format("YYYY-MM-DD"),
                      moment(startOfMonth).endOf("month").format("YYYY-MM-DD")
                    )
                  }}
                >
                  {MONTHS.slice(1).map(item => (
                    <option key={`month-option${item}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>

                <ButtonWrapper
                  isLoading={isLoading}
                  isDisabled={
                    isLoading ||
                    (`${year}` === `${YEARS[YEARS.length - 1]}` &&
                      MONTHS.indexOf(month) === MONTHS.indexOf("January"))
                  }
                  trailingIcon="chevron-left"
                  name="date-slider__previous"
                  appearance="primaryGhost"
                  onClick={() => {
                    const monthIndex = MONTHS.indexOf(month) - 1
                    const startOfMonth = new Date(year, monthIndex, 1)
                    const previousMonth = moment(startOfMonth).subtract(1, "months")

                    setMonth(moment(previousMonth).format("MMMM"))
                    setYear(moment(previousMonth).year())

                    setDate(
                      moment(previousMonth).format("YYYY-MM-DD"),
                      moment(previousMonth).endOf("month").format("YYYY-MM-DD")
                    )
                  }}
                />
                <ButtonWrapper
                  isLoading={isLoading}
                  isDisabled={
                    isLoading ||
                    (`${year}` === `${YEARS[0]}` &&
                      MONTHS.indexOf(month) === MONTHS.indexOf("December"))
                  }
                  trailingIcon="chevron-right"
                  name="date-slider__next"
                  appearance="primaryGhost"
                  onClick={() => {
                    const monthIndex = MONTHS.indexOf(month) - 1
                    const startOfMonth = new Date(year, monthIndex, 1)
                    const nextMonth = moment(startOfMonth).add(1, "months")

                    setMonth(moment(nextMonth).format("MMMM"))
                    setYear(moment(nextMonth).year())

                    setDate(
                      moment(nextMonth).format("YYYY-MM-DD"),
                      moment(nextMonth).endOf("month").format("YYYY-MM-DD")
                    )
                  }}
                />
              </FlexAlignCenter>
            )}
            {filterBy === FILTER_BY.CUSTOM && (
              <>
                <DatePicker
                  margin="0"
                  name="fromDate"
                  formik={formik}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                />
                <Icon size="2rem" icon="chevron-right" />
                <DatePicker
                  margin="0"
                  name="toDate"
                  formik={formik}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                />
              </>
            )}
          </MetricCardFooter>
        </FilterWrapper>
        <FlexStretch>
          {Object.keys(metrics).map(key => (
            <MetricCard
              key={key}
              {...metrics[key]}
              isLoading={isLoading}
              isShowingChart={isShowingChart}
            />
          ))}
        </FlexStretch>
      </ReportHeaderWrapper>
    </>
  )
}

ReportHeader.defaultProps = {
  isLoading: false
}

ReportHeader.propTypes = {
  isLoading: PropTypes.bool
}

export default ReportHeader
