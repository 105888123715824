import { string, object, boolean } from "yup"
import { validNameRegex } from "../../../../../../../../Helpers"

export const addEditApplicantModel = object({
  applicant: object({
    gender_at_birth: string().required("MISSING_REQUIRED_FIELD"),
    title: string().required("MISSING_REQUIRED_FIELD"),
    first_name: string()
      .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
      .required("MISSING_REQUIRED_FIELD"),
    middle_names: string()
      .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
      .nullable(),
    last_name: string()
      .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
      .required("MISSING_REQUIRED_FIELD"),
    email_address: string().email("INVALID_EMAIL").nullable(),
    date_of_birth: string().required("MISSING_REQUIRED_FIELD"),
    child: boolean().required("MISSING_REQUIRED_FIELD"),
    occupation: string().required("MISSING_REQUIRED_FIELD")
  }),
  questions: object({
    permanent_uk_resident: boolean()
      .oneOf([true], "MUST_BE_TRUE")
      .required("MISSING_REQUIRED_FIELD"),
    covered_with_a_gp_and_access_to_medical_records: boolean().required("MISSING_REQUIRED_FIELD"),
    pmi_required_to_fulfil_reqs_or_visa: boolean().required("MISSING_REQUIRED_FIELD"),
    tobacco_products_within_last_2_years: boolean().required("MISSING_REQUIRED_FIELD"),
    permission_to_add_member: boolean()
      .oneOf([true], "MUST_BE_TRUE")
      .required("MISSING_REQUIRED_FIELD")
  })
})
