import React from "react"
import { get, isEmpty } from "lodash"
import { H4, SmallText } from "@4cplatform/elements/Typography"
import { Skeleton } from "@4cplatform/elements/Molecules"
import { AuthContext } from "@4cplatform/elements/Auth"

// Components
import { Wrapper } from "./assignment.styles"
import Card from "./assignment.card"
import AssignDealCode from "./assignment.add"
import ViewDealCode from "./assignment.card.view"

// Helpers
import { AgencyCodesContext } from "../../agencyCodes.context"

const DealCodeAssignment = () => {
  const { assignedDealCodes, isAllLoading, viewDeal, setViewDeal } =
    React.useContext(AgencyCodesContext)
  const { canAccess } = React.useContext(AuthContext)
  const isOpenViewDealCode = get(viewDeal, "isOpen")

  return (
    <Wrapper>
      <H4 appearance="light" isLoading={isAllLoading} margin="1rem 0 2rem">
        Assigned deal codes
      </H4>
      {/* Loading state */}
      {isAllLoading ? (
        <Skeleton appearance="light" lineHeight="9rem" borderRadius="0.5rem" />
      ) : (
        <>
          {/* Deal codes */}
          {isEmpty(assignedDealCodes) && (
            <SmallText appearance="light">No deal codes have been assigned</SmallText>
          )}
          {Array.isArray(assignedDealCodes) &&
            assignedDealCodes.map(code => (
              <Card key={`assigned-deal-code-${code.id}`} code={code} />
            ))}
        </>
      )}

      {canAccess(["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN"]) && <AssignDealCode />}

      {/* View Deal Code modal */}
      {isOpenViewDealCode && (
        <ViewDealCode
          code={get(viewDeal, "data")}
          onClose={() => isAllLoading || setViewDeal({ isOpen: false, slug: null, data: null })}
        />
      )}
    </Wrapper>
  )
}

export default DealCodeAssignment
