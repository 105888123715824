import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Input, Select } from "@4cplatform/elements/Forms"
import { get } from "lodash"
import { Button } from "@4cplatform/elements/Molecules"
import { ConfigContext } from "@4cplatform/elements/Config"
import { useFormik } from "formik"

// Helpers
import { renderTitleOptions } from "@4cplatform/elements/Helpers"
import { AuthContext } from "@4cplatform/elements/Auth"
import { editUserModel as validationSchema } from "./users.helpers"
import { UsersContext } from "./users.context"

// Components
import { EditUserFormWrapper, NameWrapper, NamesWrapper, FieldWrapper } from "./users.styles"

const Form = ({ viewData }) => {
  const { user } = useContext(AuthContext)
  const { onEditUserSubmit, editLoading } = useContext(UsersContext)
  const { LOADING_TITLES, GLOBAL_TITLES } = useContext(ConfigContext)
  const isDeleted = !!get(viewData, "deleted_at")

  // Define fields for Edit User form
  const editUserFormik = useFormik({
    initialValues: {
      title: get(viewData, "title.key", ""),
      first_name: get(viewData, "first_name", ""),
      middle_names: get(viewData, "middle_names", ""),
      last_name: get(viewData, "last_name", ""),
      email: get(viewData, "email", ""),
      role: get(viewData, "role.id", "")
    },
    validationSchema,
    onSubmit: body => onEditUserSubmit({ body })
  })

  const { handleSubmit } = editUserFormik
  const formik = { ...editUserFormik, validationSchema }

  return (
    <EditUserFormWrapper data-testid="users-edit_user">
      <FieldWrapper>
        <Select name="title" label="Title" formik={formik} isDisabled={isDeleted || LOADING_TITLES}>
          {LOADING_TITLES ? (
            <option value="">Loading titles</option>
          ) : (
            <option value="">Select title</option>
          )}
          {renderTitleOptions(GLOBAL_TITLES?.data, formik)}
        </Select>
      </FieldWrapper>
      <NamesWrapper>
        <NameWrapper>
          <Input
            label="First Name"
            name="first_name"
            formik={formik}
            isDisabled={isDeleted}
            width="20.7rem"
          />
        </NameWrapper>
        <NameWrapper margin="0 0 0 0.5rem">
          <Input
            label="Middle Names"
            name="middle_names"
            formik={formik}
            isDisabled={isDeleted}
            width="20.7rem"
          />
        </NameWrapper>
        <NameWrapper margin="0 0 0 0.5rem">
          <Input
            label="Last Name"
            name="last_name"
            formik={formik}
            isDisabled={isDeleted}
            width="20.7rem"
          />
        </NameWrapper>
      </NamesWrapper>
      <FieldWrapper>
        <Input
          type="email"
          label="Email"
          name="email"
          formik={formik}
          isDisabled={isDeleted}
          width="20.7rem"
        />
      </FieldWrapper>
      <FieldWrapper>
        <Select name="role" label="Role" formik={formik}>
          {/* Org admin, support admin, system admin */}
          {(user.role.id === 4 || user.role.id === 8 || user.role.id === 9) && (
            <option value="2">Sales Adviser</option>
          )}
          {/* Org admin, support admin, system admin */}
          {(user.role.id === 4 || user.role.id === 8 || user.role.id === 9) && (
            <option value="4">Organisation Admin</option>
          )}
          {/* Provider admin, support admin, system admin */}
          {(user.role.id === 5 || user.role.id === 8 || user.role.id === 9) && (
            <option value="3">Underwriter</option>
          )}
          {/* Provider admin, support admin, system admin */}
          {(user.role.id === 5 || user.role.id === 8 || user.role.id === 9) && (
            <option value="5">Provider Admin</option>
          )}
          {/* Network admin, support admin, system admin */}
          {(user.role.id === 7 || user.role.id === 8 || user.role.id === 9) && (
            <option value="6">Network Member Admin</option>
          )}
          {/* Network admin, support admin, system admin */}
          {(user.role.id === 7 || user.role.id === 8 || user.role.id === 9) && (
            <option value="7">Network Admin</option>
          )}
          {/* Support admin, system admin */}
          {(user.role.id === 8 || user.role.id === 9) && (
            <option value="8">System Support Admin</option>
          )}
          {/* System admin */}
          {user.role.id === 9 && <option value="9">System Admin</option>}
        </Select>
      </FieldWrapper>
      {/* Submit button */}
      <Button
        appearance="success"
        trailingIcon="check"
        onClick={handleSubmit}
        data-testid="users-edit-submit"
        isLoading={editLoading}
        isDisabled={isDeleted}
        name="save"
      >
        Save
      </Button>
    </EditUserFormWrapper>
  )
}

Form.defaultProps = {
  viewData: null
}

Form.propTypes = {
  viewData: PropTypes.object
}

export default Form
