import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import moment from "moment"
import { Collapse, Button } from "@4cplatform/elements/Molecules"
import { Icon } from "@4cplatform/elements/Atoms"
import { H3 } from "@4cplatform/elements/Typography"
import { Table, PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { CommissionRatesContext } from "./commissionRates.context"

// Components
import { ActionWrapper } from "./commissionRates.styles"

const CommissionRatesAction = ({ provider }) => {
  const { queryLoading, setIsOpenAddModal, onProviderSelect, onCommissionRateSelect, formik } =
    React.useContext(CommissionRatesContext)
  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)

  const [isOpen, setOpen] = React.useState(false)

  return (
    <Collapse
      onClick={() => setOpen(curOpen => !curOpen)}
      isOpenManual={isOpen}
      headerContent={
        <ActionWrapper>
          <H3 colour={colours.blue}>{get(provider, "name", "-")}</H3>
          <div>
            <Button
              appearance="primary"
              trailingIcon="plus"
              isDisabled={queryLoading}
              onClick={e => {
                e.stopPropagation()
                setIsOpenAddModal(true)
                onProviderSelect(provider)
                formik.resetForm()
              }}
            >
              Add
            </Button>
            <Icon
              icon={isOpen ? "chevron-up" : "chevron-down"}
              colour={colours.blue}
              margin="0.5rem 0 0 1.5rem"
            />
          </div>
        </ActionWrapper>
      }
      bodyContent={
        <Table
          data={provider.commission_rates}
          isLoading={queryLoading}
          name="commission_rates"
          columns={[
            {
              label: "Members",
              dataKey: "user.full_name",
              minWidth: "80px",
              render: row => get(row, "data.user.full_name")
            },
            {
              label: "Commission rate",
              dataKey: "commission_rate",
              minWidth: "180px",
              render: row => {
                const rate = get(row, "data.commission_rate")
                return rate ? `${rate}%` : "0%"
              }
            },
            {
              label: "Start date",
              dataKey: "start_at",
              minWidth: "60px",
              render: row => {
                const created = get(row, "data.start_date", "")
                return moment.utc(created, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY")
              }
            },
            {
              label: "End date",
              dataKey: "end_at",
              minWidth: "60px",
              render: row => {
                const created = get(row, "data.end_date", "")
                return moment.utc(created, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY")
              }
            }
          ]}
          hasActions
          onClick={row => {
            onCommissionRateSelect({
              ...row,
              logo_file_path: provider?.logo_file_path || null
            })
            setPanelStatus("open")
          }}
          onClose={() => {
            if (panelStatus !== "closed") {
              setPanelStatus("closed")
            }
          }}
          isClosed={panelStatus === "closed"}
          pagination={false}
        />
      }
    />
  )
}

CommissionRatesAction.propTypes = {
  provider: PropTypes.object
}

export default CommissionRatesAction
