import React from "react"
import { get } from "lodash"
import moment from "moment"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { QuotesContext } from "./quotes.context"
import { getName } from "../../Helpers"

// Components
import Actions from "./quotes.actions"

const QuoteAdmin = () => {
  const {
    data,
    queryLoading,
    onQuoteSelect,
    onQuoteDeselect,
    pagination,
    setPage,
    setPerPage,
    onSort,
    sorting
  } = React.useContext(QuotesContext)

  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)
  const t = useTranslations()

  return (
    <>
      <Actions />
      <Table
        data={data}
        isLoading={queryLoading}
        name="quotes"
        columns={[
          {
            label: "Quote ref",
            dataKey: "reference",
            minWidth: "60px",
            sortable: true
          },
          {
            label: "Quote status",
            dataKey: "status",
            minWidth: "60px",
            render: row => t(get(row, "data.status", "IN_PROGRESS"))
          },
          {
            label: "Client Name",
            dataKey: "client",
            minWidth: "60px",
            fieldColour: get(colours, "blue", "blue"),
            sortable: true,
            render: row =>
              getName({
                data: get(row, "data.client")
              })
          },
          [
            {
              label: "Sales Organisation Name",
              dataKey: "organisation",
              minWidth: "60px",
              sortable: true,
              render: row => get(row, "data.organisation.name")
            },
            {
              label: "Sales Agent Name",
              dataKey: "sales_agent",
              minWidth: "60px",
              sortable: true,
              render: row => getName({ data: get(row, "data.sales_agent") })
            }
          ],
          {
            label: "Product Name",
            dataKey: "product_name",
            minWidth: "60px",
            sortable: true,
            render: row => t(get(row, "data.product_name", "-"))
          },
          {
            label: "Created",
            dataKey: "created_at",
            minWidth: "60px",
            render: row => {
              const created = get(row, "data.created_at", "")
              return created
                ? moment.utc(created, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          },
          {
            label: "Updated",
            dataKey: "updated_at",
            minWidth: "60px",
            render: row => {
              const updated = get(row, "data.updated_at", "")
              return updated
                ? moment.utc(updated, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          }
        ]}
        hasActions
        onClick={row => {
          onQuoteSelect(row)
          setPanelStatus("open")
        }}
        onClose={() => {
          onQuoteDeselect()
          if (panelStatus !== "closed") {
            setPanelStatus("closed")
          }
        }}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        perPageOptions={{ max: 50, interval: 5 }}
        sorting={sorting}
      />
    </>
  )
}

export default QuoteAdmin
