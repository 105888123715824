import React from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import { H1 } from "@4cplatform/elements/Typography"
import { Container } from "@4cplatform/elements/Atoms"

// Components
import { Breadcrumbs } from "../../UI/Molecules"
import ProvidersProvider from "./context/providers.provider"
import { ProviderAdmin, ProvidersPanel } from "../../UI/Templates"

// Helpers
import { DASHBOARD, PROVIDER_CONTACT_DETAILS } from "../../config/pages"

const Providers = () => {
  const { pathname } = useLocation()
  const title =
    pathname === PROVIDER_CONTACT_DETAILS.path ? "Provider contact details" : "Providers"

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ProvidersProvider>
        <Container isPageContainer width="80%">
          <Breadcrumbs trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: title }]} />
          <H1 margin="0 0 6rem">{title}</H1>
          <ProviderAdmin />
        </Container>
        <ProvidersPanel />
      </ProvidersProvider>
    </>
  )
}
export default Providers
