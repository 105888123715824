import { string, object, number, date } from "yup"
import { get } from "lodash"
import { P, H4, List } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { validPostcodeRegex } from "../../../../../../../../Helpers"
import CustomBankDetails from "./CustomBankDetails"

const getListItems = provider => {
  switch (provider) {
    case "AVIVA":
      return (
        <>
          <li>
            This Guarantee is offered by all banks and building societies that accept instructions
            to pay Direct Debits
          </li>
          <li>
            If there are any changes to the amount, date or frequency of your Direct Debit{" "}
            <strong>Aviva Health UK Limited</strong> will notify you 7 working days in advance of
            your account being debited or as otherwise agreed. If you request{" "}
            <strong>Aviva Health UK Limited</strong> to collect a payment, confirmation of the
            amount and date will be given to you at the time of the request
          </li>
          <li>
            If an error is made in the payment of your Direct Debit, by{" "}
            <strong>Aviva Health UK Limited</strong> or your bank or building society, you are
            entitled to a full and immediate refund of the amount paid from your bank or building
            society
          </li>
          <li>
            If you receive a refund you are not entitled to, you must pay it back when{" "}
            <strong>Aviva Health UK Limited</strong> asks you to
          </li>
          <li>
            You can cancel a Direct Debit at any time by simply contacting your bank or building
            society. Written confirmation may be required. Please also notify us.
          </li>
        </>
      )
    default:
      return (
        <>
          <li>
            The Guarantee is offered by all banks and building societies that accept instructions to
            pay Direct Debits
          </li>
          <li>
            If there are any changes to the amount, date or frequency of your Direct Debit the
            organisation will notify you (normally 10 working days) in advance of your account being
            debited or as otherwise agreed. If you request the organisation to collect a payment,
            confirmation of the amount and date will be given to you at the time of the request
          </li>
          <li>
            If an error is made in the payment of your Direct Debit, by the organisation or your
            bank or building society, you are entitled to a full and immediate refund of the amount
            paid from your bank or building society
          </li>
          <li>
            If you receive a refund you are not entitled to, you must pay it back when the
            organisation asks you to
          </li>
          <li>
            You can cancel a Direct Debit at any time by simply contacting your bank or building
            society. Written confirmation may be required. Please also notify the organisation.
          </li>
        </>
      )
  }
}

export const config = data => {
  const exeterInitialValues =
    get(data, "journey.policy.provider.provider_key", false) === "EXETER"
      ? {
          exeter_direct_debit: {
            declaration_agreed_by: get(
              data,
              "page.data.exeter_direct_debit.declaration_agreed_by",
              ""
            ),
            declaration_agreed_at: get(
              data,
              "page.data.exeter_direct_debit.declaration_agreed_at",
              ""
            ),
            preferred_payment_day: get(
              data,
              "page.data.exeter_direct_debit.preferred_payment_day",
              ""
            )
          }
        }
      : {}
  const exeterValidation =
    get(data, "journey.policy.provider.provider_key", false) === "EXETER"
      ? {
          exeter_direct_debit: object({
            declaration_agreed_by: string().required("MISSING_REQUIRED_FIELD"),
            declaration_agreed_at: date().required("MISSING_REQUIRED_FIELD"),
            preferred_payment_day: number()
              .min(1, "Preferred Payment Day must be between 1 and 28")
              .max(28, "Preferred Payment Day must be between 1 and 28")
              .required("MISSING_REQUIRED_FIELD")
          })
        }
      : {}

  return {
    title: "Direct debit guarantee",
    sections: [
      {
        key: "compliance_info",
        components: [
          {
            key: "list",
            component: List,
            componentProps: {
              name: "list",
              listType: "unordered",
              children: getListItems(get(data, "journey.policy.provider.provider_key", null))
            },
            skipDataMap: true
          },
          {
            key: "compliance_note",
            component: ComplianceNote,
            componentProps: {
              children: (
                <>
                  <H4 margin="0 0 1rem">Compliance note</H4>
                  <P>Please note that all bank details are encrypted.</P>
                  <P margin="0">
                    If the client has chosen to pay monthly for their policy, for compliance reasons
                    you will need to disclose the total annual premium and ask the client to confirm
                    this is affordable.
                  </P>
                </>
              ),
              type: "error",
              margin: "0"
            },
            skipDataMap: true
          }
        ]
      },
      {
        key: "account_section",
        title: "Account",
        components: [
          {
            key: "bankDetails",
            includeWithoutTheKey: true,
            initialValues: {
              name_on_account: get(data, "page.data.name_on_account", ""),
              account_number: get(data, "page.data.account_number", ""),
              sort_code: get(data, "page.data.sort_code", ""),
              bank_address: {
                name: get(data, "page.data.bank_address.name", ""),
                line_one: get(data, "page.data.bank_address.line_one", ""),
                line_two:
                  get(data, "page.data.bank_address.line_two") === null
                    ? ""
                    : get(data, "page.data.bank_address.line_two"),
                city: get(data, "page.data.bank_address.city", ""),
                postcode: get(data, "page.data.bank_address.postcode", "")
              },
              ...exeterInitialValues
            },
            validationSchema: object({
              name_on_account: string().required("MISSING_REQUIRED_FIELD"),
              account_number: string()
                .required("MISSING_REQUIRED_FIELD")
                .test("fcaReference", "ONLY_DIGITS_ALLOWED", ref => /^\d+$/.test(ref))
                .min(8)
                .max(8),
              sort_code: string()
                .required("MISSING_REQUIRED_FIELD")
                .test("len", "is not valid", val =>
                  /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/.test(val)
                ),
              bank_address: object({
                name: string().required("MISSING_REQUIRED_FIELD"),
                line_one: string().required("MISSING_REQUIRED_FIELD"),
                line_two: string().nullable(),
                city: string().required("MISSING_REQUIRED_FIELD"),
                postcode: string()
                  .test("correctUkPostcode", "CORRECT_UK_POSTCODE", no =>
                    validPostcodeRegex.test(no)
                  )
                  .required("MISSING_REQUIRED_FIELD")
              }),
              ...exeterValidation
            }),
            component: CustomBankDetails,
            componentProps: {
              isHorizontal: true,
              provider: get(data, "journey.policy.provider.provider_key", null)
            }
          }
        ]
      }
    ]
  }
}
