import React from "react"
import { get, isEmpty } from "lodash"
import { useFormik } from "formik"
import { Button } from "@4cplatform/elements/Molecules"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { H3 } from "@4cplatform/elements/Typography"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"
import { shareAgencyCodeModel as validationSchema } from "./organisationAgencyCodes.helpers"

// Components
import AgencyCodesPanelHeader from "./organisationAgencyCodes.panel.header"
import { ShareCodeActions, ShareButtonsWrapper } from "./organisationAgencyCodes.styles"
import AddUsers from "./organisationAgencyCodes.panel.sharing.add"
import UsersTable from "./organisationAgencyCodes.panel.sharing.table"

const OrganisationAgencyCodesEdit = () => {
  const { selectedAgencyCode, viewData, onSubmitShare, setRevoke, sharedUsers } =
    React.useContext(AgencyCodesContext)
  const {
    panelStatusControls: { panelStatus },
    setPanelStatus
  } = React.useContext(PageContext)

  // Formik instance
  const shareAgencyCodeFormik = useFormik({
    initialValues: {
      share_to_all: get(viewData, "share_to_all", false),
      share_with: []
    },
    validationSchema,
    onSubmit: body => onSubmitShare(body)
  })

  const { setFieldValue, submitForm } = shareAgencyCodeFormik
  const formik = { ...shareAgencyCodeFormik, validationSchema }

  return (
    <>
      <AgencyCodesPanelHeader selectedAgencyCode={selectedAgencyCode} context="wide" />
      {!isEmpty(viewData) && panelStatus !== "closed" && (
        <PanelBody>
          <H3 appearance="light">Shared with</H3>
          {/* Table actions */}
          <ShareCodeActions>
            <AddUsers
              isDisabled={!!get(formik, "values.share_to_all", false)}
              onConfirm={async ids => {
                await setFieldValue("share_with", ids)
                await submitForm()
              }}
            />
            <Button
              type="inline-button"
              appearance="errorInlineLight"
              leadingIcon="delete"
              onClick={() => setRevoke(true)}
              isDisabled={isEmpty(sharedUsers)}
            >
              Revoke shares
            </Button>
          </ShareCodeActions>

          {/* Table */}
          <UsersTable />

          {/* Save/Cancel buttons */}
          <ShareButtonsWrapper>
            <Button appearance="error" leadingIcon="cancel" onClick={() => setPanelStatus("open")}>
              Cancel
            </Button>
          </ShareButtonsWrapper>
        </PanelBody>
      )}
    </>
  )
}

export default OrganisationAgencyCodesEdit
