import moment from "moment"
import { findKey, mapValues, orderBy, sortBy } from "lodash"
import { string, object } from "yup"
import { P, A } from "@4cplatform/elements/Typography"
import { v4 as uuid } from "uuid"
import { HelperWrapper } from "./quoteComparison.styles"

export const getCurrentOptions = globalOptions =>
  mapValues(globalOptions, key => findKey(key, value => value.selected))

export const convertFlagKeyToIndicator = (flags, helpers) => {
  const trueKeys = Object.keys(flags).filter(key => flags[key] === true)
  const matchingHelpers = trueKeys.map(key => ({
    ...helpers[key]
  }))
  const sortedHelpers = sortBy(matchingHelpers, "helperOrder")
  return sortedHelpers
}

/**
 * Used to sort the quotes by monthly premium. Quotes that are succesful take priority in terms of ordering than those that are not
 * @param {Array} quotesArray
 * @returns Array
 */
export const sortQuotesByPremium = (quotesArray = []) => {
  if (!quotesArray.length) return quotesArray

  let sortedQuotes = []

  const succesfulQuotes = quotesArray.filter(_ => _.success)
  const erroredQuotes = quotesArray.filter(_ => !_.success)

  sortedQuotes = [
    ...orderBy(succesfulQuotes, [q => parseFloat(q.data.monthly_premium)], ["asc"]),
    ...orderBy(erroredQuotes, [q => moment(q.sentAt, "YYYY-MM-DDTHH:mmZ").unix()], ["asc"])
  ]
  return sortedQuotes
}

export const mapGlobalOptionsToValidationSchema = globalOptions =>
  object(mapValues(globalOptions, () => string().required("MISSING_REQUIRED_FIELD")))

export const getQuoteEditOptionHelperText = key => {
  switch (key) {
    case "UW_STYLE":
      return {}
    case "UW_TYPE":
      return {}
    case "HOSPITAL_LIST":
      return {
        helperPosition: "left",
        helperTitle: "Hospital list",
        helperText:
          "A standard hospital list will always cover the majority of hospitals with private facilities within the U.K. Depending on your location, selecting a reduced list can sometimes be a good way of reducing the premium whereas an extended list will give access to the very top hospitals, particularly in Central London."
      }
    case "OUTPATIENT":
      return {
        helperPosition: "left",
        helperTitle: "Outpatient limit",
        helperText:
          "Reducing or increasing the level of out-patient cover has an impact on the price so it is important to make the client aware of what is covered by this element of the cover to establish how important it is to them."
      }
    case "EXCESS":
      return {
        helperPosition: "left",
        helperTitle: "Excess",
        helperText:
          "You can reduce the premium of a policy by agreeing to pay a higher excess in the event of a claim."
      }
    default:
      return {}
  }
}

export const getHospitalListHelperText = (flags = {}) => {
  const helpers = {
    HOSPITAL_PREFERENCE_NOT_MATCHED: {
      helperPosition: "left",
      helperType: "warning",
      helperTitle: "Hospital Preference Not Matched",
      helperText:
        "Ensure the client is aware that the hospital list selected does not contain the client's preferred hospital."
    },
    NO_AVAILABLE_HOSPITALS_WITHIN_DISTANCE: {
      helperPosition: "left",
      helperType: "warning",
      helperTitle: "Hospital Distance",
      helperText:
        "The selected hospital list does not contain a hospital within 30 miles of the customer's address. Please select an alternative list to ensure the client has a hospital available within a reasonable distance."
    },
    AVIVA_EXPERT_SELECT: {
      helperPosition: "left",
      helperTitle: "Expert Select",
      helperText:
        "<p>An alternative to hospital lists. Simplified claims journey and direct transfer to quality approved facilities & specialists. Requires open referral.</p>"
    },
    AXA_PERSONAL_HEALTH_GUIDED_OPTION: {
      helperPosition: "left",
      helperTitle: "Guided Option",
      helperText:
        "<ul><li>A choice of suitable specialists we've selected for you</li><li>An appointment booking service for the specialists we've helped you choose</li></ol><p>Specialist treatment we've agreed with a provider we've helped you choose, paid in full</p>"
    },
    BUPA_ESS_GUIDED_OPTION: {
      helperPosition: "left",
      helperTitle: "Essential Access with Guided Care",
      helperText:
        "<p>A limited national hospital network, including a selection of private hospitals and clinics outside of Central London.</br>With Guided Care customers must call us to pre-authorise any claim before arranging or receiving any treatment. We will guide them to a choice of consultants from within our Open Referral network.</p>"
    },
    BUPA_EXT_GUIDED_OPTION: {
      helperPosition: "left",
      helperTitle: "Extended Choice with Guided Care",
      helperText:
        "<p>A longer list of hospitals and clinics available to them including some facilities in Central London.</br>With Guided Care customers must call us to pre-authorise any claim before arranging or receiving any treatment. We will guide them to a choice of consultants from within our Open Referral network.</p>"
    },
    BUPA_EXT_LON_GUIDED_OPTION: {
      helperPosition: "left",
      helperTitle: "Extended Choice with Central London and Guided Care",
      helperText:
        "<p>Same facilities as Extended Choice plus a much greater number of facilities in Central London.</br>With Guided Care customers must call us to pre-authorise any claim before arranging or receiving any treatment. We will guide them to a choice of consultants from within our Open Referral network.</p>"
    },
    VITALITY_CON_SEL_GUIDED_OPTION: {
      helperPosition: "left",
      helperTitle: "Consultant Select",
      helperText:
        "<p>Consultant Select is a simple, fully digital way to get a referral to the right high-performing consultant for your needs.</p><p>How Consultant Select works<br />Through Consultant Finder on Care Hub, we provide you with a choice of  Vitality-approved consultants, including Premier Consultants, from across the UK and all medical specialisms.<br />This means that, when you request care, you automatically get a choice of up to  10 consultants, with priority given to Premier Consultants.</p>"
    },
    EXETER_GUIDED_OPTION: {
      helperPosition: "left",
      helperTitle: "Guided Option",
      helperText:
        "<p>With The Exeter guided option, you don't choose a hospital list when you take out the policy. However, if a claim is made, the GP makes an open referral to a type of specialist and The Exeter will recommend a shortlist of specialists for you to choose from.</p>"
    }
  }
  return convertFlagKeyToIndicator(flags, helpers)
}

export const getOutpatientHelperText = (flags = {}) => {
  const helpers = {
    BUPA_BBY_OUTPATIENT_LIMITED: {
      helperPosition: "left",
      helperTitle: "Bupa - Treatment & Care",
      helperText:
        "<p>Bupa's \"Treatment and Care\" plan still offers Out-patient consultations and Out-patient minor diagnostic tests paid in full following treatment of condition/operation and within six months of being discharged.<br /><br />Out-patient therapies (including mental health therapies) paid in full.<br /> <br /> Out-patient MRI, CT, PET scans Paid in full post-diagnosis only and within six months of the discharge date of your client's hospital treatment.</p>"
    }
  }
  return convertFlagKeyToIndicator(flags, helpers)
}

export const getExcessHelperText = (flags = {}) => {
  const helpers = {
    EXCESS_NOT_MATCHED: {
      helperPosition: "left",
      helperTitle: "Excess notice",
      helperText:
        "Note: This provider does not offer an exact match for the excess level you have selected. The system has automatically chosen the closest match."
    }
  }
  return convertFlagKeyToIndicator(flags, helpers)
}

export const getPremiumHelperText = (flags = {}, environment) => {
  const conditionURL = `https://storage.googleapis.com/mgcp-1288677-4c-ng-${environment}-public-storage/Free%20cover%20for%20younger%20child%20Terms%20and%20Conditions.pdf`
  const helpers = {
    BUPA_CHILDREN_FREE_COVER: {
      helperPosition: "left",
      helperTitle: "Free cover for younger children",
      helperOrder: 1,
      helperText: (
        <HelperWrapper key={uuid()}>
          <P key={uuid()}>
            Only pay for the eldest child, up to 20 years old. New Bupa by You customers only.{" "}
            <A href={conditionURL} target="_blank">
              Terms and conditions
            </A>{" "}
            apply
          </P>
        </HelperWrapper>
      )
    },
    BUPA_OCCUPATIONAL_DISCOUNT: {
      helperPosition: "left",
      helperTitle: "15% Discount Applied",
      helperType: "warning",
      helperOrder: 2,
      helperText:
        "<p>The quoted premium has a discount of 15% applied as they have disclosed they fall underneath one of the selected occupations. Your client may be asked to provide evidence of their occupation through a GMC or DMC number to be eligible for the applied discount. If your client is unable or unwilling to provide evidence of their occupation, please update their occupation to Other.</p>"
    }
  }
  return convertFlagKeyToIndicator(flags, helpers)
}

export const getUnderwritingHelperText = (flags = {}) => {
  const helpers = {
    SHOW_BUPA_ELIGIBILITY_CRITERIA: {
      helperPosition: "left",
      helperTitle: "Eligibility Criteria",
      helperType: "warning",
      helperText:
        "<p>*Please note that for eligible switch policies, all applicants must be able to answer &#34;no&#34; to all of the below questions.</p><span><p>If you cannot, please do not proceed with this quotation.</p><p>1. In the last 5 years have any of the applicants being covered had any form of cancer that's not already excluded on their existing scheme?</p><p>2. In the last 5 years have any of the applicants had a heart or circulatory condition that's not already excluded on their existing scheme(for example coronary artery disease, angina and heart attacks, stroke, abnormal heart rhythms, blood clots or aneurysms, heart valve disorders, uncontrolled high cholesterol/blood pressure etc)?</p> <p>3. In the last 2 years have any of the applicants had any arthritic conditions and/or back, neck and spinal conditions (including sciatica) or joint replacements that are not already excluded on their existing scheme?</p> </span> <p>If you do proceed with this quote and your customer can't answer &#34;no&#34; to the above questions this quote will be invalid.* </p>"
    },
    VITALITY_SWITCH_SAVE: {
      helperPosition: "left",
      helperTitle: "Switch & Save",
      helperText:
        "<p>Switch your clients to Vitality and they could save up to 10%</p>" +
        "<p>Depending on when your client last claimed they could get a discount when they switch of up to 10%.</p>" +
        "<p>If your clients have been insured for 12 consecutive months with the same insurer on the product, benefits and underwriting type they are switching from - we’ll match their existing cover as closely as possible with VitalityHealth. The switch offer excludes health cash plans, diagnostics only plans, non-PMI modules, months free offers and negotiated new business discounts with their current insurer. </p>"
    },
    VITALITY_MORI_PLUS: {
      helperPosition: "left",
      helperTitle: "Moratorium+",
      helperText:
        "<p>The Mori+ that Vitality Health are offering, will allow customers to receive an extra discount if they answer 'No' to the following medical declaration.</p>" +
        "<p>In the last three years, has your customer or any other person to be insured on this plan:</p>" +
        "<ul>" +
        "<li>Experienced symptoms, or</li>" +
        "<li>Received any advice from  healthcare professional, or</li>" +
        "<li>Received treatment or have treatment planned or expected</li>" +
        "</ul>" +
        "<p>If the customer cannot answer “No”, they will be given Vitality’s standard moratorium pricing.</p>"
    }
  }
  return convertFlagKeyToIndicator(flags, helpers)
}
