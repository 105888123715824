import { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get, capitalize } from "lodash"
import { useTranslations } from "@4cplatform/elements/Translations"

import { JourneyContext } from "../../../../../../journey.context"

const Audit = ({ data }) => {
  const t = useTranslations()
  const { updateJourneyAuditData } = useContext(JourneyContext)

  const productName = t(get(data, "journey.selected_quote.product_name", "-"))
  const policyHolders = (get(data, "journey.selected_quote.applicants") || [])
    .map(applicant => `${applicant.first_name} ${applicant.last_name}`)
    .join(", ")
  const startDate = moment
    .utc(get(data, "journey.selected_quote.start_date"))
    .local()
    .format("DD/MM/YYYY")
  const providerName = get(data, "journey.selected_quote.provider.name")
  const underwritingType = t(get(data, "journey.selected_quote.underwriting_type"))
  const underwritingStype = t(get(data, "journey.selected_quote.underwriting_style"))
  const excess = get(data, "journey.selected_quote.excess")
  const frequency = t(get(data, "journey.selected_quote.payment_frequency"))

  useEffect(() => {
    if (excess) {
      updateJourneyAuditData([
        {
          mode: "replace",
          data: [
            {
              name: "Product Name",
              value: productName
            },
            {
              name: "Policy Holder",
              value: policyHolders
            },
            {
              name: "Start Date",
              value: startDate
            },
            {
              name: "Provider Name",
              value: providerName
            },
            {
              name: "Underwriting Type",
              value: [underwritingStype, underwritingType].join(" ")
            },
            {
              name: "Excess",
              value: excess
            },
            {
              name: "Frequency",
              value: capitalize(frequency)
            }
          ]
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productName, providerName])

  return null
}

Audit.propTypes = {
  data: PropTypes.any
}

export default Audit
