import React, { useContext } from "react"
import { P, H4 } from "@4cplatform/elements/Typography"
import { Button } from "@4cplatform/elements/Molecules"
import { MiReportContext } from "./miReports.context"
import FilterLabel from "./miReports.filterLabel"
import { FlexAlignCenterSmallGap, Divider, FlexColumn } from "./miReports.style"

const formikFilterKeys = [
  { key: "filterOrganisations", title: "Organisations" },
  { key: "filterUsers", title: "Users" },
  { key: "filterNetworks", title: "Networks" },
  { key: "filterProviders", title: "Providers" },
  { key: "filterProducts", title: "Products" }
]

const checkboxesFilterKeys = [
  {
    title: "Underwriting Type",
    data: [
      { key: "underwritingTypeFmu", title: "FMU" },
      { key: "underwritingTypeMoratorium", title: "Moratorium" }
    ]
  },
  {
    title: "Underwriting Style",
    data: [
      { key: "underwritingStyleNew", title: "New" },
      { key: "underwritingStyleSwitch", title: "Switch" }
    ]
  }
]

const locationFilterKeys = [
  { key: "filterRegion", title: "Region" },
  { key: "filterCounty", title: "County" },
  { key: "filterPostcode", title: "Postcode" }
]

const ActiveFilters = () => {
  const {
    formik: { values, setFieldValue, resetForm }
  } = useContext(MiReportContext)

  const isEmpty = key => !values[key] || values[key].filter(o => !!o.key).length === 0

  if (
    checkboxesFilterKeys.every(({ data }) => data.every(({ key }) => !values[key])) &&
    formikFilterKeys.every(({ key }) => isEmpty(key)) &&
    locationFilterKeys.every(({ key }) => !values[key])
  ) {
    return null
  }

  return (
    <FlexColumn>
      <Divider />
      <H4 margin="0 0 1.5rem 0">Active filters</H4>
      <FlexAlignCenterSmallGap>
        {checkboxesFilterKeys.map((checkboxesFilterKey, index) => {
          if (!checkboxesFilterKey.data.some(({ key }) => !!values[key])) return null

          return (
            <div key={`checkboxesFilterKeys-${index}`}>
              <P margin="0 0 0.75rem 0">{checkboxesFilterKey.title}</P>
              <FlexAlignCenterSmallGap>
                {checkboxesFilterKey.data
                  .filter(({ key }) => !!values[key])
                  .map(({ key, title }, idx) => (
                    <FilterLabel
                      name={title}
                      onDelete={() => setFieldValue(key, false)}
                      key={`checkboxesFilterKeys-${key}-${idx}`}
                    />
                  ))}
              </FlexAlignCenterSmallGap>
            </div>
          )
        })}

        {formikFilterKeys.map(({ key, title }, index) => {
          if (isEmpty(key)) return null

          return (
            <div key={`formikFilterKeys-${key}-${index}`}>
              <P margin="0 0 0.75rem 0">{title}</P>
              <FlexAlignCenterSmallGap>
                {values[key]
                  .filter(o => !!o.key)
                  .map((item, idx) => (
                    <FilterLabel
                      key={`formikFilterKeys-${key}-${item.key}-${idx}`}
                      name={`${
                        item.condition && item.condition !== "included" ? `${item.condition}: ` : ""
                      }${item.value}`}
                      onDelete={() =>
                        setFieldValue(
                          key,
                          values[key].length > 1
                            ? values[key].filter(o => o.key !== item.key)
                            : [{ key: "", value: "" }]
                        )
                      }
                    />
                  ))}
              </FlexAlignCenterSmallGap>
            </div>
          )
        })}

        {locationFilterKeys.map(({ key, title }, index) => {
          if (!values[key]) return null
          return (
            <div key={`locationFilterKeys-${key}-${index}`}>
              <P margin="0 0 0.75rem 0">{title}</P>
              <FilterLabel name={values[key]} onDelete={() => setFieldValue(key, "")} />
            </div>
          )
        })}
      </FlexAlignCenterSmallGap>
      <Divider data-html2canvas-ignore />
      <Button
        iconSize="1rem"
        margin="0 0 0 auto"
        type="inline-button"
        leadingIcon="trash-can"
        appearance="errorInline"
        data-html2canvas-ignore
        onClick={() => resetForm()}
      >
        Clear all filters
      </Button>
    </FlexColumn>
  )
}

export default ActiveFilters
