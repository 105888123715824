import React from "react"
import PropTypes from "prop-types"

// Components
import AgencyCodesProvider from "./context/agencyCodes.provider"
import { AgencyCodeAdmin, AgencyCodesPanel } from "../../../../UI/Templates"

const AgencyCodes = ({ pendingType }) => (
  <AgencyCodesProvider pendingType={pendingType}>
    <AgencyCodeAdmin />
    <AgencyCodesPanel pendingType="pending_addition" />
  </AgencyCodesProvider>
)

AgencyCodes.defaultProps = {
  pendingType: "false"
}

AgencyCodes.propTypes = {
  pendingType: PropTypes.string
}

export default AgencyCodes
