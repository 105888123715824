import moment from "moment"
import { string, object } from "yup"
import { get, capitalize } from "lodash"
import { isValidPhoneNumber } from "react-phone-number-input"
import { renderTitleOptions } from "@4cplatform/elements/Helpers"
import { Input, DatePicker, Select, QuerySelect } from "@4cplatform/elements/Forms"
import AccountAddress from "./AccountAddress"

import { validPostcodeRegex } from "../../../../../../../../Helpers"

export const config = data => ({
  title: `${capitalize(
    get(data, "page.data.third_party_payer_account_type", "")
  )} Third party payer details`,
  sections: [
    {
      key: "account_holder",
      components: [
        ...(get(data, "page.data.third_party_payer_account_type", "").toLowerCase() === "company"
          ? [
              {
                key: "company_name",
                initialValue: get(data, "page.data.company_name", ""),
                validationSchema: string().required("MISSING_REQUIRED_FIELD"),
                label: "Company name",
                component: Input,
                componentProps: {
                  isHorizontal: true,
                  isRequired: true,
                  margin: "0 0 2rem"
                }
              }
            ]
          : []),
        {
          key: "first_name",
          initialValue: get(data, "page.data.first_name", ""),
          validationSchema: string().required("MISSING_REQUIRED_FIELD"),
          label: "First name",
          component: Input,
          componentProps: {
            isHorizontal: true,
            isRequired: true,
            margin: "0 0 2rem"
          }
        },
        {
          key: "last_name",
          initialValue: get(data, "page.data.last_name", ""),
          validationSchema: string().required("MISSING_REQUIRED_FIELD"),
          label: "Last name",
          component: Input,
          componentProps: {
            isHorizontal: true,
            isRequired: true,
            margin: "0 0 2rem"
          }
        },
        {
          key: "middle_names",
          initialValue: get(data, "page.data.middle_names", ""),
          validationSchema: string().nullable(),
          label: "Middle name(s)",
          component: Input,
          componentProps: {
            isHorizontal: true,
            margin: "0 0 2rem"
          }
        },
        {
          key: "date_of_birth",
          initialValue: get(data, "page.data.date_of_birth", ""),
          validationSchema: string().required("MISSING_REQUIRED_FIELD"),
          label: "Date of birth",
          component: DatePicker,
          componentProps: {
            isDOBInput: true,
            value: get(data, "page.data.date_of_birth", ""),
            dateRangeMax: moment().subtract(18, "years").format("DD/MM/YYYY"),
            isHorizontal: true,
            isRequired: true,
            margin: "0 0 1rem"
          }
        },
        {
          key: "gender_at_birth",
          initialValue: get(data, "page.data.gender_at_birth", ""),
          validationSchema: string().required("MISSING_REQUIRED_FIELD"),
          label: "Gender at birth",
          component: Select,
          componentProps: {
            isHorizontal: true,
            isRequired: true,
            children: (
              <>
                <option value="">Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </>
            ),
            margin: "0 0 2rem"
          }
        },
        {
          key: "title",
          initialValue: get(data, "page.data.title", ""),
          validationSchema: string().required("MISSING_REQUIRED_FIELD"),
          label: "Title",
          component: QuerySelect,
          componentProps: {
            dependantField: "gender_at_birth",
            isHorizontal: true,
            isRequired: true,
            margin: "0 0 2rem",
            noun: { singular: "title", plural: "titles" },
            endpoint: "/dmz/titles",
            render: renderTitleOptions
          }
        },
        {
          key: "phone",
          initialValue: get(data, "page.data.phone", ""),
          validationSchema: string()
            .test("Phone invalid", "INVALID_UK_PHONE", val => {
              if (!val) return false
              return isValidPhoneNumber(val, "GB")
            })
            .required("MISSING_REQUIRED_FIELD"),
          label: "Phone number",
          component: Input,
          componentProps: {
            type: "tel",
            isHorizontal: true,
            isRequired: true,
            margin: "0 0 2rem"
          }
        },
        {
          key: "email_address",
          initialValue: get(data, "page.data.email_address", ""),
          validationSchema: string()
            .email("INVALID_EMAIL_WITH_PREFIX")
            .required("MISSING_REQUIRED_FIELD_WITH_PREFIX_EMAIL")
            .test("notCurrentUserEmailTest", "MUST_NOT_BE_THE_SAME_CURRENT_EMAIL", value => {
              if (value === get(data, "journey.client.email_address")) return false
              if (value === get(data, "journey.sales_agent.email")) return false
              return true
            }),
          label: "Email address",
          component: Input,
          componentProps: {
            errorPrefix: "none",
            type: "email",
            isHorizontal: true,
            isRequired: true,
            margin: "0 0 2rem"
          }
        }
      ]
    },
    {
      key: "account_address",
      title: "Account holder address",
      components: [
        {
          key: "address",
          initialValue: get(data, "page.data.address", {
            postcode: "",
            line_one: "",
            line_two: "",
            city: "",
            county: ""
          }),
          validationSchema: object({
            postcode: string()
              .test("correctUkPostcode", "CORRECT_UK_POSTCODE", no => validPostcodeRegex.test(no))
              .required("MISSING_REQUIRED_FIELD"),
            line_one: string().required("MISSING_REQUIRED_FIELD"),
            line_two: string().nullable(),
            city: string().required("MISSING_REQUIRED_FIELD"),
            county: string().required("MISSING_REQUIRED_FIELD")
          }),
          labels: {
            type: "Type",
            postcode: "Postcode",
            line_one: "Line 1",
            line_two: "Line 2",
            city: "City",
            county: "County"
          },
          component: AccountAddress,
          componentProps: {
            isHorizontal: true,
            labelWidth: "30rem",
            margin: "0 0 2rem"
          }
        }
      ]
    }
  ]
})
