import styled from "styled-components"

// Helpers
import { getBreakpoint } from "@4cplatform/elements/Helpers"

// Components
import { Container } from "@4cplatform/elements/Atoms"

export const Wrapper = styled(Container)`
  width: 64rem;
  max-width: 64rem;
  margin-left: 10%;

  ${getBreakpoint({ type: "height", max: "large" })} {
    margin-left: 2.5%;
  }
`
