import React, { useContext } from "react"
import { get } from "lodash"
import moment from "moment"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { ClientsContext } from "../../clients.context"
import { getName } from "../../../../Helpers"

// Components
import Actions from "./actions"
import ClientsPanelHeader from "../../clients.panel.header"
import ClientsPanelButtons from "../../clients.panel.body.buttons"
import RowActions from "./rowActions"
import PoliciesProvider from "../../../../../pages/Policies/context/policies.provider"

const ClientsPoliciesTab = () => {
  const {
    clientPolicies,
    getPoliciesLoading,
    policiesSorting,
    policiesPage: page,
    policiesPerPage: perPage,
    policiesTotal: total,
    updatePoliciesPageValue,
    policiesShowSimulated
  } = useContext(ClientsContext)
  const {
    panelStatusControls: { panelStatus }
  } = useContext(PageContext)

  const t = useTranslations()
  const pagination = { total, page, perPage }

  const policyTypeColumn = policiesShowSimulated
    ? [
        {
          label: "Type",
          dataKey: "type",
          minWidth: "5%",
          render: row => (get(row.data, "simulation_mode") === 1 ? "Simulated" : "Real"),
          sortable: true
        }
      ]
    : []

  return (
    <PoliciesProvider type="policy_tab">
      <PanelBody>
        <ClientsPanelHeader isEdit />
        <ClientsPanelButtons margin="3rem 0 1rem" />
        <Actions />
        <Table
          appearance="light"
          data={clientPolicies}
          isLoading={getPoliciesLoading}
          name="policies"
          columns={[
            [
              {
                label: "Reference",
                dataKey: "reference",
                minWidth: "20%",
                sortable: true,
                render: row => t(get(row.data, "reference"))
              },
              {
                label: "Agent",
                dataKey: "sales_agent",
                minWidth: "20%",
                sortable: true,
                render: row => {
                  const name = getName({
                    data: get(row.data, "sales_agent")
                  })
                  return name
                }
              }
            ],
            [
              {
                label: "Provider",
                dataKey: "provider",
                minWidth: "20%",
                sortable: true,
                render: row => get(row, "data.provider.name")
              },
              {
                label: "Product",
                dataKey: "product_name",
                minWidth: "20%",
                sortable: true,
                render: row => t(get(row.data, "product_name", "-"))
              }
            ],
            {
              label: "Monthly",
              dataKey: "monthly_premium",
              minWidth: "5%",
              sortable: true,
              render: row => `£${t(get(row.data, "monthly_premium"))}`
            },
            [
              {
                label: "Created",
                dataKey: "created_at",
                minWidth: "15%",
                sortable: true,
                render: row => {
                  const created = get(row.data, "created_at", "")
                  return created
                    ? moment.utc(created, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                    : "-"
                }
              },
              {
                label: "Onboarded",
                dataKey: "onboarded_at",
                minWidth: "15%",
                sortable: true,
                render: row => {
                  const created = get(row.data, "onboard_history[0].created_at", "")
                  return created
                    ? moment.utc(created, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                    : "-"
                }
              }
            ],
            ...policyTypeColumn,
            {
              label: "",
              dataKey: "id",
              minWidth: "5%",
              // eslint-disable-next-line react/display-name
              render: row => <RowActions data={get(row, "data")} />
            }
          ]}
          isClosed={panelStatus === "closed"}
          pagination={pagination}
          changePage={value => updatePoliciesPageValue("policiesPage", value)}
          changePerPage={value => updatePoliciesPageValue("policiesPerPage", value)}
          hasPerPage
          perPageOptions={{ max: 50, interval: 5 }}
          sorting={policiesSorting}
          onSort={value => updatePoliciesPageValue("policiesSorting", value)}
          shouldForceShowPagination
        />
      </PanelBody>
    </PoliciesProvider>
  )
}

export default ClientsPoliciesTab
