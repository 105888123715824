/* eslint-disable no-console */
import { string } from "yup"
import { get } from "lodash"
import { Toggle, Select, Input, QuerySelect } from "@4cplatform/elements/Forms"

// Helpers
import { getOptions } from "./currentPolicyOptions.helpers"

export const config = data => {
  const productsEndpoint = `/journeys/${get(data, "journey.slug")}/current-products`

  return {
    title: "Current policy options",
    cleanBodyOnSubmit: true,
    sections: [
      {
        key: "current_policy_options",
        components: [
          {
            key: "cp_payment_frequency",
            initialValue: get(data, "page.data.cp_payment_frequency", "Don't know"),
            validationSchema: string().nullable(),
            label: "Payment Frequency",
            component: Toggle,
            componentProps: {
              limit: 4,
              options: getOptions("cp_payment_frequency"),
              isHorizontal: true,
              labelWidth: "35rem"
            }
          },
          {
            key: "cp_payment_frequency_other",
            initialValue: get(data, "page.data.cp_payment_frequency_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_payment_frequency", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Payment Frequency - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_payment_frequency",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_underwriting",
            initialValue: get(data, "page.data.cp_underwriting", "Don't know"),
            validationSchema: string().nullable(),
            label: "Underwriting type",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_underwriting"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_excess",
            initialValue: get(data, "page.data.cp_excess", "Don't know"),
            validationSchema: string().nullable(),
            label: "Excess",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_excess"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_excess_other",
            initialValue: get(data, "page.data.cp_excess_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_excess", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Excess - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_excess",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_excess_type",
            initialValue: get(data, "page.data.cp_excess_type", "Don't know"),
            validationSchema: string().nullable(),
            label: "Excess type",
            component: Toggle,
            componentProps: {
              limit: 4,
              options: getOptions("cp_excess_type"),
              isHorizontal: true,
              labelWidth: "35rem",
              margin: "0 0 2rem"
            }
          },
          {
            key: "cp_excess_type_other",
            initialValue: get(data, "page.data.cp_excess_type_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_excess_type", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Excess type - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_excess_type",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_outpatient",
            initialValue: get(data, "page.data.cp_outpatient", "Don't know"),
            validationSchema: string().nullable(),
            label: "Outpatient Treatment",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_outpatient"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_outpatient_other",
            initialValue: get(data, "page.data.cp_outpatient_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_outpatient", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Outpatient Treatment - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_outpatient",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_outpatient_diagnostics",
            initialValue: get(data, "page.data.cp_outpatient_diagnostics", "Don't know"),
            validationSchema: string().nullable(),
            label: "Outpatient Diagnostics",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_outpatient_diagnostics"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_outpatient_diagnostics_other",
            initialValue: get(data, "page.data.cp_outpatient_diagnostics_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_outpatient_diagnostics", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Outpatient Diagnostics - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_outpatient_diagnostics",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_therapies",
            initialValue: get(data, "page.data.cp_therapies", "Don't know"),
            validationSchema: string().nullable(),
            label: "Therapies",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_therapies"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_therapies_other",
            initialValue: get(data, "page.data.cp_therapies_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_therapies", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Therapies - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_therapies",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_mental_health",
            initialValue: get(data, "page.data.cp_mental_health", "Don't know"),
            validationSchema: string().nullable(),
            label: "Mental health",
            component: Toggle,
            componentProps: {
              limit: 4,
              options: getOptions("cp_mental_health"),
              isHorizontal: true,
              labelWidth: "35rem"
            }
          },
          {
            key: "cp_mental_health_other",
            initialValue: get(data, "page.data.cp_mental_health_other", ""),
            validationSchema: string()
              .nullable()
              .nullable()
              .when("cp_mental_health", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Mental health - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_mental_health",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_protected_no_claims",
            initialValue: get(data, "page.data.cp_protected_no_claims", "Don't know"),
            validationSchema: string().nullable(),
            label: "Protected no claims",
            component: Toggle,
            componentProps: {
              limit: 4,
              options: getOptions("cp_protected_no_claims"),
              isHorizontal: true,
              labelWidth: "35rem"
            }
          },
          {
            key: "cp_protected_no_claims_other",
            initialValue: get(data, "page.data.cp_protected_no_claims_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_protected_no_claims", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Protected no claims - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_protected_no_claims",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_six_week",
            initialValue: get(data, "page.data.cp_six_week", "Don't know"),
            validationSchema: string().nullable(),
            label: "6 week option",
            component: Toggle,
            componentProps: {
              limit: 4,
              options: getOptions("cp_six_week"),
              isHorizontal: true,
              labelWidth: "35rem"
            }
          },
          {
            key: "cp_six_week_other",
            initialValue: get(data, "page.data.cp_six_week_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_six_week", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "6 week option - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_six_week",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_dental_and_optical",
            initialValue: get(data, "page.data.cp_dental_and_optical", "Don't know"),
            validationSchema: string().nullable(),
            label: "Dental and optical",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_dental_and_optical"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_dental_and_optical_other",
            initialValue: get(data, "page.data.cp_dental_and_optical_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_dental_and_optical", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Dental and optical - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_dental_and_optical",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_cancer_cover",
            initialValue: get(data, "page.data.cp_cancer_cover", "Don't know"),
            validationSchema: string().nullable(),
            label: "Cancer cover",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_cancer_cover"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_cancer_cover_other",
            initialValue: get(data, "page.data.cp_cancer_cover_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_cancer_cover", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Cancer cover - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_cancer_cover",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_travel_cover",
            initialValue: get(data, "page.data.cp_travel_cover", "Don't know"),
            validationSchema: string().nullable(),
            label: "Travel cover",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_travel_cover"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_travel_cover_other",
            initialValue: get(data, "page.data.cp_travel_cover_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_travel_cover", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Travel cover - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_travel_cover",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_private_gp",
            initialValue: get(data, "page.data.cp_private_gp", "Don't know"),
            validationSchema: string().nullable(),
            label: "Private GP",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_private_gp"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_private_gp_other",
            initialValue: get(data, "page.data.cp_private_gp_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_private_gp", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Private GP - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_private_gp",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_extended_cover",
            initialValue: get(data, "page.data.cp_extended_cover", "Don't know"),
            validationSchema: string().nullable(),
            label: "Extended cover",
            component: Toggle,
            componentProps: {
              limit: 4,
              options: getOptions("cp_extended_cover"),
              isHorizontal: true,
              labelWidth: "35rem"
            }
          },
          {
            key: "cp_extended_cover_other",
            initialValue: get(data, "page.data.cp_extended_cover_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_extended_cover", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Extended cover - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_extended_cover",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_hospital_list",
            initialValue: get(data, "page.data.cp_hospital_list", "Don't know"),
            validationSchema: string().nullable(),
            label: "Hospital List",
            component: Select,
            componentProps: {
              isHorizontal: true,
              options: getOptions("cp_hospital_list"),
              labelWidth: "50%",
              margin: "0 0 2rem",
              shouldDisplayEmptyOption: false
            }
          },
          {
            key: "cp_hospital_list_other",
            initialValue: get(data, "page.data.cp_hospital_list_other", ""),
            validationSchema: string()
              .nullable()
              .when("cp_hospital_list", {
                is: value => ["Other"].includes(value),
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
            label: "Hospital list - Specify other",
            component: Input,
            componentProps: {
              placeholder: "Specify other",
              isHorizontal: true,
              isRequired: true,
              labelWidth: "50%",
              margin: "0 0 2rem"
            },
            condition: {
              type: "formik",
              fieldKey: "cp_hospital_list",
              fieldValue: "Other"
            }
          },
          {
            key: "cp_vitality_list",
            initialValue: get(data, "page.data.cp_vitality_list", "Don't know"),
            validationSchema: string().required("MISSING_REQUIRED_FIELD"),
            label: "Current Product",
            component: QuerySelect,
            componentProps: {
              isHorizontal: true,
              isRequired: true,
              margin: "0 0 2rem",
              endpoint: productsEndpoint,
              apiArgs: {
                query: {
                  excess: encodeURI(get(data, "page.data.cp_excess", "Don't know")),
                  outpatient: encodeURIComponent(get(data, "page.data.cp_outpatient", "Don't know"))
                }
              },
              noun: {
                singular: "product",
                plural: "product"
              },
              render: products => {
                const productKeys = Object.keys(products)
                return productKeys.map(productKey => (
                  <option key={productKey} value={productKey}>
                    {products[productKey]}
                  </option>
                ))
              }
            }
          }
        ]
      }
    ]
  }
}
