import React, { useContext } from "react"
import { get, find } from "lodash"
import { Modal } from "@4cplatform/elements/Molecules"

// Helpers
import { QuickQuoteContext } from "../../UI/Templates/QuickQuote"

// Components
import LeadAdd from "../LeadAdd"

const QuickQuoteAddLead = () => {
  const { isAddLeadOpen, setAddLeadOpen, formik, arrivedQuotes } = useContext(QuickQuoteContext)

  const primaryApplicant = find(get(formik, "values.applicants", []), { type: "primary" })
  const postcode = get(primaryApplicant, "postcode", "")
  const sendAsEmail = get(formik, "values.send_as_email", false)

  return (
    isAddLeadOpen && (
      <Modal title="Add lead" width="65rem" onClose={() => setAddLeadOpen(false)}>
        <LeadAdd
          isQuickQuote
          isSendEmail={sendAsEmail}
          setAddLeadOpen={setAddLeadOpen}
          initialValues={{
            ...primaryApplicant,
            ...get(formik, "values", []),
            address: {
              postcode
            },
            type: "PMI",
            quotes: arrivedQuotes.filter(_ => _.success)
          }}
        />
      </Modal>
    )
  )
}

export default QuickQuoteAddLead
