import styled from "styled-components"
import { get } from "lodash"

export const NotificationTabContentWrapper = styled.div`
  border: 1px solid ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70")};
  border-radius: 4px;
`

export const ButtonsWrapper = styled.div`
  margin-top: 4rem;
`
