/* eslint-disable react/display-name */
import React, { forwardRef } from "react"
import PropTypes from "prop-types"

// Components
import QuotationSummaryTableHeader from "./quotationSummaryTable.header"
import { TableHeaderPlaceholder } from "./quotationSummaryTable.styles"

const QuotationSummaryTableHeaderPlaceholder = forwardRef(
  ({ isHeaderFixed, pageGutterWidth }, ref) => (
    <TableHeaderPlaceholder
      isVisible={isHeaderFixed}
      ref={ref}
      pageGutterWidth={pageGutterWidth}
      data-testid="summary_quotation_header_placeholder"
    >
      <QuotationSummaryTableHeader />
    </TableHeaderPlaceholder>
  )
)

QuotationSummaryTableHeaderPlaceholder.defaultProps = {
  isHeaderFixed: false,
  pageGutterWidth: 0
}

QuotationSummaryTableHeaderPlaceholder.propTypes = {
  /**
   * Used to determine whether the header is fixed or not
   */
  isHeaderFixed: PropTypes.bool,
  /**
   * Used to calculate the width of the pseudo classes for purposes of concealing fixed header overflow
   */
  pageGutterWidth: PropTypes.number
}

export default QuotationSummaryTableHeaderPlaceholder
