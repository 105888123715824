import React, { useContext } from "react"
import { PageContext } from "@4cplatform/elements/Organisms"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"

// Components
import EditQuotePanelHeader from "./quotationSummary.editPanel.header"
import EditQuotePanelBody from "./quotationSummary.editPanel.body"
import EditQuotePanelFooter from "./quotationSummary.editPanel.footer"

const EditQuotePanel = () => {
  const { setPanelStatus } = useContext(PageContext)

  return (
    <FlyOutPanel
      onClose={() => setPanelStatus("closed")}
      wideBody={() => (
        <>
          <EditQuotePanelHeader />
          <EditQuotePanelBody />
          <EditQuotePanelFooter />
        </>
      )}
      name="edit_quote_panel"
    />
  )
}

export default EditQuotePanel
