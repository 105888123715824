import React, { useContext } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get } from "lodash"
import { Icon } from "@4cplatform/elements/Atoms"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { AuthContext } from "@4cplatform/elements/Auth"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { AgencyCodesContext } from "../agencyCodes.context"
import { getIconDetails } from "../agencyCodes.helpers"
import { getName } from "../../../Helpers"

// Components
import Actions from "./agencyCodes.actions"
import { StatusWrapper } from "../components/DealCodeAssignment/assignment.styles"

const AgencyCodes = ({ hasActions }) => {
  const {
    data,
    queryLoading,
    onAgencyCodeSelect,
    onAgencyCodeDeselect,
    pagination,
    setPage,
    setPerPage,
    onSort,
    sorting,
    pendingType
  } = useContext(AgencyCodesContext)
  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = useContext(PageContext)
  const { canAccess } = useContext(AuthContext)

  const t = useTranslations()

  const statusColumn =
    pendingType === "false"
      ? [
          {
            label: "Status",
            dataKey: "status",
            minWidth: "50px",
            // eslint-disable-next-line react/display-name
            render: row => {
              const statusIcon = getIconDetails(get(row, "data.status", null))
              const isShared = get(row, "data.shared_with_count", 0) > 0
              return (
                <StatusWrapper appearance={row.isOpen ? "light" : "dark"}>
                  <Icon {...statusIcon} />
                  {isShared && <Icon icon="account-multiple" colour={colours.blue} />}
                </StatusWrapper>
              )
            },
            sortable: true
          }
        ]
      : []

  return (
    <>
      <Actions />
      <Table
        type="contained"
        data={data}
        isLoading={queryLoading}
        name="agency_codes"
        columns={[
          {
            label: "Product Type",
            dataKey: "product_type",
            minWidth: "70px",
            sortable: true
          },
          [
            ...(canAccess(["SYS_ADMIN", "SUPPORT_ADMIN", "SALES_ADVISER"])
              ? [
                  {
                    label: "Provider",
                    dataKey: "provider",
                    render: row => get(row, "data.provider.name"),
                    minWidth: "180px",
                    sortable: true
                  }
                ]
              : []),
            {
              label: "Product",
              dataKey: "product",
              render: row => t(get(row, "data.product", "-")),
              minWidth: "180px",
              sortable: true
            }
          ],
          {
            label: "Agency Code",
            dataKey: "agency_code",
            minWidth: "180px",
            sortable: true,
            render: row => get(row, "data.agency_code") || "-"
          },
          ...statusColumn,
          ...(canAccess(["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN"])
            ? [
                {
                  label: "Commission Rate",
                  dataKey: "primary_commission_rate",
                  minWidth: "50px",
                  render: row =>
                    get(row, "data.primary_commission_rate")
                      ? `${get(row, "data.primary_commission_rate")}%`
                      : "0%",
                  sortable: true
                }
              ]
            : []),
          ...(canAccess(["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN"])
            ? [
                {
                  label: "Owner",
                  dataKey: "owner_type",
                  minWidth: "150px",
                  sortable: true,
                  render: row => {
                    const owner = get(row, "data.owner", {})
                    if (owner && owner.type === "USER") {
                      return getName({ data: owner.attributes })
                    }
                    // If owner.type is ORGANISATION or NETWORK, display name field
                    return get(row, "data.owner.attributes.name", "-")
                  }
                }
              ]
            : []),
          {
            label: pendingType === "false" ? "Activation Date" : "Application Date",
            dataKey: pendingType === "false" ? "activated_at" : "created_at",
            minWidth: "80px",
            render: row => {
              const date = get(
                row,
                pendingType === "false" ? "data.activated_at" : "data.created_at"
              )
              return date
                ? moment.utc(date, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          }
        ]}
        hasActions={hasActions}
        onClick={row => {
          onAgencyCodeSelect(row)
          setPanelStatus("open")
        }}
        onClose={() => {
          if (panelStatus !== "closed") {
            setPanelStatus("closed")
          }
          onAgencyCodeDeselect()
        }}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        sorting={sorting}
      />
    </>
  )
}

AgencyCodes.defaultProps = {
  hasActions: true
}

AgencyCodes.propTypes = {
  hasActions: PropTypes.bool
}
export default AgencyCodes
