import { get, set, filter, isNull, isEmpty, isUndefined } from "lodash"

// Configs
import {
  /**
   * Fact Find stage
   */
  consentConfig,
  clientDetailsConfig,
  applicantsConfig,
  currentPolicyDetailsConfig,
  currentPolicyOptionsConfig,
  claimsHistoryConfig,
  medicalHistoryConfig,
  patientTerminologyConfig,
  hospitalPreferenceConfig,
  underwritingStyleConfig,
  startDateAndBudgetConfig,
  /**
   * Quote stage
   */
  quoteComparisonConfig,
  quotationSummaryConfig,
  hospitalListConfirmationConfig,
  quoteOverBudgetConfig,
  /**
   * Policy stage
   */
  // Generic
  underwritingSummaryConfig,
  requestPmcConfig,
  startApplicationFormConfig,
  completeApplicationFormConfig,
  submitToProviderConfig,
  paymentSetUpConfig,
  thirdPartyPayerAccountTypeConfig,
  thirdPartyPayerApprovalConfig,
  thirdPartyPayerDetailsConfig,
  directDebitGuaranteeConfig,
  policyExclusionsConfig,
  onboardPolicyConfig,
  demandPmcConfig,
  // Aviva
  avivaPolicyWithinPastYearConfig,
  avivaRenewalCommissionConfig,
  avivaPolicyDetailsConfig,
  avivaHealthDeclarationConfig,
  avivaSwitchDisclosuresConfig,
  avivaSwitchDeclarationConfirmationConfig,
  // AXA
  axaFmuDisclosures,
  axaFmuSwitchDeclarationConfirmation,
  axaMoratoriumDeclarationConfirmation,
  axaSwitchDisclosures,
  // BUPA
  bupaGmcDmcNumber,
  bupaFmuDisclosures,
  bupaHealthDeclarationConfig,
  bupaSwitchDisclosuresConfig,
  bupaSwitchDeclarationConfirmation,
  bupaSoleThirdPartyPayer,
  bupaDemandCompanyThirdPartyPayerForm,
  bupaCompanyThirdPartyPayer,
  // Exeter
  exeterQuestions,
  // Vitality
  vitalityPortal
} from "../../Templates/Journey/components/JourneyPage/pages/PMI"

/**
 * This function maps the page key to its corresponding page config.
 *
 * @param {*} data
 *
 * @returns
 */
export const getPageData = (data, t = _ => _) => {
  const key = get(data, "page.key", null)

  let config

  switch (key) {
    /**
     * Fact Find stage
     */
    case "CONSENT_TO_PERSONAL_INFO":
      config = consentConfig(data)
      break
    case "CLIENT_DETAILS":
      config = clientDetailsConfig(data)
      break
    case "APPLICANTS":
      config = applicantsConfig(data)
      break
    case "CURRENT_POLICY_DETAILS":
      config = currentPolicyDetailsConfig(data)
      break
    case "CURRENT_POLICY_OPTIONS":
      config = currentPolicyOptionsConfig(data)
      break
    case "CLAIMS_HISTORY":
      config = claimsHistoryConfig(data)
      break
    case "MEDICAL_HISTORY":
      config = medicalHistoryConfig(data)
      break
    case "INPATIENT_OUTPATIENT":
      config = patientTerminologyConfig(data)
      break
    case "HOSPITAL_PREFERENCE":
      config = hospitalPreferenceConfig(data)
      break
    case "UNDERWRITING_STYLE":
      config = underwritingStyleConfig(data)
      break
    case "START_DATE_AND_BUDGET":
      config = startDateAndBudgetConfig(data)
      break
    /**
     * Quote stage
     */
    case "QUOTE_COMPARISON":
      config = quoteComparisonConfig(data)
      break
    case "QUOTATION_SUMMARY":
      config = quotationSummaryConfig(data)
      break
    case "HOSPITAL_LIST_CONFIRMATION":
      config = hospitalListConfirmationConfig(data)
      break
    case "QUOTE_OVER_BUDGET":
      config = quoteOverBudgetConfig(data)
      break
    /**
     * Policy stage
     */
    // Generic
    case "UNDERWRITING_SUMMARY":
      config = underwritingSummaryConfig(data, t)
      break
    case "INSURER_REQUEST_CERTIFICATE_OF_INSURANCE":
      config = requestPmcConfig(data)
      break
    case "START_APPLICATION_FORM":
      config = startApplicationFormConfig(data)
      break
    case "COMPLETE_APPLICATION_FORM":
      config = completeApplicationFormConfig(data)
      break
    case "SUBMIT_TO_PROVIDER":
      config = submitToProviderConfig(data)
      break
    case "PAYMENT_SETUP":
      config = paymentSetUpConfig(data, t)
      break
    case "THIRD_PARTY_PAYER_ACCOUNT_TYPE":
      config = thirdPartyPayerAccountTypeConfig(data)
      break
    case "THIRD_PARTY_PAYER_APPROVAL":
      config = thirdPartyPayerApprovalConfig(data)
      break
    case "THIRD_PARTY_PAYER_DETAILS":
      config = thirdPartyPayerDetailsConfig(data)
      break
    case "DIRECT_DEBIT_GUARANTEE":
      config = directDebitGuaranteeConfig(data)
      break
    case "POLICY_EXCLUSIONS":
      config = policyExclusionsConfig(data)
      break
    case "ONBOARD_POLICY":
      config = onboardPolicyConfig(data)
      break
    case "INSURER_DEMAND_CERTIFICATE_OF_INSURANCE":
      config = demandPmcConfig(data)
      break
    // Aviva
    case "AVIVA_PMI_POLICY_WITHIN_PAST_YEAR":
      config = avivaPolicyWithinPastYearConfig(data)
      break
    case "AVIVA_RENEWAL_COMMISSION":
      config = avivaRenewalCommissionConfig(data)
      break
    case "AVIVA_POLICY_DETAILS":
      config = avivaPolicyDetailsConfig(data)
      break
    case "AVIVA_HEALTH_DECLARATION":
      config = avivaHealthDeclarationConfig(data)
      break
    case "AVIVA_SWITCH_DISCLOSURES":
      config = avivaSwitchDisclosuresConfig(data)
      break
    case "AVIVA_SWITCH_DECLARATION":
      config = avivaSwitchDeclarationConfirmationConfig(data)
      break
    // AXA
    case "AXA_FMU_DISCLOSURES":
      config = axaFmuDisclosures(data)
      break
    case "AXA_FMU_SWITCH_DECLARATION_CONFIRMATION":
      config = axaFmuSwitchDeclarationConfirmation(data)
      break
    case "AXA_MORI_DECLARATION_CONFIRMATION":
      config = axaMoratoriumDeclarationConfirmation(data)
      break
    case "AXA_SWITCH_DISCLOSURES":
      config = axaSwitchDisclosures(data)
      break
    // BUPA
    case "BUPA_GMC_DMC_NUMBER":
      config = bupaGmcDmcNumber(data)
      break
    case "BUPA_FMU_DISCLOSURES":
      config = bupaFmuDisclosures(data)
      break
    case "BUPA_HEALTH_DECLARATION":
      config = bupaHealthDeclarationConfig(data)
      break
    case "BUPA_SWITCH_DISCLOSURES":
      config = bupaSwitchDisclosuresConfig(data)
      break
    case "BUPA_SWITCH_DECLARATION_CONFIRMATION":
      config = bupaSwitchDeclarationConfirmation(data)
      break
    case "BUPA_SOLE_THIRD_PARTY_PAYER":
      config = bupaSoleThirdPartyPayer(data)
      break
    case "BUPA_DEMAND_COMPANY_THIRD_PARTY_PAYER_FORM":
      config = bupaDemandCompanyThirdPartyPayerForm(data)
      break
    case "BUPA_COMPANY_THIRD_PARTY_PAYER":
      config = bupaCompanyThirdPartyPayer(data)
      break
    // Exeter
    case "EXETER_QUESTIONS":
      config = exeterQuestions(data, t)
      break
    // Vitality
    case "VITALITY_PORTAL":
      config = vitalityPortal(data)
      break
    default:
      config = {}
  }

  const sections = filter(
    get(config, "sections", []),
    section => !isEmpty(section) && !isNull(section) && !isUndefined(section)
  )

  set(config, "sections", sections)

  return config
}
