import React, { useContext, Fragment } from "react"
import moment from "moment"
import { get, isEmpty, filter } from "lodash"
import { v4 as uuid } from "uuid"
import { IconWithText, LabelWithText } from "@4cplatform/elements/Atoms"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { PoliciesContext } from "./policies.context"
import { getName } from "../../Helpers"

// Components
import { Divider } from "./policies.styles"

const Exclusions = () => {
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { viewData, viewLoading } = useContext(PoliciesContext)

  const exclusions = get(viewData, "exclusions", [])
  const applicants = get(viewData, "applicants", [])

  return (
    <LabelWithText appearance="light" label="Exclusions" isLoading={viewLoading}>
      {isEmpty(exclusions) && (
        <IconWithText
          appearance="light"
          icon="minus"
          content="No history"
          isLoading={viewLoading}
          margin="0"
        />
      )}
      {!isEmpty(exclusions) &&
        applicants.map((applicant, i) => {
          const applicantExclusions = filter(exclusions, {
            policy_applicant_id: get(applicant, "id")
          })
          const name = getName({
            data: applicant,
            hasTitle: true,
            hasMiddle: true,
            globalTitles: GLOBAL_TITLES
          })
          const age = moment().diff(get(applicant, "date_of_birth"), "years")

          return (
            <Fragment key={uuid()}>
              <LabelWithText
                key={uuid()}
                appearance="light"
                label={`${name}, ${age}`}
                name={`exclusions_${i}`}
              >
                {isEmpty(applicantExclusions) && (
                  <IconWithText
                    appearance="light"
                    icon="minus"
                    content="No history"
                    isLoading={viewLoading}
                    margin="0"
                  />
                )}
                {!isEmpty(applicantExclusions) &&
                  applicantExclusions.map(exclusion => (
                    <Fragment key={uuid()}>
                      <IconWithText
                        appearance="light"
                        icon="comment-text"
                        content={get(exclusion, "exclusion", "-")}
                        isLoading={viewLoading}
                        margin="0"
                      />
                    </Fragment>
                  ))}
              </LabelWithText>
              {i !== applicants.length - 1 && <Divider />}
            </Fragment>
          )
        })}
    </LabelWithText>
  )
}

export default Exclusions
