/**
 * Authentication Gate
 */
export const MAINTENANCE_LOGIN = {
  path: "/login/maintenance"
}

export const LOGIN = {
  path: "/login"
}

export const FORGOTTEN_PASSWORD = {
  path: "/forgotten-password"
}

export const RESET_PASSWORD = {
  path: "/reset-password"
}

export const TWO_FACTOR_AUTH = {
  path: "/two-factor-authentication"
}

export const ACTIVATE_USER = {
  path: "/activate-user"
}

export const REGISTER = {
  path: "/register"
}

export const PASSWORD_CHANGE_CONFIRMATION = {
  path: "/password-change-confirmation"
}

export const EMAIL_CHANGE_CONFIRMATION = {
  path: "/confirm-email-change"
}

/**
 * Legal
 */
export const PRIVACY_POLICY = {
  path: "/privacy-policy"
}

export const TERMS_CONDITIONS = {
  path: "/terms-conditions"
}

/**
 * Self-service
 */
export const MY_ACCOUNT = {
  path: "/my-account"
}

/**
 * Notification Administration
 */
export const NOTIFICATION_ADMIN = {
  path: "/notifications"
}

/**
 * System Administration
 */
export const SYSTEM_SETTINGS = {
  path: "/system-settings",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN"]
}

export const API_STATUS = {
  path: "/api-status",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN"]
}

/**
 * User Administration
 */
export const USER_ADMIN = {
  path: "/users",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN", "NETWORK_ADMIN", "PROVIDER_ADMIN"]
}

export const USER_CREATE = {
  path: `${USER_ADMIN.path}/add`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN", "NETWORK_ADMIN", "PROVIDER_ADMIN"]
}

/**
 * Provider Administration
 */
export const PROVIDER_ADMIN = {
  path: "/providers",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN"]
}

export const PROVIDER_CREATE = {
  path: `${PROVIDER_ADMIN.path}/add`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN"]
}

export const PROVIDER_MANAGE = {
  path: `${PROVIDER_ADMIN.path}/:slug`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN"]
}

export const PROVIDER_USER_CREATE = {
  path: `${PROVIDER_ADMIN.path}/:slug${USER_CREATE.path}`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN"]
}

export const PROVIDER_CONTACT_DETAILS = {
  path: "/provider-contact-details",
  roles: ["SALES_ADVISER", "ORG_ADMIN"]
}

/**
 * Network Administration
 */
export const NETWORK_ADMIN = {
  path: "/networks",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN"]
}

export const NETWORK_CREATE = {
  path: `${NETWORK_ADMIN.path}/add`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN"]
}

export const NETWORK_MANAGE = {
  path: `${NETWORK_ADMIN.path}/:slug`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "NETWORK_ADMIN", "NETWORK_MEMBER_ADMIN"]
}

export const NETWORK_USER_CREATE = {
  path: `${NETWORK_ADMIN.path}/:slug${USER_CREATE.path}`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "NETWORK_ADMIN"]
}

/**
 * Organisation Administration
 */
export const ORGANISATION_ADMIN = {
  path: "/organisations",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN"]
}

export const ORGANISATION_MANAGE = {
  path: `${ORGANISATION_ADMIN.path}/:slug`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN", "SALES_ADVISER"]
}

export const ORGANISATION_USER_CREATE = {
  path: `${ORGANISATION_ADMIN.path}/:slug${USER_CREATE.path}`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN"]
}

/**
 * Quick Quote
 */
export const QUICK_QUOTE = {
  path: "/quick-quote",
  roles: ["ORG_ADMIN", "SALES_ADVISER"]
}

/**
 * Lead Administration
 */
export const LEAD_ADMIN = {
  path: "/leads",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN", "SALES_ADVISER"]
}

export const LEAD_CREATE = {
  path: `${LEAD_ADMIN.path}/add`,
  roles: ["ORG_ADMIN", "SALES_ADVISER"]
}

/**
 * Client Administration
 */
export const CLIENT_ADMIN = {
  path: "/clients",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN", "SALES_ADVISER"]
}

/**
 * Agency Codes and Commission Rates
 */
export const AGENCY_CODE_ADMIN = {
  path: "/agency-codes",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN"]
}

/**
 * Deal Codes
 */
export const DEAL_CODE_ADMIN = {
  path: "/deal-codes",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN"]
}

export const DEAL_CODE_CREATE = {
  path: `${DEAL_CODE_ADMIN.path}/add`,
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN"]
}

/**
 * Client Journey
 */
export const JOURNEY_ADMIN = {
  path: "/journeys",
  roles: [
    "SYS_ADMIN",
    "SUPPORT_ADMIN",
    "ORG_ADMIN",
    "SALES_ADVISER",
    "NETWORK_ADMIN",
    "NETWORK_MEMBER_ADMIN"
  ]
}

export const JOURNEY = {
  path: "/journeys/:reference/:stage",
  roles: ["SYS_ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN", "SALES_ADVISER"]
}

/**
 * Policies
 */
export const POLICY_ADMIN = {
  path: "/policies",
  roles: [
    "SYS_ADMIN",
    "SUPPORT_ADMIN",
    "ORG_ADMIN",
    "SALES_ADVISER",
    "NETWORK_ADMIN",
    "NETWORK_MEMBER_ADMIN",
    "PROVIDER_ADMIN",
    "UNDERWRITER"
  ]
}

export const POLICY_ADMIN_AWAITING_TERMS = {
  path: `${POLICY_ADMIN.path}?status=AWAITING_TERMS`,
  roles: [
    "SYS_ADMIN",
    "SUPPORT_ADMIN",
    "ORG_ADMIN",
    "SALES_ADVISER",
    "NETWORK_ADMIN",
    "NETWORK_MEMBER_ADMIN",
    "PROVIDER_ADMIN",
    "UNDERWRITER"
  ]
}

export const POLICY_ADMIN_AWAITING_ACCEPTANCE = {
  path: `${POLICY_ADMIN.path}?status=AWAITING_ACCEPTANCE`,
  roles: [
    "SYS_ADMIN",
    "SUPPORT_ADMIN",
    "ORG_ADMIN",
    "SALES_ADVISER",
    "NETWORK_ADMIN",
    "NETWORK_MEMBER_ADMIN",
    "PROVIDER_ADMIN",
    "UNDERWRITER"
  ]
}

export const POLICY_ADMIN_FAILED_ONBOARDING = {
  path: `${POLICY_ADMIN.path}?status=FAILED_ONBOARDING`,
  roles: [
    "SYS_ADMIN",
    "SUPPORT_ADMIN",
    "ORG_ADMIN",
    "SALES_ADVISER",
    "NETWORK_ADMIN",
    "NETWORK_MEMBER_ADMIN",
    "PROVIDER_ADMIN",
    "UNDERWRITER"
  ]
}

/**
 * Quick Quote log
 */
export const QUICK_QUOTE_LOG = {
  path: "/quick-quote-log",
  roles: ["SYS_ADMIN"]
}

/**
 * Dashboard
 */
export const DASHBOARD = {
  path: "/"
}

/**
 * Something Went Wrong
 */
export const FORBIDDEN = {
  path: "/403"
}

export const NOT_FOUND = {
  path: "*"
}

/**
 * Reports
 */
export const REPORTS = {
  path: "/reports",
  roles: [
    "SYS_ADMIN",
    "SUPPORT_ADMIN",
    "ORG_ADMIN",
    "SALES_ADVISER",
    "UNDERWRITER",
    "NETWORK_ADMIN",
    "NETWORK_MEMBER_ADMIN",
    "PROVIDER_ADMIN"
  ]
}

/**
 * Commission rates
 */
export const COMMISSION_RATES = {
  path: "/commission-rates"
}
