import React, { useContext } from "react"
import { P } from "@4cplatform/elements/Typography"

// Helpers
import { QuoteComparisonContext } from "./quoteComparison.context"

// Components
import {
  ProgressIndicatorContainer,
  ProgressBarContainer,
  ProgressBar,
  SelectedCount
} from "./quoteComparison.styles"

const ProgressIndicator = () => {
  const { getProgress, arrivedQuotes, selectedQuotes, isDisabled } =
    useContext(QuoteComparisonContext)

  const realProgress = getProgress()
  const progress = realProgress >= 100 ? 100 : realProgress

  const successfulQuotes = arrivedQuotes.filter(quote => quote.success).length

  return (
    <ProgressIndicatorContainer width="100%" margin="0" padding="0">
      {isDisabled && (
        <>
          <ProgressBarContainer>
            <ProgressBar progress={progress} />
          </ProgressBarContainer>
          <P margin="0">{progress}%</P>
        </>
      )}
      {!isDisabled && successfulQuotes > 0 && (
        <SelectedCount>
          {selectedQuotes.length} of {successfulQuotes} available quotes selected
        </SelectedCount>
      )}
    </ProgressIndicatorContainer>
  )
}

export default ProgressIndicator
