/* eslint-disable camelcase */
import React from "react"
import { get } from "lodash"
import moment from "moment"
import { Icon } from "@4cplatform/elements/Atoms"
import { P } from "@4cplatform/elements/Typography"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { getAdditionalColumns } from "./organisationAgencyCodes.helpers"
import { AgencyCodesContext } from "../agencyCodes.context"
import { getIconDetails } from "../agencyCodes.helpers"
import { getName } from "../../../Helpers"

// Components
import Actions from "./organisationAgencyCodes.actions"
import CheckedItemModal from "../components/CheckedItemModal"
import { StatusWrapper } from "../components/DealCodeAssignment/assignment.styles"

const OrganisationAgencyCodes = () => {
  const {
    data,
    queryLoading,
    onAgencyCodeSelect,
    onAgencyCodeDeselect,
    pagination,
    setPage,
    setPerPage,
    onSort,
    sorting,
    type,
    setRevoke,
    allSharedUsers,
    revokeModal,
    revokeLoading,
    onRevokeShares
  } = React.useContext(AgencyCodesContext)
  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)

  const t = useTranslations()

  return (
    <>
      <Actions />
      <Table
        type="contained"
        data={data}
        isLoading={queryLoading}
        name="organisation_agency_codes"
        columns={[
          {
            label: "Product Type",
            dataKey: "product_type",
            minWidth: "70px",
            sortable: true
          },
          {
            label: "Provider",
            dataKey: "provider",
            minWidth: "180px",
            sortable: true,
            render: row => get(row, "data.provider.name")
          },
          {
            label: "Product",
            dataKey: "product",
            render: row => t(get(row, "data.product", "-")),
            minWidth: "180px",
            sortable: true
          },
          {
            label: "Agency code",
            dataKey: "agency_code",
            minWidth: "180px",
            sortable: true
          },
          {
            label: "Status",
            dataKey: "status",
            minWidth: "50px",
            // eslint-disable-next-line react/display-name
            render: row => {
              const statusIcon = getIconDetails(get(row, "data.status", null))
              const isShared = get(row, "data.shared_with_count", 0) > 0
              return (
                <StatusWrapper appearance={row.isOpen ? "light" : "dark"}>
                  <Icon {...statusIcon} />
                  {isShared && <Icon icon="account-multiple" colour={colours.blue} />}
                </StatusWrapper>
              )
            },
            sortable: true
          },
          ...getAdditionalColumns(type),
          {
            label:
              type === "shared_from_network" || type === "shared_with_users"
                ? "Shared Date"
                : "Activation Date",
            dataKey:
              type === "shared_from_network" || type === "shared_with_users"
                ? "shared_at"
                : "activated_at",
            minWidth: "80px",
            render: row => {
              const date = get(
                row,
                type === "shared_from_network" || type === "shared_with_users"
                  ? "data.shared_at"
                  : "data.activated_at"
              )
              return date
                ? moment.utc(date, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          }
        ]}
        hasActions
        onClick={row => {
          onAgencyCodeSelect(row)
          setPanelStatus("open")
        }}
        onClose={() => {
          if (panelStatus !== "closed") {
            setPanelStatus("closed")
          }
          onAgencyCodeDeselect()
        }}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        sorting={sorting}
      />
      {revokeModal && (
        <CheckedItemModal
          title="Revoke shares"
          isLoading={revokeLoading}
          onClose={() => setRevoke(false)}
          label={{
            name: "User",
            date: "Shared at",
            submit: "Revoke selected"
          }}
          data={allSharedUsers?.map(({ id, shared_at, ...org }) => ({
            id,
            name: getName({ data: org }),
            date: shared_at
          }))}
          onSubmit={checkedItem => {
            onRevokeShares(Object.keys(checkedItem).filter(key => !!checkedItem[key]))
          }}
          confirmationProps={{
            confirmIcon: "delete",
            renderContent: checkedItem => (
              <P>
                {`Are you sure you want to revoke ${
                  Object.values(checkedItem).filter(val => val === true).length > 1 &&
                  Object.values(checkedItem).every(isChecked => isChecked)
                    ? "all"
                    : "selected"
                } shares for this agency code? This action cannot be undone.`}
              </P>
            )
          }}
        />
      )}
    </>
  )
}

export default OrganisationAgencyCodes
