import React from "react"
import { Helmet } from "react-helmet-async"
import { Container } from "@4cplatform/elements/Atoms"
import { H1 } from "@4cplatform/elements/Typography"

// Helpers
import { DASHBOARD } from "../../config/pages"

// Components
import { Breadcrumbs } from "../../UI/Molecules"
import NotificationsProvider from "./context/notifications.provider"
import { NotificationAdmin } from "../../UI/Templates"

const Notifications = () => (
  <>
    <Helmet>
      <title>Notifications</title>
    </Helmet>
    <NotificationsProvider>
      <Container isPageContainer width="80%">
        <Breadcrumbs
          trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: "Notifications" }]}
        />
        <H1 margin="0 0 2rem">Notifications</H1>
        <NotificationAdmin />
      </Container>
    </NotificationsProvider>
  </>
)

export default Notifications
