import React from "react"
import { MyAccountDetails } from "@4cplatform/elements/Templates"

// Components
import MyAccountDetailsProvider from "./context/details.provider"

const Details = () => (
  <MyAccountDetailsProvider>
    <MyAccountDetails />
  </MyAccountDetailsProvider>
)

export default Details
