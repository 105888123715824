import React, { useContext } from "react"
import { v4 as uuid } from "uuid"
import { get, capitalize, isEmpty } from "lodash"
import { LabelWithText } from "@4cplatform/elements/Atoms"
import { H2, H3, P, SmallText } from "@4cplatform/elements/Typography"
import { Button, Modal } from "@4cplatform/elements/Molecules"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { QuotationSummaryContext } from "./context/quotationSummary.context"
import { renderA } from "../../../../../../../../Helpers"

// Components
import {
  ProviderInfoModalHeader,
  ProviderInfoModalHeaderContent,
  ProviderInfoModalBody,
  ProviderInfoModalFooter,
  SectionWrapper,
  CustomFieldWrapper
} from "./quotationSummary.styles"
import ProviderLogo from "../../../../../QuotationSummaryTable/quotationSummaryTable.providerLogo"

const ProviderInfoModal = () => {
  const {
    setProviderInfoModal,
    providerInfoModal: { open, data }
  } = useContext(QuotationSummaryContext)
  const t = useTranslations()

  return open ? (
    <Modal
      hasHeader={false}
      onClose={() => setProviderInfoModal({ open: false, data: {} })}
      name="quote_provider_info_modal"
      hasPadding={false}
    >
      <ProviderInfoModalHeader>
        <ProviderLogo
          src={get(data, "provider.logo_file_path", "")}
          alt={get(data, "provider.name", "-")}
        />
        <ProviderInfoModalHeaderContent>
          <H3 margin="0">{get(data, "provider.name", "-")}</H3>
          <P margin="0">{t(get(data, "product_name", "-"))}</P>
        </ProviderInfoModalHeaderContent>
      </ProviderInfoModalHeader>
      <ProviderInfoModalBody>
        <H2 margin="0 0 1rem">Contact details</H2>
        <SectionWrapper data-testid="provider-details-section">
          <LabelWithText label="Primary contact email" name="primary_contact_email">
            {renderA("email", get(data, "provider.primary_contact_email", null))}
          </LabelWithText>
          <LabelWithText label="Company website" name="website">
            {renderA("url", get(data, "provider.website", null))}
          </LabelWithText>
        </SectionWrapper>
        {/* Departmental emails */}
        <H3 margin="1rem 0 2rem">Departmental emails</H3>
        <SectionWrapper data-testid="provider-details-section">
          {["risk_email", "underwriting_email", "onboarding_email", "agency_codes_email"].map(
            key => {
              const email = get(data, `provider.${key}`, null)
              return (
                email && (
                  <LabelWithText key={uuid()} name={key} label={capitalize(key.replace(/_/g, " "))}>
                    {renderA("email", email)}
                  </LabelWithText>
                )
              )
            }
          )}
        </SectionWrapper>
        {/* Additional contact details */}
        {!isEmpty(get(data, "provider.additional_contact_details", [])) && (
          <>
            <H3 margin="1rem 0">Additional contact details</H3>
            <SectionWrapper data-testid="provider-details-section" isLast>
              {get(data, "provider.additional_contact_details", []).map(item => {
                const type = get(item, "type", null)
                const contact = get(item, "contact", null)

                if (!type || !contact) return null

                let label = "Phone number"
                if (type === "email") label = "Email address"
                if (type === "url" || type === "website") label = "Website URL"

                return (
                  <CustomFieldWrapper key={uuid()}>
                    <LabelWithText margin="0" label={label} loadingLines={3}>
                      {renderA(type, contact)}
                      <SmallText>{get(item, "description", "-")}</SmallText>
                    </LabelWithText>
                  </CustomFieldWrapper>
                )
              })}
            </SectionWrapper>
          </>
        )}
      </ProviderInfoModalBody>
      <ProviderInfoModalFooter>
        <Button
          appearance="error"
          trailingIcon="close"
          onClick={() => setProviderInfoModal({ open: false, data: {} })}
          name="provider_info_close"
        >
          Close
        </Button>
      </ProviderInfoModalFooter>
    </Modal>
  ) : null
}

export default ProviderInfoModal
