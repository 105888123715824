import { useContext, useState } from "react"
import queryString from "query-string"
import { get } from "lodash"
import { usePost } from "@4cplatform/elements/Api"
import { Button, ComplianceNote } from "@4cplatform/elements/Molecules"
import { useTranslations } from "@4cplatform/elements/Translations"
import { H4, P } from "@4cplatform/elements/Typography"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { CLIENT_ADMIN, POLICY_ADMIN } from "../../../../../../../../../../config/pages"
import { JourneyContext } from "../../../../../../../journey.context"

const VitalityPortalActions = () => {
  const { data, refetchData, submitJourneyAudit } = useContext(JourneyContext)
  const t = useTranslations()

  const isLocked = get(data, "journey.locked", false)
  const inProgress = get(data, "journey.policy.status", "") === "IN_PROGRESS"
  const [onboarded, setOnboarded] = useState(!inProgress)
  const [opened, setOpened] = useState(!inProgress)

  const [submitApplication, { loading: onboardLoading }] = usePost({
    endpoint: "/journeys/:journey/vitality-portal",
    params: {
      journey: get(data, "journey.slug", "")
    },
    onCompleted: () => {
      setOnboarded(true)
      refetchData()
      submitJourneyAudit({
        page: "Onboard policy",
        responses: [
          {
            name: "Onboard policy",
            value: "Success"
          }
        ]
      })
    },
    onError: error => {
      refetchData()
      addAlert({
        message: t("POLICY_ONBOARD_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
      submitJourneyAudit({
        page: "Onboard policy",
        responses: [
          {
            name: "Onboard policy",
            value: "Failed"
          },
          {
            name: "Onboard failed reason",
            value: get(error, "__exception.message") || t("POLICY_ONBOARD_ERROR")
          }
        ]
      })
    }
  })

  const renderSubmitApplication = () => {
    setOpened(true)
  }
  const openPortal = () => {
    window.open("https://adviserhub.vitality.co.uk/health-insurance/account")
    submitApplication()
  }

  return (
    <>
      {!opened && !onboarded && (
        <>
          <ComplianceNote type="info">
            <H4 margin="0 0 1rem">Purchase the policy</H4>
            <P margin="0">Press the button below to onboard the policy with the provider</P>
          </ComplianceNote>
          <Button
            name="submit"
            width="fit-content"
            onClick={() => renderSubmitApplication()}
            trailingIcon="chevron-right"
            isDisabled={isLocked}
          >
            Onboard
          </Button>
        </>
      )}
      {opened && !onboarded && (
        <>
          <ComplianceNote type="info">
            <P margin="0">
              You have chosen to submit an application to VitalityHealth for your client. You will
              now be redirected to the VitalityHealth portal to complete the application process.
              After clicking "Submit Application", you will no longer be able to amend this journey
              and the quotation will become a 'policy awaiting acceptance'. Once we receive
              confirmation from VitalityHealth that the policy has been enrolled it will then appear
              as a live policy. Please click on "Submit Application" to progress.
            </P>
          </ComplianceNote>
          <Button
            name="submit"
            width="fit-content"
            onClick={() => openPortal()}
            isLoading={onboardLoading}
            trailingIcon="chevron-right"
            isDisabled={isLocked}
          >
            Submit application
          </Button>
        </>
      )}
      {onboarded && (
        <>
          <ComplianceNote type="info">
            <H4 margin="0">Policy successfully onboarded</H4>
          </ComplianceNote>
          <Button
            name="view_policy"
            width="fit-content"
            type="Link"
            to={{
              pathname: POLICY_ADMIN.path,
              search: `?${queryString.stringify({
                simulated: get(data, "journey.policy.simulation_mode", true) === 1,
                tab: "ALL_POLICIES",
                status: "SOLD",
                selected: get(data, "journey.policy.slug")
              })}`
            }}
            trailingIcon="eye"
            margin="0 0 2rem"
          >
            View policy
          </Button>
          <Button
            name="back_to_clients"
            width="fit-content"
            type="Link"
            to={CLIENT_ADMIN.path}
            trailingIcon="undo-variant"
          >
            Back to clients
          </Button>
        </>
      )}
    </>
  )
}

export default VitalityPortalActions
