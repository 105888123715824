import { useEffect, useContext } from "react"

import { JourneyContext } from "../../../../../../journey.context"

const Audit = () => {
  const { updateJourneyAuditData } = useContext(JourneyContext)

  useEffect(() => {
    updateJourneyAuditData([
      {
        mode: "unshift",
        data: [
          {
            name: "Please confirm your client's understanding by answering yes to the policy exclusions below. If the client would like more information on any of these general exclusions, the exact terms can be found by clicking on the exclusion.",
            value: true
          }
        ]
      }
    ])

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Audit
