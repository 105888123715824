import React, { useContext } from "react"
import { get, capitalize } from "lodash"
import moment from "moment"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { AuthContext } from "@4cplatform/elements/Auth"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"

// Components
import { Actions } from "./codeRequests.actions"

const AgencyCodeRequests = () => {
  const {
    data,
    queryLoading,
    onRequestSelect,
    onRequestDeselect,
    pagination,
    setPage,
    setPerPage,
    onSort,
    sorting
  } = React.useContext(AgencyCodesContext)
  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)
  const { canAccess } = useContext(AuthContext)

  const t = useTranslations()

  const providerColumn = canAccess(["SYS_ADMIN", "SUPPORT_ADMIN"])
    ? [
        {
          label: "Provider",
          dataKey: "provider",
          render: row => get(row, "data.provider.name"),
          minWidth: "180px",
          sortable: true
        }
      ]
    : []

  return (
    <>
      <Actions />
      <Table
        type="contained"
        data={data}
        isLoading={queryLoading}
        name="agency_codes_requests"
        columns={[
          {
            label: "Product Type",
            dataKey: "product_type",
            minWidth: "70px",
            sortable: true
          },
          [
            ...providerColumn,
            {
              label: "Product",
              dataKey: "product",
              render: row => t(get(row, "data.product", "-")),
              minWidth: "180px",
              sortable: true
            }
          ],
          {
            label: "Agency code",
            dataKey: "agency_code",
            minWidth: "180px",
            sortable: true,
            render: row => get(row, "data.agency_code") || "-"
          },
          {
            label: "Rate",
            dataKey: "primary_commission_rate",
            minWidth: "50px",
            render: row =>
              get(row, "data.primary_commission_rate")
                ? `${get(row, "data.primary_commission_rate")}%`
                : "0%",
            sortable: true
          },
          {
            label: "Owner",
            dataKey: "owner_type",
            minWidth: "150px",
            render: row => capitalize(get(row, "data.owner.attributes.name")),
            sortable: true
          },
          {
            label: "Application Date",
            dataKey: "created_at",
            minWidth: "80px",
            render: row => {
              const date = get(row, "data.created_at")
              return date
                ? moment.utc(date, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          }
        ]}
        hasActions
        onClick={row => {
          onRequestSelect(row)
          setPanelStatus("open")
        }}
        onClose={() => {
          if (panelStatus !== "closed") {
            setPanelStatus("closed")
          }
          onRequestDeselect()
        }}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        sorting={sorting}
      />
    </>
  )
}

export default AgencyCodeRequests
