/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import PropTypes from "prop-types"
import { get, isEmpty } from "lodash"
import { useFormik } from "formik"
import { object, string } from "yup"
import { Modal, Button } from "@4cplatform/elements/Molecules"

// Helpers
import {
  InviteOrganisationModel as validationSchema,
  disableButton,
  formatInvitationsBody
} from "./networkInvitations.helpers"
import { NetworkInvitationsContext } from "./networkInvitations.context"

// Components
import { InviteOrganisationButtonsWrapper } from "./networkInvitations.styles"
import Form from "./networkInvitations.actions.invitation.form"
import InvitationsList from "./networkinvitations.actions.invitation.list"

const InviteOrganisation = ({ onClose }) => {
  const { onInviteOrganisation, inviteLoading, queryLoading } =
    React.useContext(NetworkInvitationsContext)
  const [invitations, setInvitations] = React.useState([])

  const newValidationSchema = validationSchema.concat(
    object({
      email_address: string()
        .email("INVALID_EMAIL_PREFIX")
        .test("Check duplicate email", "NOT_UNIQUE_EMAIL", value => {
          if (
            value &&
            invitations?.length &&
            invitations.map(invitation => invitation.email_address).includes(value)
          ) {
            return false
          }

          return true
        })
        .nullable()
    })
  )

  // Formik instance
  const inviteOrganisationFormik = useFormik({
    initialValues: {
      email_address: ""
    },
    validationSchema: newValidationSchema,
    onSubmit: (invite, { resetForm }) => {
      setInvitations([...invitations, { ...invite }])
      resetForm()
    }
  })

  const { handleSubmit: addInvitation } = inviteOrganisationFormik
  const formik = { ...inviteOrganisationFormik, newValidationSchema }
  const allInvites = [...invitations, get(formik, "values", {})]

  const removeInvitation = invitation => {
    setInvitations(curInvitations =>
      curInvitations.filter(curInv => curInv.email_address !== invitation.email_address)
    )
  }

  return (
    <Modal onClose={() => onClose()} title="Invite organisations" hasPadding={false}>
      {/* Invites list */}
      <InvitationsList invitations={invitations} removeInvitation={removeInvitation} />

      {/* Form */}
      <Form formik={formik} handleSubmit={addInvitation} />

      {/* Form submission and cancel action buttons */}
      <InviteOrganisationButtonsWrapper>
        <Button name="cancel" appearance="error" trailingIcon="cancel" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          name="send_invitation"
          appearance="success"
          trailingIcon="send"
          onClick={() => {
            if (formik.errors.email_address) {
              formik.setFieldTouched("email_address", true)
            } else {
              onInviteOrganisation(formatInvitationsBody(allInvites))
            }
          }}
          isDisabled={
            queryLoading || (disableButton(get(formik, "values", {})) && isEmpty(invitations))
          }
          isLoading={inviteLoading}
        >
          Send invitation
        </Button>
      </InviteOrganisationButtonsWrapper>
    </Modal>
  )
}

InviteOrganisation.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default InviteOrganisation
