import React from "react"
import { get } from "lodash"
import { boolean } from "yup"
import { List, P } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { Toggle } from "@4cplatform/elements/Forms"

import Audit from "./Audit"

export const config = data => ({
  title: "AXA FMU or Switch FMU Declaration Confirmation",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          key: "compliance_note",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <P>
                  I declare that to the best of my knowledge and belief that statements made on this
                  form are full, true and correct.
                </P>
                <P>
                  I understand that the acceptance of my application shall be on the basis of these
                  statements and that I and any family members included in this plan shall be bound
                  by the terms of the plan, which I shall read when I receive my plan details. I
                  understand that you will send most correspondence about the plan to the lead
                  member but will, where possible, correspond with each individual member over the
                  age of 16 about their claim.
                </P>
                <P>
                  I understand that we are allowed to choose which law govern the policy. Unless we
                  agree something different this policy will be governed by the law of England and
                  Wales.
                </P>
                <P>
                  <strong>Please note:</strong> If you don't take reasonable care and the
                  information you give us is inaccurate or incomplete then we may take one or more
                  of the following actions:
                </P>
                <List listType="unordered" type="i" name="declaration_confirmation_text_list">
                  <li>Cancel your plan;</li>
                  <li>
                    Declare your membership void (treating your plan as it it has never existed)
                  </li>
                  <li>Change the terms of your plan; or</li>
                  <li>
                    Refuse to deal with all or part of any claim or reduce the amount of any claim
                    payments
                  </li>
                </List>
                <P>
                  We may ask you to provide further information and/or documentation to make sure
                  that the information you gave us when taking out, making changes or renewing your
                  plan was accurate and complete.
                </P>
                <P>
                  Please do not assume that we will carry out any searches or contact any other
                  person to check any of the information to the answers to any of the questions on
                  this application form or any of the information provided in response to these
                  questions. It remains your responsibility to complete the application form and
                  check that the information within it is accurate and complete.
                </P>
                <P>
                  We advise you to keep a record of all the information you provide in connection
                  with this application, including any letter(s) you send in connection with it.
                </P>
                <P>
                  If you would like a copy of this application, please let us know within three
                  months. We may turn down an application if we discover that the information you
                  provide is not sufficiently true, accurate and complete so as to present to us
                  fairly the risk we are taking on, We reserve the right to no accept your
                  application.
                </P>
                <P>
                  If you don't inform us of any treatment, investigation or tests that we ask about
                  this may change the terms of your plan or invalidate it entirely in such
                  circumstances, any subscription that has been collected will be returned in full.
                </P>
              </>
            ),
            type: "info"
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "axa_fmu_or_switch_declaration_confirmation",
          component: Toggle,
          initialValue: get(data, "page.data.axa_fmu_or_switch_declaration_confirmation", false),
          validationSchema: boolean()
            .oneOf(
              [true],
              "It is not possible to continue with the sales journey if the client has not confirmed the Declaration Confirmation statement."
            )
            .required("MISSING_REQUIRED_FIELD"),
          label: "Client confirms declaration?",
          componentProps: {
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true,
            labelWidth: "39.5rem",
            margin: "0 0 2rem"
          }
        }
      ]
    }
  ]
})
