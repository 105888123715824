import React, { useReducer, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { usePost, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { checkAddressContent } from "@4cplatform/elements/Helpers"
import { defaultBankAddress } from "../bankDetails.helpers"
import reducer from "./bankDetails.reducer"

// Components
import { Provider } from "./bankDetails.context"

const BankDetailsProvider = ({ children, formik }) => {
  const t = useTranslations()

  // State
  const [{ search, display }, dispatch] = useReducer(reducer, {
    search: "",
    display: checkAddressContent(get(formik, "values.bank_address", defaultBankAddress))
      ? "filled_in"
      : "fresh"
  })

  // Reset component to Fresh state
  const setFresh = () => {
    dispatch({ type: "SET_FRESH" })
    formik.setFieldValue("bank_address", defaultBankAddress)
    formik.setFieldValue("sort_code", "")
  }

  // Set component to Filled In state
  const setFilledIn = useCallback(
    () => {
      dispatch({ type: "SET_FILLED_IN" })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik]
  )

  // sortcode search
  const [sortcodeLookup, { loading: sortcodeLookupLoading, error: sortcodeLookupError }] = usePost({
    endpoint: "/dmz/sortcode-lookup",
    onCompleted: res => {
      const data = get(res, "data", {})
      const address = {
        name: data.bank,
        line_one: data.line_one,
        line_two: data.line_two,
        city: data.city,
        postcode: data.postcode
      }
      formik.setFieldValue("bank_address", address)
      setFilledIn()
      dispatch({ type: "SET_SEARCH", sortcode: "" })
    },
    onError: () => {
      addAlert({
        message: t("SORTCODE_LOOKUP_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    },
    skip: !search
  })

  useEffect(() => {
    if (
      (formik.isSubmitting && display !== "filled_in") ||
      (display !== "filled_in" &&
        (get(formik, "values.bank_address.line_one") || get(formik, "values.bank_address.city")))
    ) {
      setFilledIn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, display])

  return (
    <Provider
      value={{
        search,
        setSearch: val => {
          dispatch({ type: "SET_SEARCH", sortcode: val })
          sortcodeLookup({ body: { sortcode: val } })
        },
        sortcodeLookupLoading,
        setFresh,
        setFilledIn,
        formik,
        display
      }}
    >
      <ApiError error={sortcodeLookupError} />
      {children}
    </Provider>
  )
}

BankDetailsProvider.propTypes = {
  children: PropTypes.any,
  formik: PropTypes.object.isRequired
}

export default BankDetailsProvider
