import React, { useContext } from "react"

// Helpers
import { hasFormikErrorsField } from "@4cplatform/elements/Helpers"
import { JourneyContext } from "../../../../../../journey.context"

// Components
import QuotationSummaryProvider from "./context/quotationSummary.provider"
import QuotationSummaryTable from "../../../../../QuotationSummaryTable"
import DemandsNeedsForm from "./quotationSummary.demandsNeedsForm"
import EditQuotePanel from "./quotationSummary.editPanel"
import DeleteConfirmationModal from "./quotationSummary.deleteConfirmationModal"
import QuoteNetworkDocuments from "./quotationSummary.quoteNetworkDocuments"
import ProviderInfoModal from "./quotationSummary.quoteProviderModal"
import { ErrorMessage } from "./quotationSummary.styles"

const QuotationSummary = () => {
  const { formik } = useContext(JourneyContext)
  const hasError = hasFormikErrorsField(formik, "selected_quote")

  return (
    <QuotationSummaryProvider>
      <QuotationSummaryTable />
      {hasError && <ErrorMessage>A quote must be selected in order to proceed</ErrorMessage>}
      <DemandsNeedsForm />
      <QuoteNetworkDocuments />
      <EditQuotePanel />
      <DeleteConfirmationModal />
      <ProviderInfoModal />
    </QuotationSummaryProvider>
  )
}

export default QuotationSummary
