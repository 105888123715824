import { sortQuotesByPremium } from "../../../UI/Templates/QuickQuote"

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_VALUE": {
      return {
        ...state,
        [action.key]: action.value
      }
    }

    case "QUOTE_ARRIVED": {
      const arrivedQuotes = sortQuotesByPremium(
        [...state.arrivedQuotes, action.value],
        action.paymentFrequency
      )

      return {
        ...state,
        arrivedQuotes,
        quotesArrived: arrivedQuotes.length === state.expectedQuotes.length
      }
    }

    case "REORDER_QUOTES": {
      const arrivedQuotes = sortQuotesByPremium(state.arrivedQuotes, action.paymentFrequency)

      return {
        ...state,
        arrivedQuotes
      }
    }
    default:
      return state
  }
}

export default reducer
