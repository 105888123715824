import { boolean } from "yup"
import { get } from "lodash"
import { P, List } from "@4cplatform/elements/Typography"
import { Toggle } from "@4cplatform/elements/Forms"
import FileUploader from "../../../../../FileUploader"
import Audit from "./Audit"

export const config = data => ({
  title: "Request certificate of Insurance",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          key: "pmc_must_meet_the_following_criteria_text_message",
          component: P,
          componentProps: {
            children:
              "Requested copy of Previous Medical Certificate (PMC) from client. PMC must meet the following criteria:"
          },
          skipDataMap: true
        },
        {
          key: "request_certificate_of_insurance_criteria_list",
          component: List,
          componentProps: {
            listType: "unordered",
            name: "request_certificate_of_insurance_criteria_list",
            children: (
              <>
                <li>Most recent certificate</li>
                <li>Letter headed paper</li>
                <li>Confirmation of members covered</li>
                <li>Original start date</li>
                <li>Underwriting method applied</li>
                <li>Confirmation of terms / exclusions</li>
              </>
            )
          },
          skipDataMap: true
        },
        {
          key: "has_pmc_been_received_text_message",
          component: P,
          componentProps: {
            children:
              "It is possible to continue with the sales journey without confirmation that the client's PMC has been received and meets the required criteria. However, this will be required later before being able to submit the application."
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "pmc_received_and_meets_criteria",
          initialValue: get(data, "page.data.pmc_received_and_meets_criteria", false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label: "PMC received and meets above criteria?",
          component: Toggle,
          componentProps: {
            labelWidth: "39rem",
            isHorizontal: true,
            isRequired: true
          }
        }
      ]
    },
    {
      key: "section_3",
      components: [
        {
          key: "pmc_uploader",
          component: FileUploader,
          componentProps: {
            validFileTypes: ".docx,.doc,.png,.jpg,.jpeg,.pdf",
            fileType: "PMC",
            name: "pmc_files",
            maxFileSize: 30,
            canAllowMultiple: true,
            fileSelectLabel:
              "Each page of the PMC should now be uploaded in any of the following formats .docx, .doc, .png, .jpg, .jpeg, .pdf. Max File Size: 30Mb"
          },
          skipDataMap: true
        }
      ]
    }
  ]
})
