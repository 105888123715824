import React, { useContext } from "react"
import { get, isEmpty } from "lodash"
import { Toggle } from "@4cplatform/elements/Forms"
import { H3, P } from "@4cplatform/elements/Typography"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { SystemSettingsContext } from "./system.context"

// Components
import { MaintenanceWrapper } from "./system.styles"

const MaintenanceMode = () => {
  const {
    data,
    onClickMaintenanceMode,
    maintenance,
    setMaintenance,
    maintenanceLoading,
    settingsLoading,
    notificationStatus,
    notificationStatusLoading,
    enableNotification,
    disableNotification,
    notificationStatusEnabling,
    notificationStatusDisabling
  } = useContext(SystemSettingsContext)
  const isEnabled = get(data, "maintenance_mode")

  return (
    <>
      <MaintenanceWrapper>
        <H3 margin="0 0 2rem" isLoading={settingsLoading}>
          Maintenance mode
        </H3>
        {!settingsLoading && !isEmpty(data) && (
          <Toggle
            name="maintenance_mode"
            options={[
              { order: 1, label: "Disabled", value: false },
              { order: 2, label: "Enabled", value: true }
            ]}
            value={get(data, "maintenance_mode")}
            onChange={value => {
              if (value === isEnabled) {
                return null
              }
              setMaintenance(true)
            }}
            isDisabled={settingsLoading}
          />
        )}

        <H3 margin="0 0 2rem" isLoading={notificationStatusLoading}>
          Agency code notification/email to providers
        </H3>

        {notificationStatusLoading || (
          <Toggle
            name="agency_code_notificaton_to_provider"
            options={[
              { order: 1, label: "Disabled", value: false },
              { order: 2, label: "Enabled", value: true }
            ]}
            value={notificationStatus}
            onChange={value => {
              if (value) {
                enableNotification()
              } else {
                disableNotification()
              }
            }}
            isDisabled={
              notificationStatusLoading || notificationStatusEnabling || notificationStatusDisabling
            }
          />
        )}
      </MaintenanceWrapper>
      {/* Maintenance mode modal */}
      {maintenance && (
        <ConfirmationModal
          confirmAppearance={isEnabled ? "error" : "success"}
          confirmIcon="tools"
          confirmText={isEnabled ? "Disable" : "Enable"}
          cancelAppearance={isEnabled ? "errorGhost" : "error"}
          onClose={() => setMaintenance(false)}
          onConfirm={onClickMaintenanceMode}
          isLoadingConfirm={maintenanceLoading}
        >
          <P>
            Are you sure you want to {isEnabled ? "disable" : "enable"} maintenance mode on the
            system?
          </P>
        </ConfirmationModal>
      )}
    </>
  )
}

export default MaintenanceMode
