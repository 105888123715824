import React, { useContext } from "react"
import { Select } from "@4cplatform/elements/Forms"

// Helpers
import { QuickQuoteLogContext } from "./quickQuoteLog.context"

// Components
import { TableActionsWrapper } from "./quickQuoteLog.styles"
import { Search } from "../../Molecules"

const Actions = () => {
  const { setSearch, filter, setFilter } = useContext(QuickQuoteLogContext)

  return (
    <TableActionsWrapper data-testid="quick-quote-log-actions-wrapper">
      <Select
        name="filter_search"
        onChange={val => setFilter(val)}
        margin="0 1rem 0 0"
        value={filter}
        label="Search by"
        labelWidth="auto"
        isHorizontal
      >
        <option value="organisation">Organisation</option>
        <option value="sales_agent">Sales Agent</option>
      </Select>
      <Search
        name="search_quick-quote-log"
        handleChange={val => setSearch(val)}
        onCancel={() => setSearch("")}
        margin="0"
      />
    </TableActionsWrapper>
  )
}

export default Actions
