import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Collapse, Button } from "@4cplatform/elements/Molecules"
import { Icon } from "@4cplatform/elements/Atoms"
import { P, H4 } from "@4cplatform/elements/Typography"
import { get } from "lodash"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"

// Components
import { NotesHeaderWrapper, FlexRow, GreyLine } from "./switchDisclosures.styles"
import { DisclosureNotesContext } from "../../../../../../DisclosureNotes/disclosureNotes.context"
import { JourneyContext } from "../../../../../../../journey.context"

// eslint-disable-next-line arrow-body-style
const NoteDisplayTemplate = ({ note }) => {
  const { deleteLoading, setAddEditModal, noteToDelete, setConfirmationModal } =
    useContext(DisclosureNotesContext)
  const { data } = useContext(JourneyContext)

  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  const isLocked = get(data, "journey.locked", false)

  const handleToggleCollapse = () => {
    setIsCollapseOpen(!isCollapseOpen)
  }

  return (
    <Collapse
      wrapperBorderColour={colours.lightGrey}
      onClick={handleToggleCollapse}
      isOpenManual={isCollapseOpen}
      margin="1.5rem 0"
      headerContent={
        <NotesHeaderWrapper>
          <FlexRow>
            <Icon icon="account-alert" margin="0 1.5rem 0 0" colour={colours.blue} />
            <H4 margin="0">{`${note.journey_applicant.first_name} ${note.journey_applicant.last_name}`}</H4>
          </FlexRow>
          <FlexRow>
            <Button
              name="delete_disclousure_note"
              leadingIcon="trash-can"
              type="inline-button"
              isLoading={deleteLoading && noteToDelete.id === note.id}
              appearance="errorInline"
              isDisabled={isLocked}
              onClick={e => {
                e.stopPropagation()
                setConfirmationModal({
                  warningText: "Are you sure you want to delete this note?",
                  isOpen: true,
                  fieldName: note.field,
                  query: "deleteOneNote",
                  noteId: note.id,
                  closedSelected: false,
                  confirmedSelected: false
                })
              }}
            />
            <Button
              name="edit_disclousure_note"
              leadingIcon="clipboard-edit-outline"
              type="inline-button"
              isDisabled={isLocked}
              onClick={e => {
                e.stopPropagation()
                setAddEditModal({
                  type: "edit",
                  isOpen: true,
                  field: get(note, "field", ""),
                  noteInitialValues: {
                    id: get(note, "id", ""),
                    illness_or_medical_problem: get(note, "illness_or_medical_problem", ""),
                    date_of_symptoms_began: get(note, "date_of_symptoms_began", ""),
                    date_of_symptoms_ended: get(note, "date_of_symptoms_ended", ""),
                    field: get(note, "field", ""),
                    treatment: get(note, "treatment", ""),
                    current_state_of_condition_or_symptom: get(
                      note,
                      "current_state_of_condition_or_symptom",
                      ""
                    ),
                    journey_applicant_id: get(note, "journey_applicant.id", ""),
                    number_of_consults_in_past_two_years: get(
                      note,
                      "number_of_consults_in_past_two_years",
                      ""
                    ),
                    product_name: get(note, "product_name", "")
                  }
                })
              }}
            />
            <Icon
              icon={isCollapseOpen ? "chevron-up" : "chevron-down"}
              margin="0 0 0 1.5rem"
              colour={colours.blue}
            />
          </FlexRow>
        </NotesHeaderWrapper>
      }
      bodyContent={
        <>
          <FlexRow>
            <H4 width="100%">Description of Illness/medical problem:</H4>
            <GreyLine />
            <P style={{ minWidth: "16rem" }}>{note.illness_or_medical_problem}</P>
          </FlexRow>
          <FlexRow>
            <H4 width="100%">When the symptoms began:</H4>
            <GreyLine />
            <P style={{ minWidth: "16rem" }}>
              {note.date_of_symptoms_began
                ? moment.utc(note.date_of_symptoms_began).local().format("DD/MM/YYYY")
                : "-"}
            </P>
          </FlexRow>
          <FlexRow>
            <H4 width="100%">When the symptoms ended:</H4>
            <GreyLine />
            <P style={{ minWidth: "16rem" }}>
              {note.date_of_symptoms_ended
                ? moment.utc(note.date_of_symptoms_ended).local().format("DD/MM/YYYY")
                : "-"}
            </P>
          </FlexRow>
          <FlexRow>
            <H4 width="100%">Treatment received:</H4>
            <GreyLine />
            <P style={{ minWidth: "16rem" }}>{note.treatment}</P>
          </FlexRow>
          <FlexRow>
            <H4 width="100%">Current state of condition/symptom:</H4>
            <GreyLine />
            <P style={{ minWidth: "16rem" }}>{note.current_state_of_condition_or_symptom}</P>
          </FlexRow>
          <FlexRow>
            <H4 width="100%">Consulted a healthcare professional in the past 2 years:</H4>
            <GreyLine />
            <P style={{ minWidth: "16rem" }}>{note.number_of_consults_in_past_two_years}</P>
          </FlexRow>
        </>
      }
    />
  )
}

NoteDisplayTemplate.defaultProps = {
  note: {}
}

NoteDisplayTemplate.propTypes = {
  note: PropTypes.object
}

export default NoteDisplayTemplate
