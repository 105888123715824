import React from "react"
import { Helmet } from "react-helmet-async"
import { H1 } from "@4cplatform/elements/Typography"
import { Container } from "@4cplatform/elements/Atoms"

// Helpers
import { DASHBOARD } from "../../config/pages"

// Components
import { Breadcrumbs } from "../../UI/Molecules"
import { SystemSettings as Settings } from "../../UI/Templates"
import SystemSettingsProvider from "./context/settings.provider"

const SystemSettings = () => (
  <>
    <Helmet>
      <title>System settings</title>
    </Helmet>
    <SystemSettingsProvider>
      <Container isPageContainer width="80%">
        <Breadcrumbs
          trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: "System settings" }]}
        />
        <H1 margin="0 0 6rem">System settings</H1>
        <Settings />
      </Container>
    </SystemSettingsProvider>
  </>
)

export default SystemSettings
