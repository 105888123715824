import React, { useContext } from "react"
import { get } from "lodash"
import { P, H2 } from "@4cplatform/elements/Typography"
import { Button } from "@4cplatform/elements/Molecules"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { QuotationSummaryContext } from "./context/quotationSummary.context"
import { JourneyContext } from "../../../../../../journey.context"

// Components
import { EditPanelFooter, EditPanelFooterColumnWrapper } from "./quotationSummary.styles"
import { getPremiumHelperText } from "../QuoteComparison/quoteComparison.helpers"

const EditQuotePanelFooter = () => {
  const {
    selectedEditQuote,
    availableOptionsLoading,
    refreshQuoteLoading,
    tableLoading,
    journeyApplicantsLoading,
    editQuoteFormik: formik
  } = useContext(QuotationSummaryContext)
  const { setPanelStatus } = useContext(PageContext)

  const { data } = useContext(JourneyContext)
  const environment = process.env.NODE_ENV
  const isLoading =
    availableOptionsLoading || refreshQuoteLoading || tableLoading || journeyApplicantsLoading
  const isLocked = get(data, "journey.locked", false)

  return (
    <EditPanelFooter isDeleted={isLoading}>
      <EditPanelFooterColumnWrapper>
        <Button
          appearance="success"
          trailingIcon="check"
          onClick={() => {
            if (formik.dirty) {
              formik.handleSubmit()
            } else {
              setPanelStatus("closed")
            }
          }}
          name="edit_quote_confirm"
          isLoading={isLoading}
          isDisabled={isLoading || isLocked}
        >
          Confirm
        </Button>
      </EditPanelFooterColumnWrapper>

      <EditPanelFooterColumnWrapper>
        {getPremiumHelperText(get(selectedEditQuote, "flags", {}), environment).map(helper => (
          <P appearance="light" margin="0" {...helper} />
        ))}
        <P appearance="light" margin="0 2rem 0 0">
          {isLoading && "Recalculating price"}
          {!isLoading &&
            (get(selectedEditQuote, "payment_frequency") === "PAYMENT_FREQ_MONTHLY"
              ? "Monthly premium"
              : "Annual premium")}
        </P>
        <H2 appearance="light" loadingWidth="12.5rem" isLoading={isLoading} margin="0">
          £
          {parseFloat(
            get(
              selectedEditQuote,
              `${
                get(selectedEditQuote, "payment_frequency") === "PAYMENT_FREQ_MONTHLY"
                  ? "monthly"
                  : "annual"
              }_premium`
            )
          ).toFixed(2)}
        </H2>
      </EditPanelFooterColumnWrapper>
    </EditPanelFooter>
  )
}

export default EditQuotePanelFooter
