import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Collapse, Button } from "@4cplatform/elements/Molecules"
import { Icon } from "@4cplatform/elements/Atoms"
import { P, H4 } from "@4cplatform/elements/Typography"
import { get } from "lodash"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"

// Components
import { NotesHeaderWrapper, FlexRow, GreyLine } from "./switchDisclosures.styles"
import { DisclosureNotesContext } from "../../../../../../DisclosureNotes/disclosureNotes.context"
import { JourneyContext } from "../../../../../../../journey.context"

// eslint-disable-next-line arrow-body-style
const NoteDisplayTemplate = ({ note }) => {
  const { deleteLoading, setAddEditModal, noteToDelete, setConfirmationModal } =
    useContext(DisclosureNotesContext)
  const { data } = useContext(JourneyContext)

  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  const isLocked = get(data, "journey.locked", false)

  const handleToggleCollapse = () => {
    setIsCollapseOpen(!isCollapseOpen)
  }

  return (
    <Collapse
      wrapperBorderColour={colours.lightGrey}
      onClick={handleToggleCollapse}
      isOpenManual={isCollapseOpen}
      margin="1.5rem 0"
      headerContent={
        <NotesHeaderWrapper>
          <FlexRow>
            <Icon icon="account-alert" margin="0 1.5rem 0 0" colour={colours.blue} />
            <H4 margin="0">{`${note.journey_applicant.first_name} ${note.journey_applicant.last_name}`}</H4>
          </FlexRow>
          <FlexRow>
            <Button
              name="delete_disclousure_note"
              leadingIcon="trash-can"
              type="inline-button"
              isLoading={deleteLoading && noteToDelete.id === note.id}
              appearance="errorInline"
              isDisabled={isLocked}
              onClick={e => {
                e.stopPropagation()
                setConfirmationModal({
                  warningText: "Are you sure you want to delete this note?",
                  isOpen: true,
                  fieldName: note.field,
                  query: "deleteOneNote",
                  noteId: note.id,
                  closedSelected: false,
                  confirmedSelected: false
                })
              }}
            />
            <Button
              name="edit_disclousure_note"
              leadingIcon="clipboard-edit-outline"
              type="inline-button"
              isDisabled={isLocked}
              onClick={e => {
                e.stopPropagation()
                if (get(note, "product_name", "") === "HEALTHFORYOU") {
                  setAddEditModal({
                    type: "edit",
                    isOpen: true,
                    field: get(note, "field", ""),
                    noteInitialValues: {
                      id: get(note, "id", ""),
                      date_illness_began: get(note, "date_illness_began", ""),
                      date_illness_ended: get(note, "date_illness_ended", ""),
                      field: get(note, "field", ""),
                      illness_disability: get(note, "illness_disability", ""),
                      journey_applicant_id: get(note, "journey_applicant.id", ""),
                      ...(get(note, "field", "") ===
                      "have_any_treatment_consultations_investigations_or_diagnostic_tests_planned_or_pending"
                        ? {
                            cont_cover_terms_details: get(note, "cont_cover_terms_details", "")
                          }
                        : {
                            treatment_received: get(note, "treatment_received", ""),
                            present_state_of_health: get(note, "present_state_of_health", "")
                          }),
                      product_name: get(note, "product_name", "")
                    }
                  })
                } else
                  setAddEditModal({
                    type: "edit",
                    isOpen: true,
                    field: get(note, "field", ""),
                    noteInitialValues: {
                      id: get(note, "id", ""),
                      illness_disability_treatment_received: get(
                        note,
                        "illness_disability_treatment_received",
                        ""
                      ),
                      date_illness_began: get(note, "date_illness_began", ""),
                      date_illness_ended: get(note, "date_illness_ended", ""),
                      date_of_symptoms_ended: get(note, "date_of_symptoms_ended", ""),
                      field: get(note, "field", ""),
                      present_state_of_health: get(note, "present_state_of_health", ""),
                      current_state_of_condition_or_symptom: get(
                        note,
                        "current_state_of_condition_or_symptom",
                        ""
                      ),
                      journey_applicant_id: get(note, "journey_applicant.id", ""),
                      number_of_consults_in_past_two_years: get(
                        note,
                        "number_of_consults_in_past_two_years",
                        ""
                      ),
                      product_name: get(note, "product_name", "")
                    }
                  })
              }}
            />
            <Icon
              icon={isCollapseOpen ? "chevron-up" : "chevron-down"}
              margin="0 0 0 1.5rem"
              colour={colours.blue}
            />
          </FlexRow>
        </NotesHeaderWrapper>
      }
      bodyContent={
        <>
          {get(note, "product_name", "") === "HEALTHFORYOU" ? (
            <>
              <FlexRow>
                <H4 width="100%">Nature of illness/disability:</H4>
                <GreyLine />
                <P style={{ minWidth: "16rem" }}>{note.illness_disability}</P>
              </FlexRow>
              {get(note, "field", "") ===
                "had_treatment_in_hospital_or_consulted_a_specialist_in_the_last_12_months" && (
                <FlexRow>
                  <H4 width="100%">Treatment received:</H4>
                  <GreyLine />
                  <P style={{ minWidth: "16rem" }}>{note.treatment_received}</P>
                </FlexRow>
              )}
            </>
          ) : (
            <FlexRow>
              <H4 width="100%">Nature of illness/disability and treatment received:</H4>
              <GreyLine />
              <P style={{ minWidth: "16rem" }}>{note.illness_disability_treatment_received}</P>
            </FlexRow>
          )}
          <FlexRow>
            <H4 width="100%">When did the illness/disability begin:</H4>
            <GreyLine />
            <P style={{ minWidth: "16rem" }}>
              {note.date_illness_began
                ? moment.utc(note.date_illness_began).local().format("DD/MM/YYYY")
                : "-"}
            </P>
          </FlexRow>
          {get(note, "product_name", "") === "HEALTHFORYOU" && (
            <FlexRow>
              <H4 width="100%">When did the illness/disability end:</H4>
              <GreyLine />
              <P style={{ minWidth: "16rem" }}>
                {note.date_illness_ended
                  ? moment.utc(note.date_illness_ended).local().format("DD/MM/YYYY")
                  : "-"}
              </P>
            </FlexRow>
          )}
          {get(note, "product_name", "") === "HEALTHFORYOU" &&
          get(note, "field", "") ===
            "have_any_treatment_consultations_investigations_or_diagnostic_tests_planned_or_pending" ? (
            <FlexRow>
              <H4 width="100%">Details of planned/pending consultations, tests or treatment:</H4>
              <GreyLine />
              <P style={{ minWidth: "16rem" }}>{note.cont_cover_terms_details}</P>
            </FlexRow>
          ) : (
            <FlexRow>
              <H4 width="100%">Current state of health related to this illness:</H4>
              <GreyLine />
              <P style={{ minWidth: "16rem" }}>{note.present_state_of_health}</P>
            </FlexRow>
          )}
        </>
      }
    />
  )
}

NoteDisplayTemplate.defaultProps = {
  note: {}
}

NoteDisplayTemplate.propTypes = {
  note: PropTypes.object
}

export default NoteDisplayTemplate
