import { api } from "@4cplatform/elements/Api/fetchData"
import {
  MAINTENANCE_LOGIN,
  LOGIN,
  TWO_FACTOR_AUTH,
  DASHBOARD,
  FORBIDDEN,
  NOT_FOUND,
  PASSWORD_CHANGE_CONFIRMATION,
  EMAIL_CHANGE_CONFIRMATION,
  NOTIFICATION_ADMIN
} from "./pages"
import { TRANSLATION_NAMESPACES } from "./translations"

const appConfig = {
  API_URL: process.env.REACT_APP_API_URL, // Base url for the api
  API_SCOPE: process.env.REACT_APP_API_SCOPE, // API scope
  TRANSLATION_NAMESPACES,
  MAINTENANCE_LOGIN_PATH: MAINTENANCE_LOGIN.path, // Route for the maintenance login
  LOGIN_PATH: LOGIN.path, // Route for the login page
  TWOFA_PATH: TWO_FACTOR_AUTH.path, // Route for the 2fa page
  HOME_PATH: DASHBOARD.path, // Where to redirect logged in users
  FORBIDDEN_PATH: FORBIDDEN.path, // Forbidden 403 error page
  NOT_FOUND_PATH: NOT_FOUND.path, // Not Found 404 error page
  PASSWORD_CHANGE_CONFIRMATION_PATH: PASSWORD_CHANGE_CONFIRMATION.path,
  EMAIL_CHANGE_CONFIRMATION_PATH: EMAIL_CHANGE_CONFIRMATION.path,
  NOTIFICATIONS_PATH: NOTIFICATION_ADMIN.path,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  GOOGLE_RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  LOADING_TITLES: false,
  GLOBAL_TITLES: (() => {
    const titles = JSON.parse(localStorage.getItem("4c-titles-store"))
    // If null or timestamp greater than 3 days
    const oneDay = 1000 * 60 * 60 * 24
    if (!titles || Date.now() - titles.timestamp > oneDay * 3) return null
    return titles
  })(),
  DEBUG: process.env.REACT_APP_DEBUG === "true"
}

export const pusherConfig = {
  clientKey: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  authorizer: channel => ({
    authorize: (socketId, callback) => {
      api
        .post(`${appConfig.API_URL}/${appConfig.API_SCOPE}/broadcasting/auth`, {
          socket_id: socketId,
          channel_name: channel.name
        })
        .then(response => {
          callback(false, response.data)
        })
        .catch(error => {
          callback(true, error)
        })
    }
  })
}

export default appConfig
