import React from "react"
import PropTypes from "prop-types"
import { get, isEmpty } from "lodash"
import { Button } from "@4cplatform/elements/Molecules"
import { useGet } from "@4cplatform/elements/Api"
import { P } from "@4cplatform/elements/Typography"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"
import { NetworkManageContext } from "../../../../pages/NetworkManage/context/manage.context"

// Components
import CheckedItemModal from "../components/CheckedItemModal/checkedItemModal"

const AddOrganisations = ({ isDisabled, onConfirm }) => {
  const { addModal, setAddModal, selectedAgencyCode } = React.useContext(AgencyCodesContext)
  const { network } = React.useContext(NetworkManageContext)
  const [keyword, setKeyword] = React.useState("")
  const [selectedItems, setSelectedItems] = React.useState([])

  // The API caller should be here to prevent the UI flickering issue
  const { data, loading } = useGet({
    endpoint: "/networks/:slug/agency-codes/:code/organisations-not-shared-with",
    params: {
      slug: get(network, "slug", ""),
      code: get(selectedAgencyCode, "slug", "")
    },
    query: {
      ...(keyword ? { name: keyword } : {}),
      limit: 5,
      member_organisations: true
    },
    onCompleted: res => {
      if (isEmpty(selectedItems)) {
        setSelectedItems(res.data?.map(({ id, name }) => ({ id, name, checked: false })) ?? [])
      }
    }
  })

  return (
    <>
      <Button
        type="inline-button"
        appearance="whiteInline"
        leadingIcon="plus-circle-outline"
        hasIconFill={false}
        onClick={() => setAddModal(true)}
        isDisabled={isDisabled}
      >
        Share
      </Button>
      {addModal && (
        <CheckedItemModal
          data={selectedItems}
          title="Add organisations to share"
          submitAppearance="success"
          onClose={() => {
            setAddModal(false)
            setSelectedItems([])
          }}
          label={{
            name: "Organisation",
            submit: "Share with selected"
          }}
          isLoading={loading}
          searchProps={{
            label: "Select organisation",
            hasCancel: !!keyword,
            onCancel: () => setKeyword(""),
            onChange: value => setKeyword(value),
            isLoading: !!keyword && loading,
            onSelect: ({ id, label }) => {
              setSelectedItems(values => {
                // Only add item that have not in the selected list.
                if (values.find(value => value.id === id && value.checked)) {
                  return values
                }
                return [
                  ...values.filter(value => value.id !== id),
                  { id, name: label, checked: true }
                ]
              })
            },
            suggestions: data?.map(({ id, name }) => ({ id, label: name })) ?? []
          }}
          confirmationProps={{
            confirmIcon: "check",
            renderContent: checkedItem => (
              <P>
                {`Are you sure you want to share this agency code with ${
                  Object.values(checkedItem).filter(val => val === true).length > 1 &&
                  Object.values(checkedItem).every(isChecked => isChecked)
                    ? "all organisations"
                    : "the selected organisations"
                } ?`}
              </P>
            )
          }}
          onChecked={checkedItem => {
            setSelectedItems(values =>
              values.map(item => ({ ...item, checked: checkedItem[item.id] }))
            )
          }}
          onSubmit={async checkedItem => {
            await onConfirm(Object.keys(checkedItem).filter(key => checkedItem[key]))
            setAddModal(false)
          }}
        />
      )}
    </>
  )
}

AddOrganisations.propTypes = {
  isDisabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired
}

export default AddOrganisations
