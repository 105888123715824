import styled, { css } from "styled-components"

// Components
import { Skeleton as Skeletonn } from "@4cplatform/elements/Molecules"

export const HospitalPreferenceWrapper = styled.div`
  width: 100%;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const MapWrapper = styled.div`
  width: calc(60% - 1rem);
  margin-top: 3.5rem;
  max-height: 765px;
`

export const TableWrapper = styled.div`
  width: calc(40% - 1rem);

  .total-wrapper {
    justify-content: flex-end;
  }
`

export const GoogleMapsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const PinWrapper = styled.div`
  position: relative;
  display: flex;
`

export const Pin = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  z-index: ${({ zIndex }) => zIndex};
  background: ${({ theme, type }) => {
    const pinColors = {
      client: theme.red,
      closest: theme.blue,
      selected: theme.green,
      available: theme.darkBlue,
      disabled: theme.faintGrey
    }
    return pinColors[`${type}`]
  }};
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: rotate(45deg);
  }

  ${({ isDisabled }) => {
    if (isDisabled)
      return css`
        cursor: not-allowed;
      `
  }}
`

export const Tooltip = styled.div`
  visibility: ${({ isHover }) => (isHover ? "visible" : "hidden")};
  background-color: ${({ theme, type }) => (type === "selected" ? theme.green : theme.blue)};
  color: #fff !important;
  text-align: center;
  padding: 1.5rem 1.2rem;
  top: -10rem;
  left: 1rem;
  width: 22rem;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
`

export const PreferedHospital = styled.div`
  border: ${({ theme }) => `1px solid ${theme.lightGrey}`};
  width: fit-content;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  border-radius: 3px;
`

export const PreferedHospitalContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-right: 1rem;
  gap: 2rem;

  p {
    margin-bottom: 0;
  }
`

export const ZoomButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 2rem;
  right: 0;
`

export const MapSkeleton = styled(Skeletonn)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  top: 0;
  left: 0;
`
