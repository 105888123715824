import React from "react"

// Components
import MaintenanceMode from "./system.maintenance"
import DueDiligences from "./system.dueDiligences"

const SystemSettings = () => (
  <>
    <MaintenanceMode />
    <DueDiligences />
  </>
)

export default SystemSettings
