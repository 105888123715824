import React, { useContext } from "react"
import PropTypes from "prop-types"
import { AuthContext } from "@4cplatform/elements/Auth"
import { Container } from "@4cplatform/elements/Atoms"
import { H1 } from "@4cplatform/elements/Typography"

// Components
import { Breadcrumbs } from "../../Molecules"
import { GlobalStyles, Wrapper } from "./blankDocuments.styles"

const BlankDocuments = ({ children, title }) => {
  const { isLoggedIn } = useContext(AuthContext)

  return (
    <Wrapper>
      <Container width="80%">
        <GlobalStyles />
        <Breadcrumbs
          trail={[{ label: isLoggedIn ? "Dashboard" : "Home", link: "/" }, { label: title }]}
          margin="5rem 0 0.5rem 0"
        />
        <H1>{title}</H1>
        {children}
      </Container>
    </Wrapper>
  )
}

BlankDocuments.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired
}

export default BlankDocuments
