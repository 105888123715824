import React, { useContext } from "react"
import { get } from "lodash"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

import { DisclosureNotesContext } from "./disclosureNotes.context"
import { JourneyContext } from "../../journey.context"

const AddEditModal = noteFormTemplate => {
  const { isLoading } = useContext(JourneyContext)
  const { addEditModal, handleSubmit, disclosureNoteCreating, updateNoteLoading, onCancelOrClose } =
    useContext(DisclosureNotesContext)

  const NoteFormComponent = get(noteFormTemplate, "noteFormTemplate", "")
  if (!isLoading && addEditModal.isOpen) {
    return (
      <ConfirmationModal
        title={addEditModal.type !== "edit" ? "Add Note" : "Edit Note"}
        confirmIcon={addEditModal.type !== "edit" ? "playlist-plus" : "playlist-edit"}
        confirmText={addEditModal.type !== "edit" ? "Add note" : "Edit note"}
        confirmAppearance="primary"
        isLoadingConfirm={disclosureNoteCreating || updateNoteLoading}
        onClose={onCancelOrClose}
        onConfirm={handleSubmit}
        onCancel={onCancelOrClose}
      >
        <NoteFormComponent />
      </ConfirmationModal>
    )
  }
  return null
}

export default AddEditModal
