import { useEffect, useContext } from "react"
import { get } from "lodash"

import { JourneyContext } from "../../../../../../journey.context"
import { styleSwitchOptions } from "./underwritingStyle.selects"

const Audit = () => {
  const { formik, updateJourneyAuditData } = useContext(JourneyContext)

  const recommendedStyle = get(formik, "values.recommended_style", "")
  const recommendedUnderwriting = get(formik, "values.recommended_underwriting", "")
  const recommendedUnderwritingNote = get(formik, "values.recommended_underwriting_note", "")

  useEffect(() => {
    if (recommendedStyle && recommendedUnderwriting && recommendedUnderwritingNote) {
      updateJourneyAuditData([
        {
          mode: "replace",
          data: [
            {
              name: "Underwriting style",
              value: get({ NEW: "New", SWITCH: "Switch" }, recommendedStyle)
            },
            {
              name: "Underwriting type",
              value: get(styleSwitchOptions, recommendedUnderwriting)
            },
            {
              name: "Notes",
              value: recommendedUnderwritingNote
            }
          ]
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedStyle, recommendedUnderwriting, recommendedUnderwritingNote])

  return null
}

export default Audit
