import React, { useContext } from "react"
import { isEmpty } from "lodash"
import { Modal, Skeleton, Button } from "@4cplatform/elements/Molecules"
import { AuthContext, AuthWrapper } from "@4cplatform/elements/Auth"

// Helpers
import { PoliciesContext } from "../../policies.context"
import { canEditPolicyExclusionsRoles } from "../../../../Helpers"

// Components
import PolicyExclusionsHeader from "./policyExclusions.added"
import PolicyExclusionsBody from "./policyExclusions.add"
import PolicyExclusionsFooter from "./policyExclusions.footer"
import { LoadingWrapper } from "./policyExclusions.styles"

const PolicyExclusions = () => {
  const { canAccess } = useContext(AuthContext)
  const {
    exclusionsData,
    exclusionsModal,
    setExclusionsModal,
    updateExclusionLoading,
    deleteExclusionLoading,
    addExclusionLoading,
    exclusionsLoading,
    onExclusionSelect
  } = useContext(PoliciesContext)

  const isLoading =
    updateExclusionLoading || deleteExclusionLoading || addExclusionLoading || exclusionsLoading

  return (
    <>
      <Button
        appearance="whiteGhost"
        trailingIcon="account-alert"
        margin="1rem 0"
        onClick={() => {
          setExclusionsModal(true)
        }}
        isDisabled={isLoading}
        name="edit_policy_exclusions"
      >
        {isEmpty(exclusionsData) && canAccess(canEditPolicyExclusionsRoles) ? "Add" : "Manage"}{" "}
        exclusions
      </Button>
      {exclusionsModal && (
        <Modal
          title="Policy exclusions"
          onClose={() => {
            onExclusionSelect(null)
            setExclusionsModal(false)
          }}
          width="50%"
          hasPadding={false}
          margin="0"
          headerPadding="2rem"
        >
          {isLoading ? (
            <Skeleton count={3} wrapper={LoadingWrapper} />
          ) : (
            <PolicyExclusionsHeader canEditPolicyExclusionsRoles={canEditPolicyExclusionsRoles} />
          )}
          <AuthWrapper roles={canEditPolicyExclusionsRoles}>
            {isLoading ? <Skeleton count={2} wrapper={LoadingWrapper} /> : <PolicyExclusionsBody />}
          </AuthWrapper>
          {isLoading ? <Skeleton count={1} wrapper={LoadingWrapper} /> : <PolicyExclusionsFooter />}
        </Modal>
      )}
    </>
  )
}

export default PolicyExclusions
