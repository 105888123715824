import React, { useContext } from "react"
import moment from "moment"
import { v4 as uuid } from "uuid"
import { get, isEmpty, capitalize } from "lodash"
import { LabelWithText, IconWithText } from "@4cplatform/elements/Atoms"
import { H4, P } from "@4cplatform/elements/Typography"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { PoliciesContext } from "./policies.context"
import { getName } from "../../Helpers"

const getIconType = type => {
  switch (type) {
    case "dependant":
      return "account-group"
    case "partner":
      return "account-multiple"
    case "primary":
    default:
      return "account"
  }
}

const People = () => {
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { viewData, viewLoading } = useContext(PoliciesContext)
  const policyApplicants = get(viewData, "applicants", [])
  const journeyApplicants = get(viewData, "journey_applicants", [])

  return (
    <LabelWithText appearance="light" label="People on policy" isLoading={viewLoading}>
      {isEmpty(journeyApplicants) && (
        <IconWithText
          appearance="light"
          icon="minus"
          content="No people"
          isLoading={viewLoading}
          margin="0"
        />
      )}
      {!isEmpty(journeyApplicants) &&
        journeyApplicants.map((applicant, i) => {
          const isIncludedOnPolicy = policyApplicants.some(
            policyApplicant => policyApplicant.journey_applicant_id === applicant.id
          )
          const type = get(applicant, "type")
          const typeIcon = getIconType(type)
          const name = getName({
            data: applicant,
            hasTitle: true,
            hasMiddle: true,
            globalTitles: GLOBAL_TITLES
          })
          const age = moment().diff(get(applicant, "date_of_birth"), "years")

          const TypographyComponent = type === "primary" ? H4 : P

          return (
            <LabelWithText
              key={uuid()}
              appearance="light"
              label={`${name}`}
              name={`people_person_${i}`}
            >
              {/* Type */}
              <P appearance="light" margin="0">{`${moment(get(applicant, "date_of_birth")).format(
                "DD/MM/YYYY"
              )}, ${age}`}</P>
              <IconWithText appearance="light" icon={typeIcon} margin="0">
                <TypographyComponent appearance="light" margin="0">{`${capitalize(
                  type
                )} applicant`}</TypographyComponent>
              </IconWithText>
              {/* UK resident */}
              <IconWithText
                appearance="light"
                icon={get(applicant, "answers.permanent_uk_resident") ? "check" : "close"}
                content="UK Resident"
                margin="0"
              />
              {/* Smoker */}
              <IconWithText
                appearance="light"
                icon={
                  get(applicant, "answers.tobacco_products_within_last_2_years") ? "check" : "close"
                }
                content="Smoker"
                margin="0"
              />
              {/* Required for visa */}
              <IconWithText
                appearance="light"
                icon={
                  get(applicant, "answers.pmi_required_to_fulfil_reqs_or_visa") ? "check" : "close"
                }
                content="Required for visa"
                margin="0"
              />
              {/* Included on policy */}
              <IconWithText
                appearance="light"
                icon={isIncludedOnPolicy ? "check" : "close"}
                content="Included on policy"
                margin="0"
              />
            </LabelWithText>
          )
        })}
    </LabelWithText>
  )
}

export default People
