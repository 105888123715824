import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import { get, sortBy, isEmpty } from "lodash"
import { useLocation, useHistory } from "react-router-dom"
import { useGet, usePost, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { Provider } from "../../../../../UI/Templates/QuoteAdmin"
import reducer from "./quotes.reducer"
import { ProviderManageContext } from "../../../context/manage.context"
import { getOrderBy } from "../../../../../UI/Helpers"

const QuotesProvider = ({ children }) => {
  const { provider } = useContext(ProviderManageContext)
  const t = useTranslations()
  const { search: lSearch } = useLocation()
  const history = useHistory()
  const qs = queryString.parse(lSearch)

  // State
  const [
    {
      data,
      viewData,
      page,
      perPage,
      search,
      sorting,
      filter,
      status,
      quoteStatuses,
      total,
      selectedQuote,
      hasStatusFilter,
      showSimulated,
      hasShowSimulated,
      messagingModal,
      messages
    },
    dispatch
  ] = React.useReducer(reducer, {
    data: [],
    viewData: {},
    page: 1,
    perPage: 10,
    search: "",
    sorting: { direction: "asc", dataKey: "created_at" },
    filter: "client_name",
    status: "",
    quoteStatuses: [{ AWAITING_TERMS: "QUOTES_AWAITING_TERMS" }],
    total: null,
    selectedQuote: null,
    hasStatusFilter: true,
    showSimulated: get(qs, "simulated", "") === "true",
    hasShowSimulated: true,
    messagingModal: false,
    messages: []
  })

  // Set the search & status params if they have values
  const querySearch = {}
  if (search) querySearch[filter] = search
  if (status) querySearch.status = [status]

  // Index Quotes
  const { loading: queryLoading, error: queryError } = useGet({
    endpoint: "/quotes",
    skip: !get(provider, "name", ""),
    query: {
      page,
      limit: perPage,
      order_by: getOrderBy(sorting),
      simulated: showSimulated,
      provider_key: get(provider, "provider_key", ""),
      ...querySearch
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("QUOTES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Get Quote
  const { loading: viewLoading, error: viewError } = useGet({
    endpoint: "/quotes/:slug",
    skip: !get(selectedQuote, "slug"),
    params: {
      slug: get(selectedQuote, "slug", "")
    },
    query: {
      with: ["client", "salesAgent", "provider", "organisation"]
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: newData })
    }
  })

  // Get Policy messages
  const {
    loading: messagesLoading,
    error: messagesError,
    refetch: messagesRefetch
  } = useGet({
    endpoint: "/policies/:policy/messages",
    skip: !get(viewData, "policy.slug") || !messagingModal,
    params: {
      policy: get(viewData, "policy.slug")
    },
    onCompleted: res =>
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy(get(res, "data", []), "sent_at")
      }),
    onError: () =>
      addAlert({
        message: t("MESSAGES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Create a message
  const [createMessage, { loading: submitMessageLoading, error: submitMessageError }] = usePost({
    endpoint: "/policies/:policy/message",
    params: {
      policy: get(viewData, "policy.slug")
    },
    onCompleted: res => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy([...messages, res.data], "sent_at")
      })
    },
    onError: () => {
      messagesRefetch()
      addAlert({
        message: t("MESSAGE_SEND_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const submitMessage = ({ body, user }) => {
    const updatedMessages = [
      ...messages,
      {
        body_text: body.body_text,
        user_name: user
      }
    ]
    dispatch({
      type: "UPDATE_VALUE",
      key: "messages",
      value: updatedMessages
    })
    createMessage({ body })
  }

  useEffect(() => {
    if (messagingModal && isEmpty(messages)) messagesRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagingModal])

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        viewData,
        search,
        setSearch,
        showSimulated,
        hasShowSimulated,
        setShowSimulated: val => {
          dispatch({ type: "UPDATE_VALUE", key: "showSimulated", value: val })
          history.replace({ search: queryString.stringify({ ...qs, simulated: val }) })
        },
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        pagination: { total, page, perPage },
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        sorting,
        setFilter: val => dispatch({ type: "UPDATE_VALUE", key: "filter", value: val }),
        filter,
        setStatus: val => dispatch({ type: "UPDATE_VALUE", key: "status", value: val }),
        status,
        quoteStatuses,
        hasStatusFilter,
        queryLoading,
        viewLoading,
        selectedQuote,
        onQuoteSelect: val => dispatch({ type: "UPDATE_VALUE", key: "selectedQuote", value: val }),
        onQuoteDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedQuote", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "viewData", value: {} })
          dispatch({ type: "UPDATE_VALUE", key: "messages", value: [] })
        },
        messagingModal,
        setMessagingModal: value => {
          dispatch({ type: "UPDATE_VALUE", key: "messagingModal", value })
          if (!value) dispatch({ type: "UPDATE_VALUE", key: "messages", value: [] })
        },
        messages,
        messagesLoading,
        submitMessage,
        submitMessageLoading
      }}
    >
      {children}
      <ApiError error={queryError || viewError || messagesError || submitMessageError} />
    </Provider>
  )
}

QuotesProvider.defaultProps = {
  children: null
}

QuotesProvider.propTypes = {
  children: PropTypes.any
}

export default QuotesProvider
