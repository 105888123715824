import React from "react"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"

// Components
import CommissionRatesView from "./commissionRates.panel.view"

const CommissionRatesPanel = () => (
  <FlyOutPanel body={() => <CommissionRatesView />} name="commission_ratesPanel_panel" />
)

export default CommissionRatesPanel
