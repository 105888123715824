import React from "react"
import { Helmet } from "react-helmet-async"
import { H2, P, List } from "@4cplatform/elements/Typography"

// Components
import { BlankDocuments } from "../../UI/Templates"

const TermsConditions = () => (
  <>
    <Helmet>
      <title>Terms &amp; Conditions</title>
    </Helmet>
    <BlankDocuments title="Terms &amp; Conditions">
      <H2>Between</H2>
      <List name="terms-conditions">
        <li>
          4C Platform.com Limited (“the Provider”), a company incorporated in England and Wales
          under company number 7377957 and with registered address Units A1-A2, Lakeside Business
          Park, Broadway Lane, South Cerney, Cirencester, Gloucestershire, GL7 5XL;
        </li>
        <li>
          Usay Business Limited (“Usay”), a company incorporated in England and Wales under company
          number 6463920 and with address Usay House, Lakeside Business Park, Broadway Lane, South
          Cerney, Cirencester, Gloucestershire, GL7 5XL;
        </li>
        <li>[ ] (“the Introducer”).</li>
      </List>
      <H2>Whereas</H2>
      <List name="terms-conditions">
        <li>
          The Provider operates an online portal which can be used to introduce customers wanting to
          obtain insurance to insurers;
        </li>
        <li>
          Usay is an insurance intermediary that introduces customers wanting to obtain insurance to
          insurers;
        </li>
        <li>
          The Introducer is an insurance intermediary that introduces customers wanting to obtain
          insurance to insurers. The Introducer wishes to use the Provider's portal to facilitate
          such introductions, and in particular to do the following:
        </li>
        <List name="terms-conditions">
          <li>To introduce customers to insurers;</li>
          <li>
            To introduce customers to Usay, who will in turn introduce such customers to insurers
            and share the commission for such an introduction with the Introducer;
          </li>
          <li>To purchase leads.</li>
        </List>
      </List>
      <P>It is hereby agreed as follows:</P>
      <List name="terms-conditions">
        <li>
          <strong>DEFINITIONS AND INTERPRETATION</strong>
        </li>
        <List name="terms-conditions">
          <li>
            In this Agreement the following words shall have the meaning set out below unless
            otherwise stated to the contrary;
          </li>
          <List name="terms-conditions">
            <li>
              <strong>"Agreement”</strong> means this agreement between the Provider, Usay and the
              Introducer, and includes the schedules to the Agreement;
            </li>
            <li>
              <strong>"Confidential Information”</strong> means any information and/or material
              relating to the business, including but not limited to affairs, finances, trade
              secrets, systems, processes, technical information, know-how, operational documents
              (including the terms and details of this Agreement) and/or methods of operation of a
              party which are disclosed by that party to another in connection with the operation of
              this Agreement (whether disclosed electronically, orally, or in writing, at any time
              whether before or after the date of this agreement and whether or not such information
              is expressly stated to be confidential or marked as such);
            </li>
            <li>
              <strong>"Customer”</strong> means those individuals, partnerships or companies that
              wish to obtain insurance;
            </li>
            <li>
              <strong>"Customer Information”</strong> means the personal data of a Customer entered
              or placed on the portal;
            </li>
            <li>
              <strong>"Data Protection Legislation”</strong> means the Data Protection Act 1998, the
              regulations made thereunder, the General Data Protection Regulation, and any statutory
              amendments or re- enactments made thereof;
            </li>
            <li>
              <strong>"Financial Services Legislation”</strong> means the Financial Services and
              Markets Act 2000, any subordinate legislation made under that Act, any Rules and
              Guidance made under that Act (including any Rules and Guidance contained in the
              Financial Conduct Authority Handbook), and any other obligation or requirement imposed
              by virtue of being authorised and regulated by the Financial Conduct Authority, and
              includes any legislation, rules, guidance, requirements and obligations which repeals,
              replaces and/or supersedes the same;
            </li>
            <li>
              <strong>"Information”</strong> means the data entered or placed onto the Portal by the
              Introducer, Usay and other Users and/or Insurers, including any Customer Information
              or any Introducer Information;
            </li>
            <li>
              <strong>"Insurance Introductions”</strong> means that process of obtaining insurance
              on behalf of a Customer from an Insurer. It includes without limitation the obtaining
              of a quote, the sending of Customer Information to the Insurer via the Portal, and the
              obtaining of a contract of insurance;
            </li>
            <li>
              <strong>"Insurer”</strong> means insurance companies who are, or who are seeking to
              be, introduced to Customers via the Portal;
            </li>
            <li>
              <strong>"Intellectual Property Rights”</strong> means all vested, contingent and
              future intellectual property rights including but not limited to copyright (including
              copyright in software), database rights, moral rights, trade marks, patents, know-
              how, design rights (including unregistered rights, registered rights and applications
              thereof), inventions, get-up, domain names, metatags, applications for the protection
              and registration of these rights (including renewals and extensions thereof), and any
              goodwill associated with the intellectual property as well as rights under licences,
              consents, orders, statute or otherwise, whether existing in any part of the world and
              whether now known or in the future created to which the party may be entitled;
            </li>
            <li>
              <strong>"Introducer Information”</strong> means any information of whatever nature,
              including Customer Information, entered or placed on the Portal by the Introducer;
            </li>
            <li>
              <strong>"Lead”</strong> has the meaning given in Schedule 3;
            </li>
            <li>
              <strong>"Licence”</strong> means the licence granted pursuant to clause 3 below on the
              terms set out in Schedule 2 to this Agreement, as amended pursuant to the terms of
              Schedule 2 from time to time;
            </li>
            <li>
              <strong>"Network Access Agreement”</strong> means an agreement between the Provider
              and a network entered into at the request of an Introducer which allows the network
              access to the Portal to enable the network to audit the activities of the Introducer
              (to include, without limitation, the Introducer's compliance with any Financial
              Services Legislation and/or with the terms of any agreement that the Introducer may
              have with the network);
            </li>
            <li>
              <strong>"Personal Data”</strong> has the same meaning as it does in Data Protection
              Legislation;
            </li>
            <li>
              <strong>"Portal”</strong> means the electronic platform operated by the Provider by
              which Customers can be introduced to Insurers;
            </li>
            <li>
              <strong>"Schedules”</strong> means the Schedules to this Agreement;
            </li>
            <li>
              <strong>"Usay Referral”</strong> means a referral of Customer Information to Usay
              pursuant to clause 6 below;
            </li>
            <li>
              <strong>"User”</strong> means another introducer that uses the Portal, other than the
              Introducer;
            </li>
          </List>
          <li>In this Agreement unless the context requires otherwise:</li>
          <List name="terms-conditions">
            <li>Words importing any gender include every gender;</li>
            <li>Words importing the singular number include the plural number and vice versa;</li>
            <li>
              Words importing persons include firms, companies and corporations and vice versa;
            </li>
            <li>
              References to numbered clauses are references to the relevant clause in this
              Agreement;
            </li>
            <li>
              Reference in any Schedule to numbered paragraphs relate to the numbered paragraphs of
              that Schedule;
            </li>
            <li>
              The headings to the clauses, Schedules and paragraphs of this Agreement will not
              affect the interpretation;
            </li>
            <li>
              Any reference to an enactment includes reference to that enactment as amended or
              replaced from time to time and to any subordinate legislation or byelaw made under
              that enactment;
            </li>
            <li>
              Any obligation on any party to do or omit to do anything is to include an obligation
              not to allow that thing to be done or omitted to be done;
            </li>
            <li>
              Any obligation on any party to do or omit to do anything is also an obligation on that
              party to ensure that its employees, servants or agents do or omit to do that thing to
              be done or omitted to be done;
            </li>
            <li>
              Any party who agrees to do something will be deemed to fulfil that obligation if that
              party procures that it is done;
            </li>
            <li>
              Any reference to loss and damage means loss or damage of whatever nature, including
              without limitation losses, costs (including legal costs), charges, damages, expenses,
              loss of profits, loss of data, loss of income, loss or damage to property, and loss of
              chance, including pure economic, compensatory, direct, indirect, or consequential loss
              and damage;
            </li>
          </List>
          <li>
            In the case of conflict or ambiguity between any provision contained in the body of this
            Agreement and any provision contained in any Schedule, the provision in the body of this
            Agreement shall take precedence.
          </li>
        </List>
        <li>
          <strong>AUTHORISATIONS</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Usay is authorised and regulated by the Financial Conduct Authority, with reference
            number 478676;
          </li>
          <li>
            The Introducer shall at all times hold and maintain all necessary regulatory approvals,
            authorisations and permissions, including without limitation under Financial Services
            Legislation, for the activities which it proposes to carry out under this Agreement;
          </li>
          <li>
            The Introducer shall ensure that any of its employees, servants or agents who access the
            Portal or who act on behalf of the Introducer in relation to this Agreement at all time
            hold and maintain all necessary regulatory approvals, authorisations and permissions,
            including without limitation under Financial Services Legislation, that are required for
            any activity that that employee, servant or agent carries on in relation to this
            Agreement;
          </li>
          <li>
            If the Introducer has entered into an appointed representative agreement with an
            authorised person to enable it to carry out the activities which it proposes to carry
            out under this Agreement without it needing to be itself directly authorised by the
            Financial Conduct Authority this shall satisfy the Introducer's obligations under
            subclause 2.2, provided that:
          </li>
          <List name="terms-conditions">
            <li>
              The Introducer remains an appointed representative throughout the duration of the
              Agreement;
            </li>
            <li>
              The Introducer makes the authorised person fully aware of the Introducer's intention
              to use the Portal and what that entails and the authorised person consents to the
              same;
            </li>
          </List>
          <li>
            In the event that the Introducer ceases to have all necessary regulatory approvals,
            authorisations and permissions, or ceases to be an appointed representative as described
            in subclause 2.4 above, the Introducer shall immediately cease to use the Portal until
            such time as the Introducer again obtains such approvals, authorisations and
            permissions, or again becomes such an appointed representative.
          </li>
        </List>
        <li>
          <strong>USE OF THE PORTAL</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider grants to the Introducer a non-exclusive licence to use the Portal on the
            terms and conditions as set out in Schedule 2 to this Agreement;
          </li>
          <li>
            The Provider may, but is not under an obligation to, provide assistance via the Portal
            to Introducers and/or Insurers on complying with the Introducer's and/or Insurers'
            obligations under Financial Services Legislation, for example by the Portal generating
            documents that are designed to comply with Financial Services Legislation. However, the
            Portal is not a compliance platform, and the Provider is not providing any warranty,
            guarantee or assurance that any Introduction made via the Portal or documentation
            generated by the Portal shall be compliant with Financial Services Legislation. It shall
            in all cases be the responsibility of the Introducer and/or the Insurer to:
          </li>
          <List name="terms-conditions">
            <li>ensure that they are compliant with Financial Services Legislation;</li>
            <li>
              satisfy themselves that any documentation generated by the Portal or other assistance
              is compliant with Financial Services Legislation;
            </li>
          </List>
          <li>The Introducer shall use the Portal to perform one of the following activities:</li>
          <List name="terms-conditions">
            <li>The direct introduction of a Customer pursuant to subclause 5 below;</li>
            <li>A Usay Referral;</li>
            <li>The purchasing of a Lead;</li>
          </List>
          <li>
            The Introducer shall not use the Portal for any purpose other than provided for by
            subclause 3.3 above;
          </li>
          <li>The Introducer shall not allow any Customer to access or use the Portal;</li>
          <li>
            The Introducer shall not use the Portal in respect of any Customer if the Provider's
            dealings with that Customer are not subject to the Financial Services Legislation of the
            United Kingdom;
          </li>
          <li>
            Where the Introducer is a member of a network and the network's consent is needed for
            the use of the Portal the Introducer shall ensure that it has such consent before using
            the Portal. Upon request by an Introducer the Provider may, in its absolute discretion,
            agree to enter into a Network Access Agreement with that network. Once a Network Access
            Agreement is entered into the continuance of the Network Access Agreement is at the
            discretion of the Provider and the Introducer shall have no right to have the Network
            Access Agreement terminated;
          </li>
          <li>
            The use by the Introducer of the Portal is at the Provider's absolute discretion. The
            Provider may, in its absolute discretion and with or without reason and/or notice,
            refuse to allow the Introducer access to the Portal, either permanently or indefinitely
            and/or may suspend or terminate the Licence.
          </li>
        </List>
        <li>
          <strong>GOOD FAITH</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall use the Portal in good faith. In particular and without limitation
            the Introducer shall not seek to make an Insurance Introduction otherwise than through
            the Portal if that Insurance Introduction results from, or has otherwise been influenced
            or affected by, any knowledge or information gained from the Introducer's use of the
            Portal;
          </li>
          <li>
            Upon request by the Provider the Introducer shall provide such details as are reasonably
            requested by the Provider of any insurance policies placed with all or any particular
            Insurer(s), whether or not such policies were placed through or as a result of the
            Portal, for the purpose of the Provider ensuring that the Introducer is complying with
            subclause 4.1 above;
          </li>
          <li>
            The Introducer shall indemnity the Provider and/or Usay against any and all loss and
            damage incurred by them as a result of the Introducer failing to comply with this clause
            4.
          </li>
        </List>
        <li>
          <strong>DIRECT INTRODUCTION TO INSURER</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer may use the Portal to send Customer Information to an Insurer for the
            purpose of Insurance Introductions;
          </li>
          <li>
            The act by which the Introducer can send Customer Information to an Insurer will be
            described in the Portal;
          </li>
          <li>
            The ability of the Introducer to send Customer Information to any particular Insurer is
            at the discretion of both the Provider and the Insurer. The Provider does not provide
            any warranty or guarantee that the Introducer will be able to send Customer Information
            to any particular Insurer, and the Provider may withdraw or limit the ability of the
            Introducer to send Customer Information to a particular Insurer (or to any or all of
            them) at any time.
          </li>
        </List>
        <li>
          <strong>USAY REFERRAL</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Either before commencing an Insurance Introduction on behalf of a Customer, or during
            the process of an Insurance Introduction but before that Customer has obtained an
            insurance policy, an Introducer may use the Portal to refer that Customer to Usay;
          </li>
          <li>
            The act by which the Introducer can refer a Customer to Usay will be described in the
            Portal. Once the Introducer has performed such an act the introduction to Usay is not
            cancellable;
          </li>
          <li>
            Once an Introducer has referred a Customer to Usay, any further Insurance Introduction
            for that Customer will be carried on by Usay and not the Introducer;
          </li>
          <li>
            The Introducer will co-operate fully with Usay in respect of said referral, including by
            the provision of requested information and the answering of questions;
          </li>
          <li>Prior to any referral under this clause taking place the Introducer shall:</li>
          <List name="terms-conditions">
            <li>
              Ensure that the Customer is fully aware of the referral to Usay and has consented to
              the referral;
            </li>
            <li>
              Ensure that the Customer has consented to the provision of Information in respect of
              the Customer, including Customer Information, to the Portal and/or Usay;
            </li>
            <li>
              Comply with any legal and regulatory obligations, including under Financial Services
              Legislation or under Data Protection Legislation, that the Introducer is required to
              comply with before making any such referral;
            </li>
          </List>
          <li>
            In respect of each Usay Referral that results in Usay receiving commission from an
            Insurer for that Customer Usay shall pay the Introducer commission as set out in
            Schedule 1 of this Agreement;
          </li>
          <li>Following a Usay Referral:</li>
          <List name="terms-conditions">
            <li>
              Usay shall have the absolute right to contact any Customer introduced under such a
              Usay Referral, whether or not such a Customer purchased insurance; and
            </li>
            <li>
              The Introducer shall be prohibited from marketing to that Customer any insurance
              products of the same type as formed the basis of the Usay Referral, whether or not
              such a Customer purchased insurance of that type as a consequence of the Usay
              Referral;
            </li>
          </List>
          <li>Usay shall in its absolute discretion:</li>
          <List name="terms-conditions">
            <li>Be entitled to refuse to accept any Usay Referral;</li>
            <li>
              Be entitled not to proceed with an Insurance Introduction after a Usay Referral. In
              this situation the prohibition in subclause 6.7.2 shall not be applicable, although
              for the avoidance of doubt Usay may still contact such a customer;
            </li>
          </List>
          <li>
            In either circumstance set out in subclause 6.8 Usay shall have no liability to pay any
            sum to the Introducer or otherwise have any liability to the Introducer, whether in
            respect of commission or otherwise.
          </li>
        </List>
        <li>
          <strong>LEAD PURCHASE</strong>
        </li>
        <List name="terms-conditions">
          <li>
            An Introducer may purchase a Lead from the Provider in accordance with the provisions
            set out in Schedule 3 to this Agreement.
          </li>
        </List>
        <li>
          <strong>COMPLAINTS IN RESPECT OF A USAY REFERRAL OR PURCHASING A LEAD</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Each party shall be solely responsible for any complaint made against that party by a
            Customer in respect of a Usay Referral or in respect of purchasing a Lead;
          </li>
          <li>
            In the event of a complaint being made to a party which is about the actions or
            omissions of another party the party to whom the complaint is made shall act as follows:
          </li>
          <List name="terms-conditions">
            <li>
              The party shall take the name and contact details of the complainant or person acting
              on their behalf;
            </li>
            <li>
              The party shall within 1 working day of receipt forward the compliant to the other
              party, including the contact details obtained;
            </li>
            <li>
              The party shall within 5 working days of receipt explain in a final response that the
              complaint is being forwarded to the other party, and why this is being done;
            </li>
            <li>
              The party shall provide such assistance as is reasonable in respect of the complaint
              to the other party;
            </li>
            <li>
              The party shall make no admission or enter into any discussion or make any comment to
              the Customer on the complaint;
            </li>
          </List>
          <li>
            In the event of a complaint being made which is about both the party to who the
            complaint is made and another party then the party to whom the complaint is made shall
            deal with the part of the complaint that relates to that party, and follow the procedure
            at subclause 8.2 above in respect of the part of the complaint that relates to another
            party;
          </li>
          <li>
            The Introducer shall fully indemnify the Provider and/or Usay against any loss or damage
            that the Provider and/or Usay may suffer as a result of the Introducer's relationship,
            dealings, arrangements, acts or omissions with or in respect of a Customer that has been
            referred to the Provider and/or Usay or in respect of purchasing a Lead, including any
            loss or damage as a result of a Customer's complaint;
          </li>
          <li>
            For the avoidance of doubt subclause 8.4 above shall not make the Introducer liable for
            any loss or damage that has been primarily caused by either the Provider and/or Usay,
            for any liability that the Provider and/or Usay has to the Introducer under or for
            breach of this Agreement, or for any complaint upheld by the Provider and/or Usay that
            relates to the actions of the Provider and/or Usay.
          </li>
        </List>
        <li>
          <strong>DISCLOSURE OF COMMISSIONS</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall ensure that it complies with all legal and/or regulatory
            obligations to disclose to Customers the Introducer's commission received under this
            Agreement and/or received from any Insurer as a result of an introduction made using the
            Portal.
          </li>
        </List>
        <li>
          <strong>REGULATORY AND LEGAL REQUIREMENTS</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall at all material times comply with all legal and regulatory
            requirements, including under Financial Services Legislation, that it is subject to,
            including but without limitation by virtue of entering into this Agreement, using the
            Portal, making a Usay Referral, or purchasing a Lead;
          </li>
          <li>
            Nothing in this Agreement shall require any of the Introducer, the Provider and/or Usay
            to act in any way that is contrary to any legal and regulatory requirements that it may
            have, including under Financial Services Legislation, save that this clause may not be
            relied upon to allow any party to avoid the payment of any money to another party that
            would otherwise be due under this Agreement;
          </li>
          <li>
            By entering into this Agreement the Introducer represents and warrants to both the
            Provider and Usay that it is not aware of any material fact that:
          </li>
          <List name="terms-conditions">
            <li>
              Might make it illegal, contrary to any legal or regulatory requirement (including
              under Financial Services Legislation) or otherwise make it unsuitable for the
              Introducer, the Provider and/or Usay to enter into this Agreement;
            </li>
            <li>Would place it, the Provider and/or Usay in breach of this Agreement;</li>
          </List>

          <li>
            The Introducer shall immediately inform the Provider and Usay in writing on becoming
            aware of any fact that would, if it had existed at the time of entry into this
            Agreement, have fallen within subclause 10.3 above;
          </li>
          <li>
            Should the Introducer be under any doubt as to whether or not a fact is material for the
            purposes of this clause it shall treat that fact as material;
          </li>
          <li>
            No party shall accept, offer or facilitate payment, consideration or any other benefit
            which constitutes an illegal or corrupt practice contrary to any applicable anti-bribery
            legislation;
          </li>
          <li>
            All parties shall maintain on an on-going basis appropriate systems, procedures and
            controls designed to prevent the risk of bribery, money laundering and other financial
            crime;
          </li>
          <li>
            The Introducer shall fully co-operate with any enquiries made by the Provider and/or
            Usay to ensure that the Introducer is complying with this clause, such co-operation to
            include the provision of information when requested, the answering of such questions as
            the Provider and/or Usay deem appropriate, and co-operating with any audit that the
            Provider and/or Usay deem necessary or desirable. The Provider and/or Usay shall be
            entitled to take copies, at the Introducer's expense, of any information or
            documentation provided under this clause;
          </li>
          <li>
            For the avoidance of doubt nothing in this clause shall be taken to place any obligation
            on the Provider and/or Usay to ensure that the Introducer is complying with its duties
            under this Agreement and/or under any legal and regulatory requirements or to make the
            Provider and/or Usay responsible for such compliance;
          </li>
        </List>
        <li>
          <strong>RECORDS</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall maintain such records of its use of the Portal, including which
            Customer Information is entered on the Portal and its entitlement to commission, as is
            required to comply with its legal and regulatory responsibilities, including without
            limitation its obligations under Financial Services Legislation.
          </li>
        </List>
        <li>
          <strong>RESPONSIBILITY FOR INFORMATION</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall enter all Introducer Information on the Portal accurately and
            completely. Neither the Provider or Usay shall have any responsibility to verify the
            accuracy or the completeness of Introducer Information;
          </li>
          <li>
            The Provider and/or Usay accept no liability whatsoever for any loss or damage,
            howsoever caused, suffered by the Introducer as a result of the Introducer failing to
            enter Introducer Information accurately or completely on the Portal;
          </li>
          <li>
            The Provider provides access to Information on the Portal which it believes to be
            reliable and accurate at its date of publication. Neither the Provider or Usay makes any
            independent verification of Information. The Introducer accepts and agrees that access
            to and use of the Information is entirely at the Introducer's and/or Customer's own risk
            and the Introducer shall ensure that any Customer accepts and agrees to this risk.
          </li>
        </List>
        <li>
          <strong>CONFIDENTIALITY</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Subject to subclause 13.4 below no party shall at any time after the date of this
            Agreement:
          </li>
          <List name="terms-conditions">
            <li>
              Divulge or communicate to any person, company, business entity or other organisation;
            </li>
            <li>
              Use for its own purposes or for any purposes other than those of another party; or
            </li>
            <li>
              Through any failure to exercise due care and diligence, cause any unauthorised
              disclosure of any Confidential Information of the other party.
            </li>
          </List>
          <li>
            Subject to subclause 13.4 each party shall protect any Confidential Information of the
            other party with the same degree of care as it employs with regard to its own
            Confidential Information of a like nature and in any event in accordance with best
            current commercial security practices;
          </li>
          <li>
            The restrictions in subclauses 13.1 and 13.2 shall cease to apply to any information
            which shall become available to the public generally otherwise than through a breach of
            a duty of confidentiality owed to another party;
          </li>
          <li>The restrictions in subclauses 13.1 and 13.2 shall not apply to:</li>
          <List name="terms-conditions">
            <li>
              Any information which was already available to the public generally otherwise than
              through a breach of a duty of confidentiality owed to another party;
            </li>
            <li>Any act done with the consent of the party who's information is confidential;</li>
            <li>
              Any divulgence or communication to an employee, servant or agent of the party who
              needs to know the Confidential Information, provided that it is made clear that the
              divulgence or communication is confidential and that the employee, servant or agent
              owes a duty of care in respect of the divulgence or communication;
            </li>
            <li>
              Any divulgence or communication to a party's subsidiary, holding company or subsidiary
              of its holding companies (within the meaning of section 1159 of the Companies Act 2006
              as amended) provided that the company to which disclosure is made is bound by the
              provisions of this clause;
            </li>
            <li>
              Any disclosure of Confidential Information which is required in the normal course of
              acting in accordance with that party's business;
            </li>
          </List>
          <li>
            No party shall be restricted from disclosing the Confidential Information or any part of
            it pursuant to a judicial or other lawful government order, or regulatory requirement or
            other legal requirement including under Financial Services Legislation, but only to the
            extent required by such order/requirement and subject to the party obliged to comply
            with such order/requirement giving the other parties as much notice of the terms of the
            order/requirement as may be reasonably practicable or lawful;
          </li>
          <li>
            If any party becomes aware of a breach of confidence by any person to whom it discloses
            Confidential Information it shall promptly notify the other parties and shall give any
            party all reasonable assistance in connection with proceedings which any party shall
            instigate for breach of confidence.
          </li>
        </List>
        <li>
          <strong>DATA PROTECTION</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Each party shall comply with Data Protection Legislation. In particular, the Introducer
            warrants that it has duly notified the Information Commissioner of its processing of
            Personal Data, and the Introducer shall supply a copy of such notification to the
            Provider and/or Usay on request;
          </li>
          <li>
            The Introducer acknowledges and agrees that the Provider will retain and process
            Personal Data:
          </li>
          <List name="terms-conditions">
            <li>
              Of the Introducer, employees or agents of the Introducer and of Customers for the
              purpose of administration of the Portal and maintaining records;
            </li>
            <li>
              Of the Introducer and/or (if applicable) directors of the Introducer for the purpose
              of assessing the financial standing of the Introducer and/or directors of the
              Introducer with credit reference agencies;
            </li>
            <li>Of Customers for the purpose of:</li>
            <List name="terms-conditions">
              <li>Obtaining and providing a response to requests for a quote from an Insurer;</li>
              <li>
                Placing details of the Customer on the Portal to facilitate the purchase of Leads;
              </li>
              <li>Forwarding Customer Information to Usay in the event of a Usay Referral;</li>
            </List>
          </List>
          <li>The Introducer acknowledge and agrees that Usay will retain Personal Data:</li>
          <List name="terms-conditions">
            <li>
              Of employees of the Introducer and of Customers for the purpose of maintaining
              records;
            </li>
            <li>
              Of Customers for the purpose of carrying out Insurance Introductions after a Usay
              Referral, including the provision of that Personal Data to Insurers;
            </li>
            <li>
              Of the Introducer and/or (if applicable) directors of the Introducer for the purpose
              of assessing the financial standing of the Introducer and/or directors of the
              Introducer with credit reference agencies;
            </li>
            <li>
              Of the Introducer, employees of the Introducer, if applicable directors of the
              Introducer, and Customers for the purpose of complying with any obligations that it
              has under Financial Services Legislation, including without limitation any obligation
              it has to supervise the activities of the Provider;
            </li>
          </List>
          <li>
            The Introducer acknowledge and agrees that both the Provider and Usay will hold
            information about the Introducer and Customers in electronic form;
          </li>
          <li>
            The Provider may take Information from the Portal, including Introducer Information
            and/or Customer Information, anonymise it to remove any personal data, and use that
            anonymised information for its own internal business purposes, for example for research
            purposes, business development, and/or statistical analysis. The Provider may share such
            anonymised information and/or the results of its use of that information with Usay, or
            any other member of the Provider's corporate group or an associated company.
          </li>
          <li>
            The Introducer shall ensure that it has in each case obtained all necessary consents in
            writing, from Customers or otherwise, under Data Protection Legislation to permit the
            Provider and/or Usay to process Personal Data as set out above and as set out in the
            Provider's and/or Usay's privacy policy published on their websites, and the Introducer
            shall provide copies of such consents to the Provider and/or Usay on request;
          </li>
          <li>
            Each party agrees to use all reasonable endeavours to assist each other to comply with
            any obligations imposed on them by Data Protection Legislation, including the obligation
            to comply with any subject access request;
          </li>
          <li>
            Each party shall operate reasonably adequate procedures designed to ensure compliance
            with this clause;
          </li>
          <li>
            The Provider shall not access the Portal outside of the EEA and shall ensure that no
            data downloaded from the Portal is taken outside of the EEA;
          </li>
          <li>
            Where the Introducer requests that the Provider enters into a Network Access Agreement
            the Introducer shall ensure that it has in each case obtained all necessary consents in
            writing, from Customers or otherwise, under Data Protection Legislation to permit the
            Provider to enter into a Network Access Agreement and to allow the network access to the
            information on the Portal and the Introducer shall provide copies of such consents to
            the Provider on request.
          </li>
          <li>
            In so far as the Provider processes data on behalf of the Introducer under this
            Agreement the provisions of Schedule 4 shall apply.
          </li>
        </List>
        <li>
          <strong>DATA SECURITY</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Each party agrees to maintain appropriate data security measures including but not
            limited to firewalls, anti-virus/anti-malware, server back up, patch management,
            software updates and physical security measures.
          </li>
        </List>
        <li>
          <strong>PROVIDER AND USAY WARRANTIES AND LIMITATION OF LIABILITY</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider warrants that it has the right to grant the licence as set out in Schedule
            2 to this Agreement;
          </li>
          <li>
            Any other warranties from the Provider (if any) in respect of the provision of the
            Portal are set out in the Licence;
          </li>
          <li>
            The Provider shall have no liability to the Introducer for any loss or damage caused or
            suffered by the Introducer, save to the extent that it is caused by a breach of the
            warranty set out in subclause 16.1, a warranty (if any) contained in the Licence, or
            such liability is provided for under subclause 16.5 below;
          </li>
          <li>
            Subject to the following provisions Usay warrants that it will provide its obligations
            under this Agreement with reasonable care and skill;
          </li>
          <li>
            The Provider shall have no liability in respect of the purchase of Leads, other than to
            replace or refund a Lead as provided for in Schedule 3;
          </li>
          <li>
            Usay makes no warranties, and shall have no liability, in respect of any loss or damage
            caused or suffered by the Introducer as a result of its use of the Portal, save insofar
            as such loss or damage is caused by the breach of warranty set out in subclause 16.4
            above;
          </li>
          <li>
            Neither the Provider or Usay provides any warranties for the accuracy of Information on
            the Portal and neither the Provider or Usay shall have any liability for any loss or
            damage caused or suffered by inaccurate Information;
          </li>
          <li>
            The Portal is provided on an “as is” basis and neither the Provider or Usay shall have
            any liability for any loss or damage should the Portal be inaccessible or unavailable
            for whatever reason;
          </li>
          <li>Neither the Provider or Usay shall have any liability to the Introducer for:</li>
          <List name="terms-conditions">
            <li>Any indirect or consequential loss or damage;</li>
            <li>Any loss of data (unless the Licence provides otherwise);</li>
            <li>Any loss or damage to property (unless the Licence provides otherwise);</li>
            <li>Any loss or damage relating to a claim by another party to this Agreement;</li>
          </List>
          <li>
            In the event that either the Provider or Usay accepts any liability under this Agreement
            such liability will not exceed:
          </li>
          <List name="terms-conditions">
            <li>The sum of £1,000 for any individual incident;</li>
            <li>The total of £10,000 for any number of incidents in any calendar year;</li>
          </List>
          <li>
            Except as expressly set out in this Agreement, all warranties, undertakings, conditions
            and terms, express or implied, whether by common law, statute, custom, trade usage,
            course of dealings or otherwise (including without limitation as to quality, fitness for
            purpose, performance or suitability for purpose) in respect of the performance of this
            Agreement by the Provider and Usay and the accuracy and completeness of the information
            provided through the Portal are hereby excluded to the fullest extent permitted by law;
          </li>
          <li>
            Nothing in this clause shall limit or exclude either the Provider or Usay's liability
            for death or personal injury caused by its negligence, for its fraud or the fraud of its
            employees, servants or agents, for any liability that cannot be excluded under Financial
            Services Legislation, or for any other liability that it cannot lawfully exclude.
          </li>
        </List>
        <li>
          <strong>INDEMNITIES</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer will indemnify both the Provider and Usay against any and all loss and
            damage incurred by them as a result of:
          </li>
          <List name="terms-conditions">
            <li>The Introducer breaching this Agreement;</li>
            <li>The Introducer breaching the Licence;</li>
            <li>A Customer of the Introducer using the Portal;</li>
            <li>
              Any use of Information by the Introducer, or a Customer of the Introducer, or any
              other third party who has obtained the Information as a result of the actions of the
              Introducer, whether directly or indirectly;
            </li>
            <li>An Introducer or Customer misusing any third party links on the Portal.</li>
          </List>
        </List>
        <li>
          <strong>TERMINATION</strong>
        </li>
        <List name="terms-conditions">
          <li>This Agreement shall continue until terminated under this clause;</li>
          <li>Any party may terminate this Agreement:</li>
          <List name="terms-conditions">
            <li>
              On 14 days written notice to the other parties should another party be in material
              breach of this Agreement and that other party fails, in respect of a breach capable of
              being remedied, within 14 days after the receipt of a request in writing from the
              notifying party to do so, to remedy the breach;
            </li>
            <li>
              Immediately on giving written notice should an interim order be made, or a voluntary
              arrangement approved, or if a petition for bankruptcy order is presented or a
              bankruptcy order is made against another party or if a receiver or trustee is
              appointed of another party's estate or a voluntary arrangement is approved or an
              administration order is made, or a receiver or administrative receiver is appointed
              over any of another party's assets or undertaking or a resolution or petition to wind
              up another party is passed or presented (otherwise than for the purposes of
              reconstruction or amalgamation), or if any circumstances arise which entitle the Court
              or a creditor to appoint a receiver, administrative receiver or administrator or to
              present a winding up petition or make a winding up order;
            </li>
            <li>On giving 90 days written notice to the other parties.</li>
          </List>
          <li>
            the Provider and/or Usay may immediately terminate this Agreement by giving written
            notice to the other parties immediately should:
          </li>
          <List name="terms-conditions">
            <li>
              the Introducer inform the Provider and/or Usay of any material fact pursuant to
              subclause 10.4 above;
            </li>
            <li>
              either the Provider and/or Usay reasonably suspect that the Introducer is in breach of
              clauses 2, 4 or 10; or
            </li>
            <li>
              the Provider and/or Usay be of the reasonable opinion that the Introducer has in any
              way damaged or may damage the reputation or business of the Provider and/or Usay;
            </li>
          </List>
          <li>Upon termination:</li>
          <List name="terms-conditions">
            <li>
              Each party shall pay to any other party any sums due up to the date of termination;
            </li>
            <li>
              In accordance with the terms of the Licence the Introducer's right to use the Portal
              will end;
            </li>
            <li>
              The Introducer shall, at either Usay's or the Provider's discretion, either return or
              destroy all material that contains Confidential Information of either the Provider or
              Usay (save for any material that has come into the public domain other than by a
              breach of a duty of confidentiality by the Introducer), or any material that contains
              the Provider or Usay's Intellectual Property Rights;
            </li>
          </List>
          <li>
            The obligations of the parties under clauses 4.3, 6.7, 8, 9, 11, 12, 13, 16.3, 16.5 to
            16.12, 17, 18.4, 18.5, 20, 22, 26, 27, 28, 29, 31, 34, 35 shall survive the termination
            of this Agreement.
          </li>
        </List>
        <li>
          <strong>NOTICES</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Unless a clause of this Agreement provides otherwise all notices under this Agreement
            shall be in writing and must be in English;
          </li>
          <li>
            Unless a clause of this Agreement provides otherwise notices shall be deemed to have
            been duly given:
          </li>
          <List name="terms-conditions">
            <li>
              When delivered, if delivered by courier or other messenger (including registered mail)
              during normal business hours of the recipient; or
            </li>
            <li>
              When sent, if transmitted by fax or email and a successful transmission report or
              return receipt is generated; or
            </li>
            <li>
              On the fifth business day of the sender following mailing, if mailed by national
              ordinary mail, postage prepaid; or
            </li>
            <li>
              On the tenth business day of the sender following mailing, if mailed by airmail,
              postage prepaid;
            </li>
          </List>
          <P>
            in each case addressed to the most recent address, email address, or facsimile number
            notified to the other party.
          </P>
        </List>
        <li>
          <strong>NON-POACHING OF STAFF OR CONTACTING SUPPLIERS</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer covenants with the Provider and Usay that it shall not either during the
            term of this Agreement or within a period of 6 months thereafter:
          </li>
          <List name="terms-conditions">
            <li>
              directly or indirectly entice away or endeavour to entice away from the Provider or
              Usay any person who has during the previous 12 months been employed by the Provider or
              Usay to perform this Agreement;
            </li>
            <li>
              attempt to purchase any data from any supplier of data to the Provider or Usay, where
              that company has supplied data to the Provider or Usay within the pervious 12 months
              and where the Introducer has either become aware of the supplier through the use of
              this Portal or through this Agreement.
            </li>
          </List>
        </List>
        <li>
          <strong>NATURE OF RELATIONSHIP</strong>
        </li>
        <List name="terms-conditions">
          <li>
            This Agreement shall not constitute or imply any partnership, joint venture, agency,
            fiduciary or other relationship between the parties other than the contractual
            relationship expressly provided for in this Agreement;
          </li>
          <li>
            The Introducer shall not use the Portal in a manner that implies or otherwise
            misrepresents that any act of the Introducer is approved or endorsed by the Provider or
            Usay.
          </li>
        </List>
        <li>
          <strong>ANNOUNCEMENTS</strong>
        </li>
        <List name="terms-conditions">
          <li>
            No party shall issue or make any public announcement or disclose any information
            regarding this Agreement unless prior written consent has been obtained from the other
            parties.
          </li>
        </List>
        <li>
          <strong>AMENDMENTS</strong>
        </li>
        <List name="terms-conditions">
          <li>
            This Agreement may not be released, discharged, supplemented, interpreted, amended,
            varied or modified in any manner except by:
          </li>
          <List name="terms-conditions">
            <li>
              an instrument in writing signed by a duly authorised officer or representative of each
              party; or
            </li>
            <li>
              the Provider, by publishing such amendments on the Portal, provided that the Provider
              shall draw the fact that the Agreement has been amended to the attention of the
              Introducer when the Introducer next accesses the Portal, and shall invite the
              Introducer to consent to such amendments by a suitable method, for example the
              completion of an online tick box. The amendments shall take affect from the Introducer
              giving such consent, and all parties (including Usay) hereby expressly agree that any
              amendments consented to in such a manner shall be binding on them. Should the
              Introducer not agree to such amendments the Provider shall not be required to allow
              the Introducer further access to the Portal.
            </li>
          </List>
        </List>
        <li>
          <strong>ENTIRE AGREEMENT</strong>
        </li>
        <List name="terms-conditions">
          <li>
            This Agreement supersedes all prior agreements, arrangements and undertakings between
            the Introducer and either the Provider or Usay and constitutes the entire agreement
            between the them relating to its subject matter. The parties confirm that they have not
            entered into this Agreement on the basis of any representation that is not expressly
            incorporated into this Agreement.
          </li>
        </List>
        <li>
          <strong>ASSIGNMENT</strong>
        </li>
        <List name="terms-conditions">
          <li>
            This Agreement is personal to the Introducer and the Introducer may not assign or
            transfer this Agreement nor any rights, licences or obligations under it without the
            prior written approval of the other parties;
          </li>
          <li>
            The Provider and/or Usay may assign their rights and licences and transfer their
            obligations under this Agreement without the consent of the Introducer.
          </li>
        </List>
        <li>
          <strong>SUCCESSORS AND ASSIGNEES</strong>
        </li>
        <List name="terms-conditions">
          <li>
            This Agreement shall be binding upon, and inure to the benefit of, the parties and their
            respective successors and permitted assignees, and references to a party in this
            Agreement shall include its successors and permitted assignees;
          </li>
          <li>In this Agreement references to a party include references to a person:</li>
          <List name="terms-conditions">
            <li>
              Who for the time being is entitled (by assignment, novation or otherwise) to that
              party's rights under this Agreement (or any interest in those rights); or
            </li>
            <li>
              Who, as administrator, liquidator or otherwise, is entitled to exercise those rights;
            </li>
          </List>
          <P>
            and in particular those references include a person to whom those rights (or any
            interest in those rights) are transferred or pass as a result of a merger, division,
            reconstruction or other reorganisation involving that party. For this purpose,
            references to a party's rights under this Agreement include any similar rights to which
            another person becomes entitled as a result of a novation of this Agreement.
          </P>
        </List>
        <li>
          <strong>WAIVER</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Unless either the Provider or Usay expressly waives its rights in writing, no delay,
            neglect or forbearance by the Provider or Usay in enforcing against the Introducer any
            term or condition of this Agreement shall either be or be deemed to be a waiver or in
            any way prejudice any right of the Provider or Usay under this Agreement. No right,
            power or remedy in this Agreement conferred upon or reserved for the Provider or Usay is
            exclusive of any other right, power or remedy available to that party.
          </li>
        </List>
        <li>
          <strong>SEVERANCE</strong>
        </li>
        <List name="terms-conditions">
          <li>
            If any provision of this Agreement is or becomes prohibited by law or is judged by a
            court to be unlawful, void or unenforceable, the provision shall, to the extent
            required, be severed from this Agreement and rendered ineffective as far as possible
            without modifying the remaining provisions of this Agreement, and shall not in any way
            affect any other circumstances of or the validity or enforcement of the remainder of
            this Agreement.
          </li>
        </List>
        <li>
          <strong>FORCE MAJEURE</strong>
        </li>
        <List name="terms-conditions">
          <li>
            No party shall have any liability under or be deemed to be in breach of this Agreement
            for any delays or failures in performance of this Agreement which result from
            circumstances beyond the reasonable control of that party. If such circumstances
            continue for a continuous period of more than 3 months, any non-affected party may
            terminate this Agreement by written notice to the other parties.
          </li>
        </List>
        <li>
          <strong>TIME OF THE ESSENCE</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Time shall be of the essence in this Agreement as regards any time, date or period
            mentioned in this Agreement or subsequently substituted as a time, date or period by
            agreement in writing between the parties.
          </li>
        </List>
        <li>
          <strong>SET-OFF</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer may not set off any sums that are owed to it by either the Provider or
            Usay, whether under this Agreement or otherwise, against any liability that the
            Introducer has to such a party;
          </li>
          <li>
            Where either the Provider or Usay has a liability to the Introducer, whether under this
            Agreement or otherwise, and whether such liability is liquidated or unliquidated, that
            party may set off the amount of such liability against any sum that would otherwise be
            due from the Introducer under this Agreement.
          </li>
        </List>
        <li>
          <strong>LANGUAGE</strong>
        </li>
        <List name="terms-conditions">
          <li>
            This Agreement is made only in the English language. If there is any conflict in the
            meaning between the English language version of this Agreement and any version or
            translation of it in any other language, the English language version shall prevail.
          </li>
        </List>
        <li>
          <strong>COSTS AND EXPENSES</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Each party shall bear its own legal costs and other costs and expenses arising in
            connection with the drafting, negotiation, execution and registration (if applicable) of
            this Agreement.
          </li>
        </List>
        <li>
          <strong>THIRD PARTIES</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Without prejudice to any right or remedy of a third party which exists or is available
            apart from such Act, a person who is not a party to this Agreement has no right under
            the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Agreement.
            The parties to this Agreement do not require the consent of any third party to
            terminate, rescind or to agree any variation, waiver or settlement in relation to it.
          </li>
        </List>
        <li>
          <strong>PROPER LAW AND JURISDICTION</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The parties agree that the place of performance of this Agreement is England. This
            Agreement and all matters arising from it and any dispute resolutions referred to below
            shall be governed by and construed in accordance with English law notwithstanding the
            conflict of law provisions and other mandatory legal provisions save that:
          </li>
          <List name="terms-conditions">
            <li>
              The Provider and/or Usay shall have the right to sue to recover its loss or damage in
              any jurisdiction in which the Introducer is operating or has assets; and
            </li>
            <li>
              The Provider and/or Usay shall have the right to sue for breach of its Intellectual
              Property Rights and other proprietary information (whether in connection with this
              Agreement or otherwise) in any country where it believes that infringement or a breach
              of this Agreement relating to its Intellectual Property Rights and/or other
              proprietary information might be taking place;
            </li>
          </List>
          <li>
            Each party recognises that the other party's business relies upon the protection of its
            Intellectual Property Rights and that in the event of a breach or threatened breach of
            its Intellectual Property Rights, the other party will be caused irreparable damage and
            such other party may therefore be entitled to injunctive or other equitable relief in
            order to prevent a breach or threatened breach of its Intellectual Property Rights;
          </li>
          <li>
            With respect to all other disputes which are not disputes about Intellectual Property
            Rights the following procedures in subclauses 35.4 to 35.5 shall apply;
          </li>
          <li>
            Where there is a dispute the aggrieved party shall notify the other parties in writing
            of the nature of the dispute with as much detail as possible about the deficient
            performance of the other party or parties. A representative from senior management of
            each party ('representatives') shall meet in person or communicate by telephone within 5
            business days of the date of the written notification in order to reach an agreement
            about the nature of the deficiency and the corrective action to be taken by each party.
            The representatives shall produce a report about the nature of the dispute in detail to
            their respective boards and if no agreement is reached on corrective action, then the
            chief executives of each party shall meet in person or communicate by telephone, to
            facilitate an agreement within 5 business days of a written notice by one to the other.
            If the dispute cannot be resolved at board level within a further 5 business days, or if
            the agreed upon completion dates in any written plan of corrective action are exceeded,
            any party may seek its legal remedies as provided below. Where the Introducer is not a
            body corporate references in this clause to “board” and “chief executive” shall be to
            their nearest equivalent;
          </li>
          <li>
            If the parties cannot resolve a dispute in accordance with the procedure in subclause
            35.4 above, then the dispute shall be promptly referred to an independent party to act
            as arbitrator. The arbitrator shall be jointly appointed by the parties or, in the
            default of agreement within 14 days of one party first notifying the other parties of a
            proposed arbitrator, by the President of the Institute of Chartered Accountants in
            England and Wales on application of any party, who shall finally appoint an independent
            accountant to resolve the dispute. Save in the event of manifest error, the independent
            arbitrator's decision shall be final and binding upon the parties;
          </li>
          <li>
            While the dispute resolution procedure above is in progress and any party has an
            obligation to make a payment to the other party or to allow a credit in respect of such
            payment, the sum relating to the matter in dispute shall be paid into an interest
            bearing deposit account to be held in the name of the relevant party at a clearing bank
            and such payment shall be a good discharge of that party's payment obligations under
            this Agreement. Following resolution of the dispute the sum held in such account shall
            be payable as determined in accordance with the resolution, and the interest accrued
            shall be allocated between the parties pro rata according to the split of the principal
            sum as between the parties.
          </li>
        </List>
        <li>
          <strong>SIGNING</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider shall provide the Agreement to the Introducer for electronic execution.
            Such provision may but shall not have to be via the Portal. Such provision shall
            constitute an offer by the Provider and Usay to enter into this Agreement with the
            Introducer;
          </li>
          <li>
            The Introducer shall execute the Agreement electronically by following such instructions
            as are given to it by the Provider in order to so execute the Agreement electronically;
          </li>
          <li>
            This Agreement shall be binding upon all parties upon being electronically executed by
            the Introducer.
          </li>
        </List>
      </List>
      <H2>SCHEDULE 1 - COMMISSIONS</H2>
      <List name="terms-conditions">
        <li>
          <strong>Usay Referral Commission</strong>
        </li>
        <List name="terms-conditions">
          <li>
            For each Usay Referral that Usay receives from the Introducer that results in Usay
            obtaining commission from an Insurer Usay shall pay to the Introducer 60% of the amount
            of commission (net of all applicable taxes) that the Introducer would have received from
            the Insurer, had the Introducer rather than Usay introduced the policy directly to the
            Insurer;
          </li>
          <li>
            Commission shall only be payable once, for the initial Usay Referral. No further
            commission in respect of a Usay Referral shall be payable to the Introducer irrespective
            of whether or not Usay receives such further commission from the Insurer (for example
            upon renewal of an insurance policy);
          </li>
          <li>The payment of Commission shall either be on:</li>
          <List name="terms-conditions">
            <li>the “indemnity basis”, in which case paragraph 2 below applies; or</li>
            <li>the “non-indemnity basis”, in which case paragraph 3 below applies.</li>
          </List>
          <li>
            Usay will notify the Introducer at the time of entry into this Agreement of the basis
            for the payment of Commission. In the absence of such notification the non-indemnity
            basis will apply;
          </li>
          <li>
            Usay may change the basis of the payment of Commission at any time by giving notice to
            the Introducer, in which case the former basis shall apply to all Insurance
            Introductions where Usay receives commission from an insurer prior to the notification,
            and the latter basis shall apply to all Insurance Introductions where Usay receives
            commission from an insurer after such notification.
          </li>
        </List>
        <li>
          <strong>The Indemnity Basis</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Subject to paragraphs 2.2 and 2.3 below Commission will be due in full once the Customer
            has made 3 monthly instalment payments under the contract of insurance that was the
            subject of the Usay Referral;
          </li>
          <li>
            In the event of Usay having to refund any commission to an Insurer, whether in full or
            in part, the Introducer shall refund to Usay the full or an equivalent pro rata (as the
            case may be) amount of the commission that was paid to the Introducer by Usay in respect
            of the commission that was refunded;
          </li>
          <li>
            In the event that the Customer does not make 3 monthly instalment payments under the
            contract of insurance, but Usay still receives commission from the Insurer which is not
            repayable to the Insurer, then the Introducer's commission under paragraph 1.1 shall be
            reduced by an equivalent pro rata amount to the reduction in the amount of commission
            that Usay receives from the Insurer (if any). Such commission shall be due on the date
            that would have been the date of the 3rd monthly payment under the contract of insurance
            that was the subject of the Usay Referral, had such payment occurred;
          </li>
          <li>
            For each calendar month and within 30 days of the end of the calendar month Usay shall
            aggregate the total of all commissions due to the Introducer under paragraphs 2.1 and
            2.3 above and deduct from that amount all refunds payable by the Introducer pursuant to
            paragraph 2.2. In the event that this calculation results in a sum payable to the
            Introducer Usay shall pay such sum to the Introducer in a single payment. In the event
            that this calculation results in a sum payable to Usay Usay shall notify the Introducer
            of the sum owing in writing, and the Introducer shall pay this sum in a single payment
            to Usay within 14 days of receipt of this notice.
          </li>
        </List>
        <li>
          <strong>The Non Indemnity Basis</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The amount of commission due under paragraph 1.1 shall be divided into twelfths and each
            twelfth shall be due as follows:
          </li>
          <List name="terms-conditions">
            <li>
              The first 3 twelfths (so 1/4 of the total commission) shall be due once the Customer
              has made 3 monthly instalment payments under the contract of insurance that was the
              subject of the Usay Referral;
            </li>
            <li>
              The 4th twelfth shall be due once the Customer has made the 4th monthly instalment
              payment under the contract of insurance that was the subject of the Usay Referral;
            </li>
            <li>
              The 5th twelfth shall be due once the Customer has made the 5th monthly instalment
              payment under the contract of insurance that was the subject of the Usay Referral;
            </li>
            <li>
              Each subsequent twelfth shall be due on the same basis, with the twelfth falling due
              once the Customer makes the corresponding monthly instalment payment, until all
              twelfths have been paid;
            </li>
            <li>
              For the avoidance of doubt if any monthly instalment is not made by the Customer then
              no commission is payable in respect of that monthly instalment, and the amount of
              commission payable under paragraph 1.1 shall be reduced accordingly;
            </li>
          </List>
          <li>
            For each calendar month and within 30 days of the end of the calendar month Usay shall
            aggregate the total of all commissions due to the Introducer under paragraph 3.1 and
            shall pay such sum to the Introducer in a single payment;
          </li>
          <li>
            In the event that the Customer does not make 3 monthly instalment payments under the
            contract of insurance, but Usay still receives commission from the Insurer which is not
            repayable to the Insurer, then the Introducer's commission under paragraph 1.1 shall be
            reduced by an equivalent pro rata amount to the reduction in the amount of commission
            that Usay receives from the Insurer (if any). . Such commission shall be due on the date
            that would have been the date of the 3rd monthly payment under the contract of insurance
            that was the subject of the Usay Referral, had such payment occurred.
          </li>
        </List>
      </List>
      <H2>SCHEDULE 2 - PORTAL LICENCE</H2>
      <List name="terms-conditions">
        <li>
          <strong>Definitions</strong>
        </li>
        <List name="terms-conditions">
          <li>In this Licence, any words defined in the Agreement shall have the same meaning.</li>
        </List>
        <li>
          <strong>Licence</strong>
        </li>
        <List name="terms-conditions">
          <li>The use of the Portal is subject to this Licence;</li>
          <li>
            The Licence shall commence on the date of the Agreement and continue until the Agreement
            is terminated, unless otherwise suspended or terminated under the terms of the
            Agreement.
          </li>
        </List>
        <li>
          <strong>Access</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall access the Portal by visiting the web address as communicated to
            the Introducer by the Provider and registering on the Portal as further provided for
            below;
          </li>
          <li>
            The Introducer shall follow any reasonable instructions that the Provider may give to
            the Introducer for access to the Portal;
          </li>
          <li>
            It is the Introducer's responsibility, at its own cost, to ensure that it has suitable
            equipment and facilities that can access the Portal (such as an internet connection) and
            that such equipment and facilities are compatible with the Portal. The Provider shall
            have no responsibility for the Introducer's inability to access the Portal as a result
            of such equipment and facilities not being suitable;
          </li>
          <li>
            The Provider will use reasonable efforts to ensure that the Portal is available at all
            times. However the Provider cannot guarantee that the Portal or any individual function
            or feature of the Portal will always be available and/or error free. In particular, and
            without limitation to the above, the Provider may restrict the Introducer's access to
            the Portal, or to part of it, and whether temporarily or permanently, in the following
            circumstances:
          </li>
          <List name="terms-conditions">
            <li>
              The Provider has reason to suspect that the Introducer's username and/or password are
              being used by a person who is not authorised to use it/them;
            </li>
            <li>To carry out maintenance or upgrade work to the Portal;</li>
            <li>
              The Provider has reason to suspect that the Introducer is acting in breach of the
              Agreement or this Licence;
            </li>
            <li>
              The Provider has reason to suspect that the Portal is being used fraudulently or for
              any unlawful purpose;
            </li>
          </List>
          <li>
            The Introducer's right to access the Portal is personal to it and may not be
            transferred.
          </li>
          <li>
            The Provider may, but is under no obligation to, make available technical support for
            the Portal. Where such technical support is available then in the event of the
            Introducer being unable to access or utilise, whether fully or in part, the Portal or in
            the event of a technical problem with the Portal the Introducer shall, in the first
            instance, contact such technical support. The Introducer shall fully co-operate with the
            technical support, including following any reasonable instructions from such technical
            support;
          </li>
          <li>
            The Provider does not warrant that any technical support provided under paragraph 3.6
            above shall remedy or assist with any technical issue.
          </li>
          <li>
            The technical support provided under paragraph 3.6 above it provided on a best
            endeavours basis and the Provider does not provide any warranty that the technical
            support shall be provided with reasonable care and skill, and does not accept any
            liability for any loss or damage caused by the technical support, subject to paragraph
            3.9 below.
          </li>
          <li>
            Where any technical support is provided negligently and that technical support results
            in either damage to the Introducer's computer equipment or loss of the Introducer's data
            the Provider shall accept liability for such loss subject to the limits set out in the
            Agreement.
          </li>
        </List>
        <li>
          <strong>Users</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall register on the Portal and create a username and password for each
            user that shall access the Portal;
          </li>
          <li>
            The password created pursuant to paragraph 4.1 above shall comply with any requirements
            that the Provider shall stipulate as content, and the Introducer shall ensure that each
            user shall regularly change the password;
          </li>
          <li>
            The Introducer shall ensure that only authorised users are given a username and password
            and that such users do not divulge the username and password to any other person;
          </li>
          <li>
            Any acts by any person with a correct username and password shall be deemed to be done
            with the authority of the Introducer;
          </li>
          <li>
            The Introducer shall notify the Provider immediately if the Introducer becomes aware of
            any unauthorised use of a username and password or other unauthorised access to the
            Portal.
          </li>
        </List>
        <li>
          <strong>Use</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall only use the Portal in a manner that is compliant with the
            Agreement and this Licence and for the purposes of conducting business in accordance
            with the Agreement;
          </li>
          <li>
            The Introducer shall not do any act or allow any act to occur, including by its use of
            the Portal, that will or may have the result of disrupting access to or the availability
            of the Portal to Users or to Insurers that use the Portal;
          </li>
          <li>
            The Introducer shall use the Portal in a professional manner and shall not send or
            receive any message or communication which is defamatory, insulting, offensive, abusive,
            indecent, obscene or menacing or likely to cause annoyance, inconvenience or needless
            anxiety or for any improper purposes;
          </li>
          <li>The Introducer shall not use the Portal in a manner that is unlawful or illegal;</li>
          <li>
            The Introducer shall at all times use and maintain commercial virus software on any
            equipment which it uses to access the Portal, such virus software to be kept up to date
            and to be at least of a standard that would be expected to be maintained by a commercial
            entity using the internet to conduct its business affairs;
          </li>
          <li>
            The Introducer shall not reproduce, duplicate, copy, sell, resell, or otherwise exploit
            for any commercial purposes, any portion of, use of, or access to the Portal;
          </li>
          <li>
            The Introducer shall not transmit or otherwise make available in connection with the
            Portal any virus, worm, Trojan horse or other computer code that is harmful or invasive
            or may or is intended to damage the operation of, or to monitor the use of, any
            hardware, software, or equipment;
          </li>
          <li>
            The Introducer shall not translate, adapt, vary, modify, disassemble, decompile or
            reverse engineer the Portal without the Provider's prior written consent;
          </li>
          <li>
            The Introducer shall not remove any copyright, trade mark or other proprietary rights
            notice from the Portal or materials originating from the Portal;
          </li>
          <li>
            The Introducer shall not create a database by systematically downloading and storing
            Portal content;
          </li>
          <li>
            The Introducer shall not frame or mirror any part of the Portal without the Provider's
            express prior written consent;
          </li>
          <li>
            The Introducer shall not use any manual or automatic device in any way to gather Portal
            content or reproduce or circumvent the navigational structure or presentation of the
            Portal without the Provider's express prior written consent;
          </li>
          <li>
            The Introducer shall not amend or modify any documents provided by the Portal and shall
            not manipulate any information contained in such documents;
          </li>
          <li>
            The Introducer shall not duplicate any material obtained through the Portal without the
            prior written consent of the Provider, other than in connection with the ordinary
            transaction of business through the Portal as envisaged by the Agreement.
          </li>
        </List>
        <li>
          <strong>Customer Use</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer may allow Customers to view the Portal for the purpose of reviewing
            Customer Information submitted to the Portal on their behalf and for the purpose of
            explaining to the Customer the actions that the Introducer and the Provider is carrying
            out on the Customer's behalf;
          </li>
          <li>The Customer shall not be entitled to access or use the Portal.</li>
        </List>
        <li>
          <strong>External Content</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Portal may provide links to other websites. The Provider is not responsible for and
            does not endorse such external websites. The Introducer's use of third party websites it
            at its own risk.
          </li>
        </List>
        <li>
          <strong>Property and Intellectual Property Rights</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Portal and the Intellectual Property Rights of whatever nature in the Portal, and in
            the material on the Portal, are and shall remain the property of the Provider;
          </li>
          <li>
            The Introducer may only use and reproduce material (including all documents, databases,
            information, names and logos) accessed through the Portal in accordance with the
            Agreement and this Licence.
          </li>
        </List>
        <li>
          <strong>Amendment</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider may from time to time amend the terms of this Licence. The Provider may
            notify the Introducer of such amendments by any reasonable means, including by posting
            the revised version of this Licence on the Portal. The Introducer's use of the Portal
            following changes to this Licence will constitute its acceptance of the changes.
          </li>
        </List>
        <li>
          <strong>Warranties and Limitation of Liability</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The internet is inherently unreliable and the Provider shall have no liability to the
            Introducer for any loss or damage caused by hardware or software failures outside of its
            control;
          </li>
          <li>
            The Portal is provided 'as is' and the Provider makes no representation as to the
            quality, completeness or accuracy of any content on the Portal. To the maximum extent
            permitted by law, but subject to subclause 3.9 and 10.4, the Provider expressly
            excludes:
          </li>
          <List name="terms-conditions">
            <li>
              All conditions, warranties and other terms that might otherwise be implied by law into
              this Licence; and
            </li>
            <li>
              Any and all liability to the Introducer, whether arising under this Licence or
              otherwise, in connection with the Introducer's access to and use of the Portal;
            </li>
          </List>
          <li>
            The limitation of liability in this clause applies to all damage of any kind, including
            without limitation compensatory, direct, indirect or consequential damages, loss of
            data, income or profit, loss or damage to property and claims of the parties;
          </li>
          <li>
            Notwithstanding anything in this clause to the contrary, nothing in this Licence shall
            limit the Provider's liability to the Introducer for its fraud or the fraud of its
            employees, servants or agents, or for death or personal injury caused by its negligence,
            or for any liability that may not by law be excluded or limited;
          </li>
          <li>This paragraph 10 shall survive termination of the Licence.</li>
        </List>
      </List>
      <H2>SCHEDULE 3 -LEAD PURCHASE</H2>
      <List name="terms-conditions">
        <li>
          <strong>Definitions</strong>
        </li>
        <List name="terms-conditions">
          <li>
            For the avoidance of doubt any definitions set out in the Agreement are applicable to
            this Schedule;
          </li>
          <li>
            In this Schedule the following words shall have the meaning set out below unless
            otherwise stated to the contrary:
          </li>
          <List name="terms-conditions">
            <li>
              <strong>“Insurance Intermediaries”</strong> means a person who introduces Customers to
              Insurers, and shall include but not be limited to other users of the Portal other than
              the Introducer;
            </li>
            <li>
              <strong>“Lead”</strong> means the contact details for a Customer who has consented to
              be contacted in respect of the potential purchase of a contract of insurance (which
              shall include as a minimum the Customer's name and telephone number), and the nature
              of the Lead Policy;
            </li>
            <li>
              <strong>“Lead Customer”</strong> means the Customer who's details are contained in the
              Lead;
            </li>
            <li>
              <strong>“Lead Policy”</strong> means the type of insurance policy that the Lead
              Customer wishes to obtain;
            </li>
            <li>
              <strong>“Lead Price”</strong> means the price that is payable for any particular Lead;
            </li>
            <li>
              <strong>“Reconciliation”</strong> means a document provided to the Introducer by the
              Provider which lists (i) all Leads purchased by the Introducer in the period of the
              reconciliation document together with the price for such Leads; and (ii) all Leads
              returned to the Provider by the Introducer in the period of the reconciliation
              document, together with the amount (if any) due to the Introducer from the Provider in
              respect of such returned Leads.
            </li>
          </List>
        </List>
        <li>
          <strong>Availability of Leads</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider may from time to time make Leads available on the Portal for the purchase
            by the Introducer and other Insurance Intermediaries;
          </li>
          <li>
            The availability of Leads shall be in the Provider's absolute discretion. The Provider
            shall be under no obligation to make Leads available, or to make a certain or a minimum
            number of Leads available, at any particular time;
          </li>
          <li>
            When the Provider makes a Lead available on the Portal the Provider shall describe the
            type and/or class of Lead Policy that the Lead relates to.
          </li>
        </List>
        <li>
          <strong>Purchase of Leads</strong>
        </li>
        <List name="terms-conditions">
          <li>
            An Introducer may purchase a Lead from the Provider by visiting the appropriate part of
            the Portal and following the instructions set out therein;
          </li>
          <li>
            In the event that the Introducer purchases a Lead the Provider shall grant the
            Introducer a 12 month exclusive non-transferable licence of the Lead for the purpose of
            contacting the Lead Customer and providing advice only on the Lead Policy;
          </li>
          <li>
            The Provider cannot guarantee that the Customer under a Lead has not made their details
            available elsewhere, for example to a different Insurance Intermediary or on a different
            website. It is the responsibility of the Introducer to contact the Lead Customer
            promptly whilst the Lead is fresh;
          </li>
          <li>
            If a Lead Customer wishes to purchase more than one type or class of insurance policy
            each shall be treated as a separate Lead and the Provider may make available, and sell,
            each Lead separately and to different Insurance Intermediaries;
          </li>
          <li>
            In the event that the Introducer sells more than one insurance policy to a Lead Customer
            within the period of the licence for the Lead as provided for by paragraph 3.2 above,
            regardless of the nature or type of insurance sold, then the Introducer shall, for each
            insurance policy sold other than the first, pay to the Provider an additional sum equal
            to the original Lead Price as is provided for by paragraph 4 below. Such sum(s) shall be
            payable by the Introducer to the Provider within 14 days of the sale of the insurance
            policy. For the purpose of this paragraph an insurance policy is sold whenever the
            Introducer introduces the Lead Customer to an insurer, whether directly or through a
            third party, which results in the Lead Customer entering into a contract of insurance
            with that insurer.
          </li>
        </List>
        <li>
          <strong>Payment</strong>
        </li>
        <List name="terms-conditions">
          <li>The Lead Price shall be described on the Portal;</li>
          <li>
            In the event that the Portal does not require payment in advance of the Lead Price prior
            to purchase of the Lead, or in the event that any payment of a Lead Price is ineffective
            for whatever reason, then the Introducer shall pay the Provider the Lead Price within 14
            days of receipt of a written demand for payment. Such written demand may aggregate a
            number of due Lead Prices at the same time, in which case the Introducer shall pay the
            aggregate amount in a single payment. A Reconciliation may constitute such a demand;
          </li>
          <li>
            The Provider shall provide a Reconciliation to the Introducer in respect of purchased
            Leads on a periodic basis.
          </li>
        </List>
        <li>
          <strong>Return of Leads</strong>
        </li>
        <List name="terms-conditions">
          <li>Subject to paragraph 5.5 below a Lead will be returnable if:</li>
          <List name="terms-conditions">
            <li>The Lead Customer is a minor;</li>
            <li>
              The Lead is a fraudulent Lead - where the Lead Customer's details are correct but were
              not authorised by the Customer;
            </li>
            <li>
              The Lead is a hoax Lead - where the Lead contains false, untrue or incorrect
              information relating to the Customer resulting in the Lead not being a genuine
              Customer enquiry;
            </li>
            <li>The Lead does not have at least one valid telephone number;</li>
            <li>
              The Lead has previously been supplied to the Introducer within the last 90 days;
            </li>
          </List>
          <li>
            To return a Lead the Introducer must notify the Provider within 5 business days of the
            date the Lead was delivered;
          </li>
          <li>
            When a Lead is returned to the Provider the Provider may, in its absolute discretion, be
            entitled to replace the returned Lead with an alternative Lead for the same type or
            class of insurance, or instead refund the Lead Price;
          </li>
          <li>
            If the Provider decides to refund the Lead Price it shall either, in its absolute
            discretion, do one or more of the following:
          </li>
          <List name="terms-conditions">
            <li>Set off the Lead Price against any sums that the Introducer owes to it;</li>
            <li>
              Pay the Lead Price to the Introducer within 30 days of the provision of the
              Reconciliation which lists the refund;
            </li>
          </List>
          <li>
            The Introducer is not permitted to return more than 20% of the value of the Leads
            purchased from the Provider using the Portal in the period of the applicable
            Reconciliation. For the avoidance of doubt in such circumstances neither paragraph 5.3
            or 5.4 is applicable. For the purposes of calculating the 20% the calculation shall be
            performed chronologically on a first returned basis.
          </li>
        </List>
        <li>
          <strong>Use of Leads</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Introducer shall only use a Lead for the purpose of contacting the Lead Customer
            about the Lead Policy.
          </li>
        </List>
      </List>
      <H2>SCHEDULE 4 - DATA PROCESSING</H2>
      <List name="terms-conditions">
        <li>
          <strong>Interpretation</strong>
        </li>
        <List name="terms-conditions">
          <li>In this Schedule the following words have the following meanings:</li>
          <List name="terms-conditions">
            <li>
              “Introducer Data” means data, including personal data, that relates to the Introducer,
              including details of customers of the Introducer or employees of the Introducer, and
              in respect of which the Introducer is the controller for the purposes of GDPR. It does
              not include data for which the Provider is itself the controller.
            </li>
            <li>
              “GDPR” means Regulation (EU) 2016/679 of the European Parliament and of the Council of
              27 April 2016 on the protection of natural persons with regard to the processing of
              personal data and on the free movement of such data (General Data Protection
              Regulation).
            </li>
            <li>
              “Law” means the law of the European Union or a member state of the European Union or,
              if it ceases to be a member state of the European Union, the United Kingdom.
            </li>
            <li>
              “Controller”, “Data Subject”, “Personal Data”, “Personal Data Breach” and “Processing”
              has the same meaning as it does in GDPR.
            </li>
            <li>
              “Supervisory Authority” means the Information Commissioner or any other independent
              public authority established by a Member Sate under Article 51 GDPR.
            </li>
          </List>
          <li>Any term defined in the Agreement shall have the same meaning in this Schedule.</li>
        </List>
        <li>
          <strong>Processing of Introducer Data</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Throughout the duration of the Agreement the Provider may process Introducer Data on
            behalf of the Introducer for the purpose of carrying out its obligations under the
            Agreement and in order to provide the Portal. The nature of the processing is as set out
            in the Agreement. The processing shall be in respect of the Introducer's customers
            and/or employees and may include (without limitation) names, contact details, dates of
            birth and ID numbers.
          </li>
          <li>
            The Provider shall ensure that any person employed, engaged or otherwise authorised to
            process Personal Data keeps such Personal Data confidential.
          </li>
        </List>
        <li>
          <strong>Need for written instructions</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Unless required to do so by Law the Provider shall only process Introducer Data that is
            Personal Data with the written instructions of the Introducer, including with regards to
            transfers of Personal Data to a third county or an international organisation.
          </li>
          <li>
            If The Provider is required to process Personal Data by Law without the written
            instructions of the Introducer it shall, before so processing the Personal Data, notify
            the Introducer of the legal requirement that it is subject to, unless the Law itself
            prohibits such notification.
          </li>
          <li>
            The entry into of the Agreement shall constitute the Introducer's written instruction to
            the Provider to operate the Portal and to process Personal Data as set out under the
            terms of the Agreement.
          </li>
        </List>
        <li>
          <strong>Sub-processing</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider shall not engage another person (“a Sub-processor”) to process Introducer
            Data without written authorisation of the Introducer, whether specific to general.
          </li>
          <li>
            In the event that the written authorisation under paragraph 4.1 is general, the Provider
            shall notify the Introducer of any intended changes concerning the addition or
            replacement of a Sub-processor at least 30 days prior to such changes. The Introducer
            shall notify the Provider of any objection to such change within 14 days of receipt of
            such notification, in which case the Provider shall not so engage the Sub-processor.
          </li>
          <li>
            The Provider shall not engage a Sub-processor unless the Sub- processor is subject to
            the same contractual obligations as the Provider is under pursuant to this Schedule.
          </li>
          <li>
            The appointment of a Sub-processor shall not remove, lessen or otherwise effect the
            Provider's obligations under this Schedule and the Provider shall be liable to the
            Introducer for the failure of the Sub-processor in the performance of its obligations.
          </li>
        </List>
        <li>
          <strong>Security of Processing</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Taking into account the state of the art, the costs of implementation and the nature,
            scope, context and purposes of processing as well as the risk of varying likelihood and
            the severity for the rights and freedoms of natural persons, both the Provider and the
            Introducer shall implement appropriate technical and organisation measures to ensure a
            level of security appropriate to the risk, including where appropriate the matters set
            out in (a) to (d) of Article 32(1) of GDPR.
          </li>
          <li>
            In assessing the appropriate level of security under paragraph 5.1 above the Provider
            and the Introducer shall have regard to in particular the risks that are presented by
            processing, in particular from accidental or unlawful destruction, loss, alteration,
            unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise
            processed.
          </li>
          <li>
            Taking into account the nature of the processing under this Agreement and the
            information available to the Provider, the Provider shall assist the Introducer with its
            compliance with paragraph 5.1 above.
          </li>
        </List>
        <li>
          <strong>The Data Subject's rights</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider shall notify the Introducer if it receives any request from a Data Subject
            to exercise any of the rights in Chapter III of GDPR in respect of Introducer Data.
          </li>
          <li>
            In the event of either party receiving a request from a Data Subject to exercise any
            rights in Chapter III of GDPR each party shall in so far as it is possible assist the
            other to comply with its obligations to fulfil such a request, taking into account the
            nature of the processing being carried on under the Agreement.
          </li>
        </List>
        <li>
          <strong>PersonalDataBreach</strong>
        </li>
        <List name="terms-conditions">
          <li>
            In the event of a Personal Data Breach the Provider shall notify the Introducer without
            undue delay after having become aware of it.
          </li>
          <li>
            If the notification under paragraph 7.1 above is not made within 72 hours the Provider
            shall, when providing notification to the Introducer, state the reasons for the delay.
          </li>
          <li>Any notification under paragraph 7.1 shall:</li>
          <List name="terms-conditions">
            <li>
              describe the nature of the Personal Data Breach, including where possible the
              categories and approximate number of Data Subjects concerned and the categories and
              approximate number of personal data records concerned;
            </li>
            <li>
              communicate the name and contact details of the data protection officer or other
              contact point where more information can be obtained;
            </li>
            <li>describe the likely consequences of the Personal Data Breach;</li>
            <li>
              describe the measure taken or proposed to be taken to address the Personal Data
              Breach, including where appropriate, measures to mitigate its possible adverse
              effects.
            </li>
          </List>
          <li>
            Where it is not possible to provide the information at the same time, the information at
            paragraph 7.3 above may be provided in phases without undue further delay.
          </li>
          <li>
            The Provider shall document any Personal Data Breach, comprising the facts relating to
            the Personal Data Breach, its effects and the remedial action taken. The Provider shall
            provide such documentation on request to the Introducer or, at either the Introducer's
            or any Supervisory Authorities' request, to the Supervisory Authority.
          </li>
          <li>
            The Provider shall not communicate the Personal Data Breach to the Data Subject unless
            requested to do so by the Introducer or required to do so by Law.
          </li>
        </List>
        <li>
          <strong>Deletion of Personal Data</strong>
        </li>
        <List name="terms-conditions">
          <li>
            At the termination of this Agreement the Provider shall, at the option of the
            Introducer, either delete or return to the Introducer all Introducer Data, and delete
            any copies of Introducer Data, unless required to store such data by Law.
          </li>
          <li>
            Nothing in this Schedule shall prevent the Provider from retaining data for which it is
            the controller, or which it is processing on behalf of another controller, irrespective
            of whether or not this data is identical to any Introducer Data.
          </li>
        </List>
        <li>
          <strong>Audit and provision of information</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Each party shall retain records to demonstrate its compliance with this Schedule and
            shall make available to the other party such records or such other information as is
            necessary to demonstrate compliance with this Schedule and shall allow for and assist
            with any audit or inspection conducted by the other party or an auditor appointed by the
            other party.
          </li>
          <li>
            The Provider shall immediately inform the Introducer if, in its opinion, any instruction
            from the Introducer infringes the GDPR or any other data protection law that is
            applicable.
          </li>
        </List>
        <li>
          <strong>Co-operation with Supervisory Authority</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Both the Provider and the Introducer shall co-operate, on request, with a Supervisory
            Authority.
          </li>
          <li>
            Each party shall, where requested by the other, provide assistance with any request made
            of the party by a Supervisory Authority which relates to the activities of the other
            party.
          </li>
        </List>
        <li>
          <strong>Record of processing</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider shall keep a record of any processing of Introducer Data which shall
            contain at least the following information:
          </li>
          <List name="terms-conditions">
            <li>
              the name and contact details of the Provider and, if applicable, The Provider's data
              protection officer;
            </li>
            <li>the categories of processing carried out on behalf of the Introducer;</li>
            <li>
              where applicable, transfers of personal data to a third country or an international
              organisation, including the identification of that third county or international
              organisation and, in the case of transfers referred to in the second subparagraph of
              Article 49(1) of GDPR, the documentation of suitable safeguards;
            </li>
            <li>
              where possible, a general description of the technical and organisation security
              measures required under paragraph 5 above.
            </li>
          </List>
        </List>
        <li>
          <strong>DPA impact assessments</strong>
        </li>
        <List name="terms-conditions">
          <li>
            In the event of either party carrying out a data protection impact assessment under
            Article 35 of GDPR or, prior to such assessment, consulting a Supervisory Authority
            under Article 36 of GDPR, the other party shall assist that party in such assessment
            and/or consultation, taking into account the nature of the processing being carried on
            under this Agreement and the information available to the parties.
          </li>
        </List>
        <li>
          <strong>Transfer of personal data outside of the EU</strong>
        </li>
        <List name="terms-conditions">
          <li>
            The Provider shall only transfer Introducer Data outside of the European Union under
            this Agreement with the specific or general written authority of the Introducer.
          </li>
          <li>
            Where any Introducer Data that is Personal Data is transferred outside of the European
            Union by the Provider under this Agreement, including to any sub-processor, the Provider
            shall ensure that such transfer is either subject to an adequacy decision pursuant to
            Article 45 GDPR or is on the basis of appropriate safeguards pursuant to Article 46
            GDPR.
          </li>
        </List>
        <li>
          <strong>Notice</strong>
        </li>
        <List name="terms-conditions">
          <li>
            Unless this Schedule provides otherwise any notice under this Schedule shall be in
            accordance with the provisions of the Agreement.
          </li>
        </List>
      </List>
    </BlankDocuments>
  </>
)

export default TermsConditions
