import styled from "styled-components"
import { get } from "lodash"

export const Circle = styled.div`
  background: ${({ theme }) => get(theme, "blue", "blue")};
  position: absolute;
  border-radius: 50%;
  left: -4px;
  bottom: -8px;
  width: 8px;
  height: 8px;
`
