import React, { useState, useEffect } from "react"
import moment from "moment"
import { get, isEmpty } from "lodash"
import PropTypes from "prop-types"
import { Modal, Button, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"
import { Table } from "@4cplatform/elements/Organisms"
import Checkbox from "@4cplatform/elements/Forms/Checkbox"
import { ButtonsWrapper, ModalBodyWrapper } from "./checkedItemModal.styles"
import { Typeahead } from "../../../../Forms"

const MIN_WIDTH = 500
const PER_PAGE = 5

const CheckedItemModal = ({
  data,
  title,
  label,
  onClose,
  onSubmit,
  onChecked,
  isLoading,
  searchProps,
  submitAppearance,
  confirmationProps
}) => {
  const WIDTH = document.body.clientWidth * (label.date ? 0.6 : 0.3)
  const [total, setTotal] = useState(0)
  const [checkedItem, setCheckedItem] = useState({})
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [pagination, setPagination] = useState({ total: 0, page: 1 })

  useEffect(() => {
    if (!Array.isArray(data)) {
      setTotal(0)
      setCheckedItem({})
      setPagination({ total: 0, page: 1 })
    } else {
      // keep the total of selected items in case the request is loading.
      if (!isLoading) setTotal(data.filter(({ checked }) => checked).length)

      setCheckedItem(
        data.reduce((obj, { id, checked }) => ({ ...obj, [id]: checked ?? false }), {})
      )
      setPagination(cur => ({ total: data.length, page: cur.page }))
    }

    setDialogOpen(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function renderDateColumn() {
    if (!label.date) return []

    return [
      {
        minWidth: 0,
        dataKey: "date",
        label: label.date,
        render: row => {
          const date = get(row, "date")
          return (
            <P margin="0">{date ? moment.utc(row.date).local().format("DD/MM/YYYY HH:mm") : "-"}</P>
          )
        }
      }
    ]
  }

  const dataTable =
    data.filter(
      // eslint-disable-next-line no-undef
      (_, ind) => ind >= (pagination.page - 1) * PER_PAGE && ind < pagination.page * PER_PAGE
    ) ?? []

  return (
    <>
      <Modal title={title} onClose={onClose} width={`${WIDTH > MIN_WIDTH ? WIDTH : MIN_WIDTH}px`}>
        <ModalBodyWrapper>
          {searchProps && (
            <Typeahead
              shouldClearOnSubmit
              name="add-organisation"
              {...searchProps}
              isLoading={searchProps.isLoading}
            />
          )}
          <Table
            data={dataTable}
            type="default"
            isLoading={isLoading}
            columns={[
              {
                minWidth: "50px",
                label: (
                  <Checkbox
                    margin="0"
                    name="select-all"
                    label="Select all"
                    isDisabled={isEmpty(checkedItem)}
                    value={
                      !isEmpty(checkedItem) &&
                      Object.values(checkedItem).every(isChecked => isChecked)
                    }
                    onChange={isChecked => {
                      setCheckedItem(previousValue => {
                        let result = {}

                        if (!isEmpty(previousValue)) {
                          Object.keys(previousValue).forEach(key => {
                            previousValue[key] = isChecked
                          })

                          result = { ...previousValue }
                        }

                        if (onChecked) onChecked(result)

                        return result
                      })
                      setTotal(isChecked ? Object.keys(checkedItem).length : 0)
                    }}
                  />
                ),
                render: row => (
                  <Checkbox
                    margin="0"
                    name="select-item"
                    value={checkedItem[row.data.id]}
                    onChange={isChecked => {
                      setCheckedItem(previousValue => {
                        const result = { ...previousValue }

                        result[row.data.id] = isChecked

                        setTotal(Object.values(result).filter(checked => checked).length)

                        if (onChecked) onChecked(result)

                        return result
                      })
                    }}
                  />
                )
              },
              {
                minWidth: "50px",
                dataKey: "name",
                label: label.name || "Name"
              }
            ].concat(renderDateColumn())}
            pagination={{ ...pagination, perPage: PER_PAGE }}
            changePage={curPage => setPagination({ total: data.length, page: curPage })}
          />
        </ModalBodyWrapper>
        <ButtonsWrapper>
          <Button
            appearance={submitAppearance === "error" ? "errorGhost" : "error"}
            trailingIcon="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            appearance={submitAppearance}
            isLoading={isLoading}
            isDisabled={isLoading || Object.values(checkedItem).every(isChecked => !isChecked)}
            onClick={() => {
              setDialogOpen(true)
            }}
          >
            {`${label.submit || "Selected"} (${total})`}
          </Button>
        </ButtonsWrapper>
      </Modal>
      {isDialogOpen && (
        <ConfirmationModal
          confirmIcon={confirmationProps.confirmIcon}
          confirmText="Yes"
          confirmAppearance="success"
          cancelAppearance="error"
          onClose={() => setDialogOpen(false)}
          onConfirm={() => {
            setDialogOpen(false)
            if (onSubmit) onSubmit(checkedItem)
          }}
        >
          {confirmationProps.renderContent ? confirmationProps.renderContent(checkedItem) : null}
        </ConfirmationModal>
      )}
    </>
  )
}

CheckedItemModal.defaultProps = {
  data: [],
  label: {},
  isLoading: false,
  submitAppearance: "error"
}

CheckedItemModal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onChecked: PropTypes.func,
  isLoading: PropTypes.bool,
  submitAppearance: PropTypes.oneOf(["error", "success"]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      date: PropTypes.string,
      checked: PropTypes.bool
    })
  ),
  label: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string,
    submit: PropTypes.string
  }),
  searchProps: PropTypes.shape({
    label: PropTypes.string,
    onCancel: PropTypes.func,
    onSelect: PropTypes.func,
    isLoading: PropTypes.bool,
    hasCancel: PropTypes.bool,
    handleChange: PropTypes.func,
    suggestions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string
      })
    )
  }),
  confirmationProps: PropTypes.shape({
    confirmIcon: PropTypes.oneOf(["delete", "check"]),
    renderContent: PropTypes.func
  })
}

export default CheckedItemModal
