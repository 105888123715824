import React from "react"

// Components
import MyAccountQuickQuoteProvider from "./context/quickQuote.provider"
import { MyAccountQuickQuote } from "../../../../UI/Templates"

const QuickQuote = () => (
  <MyAccountQuickQuoteProvider>
    <MyAccountQuickQuote />
  </MyAccountQuickQuoteProvider>
)

export default QuickQuote
