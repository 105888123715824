import { get } from "lodash"
import styled from "styled-components"

export const PolicyWrapper = styled.div`
  overflow-y: auto;
  max-height: 360px;
  background-color: ${({ theme }) => get(theme, "blue", "blue")};
`

export const PolicyDetailWrapper = styled.div`
  padding: 0 2rem 2rem;
`
export const PolicyHeaderWrapper = styled.div`
  position: sticky;
  top: 0px;
  padding: 2rem;
  background-color: ${({ theme }) => get(theme, "blue", "blue")};
`
export const RoundedButtonsWrapper = styled.div`
  display: flex;
  margin: ${({ margin }) => margin || "0"};
`
