import React, { useEffect } from "react"
import { get } from "lodash"
import PropTypes from "prop-types"
import { Modal, ComplianceNote } from "@4cplatform/elements/Molecules"
import { H3, P, SmallText } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { ClientsContext } from "../../clients.context"
import { getIconDetails } from "../../clients.helpers"
import { getName } from "../../../../Helpers"

// Components
import { PolicySummaryUserDetails, AddressWrapper } from "../../clients.styles"
import PolicySummaryButtons from "./policy.summary.buttons"
import { PoliciesContext } from "../../../PolicyAdmin/policies.context"

const PolicySummary = ({ data }) => {
  const { viewData, setPolicySummaryModal, setSelectPolicySummary } =
    React.useContext(ClientsContext)
  const { onPolicySelect, onPolicyDeselect } = React.useContext(PoliciesContext)
  const statusType = getIconDetails(data.status)
  const t = useTranslations()
  useEffect(() => {
    if (data) {
      onPolicySelect(data)
    }
    return () => {
      setSelectPolicySummary({})
      onPolicyDeselect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Modal onClose={() => setPolicySummaryModal(false)} hasPadding={false} title="Policy details">
      <ComplianceNote type={statusType.icon || "alert"} margin="2rem">
        <P margin="2rem">{t(data.status)}</P>
        <SmallText>{`The organisation has confirmed this policy is ${t(data.status)}.`}</SmallText>
      </ComplianceNote>
      <PolicySummaryUserDetails>
        <H3 margin="2rem 0 1rem">{data.reference?.toUpperCase()}</H3>
        <P margin="0">{getName({ data: viewData })}</P>
        <P margin="0">{get(viewData, "email_address")}</P>
        <H3 margin="2rem 0 1rem">Address</H3>
        <AddressWrapper>
          {get(viewData, "address.line_one") && (
            <P margin="0">{get(viewData, "address.line_one")}</P>
          )}
          {get(viewData, "address.line_two") && (
            <P margin="0">{get(viewData, "address.line_two")}</P>
          )}
          {get(viewData, "address.city") && <P margin="0">{get(viewData, "address.city")}</P>}
          {get(viewData, "address.county") && <P margin="0">{get(viewData, "address.county")}</P>}
          {get(viewData, "address.postcode") && (
            <P margin="0">{get(viewData, "address.postcode")}</P>
          )}
        </AddressWrapper>
      </PolicySummaryUserDetails>
      <PolicySummaryButtons />
    </Modal>
  )
}

PolicySummary.defaultProps = {
  data: {}
}

PolicySummary.propTypes = {
  data: PropTypes.object
}

export default PolicySummary
