import React from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import { useLocation, useHistory } from "react-router-dom"
import { get } from "lodash"
import { useDelete, useGet, usePost, ApiError } from "@4cplatform/elements/Api"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { Provider } from "../../../../../UI/Templates/NetworkInvitations"
import { NetworkManageContext } from "../../../context/manage.context"
import { getOrderBy } from "../../../../../UI/Helpers"
import reducer from "./invitations.reducer"

const NetworkInvitationsProvider = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const values = queryString.parse(location.search)
  const { network, networkLoading } = React.useContext(NetworkManageContext)

  // State
  const [{ page, perPage, search, sorting, total, data, addOpen, cancel }, dispatch] =
    React.useReducer(reducer, {
      page: 1,
      perPage: 10,
      search: "",
      sorting: { direction: "asc", dataKey: "created_at" },
      total: null,
      data: [],
      addOpen: get(values, "send_open") === "true",
      cancel: { isOpen: false, invitation: null }
    })

  // Index invitations
  const {
    loading: invitationsLoading,
    error: queryError,
    refetch
  } = useGet({
    endpoint: "/networks/:slug/invitations",
    skip: !get(network, "slug", null),
    params: {
      slug: get(network, "slug", "")
    },
    query: {
      limit: perPage,
      page,
      order_by: getOrderBy(sorting),
      organisation_name: search || undefined
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: "There was an error fetching the invitations",
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Invite organisations
  const [invite, { loading: inviteLoading, error: inviteError }] = usePost({
    endpoint: "/networks/:slug/invitations",
    params: {
      slug: get(network, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        type: "success",
        message: "Invitations successfully created",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "INVITE_SUCCESS" })
      refetch()
    }
  })

  // Delete invitation
  const [remove, { loading: deleteLoading, error: deleteError }] = useDelete({
    endpoint: "/networks/:slug/invitations/:invitation",
    onCompleted: () => {
      addAlert({
        type: "success",
        message: "Invitation successfully removed",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "DELETE_SUCCESS" })
      refetch()
    },
    onError: err => console.error(err)
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        search,
        setSearch,
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        pagination: { total, page, perPage },
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        sorting,
        queryLoading: invitationsLoading || networkLoading,
        addOpen,
        setAddOpen: val => {
          dispatch({ type: "UPDATE_VALUE", key: "addOpen", value: val })
          if (!val)
            history.replace({
              search: queryString.exclude(location.search, ["send_open"])
            })
          if (val) history.replace({ search: queryString.stringify({ ...values, send_open: val }) })
        },
        inviteLoading,
        onInviteOrganisation: body => {
          invite({ body })
        },
        cancel,
        setCancel: val => dispatch({ type: "UPDATE_VALUE", key: "cancel", value: val }),
        onDeleteInvitation: invitation => {
          remove({
            params: {
              invitation: get(invitation, "id", null),
              slug: get(network, "slug", null)
            }
          })
        },
        deleteLoading,
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val })
      }}
    >
      {children}
      <ApiError error={queryError || inviteError || deleteError} />
    </Provider>
  )
}

NetworkInvitationsProvider.propTypes = {
  children: PropTypes.any
}

export default NetworkInvitationsProvider
