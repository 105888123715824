import styled, { css } from "styled-components"

export const SelectWrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 3rem;

  ${({ hasError }) => {
    if (hasError) {
      return css`
        padding-bottom: 0.5rem;
      `
    }
  }}
`

export const StyledLabel = styled.div`
  sup {
    visibility: "visible";
    padding-left: 0.2rem;
    color: ${({ colours }) => colours.red};
  }
`
