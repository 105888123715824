import React, { useContext } from "react"
import { get } from "lodash"
import { ComplianceNote, Button } from "@4cplatform/elements/Molecules"
import { P, SmallText, H4, H3 } from "@4cplatform/elements/Typography"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { JourneyContext } from "../../../../../../journey.context"
import { HospitalPreferenceContext } from "./hospitalPreference.context"

// Components
import HospitalPreferenceMap from "./hospitalPreference.map"
import HospitalPreferenceTable from "./hospitalPreference.table"
import {
  HospitalPreferenceWrapper,
  PreferedHospital,
  PreferedHospitalContent,
  Wrapper
} from "./hospitalPreference.styles"

const Body = () => {
  const {
    clientAddress,
    isDataLoading,
    hospitalsLoading,
    selectedHospital,
    onHospitalPreferenceSelect
  } = useContext(HospitalPreferenceContext)
  const { data } = useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)

  return (
    <HospitalPreferenceWrapper>
      <ComplianceNote type="error">
        <H4 margin="0 0 1rem">Compliance note</H4>
        <P>The three hospitals closest to the client's residential address need to be read out.</P>
        <P margin="0">
          Ask the client if they have any preference when it comes to private hospitals and whether
          they require these to be covered on their chosen hospital list. If a preference is
          provided, ensure this is covered. Should it not be beneficial for the client to have
          access to these hospitals or if they are not included on the clients chosen hospital list,
          this needs to be explained to the client and alternatives provided.
        </P>
      </ComplianceNote>
      <H4
        isLoading={isDataLoading || hospitalsLoading}
        margin="4rem 0 2rem"
        name="your_preferred_hospital"
      >
        Your preferred hospital
      </H4>
      {selectedHospital && (
        <PreferedHospital>
          <PreferedHospitalContent>
            <H4 margin="0" colour={colours.blue} isLoading={isDataLoading || hospitalsLoading}>
              {selectedHospital.name}
            </H4>
            <SmallText margin="0" isLoading={isDataLoading || hospitalsLoading}>
              {selectedHospital.address_line_one},{" "}
              {selectedHospital.address_line_two && `${selectedHospital.address_line_two},`}
              {`${selectedHospital.city}, ${selectedHospital.postcode}`}
            </SmallText>
            <Button
              onClick={() => onHospitalPreferenceSelect(null)}
              isDisabled={isLocked}
              isLoading={isDataLoading || hospitalsLoading}
              appearance="errorInline"
              trailingIcon="delete"
              type="inline-button"
              name="delete_selected_hospital"
              margin="0"
            />
          </PreferedHospitalContent>
        </PreferedHospital>
      )}
      {!selectedHospital && (
        <SmallText isLoading={isDataLoading || hospitalsLoading} margin="0 0 1.4rem">
          No preferred hospital has been indicated
        </SmallText>
      )}
      <H3
        isLoading={isDataLoading || hospitalsLoading}
        margin="0 0 2rem"
      >{`These are the closest hospitals to ${clientAddress.postcode}`}</H3>
      <Wrapper>
        <HospitalPreferenceTable />
        <HospitalPreferenceMap />
      </Wrapper>
    </HospitalPreferenceWrapper>
  )
}

export default Body
