import React from "react"
import { Helmet } from "react-helmet-async"
import { Gate } from "@4cplatform/elements/Auth"

const EmailChangeConfirmation = () => (
  <>
    <Helmet>
      <title>Email change confirmation</title>
    </Helmet>
    <Gate type="email-change-confirmation" />
  </>
)

export default EmailChangeConfirmation
