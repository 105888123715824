import React, { useContext } from "react"
import moment from "moment"
import { get } from "lodash"
import { H2, SmallText } from "@4cplatform/elements/Typography"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { Button } from "@4cplatform/elements/Molecules"
import { ConfigContext } from "@4cplatform/elements/Config"
import { AuthContext } from "@4cplatform/elements/Auth"

// Helpers
import { getName } from "../../Helpers"
import { QuotesContext } from "./quotes.context"

// Components
import { Messaging } from "../../Organisms"
import { PanelHeader } from "./quotes.styles"

const QuotesPanelHeader = () => {
  const { canAccess } = useContext(AuthContext)
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const {
    viewLoading,
    viewData,
    messagingModal,
    setMessagingModal,
    messages,
    messagesLoading,
    submitMessage,
    submitMessageLoading
  } = useContext(QuotesContext)

  const created = get(viewData, "created_at", "")
    ? moment
        .utc(get(viewData, "created_at", ""), "YYYY-MM-DDTHH:mmZ")
        .local()
        .format("DD/MM/YYYY HH:mm")
    : "-"
  const updated = get(viewData, "updated_at", "")
    ? moment
        .utc(get(viewData, "updated_at", ""), "YYYY-MM-DDTHH:mmZ")
        .local()
        .format("DD/MM/YYYY HH:mm")
    : "-"

  const status = get(viewData, "policy.status", false)

  const canMessageProviderUser =
    canAccess(["PROVIDER_ADMIN", "UNDERWRITER"]) &&
    ["AWAITING_TERMS", "AWAITING_ACCEPTANCE"].includes(status)

  return (
    <PanelHeader>
      <H2 appearance="light" isLoading={viewLoading} margin="1rem 0 1rem">
        {getName({
          data: get(viewData, "client"),
          hasMiddle: true,
          hasTitle: true,
          globalTitles: GLOBAL_TITLES,
          hideOtherTitle: true
        })}
      </H2>
      <SmallText appearance="light" isLoading={viewLoading} margin="0 0 1rem">
        Created: {created}
      </SmallText>
      <SmallText appearance="light" isLoading={viewLoading} margin="0 0 1rem">
        Updated: {updated}
      </SmallText>
      <IconWithText
        icon="pound"
        appearance="light"
        content={get(viewData, "reference", "-")}
        isLoading={viewLoading}
      />
      <IconWithText
        icon="account-box-multiple-outline"
        appearance="light"
        isLoading={viewLoading}
        margin="0"
        content={get(viewData, "sales_agent.parent.name")}
      />
      <IconWithText
        margin="0 0 2rem 3rem"
        isLoading={viewLoading}
        appearance="light"
        icon="subdirectory-arrow-right"
        content={getName({ data: get(viewData, "sales_agent") })}
      />
      <IconWithText
        margin="0"
        appearance="light"
        icon="cards-diamond"
        content={get(viewData, "provider.name")}
        isLoading={viewLoading}
      />
      <IconWithText
        margin="0 0 0 3rem"
        appearance="light"
        icon="subdirectory-arrow-right"
        content={`£${get(viewData, "monthly_premium", "")}, £${get(
          viewData,
          "annual_premium",
          ""
        )}`}
        isLoading={viewLoading}
      />
      {canMessageProviderUser && (
        <>
          <Button
            appearance="whiteGhost"
            trailingIcon="email"
            onClick={() => setMessagingModal(true)}
            name="messaging_modal"
            isLoading={viewLoading}
            isDisabled={viewLoading}
            margin="2rem 0 1rem 0"
          >
            Messaging
          </Button>
          <Messaging
            isOpen={messagingModal}
            setIsOpen={setMessagingModal}
            messages={messages}
            isMessagesLoading={messagesLoading}
            submitMessage={submitMessage}
            isSubmitMessageLoading={submitMessageLoading}
          />
        </>
      )}
    </PanelHeader>
  )
}

export default QuotesPanelHeader
