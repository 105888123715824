import React from "react"
import PropTypes from "prop-types"
import { H3, H2 } from "@4cplatform/elements/Typography"
import { colours } from "@4cplatform/elements/Helpers"
import { Skeleton } from "@4cplatform/elements/Molecules"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import { Line } from "react-chartjs-2"
import { MetricCardWrapper, MetricCardHeader, MetricCardBody, FlexColumn } from "./miReports.style"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
const { blue } = colours
const getData = (labels, data) => ({
  labels,
  datasets: [
    {
      data,
      label: "Total",
      borderColor: blue,
      backgroundColor: blue
    }
  ]
})

const MetricCard = ({
  title,
  subTitle,
  alternativeSubTitle,
  bigTitle,
  labels,
  data,
  isLoading,
  isShowingChart
}) => {
  const hasAChart = !!labels && !!labels.length && !!data && !!data.length
  return (
    <MetricCardWrapper>
      {isLoading && (
        <MetricCardHeader>
          <FlexColumn>
            <Skeleton borderRadius="0.5rem" />
            <Skeleton borderRadius="0.5rem" />
            <Skeleton borderRadius="0.5rem" lineHeight="5rem" />
          </FlexColumn>
        </MetricCardHeader>
      )}
      {!isLoading && (
        <>
          <MetricCardHeader>
            {hasAChart && !isShowingChart && alternativeSubTitle ? (
              <H3 margin="1rem 1.5rem 0rem 1.5rem" align="center" colour={blue}>
                {alternativeSubTitle}
              </H3> // If the custom range is over 1 year the Annual Total is changed to Total
            ) : (
              subTitle && (
                <H3 margin="1rem 1.5rem 0rem 1.5rem" align="center" colour={blue}>
                  {subTitle}
                </H3>
              )
            )}
            {bigTitle && (
              <H2 margin="0" align="center">
                {bigTitle}
              </H2>
            )}
          </MetricCardHeader>
          <MetricCardBody>
            {title && (
              <H2 margin="0" align="center">
                {title}
              </H2>
            )}
            {hasAChart && isShowingChart && (
              <div className="line-chart">
                <Line
                  width="100%"
                  height="40%"
                  data={getData(labels, data)}
                  options={{
                    responsive: true,
                    scales: {
                      x: { display: false },
                      y: { display: false }
                    },
                    plugins: {
                      legend: { display: false }
                    }
                  }}
                />
              </div>
            )}
          </MetricCardBody>
        </>
      )}
    </MetricCardWrapper>
  )
}

MetricCard.defaultProps = {
  title: "",
  subTitle: "",
  alternativeSubTitle: "",
  bigTitle: "",
  isLoading: false,
  labels: [],
  data: [],
  isShowingChart: false
}

MetricCard.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  subTitle: PropTypes.string,
  alternativeSubTitle: PropTypes.string,
  bigTitle: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.number),
  isShowingChart: PropTypes.bool
}

export default MetricCard
