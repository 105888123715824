import styled, { css } from "styled-components"
import { get } from "lodash"

// Components
import { P } from "@4cplatform/elements/Typography"
import { BodyCell } from "@4cplatform/elements/Organisms/Table/table.styles"

const modalPartial = css`
  padding: 2rem 3rem;
`

export const DemandsNeedsModalHeader = styled.div`
  ${modalPartial}
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${({ theme }) => get(theme, "veryFaintGrey", "white")};
`

export const DemandsNeedsModalHeaderContent = styled.div`
  padding-left: 1rem;
`

export const DemandsNeedsModalBody = styled.div`
  ${modalPartial}
`

export const DemandsNeedsModalFooter = styled.div`
  padding: 0 3rem 2rem;
`

export const EditPanelProviderDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

export const EditPanelProviderDetailsContent = styled.div`
  padding-left: 1rem;
`

export const EditPanelFormWrapper = styled.div`
  margin-top: 2rem;

  & ${BodyCell}:not(:last-child) {
    padding-right: 1rem;
  }
`

export const EditFieldRow = styled.div`
  display: flex;
  margin: 0 0 2rem;

  & > div {
    width: 100%;
  }
`
export const EditPanelFooter = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => get(theme, "tints.secondary.darkBlue.t10", "blue")};
  padding: 3rem;
  justify-content: space-between;
  z-index: 3;
`

export const EditPanelFooterColumnWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const DocumentLi = styled.li`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`

export const ErrorMessage = styled(P)`
  margin: 0;
  margin-top: 2rem;
  color: ${({ theme }) => get(theme, "red", "red")};
`

export const ProviderInfoModalHeader = styled.div`
  ${modalPartial}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => get(theme, "veryFaintGrey", "white")};
`

export const ProviderInfoModalHeaderContent = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ProviderInfoModalBody = styled.div`
  ${modalPartial}
`

export const ProviderInfoModalFooter = styled.div`
  padding: 0 3rem 2rem;
  display: flex;
  flex-direction: row-reverse;
`

export const SectionWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => get(theme, "faintGrey")};
  margin: ${({ isLast }) => (isLast ? "0" : "1rem 0")};
  padding: ${({ padding }) => padding || "0"};
`

export const CustomFieldWrapper = styled.div`
  margin: 0 0 2rem;
`

export const FlyoutTable = styled.div`
  div > div[type="contained"] > div[type="contained"]:not([data-testid="test-header"]) {
    ${({ theme }) => css`
      color: ${get(theme, "tints.secondary.darkBlue.t20", "black")};
      background: ${get(theme, "veryFaintGrey", "lightgrey")};
    `}
  }
`

export const ToggleWrapper = styled.div`
  button:disabled {
    color: #8cbed1;
    background: #fff;
  }
`
