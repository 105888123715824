import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { usePost } from "@4cplatform/elements/Api"
import { colours } from "@4cplatform/elements/Helpers"
import { IconWithText, LabelWithText } from "@4cplatform/elements/Atoms"
import { Modal, Button } from "@4cplatform/elements/Molecules"
import { H2, H3, P } from "@4cplatform/elements/Typography"
import { AuthContext } from "@4cplatform/elements/Auth"
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import {
  ViewDealHeader,
  ViewDealBody,
  ButtonsWrapper,
  ConfirmWrapper,
  ConfirmButtons,
  ModalContentWrapper,
  ModalContentOverlay
} from "./assignment.styles"
import ApplicabilityList from "./assignment.card.view.list"

// Helpers
import { getConfirmationHeader, getConfirmationSubheader } from "./assignment.helpers"
import { AgencyCodesContext } from "../../agencyCodes.context"

const ViewDealCode = ({ code, onClose }) => {
  const t = useTranslations()
  const {
    onSuspendDealCode,
    onReinstateDealCode,
    suspendDealCodeLoading,
    reinstateDealCodeLoading,
    isAllLoading,
    selectedAgencyCode,
    refetchAssignedDealCodes,
    refetchAvailableDealCodes,
    viewDeal,
    setViewDeal
  } = React.useContext(AgencyCodesContext)
  const { canAccess } = React.useContext(AuthContext)
  const [confirm, setConfirm] = React.useState({ isOpen: false, type: null })
  const isSuspended = get(code, "suspended", false)

  // Unassign deal code mutation
  const [unassignDealCode, { loading: unassignDealCodeLoading }] = usePost({
    endpoint: "/agency-codes/:slug/deal-codes/:deal/unassign",
    params: {
      slug: get(selectedAgencyCode, "slug", ""),
      deal: get(viewDeal, "slug", null)
    },
    onCompleted: () => {
      addAlert({
        message: t("DEAL_CODE_UNASSIGN_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      refetchAssignedDealCodes()
      refetchAvailableDealCodes()
      setViewDeal({ isOpen: false, slug: null, data: null })
    },
    onError: () => {
      addAlert({
        message: t("DEAL_CODE_UNASSIGN_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  return (
    <>
      <Modal
        onClose={onClose}
        name="view_deal_code_modal"
        title="Manage deal code"
        hasPadding={false}
      >
        <ModalContentWrapper>
          {/* Overlay */}
          <ModalContentOverlay
            isVisible={get(confirm, "isOpen", false)}
            onClick={() => setConfirm({ isOpen: false, type: null })}
          />
          {/* Header */}
          <ViewDealHeader>
            <H2 margin="0 0 1rem">{get(code, "name", "-")}</H2>
          </ViewDealHeader>
          {/* Body */}
          <ViewDealBody>
            <LabelWithText label="Status">
              <IconWithText
                icon={!isSuspended ? "check-circle" : "close-circle"}
                content={!isSuspended ? "Not Suspended" : "Suspended"}
                iconColour={!isSuspended ? get(colours, "green") : get(colours, "red")}
              />
            </LabelWithText>
            <LabelWithText
              label="Description"
              content={get(code, "description", "-")}
              margin="0 0 2rem"
            />
            <ApplicabilityList code={code} />
          </ViewDealBody>
          {/* Buttons */}
          {canAccess(["SYS_ADMIN", "SUPPORT_ADMIN"]) && (
            <ButtonsWrapper>
              <Button
                appearance="warning"
                trailingIcon={isSuspended ? "thumb-up" : "hand-left"}
                margin="0 1rem 0 0"
                onClick={() => {
                  if (!isSuspended) {
                    setConfirm({ isOpen: true, type: "suspend" })
                  } else {
                    setConfirm({ isOpen: true, type: "reinstate" })
                  }
                }}
                isDisabled={get(confirm, "isOpen", false) || isAllLoading}
              >
                {!isSuspended ? "Suspend" : "Reinstate"}
              </Button>
              <Button
                appearance="warning"
                trailingIcon="tag"
                margin="0"
                onClick={() => setConfirm({ isOpen: true, type: "unassign" })}
                isDisabled={get(confirm, "isOpen", false) || isAllLoading}
              >
                Unassign
              </Button>
            </ButtonsWrapper>
          )}
        </ModalContentWrapper>
        {/* Confirm segment */}
        {confirm.isOpen && (
          <ConfirmWrapper>
            <H3 margin="0 0 1rem">{getConfirmationHeader(get(confirm, "type", null))}</H3>
            <P>{getConfirmationSubheader(get(confirm, "type", null))}</P>
            <ConfirmButtons>
              <Button
                appearance="success"
                leadingIcon="check"
                onClick={() => {
                  if (get(confirm, "type") !== "unassign") {
                    if (!isSuspended) {
                      onSuspendDealCode(code)
                    } else {
                      onReinstateDealCode(code)
                    }
                  } else {
                    unassignDealCode(code)
                  }
                }}
                margin="0 1rem 0 0"
                isLoading={
                  suspendDealCodeLoading || reinstateDealCodeLoading || unassignDealCodeLoading
                }
              >
                Yes
              </Button>
              <Button
                appearance="error"
                leadingIcon="close"
                onClick={() => setConfirm({ isOpen: false, type: null })}
              >
                No
              </Button>
            </ConfirmButtons>
          </ConfirmWrapper>
        )}
      </Modal>
    </>
  )
}

ViewDealCode.propTypes = {
  code: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ViewDealCode
