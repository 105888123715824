import { get } from "lodash"
import styled from "styled-components"
import { H3 } from "@4cplatform/elements/Typography"
import { getBreakpoint } from "@4cplatform/elements/Helpers"

export const FeatureList = styled.div`
  gap: 2.5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  ${getBreakpoint({ min: "medium" })} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${getBreakpoint({ min: "large" })} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${getBreakpoint({ min: "xLarge" })} {
    grid-template-columns: repeat(4, 1fr);
  }
  /*
  ${getBreakpoint({ min: "xlarge" })} {
    grid-template-columns: repeat(4, 1fr);
  } */
`

export const Feature = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => get(theme, "tints.secondary.darkBlue.t50")};
  border-radius: 0.4rem;
  flex: 1;
  min-height: 100%;
  padding: 1.8rem 2rem;
`

export const FeatureInner = styled.div`
  display: flex;
  align-items: center;
`

export const ImageWrap = styled.div`
  flex: 0 0 auto;
  max-width: 9rem;
  width: 100%;
`

export const Image = styled.img`
  max-height: 100px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const Header = styled(H3)`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`
