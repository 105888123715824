/* eslint-disable no-unused-vars */
import React, { useContext } from "react"
import { isEmpty } from "lodash"
import { P } from "@4cplatform/elements/Typography"
import { Button } from "@4cplatform/elements/Molecules"
import { Notifications } from "@4cplatform/elements/Organisms"

// Helpers
import { NotificationsContext } from "./notifications.context"

// Components
import { NotificationTabContentWrapper, ButtonsWrapper } from "./notifications.styles"
import { Tabs, Tab } from "../../Organisms"

const NotificationAdmin = () => {
  const {
    unreadNotifications,
    readNotifications,
    notificationsLoading,
    onMarkAsRead,
    markAsReadLoading,
    toReadNotification,
    onMarkAllAsRead,
    markAllAsReadLoading
  } = useContext(NotificationsContext)
  const hasUnreadNotifications = !isEmpty(unreadNotifications) && !notificationsLoading
  const hasReadNotifications = !isEmpty(readNotifications) && !notificationsLoading

  return (
    <Tabs name="notifications" isLoading={notificationsLoading}>
      <Tab header="Notifications" isLoading={notificationsLoading}>
        {hasUnreadNotifications ? (
          <>
            <NotificationTabContentWrapper>
              <Notifications
                clickHandler={onMarkAsRead}
                isClickHandlerLoading={markAsReadLoading}
                toReadNotification={toReadNotification}
                isDetailedView
                notifications={unreadNotifications}
              />
            </NotificationTabContentWrapper>
            <ButtonsWrapper>
              <Button
                appearance="warning"
                trailingIcon="check"
                name="mark_all_as_read"
                onClick={onMarkAllAsRead}
                isLoading={markAllAsReadLoading}
              >
                Remove all
              </Button>
            </ButtonsWrapper>
          </>
        ) : (
          <P margin="0" isLoading={notificationsLoading} loadingLines={5} lineHeight="13rem">
            You have no unread notifications
          </P>
        )}
      </Tab>
      <Tab header="Read Notifications" isLoading={notificationsLoading}>
        {hasReadNotifications ? (
          <NotificationTabContentWrapper>
            <Notifications isDetailedView notifications={readNotifications} />
          </NotificationTabContentWrapper>
        ) : (
          <P margin="0" isLoading={notificationsLoading} loadingLines={15} loadingWidth="65rem">
            You have no read notifications
          </P>
        )}
      </Tab>
    </Tabs>
  )
}

export default NotificationAdmin
