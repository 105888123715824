import React from "react"
import { colours } from "@4cplatform/elements/Helpers"
import { P } from "@4cplatform/elements/Typography"
import { Row } from "@4cplatform/elements/Atoms"

// Components
import { PanelContent } from "../../components"
import { DiscoverPanelWrap } from "../../landingPage.styles"
import { Feature, Header, Image } from "./discover.styles"

const discoverFeaturesContent = [
  {
    title: "Guided Process",
    imgSrc: "full-featured",
    imgAlt: "Full featured icon",
    text: "A fully-featured sales journey takes you through every step of the fact find, application and onboarding process eliminating the need to ever use multiple portals."
  },
  {
    title: "Helps Compliance",
    imgSrc: "compliance-built-in",
    imgAlt: "Compliance biult in icon",
    text: "Comprehensive auditing tools provide detailed insight and assurance."
  },
  {
    title: "Easy to use",
    imgSrc: "easy-to-use",
    imgAlt: "Easy to use icon",
    text: "An intuitive and comprehensive process flow built designed by brokers, for brokers that is simple to use."
  }
]

const panelProps = {
  title: {
    text: "Discover the 4C Platform",
    colour: colours.darkBlue
  },
  subtitle: {
    text: "The complete 4C Platform Journey includes a wealth of features and capabilities designed to make the quoting process fast and convenient, both for the client and the broker whilst also ensuring no compliance is unknowingly not completed",
    colour: colours.darkBlue
  },
  button: {
    text: "View all the features",
    href: "#features",
    appearance: "darkBlueGhost"
  },
  hrColour: colours.darkBlue
}

const DiscoverPanel = () => (
  <DiscoverPanelWrap id="discover" data-testid="discover-panel">
    <PanelContent {...panelProps}>
      {discoverFeaturesContent.length > 0 && (
        <Row>
          {discoverFeaturesContent.map(({ title, imgSrc, imgAlt, text }) => (
            <Feature
              key={title}
              alignItems="center"
              phone={12}
              small={12}
              tablet={12}
              medium={12}
              large={4}
              xLarge={4}
              padding="2rem"
              data-testid="discover-feature"
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/landing-page/discover/${imgSrc}@1x.png`}
                srcSet={`${process.env.PUBLIC_URL}/images/landing-page/discover/${imgSrc}@2x.png 2x, ${process.env.PUBLIC_URL}/images/landing-page/discover/${imgSrc}@3x.png 3x`}
                alt={imgAlt}
                role="presentation"
                aria-hidden="true"
              />
              <Header colour={colours.darkBlue} margin="0">
                {title}
              </Header>
              <P align="center" colour={colours.darkBlue} lineHeight="1.5">
                {text}
              </P>
            </Feature>
          ))}
        </Row>
      )}
    </PanelContent>
  </DiscoverPanelWrap>
)

export default DiscoverPanel
