import { colours } from "@4cplatform/elements/Helpers"
import { COMMISSION_RATES } from "../../../config/pages"

export const getOrganisationAdminDash = slug => [
  {
    title: "Leads",
    links: [
      {
        title: "Quick Quote",
        description: "Create a quick quote based on the date of birth and postcode",
        route: "/quick-quote"
      },
      {
        title: "Create new lead",
        description: "Create new leads for this account",
        route: "/leads/add"
      },
      {
        title: "Manage leads",
        description: "Manage leads for this account",
        route: "/leads"
      },
      {
        title: "Reports",
        description: "Manage information, screen, print and download",
        route: "/reports"
      }
    ],
    colour: colours.blue,
    icon: "clipboard-account-outline",
    route: "/leads"
  },
  {
    title: "Quotes",
    links: [
      {
        title: "Journeys in progress",
        description: "Journeys in progress",
        route: "/journeys?status=IN_PROGRESS"
      },
      {
        title: "Quotes completed",
        description: "All quotes completed to date",
        route: `/organisations/${slug}?manage=client_journeys&status=COMPLETE`
      },
      {
        title: "Manage clients",
        description: "View all clients in this account",
        route: "/clients"
      }
    ],
    colour: colours.quotesBlue,
    icon: "map-marker-path",
    route: null
  },
  {
    title: "Policies",
    links: [
      {
        title: "All policies",
        description: "All policies sold to date",
        route: "/policies?tab=ALL_POLICIES&status=SOLD"
      },
      {
        title: "Awaiting terms",
        description: "All quotes awaiting terms",
        route: "/policies?status=AWAITING_TERMS"
      },
      {
        title: "Awaiting acceptance",
        description: "All policies awaiting final terms and policy numbers",
        route: "/policies?status=AWAITING_ACCEPTANCE"
      },
      {
        title: "Failed to onboard",
        description: "All policies that failed onboarding",
        route: "/policies?status=FAILED_ONBOARDING"
      }
    ],
    colour: colours.policiesPurple,
    icon: "format-list-checks",
    route: "/policies"
  },
  {
    title: "Admin",
    links: [
      {
        title: "My account",
        description: "Manage your account username and password, etc",
        route: "/my-account?my-account=details"
      },
      {
        title: "Manage my organisation",
        description: "Configure your organisation's settings",
        route: `/organisations/${slug}?manage=details`
      },
      {
        title: "User administration",
        description: "Manage and view team members for your organisation",
        route: `/organisations/${slug}?manage=users`
      },
      {
        title: "Commission rates",
        description: "Commission rates for organisations",
        route: COMMISSION_RATES.path
      },
      {
        title: "Provider contact details",
        description: "Details of all available providers on the 4C Platform",
        route: "/provider-contact-details"
      }
    ],
    colour: colours.adminBlue,
    icon: "text-box-multiple-outline",
    route: "/my-account?my-account=details"
  }
]

export const getSalesAdviserDash = slug => [
  {
    title: "Leads",
    links: [
      {
        title: "Quick Quote",
        description: "Create a quick quote based on the date of birth and postcode",
        route: "/quick-quote"
      },
      {
        title: "Create new lead",
        description: "Create new leads for this account",
        route: "/leads/add"
      },
      {
        title: "Manage leads",
        description: "Manage leads for this account",
        route: "/leads"
      },
      {
        title: "Reports",
        description: "Manage information, screen, print and download",
        route: "/reports"
      }
    ],
    colour: colours.blue,
    icon: "clipboard-account-outline",
    route: "/leads"
  },
  {
    title: "Quotes",
    links: [
      {
        title: "Journeys in progress",
        description: "Journeys in progress",
        route: "/journeys?status=IN_PROGRESS"
      },
      {
        title: "Quotes completed",
        description: "All quotes completed to date",
        route: `/organisations/${slug}?manage=client_journeys&status=COMPLETE`
      },
      {
        title: "Manage clients",
        description: "View all clients in this account",
        route: "/clients"
      }
    ],
    colour: colours.quotesBlue,
    icon: "map-marker-path",
    route: null
  },
  {
    title: "Policies",
    links: [
      {
        title: "All policies",
        description: "All policies sold to date",
        route: "/policies?tab=ALL_POLICIES&status=SOLD"
      },
      {
        title: "Awaiting terms",
        description: "All quotes awaiting terms",
        route: "/policies?status=AWAITING_TERMS"
      },
      {
        title: "Awaiting acceptance",
        description: "All policies awaiting final terms and policy numbers",
        route: "/policies?status=AWAITING_ACCEPTANCE"
      },
      {
        title: "Failed to onboard",
        description: "All policies that failed onboarding",
        route: "/policies?status=FAILED_ONBOARDING"
      }
    ],
    colour: colours.policiesPurple,
    icon: "format-list-checks",
    route: "/policies"
  },
  {
    title: "Admin",
    links: [
      {
        title: "My account",
        description: "Manage your account settings and quick quote preferences",
        route: "/my-account?my-account=details"
      },
      {
        title: "Commission rates",
        description: "Commission rates for organisations",
        route: COMMISSION_RATES.path
      },
      {
        title: "Provider contact details",
        description: "Details of all available providers on the 4C Platform",
        route: "/provider-contact-details"
      }
    ],
    colour: colours.adminBlue,
    icon: "text-box-multiple-outline",
    route: "/my-account?my-account=details"
  }
]
