import React, { useContext } from "react"
import { Checkbox, Select } from "@4cplatform/elements/Forms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { PoliciesContext } from "./policies.context"

// Components
import { Search } from "../../Molecules"
import { TableActionsWrapper, TableActionsLeft, TableActionsRight } from "./policies.styles"

const Actions = () => {
  const {
    setSearch,
    search,
    showSimulated,
    setShowSimulated,
    hasShowSimulated,
    hasStatusFilter,
    status,
    setStatus,
    filter,
    setFilter,
    currentTab
  } = useContext(PoliciesContext)
  const t = useTranslations()
  const isAllPoliciesTab = currentTab === "ALL_POLICIES"

  const statuses = isAllPoliciesTab
    ? ["ACCEPTED", "AWAITING_ACCEPTANCE"]
    : [
        "ACCEPTED",
        "ACCEPTED_UNDERWRITING",
        "ACCEPTED_UNDERWRITING_WITH_EXCLUSIONS",
        "AWAITING_ACCEPTANCE",
        "AWAITING_TERMS",
        "MORE_INFO_REQUIRED",
        "DECLINED",
        "DECLINED_UNDERWRITING",
        "FAILED_ONBOARDING"
      ]

  return (
    <TableActionsWrapper
      data-testid="policies-actions-wrapper"
      hasLeftActions={hasShowSimulated || hasStatusFilter}
    >
      <TableActionsLeft>
        {hasShowSimulated && (
          <Checkbox
            label="Show simulated policies"
            margin="0 1rem 0 0"
            name="showSimulated"
            value={showSimulated}
            onChange={setShowSimulated}
            data-testid="policy-admin-show-simulated"
          />
        )}
        {hasStatusFilter && (
          <Select
            name="filter_status"
            onChange={val => setStatus(val)}
            margin="0 1rem"
            value={status.length <= 1 ? status[0] : "SOLD"}
            label="Filter status by"
            labelWidth="auto"
            isHorizontal
          >
            <option value={isAllPoliciesTab ? "SOLD" : ""}>All policies</option>
            {statuses.map(key => (
              <option key={key} value={key}>
                {t(key)}
              </option>
            ))}
          </Select>
        )}
      </TableActionsLeft>
      <TableActionsRight>
        <Select
          name="filter_search"
          onChange={val => setFilter(val)}
          margin="0 1rem 0 0"
          value={filter}
          label="Search by"
          labelWidth="auto"
          isHorizontal
        >
          <option value="client_name">Client name</option>
          <option value="organisation_name">Organisation</option>
          <option value="sales_agent_name">Sales Agent</option>
          <option value="provider_name">Provider</option>
          <option value="reference">Policy Reference</option>
        </Select>
        <Search
          name="search_policies"
          handleChange={setSearch}
          onCancel={() => setSearch("")}
          margin="0"
          defaultValue={search}
        />
      </TableActionsRight>
    </TableActionsWrapper>
  )
}

export default Actions
