import React, { useContext } from "react"
import { AuthContext } from "@4cplatform/elements/Auth"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"

// Components
import { Tabs, Tab } from "../../../Organisms"
import CommissionRates from "../components/CommisionRates"
import DealCodeAssignment from "../components/DealCodeAssignment"

const AgencyCodesPanelBody = () => {
  const { viewLoading, assignedDealCodesLoading, pendingType, bodyTabId, setBodyTabId } =
    useContext(AgencyCodesContext)
  const isLoading = viewLoading || assignedDealCodesLoading
  const { canAccess } = useContext(AuthContext)

  return (
    <PanelBody>
      <Tabs
        type="panel"
        name="agency_codes_panel"
        isLoading={isLoading}
        defaultIndex={bodyTabId}
        newActiveID={bodyTabId}
        margin="0 0 1rem"
        onTabChange={key => setBodyTabId(key)}
      >
        <Tab
          header="Commission"
          isPresent={canAccess([
            "SYS_ADMIN",
            "SUPPORT_ADMIN",
            "PROVIDER_ADMIN",
            "ORG_ADMIN",
            "NETWORK_ADMIN",
            "NETWORK_MEMBER_ADMIN"
          ])}
        >
          <CommissionRates shouldHideSecondaryCommissionRate={pendingType !== "false"} />
        </Tab>
        <Tab header="Deal code" isPresent={canAccess(["SYS_ADMIN", "SUPPORT_ADMIN"])}>
          <DealCodeAssignment />
        </Tab>
      </Tabs>
    </PanelBody>
  )
}

export default AgencyCodesPanelBody
