import { get, isEmpty, isArray, find, isPlainObject, isString, isNumber, isBoolean } from "lodash"
import { fileNamesObject } from "../../Templates/Journey/components/FileUploader"

/**
 * This function constructs the page's audit log.
 *
 * @param {*} journeyData
 * @param {*} formikValues
 * @param {*} auditData
 *
 * @returns
 */
export const getPageAudit = ({ journeyData, formikValues, auditData: auditDataRaw }) => {
  // Get page sections
  const sections = get(journeyData, "page.sections", [])
  // Responses array
  let responses = []

  // Iterate over the sections
  sections.forEach(section => {
    // Get the components in each section, skipping those with skipDataMap
    const components = get(section, "components", []).filter(
      component => !get(component, "skipDataMap")
    )
    // Iterate over the components
    components.forEach(component => {
      const fieldKey = get(component, "key")
      const fieldValue = get(formikValues, fieldKey)

      const conditionType = get(component, "condition.type")
      const conditionFieldKey = get(component, "condition.fieldKey")
      const conditionFieldExpectedValue = get(component, "condition.fieldValue")
      const conditionComparitor = get(component, "condition.comparitor")
      const isConditionFormik = conditionType === "formik"
      const conditionFieldValue = get(formikValues, conditionFieldKey)

      // Function that allows us to
      const evaluateFieldValue = value => {
        // The processed value
        let val

        // Check if the component has options
        const fieldOptions = get(component, "componentProps.options", [])
        const hasOptions = !isEmpty(fieldOptions)

        // If it's string or number and doesn't have options array, give it the value.
        if ((isString(value) || isNumber(value)) && !hasOptions) {
          val = value
        }

        // If it's boolean and doesn't have options array, default to Yes and No.
        if (isBoolean(value) && !hasOptions) {
          val = value ? "Yes" : "No"
        }

        // If it's boolean, string or number and has the options array, get the label of the option.
        if ((isBoolean(value) || isString(value) || isNumber(value)) && hasOptions) {
          val = get(find(fieldOptions, { value }), "label")
        }

        // If it's an array
        if (Array.isArray(value)) {
          // Make the value an array
          val = []
          // Iterate over the array
          value.forEach((entry, index) => {
            // Push into the value array
            val.push({
              // The label as the name
              name: `${get(component, "label") || get(component, "key") || ""} Entry ${index + 1}`,
              // Recursion to get the readable value
              value: evaluateFieldValue(entry)
            })
          })
        }

        // If it's a plain object
        if (isPlainObject(value)) {
          // Make the value an array
          val = []
          // Get the labels
          const labels = get(component, "labels", {})
          // Iterate over each label key
          Object.keys(labels).forEach(key => {
            // Push into the value array
            val.push({
              // The label as the name
              name: get(labels, key),
              // Recursion to get the readable value
              value: evaluateFieldValue(get(value, key))
            })
          })
        }

        // Return the processed value
        return val
      }

      if (
        !isConditionFormik ||
        (isConditionFormik &&
          ((conditionComparitor === "===" && conditionFieldValue !== conditionFieldExpectedValue) ||
            (conditionComparitor === "!==" &&
              conditionFieldValue === conditionFieldExpectedValue) ||
            (!conditionComparitor && conditionFieldValue === conditionFieldExpectedValue)))
      ) {
        const name =
          get(component, "auditLabel") || get(component, "label") || get(component, "key") || ""

        responses.push({
          name,
          value: evaluateFieldValue(fieldValue)
        })
      }
    })
  })

  const auditData = (auditDataRaw || []).map(auditDataItem => ({
    ...auditDataItem,
    data: auditDataItem.isGroupByArray
      ? auditDataItem?.data || []
      : (auditDataItem?.data || []).map(auditDataItemItem => ({
          ...auditDataItemItem,
          ...(auditDataItemItem?.name === "File uploaded"
            ? { value: fileNamesObject.files || "-" }
            : {})
        }))
  }))

  let isMultiCall = false
  let isGroupByArray = false
  if (isArray(auditData) && auditData.length) {
    auditData.forEach(auditDataItem => {
      switch (auditDataItem.mode) {
        case "append":
          responses = [...responses, ...(auditDataItem?.data || [])]
          break

        case "unshift":
          responses = [...(auditDataItem?.data || []), ...responses]
          break

        case "replace":
          responses = auditDataItem?.data || []
          break

        case "custom":
          responses = responses.map(response => {
            const newResponse = (auditDataItem?.data || []).find(a => a?.name === response?.name)

            if (newResponse) {
              return newResponse
            }

            return response
          })
          break

        case "insert":
          if (responses.findIndex(response => response?.name === auditDataItem?.name) !== -1) {
            responses.splice(
              responses.findIndex(response => response?.name === auditDataItem?.name),
              auditDataItem?.deleteCount || 0,
              ...(auditDataItem?.data || [])
            )
          }
          break

        case "remove":
          responses = responses.filter(
            response => !(auditDataItem?.data || []).map(item => item.name).includes(response?.name)
          )
          break

        default:
          break
      }

      if (auditDataItem.isMultiCall) {
        isMultiCall = true
      }
      if (auditDataItem.isGroupByArray) {
        isGroupByArray = true
      }
    })
  }

  // Push dummy name and value keyed object for page submission.
  if (isEmpty(responses)) {
    responses.push({
      name: "Submission of page with no fields",
      value: "Yes"
    })
  }

  // Return the page and responses keyed object to be added to the body.
  return {
    page: get(journeyData, "page.title"),
    responses,
    ...(isMultiCall ? { isMultiCall } : {}),
    ...(isGroupByArray ? { isGroupByArray } : {})
  }
}
