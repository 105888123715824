import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useGet, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { PageContext } from "@4cplatform/elements/Organisms"
import { addAlert } from "@4cplatform/elements/Alerts"

// Components
import { Provider } from "../../../../../../../UI/Templates/AgencyCodes"

// Helpers
import { getOrderBy } from "../../../../../../../UI/Helpers"
import { preserveProvider } from "../../../../../../../UI/Templates/AgencyCodes/agencyCodes.helpers"
import reducer from "./agencyCodes.reducer"

const AgencyCodesProvider = ({ pendingType, children }) => {
  const { selfServiceData: user } = React.useContext(PageContext)
  const t = useTranslations()

  // State
  const [{ data, search, sorting, page, perPage, total, addRequestModal }, dispatch] =
    React.useReducer(reducer, {
      data: [],
      search: "",
      sorting: { direction: "asc", dataKey: "provider" },
      page: 1,
      perPage: 10,
      total: null,
      addRequestModal: false
    })

  let conditionalQueryParams = {}

  if (search.length >= 3) {
    conditionalQueryParams.search = preserveProvider(search)
  } else {
    conditionalQueryParams = {}
  }

  // Index Agency Codes query
  const {
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch
  } = useGet({
    endpoint: "/users/:slug/agency-codes",
    skip: !get(user, "slug", null),
    params: {
      slug: get(user, "slug", "")
    },
    query: {
      limit: perPage,
      order_by: getOrderBy(sorting),
      page,
      ...conditionalQueryParams
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", {})
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("AGENCY_CODES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        sorting,
        page,
        perPage,
        total,
        queryLoading,
        queryRefetch,
        search,
        pendingType,
        setSearch,
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        pagination: { total, page, perPage },
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        addRequestModal,
        setAddRequestModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "addRequestModal", value: val })
      }}
    >
      {children}
      <ApiError error={queryError} />
    </Provider>
  )
}

AgencyCodesProvider.defaultProps = {
  pendingType: "false",
  children: null
}

AgencyCodesProvider.propTypes = {
  pendingType: PropTypes.string,
  children: PropTypes.any
}

export default AgencyCodesProvider
