import styled, { createGlobalStyle } from "styled-components"
import { get } from "lodash"

export const GlobalStyles = createGlobalStyle`
  body {
    width: auto;
    height: auto;
    overflow: auto;
  }

  #page-content {
    overflow-y: initial;
    overflow-x: hidden;
  }
`

export const Wrapper = styled.div`
  padding: 3rem 0;
  background-color: ${({ theme }) => get(theme, "white", "white")};
`
