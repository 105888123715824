import React, { useState, useContext } from "react"
import { get } from "lodash"
import { Table } from "@4cplatform/elements/Organisms"
import { Checkbox } from "@4cplatform/elements/Forms"

// Helpers
import { JourneyContext } from "../../../../../../journey.context"
import { HospitalPreferenceContext } from "./hospitalPreference.context"

// Components
import { Typeahead } from "../../../../../../../../Forms"
import { TableWrapper } from "./hospitalPreference.styles"

const HospitalPreferenceTable = () => {
  const {
    onHospitalPreferenceSelect,
    selectedHospital,
    pagination,
    setPerPage,
    setPage,
    tablePaginatedData,
    hospitalsLoading,
    isDataLoading,
    hospitalsWithDistance
  } = useContext(HospitalPreferenceContext)
  const [searchValue, setSearchValue] = useState()
  const { data } = useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)

  return (
    <TableWrapper>
      <Typeahead
        name="search_hospitals"
        label="Search hospitals"
        onSelect={opt => {
          if (opt.hospital_provider_name !== null) onHospitalPreferenceSelect(opt.data)
        }}
        onChange={val => setSearchValue(val)}
        val={searchValue}
        suggestions={hospitalsWithDistance.map(hospital => {
          const { id, name } = hospital
          return {
            id,
            label: name,
            data: hospital,
            subHeader: `${hospital.distance} Miles`,
            helperText:
              hospital.hospital_provider_name === null ? "Not available with any provider" : null
          }
        })}
        hasSearch
        hasCancel={!!searchValue}
        onCancel={() => setSearchValue("")}
        isDisabled={hospitalsLoading || isDataLoading || isLocked}
        shouldClearOnSubmit
      />
      <Table
        data={tablePaginatedData}
        isLoading={hospitalsLoading || isDataLoading}
        name="hospitals"
        columns={[
          {
            label: "Hospital",
            dataKey: "name",
            minWidth: "150px",
            flexBasis: 60
          },
          {
            label: "Distance (miles)",
            dataKey: "distance",
            minWidth: "70px",
            flexBasis: 20
          },
          {
            label: "Choose",
            minWidth: "75px",
            flexBasis: 20,
            render: row => (
              <Checkbox
                key={row.data.id}
                name="style_new"
                onChange={() => onHospitalPreferenceSelect(row.data)}
                margin="0 2rem 0 0"
                value={selectedHospital && selectedHospital.id === row.data.id}
                isDisabled={isLocked}
              />
            )
          }
        ]}
        hasActions={false}
        pagination={pagination}
        paginationPagesToShow={5}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
      />
    </TableWrapper>
  )
}

export default HospitalPreferenceTable
