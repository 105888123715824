import React from "react"
import { get, isEmpty } from "lodash"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { P } from "@4cplatform/elements/Typography"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { AgencyCodesContext } from "../../agencyCodes.context"
import { FlexRow, FlexWrapper } from "./acceptanceButtons.styles"

const DeleteAgencyCode = () => {
  const { viewData, setDeleteModal, onDelete, deleteLoading, isAllLoading } =
    React.useContext(AgencyCodesContext)
  const sharedWith = get(viewData, "shared_with") ?? []

  return (
    <>
      <ConfirmationModal
        title="Delete Agency Code"
        confirmText="Delete"
        confirmAppearance="error"
        confirmIcon="delete"
        cancelText="Cancel"
        cancelAppearance="errorGhost"
        isLoadingConfirm={deleteLoading}
        onClose={() => {
          isAllLoading || setDeleteModal(false)
        }}
        onConfirm={onDelete}
        onCancel={() => {
          isAllLoading || setDeleteModal(false)
        }}
      >
        <P>
          Are you sure you want to delete the Agency Code{" "}
          <strong>{get(viewData, "agency_code")}</strong> ?
        </P>
        <P>This agency code is currently shared to the following</P>
        <FlexWrapper>
          <FlexRow>
            <IconWithText
              icon={
                // eslint-disable-next-line no-nested-ternary
                get(viewData, "owner.type") === "NETWORK"
                  ? "globe-simple"
                  : get(viewData, "owner.type") === "ORGANISATION"
                  ? "office-building-outline"
                  : "account"
              }
              iconSpacing="2rem"
              margin="1.2rem"
              appearance="dark"
              content={
                get(viewData, "owner.type") === "NETWORK" ||
                get(viewData, "owner.type") === "ORGANISATION"
                  ? get(viewData, "owner.attributes.name", "-")
                  : `${get(viewData, "owner.attributes.first_name", "-")} ${get(
                      viewData,
                      "owner.attributes.middle_names",
                      "-"
                    )} ${get(viewData, "owner.attributes.last_name", "-")}`
              }
            />
          </FlexRow>
          {!isEmpty(sharedWith) &&
            Array.isArray(sharedWith) &&
            sharedWith.map(shared => (
              <FlexRow>
                <IconWithText
                  icon={
                    get(shared, "linked_type") === "ORGANISATION"
                      ? "office-building-outline"
                      : "account"
                  }
                  iconSpacing="2rem"
                  margin="1.2rem"
                  appearance="dark"
                  content={get(shared, "name", "-")}
                />
              </FlexRow>
            ))}
        </FlexWrapper>
      </ConfirmationModal>
    </>
  )
}

export default DeleteAgencyCode
