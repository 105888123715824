import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import moment from "moment"
import { IconWithText, LabelWithText } from "@4cplatform/elements/Atoms"
import { PanelHeader } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { H2, P } from "@4cplatform/elements/Typography"

import ProviderLogo from "./commissionRates.panel.header.logo"
import { Divider } from "./commissionRates.styles"

const CommissionRatesPanelHeader = ({ selectedCommissionRate, isDeleted, isLoading }) => (
  <PanelHeader isDeleted={isDeleted}>
    <ProviderLogo
      src={get(selectedCommissionRate, "logo_file_path")}
      alt={`${get(selectedCommissionRate, "provider")} branding`}
      size="7.8rem"
      isLoading={isLoading}
    />
    <H2 margin="1rem 0" appearance="light" isLoading={isLoading}>
      {get(selectedCommissionRate, "provider")}
    </H2>

    <IconWithText
      icon="account"
      iconSize="1.5rem"
      appearance="light"
      content={get(selectedCommissionRate, "organisation")}
      fontSize="1.6rem"
      isLoading={isLoading}
      margin="0"
    />
    <IconWithText
      icon="subdirectory-arrow-right"
      appearance="light"
      content={get(selectedCommissionRate, "user.full_name")}
      fontSize="1.6rem"
      isLoading={isLoading}
      margin="0 0 1rem 1rem"
    />
    <IconWithText
      icon="percent-outline"
      iconSize="1.5rem"
      appearance="light"
      content={get(selectedCommissionRate, "commission_rate")}
      fontSize="1.6rem"
      isLoading={isLoading}
      margin="0 0 2rem 1rem"
    />

    <Divider />

    <LabelWithText label="Start date" appearance="light" isLoading={isLoading}>
      <P appearance="light" margin="0">
        {get(selectedCommissionRate, "start_date")
          ? moment(get(selectedCommissionRate, "start_date"), "YYYY-MM-DDTHH:mmZ").format(
              "DD/MM/YYYY"
            )
          : "-"}
      </P>
    </LabelWithText>

    <LabelWithText label="End date" appearance="light" isLoading={isLoading}>
      <P appearance="light" margin="0">
        {get(selectedCommissionRate, "end_date")
          ? moment(get(selectedCommissionRate, "end_date"), "YYYY-MM-DDTHH:mmZ").format(
              "DD/MM/YYYY"
            )
          : "-"}
      </P>
    </LabelWithText>
  </PanelHeader>
)

CommissionRatesPanelHeader.defaultProps = {
  selectedCommissionRate: null,
  isDeleted: false,
  isLoading: true
}

CommissionRatesPanelHeader.propTypes = {
  selectedCommissionRate: PropTypes.object,
  isDeleted: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default CommissionRatesPanelHeader
