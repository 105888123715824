import React, { useEffect } from "react"
import moment from "moment"
import { get } from "lodash"
import { object, number, string } from "yup"
import { Select, Input, TextArea, DatePicker } from "@4cplatform/elements/Forms"
import { DisclosureNotesContext } from "../../../../../../DisclosureNotes/disclosureNotes.context"
import { JourneyContext } from "../../../../../../../journey.context"

const NoteFormTemplate = () => {
  const {
    applicants,
    disclosureNoteFormik,
    setNoteValidationSchema,
    setAddEditModal,
    addEditModal
  } = React.useContext(DisclosureNotesContext)
  const { data } = React.useContext(JourneyContext)
  const isLocked = get(data, "journey.locked", false)

  const formik = {
    ...disclosureNoteFormik
  }

  useEffect(() => {
    setNoteValidationSchema(
      object({
        journey_applicant_id: number().integer().positive().required("PLEASE_SPECIFY_AN_APPLICANT"),
        illness_or_medical_problem: string().required("MISSING_REQUIRED_FIELD"),
        date_of_symptoms_began: string().required("MISSING_REQUIRED_FIELD"),
        date_of_symptoms_ended: string()
          .when(["date_of_symptoms_began"], {
            is: value => !!value,
            then: schema =>
              schema.test(
                "is-greater",
                "End date can not be before the date symptoms began and must be in the past",
                (value, context) => {
                  if (value) {
                    const dateOfSymptomsBegan = get(context, "parent.date_of_symptoms_began", "")
                    return new Date(value) > new Date(dateOfSymptomsBegan)
                  }
                  return true
                }
              )
          })
          .nullable(),
        treatment: string().required("MISSING_REQUIRED_FIELD"),
        current_state_of_condition_or_symptom: string().required("MISSING_REQUIRED_FIELD"),
        number_of_consults_in_past_two_years: number()
      })
    )
    if (addEditModal.type === "new")
      setAddEditModal({
        ...addEditModal,
        noteInitialValues: {
          journey_applicant_id: "",
          illness_or_medical_problem: "",
          date_of_symptoms_began: "",
          date_of_symptoms_ended: "",
          treatment: "",
          current_state_of_condition_or_symptom: "",
          number_of_consults_in_past_two_years: 0
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const maxDateRange = moment().subtract(1, "days").format("DD/MM/YYYY")

  const getConsultationMinDate = React.useMemo(() => {
    if (formik.values && formik.values.journey_applicant_id) {
      const applicantData = applicants.find(
        applicant => applicant.id === JSON.parse(formik.values.journey_applicant_id)
      )
      return moment(applicantData.date_of_birth).format("DD/MM/YYYY")
    }
    return ""
  }, [applicants, formik.values])

  const getTreatementMinDate = React.useMemo(() => {
    if (formik.values && formik.values.date_of_symptoms_began) {
      return moment(formik.values.date_of_symptoms_began).format("DD/MM/YYYY")
    }
    return ""
  }, [formik.values])

  return (
    <>
      <Select
        name="journey_applicant_id"
        label="Select Member"
        formik={formik}
        isRequired
        isDisabled={isLocked}
        errorPrefix="none"
      >
        <option value="">Select</option>
        {applicants.map(applicant => (
          <option value={get(applicant, "id")} key={get(applicant, "id")}>
            {get(applicant, "first_name")} {get(applicant, "last_name")}
          </option>
        ))}
      </Select>

      <TextArea
        name="illness_or_medical_problem"
        formik={formik}
        label="Please describe the illness or medical problem. If applicable please specify which area of the body is affected (eg left, right, upper, lower)"
        isRequired
        isDisabled={isLocked}
      />
      <DatePicker
        name="date_of_symptoms_began"
        formik={formik}
        label="When did the symptoms begin?"
        dateRangeMin={getConsultationMinDate}
        dateRangeMax={maxDateRange}
        isRequired
        isDisabled={isLocked}
      />
      <DatePicker
        name="date_of_symptoms_ended"
        formik={formik}
        label="When did the symptoms end?"
        helperText="If ongoing please leave end date blank"
        dateRangeMin={getTreatementMinDate}
        dateRangeMax={maxDateRange}
        isDisabled={!formik.values?.date_of_symptoms_began || isLocked}
      />
      <TextArea
        name="treatment"
        formik={formik}
        margin="0 0 2rem 0"
        label="Treatment (prescribed or otherwise)"
        isRequired
        isDisabled={isLocked}
      />
      <Select
        name="current_state_of_condition_or_symptom"
        label="Current state of condition / symptom"
        formik={formik}
        isRequired
        isDisabled={isLocked}
      >
        <option value="">Select</option>
        <option value="Ongoing">Ongoing</option>
        <option value="Controlled">Controlled</option>
        <option value="Recurrent">Recurrent</option>
        <option value="Likely to recur">Likely to recur</option>
        <option value="Fully recovered">Fully recovered</option>
      </Select>
      <Input
        name="number_of_consults_in_past_two_years"
        label="How many times have you consulted a healthcare professional in the past 2 years about this symptom/condition?"
        formik={formik}
        type="number"
        min={0}
        isDisabled={isLocked}
      />
    </>
  )
}

export default NoteFormTemplate
