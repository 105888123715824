import React from "react"
import { isEmpty } from "lodash"
import { v4 as uuid } from "uuid"
import { Button, Modal, Pagination } from "@4cplatform/elements/Molecules"
import { P, H4 } from "@4cplatform/elements/Typography"

// Helpers
import { LeadsContext } from "./leads.context"

// Components
import { Note } from "../../Molecules"
import { Notes } from "../../Organisms"
import { NotesFlyOutWrapper, LeadNotesWrapper } from "./leads.styles"

const LeadNotes = () => {
  const {
    leadNotes,
    notesModal,
    setNotesModal,
    notesLoading,
    onAddNote,
    noteCreateLoading,
    notesPagination,
    setNotesPage,
    selectLoading
  } = React.useContext(LeadsContext)

  const hasNotes = !isEmpty(leadNotes)

  return (
    <>
      <LeadNotesWrapper>
        <H4
          appearance="light"
          margin={!hasNotes && !selectLoading ? "0" : "0 0 2rem"}
          isLoading={selectLoading}
        >
          Notes
        </H4>
        <NotesFlyOutWrapper hasNotes={hasNotes}>
          {!hasNotes && (
            <P appearance="light" margin="3rem auto" isLoading={selectLoading} loadingLines={1}>
              No notes to display.
            </P>
          )}
          {hasNotes &&
            leadNotes.map((item, index) => (
              <Note
                key={uuid()}
                body={item?.body}
                timestamp={item?.created_at}
                firstName={item?.created_by.first_name}
                lastName={item?.created_by.last_name}
                isNoteTab
                isLoading={selectLoading || notesLoading}
                isFirst={index === 0}
                isLast={index === leadNotes.length - 1}
              />
            ))}
          <Pagination
            {...notesPagination}
            changePage={e => setNotesPage(e)}
            hasPerPage
            appearance="light"
            shouldOnlyShowPaginationBar
          />
        </NotesFlyOutWrapper>
        <Button
          appearance="whiteGhost"
          name="add_note"
          trailingIcon="playlist-plus"
          onClick={() => setNotesModal(true)}
          isLoading={selectLoading || notesLoading}
        >
          Add note
        </Button>
      </LeadNotesWrapper>

      {notesModal && (
        <Modal
          title="Notes"
          onClose={() => setNotesModal(false)}
          name="notes_modal"
          hasPadding={false}
        >
          <Notes
            width="100%"
            hasHeader={false}
            isLoading={notesLoading || noteCreateLoading}
            onAddNote={str => onAddNote(str)}
            onAddNoteCancel={() => setNotesModal(false)}
            data-testid="notes-block"
            notes={
              !Array.isArray(leadNotes)
                ? []
                : leadNotes.map(item => {
                    const { body: note, created_by: user, created_at: created } = item
                    return { id: uuid(), note, user, created_at: created }
                  })
            }
            pagination={notesPagination}
            changePage={e => setNotesPage(e)}
          />
        </Modal>
      )}
    </>
  )
}

export default LeadNotes
