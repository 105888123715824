import React, { useContext } from "react"
import { get, keys, isEmpty } from "lodash"
import { useFormik } from "formik"
import { H2, P } from "@4cplatform/elements/Typography"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"
import { useTranslations } from "@4cplatform/elements/Translations"
import { Toggle } from "@4cplatform/elements/Forms"
import { PanelHeader } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { QuoteComparisonContext } from "./quoteComparison.context"
import {
  getCurrentOptions,
  mapGlobalOptionsToValidationSchema,
  getQuoteEditOptionHelperText
} from "./quoteComparison.helpers"

// Components
import { OptionsWrapper, PanelBody, PanelFooter, RegenerateButton } from "./quoteComparison.styles"
import { JourneyContext } from "../../../../../../journey.context"

const QuoteComparisonConfigure = () => {
  const t = useTranslations()
  const {
    isLoading,
    globalOptions,
    selectedQuotes,
    onRegenerateQuotes,
    initCurrentPolicyOptions,
    regenerateModal,
    setRegenerateModal
  } = useContext(QuoteComparisonContext)
  const { data } = useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)
  const initUnderWritingType = get(initCurrentPolicyOptions, "cp_underwriting")

  const validationSchema = mapGlobalOptionsToValidationSchema(globalOptions)

  // Configure quotes formik instance
  const configureQuotesFormik = useFormik({
    initialValues: getCurrentOptions(globalOptions),
    validationSchema,
    enableReinitialize: true,
    onSubmit: body => onRegenerateQuotes(body)
  })

  // eslint-disable-next-line no-unused-vars
  const formik = {
    ...configureQuotesFormik,
    validationSchema
  }

  const uwStyleFormik = get(formik, "values.UW_STYLE")
  const isNewUwStyleGlobal = get(globalOptions, "UW_STYLE.NEW.selected", false)
  const isSwitchUwStyleGlobal = get(globalOptions, "UW_STYLE.SWITCH.selected", false)

  React.useEffect(() => {
    if (uwStyleFormik === "SWITCH" && !isSwitchUwStyleGlobal) {
      if (initUnderWritingType && get(formik, "values.UW_TYPE") !== initUnderWritingType) {
        formik.setFieldValue("UW_TYPE", initUnderWritingType)
      }
    }
    if (uwStyleFormik === "NEW" && !isNewUwStyleGlobal) {
      if (get(formik, "values.UW_TYPE") !== "MORI") {
        formik.setFieldValue("UW_TYPE", "MORI")
      }
    }
    // eslint-disable-next-line
  }, [uwStyleFormik, initUnderWritingType])

  return (
    <>
      <PanelHeader isDeleted={isLoading}>
        <H2 margin="3rem 0 3rem" appearance="light">
          Configure quotes
        </H2>
      </PanelHeader>
      <PanelBody>
        <OptionsWrapper>
          {!isEmpty(globalOptions) &&
            keys(globalOptions)
              .sort((a, b) => {
                const sortingArray = [
                  "UW_STYLE",
                  "UW_TYPE",
                  "HOSPITAL_LIST",
                  "OUTPATIENT",
                  "EXCESS"
                ]
                return sortingArray.indexOf(a) - sortingArray.indexOf(b)
              })
              .map(key => {
                const options = keys(get(globalOptions, key)).map((option, i) => ({
                  order: i,
                  label: t(option),
                  value: option
                }))

                if (key === "UW_TYPE") {
                  if (uwStyleFormik === "NEW") {
                    return (
                      <Toggle
                        key={key}
                        limit={4}
                        isHorizontal
                        appearance="light-inverse"
                        labelWidth="25rem"
                        label={t(key)}
                        name={key}
                        formik={formik}
                        options={[
                          {
                            order: 0,
                            label: t("FMU"),
                            value: "FMU"
                          },
                          {
                            order: 1,
                            label: t("MORI"),
                            value: "MORI"
                          }
                        ]}
                        isDisabled={isLocked}
                        {...getQuoteEditOptionHelperText(key)}
                      />
                    )
                  }

                  if (uwStyleFormik === "SWITCH" && initUnderWritingType) {
                    return (
                      <Toggle
                        key={key}
                        limit={4}
                        isHorizontal
                        appearance="light-inverse"
                        labelWidth="25rem"
                        label={t(key)}
                        name={key}
                        formik={formik}
                        options={[
                          {
                            order: 0,
                            label: t(initUnderWritingType),
                            value: initUnderWritingType
                          }
                        ]}
                        isDisabled
                        {...getQuoteEditOptionHelperText(key)}
                      />
                    )
                  }
                }

                return (
                  <Toggle
                    key={key}
                    limit={4}
                    isHorizontal
                    appearance="light-inverse"
                    labelWidth="25rem"
                    label={t(key)}
                    name={key}
                    formik={formik}
                    options={options}
                    isDisabled={options.length <= 1 || isLocked}
                    {...getQuoteEditOptionHelperText(key)}
                  />
                )
              })}
        </OptionsWrapper>
        {formik.dirty && (
          <PanelFooter>
            <P width="75%" margin="0" appearance="light">
              One or more options have changed.
              <br /> Please Regenerate quotes to refresh or close the panel to return to previous
              quotations.
            </P>
            <RegenerateButton
              appearance="success"
              trailingIcon="autorenew"
              onClick={() => {
                if (!isEmpty(selectedQuotes)) {
                  setRegenerateModal(true)
                } else {
                  formik.handleSubmit()
                }
              }}
              name="regenerate_quotes"
              width="22rem"
              isDisabled={isLocked}
            >
              Regenerate quotes
            </RegenerateButton>
          </PanelFooter>
        )}
      </PanelBody>
      {regenerateModal && (
        <ConfirmationModal
          confirmIcon="chevron-right"
          confirmText="Yes"
          confirmAppearance="success"
          cancelAppearance="error"
          onClose={() => setRegenerateModal(false)}
          onConfirm={() => {
            setRegenerateModal(false)
            formik.handleSubmit()
          }}
          isDisabledConfirm={isLocked}
        >
          <P>
            <strong>
              {selectedQuotes.length} {selectedQuotes.length > 1 ? "quotes are " : "quote is "}
              selected.
            </strong>
            <br />
            Are you sure you want to regenerate quotes? Your previous quotations will be lost.
          </P>
        </ConfirmationModal>
      )}
    </>
  )
}

export default QuoteComparisonConfigure
