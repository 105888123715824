import React, { useContext } from "react"
import { isEmpty } from "lodash"
import { v4 as uuid } from "uuid"
import { PanelBody, Button, Pagination } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"

// Helpers
import { ClientsContext } from "../../clients.context"

// Components
import { Note } from "../../../../Molecules"
import { NotesFlyOutWrapper, ClientNotesWrapper } from "../../clients.styles"
import ClientsPanelHeader from "../../clients.panel.header"
import ClientsPanelButtons from "../../clients.panel.body.buttons"
import NotesModal from "./notes.modal"

const ClientsNotesTab = () => {
  const {
    viewLoading,
    clientNotes,
    getNotesLoading,
    addNoteLoading,
    notesModal,
    setNotesModal,
    notesPagination,
    setNotesPage
  } = useContext(ClientsContext)
  const hasNotes = !isEmpty(clientNotes)

  return (
    <>
      <ClientsPanelHeader isEdit={false} isWidePanel={false} />
      <PanelBody>
        <ClientsPanelButtons />
        <ClientNotesWrapper>
          <NotesFlyOutWrapper hasNotes={hasNotes}>
            {!hasNotes && (
              <P
                appearance="light"
                margin="3rem auto"
                isLoading={viewLoading || getNotesLoading}
                loadingLines={1}
              >
                No notes to display.
              </P>
            )}
            {hasNotes &&
              clientNotes.map((item, index) => (
                <Note
                  key={uuid()}
                  body={item?.body}
                  timestamp={item?.created_at}
                  firstName={item?.created_by?.first_name}
                  lastName={item?.created_by?.last_name}
                  isNoteTab
                  isLoading={viewLoading || getNotesLoading || addNoteLoading}
                  isFirst={index === 0}
                  isLast={index === clientNotes.length - 1}
                />
              ))}
            <Pagination
              {...notesPagination}
              changePage={e => setNotesPage(e)}
              hasPerPage
              appearance="light"
              shouldOnlyShowPaginationBar
            />
          </NotesFlyOutWrapper>
          <Button
            appearance="whiteGhost"
            trailingIcon="playlist-plus"
            onClick={() => {
              setNotesModal(true)
            }}
            isDisabled={viewLoading || getNotesLoading}
            name="add_note"
          >
            Add note
          </Button>
        </ClientNotesWrapper>
      </PanelBody>
      {notesModal && <NotesModal />}
    </>
  )
}
export default ClientsNotesTab
