import React from "react"
import PropTypes from "prop-types"

// Components
import { Navigation } from "./components"
import { Contact, Discover, Faq, Features, Hero } from "./panels"
import { GlobalStyles, Wrapper } from "./landingPage.styles"

const LandingPage = ({ additionalLinks, children }) => (
  <>
    <GlobalStyles />
    <Navigation additionalLinks={additionalLinks} />
    {children}
    <Wrapper>
      <Hero />
      <Discover />
      <Features />
      <Faq />
      <Contact />
    </Wrapper>
  </>
)

LandingPage.propTypes = {
  children: PropTypes.any,
  additionalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  )
}

export default LandingPage
