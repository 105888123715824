import styled, { createGlobalStyle } from "styled-components"
import { get } from "lodash"
import { SVG } from "@4cplatform/elements/Atoms/Icon/icon.styles"
import { Button } from "@4cplatform/elements/Molecules"
import { DatePicker, Select } from "@4cplatform/elements/Forms"

export const GlobalStyles = createGlobalStyle`
  @media print {
    body {
      -webkit-print-color-adjust: exact;
    }

    #page-wrapper {
      background-color: ${({ theme }) => get(theme, "white", "white")};
    } 

    header,
    nav,
    footer {
      display: none !important;
    } 

    main {
      width: 100% !important;
    }

    html,
    body,
    #root {
      height: 100% !important;
    }

    html,
    body,
    #root,
    #page-content {
      overflow-y: visible;
    }

    #panel_container {
      display: none;
    }
  }
`

export const ExportedReportWrapper = styled.div`
  & div ul {
    margin-bottom: 0;
  }

  & canvas {
    background-color: ${({ theme }) => get(theme, "white", "white")};
  }
`

const DivFlex = styled.div`
  display: flex;
`

export const FlexStretch = styled(DivFlex)`
  align-items: stretch;
  gap: 3.5rem;
  width: 70%;
  @media print {
    max-height: 160px;

    & canvas {
      width: 100% !important;
      height: 40px !important;
    }
  }
`

export const FlexAlignCenter = styled(DivFlex)`
  align-items: center;
  gap: 1.5rem;
`

export const FlexCenter = styled(FlexAlignCenter)`
  justify-content: center;
`

export const FlexAlignCenterSmallGap = styled(FlexAlignCenter)`
  gap: 1rem;
`

export const FlexColumn = styled(DivFlex)`
  flex-direction: column;
  gap: 1.5rem;
`

export const FlexColumnLargeGap = styled(FlexColumn)`
  gap: 2.5rem;
`

export const CardWrapper = styled.div`
  border-radius: 0.8rem;
  border: 2px solid ${({ theme }) => get(theme, "blue", "lightgrey")};
`

export const FilterWrapper = styled(CardWrapper)`
  width: 462px;
  margin-bottom: 1.5rem;
`

export const MetricCardWrapper = styled(CardWrapper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 12vw;
`

export const MetricCardHeader = styled.div`
  padding: 1.5rem;
  display: grid;
  height: 50%;
`

export const MetricCardBody = styled(FlexAlignCenter)`
  flex: 1;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 1.5rem 1.5rem 1.5rem;
  .line-chart {
    width: 80%;
  }
`

export const MetricCardFooter = styled.div`
  margin-top: auto;
  padding: 1.5rem;
  font-size: 14px;
  text-align: center;
  border-top: 2px solid ${({ theme }) => get(theme, "lightgrey", "lightgrey")};
`

export const ReportHeaderWrapper = styled.div`
  margin-top: 2.5rem;
`

export const FormGroup = styled.div`
  display: inline-flex;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`

export const SubTabWrapper = styled(DivFlex)`
  margin-top: 1.5rem;
  gap: 1rem;
`

export const DoughnutChartContainer = styled.div`
  width: 100%;
`

export const DoughnutChartWrapper = styled.div`
  width: 70%;
  max-width: 600px;
`

export const BarChartWrapper = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media print {
    & canvas {
      width: 100% !important;
      height: 400px !important;
    }
  }
`

export const RadioCheckMark = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => get(theme, "blue", "lightgrey")};
  background-color: ${({ appearance }) => (appearance === "light" ? "white" : "transparent")};
`

export const Radio = styled.input`
  &[type="radio"] {
    display: none;

    & ~ label {
      cursor: pointer;
    }

    &:checked ~ label {
      ${RadioCheckMark}::after {
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        display: inline-block;
        background-color: ${({ theme }) => get(theme, "blue", "lightgrey")};
      }
    }
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ appearance, theme }) =>
    appearance === "light" ? get(theme, "white") : "lightgrey"};
`

export const FilterLabelWrapper = styled(FlexCenter)`
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  color: ${({ theme }) => get(theme, "blue", "lightgrey")};
  border: 2px solid ${({ theme }) => get(theme, "blue", "lightgrey")};

  & ${SVG} {
    cursor: pointer;
  }
`

export const ButtonWrapper = styled(Button)`
  height: 33px;
  padding: 0;
  margin: 0 0 1rem;
  border: 2px solid #197da4;
  border-radius: 0.4rem;

  > div {
    margin-left: 0;
  }
`

export const SelectWrapper = styled(Select)`
  border-radius: 0.4rem;
`

export const DatePickerWrapper = styled(DatePicker)`
  border-radius: 0.4rem;
`

export const ConditionTypeHeadWrapper = styled.div`
  gap: 0.5rem;
  grid-template-columns: 0.35fr 0.65fr;
  display: grid;
  div {
    border: none;
  }
  button {
    text-align: left;
  }
`
