import React, { useEffect, useContext } from "react"
import moment from "moment"
import { get } from "lodash"
import { DatePicker } from "@4cplatform/elements/Forms"
import SmallText from "@4cplatform/elements/Typography/SmallText"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { JourneyContext } from "../../../../../../journey.context"

const StartDatePicker = () => {
  const t = useTranslations()
  const { formik, data: dataJourney, updateJourneyAuditData } = useContext(JourneyContext)
  const {
    page: { conditionals, data }
  } = dataJourney
  const invalidAvivaDate = ["29", "30", "31"]

  const renewalDate = get(data, "cp_renewal_date", false)

  const isLocked = get(dataJourney, "journey.locked", false)

  const skipAffordableBudget = get(data, "page.conditionals.skip_affordable_budget")
  const startDate = get(formik, "values.start_date", "")
  const budget = get(formik, "values.affordable_budget")

  useEffect(() => {
    if (formik.values && renewalDate && data.recommended_style === "SWITCH")
      formik.setFieldValue("start_date", renewalDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  useEffect(() => {
    if (startDate) {
      updateJourneyAuditData([
        {
          mode: "insert",
          name: "Affordable monthly budget",
          deleteCount: 1,
          data: [
            {
              name: "Affordable monthly budget £",
              value: `${parseFloat(budget).toFixed(2)}`
            }
          ]
        },
        {
          mode: "custom",
          data: [
            {
              name: "Start date",
              value: moment(startDate).format("DD/MM/YYYY")
            }
          ]
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipAffordableBudget, startDate, budget])

  const getHelperText = () => {
    if (data.recommended_style === "SWITCH") {
      return (
        <SmallText margin="0 0 0 30rem" width="calc(100% - 30rem)">
          The policy start date has been automatically set to the renewal date for the client's
          current policy.
        </SmallText>
      )
    }
    if (
      conditionals.access_to_aviva_agency_code &&
      formik.values.start_date &&
      invalidAvivaDate.includes(formik.values.start_date.split("-")[2])
    ) {
      return (
        <SmallText margin="0 0 0 30rem" width="calc(100% - 30rem)">
          Aviva policies cannot start on the 29th, 30th or 31st of the month. If you continue with
          the selected date, any quotes from Aviva in the Quote Comparison page will be for policies
          commencing on{" "}
          {moment(formik.values.start_date, "YYYY-MM-DD")
            .add(1, "M")
            .startOf("month")
            .format("DD/MM/YYYY")}
        </SmallText>
      )
    }
    if (
      data.recommended_style === "NEW" &&
      (data.recommended_underwriting === "MORI" || data.recommended_underwriting === "FMU")
    ) {
      return (
        <SmallText margin="0 0 0 30rem" width="calc(100% - 30rem)">
          {t("START_DATE_NOT_BETWEEN_NOW_AND_THIRTY_DAYS")}
        </SmallText>
      )
    }
  }

  return (
    <>
      <DatePicker
        name="start_date"
        label="Start date"
        margin="0"
        dateRangeMin={moment().format("DD/MM/YYYY")}
        dateRangeMax={moment().add(30, "days").format("DD/MM/YYYY")}
        isHorizontal
        isRequired
        formik={formik}
        isDisabled={data.recommended_style === "SWITCH" || isLocked}
      />
      {getHelperText()}
    </>
  )
}

export default StartDatePicker
