import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get, isEmpty } from "lodash"
import { H2, SmallText } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { PageContext } from "@4cplatform/elements/Organisms"
import { Button } from "@4cplatform/elements/Molecules"
import { PanelHeader } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { getName } from "../../Helpers"
import { UsersContext } from "./users.context"

const UsersPanelHeader = ({ viewData, context, isDeleted }) => {
  const t = useTranslations()
  const { viewLoading } = React.useContext(UsersContext)
  const { setPanelStatus } = React.useContext(PageContext)
  const parent = get(viewData, "parent", {})
  const network = get(viewData, "parent.parent", null)
  const deleted = get(viewData, "deleted_at")
  const created = get(viewData, "created_at")

  return (
    <PanelHeader isDeleted={isDeleted}>
      <H2 appearance="light" isLoading={viewLoading}>
        {getName({ data: viewData, hasTitle: true, hideOtherTitle: true })}
      </H2>

      <SmallText margin="0 0 1rem" appearance="light" isLoading={viewLoading}>
        {get(viewData, "email")}
      </SmallText>

      <SmallText margin="0 0 1rem" appearance="light" isLoading={viewLoading}>
        {t(get(viewData, "role.name"))}
      </SmallText>

      {!isEmpty(parent) && (
        <SmallText margin={network ? "0" : "0 0 1rem"} appearance="light" isLoading={viewLoading}>
          <b>{get(parent, "name")}</b>
        </SmallText>
      )}

      {!isEmpty(parent) && !!network && (
        <IconWithText
          icon="subdirectory-arrow-right"
          appearance="light"
          content={get(network, "name")}
          fontSize="1.4rem"
          margin={viewLoading ? "1rem 0" : "0 0 1rem"}
          isLoading={viewLoading}
        />
      )}

      <SmallText margin="0 0 1rem" appearance="light" isLoading={viewLoading}>
        {`Account start date: ${
          created ? moment.utc(created).local().format("DD/MM/YY HH:mm") : "-"
        }`}
      </SmallText>

      {!!deleted && (
        <SmallText appearance="light" isLoading={viewLoading}>
          {`Account deleted date: ${moment.utc(deleted).local().format("DD/MM/YY HH:mm")}`}
        </SmallText>
      )}

      {context === "open" && (
        <Button
          appearance="whiteGhost"
          trailingIcon="account-reactivate"
          margin="0 0 2rem"
          onClick={() => setPanelStatus("wide")}
          isDisabled={viewLoading}
          name="edit_user"
        >
          Edit user
        </Button>
      )}
    </PanelHeader>
  )
}

UsersPanelHeader.defaultProps = {
  viewData: null
}

UsersPanelHeader.propTypes = {
  viewData: PropTypes.object,
  context: PropTypes.oneOf(["open", "wide"]).isRequired,
  isDeleted: PropTypes.bool.isRequired
}

export default UsersPanelHeader
