import React from "react"
import { get, isEmpty } from "lodash"
import moment from "moment"
import { H2, H3, SmallText } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"
import { IconWithText, LabelWithText } from "@4cplatform/elements/Atoms"
import { Button, PanelHeader } from "@4cplatform/elements/Molecules"
import { AuthWrapper } from "@4cplatform/elements/Auth"
import PropTypes from "prop-types"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"
import { getIconDetails } from "../agencyCodes.helpers"

// Components
import AcceptanceButtons from "../components/AcceptanceButtons"

const AgencyCodesPanelHeader = ({ pendingType }) => {
  const { viewData, viewLoading } = React.useContext(AgencyCodesContext)
  const t = useTranslations()

  const activated = get(viewData, "activated_at", "")
    ? moment.utc(get(viewData, "activated_at", "")).local().format("DD/MM/YYYY HH:mm")
    : "-"

  const created = get(viewData, "created_at", "")
    ? moment.utc(get(viewData, "created_at", "")).local().format("DD/MM/YYYY HH:mm")
    : "-"

  const status = get(viewData, "status", "")
  const statusIcon = getIconDetails(status, true)
  const isShared = get(viewData, "shared_with_count", 0) > 0

  return (
    <PanelHeader>
      <H2 appearance="light" isLoading={viewLoading} margin="2rem 0 1rem">
        {get(viewData, "provider.name", "-")}
      </H2>
      <H3 appearance="light" isLoading={viewLoading}>
        {t(get(viewData, "product", "-"))}
      </H3>
      <SmallText appearance="light" isLoading={viewLoading} margin="0 0 2rem">
        {pendingType === "pending_addition" ? `Requested: ${created}` : `Activated: ${activated}`}
      </SmallText>
      <IconWithText
        icon="pound"
        margin="0 0 1rem"
        appearance="light"
        content={get(viewData, "agency_code", "-")}
        isLoading={viewLoading}
      />
      <IconWithText
        icon="medical-bag"
        appearance="light"
        content={get(viewData, "product_type", "-")}
        isLoading={viewLoading}
      />
      <LabelWithText label="Status" appearance="light" isLoading={viewLoading}>
        {!isEmpty(statusIcon) && (
          <IconWithText appearance="light" content={t(status)} margin="0 0 1rem" {...statusIcon} />
        )}
        {isShared && (
          <IconWithText
            icon="account-multiple"
            appearance="light"
            content="Shared"
            isLoading={viewLoading}
          />
        )}
      </LabelWithText>
      <LabelWithText label="Owner" appearance="light" isLoading={viewLoading} margin="0 0 3rem">
        <IconWithText
          isLoading={viewLoading}
          appearance="light"
          icon="subdirectory-arrow-right"
          content={get(viewData, "owner.attributes.name", "-")}
        />
      </LabelWithText>
      <AuthWrapper roles={["SYS_ADMIN", "SUPPORT_ADMIN", "NETWORK_ADMIN", "NETWORK_MEMBER_ADMIN"]}>
        <Button
          isLoading={viewLoading}
          appearance="whiteGhost"
          name="deal_codes_link"
          type="Link"
          to="/deal-codes"
          margin="0 0 3rem"
          trailingIcon="chevron-right"
        >
          Deal Codes
        </Button>
      </AuthWrapper>
      <AcceptanceButtons />
    </PanelHeader>
  )
}

AgencyCodesPanelHeader.defaultProps = {
  pendingType: "false"
}

AgencyCodesPanelHeader.propTypes = {
  pendingType: PropTypes.string
}

export default AgencyCodesPanelHeader
