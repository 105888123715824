import React, { useContext } from "react"
import PropTypes from "prop-types"
import { get, isEmpty } from "lodash"
import { useFormik } from "formik"
import { P, H4, H3 } from "@4cplatform/elements/Typography"
import { AuthWrapper } from "@4cplatform/elements/Auth"

// Helpers
import { PoliciesContext } from "../../policies.context"
import { createEditExclusionModel as validationSchema } from "./policyExclusions.helpers"

// Components
import {
  SectionWrapper,
  ExclusionHeaderTextWrapper,
  ExclusionWrapper
} from "./policyExclusions.styles"
import PolicyExclusionsHeaderActions from "./policyExclusions.added.exclusion.actions"
import PolicyExclusionsApplicantEdit from "./policyExclusions.added.exclusion.edit"

const PolicyExclusionsHeader = ({ canEditPolicyExclusionsRoles }) => {
  const {
    exclusionsData,
    selectedExclusion,
    setEditModal,
    onUpdateExclusion,
    onExclusionSelect,
    viewData
  } = useContext(PoliciesContext)
  const applicants = get(viewData, "applicants", [])

  const editExclusionFormik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      policy_applicant_id: get(selectedExclusion, "policy_applicant_id"),
      exclusion: get(selectedExclusion, "exclusion")
    },
    onSubmit: body => {
      setEditModal(false)
      onExclusionSelect(null)
      onUpdateExclusion({
        body: { ...body, policy_applicant_id: parseInt(get(body, "policy_applicant_id")) }
      })
    }
  })

  const formik = { ...editExclusionFormik, validationSchema }
  const { handleSubmit } = formik

  return (
    <SectionWrapper>
      <H3 margin="0 0 2rem">Added exclusions</H3>
      {isEmpty(exclusionsData) && <P>No added exclusions</P>}
      {!isEmpty(exclusionsData) &&
        exclusionsData.map(exclusion => {
          const exclusionId = get(exclusion, "id")
          const applicantName = get(exclusion, "applicant_name")
          const exclusionTitle = get(exclusion, "exclusion")
          const clickedExclusion = get(selectedExclusion, "id")
          return (
            <ExclusionWrapper key={exclusionId}>
              {clickedExclusion === exclusionId ? (
                <PolicyExclusionsApplicantEdit formik={formik} applicants={applicants} />
              ) : (
                <ExclusionHeaderTextWrapper>
                  <H4 margin="0">{applicantName}</H4>
                  <P margin="1rem 0">{exclusionTitle}</P>
                </ExclusionHeaderTextWrapper>
              )}
              <AuthWrapper roles={canEditPolicyExclusionsRoles}>
                <PolicyExclusionsHeaderActions exclusion={exclusion} onClick={handleSubmit} />
              </AuthWrapper>
            </ExclusionWrapper>
          )
        })}
    </SectionWrapper>
  )
}

PolicyExclusionsHeader.defaultProps = {
  canEditPolicyExclusionsRoles: []
}

PolicyExclusionsHeader.propTypes = {
  canEditPolicyExclusionsRoles: PropTypes.arrayOf(PropTypes.string)
}

export default PolicyExclusionsHeader
