import React from "react"
import { Checkbox } from "@4cplatform/elements/Forms"

// Helpers
import { ClientsContext } from "../../clients.context"

// Components
import { Search } from "../../../../Molecules"
import { TableActionsWrapper, TableActionsLeft, TableActionsRight } from "../../clients.styles"

const Actions = () => {
  const { policiesSearch, policiesShowSimulated, updatePoliciesPageValue } =
    React.useContext(ClientsContext)
  return (
    <TableActionsWrapper data-testid="clients-policies-actions-wrapper" hasLeftActions>
      <TableActionsLeft>
        <Checkbox
          label="Show simulated policies"
          margin="0"
          name="show_simulated_policies"
          appearance="light"
          value={policiesShowSimulated}
          onChange={value => updatePoliciesPageValue("policiesShowSimulated", value)}
          data-testid="show_simulated_policies"
        />
      </TableActionsLeft>
      <TableActionsRight>
        <Search
          name="search_client-policies"
          handleChange={value => {
            if (policiesSearch.length > 0 && (value.length < 3 || value === policiesSearch)) {
              updatePoliciesPageValue("policiesSearch", "")
            } else if (value.length >= 3) {
              updatePoliciesPageValue("policiesSearch", value)
            }
          }}
          onCancel={() => updatePoliciesPageValue("policiesSearch", "")}
          margin="0"
          defaultValue={policiesSearch}
        />
      </TableActionsRight>
    </TableActionsWrapper>
  )
}

export default Actions
