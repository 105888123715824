import React, { useContext } from "react"
import { get } from "lodash"
import { H3, P, A, List } from "@4cplatform/elements/Typography"
import { Icon } from "@4cplatform/elements/Atoms"
import { Button, Modal } from "@4cplatform/elements/Molecules"
import { colours } from "@4cplatform/elements/Helpers"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { QuotationSummaryContext } from "./context/quotationSummary.context"

// Components
import {
  DemandsNeedsModalHeader,
  DemandsNeedsModalHeaderContent,
  DemandsNeedsModalBody,
  DemandsNeedsModalFooter,
  DocumentLi
} from "./quotationSummary.styles"
import QuoteProviderLogo from "../../../../../QuotationSummaryTable/quotationSummaryTable.providerLogo"

const QuoteNetworkDocuments = () => {
  const t = useTranslations()
  const {
    quoteNetworkDocuments,
    quoteNetworkDocumentsModal,
    onQuoteNetworkDocumentsClose,
    copyQuoteLoading,
    pushQuoteToClientLoading,
    tableLoading,
    fetchQuoteLoading,
    availableOptionsLoading,
    getQuoteLoadingState
  } = useContext(QuotationSummaryContext)
  const isLoading =
    getQuoteLoadingState(quoteNetworkDocuments?.id) ||
    copyQuoteLoading ||
    pushQuoteToClientLoading ||
    tableLoading ||
    fetchQuoteLoading ||
    availableOptionsLoading

  return quoteNetworkDocumentsModal ? (
    <Modal
      hasHeader={false}
      onClose={() => onQuoteNetworkDocumentsClose()}
      name="quote_network_documents_modal"
      hasPadding={false}
    >
      <DemandsNeedsModalHeader>
        <QuoteProviderLogo src={get(quoteNetworkDocuments, "provider.logo_file_path", "")} />
        <DemandsNeedsModalHeaderContent>
          <H3 margin="0">{t(get(quoteNetworkDocuments, "product_name", "-"))}</H3>
          <P>#{get(quoteNetworkDocuments, "reference", "")}</P>
        </DemandsNeedsModalHeaderContent>
      </DemandsNeedsModalHeader>

      <DemandsNeedsModalBody>
        <H3 margin="0 0 2rem 0">Network documents</H3>

        {get(quoteNetworkDocuments, "network_documents", []).length ? (
          <List name="quote_network_documents" listType="unordered">
            {get(quoteNetworkDocuments, "network_documents", []).map(doc => (
              <DocumentLi key={`quote_network_doc_${doc.id}`}>
                <A
                  isLoading={isLoading}
                  target="_blank"
                  fontSize="1.6rem"
                  decoration="none"
                  href={doc.link}
                >
                  {doc.title}

                  <Icon
                    size="1.6rem"
                    icon="open-in-new"
                    margin="0 0 0 1rem"
                    style={{ verticalAlign: "middle" }}
                    colour={colours.blue}
                  />
                </A>
              </DocumentLi>
            ))}
          </List>
        ) : (
          <P>No network documents available.</P>
        )}
      </DemandsNeedsModalBody>

      <DemandsNeedsModalFooter>
        <Button
          appearance="primary"
          onClick={onQuoteNetworkDocumentsClose}
          name="quote_network_documents_close"
        >
          Close
        </Button>
      </DemandsNeedsModalFooter>
    </Modal>
  ) : null
}

export default QuoteNetworkDocuments
