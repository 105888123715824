import React from "react"
import { get } from "lodash"
import { boolean } from "yup"
import { H4, List, P } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { Toggle } from "@4cplatform/elements/Forms"
import { ListStyle } from "./moratoriumDeclarationConfirmation.styles"
import Audit from "./Audit"

export const config = data => ({
  title: "AXA Moratorium Declaration Confirmation",
  navTitle: "AXA Moratorium Declaration Confirmation",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          key: "compliance_note",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <H4 margin="0 0 1rem">Declaration Confirmation</H4>
                <P>
                  I declare that to the best of my knowledge and belief the statements made on this
                  form are true and correct. I acknowledge that any future plan enrolments will be
                  on the basis of these statements and that I, and my family members included on
                  such a plan, shall be bound by the terms of that plan which I shall read when I
                  receive my plan details. I understand that you will send most correspondence about
                  the plan to the lead member but will, where possible, correspond with each
                  individual member over the age of 16 about their claim. I have indicated my chosen
                  products and method of payment I would like. I understand that the persons covered
                  by this application will be subject to a two year rolling moratorium clause, the
                  details of which are:
                </P>
                <ListStyle>
                  <List name="declaration_confirmation_text_list">
                    <li>
                      <P>
                        Initially there is no cover at all for treatment of any medical condition
                        which was in existence at any time during the five years immediately
                        preceding the date on which the persons included on this application joined
                        AXA PPP healthcare. This exclusion relates not only to those conditions for
                        which a diagnosis has been received but also to any medical condition for
                        which they actually had symptoms, even though no diagnosis had been attached
                        to those symptoms. All that matters is that the person knows, or ought
                        reasonably to have known, that something was wrong even if they had not
                        consulted a doctor. If a claim is made, therefore, the person's doctor may
                        be asked for confirmation they would have had no reason to know or believe,
                        when they joined, that they might have the condition for which they are
                        claiming treatment.
                      </P>
                      <P>
                        In addition, the persons covered by this application will not be covered for
                        treatment of specified conditions where they have been diagnosed with
                        diabetes, are currently undergoing treatment for blood pressure
                        (hypertension), are under investigation, having treatment or undergoing
                        monitoring as a result of a Prostate Specific Antigen (PSA) test.
                      </P>
                    </li>
                    <li>
                      Treatment of all such conditions is completely excluded from cover for two
                      years from the date joining.
                    </li>
                    <li>
                      <P>
                        At the end of those two years the persons included in this application will
                        be able to claim for treatment of those conditions or symptoms described in
                        (a) above, but only if they have not had any medical treatment or any
                        medical advice, or taken any drugs or medicines, or followed any special
                        diets in respect of those conditions for the period of 24 consecutive
                        months. If such treatment is received within the period of two consecutive
                        years then they won't be able to claim for treatment of those conditions
                        until such time as they have gone for a period of 24 consecutive months
                        without any treatment or advice or help or drugs.
                      </P>
                      <P>
                        It follows that there are some medical conditions and specified conditions -
                        those which continue or keep recurring - for which it will never be possible
                        to make claim for treatment. This is because the person will always need to
                        have medical advice or take medication and therefore will not be able to go
                        for a period of 24 consecutive months without advice or medication.
                        Treatment of those conditions is therefore completely excluded from cover
                        for all time.
                      </P>
                    </li>
                  </List>
                </ListStyle>
              </>
            ),
            type: "info"
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "client_confirms_axa_mori_declaration_confirmation",
          component: Toggle,
          initialValue: get(
            data,
            "page.data.client_confirms_axa_mori_declaration_confirmation",
            false
          ),
          validationSchema: boolean()
            .oneOf(
              [true],
              "It is not possible to continue with the sales journey if the client has not confirmed the Declaration Confirmation statement."
            )
            .required("MISSING_REQUIRED_FIELD"),
          label: "Client confirms declaration?",
          componentProps: {
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true,
            labelWidth: "39.5rem",
            margin: "0 0 2rem"
          }
        }
      ]
    }
  ]
})
