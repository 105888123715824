import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { Toggle } from "@4cplatform/elements/Forms"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { H4, P } from "@4cplatform/elements/Typography"

import { JourneyContext } from "../../../../../../journey.context"

const ApplicantQuestions = ({ formik }) => {
  const { data } = React.useContext(JourneyContext)
  return (
    <>
      {get(formik, "values.questions.permanent_uk_resident") === false && (
        <ComplianceNote type="error">
          <H4 margin="0 0 1rem">UK residency</H4>
          <P margin="0">
            In order to continue with the journey, all partners and dependants must be UK residents.
          </P>
        </ComplianceNote>
      )}
      <Toggle
        name="questions.permanent_uk_resident"
        label="Is the client a permanent UK resident?"
        formik={formik}
        labelWidth="39rem"
        isHorizontal
      />
      <Toggle
        name="questions.covered_with_a_gp_and_access_to_medical_records"
        label="Is the person to be covered registered with a GP and do they have access to their medical records in English?"
        formik={formik}
        labelWidth="35rem"
        isHorizontal
        helperTitle="VitalityHealth"
        helperText={
          get(data, "page.conditionals.has_access_to_vitality_agency_codes")
            ? "<p>GP/DENTIST REGISTRATION<br /> All members must have been resident in the UK and registered with a UK GP for a minimum of six months prior to the cover start date. If this is not in place, there may be a delay in getting authorisation for an eligible claim, or in some circumstances it may lead to the claim being declined</p>"
            : ""
        }
      />
      <Toggle
        name="questions.pmi_required_to_fulfil_reqs_or_visa"
        label="Is PMI required for a visa?"
        formik={formik}
        labelWidth="39rem"
        isHorizontal
      />
      <Toggle
        name="questions.tobacco_products_within_last_2_years"
        label="Has the client used any tobacco products within the last 2 years?"
        formik={formik}
        labelWidth="39rem"
        isHorizontal
      />
      {get(formik, "values.questions.permission_to_add_member") === false && (
        <ComplianceNote type="error">
          <H4 margin="0 0 1rem">Permission to add</H4>
          <P margin="0">
            In order to continue with the journey, all partners and dependants must give permission
            to be added.
          </P>
        </ComplianceNote>
      )}
      <Toggle
        name="questions.permission_to_add_member"
        label="Permission to add this member?"
        formik={formik}
        labelWidth="39rem"
        isHorizontal
      />
    </>
  )
}

ApplicantQuestions.propTypes = {
  formik: PropTypes.object.isRequired
}

export default ApplicantQuestions
