import React from "react"
import PropTypes from "prop-types"
import { get, isEmpty } from "lodash"
import moment from "moment"
import { useGet, useDelete, usePost, usePatch, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../applicants.context"

// Helpers
import reducer from "./applicants.reducer"
import { JourneyContext } from "../../../../../../../journey.context"

const ApplicantsProvider = ({ children }) => {
  const { data } = React.useContext(JourneyContext)
  const t = useTranslations()

  const [
    {
      applicants,
      included,
      page,
      perPage,
      total,
      includedPage,
      includedPerPage,
      includedTotal,
      applicantModal,
      deleteApplicantModal,
      activeTabID,
      emailValidateError,
      applicantPaginatedData
    },
    dispatch
  ] = React.useReducer(reducer, {
    applicants: [],
    included: [],
    page: 1,
    perPage: 10,
    total: null,
    includedPage: 1,
    includedPerPage: 10,
    includedTotal: null,
    applicantModal: { type: null, isOpen: false, isEdit: false, applicant: null },
    deleteApplicantModal: { applicant: null, isOpen: false },
    activeTabID: "personal_details",
    emailValidateError: undefined,
    applicantPaginatedData: []
  })

  // Index applicants
  const {
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch
  } = useGet({
    endpoint: "/journeys/:slug/applicants",
    params: {
      slug: get(data, "journey.slug")
    },
    query: {
      with: ["journeyData"]
    },
    onCompleted: res =>
      dispatch({
        type: "FETCH_APPLICANTS_COMPLETE",
        total: get(res, "pagination.totalItems"),
        data: get(res, "data", [])
      })
  })

  // Index included applicants
  const {
    loading: includedLoading,
    error: includedError,
    refetch: includedRefetch
  } = useGet({
    endpoint: "/journeys/:slug/applicants",
    params: {
      slug: get(data, "journey.slug")
    },
    query: {
      page: includedPage,
      limit: includedPerPage,
      with: ["journeyData"],
      included: true
    },
    onCompleted: res =>
      dispatch({
        type: "FETCH_INCLUDED_COMPLETE",
        total: get(res, "pagination.totalItems"),
        data: get(res, "data", [])
      })
  })

  // Add applicant
  const [addApplicant, { loading: addApplicantLoading, error: addApplicantError }] = usePost({
    endpoint: "/journeys/:slug/applicants",
    params: {
      slug: get(data, "journey.slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("APPLICANT_CREATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "applicantModal", value: false })
      dispatch({ type: "UPDATE_VALUE", key: "activeTabID", value: "personal_details" })
      queryRefetch()
      includedRefetch()
    },
    onError: ({ message, validation }) => {
      if (message === "VALIDATION_FAILED") {
        if (validation["applicant.email_address"]) {
          dispatch({ type: "UPDATE_VALUE", key: "activeTabID", value: "personal_details" })
          dispatch({
            type: "UPDATE_VALUE",
            key: "emailValidateError",
            value: validation["applicant.email_address"][0]
          })
        }
      }
    }
  })

  // Update applicant
  const [updateApplicant, { loading: updateApplicantLoading, error: updateApplicantError }] =
    usePatch({
      endpoint: "/journeys/:slug/applicants/:applicant",
      params: {
        slug: get(data, "journey.slug", ""),
        applicant: get(applicantModal, "applicant.slug", "")
      },
      onCompleted: () => {
        addAlert({
          message: t("APPLICANT_UPDATE_SUCCESS"),
          type: "success",
          dismissble: true,
          timeout: 5
        })
        queryRefetch()
        dispatch({
          type: "UPDATE_VALUE",
          key: "applicantModal",
          value: { isOpen: false, applicant: null }
        })
      },
      onError: ({ message, validation }) => {
        if (message === "VALIDATION_FAILED") {
          if (validation["applicant.email_address"]) {
            dispatch({ type: "UPDATE_VALUE", key: "activeTabID", value: "personal_details" })
            dispatch({
              type: "UPDATE_VALUE",
              key: "emailValidateError",
              value: validation["applicant.email_address"][0]
            })
          }
        }
      }
    })

  // Delete applicant
  const [removeApplicant, { loading: deleteApplicantLoading, error: deleteApplicantError }] =
    useDelete({
      endpoint: "/journeys/:slug/applicants/:applicant",
      onCompleted: () => {
        addAlert({
          message: t("APPLICANT_DELETE_SUCCESS"),
          type: "success",
          dismissible: true,
          timeout: 5
        })
        dispatch({
          type: "UPDATE_VALUE",
          key: "deleteApplicantModal",
          value: { applicant: null, isOpen: false }
        })
        queryRefetch()
        if (includedPage === 1) {
          includedRefetch()
        } else {
          dispatch({ type: "UPDATE_VALUE", key: "includedPage", value: 1 })
        }
      }
    })

  // Include applicant
  const [includeApplicant, { loading: includeApplicantLoading, error: includeApplicantError }] =
    usePost({
      endpoint: "/journeys/:slug/applicants/:applicant/include",
      onCompleted: () => {
        addAlert({
          message: t("APPLICANT_INCLUDE_SUCCESS"),
          type: "success",
          dismissible: true,
          timeout: 5
        })
        queryRefetch()
        if (includedPage === 1) {
          includedRefetch()
        } else {
          dispatch({ type: "UPDATE_VALUE", key: "includedPage", value: 1 })
        }
      }
    })

  // Exclude applicant
  const [excludeApplicant, { loading: excludeApplicantLoading, error: excludeApplicantError }] =
    usePost({
      endpoint: "/journeys/:slug/applicants/:applicant/exclude",
      onCompleted: () => {
        addAlert({
          message: t("APPLICANT_EXCLUDE_SUCCESS"),
          type: "success",
          dismissible: true,
          timeout: 5
        })
        queryRefetch()
        if (includedPage === 1) {
          includedRefetch()
        } else {
          dispatch({ type: "UPDATE_VALUE", key: "includedPage", value: 1 })
        }
      }
    })

  const filterApplicantError = error => {
    if (error && error.message !== "VALIDATION_FAILED") {
      return error
    }
  }

  React.useEffect(() => {
    if (!isEmpty(applicants)) {
      let start = 0
      let end = perPage
      if (page === 1) {
        start = 0
        end = perPage
      } else if (applicants.length - page * perPage >= perPage) {
        start = (page - 1) * perPage
        end = page * perPage
      } else {
        start = (page - 1) * perPage
        end = applicants.length
      }
      const val = applicants.slice(start, end)
      dispatch({ type: "UPDATE_VALUE", key: "applicantPaginatedData", value: val })
    }
  }, [page, perPage, applicants])
  return (
    <Provider
      value={{
        applicants,
        applicantPaginatedData,
        included,
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        applicantModal,
        setApplicantModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "applicantModal", value: val }),
        deleteApplicantModal,
        setDeleteApplicantModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "deleteApplicantModal", value: val }),
        deleteApplicant: applicant => {
          removeApplicant({
            params: {
              slug: get(data, "journey.slug", ""),
              applicant: get(applicant, "slug", "")
            }
          })
        },
        applicantsLoading: queryLoading,
        updateApplicant: body => {
          updateApplicant({ body })
        },
        updateApplicantLoading,
        deleteApplicantLoading,
        addApplicant: body => {
          addApplicant({ body })
        },
        addApplicantLoading,
        includeApplicant: applicant => {
          includeApplicant({
            params: {
              applicant,
              slug: get(data, "journey.slug", "")
            }
          })
        },
        includeApplicantLoading,
        excludeApplicant: applicant => {
          excludeApplicant({
            params: {
              applicant,
              slug: get(data, "journey.slug", "")
            }
          })
        },
        excludeApplicantLoading,
        includedLoading,
        pagination: { total, page, perPage },
        includedPagination: { total: includedTotal, page: includedPage, perPage: includedPerPage },
        includedPerPage,
        setIncludedPerPage: val =>
          dispatch({ type: "UPDATE_VALUE", key: "includedPerPage", value: val }),
        includedPage,
        setIncludedPage: val => dispatch({ type: "UPDATE_VALUE", key: "includedPage", value: val }),
        updateActiveTabID: val =>
          dispatch({ type: "UPDATE_VALUE", key: "activeTabID", value: val }),
        activeTabID,
        emailValidateError,
        updateEmailValidateError: val =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "emailValidateError",
            value: val
          }),
        checkIfChildOnlyQuote: () => {
          if (!applicants.filter(_ => _.included).length) return false

          return !applicants.filter(
            applicant =>
              applicant.included &&
              moment
                .utc(get(applicant, "date_of_birth", ""), "YYYY/MM/DD")
                .local()
                .isBefore(moment(new Date(), "YYYY/MM/DD").subtract(18, "years"))
          ).length
        },
        canQuoteAxa: get(data, "page.conditionals.has_access_to_axa_agency_codes")
      }}
    >
      {children}
      <ApiError
        error={
          queryError ||
          deleteApplicantError ||
          filterApplicantError(addApplicantError) ||
          filterApplicantError(updateApplicantError) ||
          excludeApplicantError ||
          includeApplicantError ||
          includedError
        }
      />
    </Provider>
  )
}

ApplicantsProvider.propTypes = {
  children: PropTypes.any
}

export default ApplicantsProvider
