import React from "react"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { get, toLower } from "lodash"
import { AuthContext } from "@4cplatform/elements/Auth"
import { Container } from "@4cplatform/elements/Atoms"
import { Helmet } from "react-helmet-async"
import { H1 } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import { Breadcrumbs } from "../../UI/Molecules"
import JourneysProvider from "./context/journeys.provider"
import { JourneyAdmin, JourneysPanel } from "../../UI/Templates"

// Helpers
import { DASHBOARD } from "../../config/pages"

const Journeys = () => {
  const { search } = useLocation()
  const t = useTranslations()
  const { canAccess } = React.useContext(AuthContext)

  const qs = queryString.parse(search)
  const status = get(qs, "status")

  const getTitle = () => {
    if (Array.isArray(status) && status.includes("IN_PROGRESS") && status.includes("QUOTED"))
      return "Incomplete journeys"
    if (status === "COMPLETE") return "Quotes completed"
    return `Journeys ${toLower(t(status))}`
  }

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <JourneysProvider>
        <Container isPageContainer width="80%">
          <Breadcrumbs
            trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: getTitle() }]}
          />
          <H1 margin="0 0 6rem">{getTitle()}</H1>
          <JourneyAdmin
            hasOrganisation={
              status !== "COMPLETE" &&
              canAccess(["SYS_ADMIN", "SUPPORT_ADMIN", "NETWORK_ADMIN", "NETWORK_MEMBER_ADMIN"])
            }
          />
        </Container>
        <JourneysPanel />
      </JourneysProvider>
    </>
  )
}

export default Journeys
