import React from "react"
import { trim } from "lodash"
import { colours } from "@4cplatform/elements/Helpers"
import { Row, Column } from "@4cplatform/elements/Atoms"
import { H3, P } from "@4cplatform/elements/Typography"

// Components
import { PanelContent } from "../../components"
import { FaqPanelWrap } from "../../landingPage.styles"
import { OrderedList } from "./faq.styles"

const faqs = [
  {
    title: "What type of insurance can I sell through 4C Platform?",
    text: [
      "4C Platform is designed to assist with the entire sales process for Individual Private Medical Insurance in the UK."
    ]
  },
  {
    title: "Which PMI insurance providers are available on the 4C Platform?",
    text: [
      "The 4C Platform is designed to assist with selling PMI from a leading panel of the UK's health insurance providers. The quote and apply tool is currently available for brokers who have agency codes with Aviva, AXA Health, Bupa, The Exeter and VitalityHealth. Additional providers are due to be implemented over the coming months and news of exact dates will be published here when known."
    ]
  },
  {
    title: "How can 4C Platform help ensure compliant PMI sales?",
    text: [
      "4C Platform contains a sales journey which the broker steps through with each client. The sales journey requires the broker to ask all the right questions, and to record the answers, so that each quotation contains a complete audit trail.",
      "Once the quotation has been accepted by the client, the policy is on-boarded electronically and all relevant documentation sent to yourselves and the client so no paperwork is required."
    ]
  },
  {
    title: "What is the cost?",
    text: [
      "There is no fee for joining or using the 4C Platform, and there is no commission split or sacrifice. 4C Platform.com Limited is reimbursed for sales that are facilitated within the app by the insurance providers."
    ]
  },
  {
    title: "How can I start selling PMI on the 4C Platform?",
    text: ["To start selling PMI on the 4C Platform, you need to meet the following criteria:"],
    list: [
      "Be an established broker or IFA with an FCA reference number and relevant agency code(s)",
      "Be invited to join or have an application accepted to join the platform"
    ]
  }
]

const panelProps = {
  title: {
    text: "Frequently asked questions, answered",
    colour: colours.darkBlue
  },
  subtitle: {
    text: "We're here to help. Browse our most frequently asked questions below",
    colour: colours.darkBlue
  },
  button: {
    text: "Get in touch today",
    href: "#contact",
    appearance: "darkBlueGhost"
  },
  hrColour: colours.lightBlue
}

const FaqPanel = () => {
  const renderFaq = ({ title, text, list }) => (
    <div key={trim(title)} data-testid="faq-item">
      <H3 fontSize="1.9rem">{title}</H3>
      <P fontSize="1.7rem" lineHeight="1.6" margin={list?.length > 0 ? " 0 0 1rem 0" : "0"}>
        {text}
      </P>
      {list?.length > 0 && (
        <OrderedList name="faq-list">
          {list.map(item => (
            <li key={trim(item)}>{item}</li>
          ))}
        </OrderedList>
      )}
    </div>
  )

  return (
    <FaqPanelWrap id="faq" data-testid="faq-panel">
      <PanelContent {...panelProps}>
        {faqs.length > 0 && (
          <Row>
            <Column
              padding="0 2rem"
              phone={12}
              small={12}
              tablet={12}
              medium={12}
              large={6}
              xLarge={6}
              xxLarge={6}
            >
              {faqs.slice(0, Math.ceil(faqs.length / 2)).map(faq => renderFaq(faq))}
            </Column>
            <Column
              padding="0 2rem"
              phone={12}
              small={12}
              tablet={12}
              medium={12}
              large={6}
              xLarge={6}
              xxLarge={6}
            >
              {faqs.slice(Math.ceil(faqs.length / 2)).map(faq => renderFaq(faq))}
            </Column>
          </Row>
        )}
      </PanelContent>
    </FaqPanelWrap>
  )
}

export default FaqPanel
