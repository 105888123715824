import React from "react"
import { Helmet } from "react-helmet-async"
import { get } from "lodash"
import { PageContext } from "@4cplatform/elements/Organisms"
import { Dashboard as Dash } from "@4cplatform/elements/Templates"

// Helpers
import { getUserDashboard } from "../../UI/Helpers"
import { getTitle } from "./dashboard.helpers"

const Dashboard = () => {
  const { selfServiceData: user, selfServiceLoading } = React.useContext(PageContext)

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Dash
        dashboard={getUserDashboard(user)}
        title={getTitle(get(user, "role.name", "SYS_ADMIN"))}
        isLoading={selfServiceLoading}
      />
    </>
  )
}

export default Dashboard
