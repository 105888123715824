import styled, { css } from "styled-components"
import { get } from "lodash"
import { Button } from "@4cplatform/elements/Molecules"
import { colours } from "@4cplatform/elements/Helpers"

export const CancelWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
`

export const TableActionsButton = styled(Button)`
  height: 5rem;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export const InviteOrganisationFormWrapper = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => get(theme, "veryFaintGrey")};
`

export const InviteOrganisationButtonsWrapper = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: space-between;
`

export const InvitesListWrapper = styled.div`
  padding: 2rem;
  max-height: 30rem;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const InviteOrganisationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const InviteOrganisationBorder = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.2rem 1rem;

  ${({ hasBorder }) => {
    if (hasBorder)
      return css`
        margin: 0.5rem 0;
        border: solid 1px ${colours.blue};
        border-radius: 15px;
      `
  }}
`

export const Close = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => get(theme, "red", "red")};
  cursor: pointer;
  line-height: 0;
  z-index: 1;
  font-size: 2rem;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`
