import { P, H4, List } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import moment from "moment"
import { get } from "lodash"
import Audit from "./Audit"

const underwritingTypeMoriObj = {
  AXA: [
    {
      key: "compliance_note_moratorium_axa",
      component: ComplianceNote,
      componentProps: {
        children: (
          <P margin="0 2rem 0 0">
            Any condition that you have had symptoms, treatment, medication or advice on in the last
            5 years will not be covered for the first 2 years of your policy. If you are able to go
            2 years without treatment, medication, advice, over the counter medication and special
            dietary requirement free of the that condition, while a member with AXA Health, it will
            look to be covered.
          </P>
        ),
        type: "info"
      },
      skipDataMap: true
    }
  ],
  VITALITYHEALTH: [
    {
      key: "compliance_note_moratorium_vitality",
      component: ComplianceNote,
      componentProps: {
        children: (
          <P margin="0 2rem 0 0">
            Any medical conditions you've had in the five years leading up to your cover start date
            are automatically excluded. These conditions can become eligible for cover if you don't
            consult anyone in a medical capacity, receive medical treatment or take medication for
            them or any related conditions for two continuous years.
          </P>
        ),
        type: "info"
      },
      skipDataMap: true
    }
  ],
  BUPA: [
    {
      key: "compliance_note_moratorium_bupa",
      component: ComplianceNote,
      componentProps: {
        children: (
          <P margin="0 2rem 0 0">
            A Moratorium with Bupa does not require you to disclose your full medical history at the
            point of sale , medical history will only need to be provided at point of claim. Any
            medical condition that you have had symptoms, treatment, medication or advice on in the
            5 years prior to the policy start date is automatically excluded from cover for the
            first 2 years of the policy. However, provided certain criteria has been met, then that
            medical condition may be covered in full. This form of underwriting offers the potential
            for pre-existing conditions to eventually be covered on the policy.
          </P>
        ),
        type: "info"
      },
      skipDataMap: true
    }
  ],
  EXETER: [
    {
      key: "compliance_note_moratorium_the_exeter",
      component: ComplianceNote,
      componentProps: {
        children: (
          <P margin="0 2rem 0 0">
            Benefits will not be available for treatment of any condition suffered by an applicant
            if the applicant had symptoms, medication, treatment or advice in connection with that
            condition in the five years before the start of their policy and there has not been a
            clear two year period after the start of their policy during which the applicant has
            been free of symptoms, medication, treatment and advice in connection with that
            condition.
            <br />
            <br />
            Important - If any applicant makes a claim, we may request up to five years of medical
            records in English to determine whether the condition was pre-existing or not, or
            whether there have been two clear years free of the condition. If this cannot be
            provided, we may not be able to pay a claim.
          </P>
        ),
        type: "info"
      },
      skipDataMap: true
    }
  ],
  AVIVA: [
    {
      key: "compliance_note_moratorium_aviva",
      component: ComplianceNote,
      componentProps: {
        children: (
          <>
            <P margin="0 2rem 1rem 0">
              We do not cover treatment of any pre-existing condition, or any related condition, if
              you had:
            </P>
            <List listType="unordered" name="compliance_note_moratorium_axa_list_condition">
              <li>symptoms of</li>
              <li>medication for</li>
              <li>diagnostic tests for</li>
              <li>treatment for, or</li>
              <li>advice about that condition in the five years before you joined the policy.</li>
            </List>
            <P margin="0 2rem 1rem 0">However, we will cover that condition if you do not have:</P>
            <List listType="unordered" name="compliance_note_moratorium_axa_list_condition_dont">
              <li>medication for</li>
              <li>diagnostic tests for</li>
              <li>treatment for, or</li>
              <li>advice about</li>
            </List>
            <P margin="0 2rem 0 0">
              that condition during a continuous two year period after you join the policy. With
              mori underwriting the claims process may take a bit longer, as each time you make a
              claim we'll look at your medical history, and may ask for information from your GP, to
              understand if your symptom or condition is new or pre-existing.
            </P>
          </>
        ),
        type: "info"
      },
      skipDataMap: true
    }
  ]
}

export const config = (data, t) => {
  const underwritingType = get(data, "journey.selected_quote.underwriting_type")
  const providerKey = get(data, "journey.selected_quote.provider.provider_key")
  const selectedStartDate = get(data, "page.data.start_date")
  const isVitality = get(data, "journey.selected_quote.provider.provider_key") === "VITALITYHEALTH"
  const isSwitch = get(data, "journey.selected_quote.underwriting_style") === "SWITCH"
  const underwritingTypeComplianceNoteMori =
    underwritingType === "MORI" ? get(underwritingTypeMoriObj, providerKey, []) : []

  const underwritingTypeComplianceNoteFmu =
    underwritingType === "FMU"
      ? [
          {
            key: "compliance_note_fmu",
            component: ComplianceNote,
            componentProps: {
              children: (
                <P margin="0 2rem 0 0">
                  Full Medical Underwriting (FMU) requires full disclosure of current and past
                  medical history for all members covered on the policy. Based upon completion of a
                  medical questionnaire an underwriter will assess your disclosures and may exclude
                  these medical conditions from cover. You will be sent policy documentation after
                  this process has been completed which will detail whether any conditions have been
                  excluded. Any new conditions or symptoms that arise after the start date of the
                  policy will be covered, subject to the benefit options chosen and any standard
                  Terms & Conditions.
                </P>
              ),
              type: "info"
            },
            skipDataMap: true
          }
        ]
      : []

  const startDateValidComplianceNote =
    providerKey === "AVIVA" && [29, 30, 31].includes(moment(selectedStartDate, "YYYY-MM-DD").date())
      ? [
          {
            key: "compliance_note_start_date",
            component: ComplianceNote,
            componentProps: {
              children: (
                <P margin="0 2rem 0 0">
                  You have selected an Aviva policy. Aviva do not support policy start dates on the
                  29<sup>th</sup>, 30<sup>th</sup> or 31<sup>st</sup> of the month, so the selected
                  policy will start on the first day of the next month.
                </P>
              ),
              type: "info"
            },
            skipDataMap: true
          }
        ]
      : []

  return {
    title: "Underwriting summary",
    sections: [
      {
        key: "underwriting_summary",
        components: [
          {
            component: Audit,
            componentProps: { data },
            skipDataMap: true
          },
          {
            component: H4,
            componentProps: {
              children: "In summary, you have requested:",
              margin: "0rem 0 1rem"
            },
            skipDataMap: true
          },
          {
            key: "additional_data",
            component: List,
            componentProps: {
              listType: "unordered",
              name: "additional_data",
              children: (
                <>
                  <li>
                    {get(data, "journey.selected_quote.product_name", "-") === "INSPIRE"
                      ? "An"
                      : "A"}{" "}
                    {t(get(data, "journey.selected_quote.product_name", "-"))} policy to cover{" "}
                    {get(data, "journey.selected_quote.applicants")?.map(
                      (applicant, index) =>
                        `${applicant.first_name} ${applicant.last_name}${
                          index !== get(data, "journey.selected_quote.applicants").length - 1
                            ? ","
                            : ""
                        } `
                    )}{" "}
                    with a proposed start date of{" "}
                    {moment
                      .utc(get(data, "journey.selected_quote.start_date"))
                      .local()
                      .format("DD/MM/YYYY")}
                    .
                  </li>
                  <li>
                    The policy selected is a{" "}
                    {t(get(data, "journey.selected_quote.underwriting_style"))}{" "}
                    {t(get(data, "journey.selected_quote.underwriting_type"))} supplied by{" "}
                    {get(data, "journey.selected_quote.provider.name")} with{" "}
                    {isVitality && isSwitch
                      ? get(data, "journey.selected_quote.variant_options.Excess")
                      : get(data, "journey.selected_quote.excess")}{" "}
                    excess.
                  </li>
                  <li>
                    Payment will be taken {t(get(data, "journey.selected_quote.payment_frequency"))}
                    .
                  </li>
                  <li>The contract for this insurance policy is for a 12 month period.</li>
                </>
              )
            },
            skipDataMap: true
          },
          ...underwritingTypeComplianceNoteMori,
          ...underwritingTypeComplianceNoteFmu,
          ...startDateValidComplianceNote
        ]
      }
    ]
  }
}
