import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useFormik } from "formik"
import * as yup from "yup"
import { v4 as uuid } from "uuid"
import { Avatar } from "@4cplatform/elements/Atoms"
import { Input } from "@4cplatform/elements/Forms"
import { nullFunc } from "@4cplatform/elements/Helpers"
import { Skeleton, Button, Pagination } from "@4cplatform/elements/Molecules"

// Components
import {
  Wrapper,
  NotesWrapper,
  InputWrapper,
  MessageWidth,
  MessageWrapper,
  MessageLoading,
  MessageNoNotes,
  UnderWrapper,
  UnderWrapperLoading,
  HeaderWrapper,
  HeaderText,
  NotesFooterWrapper,
  NotesButtonsWrapper,
  NotesPaginationWrapper,
  MessagesWrapper
} from "./notes.styles"
import { Note } from "../../Molecules"

const Notes = ({
  onAddNote,
  onAddNoteCancel,
  notes,
  isLoading,
  hasHeader,
  width,
  isNoteTab,
  pagination,
  changePage,
  isOrganisation
}) => {
  const validationSchema = yup.object({
    note: yup.string().required()
  })
  const latestMessageRef = useRef(null)

  const formik = useFormik({
    initialValues: {
      note: ""
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { note } = values
      onAddNote(note)
      resetForm()
    }
  })
  const [scrollBehaviour, setScrollBehaviour] = useState("auto")

  useEffect(() => {
    const timeout = setTimeout(() => {
      setScrollBehaviour("smooth")
    }, 1000)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (latestMessageRef?.current)
      latestMessageRef.current.scrollIntoView({ behavior: scrollBehaviour })
  })
  const enhancedFormik = { ...formik, validationSchema }

  return (
    <Wrapper data-testid="test-notes" width={width} isNoteTab={isNoteTab}>
      {hasHeader && (
        <HeaderWrapper data-testid="test-notes-input-header-wrapper">
          <HeaderText data-testid="test-notes-input-header-text">Notes</HeaderText>
        </HeaderWrapper>
      )}

      <NotesWrapper data-testid="test-notes-wrapper" isNoteTab={isNoteTab}>
        {!isLoading && (
          <MessagesWrapper>
            <MessageWidth>
              {notes?.map(note => (
                <Note
                  key={uuid()}
                  body={get(note, "note", get(note, "body", ""))}
                  timestamp={get(note, "created_at", "")}
                  firstName={
                    isOrganisation
                      ? get(note, "created_by.first_name", "")
                      : get(note, "user.first_name", "")
                  }
                  lastName={
                    isOrganisation
                      ? get(note, "created_by.last_name", "")
                      : get(note, "user.last_name", "")
                  }
                  isNoteTab={isNoteTab}
                  colour="lightgrey"
                />
              ))}
              <div ref={latestMessageRef} />
            </MessageWidth>
          </MessagesWrapper>
        )}

        {notes.length === 0 && !isLoading && (
          <MessageNoNotes isNoteTab={isNoteTab}>No notes to display</MessageNoNotes>
        )}
        {/* Loading state */}
        {isLoading && (
          <MessageWrapper data-testid="notes-loading_wrapper">
            <Skeleton wrapper={MessageLoading} />
            <UnderWrapper data-testid="test-notes-under-wrapper">
              <Skeleton wrapper={UnderWrapperLoading} />
              <Avatar first="Loading" last="State" margin="0 0.5rem 0 0" isLoading />
            </UnderWrapper>
          </MessageWrapper>
        )}
        <NotesPaginationWrapper>
          <Pagination
            {...pagination}
            changePage={changePage}
            hasPerPage
            appearance="dark"
            shouldOnlyShowPaginationBar
          />
        </NotesPaginationWrapper>
      </NotesWrapper>
      {!isNoteTab && (
        <NotesFooterWrapper data-testid="test-notes-footer-wrapper">
          <InputWrapper data-testid="test-notes-input-wrapper">
            <form autoComplete="off" noValidate onSubmit={enhancedFormik.handleSubmit}>
              <Input
                name="note"
                formik={enhancedFormik}
                placeholder="Write your note"
                margin="0"
                isDisabled={isLoading}
              />
            </form>
          </InputWrapper>
          <NotesButtonsWrapper data-testid="test-notes-buttons-wrapper">
            {onAddNoteCancel !== null && (
              <Button
                name="cancel_note"
                trailingIcon="cancel"
                appearance="error"
                onClick={onAddNoteCancel}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
            )}
            <Button
              name="add_new_note"
              trailingIcon="message-plus"
              onClick={formik.handleSubmit}
              isDisabled={isLoading || !enhancedFormik.values?.note}
            >
              Add Note
            </Button>
          </NotesButtonsWrapper>
        </NotesFooterWrapper>
      )}
    </Wrapper>
  )
}

Notes.defaultProps = {
  onAddNote: nullFunc,
  onAddNoteCancel: null,
  notes: [],
  isLoading: false,
  hasHeader: true,
  width: "54.1rem",
  isNoteTab: false,
  pagination: null,
  changePerPage: null,
  changePage: null,
  perPageOptions: null,
  isOrganisation: false
}

Notes.propTypes = {
  onAddNote: PropTypes.func,
  onAddNoteCancel: PropTypes.func,
  notes: PropTypes.array,
  isLoading: PropTypes.bool,
  hasHeader: PropTypes.bool,
  width: PropTypes.string,
  isNoteTab: PropTypes.bool,
  changePerPage: PropTypes.func,
  changePage: PropTypes.func,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    total: PropTypes.number,
    perPage: PropTypes.number
  }),
  perPageOptions: PropTypes.shape({
    max: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired
  }),
  isOrganisation: PropTypes.bool
}

export default Notes
