import React, { useContext } from "react"
import { get } from "lodash"
import { H2, SmallText } from "@4cplatform/elements/Typography"
import { PanelHeader } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { QuotationSummaryContext } from "./context/quotationSummary.context"

// Components
import QuoteProviderLogo from "../../../../../QuotationSummaryTable/quotationSummaryTable.providerLogo"
import {
  EditPanelProviderDetails,
  EditPanelProviderDetailsContent
} from "./quotationSummary.styles"

const EditQuotePanelHeader = () => {
  const t = useTranslations()
  const {
    selectedEditQuote,
    availableOptionsLoading,
    refreshQuoteLoading,
    tableLoading,
    journeyApplicantsLoading
  } = useContext(QuotationSummaryContext)

  const isLoading =
    availableOptionsLoading || refreshQuoteLoading || tableLoading || journeyApplicantsLoading

  return (
    <PanelHeader isDeleted={isLoading}>
      <EditPanelProviderDetails>
        <QuoteProviderLogo
          src={get(selectedEditQuote, "provider.logo_file_path", "")}
          size="9rem"
        />
        <EditPanelProviderDetailsContent>
          <H2 appearance="light" margin="0 0 0.5rem">
            {t(get(selectedEditQuote, "product_name", "-"))}
          </H2>
          <SmallText appearance="light" margin="0">
            #{get(selectedEditQuote, "reference", "")}
          </SmallText>
        </EditPanelProviderDetailsContent>
      </EditPanelProviderDetails>
    </PanelHeader>
  )
}

export default EditQuotePanelHeader
