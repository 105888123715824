import React from "react"
import { Helmet } from "react-helmet-async"
import { Gate } from "@4cplatform/elements/Auth"

// Components
import { LandingPage } from "../../UI/Templates"

const Register = () => (
  <>
    <Helmet>
      <title>Register</title>
    </Helmet>
    <LandingPage
      additionalLinks={[
        {
          title: "Register",
          href: "#page-content"
        }
      ]}
    >
      <Gate type="register" />
    </LandingPage>
  </>
)

export default Register
