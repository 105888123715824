import React from "react"
import { colours } from "@4cplatform/elements/Helpers"
import { P } from "@4cplatform/elements/Typography"

// Components
import { FeaturePanelWrap } from "../../landingPage.styles"
import { PanelContent } from "../../components"
import {
  Feature,
  FeatureList,
  FeatureInner,
  ImageWrap,
  Image,
  Content,
  Header
} from "./features.styles"

const features = [
  {
    title: "Data & Leads",
    text: "Ability to quickly insert and save existing data and client information.",
    imgSrc: "data-leads"
  },
  {
    title: "Scripted Journey",
    text: "Fully scripted journey from start to completion with the ability to edit some parts to suit your own organisation's needs.",
    imgSrc: "scripted-journey"
  },
  {
    title: "Medical Question Set",
    text: "Complete set of in-depth medical questions and insurer specific qualification criteria.",
    imgSrc: "medical-question-set"
  },
  {
    title: "Quoting Tool",
    text: "Easily toggle between different providers features, benefits and live prices.",
    imgSrc: "quoting-tool"
  },
  {
    title: "Pre-Population",
    text: "Automatically pre-populated application forms and paperwork.",
    imgSrc: "pre-population"
  },
  {
    title: "Quality Check",
    text: "Sales audit trail available for quality checking.",
    imgSrc: "quality-check"
  },
  {
    title: "Directed Journey",
    text: "Guided journey and fact-find to ensure that all compliance is followed.",
    imgSrc: "compliant-journey"
  },
  {
    title: "Hospital Finder",
    text: "Check which hospitals are closest and the most suitable for your client.",
    imgSrc: "hospital-finder"
  }
]

const panelProps = {
  title: {
    text: "A full range of features",
    colour: colours.lightBlue
  },
  subtitle: {
    text: "The 4C Platform has a comprehensive and evolving feature set, covering every step of the process",
    colour: colours.white
  },
  button: {
    text: "See FAQs",
    href: "#faq"
  },
  hrColour: colours.white
}

const FeaturesPanel = () => (
  <FeaturePanelWrap id="features" data-testid="features-panel">
    <PanelContent {...panelProps}>
      {features.length > 0 && (
        <FeatureList>
          {features.map(({ title, imgSrc, text }) => (
            <Feature key={title} data-testid="feature">
              <FeatureInner>
                <ImageWrap>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/landing-page/features/${imgSrc}@1x.png`}
                    srcSet={`${process.env.PUBLIC_URL}/images/landing-page/features/${imgSrc}@2x.png 2x, ${process.env.PUBLIC_URL}/images/landing-page/features/${imgSrc}@3x.png 3x`}
                    alt={`${title} icon`}
                    role="presentation"
                    aria-hidden="true"
                  />
                </ImageWrap>
                <Content>
                  <Header colour={colours.lightBlue} fontSize="1.4rem" margin="0">
                    {title}
                  </Header>
                  <P colour={colours.white} fontSize="1.4rem" lineHeight="1.5" margin="0">
                    {text}
                  </P>
                </Content>
              </FeatureInner>
            </Feature>
          ))}
        </FeatureList>
      )}
    </PanelContent>
  </FeaturePanelWrap>
)

export default FeaturesPanel
