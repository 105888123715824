import { boolean } from "yup"
import { get } from "lodash"
import { P, H3, List } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { Toggle } from "@4cplatform/elements/Forms"

export const config = data => ({
  title: "Bupa Health Declaration",
  navTitle: "Bupa Health Declaration",
  sections: [
    {
      key: "section_1",
      components: [
        {
          key: "compliance_note_1",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <H3 margin="0 0 1rem">Obtaining medical reports from a GP</H3>
                <P>
                  Your client consents to Bupa obtaining a written medical report from their/family
                  member's consultant or general practitioner in order to support their application
                  or claim, made using this form. Your client/client's family members understand
                  that they have rights under the Access to Medical Reports Act 1988 or the Access
                  to Personal Files and Medical Reports (NI) Order 1991 (the 'Acts').
                </P>
                <P>
                  The 'Your rights' section below summarises your client/their family members rights
                  under the Acts. Your client and their family members should read this section
                  carefully and if they don't understand any point, they should ask for further
                  information.
                </P>
                <P>Your client/client's family members rights.</P>
                <P>
                  They can authorise the disclosure of the doctor's report without asking to see it
                  The report will then be sent directly to us by the doctor Should they give their
                  consent to the disclosure of a report without indicating their wish to see it,
                  your client can change their mind by contacting their doctor before the report is
                  sent to us, in which case they will have the opportunity to see the report and ask
                  the doctor to change the report or add their comments before it is sent to us, or
                  withhold their consent for its release. <br />
                  They can give their consent but ask to see the report before it is sent to us If
                  your client does this they should contact their doctor within 21 days of sending
                  the request to him/her If they do not contact the doctor within the 21 day period
                  they have authorised them to disclose the report to us directly without further
                  notice to your client If they do contact their doctor within the 21 day period
                  they must give them their written consent to disclose the report Your client may
                  ask their doctor to change the report if they think it is misleading if their
                  doctor refuses, they can insist on adding their own comments to the report before
                  it is sent to us.
                </P>
                <P>
                  They can withhold their consent but, if they do, please bear in mind that we may
                  be unable to process your client's request.
                </P>
                <P>
                  Whether or not your client indicates that they wish to see the report before it is
                  sent, they have the right to ask their doctor to let them see a copy, provided
                  your client asks him/her within six months of the report having been supplied to
                  us. Your client's doctor is entitled to withhold some or all of the information
                  contained in the report if, in their opinion, this information (a) might cause
                  serious harm to their physical or mental health or that of another person, or (b)
                  it would reveal the identity of another person without their consent (other than
                  that provided by a healthcare professional in their professional capacity in
                  relation to their care).
                </P>
                <P>
                  Your client's doctor may charge a fee for providing a medical report, which may be
                  reclaimable from Bupa.
                </P>
              </>
            ),
            type: "info"
          },
          skipDataMap: true
        },
        {
          key: "client_wished_to_see_medical_report_from_their_doctor",
          component: Toggle,
          initialValue: get(
            data,
            "page.data.client_wished_to_see_medical_report_from_their_doctor",
            false
          ),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label:
            "Does the client wish to see the medical report from their doctor before it is supplied to Bupa?",
          componentProps: {
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true,
            labelWidth: "80.5rem"
          }
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "compliance_note_2",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <H3 margin="0 0 1rem">Your legal declaration</H3>
                <P>I confirm the following:</P>
                <List>
                  <li>
                    <P>
                      The client has declared that to the best of their knowledge and belief the
                      information given in this form is true, accurate and complete. The client
                      understands that Bupa can end a person's policy or refuse to pay a claim in
                      full or part if there is reasonable evidence that they or a dependant did not
                      take reasonable care when providing any information requested in this form.
                    </P>
                  </li>
                  <li>
                    <P>
                      Where the client has provided information on behalf of any other person to be
                      covered by the policy, I have checked with the client that the information
                      about each other person is also correct before completing this form and the
                      client has confirmed that they have express agreement from each individual to
                      submit this form on their behalf.
                    </P>
                  </li>
                  <li>
                    <P>
                      The client has declared they understand their personal information and that of
                      any other person to be covered by this policy will be processed by Bupa for
                      the purposes set out in Bupa's privacy notice. The client has provided me with
                      confirmation that they have brought Bupa's privacy notice to the attention of
                      any other person who will be covered by the policy.
                    </P>
                  </li>
                  <li>
                    <P>
                      The client has declared they agree to be bound by the terms of this policy
                      (including in respect of those terms that apply to any other person to be
                      covered by this policy). The client has confirmed they understand and agree
                      that English law will apply to the policy.
                    </P>
                  </li>
                </List>
                <P>
                  It is essential that the client takes reasonable care to provide full, complete
                  and accurate information when you complete this form. Please be sure to check the
                  entire form.
                </P>
                <P>
                  If the client does not provide complete information about themselves or any other
                  person covered under the policy, we will have the right to end their policy, or to
                  refuse to pay all or part of a claim.
                </P>
                <P>
                  We recommend that you and the client keep a record of all the information you
                  supply to us in connection with this form, including letters.
                </P>
                <P>If you or the client would like a copy of this form, please ask us.</P>
                <P>
                  This form must be received by us within six weeks of the date of this declaration.
                  Fill in the form with complete up-to-date medical history before you sign and date
                  it. If we do not receive this application form within six weeks of this
                  declaration date, we will require you to submit a new form.
                </P>
                <H3 margin="0 0 1rem">Obtaining medical reports from your GP</H3>
                <List listType="unordered">
                  <li>
                    <P>
                      The client/client's family members have confirmed that they understand that
                      Bupa may need them to provide a medical report from their GP within the first
                      24 months of their membership before treatment is authorised
                    </P>
                  </li>
                  <li>
                    <P>
                      The client/client's family members have agreed to Bupa obtaining this
                      information from their GP on their behalf and they understand that Bupa will
                      gain verbal confirmation from them prior to any medical report being requested
                      in this way
                    </P>
                  </li>
                  <li>
                    <P>
                      The client has confirmed that they understand the rights they have in relation
                      to such reports as explained in section 7
                    </P>
                  </li>
                </List>
                <P>
                  The client has confirmed that they have shown this declaration to the proposed
                  dependants on the policy and confirm that they understand that if they need to
                  claim they will be asked on the telephone to confirm their consent to Bupa
                  requesting a medical report on their behalf
                </P>
              </>
            ),
            type: "info"
          },
          skipDataMap: true
        },
        {
          key: "client_confirmed_bupa_can_obtain_medical_reports",
          component: Toggle,
          initialValue: get(
            data,
            "page.data.client_confirmed_bupa_can_obtain_medical_reports",
            false
          ),
          validationSchema: boolean()
            .oneOf(
              [true],
              "It is not possible to continue with the sales journey if the client has not confirmed their understanding of the Health Declaration."
            )
            .required("MISSING_REQUIRED_FIELD"),
          label: "Client confirms Bupa can obtain medical reports on your behalf?",
          componentProps: {
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true,
            labelWidth: "80.5rem"
          }
        }
      ]
    }
  ]
})
