import React, { useContext } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { Input, DatePicker } from "@4cplatform/elements/Forms"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { BankDetailsContext } from "./context/bankDetails.context"

// Components
import {
  BankDetailsFieldsWrapper,
  Divider,
  FlexRow,
  FlexCol,
  ManualWrapper
} from "./bankDetails.styles"

const BankDetailsFields = ({
  labelWidth,
  isHorizontal,
  appearance,
  isDisabled,
  apiErrors,
  provider
}) => {
  const { setSearch, search, setFresh, formik, display, setFilledIn, sortcodeLookupLoading } =
    useContext(BankDetailsContext)
  const sortCodeValue = get(formik, "values.sort_code", null)
  // Check whether or not to display the Cancel button and behaviors
  const isCancel = (!!search && display !== "fresh") || display === "filled_in"

  return (
    <BankDetailsFieldsWrapper>
      {provider === "EXETER" ? (
        <>
          <Input
            label="Direct Debit Declaration Agreed By"
            isHorizontal={isHorizontal}
            labelWidth={labelWidth}
            name="exeter_direct_debit.declaration_agreed_by"
            margin="0 0 2rem"
            placeholder=""
            formik={formik}
            appearance={appearance}
            isDisabled={isDisabled}
            apiErrors={apiErrors}
          />
          <DatePicker
            label="Direct Debit Declaration Agreed At"
            isHorizontal={isHorizontal}
            labelWidth={labelWidth}
            name="exeter_direct_debit.declaration_agreed_at"
            margin="0 0 1rem"
            placeholder=""
            formik={formik}
            appearance={appearance}
            isDisabled={isDisabled}
            apiErrors={apiErrors}
          />
          <Input
            label="Preferred Payment Day"
            isHorizontal={isHorizontal}
            labelWidth={labelWidth}
            name="exeter_direct_debit.preferred_payment_day"
            margin="0 0 3rem"
            placeholder=""
            formik={formik}
            appearance={appearance}
            isDisabled={isDisabled}
            apiErrors={apiErrors}
          />
        </>
      ) : (
        ""
      )}
      <Input
        label="Name on account"
        isHorizontal={isHorizontal}
        labelWidth={labelWidth}
        name="name_on_account"
        placeholder=""
        formik={formik}
        appearance={appearance}
        isDisabled={isDisabled}
        apiErrors={apiErrors}
        isRequired
      />
      <FlexCol>
        <Input
          label="Account number"
          isHorizontal={isHorizontal}
          labelWidth={labelWidth}
          name="account_number"
          margin="2rem 0"
          leadingText="#"
          placeholder="########"
          formik={formik}
          appearance={appearance}
          isDisabled={isDisabled}
          apiErrors={apiErrors}
          helperText="Must be 8 digits and cannot contain spaces or special characters. For account numbers with 7 digits, prefix with a 0."
          isRequired
        />
      </FlexCol>

      <FlexRow>
        <Input
          label="Sort code"
          isHorizontal={isHorizontal}
          labelWidth={labelWidth}
          name="sort_code"
          formik={formik}
          trailingIcon="magnify"
          leadingText="#"
          placeholder="######"
          onClick={() => {
            if (get(formik, "values.sort_code").length === 6) setSearch(sortCodeValue)
          }}
          appearance={appearance}
          isDisabled={isDisabled || sortcodeLookupLoading}
          apiErrors={apiErrors}
          helperText="Must be 6 digits long"
          isRequired
        />
        <ManualWrapper>
          <Button
            appearance={appearance === "light" ? "whiteInline" : "primaryInline"}
            type="inline-button"
            margin="0 0 0 1rem"
            onClick={() => {
              if (isCancel) {
                setFresh()
              } else {
                setFilledIn()
              }
            }}
            name={isCancel ? "cancel_manually" : "enter_manually"}
            isDisabled={isDisabled}
          >
            {isCancel ? "Clear sortcode" : "Enter manually"}
          </Button>
        </ManualWrapper>
      </FlexRow>
      {display === "filled_in" && <Divider />}
    </BankDetailsFieldsWrapper>
  )
}

BankDetailsFields.defaultProps = {
  labelWidth: "18rem",
  isHorizontal: false,
  appearance: "dark",
  isDisabled: false,
  apiErrors: {}
}

BankDetailsFields.propTypes = {
  /**
   * Width of the label element while the component is in Horizontal mode
   */
  labelWidth: PropTypes.string,
  /**
   * Display the component in Horizontal mode
   */
  isHorizontal: PropTypes.bool,
  /**
   * The light/dark appearance of the label text
   */
  appearance: PropTypes.oneOf(["dark", "light"]),
  /**
   * This controls the disabled state of the Select
   */
  isDisabled: PropTypes.bool,
  /**
   * API errors
   */
  apiErrors: PropTypes.object,
  /**
   * Selected quote provider
   */
  provider: PropTypes.string
}

export default BankDetailsFields
