import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { P } from "@4cplatform/elements/Typography"
import { Icon } from "@4cplatform/elements/Atoms"
import { colours } from "@4cplatform/elements/Helpers"

// Components
import { CardWrapper, ActionsButton } from "./assignment.styles"

// Helpers
import { AgencyCodesContext } from "../../agencyCodes.context"

const Card = ({ code }) => {
  const { setViewDeal, isAllLoading } = React.useContext(AgencyCodesContext)
  const isSuspended = get(code, "suspended", false)

  return (
    <CardWrapper data-testid={`deal_code_assignment-card-${code.id}`}>
      {/* Text */}
      <div>
        <P margin="0 0 0.5rem">{get(code, "name", "-")}</P>
      </div>
      {/* Actions */}
      <div>
        <Icon
          icon={!isSuspended ? "check-circle" : "close-circle"}
          colour={!isSuspended ? get(colours, "green") : get(colours, "red")}
        />
        <ActionsButton
          data-testid={`deal_code_assignment-view_button-${code.id}`}
          onClick={() =>
            isAllLoading || setViewDeal({ isOpen: true, slug: get(code, "slug"), data: code })
          }
        >
          <Icon icon="dots-horizontal" colour={get(colours, "blue")} />
        </ActionsButton>
      </div>
    </CardWrapper>
  )
}

Card.propTypes = {
  code: PropTypes.object.isRequired
}

export default Card
