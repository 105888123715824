import React from "react"
import { Container } from "@4cplatform/elements/Atoms"
import { Helmet } from "react-helmet-async"
import { H1 } from "@4cplatform/elements/Typography"

// Helpers
import { DASHBOARD } from "../../config/pages"

// Components
import { Breadcrumbs } from "../../UI/Molecules"
import { CommissionRatesAdmin, CommissionRatesPanel } from "../../UI/Templates"
import CommissionRatesProvider from "./context/commissionRates.provider"

const CommissionRates = () => (
  <>
    <Helmet>
      <title>Commission Rates</title>
    </Helmet>
    <CommissionRatesProvider>
      <Container isPageContainer width="80%">
        <Breadcrumbs
          trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: "Commission Rates" }]}
        />
        <H1 margin="0 0 6rem">Commission Rates</H1>
        <CommissionRatesAdmin />
      </Container>
      <CommissionRatesPanel />
    </CommissionRatesProvider>
  </>
)

export default CommissionRates
