import React, { useState, useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get } from "lodash"

import { renderTitleOptions } from "@4cplatform/elements/Helpers"
import { Input, QuerySelect, Select, DatePicker, Checkbox } from "@4cplatform/elements/Forms"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { H4, P } from "@4cplatform/elements/Typography"
import { ConfigContext } from "@4cplatform/elements/Config"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { ApplicantsContext } from "./applicants.context"
import { JourneyContext } from "../../../../../../journey.context"

const ApplicantDetails = ({ formik, type }) => {
  const isDependant = type === "dependant"
  const isChild = get(formik, "values.applicant.child", true)
  const [showDependantOlderThanPrimaryError, setShowDependentOlderThanPrimaryError] =
    useState(false)
  const t = useTranslations()
  const { LOADING_TITLES, GLOBAL_TITLES } = useContext(ConfigContext)
  const { applicants, emailValidateError, updateEmailValidateError } = useContext(ApplicantsContext)
  const { data } = useContext(JourneyContext)
  const isLocked = get(data, "journey.locked", false)
  const firstRender = useRef(true)
  const [email, setEmail] = useState(get(formik, "values.applicant.email_address", ""))
  // reset DOB value each time the child option is toggled
  useEffect(() => {
    if (firstRender.current) {
      return (firstRender.current = false)
    }

    formik.setFieldValue("applicant.date_of_birth", "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChild])

  useEffect(
    () => () => {
      updateEmailValidateError("")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return (
    <>
      <Select
        name="applicant.gender_at_birth"
        formik={formik}
        label="Gender at birth"
        isDisabled={isLocked}
      >
        <option value="">Select a gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </Select>
      <Select
        name="applicant.title"
        label="Title"
        formik={formik}
        isDisabled={LOADING_TITLES || isLocked}
      >
        {LOADING_TITLES ? (
          <option value="">Loading titles</option>
        ) : (
          <option value="">Select title</option>
        )}
        {renderTitleOptions(GLOBAL_TITLES?.data, formik, "applicant.gender_at_birth")}
      </Select>
      <Input label="First name" name="applicant.first_name" formik={formik} isDisabled={isLocked} />
      <Input
        label="Middle names"
        name="applicant.middle_names"
        formik={formik}
        isDisabled={isLocked}
      />
      <Input label="Last name" name="applicant.last_name" formik={formik} isDisabled={isLocked} />
      <Input
        label="Email address"
        name="applicant.email_address"
        formik={formik}
        isDisabled={isLocked}
        value={email}
        onChange={value => {
          if (emailValidateError) {
            updateEmailValidateError("")
          }
          formik.setFieldValue("applicant.email_address", value)
          setEmail(value)
        }}
      />
      {emailValidateError && (
        <ComplianceNote type="error" margin="3rem 0 2rem">
          <H4 margin="0 0 1rem">Error</H4>
          <P margin="0">{t(emailValidateError)}</P>
        </ComplianceNote>
      )}
      {/* Conditional fields */}
      {isDependant && (
        <Checkbox
          label="Child"
          name="applicant.child"
          formik={formik}
          margin="2rem 0 2rem"
          isDisabled={isLocked}
        />
      )}
      {(!isDependant || (isDependant && !isChild)) && (
        <QuerySelect
          name="applicant.occupation"
          label="Occupation"
          noun={{ singular: "occupation", plural: "occupations" }}
          endpoint="/occupations"
          helperTitle="Occupation"
          helperText="<p>Please note that some insurers apply a discount to the premium should the client disclose that this person falls under one of the selected occupations. Your client may be asked to provide evidence of this person's occupation in order to be eligible for any discounts applied. If your client is unable or unwilling to provide evidence of this person's occupation, please mark their occupation as Other.</p>"
          render={jobs => {
            const keys = Object.keys(jobs)
            return keys.map(key => (
              <option key={key} value={key}>
                {jobs[key]}
              </option>
            ))
          }}
          formik={formik}
          shouldSkip={isDependant && isChild}
          isDisabled={isLocked}
        />
      )}
      {showDependantOlderThanPrimaryError && (
        <ComplianceNote type="error" margin="3rem 0 2rem">
          <H4 margin="0 0 1rem">Error</H4>
          <P margin="0">The age of the dependant cannot exceed that of the policy holder.</P>
        </ComplianceNote>
      )}
      <DatePicker
        isDOBInput
        name="applicant.date_of_birth"
        formik={formik}
        label="Date of birth"
        margin="0"
        dateRangeMax={
          isDependant && isChild ? null : moment().subtract(18, "years").format("DD/MM/YYYY")
        }
        dateRangeMin={
          isDependant && isChild ? moment().subtract(18, "years").format("DD/MM/YYYY") : null
        }
        onChange={selectedDate => {
          if (!isDependant) return formik.setFieldValue("applicant.date_of_birth", selectedDate)

          const primaryApplicantDOB = applicants.find(_ => _.type === "primary")?.date_of_birth

          if (moment(selectedDate).isAfter(moment(primaryApplicantDOB))) {
            formik.setFieldValue("applicant.date_of_birth", selectedDate)
            if (showDependantOlderThanPrimaryError) setShowDependentOlderThanPrimaryError(false)
          } else {
            setShowDependentOlderThanPrimaryError(true)
          }
        }}
        isDisabled={isLocked}
      />
    </>
  )
}

ApplicantDetails.propTypes = {
  formik: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}

export default ApplicantDetails
