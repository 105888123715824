import React, { useEffect, useContext, useReducer } from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import moment from "moment"
import { useLocation, useHistory } from "react-router-dom"
import { get, sortBy, isEmpty, cloneDeep, toLower } from "lodash"
import { useGet, usePost, useDelete, usePatch, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { PageContext } from "@4cplatform/elements/Organisms"
import { getOrderBy, getPolicyStatus } from "../../../UI/Helpers"
import reducer from "./policies.reducer"

// Components
import { Provider } from "../../../UI/Templates/PolicyAdmin"

const PoliciesProvider = ({ children, type }) => {
  const t = useTranslations()
  const { setPanelStatus } = useContext(PageContext)

  const { search: lSearch } = useLocation()
  const history = useHistory()
  const qs = queryString.parse(lSearch)
  const queryStatus = get(qs, "status", "")
  const simulatedQuery = get(qs, "simulated", "")
  const tabQuery = get(qs, "tab", "")
  const statuses = getPolicyStatus(queryStatus)
  const skipFetchAllPolicies = type === "policy_tab"
  // State
  const [
    {
      data,
      viewData,
      showSimulated,
      hasShowSimulated,
      status,
      hasStatusFilter,
      search,
      filter,
      selectedPolicy,
      selectedSlug,
      sorting,
      page,
      perPage,
      total,
      editModal,
      deleteExclusionModal,
      declineUnderwritingModal,
      declinePolicyModal,
      messagingModal,
      exclusionsModal,
      reference,
      selectedExclusion,
      messages,
      exclusionsData,
      requestMoreInfoModal,
      currentTab,
      duration
    },
    dispatch
  ] = useReducer(reducer, {
    data: [],
    viewData: {},
    showSimulated: simulatedQuery === "true",
    hasShowSimulated: true,
    status: statuses,
    hasStatusFilter: true,
    search: "",
    selectedPolicy: null,
    selectedSlug: get(qs, "selected", ""),
    selectedExclusion: null,
    sorting: {
      direction: "desc",
      dataKey: ["", "sold"].includes(toLower(t(get(qs, "status")))) ? "onboarded_at" : "created_at"
    },
    filter: "client_name",
    page: 1,
    perPage: 10,
    total: null,
    messagingModal: false,
    deleteExclusionModal: false,
    exclusionsModal: false,
    declineUnderwritingModal: false,
    declinePolicyModal: false,
    reference: "",
    messages: [],
    exclusionsData: [],
    requestMoreInfoModal: false,
    currentTab: tabQuery,
    duration: moment()
  })

  const querySearch = {}
  if (search) querySearch[filter] = search
  if (status.length) querySearch.status = status
  // Index Policies query
  const {
    loading: queryLoading,
    error: queryError,
    called: queryCalled,
    refetch: queryRefetch
  } = useGet({
    endpoint: "/policies",
    skip: skipFetchAllPolicies,
    query: {
      page,
      limit: perPage,
      order_by: getOrderBy(sorting),
      simulated: showSimulated,
      status,
      ...querySearch
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () =>
      addAlert({
        message: t("POLICIES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Policy data query
  const {
    loading: viewLoading,
    error: viewError,
    refetch: viewRefetch
  } = useGet({
    endpoint: "/policies/:slug",
    skip: !selectedPolicy && !selectedSlug,
    params: {
      slug: get(selectedPolicy, "slug", selectedSlug)
    },
    query: {
      with: [
        "organisation",
        "client",
        "salesAgent",
        "provider",
        "disclosureNotes",
        "medicalNotes",
        "applicants",
        "documentLinks",
        "journey",
        "exclusions"
      ]
    },
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: get(res, "data", {}) }),
    onError: () =>
      addAlert({
        message: t("POLICY_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  const [submitAudit, { loading: auditLoading, error: auditError }] = usePost({
    endpoint: "/journeys/:reference/audits",
    params: {
      reference: get(viewData, "journey_slug", "")
    }
  })

  const submitJourneyAudit = auditForm => {
    // Submit Journey Audit
    const auditBody = {
      duration: moment().diff(duration, "seconds"),
      ...auditForm
    }
    submitAudit({ body: auditBody })
  }

  const removeSelectedParams = () => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    searchParams.delete("selected")
    url.search = searchParams.toString()
    history.replace(url.pathname + url.search)
  }

  const onPolicyDeselect = () => {
    dispatch({ type: "UPDATE_VALUE", key: "viewData", value: {} })
    dispatch({ type: "UPDATE_VALUE", key: "selectedPolicy", value: null })
    dispatch({ type: "UPDATE_VALUE", key: "exclusionsData", value: [] })
    dispatch({ type: "UPDATE_VALUE", key: "messages", value: [] })
  }

  // Policy exclusions data query
  const {
    loading: exclusionsLoading,
    error: exclusionsError,
    refetch: exclusionsRefetch
  } = useGet({
    endpoint: "/policies/:slug/exclusions",
    skip: !selectedPolicy || get(selectedPolicy, "status", "") !== "AWAITING_TERMS",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "exclusionsData", value: get(res, "data", []) }),
    onError: () =>
      addAlert({
        message: t("EXCLUSIONS_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Add policy exclusion
  const [onCreateExclusion, { loading: addExclusionLoading, error: addExclusionError }] = usePost({
    endpoint: "/policies/:slug/exclusions",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("EXCLUSION_ADD_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      exclusionsRefetch()
    },
    onError: () =>
      addAlert({
        message: t("EXCLUSION_ADD_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Delete exclusion mutation
  const [onDeleteExclusion, { loading: deleteExclusionLoading }] = useDelete({
    endpoint: "/policies/:slug/exclusions/:exclusion",
    onCompleted: () => {
      addAlert({
        message: t("EXCLUSION_DELETE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 2
      })
      exclusionsRefetch()
    },
    onError: () =>
      addAlert({
        message: t("EXCLUSION_DELETE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Update exclusion mutation
  const [onUpdateExclusion, { loading: updateExclusionLoading }] = usePatch({
    endpoint: "/policies/:slug/exclusions/:exclusion",
    params: {
      slug: get(selectedPolicy, "slug", ""),
      exclusion: get(selectedExclusion, "id", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("EXCLUSION_UPDATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      exclusionsRefetch()
    },
    onError: () =>
      addAlert({
        message: t("EXCLUSION_UPDATE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Accept underwriting
  const [
    onAcceptUnderwriting,
    { loading: acceptUnderwritingLoading, error: acceptUnderwritingError }
  ] = usePatch({
    endpoint: "/policies/:slug/accept-underwriting",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("UNDERWRITING_ACCEPT_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      setPanelStatus("closed")
      onPolicyDeselect()
      queryRefetch()
    },
    onError: () =>
      addAlert({
        message: t("UNDERWRITING_ACCEPT_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Accept underwriting with exclusions
  const [
    onAcceptUnderwritingExclusions,
    { loading: acceptUnderwritingExclusionsLoading, error: acceptUnderwritingExclusionsError }
  ] = usePatch({
    endpoint: "/policies/:slug/accept-underwriting-with-exclusions",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("UNDERWRITING_EXCLUSIONS_ACCEPT_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      setPanelStatus("closed")
      onPolicyDeselect()
      queryRefetch()
    },
    onError: () =>
      addAlert({
        message: t("UNDERWRITING_EXCLUSIONS_ACCEPT_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Accept underwriting
  const [onRequestMoreInfo, { loading: requestMoreInfoLoading, error: requestMoreInfoError }] =
    usePatch({
      endpoint: "/policies/:slug/require-more-info-underwriting",
      params: {
        slug: get(selectedPolicy, "slug", "")
      },
      onCompleted: () => {
        addAlert({
          message: t("REQUIRE_MORE_INFO_SUCCESS"),
          type: "success",
          dismissible: true,
          timeout: 5
        })
        dispatch({ type: "UPDATE_VALUE", key: "requestMoreInfoModal", value: false })
        setPanelStatus("closed")
        onPolicyDeselect()
        queryRefetch()
      },
      onError: () =>
        addAlert({
          message: t("REQUIRE_MORE_INFO_ERROR"),
          type: "error",
          dismissible: true,
          timeout: 5
        })
    })

  // Decline underwriting
  const [
    onDeclineUnderwriting,
    { loading: declineUnderwritingLoading, error: declineUnderwritingError }
  ] = usePatch({
    endpoint: "/policies/:slug/decline-underwriting",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("UNDERWRITING_DECLINE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "declineUnderwritingModal", value: false })
      setPanelStatus("closed")
      onPolicyDeselect()
      queryRefetch()
    },
    onError: () =>
      addAlert({
        message: t("UNDERWRITING_DECLINE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Accept Policy
  const [onAcceptPolicy, { loading: acceptPolicyLoading, error: acceptPolicyError }] = usePatch({
    endpoint: "/policies/:slug/accept",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("POLICY_ACCEPT_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "reference", value: "" })
      setPanelStatus("closed")
      onPolicyDeselect()
      queryRefetch()
    },
    onError: () =>
      addAlert({
        message: t("POLICY_ACCEPT_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Decline policy
  const [onDeclinePolicy, { loading: declinePolicyLoading, error: declinePolicyError }] = usePatch({
    endpoint: "/policies/:slug/decline",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("POLICY_DECLINE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "declinePolicyModal", value: false })
      setPanelStatus("closed")
      onPolicyDeselect()
      queryRefetch()
    },
    onError: () =>
      addAlert({
        message: t("POLICY_DECLINE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Get Policy messages
  const {
    loading: messagesLoading,
    error: messagesError,
    refetch: messagesRefetch
  } = useGet({
    endpoint: "/policies/:policy/messages",
    skip: !messagingModal,
    params: {
      policy: get(viewData, "slug", "")
    },
    onCompleted: res =>
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy(get(res, "data", {}), "sent_at")
      }),
    onError: () =>
      addAlert({
        message: t("MESSAGES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Create a message
  const [createMessage, { loading: submitMessageLoading, error: submitMessageError }] = usePost({
    endpoint: "/policies/:policy/message",
    params: {
      policy: get(viewData, "slug", "")
    },
    onCompleted: res => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy([...messages, res.data], "sent_at")
      })
    },
    onError: () => {
      messagesRefetch()
      addAlert({
        message: t("MESSAGE_SEND_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const submitMessage = ({ body, user }) => {
    const updatedMessages = [
      ...messages,
      {
        body_text: body.body_text,
        user_name: user
      }
    ]
    dispatch({
      type: "UPDATE_VALUE",
      key: "messages",
      value: updatedMessages
    })
    createMessage({ body })
  }

  useEffect(() => {
    if (messagingModal && isEmpty(messages)) messagesRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagingModal])

  useEffect(() => {
    dispatch({
      type: "UPDATE_VALUE",
      key: "status",
      value: queryStatus ? getPolicyStatus(queryStatus) : []
    })
  }, [queryStatus])

  useEffect(() => {
    dispatch({
      type: "UPDATE_VALUE",
      key: "showSimulated",
      value: simulatedQuery === "true"
    })
  }, [simulatedQuery])

  useEffect(() => {
    if (selectedSlug) {
      setPanelStatus("open")
      viewRefetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlug])

  const removeSelectedSlugFormURL = () => {
    if (selectedSlug) {
      dispatch({ type: "UPDATE_VALUE", key: "selectedSlug", value: null })
      removeSelectedParams()
    }
  }

  const [generateAuditLog, { loading: generateAuditLogLoading }] = usePost({
    endpoint: "/journeys/:slug/generate-audits",
    params: {
      slug: get(viewData, "journey_slug", "")
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      if (filePath) {
        window.open(filePath, "_blank")
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("AUDIT_LOG_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  useEffect(() => {
    if (queryCalled && !queryLoading)
      dispatch({ type: "UPDATE_VALUE", key: "duration", value: moment() })
  }, [queryLoading, queryCalled])

  const setSearch = React.useCallback(
    value => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        viewData,
        exclusionsData,
        showSimulated,
        hasShowSimulated,
        status,
        hasStatusFilter,
        search,
        selectedPolicy,
        sorting,
        page,
        perPage,
        total,
        messagingModal,
        exclusionsModal,
        reference,
        selectedExclusion,
        queryLoading,
        queryRefetch,
        viewLoading,
        viewRefetch,
        exclusionsLoading,
        exclusionsRefetch,
        addExclusionLoading,
        deleteExclusionLoading,
        updateExclusionLoading,
        editModal,
        deleteExclusionModal,
        onAcceptUnderwriting,
        acceptUnderwritingLoading,
        onAcceptUnderwritingExclusions,
        acceptUnderwritingExclusionsLoading,
        onDeclineUnderwriting,
        declineUnderwritingLoading,
        acceptPolicyLoading,
        onDeclinePolicy,
        declineUnderwritingModal,
        declinePolicyLoading,
        declinePolicyModal,
        messages,
        messagesLoading,
        submitMessage,
        submitMessageLoading,
        generateAuditLogLoading,
        generateAuditLog,
        setShowSimulated: value => {
          dispatch({ type: "UPDATE_VALUE", key: "showSimulated", value })
          history.replace({ search: queryString.stringify({ ...qs, simulated: value }) })
        },
        setFilter: value => dispatch({ type: "UPDATE_VALUE", key: "filter", value }),
        filter,
        setStatus: value => {
          if (!value) {
            dispatch({ type: "UPDATE_VALUE", key: "status", value: [] })
            const newQS = cloneDeep(qs)
            delete newQS.status
            history.replace({
              search: queryString.stringify(newQS)
            })
          } else {
            dispatch({ type: "UPDATE_VALUE", key: "status", value: getPolicyStatus(value) })
            history.replace({
              search: queryString.stringify({ ...qs, status: value })
            })
          }
        },
        setStatusFilter: value => dispatch({ type: "UPDATE_VALUE", key: "hasStatusFilter", value }),
        setSearch,
        onPolicySelect: value => dispatch({ type: "UPDATE_VALUE", key: "selectedPolicy", value }),
        onPolicyDeselect,
        onSort: value => dispatch({ type: "UPDATE_VALUE", key: "sorting", value }),
        pagination: { total, page, perPage },
        setPage: value => {
          dispatch({ type: "UPDATE_VALUE", key: "page", value })
          removeSelectedSlugFormURL()
        },
        setPerPage: value => dispatch({ type: "UPDATE_VALUE", key: "perPage", value }),
        setEditModal: value => dispatch({ type: "UPDATE_VALUE", key: "editModal", value }),
        setDeleteExclusionModal: value =>
          dispatch({ type: "UPDATE_VALUE", key: "deleteExclusionModal", value }),
        setMessagingModal: value => {
          dispatch({ type: "UPDATE_VALUE", key: "messagingModal", value })
          if (!value) dispatch({ type: "UPDATE_VALUE", key: "messages", value: [] })
        },
        setExclusionsModal: value =>
          dispatch({ type: "UPDATE_VALUE", key: "exclusionsModal", value }),
        setDeclineUnderwritingModal: value =>
          dispatch({ type: "UPDATE_VALUE", key: "declineUnderwritingModal", value }),
        setDeclinePolicyModal: value =>
          dispatch({ type: "UPDATE_VALUE", key: "declinePolicyModal", value }),
        onExclusionSelect: value =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedExclusion", value }),
        onCreateExclusion,
        onDeleteExclusion: exclusion =>
          onDeleteExclusion({
            params: {
              slug: get(selectedPolicy, "slug", ""),
              exclusion
            }
          }),
        onUpdateExclusion,
        setReference: value => dispatch({ type: "UPDATE_VALUE", key: "reference", value }),
        onPolicyAccept: body => onAcceptPolicy({ body }),
        requestMoreInfoModal,
        setRequestMoreInfoModal: value =>
          dispatch({ type: "UPDATE_VALUE", key: "requestMoreInfoModal", value }),
        onRequestMoreInfo,
        requestMoreInfoLoading,
        currentTab,
        auditLoading,
        auditError,
        submitJourneyAudit
      }}
    >
      {children}
      <ApiError
        error={
          queryError ||
          viewError ||
          exclusionsError ||
          addExclusionError ||
          acceptUnderwritingExclusionsError ||
          acceptUnderwritingError ||
          declineUnderwritingError ||
          acceptPolicyError ||
          declinePolicyError ||
          messagesError ||
          submitMessageError ||
          requestMoreInfoError
        }
      />
    </Provider>
  )
}

PoliciesProvider.defaultProps = {
  children: null,
  type: ""
}

PoliciesProvider.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string
}

export default PoliciesProvider
