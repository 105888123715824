import styled from "styled-components"

export const RateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`

export const InputWrapper = styled.div`
  width: 16.5rem;
  margin-right: 1rem;
`
