import React, { useContext, Fragment } from "react"
import moment from "moment"
import { get, isEmpty, filter } from "lodash"
import { v4 as uuid } from "uuid"
import { IconWithText, LabelWithText } from "@4cplatform/elements/Atoms"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { PoliciesContext } from "./policies.context"
import { getName } from "../../Helpers"

// Components
import { Divider } from "./policies.styles"
import AvivaDisclosure from "./policies.panel.body.disclosures.aviva"
import AxaDisclosure from "./policies.panel.body.disclosures.axa"
import BupaDisclosure from "./policies.panel.body.disclosures.bupa"

const Disclosures = () => {
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { viewData, viewLoading } = useContext(PoliciesContext)

  const providerKey = get(viewData, "provider.provider_key")

  const disclosureNotes = get(viewData, "disclosure_notes", [])
  const applicants = get(viewData, "applicants", [])

  return (
    <LabelWithText appearance="light" label="Medical disclosures" isLoading={viewLoading}>
      {isEmpty(disclosureNotes) && (
        <IconWithText
          appearance="light"
          icon="minus"
          content="No disclosures"
          isLoading={viewLoading}
          margin="0"
        />
      )}
      {!isEmpty(disclosureNotes) &&
        applicants.map((applicant, i) => {
          const applicantDisclosures = filter(disclosureNotes, [
            "journey_applicant.id",
            get(applicant, "journey_applicant_id")
          ])
          const name = getName({
            data: applicant,
            hasTitle: true,
            hasMiddle: true,
            globalTitles: GLOBAL_TITLES
          })
          const age = moment().diff(get(applicant, "date_of_birth"), "years")

          return (
            <Fragment key={uuid()}>
              <LabelWithText
                key={uuid()}
                appearance="light"
                label={`${name}, ${age}`}
                name={`disclosures_person_${i}`}
                isLoading={viewLoading}
              >
                {isEmpty(applicantDisclosures) && (
                  <IconWithText
                    appearance="light"
                    icon="minus"
                    content="No disclosures"
                    isLoading={viewLoading}
                    margin="0"
                  />
                )}
                {!isEmpty(applicantDisclosures) &&
                  applicantDisclosures.map(data => {
                    switch (providerKey) {
                      case "AVIVA":
                        return <AvivaDisclosure key={uuid()} data={data} />
                      case "AXA":
                        return <AxaDisclosure key={uuid()} data={data} />
                      case "BUPA":
                        return <BupaDisclosure key={uuid()} data={data} />
                      default:
                        return null
                    }
                  })}
              </LabelWithText>
              {i !== applicants.length - 1 && <Divider />}
            </Fragment>
          )
        })}
    </LabelWithText>
  )
}

export default Disclosures
