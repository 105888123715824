import React, { useContext } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import moment from "moment"
import { useTranslations } from "@4cplatform/elements/Translations"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { colours } from "@4cplatform/elements/Helpers"

// Helpers
import { JourneysContext } from "./journeys.context"
import { getName } from "../../Helpers"

// Components
import Actions from "./journeys.actions"
import { SimulatedWrapper } from "./journeys.styles"

const JourneyAdmin = ({ hasStatus, hasOrganisation }) => {
  const t = useTranslations()

  const {
    data,
    queryLoading,
    onJourneySelect,
    onJourneyDeselect,
    pagination,
    setPage,
    setPerPage,
    onSort,
    sorting
  } = useContext(JourneysContext)

  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = useContext(PageContext)

  return (
    <>
      <Actions />
      <Table
        data={data}
        isLoading={queryLoading}
        name="journeys"
        columns={[
          {
            label: "Journey Ref",
            dataKey: "reference",
            minWidth: "60px",
            sortable: true
          },
          ...(hasStatus
            ? [
                {
                  label: "Status",
                  dataKey: "status",
                  minWidth: "60px",
                  sortable: true,
                  render: row => t(get(row, "data.status"))
                }
              ]
            : [
                {
                  label: "Status",
                  dataKey: "simulated",
                  minWidth: "60px",
                  render: row => {
                    const isSimulated = get(row, "data.simulation_mode", 0)
                    return isSimulated === 1 ? (
                      <SimulatedWrapper>simulated</SimulatedWrapper>
                    ) : (
                      <></>
                    )
                  }
                }
              ]),
          {
            label: "Client",
            dataKey: "client",
            minWidth: "60px",
            fieldColour: get(colours, "blue", "blue"),
            sortable: true,
            render: row => getName({ data: get(row, "data.client") })
          },
          {
            label: "Sales agent",
            dataKey: "sales_agent",
            minWidth: "60px",
            sortable: true,
            render: row => getName({ data: get(row, "data.sales_agent") })
          },
          ...(hasOrganisation
            ? [
                {
                  label: "Organisation",
                  dataKey: "organisation",
                  sortable: true,
                  minWidth: "100px",
                  render: row => get(row, "data.organisation.name")
                }
              ]
            : []),
          {
            label: "Created",
            dataKey: "created_at",
            minWidth: "60px",
            render: row => {
              const created = get(row, "data.created_at", "")
              return created
                ? moment.utc(created, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          },
          {
            label: "Last Updated",
            dataKey: "updated_at",
            minWidth: "60px",
            render: row => {
              const updated = get(row, "data.updated_at", "")
              return updated
                ? moment.utc(updated, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          }
        ]}
        hasActions
        onClick={row => {
          onJourneySelect(row)
          setPanelStatus("open")
        }}
        onClose={() => {
          onJourneyDeselect()
          if (panelStatus !== "closed") {
            setPanelStatus("closed")
          }
        }}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        perPageOptions={{ max: 50, interval: 5 }}
        sorting={sorting}
      />
    </>
  )
}

JourneyAdmin.propTypes = {
  hasStatus: PropTypes.bool,
  hasOrganisation: PropTypes.bool
}

export default JourneyAdmin
