import React, { useContext, useEffect } from "react"
import { PageContext } from "@4cplatform/elements/Organisms"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"

// Components
import ViewAgencyCode from "./organisationAgencyCodes.panel.view"
import ShareAgencyCode from "./organisationAgencyCodes.panel.sharing"
import { AgencyCodesContext } from "../agencyCodes.context"

const AgencyCodesPanel = () => {
  const {
    panelStatusControls: { panelStatus }
  } = useContext(PageContext)
  const { setBodyTabId } = useContext(AgencyCodesContext)

  useEffect(() => {
    if (panelStatus === "closed" && setBodyTabId) setBodyTabId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelStatus])

  return (
    <FlyOutPanel
      body={() => <ViewAgencyCode />}
      wideBody={() => <ShareAgencyCode />}
      name="organisation_agency_codes_panel"
    />
  )
}
export default AgencyCodesPanel
