import React, { useContext } from "react"
import { get } from "lodash"
import moment from "moment"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { ClientsContext } from "../../clients.context"

// Components
import Actions from "./actions"
import ClientsPanelHeader from "../../clients.panel.header"
import ClientsPanelButtons from "../../clients.panel.body.buttons"
import RowActions from "./rowActions"

const ClientsQuotesTab = () => {
  const {
    clientQuotes,
    getQuotesLoading,
    quotesShowSimulated,
    quotesSorting,
    quotesPage: page,
    quotesPerPage: perPage,
    quotesTotal: total,
    updateQuotesPageValue
  } = useContext(ClientsContext)
  const {
    panelStatusControls: { panelStatus }
  } = useContext(PageContext)
  const t = useTranslations()
  const incompleteQuotes = clientQuotes.filter(quote => quote.status !== "COMPLETE")
  const pagination = { total, page, perPage }

  const quoteTypeColumn = quotesShowSimulated
    ? [
        {
          label: "Type",
          dataKey: "simulation_mode",
          minWidth: "80px",
          render: row => (get(row.data, "simulation_mode") === 1 ? "Simulated" : "Real"),
          sortable: true
        }
      ]
    : []

  return (
    <PanelBody>
      <ClientsPanelHeader isEdit />
      <ClientsPanelButtons margin="3rem 0 1rem" />
      <Actions />
      <Table
        appearance="light"
        data={incompleteQuotes}
        isLoading={getQuotesLoading}
        name="quotes"
        columns={[
          {
            label: "Reference",
            dataKey: "reference",
            minWidth: "150px",
            sortable: true,
            render: row => t(get(row.data, "reference"))
          },
          {
            label: "Agent",
            dataKey: "sales_agent",
            minWidth: "150px",
            sortable: true,
            render: row => t(get(row.data, "sales_agent_name"))
          },
          {
            label: "Created",
            dataKey: "created_at",
            minWidth: "150px",
            sortable: true,
            render: row => {
              const created = get(row.data, "created_at", "")
              return created
                ? moment.utc(created, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            }
          },
          ...quoteTypeColumn,
          {
            label: "",
            dataKey: "id",
            minWidth: "200px",
            // eslint-disable-next-line react/display-name
            render: row => <RowActions selectedQuote={get(row, "data")} />
          }
        ]}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={value => updateQuotesPageValue("quotesPage", value)}
        changePerPage={value => updateQuotesPageValue("quotesPerPage", value)}
        hasPerPage
        perPageOptions={{ max: 50, interval: 5 }}
        sorting={quotesSorting}
        onSort={value => updateQuotesPageValue("quotesSorting", value)}
        shouldForceShowPagination
      />
    </PanelBody>
  )
}

export default ClientsQuotesTab
