import { useContext, useEffect } from "react"

import { get, flattenDeep, map } from "lodash"

import moment from "moment"

import { JourneyContext } from "../../../../../../../journey.context"

const Audit = () => {
  const { data, formik, updateJourneyAuditData, pageDisclosuresNotes } = useContext(JourneyContext)

  const components = get(data, "page.sections", []).map(section => section.components)
  const productKey = get(data, "page.key")

  const mergeQuestionsWithNotes = (questions, notes) => {
    questions.forEach(question => {
      const field = question.key
      const matchedNote = notes.filter(note => note.field === field)
      question.note = matchedNote || null
      question.value = formik.values[field]
    })
  }
  const refactorAuditData = () => {
    const questionSet = flattenDeep(components)
      .map(component => ({
        key: component.key,
        label: component.label
      }))
      .filter(question => question.label)

    const formatBySelectMember = array => {
      const resultArray = map(array, value => ({
        name: value[0].value,
        value: value.filter(field => field.name !== "Select member")
      }))
      return resultArray
    }

    // eslint-disable-next-line no-console
    mergeQuestionsWithNotes(questionSet, pageDisclosuresNotes)

    const auditData = []
    switch (productKey) {
      case "AVIVA_SWITCH_DISCLOSURES":
        questionSet.forEach(question => {
          const notes = get(question, "note")
          const auditQueston = {
            name: question.label,
            value: question.value ? "Yes" : "No"
          }
          const auditNote = notes.map(note => [
            {
              name: "Select member",
              value: `${note.journey_applicant.first_name} ${note.journey_applicant.last_name}`
            },
            {
              name: "Condition / symptoms",
              value: get(note, "conditions_or_symptoms", true)
            },
            {
              name: "Date of consultation",
              value: get(note, "date_of_consultation")
                ? moment(get(note, "date_of_consultation"), "YYYY-MM-DDTHH:mmZ").format(
                    "DD/MM/YYYY"
                  )
                : "-"
            },
            {
              name: "Date of last symptoms / treatment",
              value: get(note, "date_of_last_symptom_or_treatment")
                ? moment(
                    get(note, "date_of_last_symptom_or_treatment"),
                    "YYYY-MM-DDTHH:mmZ"
                  ).format("DD/MM/YYYY")
                : "-"
            },
            {
              name: "Treatment received",
              value: get(note, "treatment_received", true)
            },
            {
              name: "Present state of health",
              value: get(note, "present_state_of_health", true)
            },
            {
              name: "Any foreseeable need for any further consultation or treatment?",
              value: get(note, "foreseeable_consultation_or_treatment") ? "Yes" : "No"
            }
          ])

          const auditQuestionSet = []
          auditQuestionSet.push(auditQueston)
          if (auditNote.length > 0) {
            const groupedAuditNote = formatBySelectMember(auditNote)
            auditData.push(auditQuestionSet.concat(groupedAuditNote))
          } else {
            auditData.push(auditQuestionSet)
          }
        })
        break
      case "AXA_FMU_DISCLOSURES":
        questionSet.forEach(question => {
          const notes = get(question, "note")
          let auditNote = []
          const auditQueston = {
            name: question.label,
            value: question.value ? "Yes" : "No"
          }

          auditNote = notes.map(note =>
            [
              {
                name: "Select member",
                value: `${note.journey_applicant.first_name} ${note.journey_applicant.last_name}`
              },
              get(note, "illness_disability") && {
                name: "Nature of illness/disability",
                value: get(note, "illness_disability")
              },
              get(note, "illness_disability_treatment_received") && {
                name: "Nature of illness/disability and treatment received",
                value: get(note, "illness_disability_treatment_received")
              },
              get(note, "treatment_received", true) && {
                name: "Treatment received",
                value: get(note, "treatment_received")
              },
              {
                name: "When did the illness/disability begin?",
                value: get(note, "date_illness_began")
                  ? moment(get(note, "date_illness_began"), "YYYY-MM-DDTHH:mmZ").format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              },
              {
                name: "When did the illness/disability end?",
                value: get(note, "date_illness_ended")
                  ? moment(get(note, "date_illness_ended"), "YYYY-MM-DDTHH:mmZ").format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              },
              {
                name: "Present state of health in this respect",
                value: get(note, "present_state_of_health", true)
              }
            ].filter(filterNote => filterNote)
          )

          const auditQuestionSet = []
          auditQuestionSet.push(auditQueston)
          if (auditNote.length > 0) {
            const groupedAuditNote = formatBySelectMember(auditNote)
            auditData.push(auditQuestionSet.concat(groupedAuditNote))
          } else {
            auditData.push(auditQuestionSet)
          }
        })
        break
      case "AXA_SWITCH_DISCLOSURES":
        questionSet.forEach(question => {
          const notes = get(question, "note")
          let auditNote = []
          const auditQueston = {
            name: question.label,
            value: question.value ? "Yes" : "No"
          }

          auditNote = notes.map(note =>
            [
              {
                name: "Select member",
                value: `${note.journey_applicant.first_name} ${note.journey_applicant.last_name}`
              },
              get(note, "illness_disability_treatment_received") && {
                name: "Nature of illness/disability and treatment received",
                value: get(note, "illness_disability_treatment_received")
              },
              get(note, "illness_disability") && {
                name: "Nature of illness/disability",
                value: get(note, "illness_disability")
              },
              get(note, "treatment_received", true) && {
                name: "Treatment received",
                value: get(note, "treatment_received")
              },
              {
                name: "When did the illness/disability begin?",
                value: get(note, "date_illness_began")
                  ? moment(get(note, "date_illness_began"), "YYYY-MM-DDTHH:mmZ").format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              },
              {
                name: "How the person is now",
                value: get(note, "present_state_of_health", true)
              }
            ].filter(filterNote => filterNote)
          )

          const auditQuestionSet = []
          auditQuestionSet.push(auditQueston)
          if (auditNote.length > 0) {
            const groupedAuditNote = formatBySelectMember(auditNote)
            auditData.push(auditQuestionSet.concat(groupedAuditNote))
          } else {
            auditData.push(auditQuestionSet)
          }
        })
        break
      case "BUPA_SWITCH_DISCLOSURES":
        questionSet.forEach(question => {
          const notes = get(question, "note")
          let auditNote = []
          const auditQueston = {
            name: question.label,
            value: question.value ? "Yes" : "No"
          }

          auditNote = notes.map(note =>
            [
              {
                name: "Select member",
                value: `${note.journey_applicant.first_name} ${note.journey_applicant.last_name}`
              },
              {
                name: "Please describe the illness or medical problem. If applicable please specify which area of the body is affected (eg left, right, upper, lower)",
                value: get(note, "illness_or_medical_problem", true)
              },
              {
                name: "When did the symptoms begin?",
                value: get(note, "date_of_symptoms_began")
                  ? moment(get(note, "date_of_symptoms_began"), "YYYY-MM-DDTHH:mmZ").format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              },
              {
                name: "When did the symptoms end?",
                value: get(note, "date_of_symptoms_ended")
                  ? moment(get(note, "date_of_symptoms_ended"), "YYYY-MM-DDTHH:mmZ").format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              },
              {
                name: "Treatment (prescribed or otherwise)",
                value: get(note, "treatment", true)
              },
              {
                name: "How many times have you consulted a healthcare professional in the past 2 years about this symptom / condition?",
                value: get(note, "number_of_consults_in_past_two_years", true)
              }
            ].filter(filterNote => filterNote)
          )

          const auditQuestionSet = []
          auditQuestionSet.push(auditQueston)
          if (auditNote.length > 0) {
            const groupedAuditNote = formatBySelectMember(auditNote)
            auditData.push(auditQuestionSet.concat(groupedAuditNote))
          } else {
            auditData.push(auditQuestionSet)
          }
        })

        break
      case "BUPA_FMU_DISCLOSURES":
        questionSet.forEach(question => {
          const notes = get(question, "note")
          let auditNote = []
          const auditQueston = {
            name: question.label,
            value: question.value ? "Yes" : "No"
          }

          auditNote = notes.map(note =>
            [
              {
                name: "Select member",
                value: `${note.journey_applicant.first_name} ${note.journey_applicant.last_name}`
              },
              {
                name: "Please describe the illness or medical problem. If applicable please specify which area of the body is affected (eg left, right, upper, lower)",
                value: get(note, "illness_or_medical_problem", true)
              },
              {
                name: "When did the symptoms begin?",
                value: get(note, "date_of_symptoms_began")
                  ? moment(get(note, "date_of_symptoms_began"), "YYYY-MM-DDTHH:mmZ").format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              },
              {
                name: "When did the symptoms end?",
                value: get(note, "date_of_symptoms_ended")
                  ? moment(get(note, "date_of_symptoms_ended"), "YYYY-MM-DDTHH:mmZ").format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              },
              {
                name: "Treatment (prescribed or otherwise)",
                value: get(note, "treatment", true)
              },
              {
                name: "Current state of condition / symptom",
                value: get(note, "current_state_of_condition_or_symptom", true)
              },
              {
                name: "How many times have you consulted a healthcare professional in the past 2 years about this symptom / condition?",
                value: get(note, "number_of_consults_in_past_two_years", true)
              }
            ].filter(filterNote => filterNote)
          )

          const auditQuestionSet = []
          auditQuestionSet.push(auditQueston)
          if (auditNote.length > 0) {
            const groupedAuditNote = formatBySelectMember(auditNote)
            auditData.push(auditQuestionSet.concat(groupedAuditNote))
          } else {
            auditData.push(auditQuestionSet)
          }
        })

        break
      default:
        return null
    }

    updateJourneyAuditData([
      {
        mode: "replace",
        data: auditData,
        isMultiCall: true,
        isGroupByArray: true
      }
    ])
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    refactorAuditData()

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDisclosuresNotes, formik.values])

  return null
}

export default Audit
