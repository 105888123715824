import React from "react"
import PropTypes from "prop-types"
import { Row, Column } from "@4cplatform/elements/Atoms"
import { H1, H3 } from "@4cplatform/elements/Typography"
import { Button } from "@4cplatform/elements/Molecules"

// Components
import { Container, HorizontalRule } from "./panelContent.styles"

const PanelContent = ({ children, title, subtitle, button, hrColour }) => (
  <Container width="80%">
    <Row justifyContent="center">
      <Column
        alignItems="center"
        phone={10}
        small={10}
        tablet={10}
        medium={10}
        large={10}
        xLarge={10}
        padding="0"
      >
        <H1 colour={title.colour} align="center" margin="0 0 1.2rem 0">
          {title.text}
        </H1>
        <H3 colour={subtitle.colour} align="center" margin="0">
          {subtitle.text}
        </H3>
      </Column>
    </Row>
    <HorizontalRule colour={hrColour} />
    {children}
    <Row justifyContent="center">
      <Button
        appearance={button.appearance || "whiteGhost"}
        type="a"
        href={button.href}
        trailingIcon={button.trailingIcon || "chevron-down"}
      >
        {button.text}
      </Button>
    </Row>
  </Container>
)

PanelContent.propTypes = {
  children: PropTypes.any,
  title: PropTypes.shape({
    text: PropTypes.string,
    colour: PropTypes.string
  }).isRequired,
  subtitle: PropTypes.shape({
    text: PropTypes.string,
    colour: PropTypes.string
  }).isRequired,
  button: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
    appearance: PropTypes.string,
    trailingIcon: PropTypes.string
  }).isRequired,
  hrColour: PropTypes.string
}

export default PanelContent
