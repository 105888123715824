import React from "react"
import PropTypes from "prop-types"
import { Icon } from "@4cplatform/elements/Atoms"
import { colours } from "@4cplatform/elements/Helpers"
import { FilterLabelWrapper } from "./miReports.style"

const FilterLabel = ({ name, onDelete }) => (
  <FilterLabelWrapper>
    <Icon size="1.5rem" icon="close" colour={colours.red} onClick={onDelete} /> {name}
  </FilterLabelWrapper>
)

FilterLabel.defaultProps = {
  name: ""
}

FilterLabel.propTypes = {
  name: PropTypes.string,
  onDelete: PropTypes.func
}

export default FilterLabel
