/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { get, isEmpty } from "lodash"
import { Modal, RenderHTML, Button } from "@4cplatform/elements/Molecules"

// Helpers
import { JourneyContext } from "../../journey.context"

// Components
import { ButtonsWrapper } from "./modal.styles"

const SubmitModal = () => {
  const { data, formik, onPageSubmit, hasSubmitModal, setSubmitModal } =
    React.useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)
  // Filter the list of modals to see which should be displayed
  const modals = get(data, "page.modals.submit", [])
  const values = get(formik, "values", {})
  const filteredModals = modals.filter(
    ({ fieldKey, fieldValueTrigger }) => fieldValueTrigger === get(values, fieldKey)
  )
  // If prompted to open the submit modal and no modals should be displayed, run default page submission function
  React.useEffect(() => {
    if (hasSubmitModal && isEmpty(filteredModals)) {
      setSubmitModal(false)
      onPageSubmit()
    }
  }, [hasSubmitModal])

  // Don't render modal content if there are no trigger matches
  if (isEmpty(filteredModals)) return null

  let modal = { title: "", content: "", onModalAccept: "", formikValuesToSet: "" }

  // If there's only one modal, set the modal with a title
  if (filteredModals.length === 1) {
    modal = {
      title: filteredModals[0].title,
      content: filteredModals[0].content,
      onModalAccept: filteredModals[0].onModalAccept,
      formikValuesToSet: filteredModals[0].formikValuesToSet
    }
  }

  // If there is more than one valid modal, render it all as modal content
  if (filteredModals.length > 1) {
    modal = filteredModals.reduce(
      (acc, val) => {
        const { title, content, onModalAccept, formikValuesToSet } = val
        return {
          title,
          content: `${acc.content}<h3>${title}</h3>${content}`,
          onModalAccept,
          formikValuesToSet
        }
      },
      { title: "", content: "" }
    )
  }

  return (
    <Modal
      onClose={() => setSubmitModal(false)}
      name="submit_modal"
      title={modal.title}
      hasHeader={!!modal.title}
    >
      {typeof modal.content === "string" ? <RenderHTML content={modal.content} /> : modal.content}
      <ButtonsWrapper>
        <Button
          trailingIcon="close"
          appearance="errorGhost"
          name="modal_cancel"
          onClick={() => setSubmitModal(false)}
        >
          Cancel
        </Button>
        <Button
          trailingIcon="check"
          appearance="success"
          name="modal_save"
          onClick={() => {
            if (modal.onModalAccept) {
              if (modal.onModalAccept === "setFormikValues") {
                formik.setFieldValue(modal.formikValuesToSet[0], modal.formikValuesToSet[1])
              }
            }
            onPageSubmit()
            setSubmitModal(false)
          }}
          isDisabled={isLocked}
        >
          Save and Continue
        </Button>
      </ButtonsWrapper>
    </Modal>
  )
}

export default SubmitModal
