import React from "react"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"

// Components
import OrganisationView from "./organisations.panel.view"

const OrganisationsPanel = () => (
  <FlyOutPanel body={() => <OrganisationView />} name="organisations_panel" />
)

export default OrganisationsPanel
