import React from "react"

// Components
import AgencyCodesPanelHeader from "./networkAgencyCodes.panel.header"
import AgencyCodesPanelBody from "./networkAgencyCodes.panel.body"

const ViewAgencyCode = () => (
  <>
    <AgencyCodesPanelHeader context="open" />
    <AgencyCodesPanelBody />
  </>
)

export default ViewAgencyCode
