import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { v4 as uuid } from "uuid"
import { Icon, IconWithText } from "@4cplatform/elements/Atoms"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"

// Components
import {
  InviteOrganisationWrapper,
  InvitesListWrapper,
  InviteOrganisationBorder,
  Close
} from "./networkInvitations.styles"

const InvitationsList = ({ invitations, removeInvitation }) => {
  if (isEmpty(invitations)) return null

  return (
    <InvitesListWrapper>
      {invitations.map(invite => {
        const { email_address: email } = invite
        const uid = uuid()

        if (email)
          return (
            <InviteOrganisationWrapper key={uid}>
              <InviteOrganisationBorder hasBorder>
                <IconWithText margin="0" icon="email" content={email} iconColour={colours.blue} />

                <Close
                  margin="0"
                  top="1rem"
                  theme="inverse"
                  onClick={() => removeInvitation(invite)}
                  data-testid={`${uid}-modal-close`}
                >
                  <Icon icon="close" colour={colours.red} />
                </Close>
              </InviteOrganisationBorder>
            </InviteOrganisationWrapper>
          )
        return null
      })}
    </InvitesListWrapper>
  )
}

InvitationsList.defaultProps = {
  invitations: [],
  removeInvitation: () => {}
}

InvitationsList.propTypes = {
  invitations: PropTypes.array,
  removeInvitation: PropTypes.func
}

export default InvitationsList
