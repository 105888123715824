import styled, { css } from "styled-components"
import { get } from "lodash"
import { ErrorMessage } from "@4cplatform/elements/Forms"
import { Button as ElementsButton } from "@4cplatform/elements/Molecules"

const stylePartial = css`
  width: 100%;
  background-color: ${({ theme }) => get(theme, "white", "white")};
  padding: 3rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
`

export const Form = styled.form`
  ${stylePartial}
  gap: 1.5rem;
`

export const SuccessMessage = styled.div`
  ${stylePartial}
  align-items: center;
`

export const SuccessInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled(ElementsButton)`
  justify-content: center;
`

export const RecaptchaErrorMessage = styled(ErrorMessage)`
  margin: -1.5rem 0 0;
`
