/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { get } from "lodash"
import { Button } from "@4cplatform/elements/Molecules"
import { usePost } from "@4cplatform/elements/Api"
import { addAlert } from "@4cplatform/elements/Alerts"
import { JourneyContext } from "../../../../../../../journey.context"

function getButtonText(isDocumentSent, isDisabled) {
  if (isDocumentSent) return "New document notification sent to client"
  if (isDisabled) return "Sent client the document notification successfully"
  return "Send New document notification to client"
}

const SendDocumentButton = ({ apiEndpointPath, isDocumentSent }) => {
  const { reference } = useParams()
  const { data, onPageSubmit, setCustomPageSubmit } = useContext(JourneyContext)
  const [isButtonDisabled, setButtonDisabled] = useState(isDocumentSent)

  const isLocked = get(data, "journey.locked", false)

  useEffect(() => {
    if (isDocumentSent) {
      setCustomPageSubmit(null)
      return
    }

    const submitJourney = () => {
      if (isButtonDisabled) {
        onPageSubmit()
      } else {
        addAlert({
          message: "New document notification must be sent to client in order to proceed.",
          type: "error",
          dismissible: true,
          timeout: 3
        })
      }
    }

    setCustomPageSubmit(submitJourney)

    return () => {
      setCustomPageSubmit(null)
    }
  }, [isDocumentSent, isButtonDisabled]) // eslint-disable-line react-hooks/exhaustive-deps

  const [submit, { loading }] = usePost({
    endpoint: `/journeys/:reference/${apiEndpointPath || "notify-client"}`,
    params: {
      reference
    },
    body: {
      document_notification_sent: true
    },
    onCompleted: () => {
      setButtonDisabled(true)
    },
    onError: error => {
      if (error.message === "QUOTE_NOT_PUSHED_TO_PORTAL") {
        addAlert({
          message: "Return to Quotation Summary to push the quote to the client portal first",
          type: "error",
          dismissible: true,
          timeout: 6
        })
      }
    }
  })

  return (
    <Button
      width="fit-content"
      trailingIcon="comment-check-outline"
      isLoading={loading}
      isDisabled={isButtonDisabled || loading || isLocked}
      onClick={() => submit()}
    >
      {getButtonText(isDocumentSent, isButtonDisabled)}
    </Button>
  )
}

SendDocumentButton.defaultProps = {
  isDocumentSent: false
}

SendDocumentButton.propTypes = {
  apiEndpointPath: PropTypes.string,
  isDocumentSent: PropTypes.bool
}

export default SendDocumentButton
