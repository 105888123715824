import React from "react"

// Components
import { RoundedButton, RoundedButtonsWrapper } from "../../clients.styles"
import People from "../../../PolicyAdmin/policies.panel.body.people"
import Disclosures from "../../../PolicyAdmin/policies.panel.body.disclosures"
import Documents from "../../../PolicyAdmin/policies.panel.body.documents"
import { PolicyDetailWrapper, PolicyHeaderWrapper, PolicyWrapper } from "./policy.styles"
import Exclusions from "../../../PolicyAdmin/policies.panel.body.exclusions"

const PolicySummaryButtons = () => {
  const [activeTab, setActiveTab] = React.useState("People")

  const policiesDetail = () => {
    switch (activeTab) {
      case "People": {
        return <People />
      }
      case "Exclusions": {
        return <Exclusions />
      }
      case "Disclosures": {
        return <Disclosures />
      }
      case "Documentation": {
        return <Documents />
      }
      default:
        return <></>
    }
  }
  return (
    <PolicyWrapper>
      <PolicyHeaderWrapper>
        <RoundedButtonsWrapper margin="0">
          <RoundedButton
            header="All"
            appearance="whiteGhost"
            onClick={() => {
              setActiveTab("People")
            }}
            className={activeTab === "People" && "active"}
          >
            People
          </RoundedButton>
          <RoundedButton
            appearance="whiteGhost"
            onClick={() => {
              setActiveTab("Documentation")
            }}
            className={activeTab === "Documentation" && "active"}
          >
            Documentation
          </RoundedButton>
          <RoundedButton
            appearance="whiteGhost"
            onClick={() => {
              setActiveTab("Disclosures")
            }}
            className={activeTab === "Disclosures" && "active"}
          >
            Disclosures
          </RoundedButton>
          <RoundedButton
            appearance="whiteGhost"
            onClick={() => {
              setActiveTab("Exclusions")
            }}
            className={activeTab === "Exclusions" && "active"}
          >
            Exclusions
          </RoundedButton>
        </RoundedButtonsWrapper>
      </PolicyHeaderWrapper>
      <PolicyDetailWrapper>{policiesDetail()}</PolicyDetailWrapper>
    </PolicyWrapper>
  )
}

export default PolicySummaryButtons
