export const defaultBankDetails = {
  name_on_account: "",
  account_number: "",
  sort_code: "",
  bank_address: {
    name: "",
    line_one: "",
    line_two: "",
    city: "",
    postcode: ""
  }
}

export const defaultBankAddress = {
  name: "",
  line_one: "",
  line_two: "",
  city: "",
  postcode: ""
}
