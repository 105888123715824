import React, { useState } from "react"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { P, H3, H4 } from "@4cplatform/elements/Typography"

// Components
import ApplicantsTable from "./applicants.table"
import Actions from "./applicants.actions"
import IncludedTable from "./applicants.included"

const Body = () => {
  const [isLastCheckbox, setLastCheckbox] = useState(false)

  return (
    <>
      <ComplianceNote type="error">
        <H4 margin="0 0 1rem">Compliance note</H4>
        <P margin="0">
          You need to confirm with the client that you have permission to add the additional
          member(s) and they also have permission to discuss the quote on their behalf.
        </P>
      </ComplianceNote>
      {isLastCheckbox && (
        <ComplianceNote type="error">
          <H4 margin="0 0 1rem">Error</H4>
          <P margin="0">At least 1 applicant must be included in the quote.</P>
        </ComplianceNote>
      )}
      <H3 margin="0 0 2rem">Collect details of all persons to be quoted</H3>
      <ApplicantsTable
        onCheckboxChange={isLastCheckboxChecked => setLastCheckbox(isLastCheckboxChecked)}
      />
      <Actions />
      <H3>Applicants to be quoted</H3>
      <IncludedTable />
    </>
  )
}

export default Body
