import React, { useState, useEffect, useContext } from "react"
import moment from "moment"
import { useGet } from "@4cplatform/elements/Api"
import { string, object, boolean, array } from "yup"
import { get, isEmpty, find } from "lodash"
import { isValidPhoneNumber } from "react-phone-number-input"
import { renderTitleOptions } from "@4cplatform/elements/Helpers/forms"
import {
  Input,
  Toggle,
  Address,
  DatePicker,
  Select,
  QuerySelect,
  PhoneNumbers
} from "@4cplatform/elements/Forms"
import { H3, P } from "@4cplatform/elements/Typography"
import { ConfigContext } from "@4cplatform/elements/Config"
import { JourneyContext } from "../../../../../.."
import { validNameRegex, validPostcodeRegex } from "../../../../../../../../Helpers"

const TitleOptions = () => {
  const { LOADING_TITLES, GLOBAL_TITLES } = useContext(ConfigContext)
  const { formik, updateJourneyAuditData } = useContext(JourneyContext)
  const formikValue = get(formik, "values", "")
  const dateOfBirth = get(formikValue, "client.date_of_birth", "")
  const title = get(formikValue, "client.title", "")
  const occupation = get(formikValue, "client.occupation", "")
  const address = get(formikValue, "address", null)
  const phoneNumbers = get(formikValue, "phone_numbers", [])
  const [occupationsObj, setOccupationsObj] = useState(null)
  const occupationLabel = get(occupationsObj, `${occupation}`, "")
  useGet({
    endpoint: "/occupations",
    onCompleted: res => {
      const newData = get(res, "data", [])
      setOccupationsObj(newData)
    }
  })
  useEffect(() => {
    const customAuditData = []
    const auditInsertData = []

    if (dateOfBirth) {
      customAuditData.push({
        name: "Date of birth",
        value: moment.utc(dateOfBirth).format("DD/MM/YYYY")
      })
    }
    if (title) {
      customAuditData.push({
        name: "Title",
        value: get(GLOBAL_TITLES?.data, `${title}.title`, title)
      })
    }
    if (occupationLabel) {
      customAuditData.push({
        name: "Occupation",
        value: occupationLabel
      })
    }
    if (phoneNumbers.length) {
      phoneNumbers.forEach(phoneNumber => {
        auditInsertData.push({
          name: `${get(
            {
              PRIMARY: "Primary",
              MOBILE: "Mobile",
              HOME: "Home",
              WORK: "Work",
              RECEPTION: "Reception",
              OTHER: "Other"
            },
            `${get(phoneNumber, "type")}`,
            "Primary"
          )} number`,
          value: get(phoneNumber, "number")
        })
      })
    }

    updateJourneyAuditData([
      {
        mode: "custom",
        data: customAuditData
      },
      ...(!isEmpty(address)
        ? [
            {
              mode: "insert",
              name: "address",
              deleteCount: 1,
              data: [
                {
                  name: "Address",
                  value: [
                    get(address, "line_one", ""),
                    get(address, "line_two", ""),
                    get(address, "city", ""),
                    get(address, "county", ""),
                    get(address, "postcode", "")
                  ]
                    .filter(adr => !!adr)
                    .join(", ")
                }
              ]
            }
          ]
        : []),
      {
        mode: "insert",
        name: "Phone numbers",
        deleteCount: 1,
        data: auditInsertData
      }
    ])

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfBirth, formikValue])

  return (
    <>
      {LOADING_TITLES ? (
        <option value="">Loading titles</option>
      ) : (
        <option value="">Select title</option>
      )}
      {renderTitleOptions(GLOBAL_TITLES?.data, formik, "client.gender_at_birth")}
    </>
  )
}

export const config = data => {
  const applicants = get(data, "journey.applicants")
  const client = get(data, "journey.client")
  const primaryApplicant = find(applicants, { type: "primary" })
  return {
    title: "Client details",
    sections: [
      {
        key: "client_details",
        components: [
          {
            key: "client.email_address",
            initialValue: get(primaryApplicant, "email_address", get(client, "email_address", "")),
            validationSchema: string()
              .email("INVALID_EMAIL_ADDRESS")
              .required("MISSING_REQUIRED_FIELD_WITH_PREFIX_EMAIL"),
            label: "Email address",
            component: Input,
            componentProps: {
              type: "email",
              isHorizontal: true,
              isRequired: true,
              margin: "0 0 2rem",
              errorPrefix: "none"
            }
          },
          {
            key: "client.first_name",
            initialValue: get(primaryApplicant, "first_name", get(client, "first_name", "")),
            validationSchema: string()
              .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
              .required("MISSING_REQUIRED_FIELD"),
            label: "First name",
            component: Input,
            componentProps: {
              isHorizontal: true,
              isRequired: true,
              margin: "0 0 2rem"
            }
          },
          {
            key: "client.middle_names",
            initialValue: get(primaryApplicant, "middle_names", get(client, "middle_names", "")),
            validationSchema: string().nullable(),
            label: "Middle name(s)",
            component: Input,
            componentProps: {
              isHorizontal: true,
              margin: "0 0 2rem"
            }
          },
          {
            key: "client.last_name",
            initialValue: get(primaryApplicant, "last_name", get(client, "last_name", "")),
            validationSchema: string().required("MISSING_REQUIRED_FIELD"),
            label: "Last name",
            component: Input,
            componentProps: {
              isHorizontal: true,
              isRequired: true,
              margin: "0 0 2rem"
            }
          },
          {
            key: "client.date_of_birth",
            initialValue: get(primaryApplicant, "date_of_birth", get(client, "date_of_birth", "")),
            validationSchema: string().required("MISSING_REQUIRED_FIELD"),
            label: "Date of birth",
            component: DatePicker,
            componentProps: {
              value: get(primaryApplicant, "date_of_birth", get(client, "date_of_birth", "")),
              isDOBInput: true,
              dateRangeMax: moment().subtract(18, "years").format("DD/MM/YYYY"),
              isHorizontal: true,
              isRequired: true,
              margin: "0 0 1rem"
            }
          },
          {
            key: "client.gender_at_birth",
            initialValue: get(
              primaryApplicant,
              "gender_at_birth",
              get(client, "gender_at_birth", "")
            ),
            validationSchema: string().required("MISSING_REQUIRED_FIELD"),
            label: "Gender at birth",
            component: Select,
            componentProps: {
              isHorizontal: true,
              isRequired: true,
              margin: "0 0 2rem",
              options: [
                { order: 0, label: "Male", value: "male" },
                { order: 1, label: "Female", value: "female" }
              ]
            }
          },
          {
            key: "client.title",
            initialValue: get(primaryApplicant, "title", get(client, "title", "")),
            validationSchema: string().required("MISSING_REQUIRED_FIELD"),
            label: "Title",
            component: Select,
            componentProps: {
              isHorizontal: true,
              isRequired: true,
              margin: "0 0 2rem",
              children: <TitleOptions />
            }
          },
          {
            key: "client.occupation",
            initialValue: get(primaryApplicant, "occupation", get(client, "occupation", "")),
            validationSchema: string().required("MISSING_REQUIRED_FIELD"),
            label: "Occupation",
            component: QuerySelect,
            componentProps: {
              isHorizontal: true,
              isRequired: true,
              helperTitle: "Occupation",
              helperText: (
                <P>
                  Please note that some insurers apply a discount to the premium should the client
                  disclose that they fall under one of the selected occupations. Your client may be
                  asked to provide evidence of their occupation in order to be eligible for any
                  discounts applied. If your client is unable or unwilling to provide evidence of
                  their occupation, please mark their occupation as <strong>Other</strong>.
                </P>
              ),
              margin: "0 0 2rem",
              noun: { singular: "occupation", plural: "occupations" },
              endpoint: "/occupations",
              render: jobs => {
                const keys = Object.keys(jobs)

                return keys.map(key => (
                  <option key={key} value={key}>
                    {jobs[key]}
                  </option>
                ))
              }
            }
          },
          {
            key: "phone_numbers",
            initialValue:
              get(primaryApplicant, "phone_numbers", []).length > 0
                ? get(primaryApplicant, "phone_numbers")
                : get(client, "phone_numbers", [{ type: "PRIMARY", number: "" }]),
            validationSchema: array(
              object({
                type: string().required("MISSING_REQUIRED_FIELD"),
                number: string()
                  .test("Phone invalid", "INVALID_UK_PHONE", val => {
                    if (!val) return false
                    return isValidPhoneNumber(val, "GB")
                  })
                  .required("MISSING_REQUIRED_FIELD")
              })
            )
              .required("MIN_LENGTH_NOT_MET")
              .min(1),
            label: "Phone numbers",
            component: PhoneNumbers,
            componentProps: {
              isHorizontal: true,
              margin: "0 0 2rem",
              isRequired: true
            }
          }
        ]
      },
      {
        key: "address",
        title: "Address",
        components: [
          {
            key: "address",
            initialValue: get(
              primaryApplicant,
              "address",
              get(client, "address", {
                postcode: "",
                line_one: "",
                line_two: "",
                city: "",
                county: ""
              })
            ),
            validationSchema: object({
              postcode: string()
                .test("correctUkPostcode", "CORRECT_UK_POSTCODE_PREFIX", no =>
                  validPostcodeRegex.test(no)
                )
                .test("missingAddressField", "MISSING_POSTCODE_ADDRESS", (no, { parent }) => {
                  // eslint-disable-next-line camelcase
                  const { line_one, city, county } = parent
                  // eslint-disable-next-line camelcase
                  return line_one && city && county
                })
                .required("MISSING_REQUIRED_FIELD"),
              line_one: string().required(),
              line_two: string().nullable(),
              city: string().required(),
              county: string().required()
            }),
            labels: {
              type: "Type",
              postcode: "Postcode",
              line_one: "Line 1",
              line_two: "Line 2",
              city: "City",
              county: "County"
            },
            component: Address,
            componentProps: {
              errorPrefix: "none",
              isHorizontal: true,
              labelWidth: "30rem",
              margin: "0 0 2rem"
            }
          }
        ]
      },
      {
        key: "questions",
        title: "Other",
        components: [
          {
            key: "questions.permanent_uk_resident",
            initialValue: get(data, "page.data.permanent_uk_resident", ""),
            validationSchema: boolean()
              .oneOf(
                [true],
                "One or more client responses indicate that a compliant sale may not be possible. It is not possible to continue the client journey based upon the current responses."
              )
              .required("MISSING_REQUIRED_QUESTION_FIELD"),
            label: "Is the client a permanent UK resident?",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              options: [
                {
                  order: 0,
                  label: "No",
                  value: false
                },
                {
                  order: 1,
                  label: "Yes",
                  value: true
                }
              ],
              isHorizontal: true,
              labelWidth: "39.5rem",
              margin: "0 0 2rem",
              isRequired: true
            }
          },
          {
            key: "questions.covered_with_a_gp_and_access_to_medical_records",
            initialValue: get(
              data,
              "page.data.covered_with_a_gp_and_access_to_medical_records",
              ""
            ),
            validationSchema: boolean().required("MISSING_REQUIRED_QUESTION_FIELD"),
            label:
              "Is the person to be covered registered with a GP and do they have access to their medical records in English?",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "VitalityHealth",
              helperText: get(data, "page.conditionals.has_access_to_vitality_agency_codes", true)
                ? "<p>GP/DENTIST REGISTRATION<br /> All members must have been resident in the UK and registered with a UK GP for a minimum of six months prior to the cover start date. If this is not in place, there may be a delay in getting authorisation for an eligible claim, or in some circumstances it may lead to the claim being declined</p>"
                : "",
              options: [
                {
                  order: 0,
                  label: "No",
                  value: false
                },
                {
                  order: 1,
                  label: "Yes",
                  value: true
                }
              ],
              isHorizontal: true,
              labelWidth: "auto",
              margin: "0 0 2rem",
              isRequired: true
            }
          },
          {
            key: "questions.pmi_required_to_fulfil_reqs_or_visa",
            initialValue: get(data, "page.data.pmi_required_to_fulfil_reqs_or_visa", ""),
            validationSchema: boolean().required("MISSING_REQUIRED_QUESTION_FIELD"),
            label:
              "Is PMI required in order to fulfil home office requirements and/or a visa application?",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              options: [
                {
                  order: 0,
                  label: "No",
                  value: false
                },
                {
                  order: 1,
                  label: "Yes",
                  value: true
                }
              ],
              isHorizontal: true,
              labelWidth: "auto",
              margin: "0 0 2rem",
              isRequired: true
            }
          },
          {
            key: "questions.tobacco_products_within_last_2_years",
            initialValue: get(data, "page.data.tobacco_products_within_last_2_years", ""),
            validationSchema: boolean().required("MISSING_REQUIRED_QUESTION_FIELD"),
            label: "Has the client used any tobacco products within the last 2 years?",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              options: [
                {
                  order: 0,
                  label: "No",
                  value: false
                },
                {
                  order: 1,
                  label: "Yes",
                  value: true
                }
              ],
              isHorizontal: true,
              labelWidth: "auto",
              margin: "0 0 2rem",
              isRequired: true
            }
          },
          {
            key: "questions.payment_for_participating_in_sport",
            initialValue: get(data, "page.data.payment_for_participating_in_sport", ""),
            validationSchema: boolean().required("MISSING_REQUIRED_QUESTION_FIELD"),
            label: "Do any of the applicants receive payment for participating in sport?",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              options: [
                {
                  order: 0,
                  label: "No",
                  value: false
                },
                {
                  order: 1,
                  label: "Yes",
                  value: true
                }
              ],
              isHorizontal: true,
              labelWidth: "auto",
              margin: "0 0 2rem",
              isRequired: true
            },
            modal: {
              fieldValueTrigger: !get(data, "page.data.payment_for_participating_in_sport", false),
              title: "Sport-related Injury",
              content: (
                <>
                  <P>
                    Please ensure that your client is aware that no insurer will provide cover for
                    any injury that occurs directly from or as a result of participating in a paid
                    or funded sport.
                  </P>
                  <P>
                    In some cases, this is an eligibility criteria question and the insurer will
                    look to decline cover for any applicants who can answer YES to this question.
                  </P>
                  <H3>Insurer's Stance</H3>
                  <P>
                    Bupa will not provide cover for a professional sports person, or a
                    semi-professional sports persion who follows a sport occupation as a secondary
                    means of livelihood.
                  </P>
                </>
              ),
              confirmation: true,
              onCancelConfirmationSetValue: ""
            }
          }
        ]
      }
    ]
  }
}
