import React from "react"
import { get } from "lodash"
import { P } from "@4cplatform/elements/Typography"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { AgencyCodesContext } from "../../agencyCodes.context"

const ActivateAgencyCode = () => {
  const { viewData, setActivateModal, onActivate, activateLoading } =
    React.useContext(AgencyCodesContext)

  return (
    <>
      <ConfirmationModal
        title="Activate Agency Code"
        confirmText="Activate"
        confirmAppearance="success"
        confirmIcon="check"
        cancelIcon="close"
        cancelAppearance="errorGhost"
        isLoadingConfirm={activateLoading}
        onClose={() => {
          setActivateModal(false)
        }}
        onConfirm={onActivate}
        onCancel={() => {
          setActivateModal(false)
        }}
      >
        <P>
          Are you sure you want to activate Agency Code{" "}
          <strong>{get(viewData, "agency_code")}</strong> for{" "}
          <strong>
            {get(viewData, "owner.type") === "NETWORK" ||
            get(viewData, "owner.type") === "ORGANISATION"
              ? get(viewData, "owner.attributes.name", "-")
              : `${get(viewData, "owner.attributes.first_name", "-")} ${get(
                  viewData,
                  "owner.attributes.middle_names",
                  "-"
                )} ${get(viewData, "owner.attributes.last_name", "-")}`}
          </strong>{" "}
          ?
        </P>
      </ConfirmationModal>
    </>
  )
}

export default ActivateAgencyCode
