// Helpers
import { sortQuotesByPremium } from "../quotationSummary.helpers"

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_VALUE": {
      return {
        ...state,
        [action.key]: action.value
      }
    }
    case "ADD_QUOTE": {
      const newQuotesState = [...state[action.key], action.value]

      return {
        ...state,
        [action.key]: sortQuotesByPremium(newQuotesState)
      }
    }
    case "DELETE_QUOTE": {
      const quotes = state[action.key].filter(quote => quote.id !== action.value)

      return {
        ...state,
        [action.key]: quotes
      }
    }
    case "UPDATE_QUOTE_LOADING_STATE": {
      if (state[action.key].find(item => item.quoteId === action.value?.quoteId)) {
        return {
          ...state,
          [action.key]: state[action.key].map(item => {
            if (item.quoteId === action.value?.quoteId) item.loading = action.value?.loading
            return item
          })
        }
      }
      return {
        ...state,
        [action.key]: [...state[action.key], action.value]
      }
    }
    case "UPDATE_QUOTE": {
      const newState = state[action.key].map(item => {
        if (item.id === action.value?.quoteId) return { ...item, ...action.value?.data }
        return item
      })

      return {
        ...state,
        [action.key]: sortQuotesByPremium(newState)
      }
    }
    case "UPDATE_QUOTE_SENT_TO_CLIENT_STATE": {
      const quote = state[action.key].find(item => item.id === action.value?.quoteId)

      if (quote) quote.sent_to_client = action.value?.sentToClient

      return {
        ...state,
        [action.key]: state[action.key]
      }
    }
    case "RESET_EDIT_QUOTE_FORM": {
      return {
        ...state,
        availableQuoteOptions: null,
        selectedEditQuote: null,
        selectedEditQuoteMembers: [],
        fetchedQuote: null,
        showNoChildOnlyPolicyError: null,
        showChildOnlyPolicyMessage: null,
        showDependantOnlyPolicyMessage: null,
        showPartnerPolicyMessage: null,
        showNoApplicantsError: null
      }
    }
    default:
      return state
  }
}

export default reducer
