import React from "react"
import { useFormik } from "formik"
import { get, isEmpty } from "lodash"
import { usePost } from "@4cplatform/elements/Api"
import { Button, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { Select } from "@4cplatform/elements/Forms"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { assignDealCodeModel as validationSchema } from "./assignment.helpers"
import { AgencyCodesContext } from "../../agencyCodes.context"

const AssignDealCode = () => {
  const {
    availableDealCodes,
    isAllLoading,
    selectedAgencyCode,
    refetchAssignedDealCodes,
    refetchAvailableDealCodes,
    setViewDeal
  } = React.useContext(AgencyCodesContext)
  const t = useTranslations()
  const [open, setOpen] = React.useState(false)

  // Assign deal code mutation
  const [assignDealCode, { loading: assignDealCodeLoading }] = usePost({
    endpoint: "/agency-codes/:slug/deal-codes/:deal/assign",
    onCompleted: () => {
      setOpen(false)
      addAlert({
        message: t("DEAL_CODE_ASSIGN_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      refetchAssignedDealCodes()
      refetchAvailableDealCodes()
      setViewDeal({ isOpen: false, slug: null, data: null })
    },
    onError: () => {
      addAlert({
        message: t("DEAL_CODE_ASSIGN_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const assignDealCodeFormik = useFormik({
    initialValues: {
      deal_code_slug: ""
    },
    validationSchema,
    onSubmit: body => {
      const deal = get(body, "deal_code_slug", "")
      assignDealCode({ params: { deal, slug: get(selectedAgencyCode, "slug", "") } })
    }
  })

  const { handleSubmit } = assignDealCodeFormik
  const formik = { ...assignDealCodeFormik, validationSchema }

  return (
    <>
      <Button
        appearance="whiteGhost"
        onClick={() => setOpen(true)}
        name="assign"
        margin="2rem 0 0 0"
        trailingIcon="plus"
        isDisabled={isAllLoading}
      >
        Assign new deal code
      </Button>

      {open && (
        <ConfirmationModal
          title="Assign Deal Code"
          confirmText="Assign"
          confirmAppearance="success"
          confirmIcon="check"
          cancelIcon="close"
          cancelText="Cancel"
          cancelAppearance="error"
          isLoadingConfirm={assignDealCodeLoading}
          onClose={() => isAllLoading || setOpen(false)}
          onConfirm={handleSubmit}
          onCancel={() => isAllLoading || setOpen(false)}
          isDisabledConfirm={isEmpty(availableDealCodes)}
        >
          <Select
            label="Deal Code"
            name="deal_code_slug"
            formik={formik}
            margin="0 0 2rem"
            isDisabled={isEmpty(availableDealCodes)}
          >
            <option value="">
              {isEmpty(availableDealCodes) ? "No deal codes available" : "Select deal code"}
            </option>
            {Array.isArray(availableDealCodes) &&
              availableDealCodes.map(code => (
                <option value={get(code, "slug")} key={get(code, "id")}>
                  {get(code, "name", "-")}
                </option>
              ))}
          </Select>
        </ConfirmationModal>
      )}
    </>
  )
}

export default AssignDealCode
