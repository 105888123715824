import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Input } from "@4cplatform/elements/Forms"
import { H3 } from "@4cplatform/elements/Typography"

// Helpers
import { BankDetailsContext } from "./context/bankDetails.context"

const BankAddressFields = ({ labelWidth, isHorizontal, appearance, isDisabled, apiErrors }) => {
  const { display, formik, sortcodeLookupLoading } = useContext(BankDetailsContext)

  // Do not display fields unless component is in a Filled In state
  if (display !== "filled_in") return null

  const handlePostcodeBlur = e => {
    const postcode = e?.target?.value || ""
    let searchVal = postcode.trim().replace(/\s\s+/g, " ").toUpperCase()
    // Check for white space
    if (searchVal.length > 3 && !/\s/.test(searchVal)) {
      searchVal = [...searchVal] // convert to array of characters
      searchVal.splice(searchVal.length - 3, 0, " ")
      searchVal = searchVal.join("") // convert back to string

      formik.setFieldValue("bank_address.postcode", searchVal)
    }
  }

  return (
    <>
      <H3>Bank Address</H3>
      <Input
        label="Bank name"
        isHorizontal={isHorizontal}
        labelWidth={labelWidth}
        formik={formik}
        name="bank_address.name"
        appearance={appearance}
        isDisabled={isDisabled || sortcodeLookupLoading}
        apiErrors={apiErrors}
        margin="2rem 0"
        isRequired
      />
      <Input
        label="Line 1"
        isHorizontal={isHorizontal}
        labelWidth={labelWidth}
        formik={formik}
        name="bank_address.line_one"
        appearance={appearance}
        isDisabled={isDisabled || sortcodeLookupLoading}
        apiErrors={apiErrors}
        margin="2rem 0"
        isRequired
      />
      <Input
        label="Line 2"
        isHorizontal={isHorizontal}
        labelWidth={labelWidth}
        formik={formik}
        name="bank_address.line_two"
        appearance={appearance}
        isDisabled={isDisabled || sortcodeLookupLoading}
        apiErrors={apiErrors}
        margin="2rem 0"
      />
      <Input
        label="City"
        isHorizontal={isHorizontal}
        labelWidth={labelWidth}
        formik={formik}
        name="bank_address.city"
        appearance={appearance}
        isDisabled={isDisabled || sortcodeLookupLoading}
        apiErrors={apiErrors}
        margin="2rem 0"
        isRequired
      />
      <Input
        label="Postcode"
        isHorizontal={isHorizontal}
        labelWidth={labelWidth}
        formik={formik}
        onBlur={handlePostcodeBlur}
        name="bank_address.postcode"
        appearance={appearance}
        isDisabled={isDisabled || sortcodeLookupLoading}
        apiErrors={apiErrors}
        margin="2rem 0"
        isRequired
      />
    </>
  )
}

BankAddressFields.defaultProps = {
  labelWidth: "18rem",
  isHorizontal: false,
  appearance: "dark",
  isDisabled: false,
  apiErrors: {}
}

BankAddressFields.propTypes = {
  /**
   * Width of the label element while the component is in Horizontal mode
   */
  labelWidth: PropTypes.string,
  /**
   * Display the component in Horizontal mode
   */
  isHorizontal: PropTypes.bool,
  /**
   * The light/dark appearance of the label text
   */
  appearance: PropTypes.oneOf(["dark", "light"]),
  /**
   * This controls the disabled state of the Select
   */
  isDisabled: PropTypes.bool,
  /**
   * API errors
   */
  apiErrors: PropTypes.object
}

export default BankAddressFields
