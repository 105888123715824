import React, { useContext } from "react"
import { get } from "lodash"
import { PageContext } from "@4cplatform/elements/Organisms"
import { AuthContext } from "@4cplatform/elements/Auth"

// Components
import { Tabs, Tab } from "../../../../UI/Organisms"
import OrganisationAgencyCodes from "./pages/OrganisationAgencyCodes"
import { Codes, SharedAgencyCodes } from "../../../MyAccount/pages/AgencyCodes/pages"

const AgencyCodes = () => {
  const { selfServiceData: user } = useContext(PageContext)
  const { canAccess } = useContext(AuthContext)

  return (
    <Tabs hasQueryControls type="table" name="agency_codes_type">
      <Tab header="Agency codes">
        <OrganisationAgencyCodes type="default" />
      </Tab>
      <Tab header="My Agency codes" isPresent={canAccess(["ORG_ADMIN"])}>
        <Codes />
      </Tab>
      <Tab header="Shared with me" isPresent={canAccess(["ORG_ADMIN"])}>
        <SharedAgencyCodes />
      </Tab>
      <Tab
        header="Shared from network"
        isPresent={get(user, "parent.parent.type", "") === "NETWORK"}
      >
        <OrganisationAgencyCodes type="shared_from_network" />
      </Tab>
      <Tab header="Shared with users">
        <OrganisationAgencyCodes type="shared_with_users" />
      </Tab>
      <Tab header="Assigned to users">
        <OrganisationAgencyCodes type="assigned_to_users" />
      </Tab>
    </Tabs>
  )
}

export default AgencyCodes
