import React, { useContext } from "react"
import { useFormik } from "formik"
import { get } from "lodash"
import { object, string } from "yup"
import { TextArea } from "@4cplatform/elements/Forms"
import { Button, ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { PoliciesContext } from "../../policies.context"

const RequestMoreInfo = () => {
  const {
    viewLoading,
    requestMoreInfoModal,
    setRequestMoreInfoModal,
    onRequestMoreInfo,
    requestMoreInfoLoading,
    submitJourneyAudit
  } = useContext(PoliciesContext)

  const validationSchema = object({
    more_info_required: string().required("MISSING_REQUIRED_FIELD").nullable()
  })

  const requestMoreInfoFormik = useFormik({
    initialValues: {
      more_info_required: ""
    },
    validationSchema,
    onSubmit: (body, { resetForm }) => {
      onRequestMoreInfo({
        body
      })
      submitJourneyAudit({
        page: "Submit to provider",
        responses: [
          {
            name: "Response",
            value: "Requested more information"
          },
          {
            name: "Submit failure reason",
            value: get(body, "more_info_required")
          }
        ]
      })
      resetForm()
    }
  })
  const formik = { ...requestMoreInfoFormik, validationSchema }
  const { handleSubmit } = formik

  return (
    <>
      <Button
        onClick={() => setRequestMoreInfoModal(true)}
        isLoading={viewLoading || requestMoreInfoLoading}
        trailingIcon="information-outline"
        appearance="warning"
        margin="1rem 0"
        name="request_more_info"
      >
        Request information
      </Button>
      {requestMoreInfoModal && (
        <ConfirmationModal
          onConfirm={handleSubmit}
          onClose={() => setRequestMoreInfoModal(false)}
          confirmAppearance="success"
          isLoadingConfirm={requestMoreInfoLoading}
          confirmText="OK"
          title="Request information"
        >
          <TextArea name="more_info_required" formik={formik} label="Note" margin="0 0 2rem" />
        </ConfirmationModal>
      )}
    </>
  )
}

export default RequestMoreInfo
