import styled from "styled-components"
import { StyledCheckbox } from "@4cplatform/elements/Forms/Checkbox/checkbox.styles"

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 4rem;
  justify-content: space-between;
`

export const ModalBodyWrapper = styled.div`
  padding-top: 2rem;

  & ${StyledCheckbox} {
    width: 2rem;
    height: 2rem;
  }
`
