import React, { useContext, useEffect, useCallback, useState, useMemo } from "react"
import { useFormik } from "formik"
import { cloneDeep, get, uniqBy } from "lodash"
import { useGet } from "@4cplatform/elements/Api"
import { H3, P } from "@4cplatform/elements/Typography"
import { PageContext } from "@4cplatform/elements/Organisms"
import { Checkbox, Input, Select } from "@4cplatform/elements/Forms"
import { AuthWrapper, AuthContext } from "@4cplatform/elements/Auth"
import { FlyOutPanel, PanelHeader, PanelBody, Button } from "@4cplatform/elements/Molecules"
import { MiReportContext } from "./miReports.context"
import { FlexAlignCenter, FlexColumnLargeGap, Divider } from "./miReports.style"
import FilterItem from "./miReports.panel.filterItem"
import Radios from "./miReports.radios"
import { getName } from "../../Helpers"
import { miReportValidationSchema, REPORT_CONSTANTS } from "./miReports.helpers"

const allowedRolesOfOrganisationAndUser = [
  "SYS_ADMIN",
  "SUPPORT_ADMIN",
  "PROVIDER_ADMIN",
  "UNDERWRITER",
  "NETWORK_ADMIN",
  "NETWORK_MEMBER_ADMIN"
]

const allowedRolesOfProvider = [
  "SYS_ADMIN",
  "SUPPORT_ADMIN",
  "ORG_ADMIN",
  "SALES_ADVISER",
  "NETWORK_ADMIN",
  "NETWORK_MEMBER_ADMIN"
]

const locationKeys = ["County", "Region", "Postcode"]

const { TABS } = REPORT_CONSTANTS
const CONVERSIONS_INDEX = TABS.findIndex(tab => tab === "conversions")

const ReportFilterPanel = () => {
  const { setPanelStatus, panelStatusControls } = useContext(PageContext)
  const { formik, regions, counties, activeTabIndex } = useContext(MiReportContext)
  const { canAccess, user } = useContext(AuthContext)

  const [products, setProducts] = useState([])
  const [providers, setProviders] = useState([])
  const [location, setLocation] = useState(locationKeys[0])

  const isNetworkRole = useMemo(
    () => canAccess(["NETWORK_ADMIN", "NETWORK_MEMBER_ADMIN"]),
    [canAccess]
  )
  const flyoutFormik = useFormik({
    initialValues: { ...formik.initialValues },
    validationSchema: miReportValidationSchema,
    onSubmit: values => {
      formik.setValues(values)
      setPanelStatus("closed")
    }
  })

  const updateProducts = selectedKeyProviders => {
    const listProvider = get(selectedKeyProviders, "products", {})
    let product = []
    Object.keys(listProvider).forEach(key => {
      product = product.concat({
        key,
        value: listProvider[key]
      })
    })
    // Remove duplicated items
    product = uniqBy(product, "key")
    setProducts(product)
  }

  useGet({
    endpoint: "/providers",
    onCompleted: res => {
      const data = get(res, "data", {})
      if (data && data.length > 0) {
        const listProviders = res.data.map(provider => ({
          key: provider.slug,
          value: provider.name,
          products: provider.products
        }))
        setProviders(listProviders)
      }
    }
  })

  const isNotConversionsTab = activeTabIndex !== CONVERSIONS_INDEX

  useEffect(
    () => {
      setLocation(locationKeys[0])
      flyoutFormik.setValues(cloneDeep(formik.values))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, panelStatusControls.panelStatus]
  )

  const renderUserList = useCallback(
    ({ slug, ...rest }) => ({
      id: slug,
      label: getName({ data: rest })
    }),
    []
  )

  const flyOutBody = useCallback(
    () => (
      <form onSubmit={flyoutFormik.handleSubmit}>
        <PanelHeader>
          <H3 appearance="light" margin="0 0 1.5rem 0">
            Underwriting
          </H3>
          <P appearance="light" margin="0 0 1rem 0">
            Type
          </P>
          <FlexAlignCenter>
            <Checkbox
              formik={flyoutFormik}
              appearance="light"
              name="underwritingTypeFmu"
              label="FMU"
            />
            <Checkbox
              formik={flyoutFormik}
              appearance="light"
              name="underwritingTypeMoratorium"
              label="Moratorium"
            />
          </FlexAlignCenter>
          <P appearance="light" margin="0 0 1rem 0">
            Style
          </P>
          <FlexAlignCenter>
            <Checkbox
              formik={flyoutFormik}
              appearance="light"
              name="underwritingStyleNew"
              label="New"
            />
            <Checkbox
              formik={flyoutFormik}
              appearance="light"
              name="underwritingStyleSwitch"
              label="Switch"
            />
          </FlexAlignCenter>
        </PanelHeader>
        <PanelBody>
          <FlexColumnLargeGap>
            <AuthWrapper roles={["SYS_ADMIN", "SUPPORT_ADMIN"]}>
              <H3 appearance="light" margin="0">
                Networks
              </H3>
              <AuthWrapper>
                <FilterItem
                  hasSearch
                  type="condition_typeahead"
                  title="Networks"
                  formik={flyoutFormik}
                  formikKey="filterNetworks"
                  api="/networks"
                />
              </AuthWrapper>
            </AuthWrapper>
            <AuthWrapper roles={allowedRolesOfOrganisationAndUser}>
              <H3 appearance="light" margin="0">
                Organisations & Users
              </H3>
              <AuthWrapper roles={["SYS_ADMIN", "SUPPORT_ADMIN"]}>
                <FilterItem
                  hasSearch
                  type="condition_typeahead"
                  title="Organisation"
                  formik={flyoutFormik}
                  formikKey="filterOrganisations"
                  api={
                    isNetworkRole
                      ? `/networks/${get(user, "parent.slug", "")}/organisations`
                      : "/organisations"
                  }
                  queries={{
                    ...{
                      not_member_organisations:
                        flyoutFormik.values.filterNetworks
                          ?.filter(
                            network =>
                              network.condition === "not included" && network.key.trim() !== ""
                          )
                          ?.map(o => o.key) ?? [],
                      member_organisations:
                        flyoutFormik.values.filterNetworks
                          ?.filter(
                            network => network.condition === "included" && network.key.trim() !== ""
                          )
                          ?.map(o => o.key) ?? []
                    }
                  }}
                />
              </AuthWrapper>
            </AuthWrapper>
            <AuthWrapper roles={[...allowedRolesOfOrganisationAndUser, "ORG_ADMIN"]}>
              <FilterItem
                hasSearch
                formik={flyoutFormik}
                type="condition_typeahead"
                title="Users"
                api="/users-organisations"
                keywordSearch="report_search"
                formikKey="filterUsers"
                renderOption={renderUserList}
                queries={{
                  ...(() => {
                    if (canAccess(["PROVIDER_ADMIN", "NETWORK_ADMIN", "NETWORK_MEMBER_ADMIN"])) {
                      return {}
                    }
                    return { parent_type: "ORGANISATION" }
                  })(),
                  parent_slug: canAccess(["ORG_ADMIN"])
                    ? [get(user, "parent.slug", "")]
                    : flyoutFormik.values.filterOrganisations
                        ?.filter(organisation => organisation.condition === "included")
                        ?.map(o => o.key) ?? [],
                  ...(canAccess(["ORG_ADMIN"])
                    ? {}
                    : {
                        parent_exclusion:
                          flyoutFormik.values.filterOrganisations
                            ?.filter(organisation => organisation.condition === "not included")
                            ?.map(o => o.key) ?? []
                      })
                }}
              />
              <Divider appearance="light" />
            </AuthWrapper>

            {isNotConversionsTab && (
              <>
                <AuthWrapper roles={allowedRolesOfProvider}>
                  <H3 appearance="light" margin="0">
                    Providers & Products
                  </H3>
                  <FilterItem
                    title="Providers"
                    type="select"
                    formik={flyoutFormik}
                    resultItems={providers}
                    formikKey="filterProviders"
                    onSelect={selectedProvider => updateProducts(selectedProvider)}
                  />
                </AuthWrapper>
                <FilterItem
                  type="select"
                  title="Products"
                  formik={flyoutFormik}
                  resultItems={products}
                  formikKey="filterProducts"
                />
                <Divider appearance="light" />
              </>
            )}

            <H3 appearance="light" margin="0">
              Location
            </H3>
            <Radios
              name="location"
              appearance="light"
              selectedKey={location}
              onChange={setLocation}
              items={locationKeys}
            />
            {location === locationKeys[0] && (
              <Select margin="0" name="filterCounty" formik={flyoutFormik}>
                <option value="">Select type</option>
                {counties?.map(key => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </Select>
            )}
            {location === locationKeys[1] && (
              <Select margin="0" name="filterRegion" formik={flyoutFormik}>
                <option value="">Select type</option>
                {regions?.map(key => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </Select>
            )}
            {location === locationKeys[2] && (
              <Input formik={flyoutFormik} resultItems={regions} name="filterPostcode" autoFocus />
            )}
            <Divider appearance="light" />
            <Button
              iconSize="1rem"
              margin="0 0 0 auto"
              type="inline-button"
              leadingIcon="trash-can"
              appearance="errorInlineLight"
              onClick={() => flyoutFormik.resetForm()}
            >
              Clear all filters
            </Button>
            <FlexAlignCenter>
              <Button
                appearance="error"
                leadingIcon="close"
                margin="0 auto 0 0"
                onClick={() => setPanelStatus("closed")}
              >
                Cancel
              </Button>
              <Button type="submit" appearance="success" leadingIcon="check">
                Apply
              </Button>
            </FlexAlignCenter>
          </FlexColumnLargeGap>
        </PanelBody>
      </form>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      counties,
      flyoutFormik,
      isNetworkRole,
      canAccess,
      location,
      products,
      regions,
      renderUserList,
      setPanelStatus,
      user
    ]
  )

  return <FlyOutPanel body={flyOutBody} />
}

export default ReportFilterPanel
