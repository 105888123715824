import React from "react"
import { get } from "lodash"
import { Button, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { P, SmallText } from "@4cplatform/elements/Typography"
import { AuthWrapper } from "@4cplatform/elements/Auth"

// Helpers
import { NetworksContext } from "./networks.context"

// Components
import NetworksPanelHeader from "./networks.panel.header"

const NetworkView = () => {
  const { onDeleteNetwork, deleteLoading, viewData, viewLoading } =
    React.useContext(NetworksContext)
  const [open, setOpen] = React.useState(false)
  const isDeleted = !!get(viewData, "deleted_at")

  return (
    <>
      <NetworksPanelHeader
        selectedNetwork={viewData}
        isDeleted={isDeleted}
        isLoading={viewLoading}
      />
      <PanelBody isDeleted={isDeleted}>
        <SmallText appearance="light" isLoading={viewLoading} margin="0 0 1rem">
          <b>Description:</b>
        </SmallText>
        <P isLoading={viewLoading} appearance="light">
          {get(viewData, "description", "-")}
        </P>
        {!isDeleted && (
          <AuthWrapper roles={["SYS_ADMIN"]}>
            <Button
              appearance="error"
              trailingIcon="delete"
              onClick={() => setOpen(true)}
              isLoading={viewLoading}
              isDisabled={isDeleted || viewLoading || !get(viewData, "is_deletable")}
              name="delete_network"
            >
              Delete network
            </Button>
          </AuthWrapper>
        )}
      </PanelBody>
      {open && (
        <ConfirmationModal
          confirmIcon="delete"
          confirmText="Delete network"
          confirmAppearance="error"
          cancelAppearance="errorGhost"
          onClose={() => setOpen(false)}
          onConfirm={() => {
            onDeleteNetwork(viewData)
          }}
          isLoadingConfirm={deleteLoading}
        >
          <P>Are you sure you want to delete this network? This action cannot be undone.</P>
        </ConfirmationModal>
      )}
    </>
  )
}

export default NetworkView
