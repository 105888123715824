import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import moment from "moment"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { PoliciesContext } from "./policies.context"
import { getName } from "../../Helpers"

// Components
import Actions from "./policies.actions"
import { SimulatedWrapper } from "./policies.styles"

const PolicyAdmin = ({ hasType }) => {
  const {
    data,
    queryLoading,
    onPolicySelect,
    onPolicyDeselect,
    pagination,
    setPage,
    setPerPage,
    onSort,
    sorting,
    status,
    setReference
  } = React.useContext(PoliciesContext)

  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)
  const t = useTranslations()

  return (
    <>
      <Actions />
      <Table
        data={data}
        isLoading={queryLoading}
        name="policies"
        hasQueryControls
        columns={[
          {
            label: status[0] === "AWAITING_TERMS" ? "Quote Ref" : "Policy ref",
            dataKey: "reference",
            minWidth: "60px",
            sortable: true
          },
          hasType && {
            label: "Type",
            dataKey: "simulated",
            minWidth: "60px",
            render: row => {
              const isSimulated = get(row, "data.simulation_mode", 0)
              return isSimulated === 1 ? <SimulatedWrapper>simulated</SimulatedWrapper> : <></>
            }
          },
          [
            {
              label: "Client name",
              dataKey: "client",
              minWidth: "60px",
              fieldColour: get(colours, "blue", "blue"),
              sortable: true,
              render: row =>
                getName({
                  data: get(row, "data.client"),
                  hasMiddle: true
                })
            },
            {
              label: status[0] === "AWAITING_TERMS" ? "Quote status" : "Underwriting status",
              dataKey: "status",
              minWidth: "60px",
              sortable: true,
              render: row => t(get(row.data, "status"))
            }
          ],
          [
            {
              label: "Sales Organisation name",
              dataKey: "organisation",
              minWidth: "60px",
              sortable: true,
              render: row => get(row, "data.organisation.name")
            },
            {
              label: "Sales Agent name",
              dataKey: "sales_agent",
              minWidth: "60px",
              sortable: true,
              render: row => getName({ data: get(row, "data.sales_agent") })
            }
          ],
          hasType
            ? [
                {
                  label: "Provider",
                  dataKey: "provider",
                  minWidth: "60px",
                  sortable: true,
                  render: row => t(get(row, "data.provider.name"))
                },
                {
                  label: "Product name",
                  dataKey: "product_name",
                  minWidth: "60px",
                  sortable: true,
                  render: row => t(get(row, "data.product_name", "-"))
                }
              ]
            : {
                label: "Product name",
                dataKey: "product_name",
                minWidth: "60px",
                sortable: true,
                render: row => t(get(row, "data.product_name", "-"))
              },
          [
            {
              label: "Annual premium",
              dataKey: "annual_premium",
              minWidth: "60px",
              render: row => {
                const premium = get(row, "data.annual_premium", "")
                return premium ? `£${premium}` : "-"
              },
              sortable: true
            },
            {
              label: "Months of cover",
              dataKey: "months_of_cover",
              minWidth: "80px",
              sortable: true
            }
          ],
          hasType
            ? [
                {
                  label: "Created",
                  dataKey: "created_at",
                  minWidth: "60px",
                  render: row => {
                    const created = get(row, "data.created_at", "")
                    return created ? moment.utc(created).local().format("DD/MM/YYYY HH:mm") : "-"
                  },
                  sortable: true
                },
                {
                  label: "Onboarded",
                  dataKey: "onboarded_at",
                  minWidth: "60px",
                  sortable: true,
                  render: row => {
                    const oboarded = get(row, "data.onboard_history[0].created_at", "")
                    return oboarded ? moment.utc(oboarded).local().format("DD/MM/YYYY HH:mm") : "-"
                  }
                }
              ]
            : {
                label: "Created",
                dataKey: "created_at",
                minWidth: "60px",
                render: row => {
                  const created = get(row, "data.created_at", "")
                  return created
                    ? moment.utc(created, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                    : "-"
                },
                sortable: true
              }
        ]}
        hasActions
        onClick={row => {
          onPolicySelect(row)
          setPanelStatus("open")
        }}
        onClose={() => {
          setPanelStatus("closed")
          onPolicyDeselect()
          setReference()
        }}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        perPageOptions={{ max: 50, interval: 5 }}
        sorting={sorting}
      />
    </>
  )
}

PolicyAdmin.defaultProps = {
  hasType: false
}

PolicyAdmin.propTypes = {
  hasType: PropTypes.bool
}
export default PolicyAdmin
