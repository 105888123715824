import styled, { css } from "styled-components"
import { get } from "lodash"
import { getBreakpoint } from "@4cplatform/elements/Helpers"

export const Wrapper = styled.header`
  display: flex;
  background-color: ${({ theme }) => get(theme, "darkBlue", "darkBlue")};
  position: fixed;
  min-height: 7rem;
  top: -100%;
  opacity: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;

  ${getBreakpoint({ max: "medium" })} {
    padding: 2rem;
  }

  ${({ isSticky }) =>
    isSticky &&
    css`
      opacity: 1;
      z-index: 99;
      top: 0;
      left: 0;
    `}
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;

  ${getBreakpoint({ max: "medium" })} {
    gap: 2rem;
    flex-direction: column;
  }
`

export const Nav = styled.nav`
  display: flex;
  color: white;
  gap: 4rem;
  align-items: center;
  flex-wrap: wrap;

  ${getBreakpoint({ max: "medium" })} {
    flex-direction: column;
  }
`
