import React, { useContext, useReducer, useEffect } from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import { get, sortBy, cloneDeep } from "lodash"
import { useLocation, useHistory } from "react-router-dom"
import { useGet, usePost, useDelete, usePatch, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Components
import { Provider } from "../../../../../UI/Templates/PolicyAdmin"
import { ProviderManageContext } from "../../../context/manage.context"

// Helpers
import { getOrderBy } from "../../../../../UI/Helpers"
import reducer from "./policies.reducer"
import { getStatus } from "../policies.helpers"

const PoliciesProvider = ({ children }) => {
  const { provider } = useContext(ProviderManageContext)
  const t = useTranslations()
  const { search: lSearch } = useLocation()
  const history = useHistory()
  const qs = queryString.parse(lSearch)
  const queryStatus = get(qs, "status", "")
  const simulatedQuery = get(qs, "simulated", "")

  // State
  const [
    {
      data,
      viewData,
      showSimulated,
      hasShowSimulated,
      status,
      hasStatusFilter,
      search,
      filter,
      selectedPolicy,
      sorting,
      page,
      perPage,
      total,
      editModal,
      deleteExclusionModal,
      declineUnderwritingModal,
      declinePolicyModal,
      messagingModal,
      exclusionsModal,
      reference,
      selectedExclusion,
      messages,
      exclusionsData
    },
    dispatch
  ] = useReducer(reducer, {
    data: [],
    viewData: {},
    showSimulated: simulatedQuery === "true",
    hasShowSimulated: true,
    status: ["ACCEPTED", "ONBOARDED"],
    hasStatusFilter: true,
    search: "",
    selectedPolicy: null,
    selectedExclusion: null,
    sorting: { direction: "asc", dataKey: "created_at" },
    filter: "client_name",
    page: 1,
    perPage: 10,
    total: null,
    messagingModal: false,
    deleteExclusionModal: false,
    exclusionsModal: false,
    declineUnderwritingModal: false,
    declinePolicyModal: false,
    reference: "",
    messages: [],
    exclusionsData: []
  })

  // Set the query search if search is truthy
  const querySearch = {}
  if (search) querySearch[filter] = search
  if (status.length) querySearch.status = status

  // Index Policies query
  const {
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch
  } = useGet({
    endpoint: "/policies",
    skip: !get(provider, "name", ""),
    query: {
      limit: perPage,
      order_by: getOrderBy(sorting),
      simulated: showSimulated,
      provider_key: get(provider, "provider_key", ""),
      page,
      status
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("POLICIES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Policy data query
  const {
    loading: viewLoading,
    error: viewError,
    refetch: viewRefetch
  } = useGet({
    endpoint: "/policies/:slug",
    skip: !selectedPolicy,
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    query: {
      with: [
        "organisation",
        "client",
        "salesAgent",
        "provider",
        "disclosureNotes",
        "applicants",
        "documentLinks",
        "exclusions"
      ]
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: newData })
    },
    onError: () => {
      addAlert({
        message: t("POLICY_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Policy exclusions data query
  const {
    loading: exclusionsLoading,
    error: exclusionsError,
    refetch: exclusionsRefetch
  } = useGet({
    endpoint: "/policies/:slug/exclusions",
    skip: !selectedPolicy || get(selectedPolicy, "status", "") !== "AWAITING_TERMS",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "exclusionsData", value: get(res, "data", []) }),
    onError: () => {
      addAlert({
        message: t("EXCLUSIONS_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Add policy exclusion
  const [onCreateExclusion, { loading: addExclusionLoading, error: addExclusionError }] = usePost({
    endpoint: "/policies/:slug/exclusions",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("EXCLUSION_ADD_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      exclusionsRefetch()
    },
    onError: () => {
      addAlert({
        message: t("EXCLUSION_ADD_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Delete exclusion mutation
  const [onDeleteExclusion, { loading: deleteExclusionLoading }] = useDelete({
    endpoint: "/policies/:slug/exclusions/:exclusion",
    params: {
      slug: get(selectedPolicy, "slug", ""),
      exclusion: get(selectedExclusion, "id", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("EXCLUSION_DELETE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 2
      })
      viewRefetch()
    },
    onError: () => {
      addAlert({
        message: t("EXCLUSION_DELETE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Update exclusion mutation
  const [onUpdateExclusion, { loading: updateExclusionLoading }] = usePatch({
    endpoint: "/policies/:slug/exclusions/:exclusion",
    params: {
      slug: get(selectedPolicy, "slug", ""),
      exclusion: get(selectedExclusion, "id", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("EXCLUSION_UPDATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "editModal", value: false })
      viewRefetch()
    },
    onError: () => {
      addAlert({
        message: t("EXCLUSION_UPDATE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Accept underwriting
  const [
    onAcceptUnderwriting,
    { loading: acceptUnderwritingLoading, error: acceptUnderwritingError }
  ] = usePatch({
    endpoint: "/policies/:slug/accept-underwriting",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("UNDERWRITING_ACCEPT_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      addAlert({
        message: t("UNDERWRITING_ACCEPT_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Accept underwriting with exclusions
  const [
    onAcceptUnderwritingExclusions,
    { loading: acceptUnderwritingExclusionsLoading, error: acceptUnderwritingExclusionsError }
  ] = usePatch({
    endpoint: "/policies/:slug/accept-underwriting",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("UNDERWRITING_EXCLUSIONS_ACCEPT_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      addAlert({
        message: t("UNDERWRITING_EXCLUSIONS_ACCEPT_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Decline underwriting
  const [
    onDeclineUnderwriting,
    { loading: declineUnderwritingLoading, error: declineUnderwritingError }
  ] = usePatch({
    endpoint: "/policies/:slug/decline-underwriting",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("UNDERWRITING_DECLINE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "declineUnderwritingModal", value: false })
    },
    onError: () => {
      addAlert({
        message: t("UNDERWRITING_DECLINE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Accept Policy
  const [onAcceptPolicy, { loading: acceptPolicyLoading, error: acceptPolicyError }] = usePatch({
    endpoint: "/policies/:slug/accept",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("POLICY_ACCEPT_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      queryRefetch()
    },
    onError: () => {
      addAlert({
        message: t("POLICY_ACCEPT_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Decline policy
  const [onDeclinePolicy, { loading: declinePolicyLoading, error: declinePolicyError }] = usePatch({
    endpoint: "/policies/:slug/decline",
    params: {
      slug: get(selectedPolicy, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("POLICY_DECLINE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "declinePolicyModal", value: false })
    },
    onError: () => {
      addAlert({
        message: t("POLICY_DECLINE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Get Policy messages
  const {
    loading: messagesLoading,
    error: messagesError,
    refetch: messagesRefetch
  } = useGet({
    endpoint: "/policies/:policy/messages",
    skip: !get(selectedPolicy, "slug") || !messagingModal,
    params: {
      policy: get(selectedPolicy, "slug", "")
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "messages", value: sortBy(newData, "sent_at") })
    },
    onError: () => {
      addAlert({
        message: t("POLICY_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Create a message
  const [createMessage, { loading: submitMessageLoading, error: submitMessageError }] = usePost({
    endpoint: "/policies/:policy/message",
    params: {
      policy: get(selectedPolicy, "slug", "")
    },
    onCompleted: res => {
      const updatedMessages = [...messages, res.data]
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy(updatedMessages, "sent_at")
      })
    },
    onError: () => {
      messagesRefetch()
      addAlert({
        message: t("SEND_MESSAGE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })
  const submitMessage = ({ body, user }) => {
    const updatedMessages = [
      ...messages,
      {
        body_text: body.body_text,
        user_name: user
      }
    ]
    dispatch({
      type: "UPDATE_VALUE",
      key: "messages",
      value: updatedMessages
    })
    createMessage({ body })
  }

  useEffect(() => {
    dispatch({
      type: "UPDATE_VALUE",
      key: "status",
      value: queryStatus ? getStatus(queryStatus) : []
    })
  }, [queryStatus])

  useEffect(() => {
    dispatch({
      type: "UPDATE_VALUE",
      key: "showSimulated",
      value: simulatedQuery === "true"
    })
  }, [simulatedQuery])

  const [generateAuditLog, { loading: generateAuditLogLoading }] = usePost({
    endpoint: "/journeys/:slug/generate-audits",
    params: {
      slug: get(viewData, "journey_slug", "")
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      if (filePath) {
        window.open(filePath, "_blank")
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("AUDIT_LOG_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        viewData,
        exclusionsData,
        showSimulated,
        hasShowSimulated,
        status,
        hasStatusFilter,
        search,
        selectedPolicy,
        sorting,
        page,
        perPage,
        total,
        messagingModal,
        exclusionsModal,
        reference,
        selectedExclusion,
        queryLoading,
        queryRefetch,
        viewLoading,
        viewRefetch,
        exclusionsLoading,
        exclusionsRefetch,
        addExclusionLoading,
        deleteExclusionLoading,
        updateExclusionLoading,
        editModal,
        deleteExclusionModal,
        onAcceptUnderwriting,
        acceptUnderwritingLoading,
        onAcceptUnderwritingExclusions,
        acceptUnderwritingExclusionsLoading,
        onDeclineUnderwriting,
        declineUnderwritingLoading,
        acceptPolicyLoading,
        onDeclinePolicy,
        declineUnderwritingModal,
        declinePolicyLoading,
        declinePolicyModal,
        messages,
        messagesLoading,
        submitMessage,
        submitMessageLoading,
        generateAuditLogLoading,
        generateAuditLog,
        setShowSimulated: val => {
          dispatch({ type: "UPDATE_VALUE", key: "showSimulated", value: val })
          history.replace({ search: queryString.stringify({ ...qs, simulated: val }) })
        },
        setFilter: val => dispatch({ type: "UPDATE_VALUE", key: "filter", value: val }),
        filter,
        setStatus: val => {
          if (!val) {
            dispatch({ type: "UPDATE_VALUE", key: "status", value: [] })
            const newQS = cloneDeep(qs)
            delete newQS.status
            history.replace({
              search: queryString.stringify(newQS)
            })
          } else {
            dispatch({ type: "UPDATE_VALUE", key: "status", value: getStatus(val) })
            history.replace({
              search: queryString.stringify({ ...qs, status: val })
            })
          }
        },
        setStatusFilter: val =>
          dispatch({ type: "UPDATE_VALUE", key: "hasStatusFilter", value: val }),
        setSearch,
        onPolicySelect: row =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedPolicy", value: row }),
        onPolicyDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "viewData", value: {} })
          dispatch({ type: "UPDATE_VALUE", key: "selectedPolicy", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "exclusionsData", value: [] })
          dispatch({ type: "UPDATE_VALUE", key: "messages", value: [] })
        },
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        pagination: { total, page, perPage },
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        setEditModal: val => dispatch({ type: "UPDATE_VALUE", key: "editModal", value: val }),
        setDeleteModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "deleteExclusionModal", value: val }),
        setMessagingModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "messagingModal", value: val }),
        setExclusionsModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "exclusionsModal", value: val }),
        setDeclineUnderwritingModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "declineUnderwritingModal", value: val }),
        setDeclinePolicyModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "declinePolicyModal", value: val }),
        onExclusionSelect: exclusion =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedExclusion", value: exclusion }),
        onCreateExclusion,
        onDeleteExclusion,
        onUpdateExclusion,
        setReference: val => {
          dispatch({ type: "UPDATE_VALUE", key: "reference", value: val })
        },
        onPolicyAccept: body => {
          onAcceptPolicy({ body })
          dispatch({ type: "UPDATE_VALUE", key: "reference", value: "" })
        }
      }}
    >
      {children}
      <ApiError
        error={
          queryError ||
          viewError ||
          exclusionsError ||
          addExclusionError ||
          acceptUnderwritingExclusionsError ||
          acceptUnderwritingError ||
          declineUnderwritingError ||
          acceptPolicyError ||
          declinePolicyError ||
          messagesError ||
          submitMessageError
        }
      />
    </Provider>
  )
}

PoliciesProvider.defaultProps = {
  children: null
}

PoliciesProvider.propTypes = {
  children: PropTypes.any
}

export default PoliciesProvider
