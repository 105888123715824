import { get } from "lodash"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"
import SendDocumentButton from "../../components/SendDocumentButton/sendDocumentButton"
import Audit from "./Audit"

export const config = data => ({
  title: "Sole Third Party Payer",
  sections: [
    {
      key: "main_section",
      components: [
        {
          component: Audit,
          componentProps: {
            isDocumentSent: !!get(data, "page.data.document_notification_sent")
          },
          skipDataMap: true
        },
        {
          key: "compliance_note",
          component: ComplianceNote,
          condition: {
            type: "data",
            path: "journey.selected_quote.sent_to_client",
            value: false
          },
          componentProps: {
            children: (
              <>
                <P margin="0">
                  Please revisit the Quotation Summary page and select "Push to Portal" for this
                  quote.
                </P>
              </>
            ),
            type: "warning"
          },
          skipDataMap: true
        },
        {
          key: "document_notification_sent",
          component: SendDocumentButton,
          componentProps: {
            isDocumentSent: !!get(data, "page.data.document_notification_sent"),
            apiEndpointPath: "sole-notify-client"
          },
          skipDataMap: true
        }
      ]
    }
  ]
})
