import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { v4 as uuid } from "uuid"
import { Select, TextArea } from "@4cplatform/elements/Forms"

// Components
import { ExclusionBodyWrapper, SectionWrapper } from "./policyExclusions.styles"
import { getName } from "../../../../Helpers"

const PolicyExclusionsApplicantEdit = ({ formik, applicants }) => (
  <ExclusionBodyWrapper data-testid="edit_exclusions-wrapper" isEditForm>
    <SectionWrapper isEditForm>
      <Select formik={formik} name="policy_applicant_id" label="Applicant" errorPrefix="label">
        <option value="">Select applicant</option>
        {applicants.map(applicant => {
          const name = getName({ data: applicant, hasMiddle: true })
          return (
            <option key={uuid()} value={get(applicant, "id")}>
              {name}
            </option>
          )
        })}
      </Select>
      <TextArea name="exclusion" label="Exclusion" margin="0" padding="1rem" formik={formik} />
    </SectionWrapper>
  </ExclusionBodyWrapper>
)

PolicyExclusionsApplicantEdit.propTypes = {
  /**
   * The formik instance that controls this component
   */
  formik: PropTypes.object.isRequired,
  /**
   * The list of policy exclusion applicant names
   */
  applicants: PropTypes.array.isRequired
}
export default PolicyExclusionsApplicantEdit
