/* eslint-disable no-fallthrough */
import React, { useContext, useEffect, useReducer } from "react"
import PropTypes from "prop-types"
import { get, map, extend, find, isEqual } from "lodash"
import { useFormik } from "formik"
import { usePost, useDelete, usePatch, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Components
import { Provider } from "../disclosureNotes.context"

// Helpers
import reducer from "./disclosureNotes.reducer"
import { JourneyContext } from "../../../journey.context"

const DisclosureNotesProvider = ({ children }) => {
  const {
    data,
    formik,
    onUpdatePageDisclosuresNotes,
    pageDisclosuresNotes,
    updateJourneyAuditData
  } = useContext(JourneyContext)
  const t = useTranslations()

  const [
    {
      applicants,
      addEditModal,
      noteToDelete,
      noteValidationSchema,
      confirmationModal,
      productName
    },
    dispatch
  ] = useReducer(reducer, {
    applicants: get(data, "journey.applicants", []),
    addEditModal: { type: null, isOpen: false, field: "", noteInitialValues: {} },
    noteToDelete: {
      id: null,
      fieldName: null
    },
    noteValidationSchema: {},
    confirmationModal: {
      warningText: "",
      isOpen: false,
      fieldName: null,
      query: "",
      noteId: null,
      closedSelected: false,
      confirmedSelected: false
    },
    productName: get(data, "journey.policy.product_name", "")
  })

  // Create disclousure note
  const [uploadNote, { loading: disclosureNoteCreating, error: uploadNoteError }] = usePost({
    endpoint: "/policies/:policy/disclosure-notes",
    params: {
      policy: get(data.journey.policy, "slug", "")
    },
    onCompleted: res => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "addEditModal",
        value: { type: null, isOpen: false, field: "", noteInitialValues: {} }
      })
      onUpdatePageDisclosuresNotes([...pageDisclosuresNotes, res.data])
      addAlert({
        message: t("DISCLOSURE_NOTE_CREATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      addAlert({
        message: t("DISCLOSURE_NOTE_CREATE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Update disclousure note
  const [updateNote, { loading: updateNoteLoading, error: updateNoteError }] = usePatch({
    endpoint: "/policies/:policy/disclosure-notes/:disclosure-note",
    onCompleted: res => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "addEditModal",
        value: { type: null, isOpen: false, field: "", noteInitialValues: {} }
      })
      addAlert({
        message: t("DISCLOSURE_NOTE_UPDATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      const newNote = map(pageDisclosuresNotes, note => {
        if (note.id === res.data.id) {
          return extend(note, res.data)
        }
        return note
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "disclosureNotes",
        value: newNote
      })
      onUpdatePageDisclosuresNotes(newNote)
    },
    onError: () => {
      addAlert({
        message: t("DISCLOSURE_NOTE_UPDATE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Delete disclousure  note
  const [deleteNote, { loading: deleteLoading, error: deleteError }] = useDelete({
    endpoint: "/policies/:policy/disclosure-notes/:disclosure-note",
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("DISCLOSURE_NOTE_DELETE_SUCCESS"),
        dismissible: true,
        timeout: 5
      })

      let filteredDisclosureNotes = []
      if (noteToDelete.id) {
        filteredDisclosureNotes = pageDisclosuresNotes.filter(note => note.id !== noteToDelete.id)
        dispatch({
          type: "UPDATE_VALUE",
          key: "disclosureNotes",
          value: filteredDisclosureNotes
        })
        onUpdatePageDisclosuresNotes(filteredDisclosureNotes)
      }
      // if no disclosure notes assosiated with the field left, then set the field value to false
      if (
        !find(filteredDisclosureNotes, note => note.field === noteToDelete.fieldName) &&
        get(formik, `values.${noteToDelete.fieldName}`)
      ) {
        formik.setFieldValue(noteToDelete.fieldName, false)
        dispatch({
          type: "UPDATE_VALUE",
          key: "noteToDelete",
          value: {
            id: null,
            fieldName: null
          }
        })
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("DISCLOSURE_NOTE_DELETE_ERROR"),
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "deleteOpen", value: false })
    }
  })

  const disclosureNoteFormik = useFormik({
    initialValues: addEditModal.noteInitialValues,
    validationSchema: noteValidationSchema,
    enableReinitialize: true,
    onSubmit: ({ id, ...values }) => {
      // Apply field to body
      const body = {
        ...values,
        field: addEditModal.field
      }
      if (addEditModal.type === "edit") {
        updateNote({
          body,
          params: {
            policy: get(data, "journey.policy.slug"),
            "disclosure-note": parseInt(id)
          }
        })
      } else {
        uploadNote({ body })
      }
    }
  })

  useEffect(() => {
    if (noteToDelete.id) {
      deleteNote({
        params: {
          policy: get(data, "journey.policy.slug"),
          "disclosure-note": parseInt(noteToDelete.id)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteToDelete])

  const { handleSubmit } = disclosureNoteFormik

  const onCancelOrClose = () => {
    if (!isEqual(disclosureNoteFormik.values, disclosureNoteFormik.initialValues)) {
      dispatch({
        type: "UPDATE_VALUE",
        key: "confirmationModal",
        value: {
          ...confirmationModal,
          warningText:
            "Are you sure you want to discard your changes? Unsaved changes will be lost!",
          isOpen: true,
          query: "deleteNewNote"
        }
      })
    } else {
      if (!find(pageDisclosuresNotes, note => note.field === addEditModal.field)) {
        formik.setFieldValue(addEditModal.field, false)
      }
      dispatch({
        type: "UPDATE_VALUE",
        key: "addEditModal",
        value: {
          type: null,
          isOpen: false,
          field: "",
          noteInitialValues: {}
        }
      })
    }
  }

  useEffect(() => {
    if (confirmationModal.closedSelected) {
      dispatch({
        type: "UPDATE_VALUE",
        key: "confirmationModal",
        value: {
          warningText: "",
          isOpen: false,
          fieldName: null,
          query: "",
          noteId: null,
          closedSelected: false,
          confirmedSelected: false
        }
      })
    }

    if (confirmationModal.confirmedSelected) {
      // eslint-disable-next-line default-case
      switch (confirmationModal.query) {
        case "deleteOneNote": {
          dispatch({
            type: "UPDATE_VALUE",
            key: "noteToDelete",
            value: {
              id: confirmationModal.noteId,
              fieldName: confirmationModal.fieldName
            }
          })

          break
        }
        // eslint-disable-next-line no-fallthrough
        case "deleteNewNote": {
          if (!find(pageDisclosuresNotes, note => note.field === addEditModal.field)) {
            formik.setFieldValue(addEditModal.field, false)
          }
          dispatch({
            type: "UPDATE_VALUE",
            key: "addEditModal",
            value: {
              type: null,
              isOpen: false,
              field: "",
              noteInitialValues: {}
            }
          })

          break
        }
      }
      dispatch({
        type: "UPDATE_VALUE",
        key: "confirmationModal",
        value: {
          warningText: "",
          isOpen: false,
          fieldName: null,
          query: "",
          noteId: null,
          closedSelected: false,
          confirmedSelected: false
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationModal])

  useEffect(
    () => () => updateJourneyAuditData([]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return (
    <Provider
      value={{
        disclosureNoteFormik,
        disclosureNotes: pageDisclosuresNotes,
        addEditModal,
        applicants,
        disclosureNoteCreating,
        setAddEditModal: val => dispatch({ type: "UPDATE_VALUE", key: "addEditModal", value: val }),
        setNoteToDelete: val => dispatch({ type: "UPDATE_VALUE", key: "noteToDelete", value: val }),
        handleSubmit,
        deleteLoading,
        noteToDelete,
        updateNoteLoading,
        onCancelOrClose,
        setNoteValidationSchema: val =>
          dispatch({ type: "UPDATE_VALUE", key: "noteValidationSchema", value: val }),
        confirmationModal,
        setConfirmationModal: val =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "confirmationModal",
            value: val
          }),
        productName
      }}
    >
      {children}
      <ApiError error={uploadNoteError || updateNoteError || deleteError} />
    </Provider>
  )
}

DisclosureNotesProvider.propTypes = {
  children: PropTypes.any
}

export default DisclosureNotesProvider
