import { boolean } from "yup"
import { get } from "lodash"

import DisclosureNotes from "../../../../../../DisclosureNotes"
import NoteFormTemplate from "./noteFormTemplate"
import NoteDisplayTemplate from "./noteDisplayTemplate"
import Audit from "./Audit"

export const config = data => ({
  title: "AXA FMU Disclosures",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          key: "specialist_admissions_illness_last_5_years",
          name: "specialist_admissions_illness_last_5_years",
          initialValue: get(data, "page.data.specialist_admissions_illness_last_5_years", false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label:
            "Have you or any person included in this application consulted with a specialist, been admitted to hospital or nursing home, or suffered from intermittent or recurrent illness during the last five years?",
          component: DisclosureNotes,
          componentProps: {
            options: [
              { order: 0, label: "No", value: false },
              { order: 1, label: "Yes", value: true }
            ],
            noteFormTemplate: NoteFormTemplate,
            noteDisplayTemplate: NoteDisplayTemplate,
            isHorizontal: true,
            isRequired: true
          }
        },
        {
          key: "seen_medical_practitioner_last_12_months",
          name: "seen_medical_practitioner_last_12_months",
          initialValue: get(data, "page.data.seen_medical_practitioner_last_12_months", false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label:
            "Have you or any person included in this application seen a medical practitioner in the past year? This includes a doctor, physiotherapist, practice nurse etc",
          component: DisclosureNotes,
          componentProps: {
            options: [
              { order: 0, label: "No", value: false },
              { order: 1, label: "Yes", value: true }
            ],
            noteFormTemplate: NoteFormTemplate,
            noteDisplayTemplate: NoteDisplayTemplate,
            isHorizontal: true,
            isRequired: true
          }
        },
        {
          key: "medical_conditions",
          name: "medical_conditions",
          initialValue: get(data, "page.data.medical_conditions", false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label:
            "Have you or any person included in this application had any medical condition, disability or health problem, not mentioned above, whether or not a doctor has been consulted, for example, gynaecological or menstrual problems, complications of pregnancy, signs or varicose veins, back trouble, joint disorders, joint replacements, foot problems (eg bunions), indigestion or bowel problems, abdominal pain, skin problems allergies, anxiety, depression or other psychiatric problems, trouble with heart, limbs, ears, eyes, urination etc?",
          component: DisclosureNotes,
          componentProps: {
            options: [
              {
                order: 0,
                label: "No",

                value: false
              },
              { order: 1, label: "Yes", value: true }
            ],
            noteFormTemplate: NoteFormTemplate,
            noteDisplayTemplate: NoteDisplayTemplate,
            isHorizontal: true,
            isRequired: true
          }
        }
      ]
    }
  ]
})
