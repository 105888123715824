import React, { useState } from "react"
import PropTypes from "prop-types"
import { v4 as uuid } from "uuid"
import { get } from "lodash"
import { FileSelect } from "@4cplatform/elements/Forms"
import { H3, P } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { nullFunc } from "@4cplatform/elements/Helpers"
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import { BodyWrapper, ListWrapper } from "./import.styles"
import FileList from "../../../../Molecules/FileList"

const Body = ({ type, formik, isLoading, errors, handleClearForm }) => {
  const t = useTranslations()
  const [keyUpload, setFileUpload] = useState(uuid())

  const handleClear = () => {
    setFileUpload(uuid())
    handleClearForm()
  }

  return (
    <>
      <BodyWrapper>
        {/* "import" type content */}
        {type === "import" && (
          <FileSelect
            name="file"
            formik={formik}
            margin="0"
            isLoading={isLoading}
            accept=".xls,.xlsx,.csv"
            maxFileSizeMb="10"
            key={keyUpload}
          />
        )}
        {/* "error" type content */}
        {type === "error" && (
          <ComplianceNote type="error">
            <P>There are errors with the uploaded file:</P>
            {errors.map(item => (
              <P key={uuid()}>{t(item)}</P>
            ))}
          </ComplianceNote>
        )}
      </BodyWrapper>
      {/* FileList */}
      <ListWrapper>
        <H3 margin="0 0 2rem">Selected File</H3>
        <FileList
          files={get(formik, "values.file", [])}
          isError={type === "error"}
          handleClearForm={handleClear}
          isLoading={isLoading}
        />
      </ListWrapper>
    </>
  )
}

Body.defaultProps = {
  type: "import",
  formik: {},
  isLoading: false,
  errors: [],
  handleClearForm: nullFunc
}

Body.propTypes = {
  type: PropTypes.oneOf(["import", "error"]),
  formik: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.array,
  handleClearForm: PropTypes.func
}

export default Body
