import styled from "styled-components"
import { get } from "lodash"

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: ${({ hasLeftActions }) => (hasLeftActions ? "space-between" : "flex-end")};
  align-items: center;
  margin-bottom: 1rem;
`
export const TableActionsLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TableActionsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const SimulatedWrapper = styled.span`
  padding: 0.5rem;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => get(theme, "white", "white")};
  background: ${({ theme }) => get(theme, "red", "red")};
  text-transform: uppercase;
`
