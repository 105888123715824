import React from "react"
import CheckBox from "@4cplatform/elements/Forms/Checkbox"
import { AuthWrapper } from "@4cplatform/elements/Auth"
import { Select } from "@4cplatform/elements/Forms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { UsersContext } from "./users.context"

// Components
import {
  TableActionsWrapper,
  TableActionsButton,
  TableActionsRight,
  TableActionsLeft,
  RoleWrapper
} from "./users.styles"
import { Search } from "../../Molecules"

const parentTypes = ["NETWORK", "ORGANISATION", "PROVIDER", "SYSTEM"]

const getRoles = parentType => {
  switch (parentType) {
    case "SYSTEM":
      return ["SYS_ADMIN", "SUPPORT_ADMIN"]

    case "PROVIDER":
      return ["PROVIDER_ADMIN", "UNDERWRITER"]

    case "ORGANISATION":
      return ["ORG_ADMIN", "SALES_ADVISER"]

    case "NETWORK":
      return ["NETWORK_ADMIN", "NETWORK_MEMBER_ADMIN"]

    default:
      return [
        "SYS_ADMIN",
        "SUPPORT_ADMIN",
        "ORG_ADMIN",
        "SALES_ADVISER",
        "NETWORK_ADMIN",
        "NETWORK_MEMBER_ADMIN",
        "PROVIDER_ADMIN",
        "UNDERWRITER"
      ]
  }
}

const Actions = () => {
  const {
    setSearch,
    showDeleted,
    hasParentFilter,
    parentType,
    setParentType,
    setShowDeleted,
    createUserRoute,
    setRole,
    role,
    setPage
  } = React.useContext(UsersContext)

  const t = useTranslations()

  const parentTypeHandler = val => {
    setPage(1)
    setParentType(val)
    setRole("")
  }

  const roleHandler = val => {
    setPage(1)
    setRole(val)
  }

  return (
    <TableActionsWrapper data-testid="users-actions-wrapper">
      <TableActionsLeft>
        <CheckBox
          label="Show deleted users"
          margin="0 1rem 0 0"
          name="show_deleted"
          value={showDeleted}
          onChange={setShowDeleted}
        />
      </TableActionsLeft>

      <TableActionsRight>
        {hasParentFilter && (
          <AuthWrapper roles={["SYS_ADMIN", "SUPPORT_ADMIN"]}>
            <Select
              name="filter_parent_type"
              onChange={parentTypeHandler}
              margin="0"
              value={parentType}
              label="Parent type"
              labelWidth="auto"
              isHorizontal
            >
              <option value="">Select type</option>
              {parentTypes.map(key => (
                <option key={key} value={key}>
                  {t(key)}
                </option>
              ))}
            </Select>
            <RoleWrapper>
              <Select
                name="filter_role"
                onChange={roleHandler}
                margin="0"
                value={role}
                label="Role"
                labelWidth="auto"
                isHorizontal
              >
                <option value="">Select role</option>
                {getRoles(parentType).map(val => (
                  <option key={val} value={val}>
                    {t(val)}
                  </option>
                ))}
              </Select>
            </RoleWrapper>
          </AuthWrapper>
        )}
        <Search
          name="search_users"
          handleChange={setSearch}
          onCancel={() => setSearch("")}
          margin={hasParentFilter ? "0 2rem 0 2rem" : "0 2rem 0 0"}
          width="23rem"
        />

        <TableActionsButton
          trailingIcon="account-plus"
          type="Link"
          to={createUserRoute || "/users/add"}
          name="add_user"
        >
          Add user
        </TableActionsButton>
      </TableActionsRight>
    </TableActionsWrapper>
  )
}

export default Actions
