import moment from "moment"
import { object, string, boolean, array } from "yup"
import JsPDF from "jspdf"
import html2canvas from "html2canvas"
import { formatLabelForTestID } from "@4cplatform/elements/Helpers"
import { validPostcodeRegex } from "../../Helpers"

export const REPORT_CONSTANTS = {
  METRIC_ANNUAL_TOTAL: {
    TITLE: "Annual Total",
    KEY: "annual_total"
  },
  METRIC_TOTAL: {
    TITLE: "Total",
    KEY: "annual_total"
  },
  METRIC_BEST_MONTH: {
    TITLE: "Best Month",
    KEY: "best_month"
  },
  METRIC_MONTHLY_AVERAGE: {
    TITLE: "Monthly Average",
    KEY: "monthly_average"
  },
  METRIC_POLICIES_SOLD: {
    TITLE: "Policies Sold",
    KEY: "policies_sold"
  },
  FILTER_BY: {
    YEAR: "year",
    MONTH: "month",
    CUSTOM: "custom"
  },
  MONTHS: [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  TABS: ["sales", "conversions", "commission"],
  TABS_REPORT: [
    {
      value: "sales",
      label: "Sales"
    },
    {
      value: "conversions",
      label: "Quotation and Conversions (%)",
      excludes: ["PROVIDER_ADMIN", "UNDERWRITER"]
    },
    {
      value: "commission",
      label: "Commission",
      excludes: ["SYS_ADMIN", "SUPPORT_ADMIN", "PROVIDER_ADMIN", "UNDERWRITER", "QUEUE_WORKER"]
    }
  ],
  SALES_SUB_TABS: ["premiums", "policies", "products"]
}

const { TABS, MONTHS, FILTER_BY, SALES_SUB_TABS } = REPORT_CONSTANTS
const SALES_INDEX = TABS.findIndex(tab => tab === "sales")
const CONVERSIONS_INDEX = TABS.findIndex(tab => tab === "conversions")
const COMMISSION_INDEX = TABS.findIndex(tab => tab === "commission")

export function onHandlePDF(element, isPrintPDF = false) {
  const pdf = new JsPDF("p", "pt", "a4")
  const width = pdf.internal.pageSize.getWidth()

  html2canvas(element, {
    onclone: document => {
      const dateRangeLabel = document.querySelector("#dateRangeForPDF")
      if (dateRangeLabel) dateRangeLabel.style.display = "block"

      const reportHeaderPDF = document.querySelector("#reportHeaderPDF")
      if (reportHeaderPDF) reportHeaderPDF.style.display = "block"

      const reportHeader = document.querySelector("#reportHeader")
      if (reportHeader) reportHeader.style.display = "none"
    }
  }).then(canvas => {
    const img = canvas.toDataURL("image/png", 1.0)
    pdf.addImage(
      img,
      "PNG",
      16,
      16,
      width - 16 * 2,
      canvas.height / (canvas.width / width) - 16 * 2
    )
    if (isPrintPDF) {
      pdf.autoPrint()
      window.open(pdf.output("bloburl"), "_blank")
    } else {
      pdf.save(`MI_report${moment().format("_DD_MM_YYYY_HH_MM_ss")}.pdf`)
    }
  })
}

export function getYearList() {
  const years = [new Date().getFullYear()]

  for (let i = 0; i < 10; i++) {
    years.push(years[years.length - 1] - 1)
  }

  return years
}

export function getChartLabel(filterBy, item) {
  switch (filterBy) {
    case FILTER_BY.YEAR:
      return MONTHS[item.month]

    case FILTER_BY.MONTH:
      return moment.utc(item.date).local().format("DD/MM/YYYY")

    default:
      return item.date
        ? moment.utc(item.date).local().format("DD/MM/YYYY")
        : `${item.year}/${MONTHS[item.month]}`
  }
}

export function getChartKey(tabKey) {
  switch (tabKey) {
    case `${TABS[SALES_INDEX]}_${SALES_SUB_TABS[0]}`:
    case `${TABS[SALES_INDEX]}_${SALES_SUB_TABS[1]}`:
      return "sale_chart_data"

    case `${TABS[SALES_INDEX]}_${SALES_SUB_TABS[2]}`:
      return "product_sale_chart_data"

    case TABS[CONVERSIONS_INDEX]:
      return "conversions_chart_data"

    case TABS[COMMISSION_INDEX]:
      return "commission_chart_data"

    default:
      return ""
  }
}

export const keyValueSchema = object().shape({
  key: string(),
  value: string()
})

export function getTabHeader(key) {
  switch (key) {
    case TABS[SALES_INDEX]:
      return "Sales"
    case TABS[CONVERSIONS_INDEX]:
      return "Quotation and Conversions (%)"
    case TABS[COMMISSION_INDEX]:
      return "Commission"
    case SALES_SUB_TABS[0]:
      return "Premiums"
    case SALES_SUB_TABS[1]:
      return "Policies"
    case SALES_SUB_TABS[2]:
      return "Products"
    default:
      return ""
  }
}

export function getTabIndexByKey(arrKeys, key) {
  return arrKeys?.map(k => formatLabelForTestID(getTabHeader(k)))?.indexOf(key) ?? -1
}

export function getDefaultTabIndexes(queryParsed) {
  let activeTabIndex = 0
  let activeSalesSubTabIndex = 0

  if (queryParsed && Object.keys(queryParsed).length > 0) {
    let idx = getTabIndexByKey(TABS, queryParsed.report_type)
    activeTabIndex = idx > -1 ? idx : 0

    if (activeTabIndex === 0) {
      idx = getTabIndexByKey(SALES_SUB_TABS, queryParsed.sales_sub_tab)
      activeSalesSubTabIndex = idx > -1 ? idx : 0
    } else {
      activeSalesSubTabIndex = -1
    }
  }

  return { activeTabIndex, activeSalesSubTabIndex }
}
export const miReportValidationSchema = object({
  filterRegion: string(),
  filterCounty: string(),
  postcode: string()
    .test("", "CORRECT_UK_POSTCODE", no => (no ? validPostcodeRegex.test(no) : true))
    .nullable(),
  underwritingTypeFmu: boolean(),
  underwritingStyleNew: boolean(),
  underwritingStyleSwitch: boolean(),
  underwritingTypeMoratorium: boolean(),
  filterUsers: array().of(keyValueSchema),
  filterProducts: array().of(keyValueSchema),
  filterProviders: array().of(keyValueSchema),
  filterOrganisations: array().of(keyValueSchema),
  filterNetworks: array().of(keyValueSchema)
})
