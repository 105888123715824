import { useState, useEffect, useContext } from "react"
import { get, capitalize } from "lodash"
import moment from "moment"
import { useGet } from "@4cplatform/elements/Api"

import { ConfigContext } from "@4cplatform/elements/Config"
import { JourneyContext } from "../../../../../../journey.context"
import { ApplicantsContext } from "./applicants.context"

const Audit = () => {
  const { updateJourneyAuditData } = useContext(JourneyContext)
  const { applicants } = useContext(ApplicantsContext)
  const { GLOBAL_TITLES } = useContext(ConfigContext)

  const [occupationsObj, setOccupationsObj] = useState(null)

  // Index occupations
  useGet({
    endpoint: "/occupations",
    onCompleted: res => {
      const newData = get(res, "data", [])
      setOccupationsObj(newData)
    }
  })

  useEffect(() => {
    if (occupationsObj) {
      updateJourneyAuditData([
        {
          mode: "replace",
          data: (applicants || []).map(row => {
            const applicantName = (() => {
              const first = get(row, "first_name")
              const last = get(row, "last_name")
              const middle = get(row, "middle_names", "")
              let value = ""

              if (!first || !last) {
                return "-"
              }
              if (middle) {
                value = `${first} ${middle} ${last}`
              } else {
                value = `${first} ${last}`
              }

              return value
            })()
            const applicantType = capitalize(get(row, "type", "-"))
            const title = (() => {
              let _title = get(
                row,
                "title.label",
                get(GLOBAL_TITLES, `data.${get(row, "title")}.title`, get(row, "title"))
              )
              if (_title === "Other") {
                _title = ""
              }

              return _title
            })()

            return {
              name: `${applicantName} (${applicantType})`,
              value: [
                {
                  name: "Type",
                  value: applicantType
                },
                {
                  name: "Title",
                  value: title
                },
                {
                  name: "First name",
                  value: get(row, "first_name", "-")
                },
                {
                  name: "Last name",
                  value: get(row, "last_name", "-")
                },
                {
                  name: "Middle name",
                  value: get(row, "middle_names", "-")
                },
                {
                  name: "Gender",
                  value: get(
                    { male: "Male", female: "Female" },
                    get(row, "gender_at_birth", "-"),
                    ""
                  )
                },
                {
                  name: "Occupation",
                  value: get(occupationsObj, `${get(row, "occupation")}`, "")
                },
                {
                  name: "Date of birth",
                  value: get(row, "date_of_birth")
                    ? moment(get(row, "date_of_birth"), "YYYY-MM-DD HH:mm").format("DD/MM/YYYY")
                    : ""
                },
                {
                  name: "Is the client a permanent UK resident?",
                  value: get(row, "data.permanent_uk_resident") ? "Yes" : "No"
                },
                {
                  name: "Is the person to be covered registered with a GP and do they have access to their medical records in English?",
                  value: get(row, "data.covered_with_a_gp_and_access_to_medical_records")
                    ? "Yes"
                    : "No"
                },
                {
                  name: "Is PMI required for a visa?",
                  value: get(row, "data.pmi_required_to_fulfil_reqs_or_visa") ? "Yes" : "No"
                },
                {
                  name: "Has the client used any tobacco products within the last 2 years?",
                  value: get(row, "data.tobacco_products_within_last_2_years") ? "Yes" : "No"
                },
                ...(get(row, "type", "-") !== "primary"
                  ? [
                      {
                        name: "Permission to add this member?",
                        value: get(row, "data.permission_to_add_member") ? "Yes" : "No"
                      }
                    ]
                  : [])
              ]
            }
          }),
          isMultiCall: true
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicants, occupationsObj])

  return null
}

export default Audit
