import moment from "moment"
import { orderBy } from "lodash"

/**
 * Used to sort the quotes by monthly premium. Quotes that are succesful take priority in terms of ordering than those that are not
 * @param {Array} quotesArray
 * @returns Array
 */
export const sortQuotesByPremium = (quotesArray = [], paymentFrequency = "MONTHLY") => {
  if (!quotesArray.length) return quotesArray

  let sortedQuotes = []

  const succesfulQuotes = quotesArray.filter(_ => _.success)
  const erroredQuotes = quotesArray.filter(_ => !_.success)

  sortedQuotes = [
    ...orderBy(
      succesfulQuotes,
      [
        q =>
          parseFloat(
            paymentFrequency === "MONTHLY" ? q.data.monthly_premium : q.data.annual_premium
          )
      ],
      ["asc"]
    ),
    ...orderBy(erroredQuotes, [q => moment(q.sentAt, "YYYY-MM-DDTHH:mmZ").unix()], ["asc"])
  ]
  return sortedQuotes
}
