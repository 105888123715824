import React, { useContext } from "react"
import { Checkbox } from "@4cplatform/elements/Forms"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { QuickQuoteContext } from "./quickQuote.context"

// Components
import { NavigationWrapper } from "./quickQuote.styles"

const StickyNav = () => {
  const { formik, setAddLeadOpen } = useContext(QuickQuoteContext)

  return (
    <NavigationWrapper data-testid="quick_quote-navigation-wrapper">
      <Checkbox
        name="send_as_email"
        label="Send as email"
        appearance="light"
        margin="0 5rem 0 0"
        formik={formik}
      />
      <Button
        onClick={() => setAddLeadOpen(true)}
        appearance="primary"
        trailingIcon="account-plus"
        name="add_new_lead"
      >
        Create new lead
      </Button>
    </NavigationWrapper>
  )
}

export default StickyNav
