import styled from "styled-components"
import { P } from "@4cplatform/elements/Typography"

export const MessageContainer = styled.div`
  margin-left: ${({ isProviderMessage }) => (isProviderMessage ? "2rem" : "0")};
  margin-right: ${({ isProviderMessage }) => (isProviderMessage ? "0" : "2rem")};
  display: inline-block;
  padding: 2rem 1rem;
  font-size: 1.2rem;
`

export const MessageBody = styled.div`
  position: relative;
  background: ${({ theme, isProviderMessage }) =>
    !isProviderMessage ? theme.purple : theme.paleGreen};
  padding: 1.8rem 1.5rem;
  border-radius: 0.3rem;
  border-bottom-left-radius: ${({ isProviderMessage }) => (isProviderMessage ? "0.3rem" : "0")};
  border-bottom-right-radius: ${({ isProviderMessage }) => (isProviderMessage ? "0" : "0.3rem")};
`

export const Messages = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.veryFaintGrey};
  padding: 1rem;
  border-top: 0.1rem solid ${({ theme }) => theme.faintGrey};
  border-bottom: 0.1rem solid ${({ theme }) => theme.faintGrey};

  height: 50rem;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const NewMessagesWrapper = styled.div`
  height: 18rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 2rem;
  div {
    width: 100%;
  }
`

export const MessageMeta = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({ isProviderMessage }) => (isProviderMessage ? "row" : "row-reverse")};
  align-items: center;
  justify-content: space-between;
`

export const MessageWrapper = styled.div`
  margin-left: ${({ isProviderMessage }) => (isProviderMessage ? "1.3rem" : "1.3rem")};
  margin-right: ${({ isProviderMessage }) => (isProviderMessage ? "1.3rem" : "1.3rem")};
`

export const Message = styled(P)`
  word-break: break-word;
`

export const AvatarWrapper = styled.div`
  flex-direction: ${({ isProviderMessage }) => (isProviderMessage ? "row-reverse" : "row")};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isProviderMessage }) => (isProviderMessage ? "flex-end" : "flex-start")};
  justify-content: center;
`

export const MessageDateWrapper = styled.div`
  transform: translateY(-1.8rem);
`

export const SvgWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: ${({ isProviderMessage }) => (isProviderMessage ? "flex-end" : "flex-start")};
  svg {
    transform: ${({ isProviderMessage }) => (isProviderMessage ? "scaleX(-1)" : "scaleX(1)")};
  }
`

export const MessageIsSending = styled.div`
  margin-left: 1rem;
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme, isProviderMessage }) =>
    !isProviderMessage ? theme.purple : theme.paleGreen};

  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-left: ${({ isProviderMessage }) => (isProviderMessage ? "3rem" : "0")};
  margin-right: ${({ isProviderMessage }) => (isProviderMessage ? "0" : "3rem")};

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -1.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: ${({ theme, isProviderMessage }) =>
      !isProviderMessage ? theme.purple : theme.paleGreen};
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 1.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: ${({ theme, isProviderMessage }) =>
      !isProviderMessage ? theme.purple : theme.paleGreen};
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: ${({ theme, isProviderMessage }) =>
        !isProviderMessage ? theme.purple : theme.paleGreen};
    }
    50%,
    100% {
      background-color: ${({ isProviderMessage }) => (!isProviderMessage ? "#ebe6ff" : "#a3c9bc")};
    }
  }
`

export const TextAreaControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NoMessagesTextWraoper = styled.div`
  position: absolute;
  bottom: 23%;
  left: 37%;
`
