import styled, { css } from "styled-components"
import { get } from "lodash"

// Components
import { Container } from "@4cplatform/elements/Atoms"
import { Skeleton as SkeletonComponent, Button } from "@4cplatform/elements/Molecules"
import { P, SmallText } from "@4cplatform/elements/Typography"

const dividerPartial = css`
  height: 0.1rem;
  background-color: ${({ theme }) => get(theme, "faintGrey", "lightgray")};
`

export const QuoteCompareContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const QuotesContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
`

export const QuotesContainer = styled.div`
  width: calc(100% - 7rem);
  height: 62.5rem;
  overflow-x: scroll;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  align-items: center;
  padding: 0 0.2rem;
`

export const QuotesContainerSkeleton = styled(SkeletonComponent)`
  width: 100%;
  height: 56.5rem;
  border-radius: 0.4rem;
`

export const QuoteWrapper = styled.div`
  min-width: 30rem;
  max-width: 30rem;
  height: 56.5rem;
  border-radius: 0.4rem;
  box-sizing: border-box;
  outline: 0.1rem solid ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "lightgray")};
  ${({ isFirst, isLast }) => {
    if (isFirst) {
      return css`
        margin-right: 1rem;
      `
    }

    if (isLast) {
      return css`
        margin-left: 1rem;
      `
    }

    return css`
      margin: 0 1rem;
    `
  }};

  ${({ theme, isSelected, hasError }) => {
    if (isSelected) {
      return css`
        outline: 0.2rem solid ${get(theme, "green", "green")};
      `
    }

    if (hasError) {
      return css`
        outline: 0.2rem solid ${get(theme, "red", "red")};
      `
    }
  }}
`

export const QuoteHeader = styled.div`
  width: 100%;
  height: 10rem;
  border-bottom: 0.1rem solid
    ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "lightgray")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem;
`

export const ProviderInfoWrapper = styled.div`
  cursor: pointer;
`

export const LogoSkeleton = styled(SkeletonComponent)`
  margin-right: 1rem;

  ${({ loadingMargin }) => {
    if (loadingMargin) {
      return css`
        margin: ${loadingMargin};
      `
    }
  }}
`

export const Logo = styled.img`
  max-width: 100%;
  object-fit: contain;
  background: ${({ theme, src }) =>
    !src || src.includes("https://") ? "transparent" : get(theme, "faintGrey", "lightgray")};
  margin: 0 1rem;
  ${({ size }) => {
    if (size) {
      return css`
        width: ${size};
        height: ${size};
      `
    }
  }}
`

export const QuoteProviderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const QuoteSection = styled.div`
  width: 100%;
  height: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
`

export const QuoteSectionDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  ${dividerPartial}
`

export const QuoteFooter = styled.div`
  width: 100%;
  height: 10rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 0.1rem solid ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "lightgray")};
`

export const QuotePremiumHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const QuotePremiumInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const QuoteErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 16.1rem);
  align-items: center;
  padding: 2rem;
`

export const QuoteErrorText = styled(SmallText)`
  width: 100%;
  margin: 0;
  word-break: break-word;
`

export const QuoteSelect = styled.div`
  display: flex;
  height: 6.1rem;
  align-items: center;
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 0 2rem;

  label {
    transition: none !important;
    margin: 0;
  }

  div[role="checkbox"] {
    margin-right: 1rem;
  }

  ${({ theme, isSelected, hasError }) => {
    if (isSelected) {
      return css`
        padding-right: 0;
        background-color: ${get(theme, "green", "green")};
      `
    }

    if (hasError) {
      return css`
        padding-right: 0;
        background-color: ${get(theme, "red", "red")};
      `
    }
  }}
`

export const ProgressIndicatorContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProgressBarContainer = styled.div`
  width: 80%;
  height: 1rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => get(theme, "faintGrey", "lightgray")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const ProgressBar = styled.div`
  width: ${({ progress }) => progress}%;
  height: 100%;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => get(theme, "blue", "blue")};
`
export const SelectedCount = styled(P)`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`

export const ErrorMessage = styled(P)`
  padding-left: 4.5rem;
  margin: 0;
  color: ${({ theme }) => get(theme, "red", "red")};
`
export const PanelBody = styled.div`
  display: grid;
  margin: 2rem;
  height: 85%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "row1"
    "row2";
`
export const OptionsWrapper = styled.div`
  grid-area: row1;
`

export const PanelFooter = styled.div`
  display: flex;
  background-color: ${({ theme }) => get(theme, "tints.secondary.darkBlue.t10", "blue")};
  padding: 3rem;
  justify-content: space-between;
  align-items: center;
  grid-area: row2;
`

const modalPartial = css`
  padding: 2rem 3rem;
`

export const ProviderInfoModalHeader = styled.div`
  ${modalPartial}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => get(theme, "veryFaintGrey", "white")};
`

export const ProviderInfoModalHeaderContent = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ProviderInfoModalBody = styled.div`
  ${modalPartial}
`

export const ProviderInfoModalFooter = styled.div`
  padding: 0 3rem 2rem;
  display: flex;
  flex-direction: row-reverse;
`

export const SectionWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => get(theme, "faintGrey")};
  margin: ${({ isLast }) => (isLast ? "0" : "1rem 0")};
  padding: ${({ padding }) => padding || "0"};
`

export const CustomFieldWrapper = styled.div`
  margin: 0 0 2rem;
`

export const IndicatorWrapper = styled.div`
  display: flex;
`
export const HelperWrapper = styled.div`
  p {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
`

export const RegenerateButton = styled(Button)`
  padding: 0 0 0 1rem;
`
