import React, { useContext, useEffect, useRef, useState } from "react"
import { isEmpty } from "lodash"
import { P } from "@4cplatform/elements/Typography"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { HEADER_HEIGHT_OFFSET } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/quotationSummary.helpers"

// Components
import { QuotationSummaryTableWrapper, TableMainWrapper } from "./quotationSummaryTable.styles"
import QuotationSummaryTableAside from "./quotationSummaryTable.aside"
import QuotationSummaryTableHeader from "./quotationSummaryTable.header"
import QuotationSummaryTableHeaderPlaceholder from "./quotationSummaryTable.header.placeholder"
import QuotationSummaryTableBody from "./quotationSummaryTable.body"
import { QuotationSummaryContext } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/context/quotationSummary.context"

const QuotationSummaryTable = () => {
  const { quotes, isJourneyLoading, journeyOnClickPrevious } = useContext(QuotationSummaryContext)
  const [isHeaderFixed, setHeaderFixed] = useState(false)
  const [pageGutterWidth, setPageGutterWidth] = useState(0)
  const [pageScrolledY, setPageScrolledY] = useState(0)
  const tableWrapperRef = useRef(null)
  const tableHeaderRef = useRef(null)
  const tableHeaderPlaceholderRef = useRef(null)
  const tableMainRef = useRef(null)
  const tableAsideHeaderRef = useRef(null)
  const firstRender = useRef(true)
  const isMouseInsideTableHeaderRef = useRef(false)

  const resetHeaderHorizontalOffset = () => (tableHeaderRef.current.style = "")

  const fixTableHeader = () => setHeaderFixed(true)

  const unfixTableHeader = () => {
    setHeaderFixed(false)
    resetHeaderHorizontalOffset()
  }

  // updates the horizontal positioning of the fixed header to match the scroll offset of the table body
  const handleTableBodyHorizontalScroll = e => {
    if (tableHeaderPlaceholderRef.current && !isMouseInsideTableHeaderRef.current) {
      tableHeaderRef.current.scroll(e.target?.scrollLeft, 0)
    }
  }

  // updates the horizontal positioning of the table body to match the scroll offset of the fixed header
  const handleTableHeaderHorizontalScroll = e => {
    if (tableHeaderPlaceholderRef.current && isMouseInsideTableHeaderRef.current) {
      tableMainRef.current.scroll(e.target?.scrollLeft, 0)
    }
  }

  const handlePageScroll = () => {
    const tableMainRefCurrent = tableMainRef?.current

    if (
      (tableHeaderRef.current?.getBoundingClientRect().top <= HEADER_HEIGHT_OFFSET &&
        !tableHeaderPlaceholderRef.current) ||
      (tableHeaderPlaceholderRef.current &&
        tableHeaderPlaceholderRef.current?.getBoundingClientRect().top > HEADER_HEIGHT_OFFSET)
    )
      setPageScrolledY(tableMainRefCurrent?.getBoundingClientRect().top)
  }

  // fires on initial render to set scroll event listener
  useEffect(() => {
    const tableWrapperRefCurrent = tableWrapperRef?.current
    const tableMainRefCurrent = tableMainRef?.current
    const tableHeaderRefCurrent = tableHeaderRef?.current
    const mainElementTag = tableWrapperRefCurrent?.closest("main")

    // Event listener for handling vertical scroll for the page
    if (mainElementTag) mainElementTag.addEventListener("scroll", handlePageScroll, true)

    function handleWindowResize() {
      // This is used to set the prop pageGutterWidth that is passed into QuotationSummaryTableHeaderPlaceholder
      setPageGutterWidth(window.innerWidth - tableWrapperRefCurrent?.getBoundingClientRect().right)
    }

    handleWindowResize()

    // Event listener for handling window resize
    window.addEventListener("resize", handleWindowResize)

    function checkIfMouseInsideTableHeader(event) {
      // To verify whether the scrolling element corresponds to the table header or the table body
      isMouseInsideTableHeaderRef.current = event
        ? !!tableHeaderRef?.current?.contains(event.target)
        : false
    }
    checkIfMouseInsideTableHeader()
    window.addEventListener("mousemove", checkIfMouseInsideTableHeader)

    // Event listener for handling horizontal scroll within table body
    if (tableMainRefCurrent)
      tableMainRefCurrent.addEventListener("scroll", handleTableBodyHorizontalScroll)

    if (tableHeaderRefCurrent)
      tableHeaderRefCurrent.addEventListener("scroll", handleTableHeaderHorizontalScroll)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
      window.removeEventListener("mousemove", checkIfMouseInsideTableHeader)

      if (mainElementTag) mainElementTag.removeEventListener("scroll", handlePageScroll)

      if (tableHeaderRefCurrent)
        tableHeaderRefCurrent.removeEventListener("scroll", handleTableHeaderHorizontalScroll)

      if (tableMainRefCurrent)
        tableMainRefCurrent.removeEventListener("scroll", handleTableBodyHorizontalScroll)
    }
  }, [])

  // Fires everytime the page is scrolled vertically
  useEffect(() => {
    if (firstRender?.current) return

    if (
      isHeaderFixed &&
      tableHeaderPlaceholderRef.current?.getBoundingClientRect().top > HEADER_HEIGHT_OFFSET
    )
      return unfixTableHeader()

    if (tableHeaderRef.current?.getBoundingClientRect().top <= HEADER_HEIGHT_OFFSET)
      fixTableHeader()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageScrolledY])

  useEffect(() => (firstRender.current = false), [])

  // Fires everytime the header is fixed/unfixed
  useEffect(() => {
    if (firstRender?.current) return

    if (isHeaderFixed && !isEmpty(quotes)) {
      tableHeaderRef.current?.scroll(tableMainRef.current?.scrollLeft, 0)
      tableHeaderRef.current.style.maxWidth = `${tableMainRef.current?.clientWidth}px`

      setTimeout(() => {
        tableHeaderRef.current?.scroll(tableMainRef.current?.scrollLeft, 0)
      }, 50)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeaderFixed, quotes])

  // Update CSS variables with the height of TableMainWrapper scrollbar
  useEffect(() => {
    function updateCssVariables() {
      const VERTICAL_BORDER_WIDTH = 2
      let scrollbarHeight = 0

      if (tableMainRef?.current) {
        scrollbarHeight =
          tableMainRef.current.offsetHeight -
          tableMainRef.current.scrollHeight -
          VERTICAL_BORDER_WIDTH
      }

      // Set the CSS variable for the scrollbar height
      document
        .querySelector("html")
        .style.setProperty("--qs-scrollbar-height", `${scrollbarHeight}px`)
    }

    updateCssVariables()

    window.addEventListener("resize", updateCssVariables)

    return () => {
      window.removeEventListener("resize", updateCssVariables)
    }
  }, [tableMainRef])

  if (isEmpty(quotes) && !isJourneyLoading)
    return (
      <>
        <P>
          No quotes available.
          <br />
          Please return to the <strong>Quote comparison</strong> page for new quotations.
        </P>
        <Button
          leadingIcon="chevron-left"
          onClick={journeyOnClickPrevious}
          name="back_to_qc"
          isLoading={isJourneyLoading}
          width="fit-content"
        >
          Quote comparison
        </Button>
      </>
    )

  return (
    <QuotationSummaryTableWrapper
      ref={tableWrapperRef}
      isHeaderFixed={isHeaderFixed}
      data-testid="summary_quotation_table"
    >
      <QuotationSummaryTableAside ref={tableAsideHeaderRef} isHeaderFixed={isHeaderFixed} />
      <TableMainWrapper ref={tableMainRef} isHeaderFixed={isHeaderFixed}>
        {isHeaderFixed && (
          <QuotationSummaryTableHeaderPlaceholder
            ref={tableHeaderPlaceholderRef}
            isHeaderFixed={isHeaderFixed}
            pageGutterWidth={pageGutterWidth}
          />
        )}
        <QuotationSummaryTableHeader ref={tableHeaderRef} isHeaderFixed={isHeaderFixed} />
        <QuotationSummaryTableBody />
      </TableMainWrapper>
    </QuotationSummaryTableWrapper>
  )
}

export default QuotationSummaryTable
