import React from "react"
import { Container } from "@4cplatform/elements/Atoms"
import { Helmet } from "react-helmet-async"
import { H1 } from "@4cplatform/elements/Typography"

// Helpers
import { DASHBOARD } from "../../config/pages"

// Components
import { Breadcrumbs } from "../../UI/Molecules"
import QuickQuoteLogProvider from "./context/quickQuoteLog.provider"
import { QuickQuoteLogAdmin } from "../../UI/Templates"

const QuickQuoteLog = () => (
  <>
    <Helmet>
      <title>Quick Quote log</title>
    </Helmet>
    <QuickQuoteLogProvider>
      <Container isPageContainer width="80%">
        <Breadcrumbs
          trail={[{ label: "Dashboard", link: DASHBOARD.path }, { label: "Quick Quote log" }]}
        />
        <H1 margin="0 0 6rem">Quick Quote log</H1>
        <QuickQuoteLogAdmin />
      </Container>
    </QuickQuoteLogProvider>
  </>
)

export default QuickQuoteLog
