import { get } from "lodash"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"
import { boolean } from "yup"
import { Toggle } from "@4cplatform/elements/Forms"

// Components
import FileUploader from "../../../../../../FileUploader"
import SendDocumentButton from "../../components/SendDocumentButton/sendDocumentButton"
import Audit from "./Audit"

export const config = data => ({
  title: "Company Third Party Payer",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          componentProps: {
            isDocumentSent: !!get(data, "page.data.document_notification_sent")
          },
          skipDataMap: true
        },
        {
          key: "compliance_note",
          component: ComplianceNote,
          condition: {
            type: "data",
            path: "journey.selected_quote.sent_to_client",
            value: false
          },
          componentProps: {
            children: (
              <>
                <P margin="0">
                  Please return to the Quotation Summary page and select Push to portal for this
                  quote before progressing with this journey.
                </P>
              </>
            ),
            type: "warning"
          },
          skipDataMap: true
        },
        {
          key: "document_notification_sent",
          component: SendDocumentButton,
          componentProps: {
            isDocumentSent: !!get(data, "page.data.document_notification_sent"),
            apiEndpointPath: "company-third-party-payer-notify-client",
            documentType: "company_third_party_payer"
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "application_form_received_from_client",
          component: Toggle,
          initialValue: get(data, "page.data.application_form_received_from_client", false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label: "Company Third Party Payer form received from client?",
          componentProps: {
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true,
            labelWidth: "39.5rem"
          }
        },
        {
          key: "form_filled_out_correctly",
          component: Toggle,
          initialValue: get(data, "page.data.form_filled_out_correctly", false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label: "Form filled out correctly?",
          componentProps: {
            errorPrefix: "none",

            isHorizontal: true,
            isRequired: true,
            labelWidth: "39.5rem"
          }
        }
      ]
    },
    {
      key: "section_3",
      components: [
        {
          key: "company_third_party_payer_uploader",
          component: FileUploader,
          componentProps: {
            validFileTypes: ".zip",
            fileType: "COMPANY_THIRD_PARTY_PAYER",
            name: "company_third_party_payer_files",
            maxFileSize: 30,
            canAllowMultiple: true,
            fileSelectLabel:
              "The completed Third Party Payer form pages should now be scanned and the resulting images zipped into an archive for upload",
            apiEndpointPath: "bupa-company-third-party-payer-files"
          },
          skipDataMap: true
        }
      ]
    }
  ]
})
