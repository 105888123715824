export const TRANSLATION_NAMESPACES = [
  "agencyCodes",
  "authentication",
  "clients",
  "common",
  "commissionRates",
  "dealCodes",
  "journeys",
  "landingPage",
  "leads",
  "messages",
  "networks",
  "notes",
  "notifications",
  "organisations",
  "policies",
  "products",
  "providers",
  "quotes",
  "roles",
  "system",
  "users"
]
