import React, { useContext, useState, useEffect } from "react"
import { Select } from "@4cplatform/elements/Forms"
import { get } from "lodash"
import { string, object } from "yup"
import { H4, P } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { v4 as uuid } from "uuid"
import { JourneyContext } from "../../../../../../journey.context"

export const styleSwitchOptions = {
  MORI: "Moratorium",
  FMU: "Full Medical Underwriting",
  CMORI: "Continued Moratorium",
  CME: "Continued Medical Exclusions",
  CPME: "Continued Personal Medical Exclusions",
  MHD: "Medical History Disregarded"
}

const underwritingTypeNewList = [
  {
    uid: uuid(),
    value: "MORI",
    label: "Moratorium"
  },
  {
    uid: uuid(),
    value: "FMU",
    label: "Full Medical Underwriting"
  }
]

const UnderwritingStyleSelects = () => {
  const { data, formik, addToFormikValidationSchema } = useContext(JourneyContext)
  const isLocked = get(data, "journey.locked", false)
  const has0YearsCovered = get(data, "payload.has_applicant_with_zero_year_covered", false)
  const hasAccessToAgencyCode = get(data, "page.conditionals.has_access_to_agency_codes", false)
  const [recommendedStyleSelectConfig, setRecommendedStyleSelectConfig] = useState({
    value: get(data, "page.data.recommended_style", ""),
    options: null,
    helperText: "",
    isDisabled: false
  })

  const [complianceNoteContent, setComplianceNoteContent] = useState({
    title: null,
    text: null
  })

  const cpCurrentPolicy = get(data, "page.data.cp_current_policy", false)
  const cpUnderwriting = get(data, "page.data.cp_underwriting", "")
  const recommendedStyle = get(formik, "values.recommended_style", "")
  const recommendedUnderwriting = get(formik, "values.recommended_underwriting", "")

  useEffect(() => {
    // setting formik values and validationSchema
    if (
      get(formik, "values.recommended_underwriting", undefined) === undefined ||
      get(formik, "values.recommended_style", undefined) === undefined
    ) {
      // Setting recommended_style to NEW if current policy doesnt exist
      const recommendedStyleValue =
        cpCurrentPolicy && cpUnderwriting !== "Don't know"
          ? get(data, "page.data.recommended_style", "")
          : "NEW"
      formik.setFieldValue("recommended_style", recommendedStyleValue)
      formik.setFieldValue(
        "recommended_underwriting",
        get(data, "page.data.recommended_underwriting", "")
      )
      addToFormikValidationSchema(
        object({ recommended_style: string().required("MISSING_REQUIRED_FIELD") }).concat(
          object({ recommended_underwriting: string().required("MISSING_REQUIRED_FIELD") })
        )
      )
    }

    // if user has a current policy
    if (
      cpCurrentPolicy &&
      cpUnderwriting !== "Don't know" &&
      hasAccessToAgencyCode &&
      !has0YearsCovered
    ) {
      setRecommendedStyleSelectConfig({
        ...recommendedStyleSelectConfig,
        value: recommendedStyle,
        isDisabled: false,
        options: (
          <>
            <option key={uuid()} value="NEW">
              New
            </option>
            <option key={uuid()} value="SWITCH">
              Switch
            </option>
          </>
        )
      })

      if (recommendedStyle === "NEW") {
        setComplianceNoteContent({
          title: null,
          text: null
        })
      }

      if (recommendedStyle === "SWITCH") {
        let complianceNoteContentUpdate
        switch (cpUnderwriting) {
          case "CMORI": {
            complianceNoteContentUpdate = {
              title: "Underwriting Changed",
              text: "The underwriting type has been automatically set to CMORI because you have selected to Switch from a current CMORI policy."
            }
            break
          }
          case "CME": {
            complianceNoteContentUpdate = {
              title: "Underwriting Changed",
              text: "The underwriting type has been automatically set to CME because you have selected to Switch from a current CME policy."
            }
            break
          }
          case "CPME": {
            complianceNoteContentUpdate = {
              title: "Underwriting Changed",
              text: "The underwriting type has been automatically set to CPME because you have selected to Switch from a current CPME policy."
            }
            break
          }
          case "MHD": {
            complianceNoteContentUpdate = {
              title: "Underwriting Set Automatically",
              text: "The underwriting type has been automatically set to MHD because you have selected to Switch from a current MHD policy."
            }
            break
          }
          case "MORI": {
            complianceNoteContentUpdate = {
              title: "Underwriting Set Automatically",
              text: "The underwriting type has been automatically set to Moratorium because you have selected to Switch from a current Moratorium policy."
            }
            break
          }
          case "FMU": {
            complianceNoteContentUpdate = {
              title: "Underwriting Set Automatically",
              text: "The underwriting type has been automatically set to FMU because you have selected to Switch from a current FMU policy."
            }
            break
          }
          default:
            break
        }
        formik.setFieldValue("recommended_underwriting", cpUnderwriting)
        setComplianceNoteContent(complianceNoteContentUpdate)
      }
    } else {
      // If user doesnt have current policy
      formik.setFieldValue("recommended_style", "NEW")
      setRecommendedStyleSelectConfig({
        ...recommendedStyleSelectConfig,
        value: "NEW",
        isDisabled: true,
        options: (
          <option key={uuid()} value="NEW">
            New
          </option>
        ),
        helperText:
          "If you are looking to quote for a switch policy you must complete the relevant information regarding your clients existing policy in order for a price to be calculated."
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  useEffect(() => {
    if (
      recommendedUnderwriting &&
      ((recommendedStyle === "NEW" &&
        !underwritingTypeNewList.find(type => type.value === recommendedUnderwriting)) ||
        (recommendedStyle === "SWITCH" && !get(styleSwitchOptions, recommendedUnderwriting)))
    ) {
      formik.setFieldValue("recommended_underwriting", "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedUnderwriting])

  return (
    <>
      <Select
        name="recommended_style"
        label="Underwriting style"
        required
        value={recommendedStyleSelectConfig.value}
        formik={formik}
        onChange={val => {
          if (val === "NEW") {
            formik.setFieldValue("recommended_underwriting", "")
          }
          formik.setFieldValue("recommended_style", val)
        }}
        isDisabled={recommendedStyleSelectConfig.isDisabled || isLocked || has0YearsCovered}
        helperText={recommendedStyleSelectConfig.helperText}
        helperTitle="Underwriting style"
      >
        <option key={uuid()} value="">
          Please select
        </option>
        {recommendedStyleSelectConfig.options}
      </Select>
      {has0YearsCovered && (
        <ComplianceNote type="info" margin="0 0 3rem">
          <H4 margin="0 0 1rem">Note</H4>
          <P margin="0">
            Switch is not available for this policy because at least one member is not on the
            existing policy or has not been covered for a full year
          </P>
        </ComplianceNote>
      )}
      <Select
        name="recommended_underwriting"
        label="Underwriting type"
        required
        value={recommendedUnderwriting}
        formik={formik}
        onChange={val => formik.setFieldValue("recommended_underwriting", val)}
        isDisabled={recommendedStyle === "SWITCH" || recommendedStyle === "" || isLocked}
      >
        <option key={uuid()} value="">
          Please select
        </option>
        {recommendedStyle === "NEW" && (
          <>
            {underwritingTypeNewList.map(type => (
              <option key={type.uid} value={type.value}>
                {type.label}
              </option>
            ))}
          </>
        )}
        {recommendedStyle === "SWITCH" &&
          Object.keys(styleSwitchOptions).map(option => (
            <option key={uuid()} value={option}>
              {styleSwitchOptions[option]}
            </option>
          ))}
      </Select>

      {complianceNoteContent.text && (
        <ComplianceNote type="info" margin="0 0 3rem">
          <H4 margin="0 0 1rem">{complianceNoteContent.title}</H4>
          <P margin="0">{complianceNoteContent.text}</P>
        </ComplianceNote>
      )}
    </>
  )
}
export default UnderwritingStyleSelects
