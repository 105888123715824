/* eslint-disable no-unused-vars */
import React, { useContext } from "react"
import { get } from "lodash"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"

// Components
import RequestAcceptance from "../components/RequestAcceptance"

const AgencyCodeRequestsPanelBody = () => (
  <PanelBody>
    <RequestAcceptance />
  </PanelBody>
)

export default AgencyCodeRequestsPanelBody
