import React, { useContext, useEffect } from "react"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"
import { PageContext } from "@4cplatform/elements/Organisms"

// Components
import { ClientsContext } from "./clients.context"
import ViewClient from "./clients.panel.view"
import EditClient from "./clients.panel.edit.form"
import ClientsPoliciesTab from "./tabs/policies"
import ClientsQuotesTab from "./tabs/quotes"
import ClientsNotesTab from "./tabs/notes"

const ClientsPanel = () => {
  const { panelBodyContent, setPanelBodyContent, refetchPolicies, refetchNotes, refetchQuotes } =
    useContext(ClientsContext)
  const { panelStatusControls, setPanelStatus } = useContext(PageContext)

  const getWidePanelBodyContent = () => {
    switch (panelBodyContent) {
      case "Policies":
        return <ClientsPoliciesTab />
      case "Quotes":
        return <ClientsQuotesTab />
      case "Edit":
      default:
        return <EditClient />
    }
  }

  const getNarrowPanelBodyContent = () => {
    switch (panelBodyContent) {
      case "Notes":
        return <ClientsNotesTab />
      case "Journey":
      default:
        return <ViewClient />
    }
  }

  useEffect(() => {
    if (
      panelStatusControls.panelStatus === "open" &&
      (panelBodyContent === "Policies" ||
        panelBodyContent === "Quotes" ||
        panelBodyContent === "Edit")
    ) {
      setPanelStatus("wide")
    }

    if (
      panelStatusControls.panelStatus === "wide" &&
      (panelBodyContent === "Journey" || panelBodyContent === "Notes")
    ) {
      setPanelStatus("open")
    }
  }, [panelStatusControls.panelStatus, panelBodyContent, setPanelStatus])

  useEffect(() => {
    if (panelStatusControls.panelStatus === "open" || panelStatusControls.panelStatus === "wide") {
      switch (panelBodyContent) {
        case "Quotes":
          return refetchQuotes()
        case "Notes":
          return refetchNotes()
        case "Policies":
          return refetchPolicies()
        default:
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelStatusControls.panelStatus, panelBodyContent])

  return (
    <FlyOutPanel
      body={() => getNarrowPanelBodyContent()}
      wideBody={() => getWidePanelBodyContent()}
      name="clients_panel"
      onClose={() => {
        if (
          (panelBodyContent !== "Journey" || panelBodyContent !== "Notes") &&
          panelStatusControls.panelStatus === "wide"
        ) {
          setPanelStatus("open")
          setPanelBodyContent("Journey")
        }

        if (panelStatusControls.panelStatus === "open") {
          setPanelStatus("closed")
          setPanelBodyContent("Journey")
        }
      }}
    />
  )
}
export default ClientsPanel
