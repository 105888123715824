import React, { useEffect } from "react"
import moment from "moment"
import { get } from "lodash"
import { object, number, string } from "yup"
import { Select, TextArea, DatePicker } from "@4cplatform/elements/Forms"
import { DisclosureNotesContext } from "../../../../../../DisclosureNotes/disclosureNotes.context"
import { JourneyContext } from "../../../../../../../journey.context"

const NoteFormTemplate = () => {
  const {
    applicants,
    disclosureNoteFormik,
    setNoteValidationSchema,
    setAddEditModal,
    addEditModal,
    productName
  } = React.useContext(DisclosureNotesContext)
  const { data } = React.useContext(JourneyContext)
  const isLocked = get(data, "journey.locked", false)

  useEffect(() => {
    setNoteValidationSchema(
      object({
        journey_applicant_id: number().min(0).required("PLEASE_SPECIFY_AN_APPLICANT"),
        date_illness_began: string().required("MISSING_REQUIRED_FIELD"),
        date_illness_ended: string().nullable(),
        present_state_of_health: string().nullable(),
        ...(productName === "HEALTHFORYOU"
          ? {
              illness_disability: string().required("MISSING_REQUIRED_FIELD"),
              treatment_received: string().required("MISSING_REQUIRED_FIELD")
            }
          : {
              illness_disability_treatment_received: string().required("MISSING_REQUIRED_FIELD")
            })
      })
    )
    if (addEditModal.type === "new") {
      setAddEditModal({
        ...addEditModal,
        noteInitialValues: {
          journey_applicant_id: "",
          date_illness_began: "",
          date_illness_ended: "",
          present_state_of_health: "",
          ...(productName === "HEALTHFORYOU"
            ? {
                illness_disability: "",
                treatment_received: ""
              }
            : {
                illness_disability_treatment_received: ""
              })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = {
    ...disclosureNoteFormik
  }
  const getConsultationMinDate = React.useMemo(() => {
    if (formik.values && formik.values.journey_applicant_id) {
      const applicantData = applicants.find(
        applicant => applicant.id === JSON.parse(formik.values.journey_applicant_id)
      )
      return moment(applicantData.date_of_birth).format("DD/MM/YYYY")
    }
    return ""
  }, [applicants, formik.values])

  const getConsultationMaxDate = React.useMemo(() => {
    if (formik.values && formik.values.date_illness_ended) {
      return moment(formik.values.date_illness_ended).subtract(1, "days").format("DD/MM/YYYY")
    }

    return moment().subtract(1, "days").format("DD/MM/YYYY")
  }, [formik.values])

  const getTreatementMinDate = React.useMemo(() => {
    if (formik.values && formik.values.date_illness_began) {
      return moment(formik.values.date_illness_began).add(1, "days").format("DD/MM/YYYY")
    }
    return ""
  }, [formik.values])

  const getTreatementMaxDate = React.useMemo(
    () => moment().subtract(1, "days").format("DD/MM/YYYY"),
    []
  )

  const disableTreatementMaxDate = React.useMemo(() => {
    if (formik.values && formik.values.date_illness_began) {
      return moment(formik.values.date_illness_began).add(1, "days").isSame(moment().startOf("day"))
    }

    return true
  }, [formik.values])

  return (
    <>
      <Select
        name="journey_applicant_id"
        label="Select member:"
        formik={formik}
        isRequired
        isDisabled={isLocked}
        errorPrefix="none"
      >
        <option value="">Select applicant</option>
        {applicants.map(applicant => (
          <option value={get(applicant, "id")} key={get(applicant, "id")}>
            {get(applicant, "first_name")} {get(applicant, "last_name")}
          </option>
        ))}
      </Select>

      {productName === "HEALTHFORYOU" ? (
        <>
          <TextArea
            name="illness_disability"
            formik={formik}
            label="Nature of illness/disability:"
            isRequired
            isDisabled={isLocked}
          />
          <TextArea
            name="treatment_received"
            formik={formik}
            label="Treatment received:"
            isRequired
            isDisabled={isLocked}
          />
        </>
      ) : (
        <TextArea
          name="illness_disability_treatment_received"
          formik={formik}
          label="Nature of illness/disability and treatment received:"
          isRequired
          isDisabled={isLocked}
        />
      )}
      <DatePicker
        name="date_illness_began"
        formik={formik}
        label="When did the illness/disability begin?"
        dateRangeMin={getConsultationMinDate}
        dateRangeMax={getConsultationMaxDate}
        isRequired
        isDisabled={isLocked}
      />
      <DatePicker
        name="date_illness_ended"
        formik={formik}
        label="When did the illness/disability end?"
        dateRangeMin={getTreatementMinDate}
        dateRangeMax={getTreatementMaxDate}
        isDisabled={disableTreatementMaxDate || isLocked}
      />
      <TextArea
        name="present_state_of_health"
        formik={formik}
        margin="0 0 2rem 0"
        label="Present state of health in this respect:"
        isDisabled={isLocked}
      />
    </>
  )
}

export default NoteFormTemplate
