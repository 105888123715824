import React from "react"

// Components
import JourneysPanelHeader from "./journeys.panel.header"
import JourneysPanelBody from "./journeys.panel.body"

const JourneyView = () => (
  <>
    <JourneysPanelHeader />
    <JourneysPanelBody />
  </>
)

export default JourneyView
