import React, { useContext } from "react"
import { P } from "@4cplatform/elements/Typography"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { QuotationSummaryContext } from "./context/quotationSummary.context"

const DeleteConfirmationModal = () => {
  const { deleteQuoteModal, deleteQuoteLoading, setDeleteQuoteModal, onDeleteQuote } =
    useContext(QuotationSummaryContext)

  return deleteQuoteModal?.show ? (
    <ConfirmationModal
      onConfirm={onDeleteQuote}
      confirmAppearance="error"
      cancelAppearance="errorGhost"
      isLoadingConfirm={deleteQuoteLoading}
      onClose={() => setDeleteQuoteModal(false)}
    >
      <P>Are you sure you want to delete this quote?</P>
    </ConfirmationModal>
  ) : null
}

export default DeleteConfirmationModal
