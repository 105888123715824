import React, { useState, useContext } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useFormik } from "formik"
import { pickBy } from "lodash"
import { usePost } from "@4cplatform/elements/Api"
import { Column, IconWithText, Row } from "@4cplatform/elements/Atoms"
import { A, H4, P } from "@4cplatform/elements/Typography"
import { TextArea, Input } from "@4cplatform/elements/Forms"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { ConfigContext } from "@4cplatform/elements/Config"
import { createContactModel as validationSchema } from "./contact.helpers"

// Components
import { PanelContent } from "../../components"
import { ContactPanel as Panel } from "../../landingPage.styles"
import {
  Button,
  Form,
  SuccessMessage,
  SuccessInnerWrapper,
  RecaptchaErrorMessage
} from "./contact.styles"

const lineHeight = "1.6"
const textColour = colours.white

const panelProps = {
  title: {
    text: "Contact the team today",
    colour: colours.white
  },
  subtitle: {
    text: "Our team is happy to answer your questions. Fill out the form and we'll be in touch as soon as possible",
    colour: colours.white
  },
  button: {
    text: "Back to top",
    href: "#page-content",
    trailingIcon: "chevron-up"
  },
  hrColour: colours.white
}

const ContactPanel = () => {
  const [successfulFormSubmission, setSuccessfulFormSubmission] = useState(false)
  const t = useTranslations()
  const { GOOGLE_RECAPTCHA_KEY } = useContext(ConfigContext)

  const [onSubmit, { loading }] = usePost({
    endpoint: "/dmz/landing-page-contact-form",
    onCompleted: () => {
      setSuccessfulFormSubmission(true)
    },
    onError: () => {
      setSuccessfulFormSubmission(false)
      addAlert({
        type: "error",
        message: t("LANDING_PAGE_CONTACT_SUBMIT_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  const createContactFormik = useFormik({
    initialValues: {
      name: "",
      email_address: "",
      phone_number: "",
      comment: "",
      recaptcha: null
    },
    validationSchema,
    onSubmit: body =>
      onSubmit({
        body: pickBy(body, (v, k) => k !== "recaptcha" && v !== "" && v !== null && v !== undefined)
      })
  })

  const formik = { ...createContactFormik, validationSchema }

  return (
    <Panel id="contact" data-testid="contact-panel">
      <PanelContent {...panelProps}>
        <Row justifyContent="space-between" alignItems="center">
          <Column phone={12} small={12} tablet={12} medium={12} large={4} xLarge={4} xxLarge={4}>
            <P colour={textColour} lineHeight={lineHeight}>
              <b>4C Platform.com Ltd</b>
              <br />
              Unit 1, Hercules Court
              <br />
              Lakeside Business Park
              <br />
              Broadway Lane
              <br />
              Cirencester
              <br />
              Gloucestershire
              <br />
              GL7 5XL
            </P>
            <P colour={textColour} lineHeight={lineHeight} margin="0">
              4C Platform.com Limited is authorised and regulated by the Financial Conduct Authority
              No: 827940 and is registered in England and Wales No: 07377957. Registered address
              office is 5 Hercules Court Lakeside Business Park, Broadway Lane, South Cerney,
              Cirencester, Gloucestershire, England, GL7 5XZ.
              <br />
              Email:{" "}
              <A
                href="mailto:support@4cplatform.com"
                appearance="light"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@4cplatform.com
              </A>{" "}
              | Tel:{" "}
              <A
                href="tel:+441285705900"
                appearance="light"
                target="_blank"
                rel="noopener noreferrer"
              >
                01285 705900
              </A>
            </P>
            <P colour={textColour} lineHeight={lineHeight} margin="2rem 0 0">
              &copy; 2016 - {new Date().getFullYear()} 4C Platform.com Limited. All rights reserved.
              <br />
              <A href="/terms-conditions" appearance="light" target="_blank">
                Terms &amp; Conditions
              </A>{" "}
              |{" "}
              <A href="/privacy-policy" appearance="light" target="_blank">
                Privacy Policy
              </A>
            </P>
          </Column>
          <Column phone={12} small={12} tablet={12} medium={12} large={6} xLarge={6} xxLarge={6}>
            {successfulFormSubmission ? (
              <SuccessMessage>
                <IconWithText icon="check" iconColour={colours.green} margin="0" iconSize="4rem">
                  <SuccessInnerWrapper>
                    <H4 margin="0">Thank you</H4>
                    <P margin="0">Your enquiry has been sent and we will be in touch shortly</P>
                  </SuccessInnerWrapper>
                </IconWithText>
              </SuccessMessage>
            ) : (
              <Form data-testid="contact-form">
                <Input name="name" formik={formik} margin="0" label="Name" />
                <Input name="email_address" formik={formik} margin="0" label="Email" />
                <Input label="Phone" name="phone_number" formik={formik} />
                <TextArea name="comment" formik={formik} margin="0" label="Comments" />
                <>
                  <ReCAPTCHA
                    hl="en-GB"
                    sitekey={GOOGLE_RECAPTCHA_KEY}
                    onChange={token => formik.setFieldValue("recaptcha", token)}
                    onErrored={() =>
                      addAlert({
                        type: "error",
                        message: t("RECAPTCHA_SUBMIT_ERROR"),
                        dismissible: true,
                        timeout: 5
                      })
                    }
                  />
                  <RecaptchaErrorMessage
                    name="recaptcha"
                    prefix="label"
                    label="reCAPTCHA"
                    formik={formik}
                  />
                </>
                <Button
                  name="submit_contact_form"
                  trailingIcon="chevron-right"
                  onClick={formik.handleSubmit}
                  isDisabled={loading}
                >
                  {loading ? "Submitting" : "Submit Enquiry"}
                </Button>
              </Form>
            )}
          </Column>
        </Row>
      </PanelContent>
    </Panel>
  )
}

export default ContactPanel
