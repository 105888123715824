import React, { useReducer, useContext, useMemo } from "react"
import PropTypes from "prop-types"
import { get, omit } from "lodash"
import { useFormik } from "formik"
import { string, object } from "yup"
import { useGet, usePatch, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { PageContext } from "@4cplatform/elements/Organisms"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { Provider } from "../../../../../UI/Templates/MyAccountQuickQuote"
import reducer from "./quickQuote.reducer"

const SelfServiceProvider = ({ children }) => {
  const t = useTranslations()
  const { selfServiceData } = useContext(PageContext)

  // State
  const [{ quickQuoteSettings }, dispatch] = useReducer(reducer, {
    quickQuoteSettings: {}
  })

  const roleName = useMemo(() => get(selfServiceData, "role.name", ""), [selfServiceData])

  const slug = useMemo(() => {
    switch (roleName) {
      case "ORG_ADMIN":
      case "SALES_ADVISER":
        return get(selfServiceData, "slug", "")
      default:
        return ""
    }
  }, [roleName, selfServiceData])

  const endpoint = useMemo(() => {
    switch (roleName) {
      case "ORG_ADMIN":
      case "SALES_ADVISER":
        return "/users/:slug/quick-quote-options"
      default:
        return ""
    }
  }, [roleName])

  // Quick quote settings query
  const {
    loading: quickQuoteLoading,
    error: quickQuoteError,
    refetch: quickQuoteRefetch
  } = useGet({
    skip: !slug || !endpoint,
    params: { slug },
    endpoint,
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "quickQuoteSettings", value: get(res, "data", {}) }),
    onError: () => {
      addAlert({
        message: t("QQ_SETTINGS_INDEX_ERROR"),
        type: "error"
      })
    }
  })

  // Update journey settings mutation
  const [
    updateQuickQuoteSettings,
    { loading: updateQuickQuoteLoading, error: updateQuickQuoteError }
  ] = usePatch({
    skip: !slug || !endpoint,
    params: { slug },
    endpoint,
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "quickQuoteSettings", value: get(res, "data", {}) }),
    onError: () => {
      addAlert({
        type: "error",
        message: t("QQ_SETTINGS_UPDATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  const formFields = omit(quickQuoteSettings, ["id", "available_options"])

  const validationSchema = (() => {
    const schema = {}
    Object.keys(formFields).forEach(
      key => (schema[key] = string().required("MISSING_REQUIRED_FIELD"))
    )
    return object(schema)
  })()

  const quickQuoteSettingsFormik = useFormik({
    enableReinitialize: true,
    initialValues: formFields,
    validationSchema,
    onSubmit: body => updateQuickQuoteSettings({ body })
  })
  const formik = { ...quickQuoteSettingsFormik, validationSchema }
  const { handleSubmit } = formik

  return (
    <Provider
      value={{
        quickQuoteData: quickQuoteSettings,
        quickQuoteLoading,
        quickQuoteRefetch,
        updateQuickQuoteLoading,
        formik,
        handleSubmit
      }}
    >
      {children}
      <ApiError error={quickQuoteError || updateQuickQuoteError} />
    </Provider>
  )
}

SelfServiceProvider.defaultProps = {
  children: null
}

SelfServiceProvider.propTypes = {
  children: PropTypes.any
}

export default SelfServiceProvider
