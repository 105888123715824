import VitalityPortalActions from "./VitalityPortalActions"

export const config = () => ({
  title: "Onboard policy",
  sections: [
    {
      key: "main",
      components: [
        {
          key: "actions",
          component: VitalityPortalActions,
          skipDataMap: true
        }
      ]
    }
  ]
})
