import styled from "styled-components"

export const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
`

export const TD = styled.td`
  border: 1px solid black;
  padding: 3rem;
`

export const LeftTD = styled(TD)`
  width: 33%;
`
