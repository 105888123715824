import React, { useContext, useReducer, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { get, toLower, isEmpty, isEqual, floor } from "lodash"
import { ApiError, useGet, usePost, PusherContext } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { PageContext } from "@4cplatform/elements/Organisms"
import { ConfigContext } from "@4cplatform/elements/Config"
import { isValidJson } from "@4cplatform/elements/Helpers"
import { addAlert } from "@4cplatform/elements/Alerts"
import { logToConsole } from "../../../../../../../../../Helpers"
import { JourneyContext } from "../../../../../../../journey.context"
import { getCurrentOptions } from "../quoteComparison.helpers"
import reducer, { initialState } from "./quoteComparison.reducer"

// Components
import { Provider } from "../quoteComparison.context"

const QuoteComparisonProvider = ({ children }) => {
  const { client: pusher } = useContext(PusherContext)
  const { DEBUG } = useContext(ConfigContext)
  const { data, isLoading: isJourneyLoading, formik } = useContext(JourneyContext)
  const {
    selfServiceData,
    selfServiceLoading,
    panelStatusControls: { panelStatus },
    setPanelStatus
  } = useContext(PageContext)
  const t = useTranslations()
  const { reference } = useParams()
  const [
    {
      subscribedToQuotes,
      globalOptions,
      currentOptions,
      expectedQuotes,
      expectedQuotesArrived,
      arrivedQuotes,
      tempArrivedQuotes,
      selectedQuotes,
      initCurrentPolicyOptions,
      regenerateModal,
      providerInfoModal
    },
    dispatch
  ] = useReducer(reducer, initialState)

  const isJourneyLocked = get(data, "journey.locked", false)

  // Global options request
  const [requestGlobalOptions, { loading: globalOptionsLoading, error: globalOptionsError }] =
    usePost({
      endpoint: "/journeys/:journey/quote-comparison/global-options",
      params: {
        journey: get(data, "journey.slug")
      },
      onCompleted: res => {
        if (!isEmpty(currentOptions)) {
          dispatch({
            type: "UPDATE_VALUE",
            key: "expectedQuotes",
            value: []
          })
          dispatch({
            type: "UPDATE_VALUE",
            key: "arrivedQuotes",
            value: []
          })
          dispatch({
            type: "UPDATE_VALUE",
            key: "selectedQuotes",
            value: []
          })
          dispatch({
            type: "UPDATE_VALUE",
            key: "currentOptions",
            value: {}
          })
        }

        const options = get(res, "data", {})

        dispatch({
          type: "UPDATE_VALUE",
          key: "globalOptions",
          value: options
        })

        if (DEBUG) {
          logToConsole("log", "Received global options:", options)
        }

        if (panelStatus !== "closed") setPanelStatus("closed")
      },
      onError: () => {
        addAlert({
          type: "error",
          message: t("QUOTE_COMPARISON_GLOBAL_OPTIONS_INDEX_ERROR"),
          dismissible: true,
          timeout: 5
        })
      }
    })

  // Quote request
  const [requestQuotes, { loading: requestQuotesLoading, error: requestQuotesError }] = usePost({
    endpoint: "/journeys/:journey/quote-comparison/request-quotes",
    params: {
      journey: get(data, "journey.slug")
    },
    body: currentOptions,
    onCompleted: res => {
      const value = get(res, "data", [])

      dispatch({ type: "EXPECTED_QUOTES_ARRIVED", value })

      if (DEBUG) {
        logToConsole("log", `Expecting ${value.length} quotes:`, value)
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("QUOTE_COMPARISON_QUOTES_REQUEST_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Get initial current policy options
  useGet({
    endpoint: "/journeys/:reference/current-policy-options",
    params: {
      reference
    },
    onCompleted: res => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "initCurrentPolicyOptions",
        value: get(res, "data.page.data", {})
      })
    }
  })

  useEffect(() => {
    if (subscribedToQuotes) {
      requestGlobalOptions()

      if (DEBUG) {
        logToConsole("log", "Requested global options")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedToQuotes])

  useEffect(() => {
    if (!isEmpty(globalOptions)) {
      const current = getCurrentOptions(globalOptions)

      dispatch({
        type: "UPDATE_VALUE",
        key: "currentOptions",
        value: current
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalOptions])

  useEffect(() => {
    if (!isEmpty(currentOptions)) {
      formik.setFieldValue("global_options", currentOptions)
      requestQuotes()

      if (DEBUG) {
        logToConsole("log", "Requested quotes with the following options:", currentOptions)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOptions])

  useEffect(() => {
    formik.setFieldValue(
      "selected_quotes",
      selectedQuotes.map(quote => get(quote, "data.quote_id"))
    )

    if (DEBUG) {
      logToConsole("log", "Selected quotes:", selectedQuotes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuotes])

  const isLoading =
    isJourneyLoading || selfServiceLoading || globalOptionsLoading || requestQuotesLoading

  const onQuoteArrived = useCallback(
    res => {
      const quote = isValidJson(get(res, "data")) ? JSON.parse(get(res, "data")) : null

      if (quote && !requestQuotesError) {
        dispatch({
          type: "QUOTE_ARRIVED",
          value: { ...quote, sentAt: get(res, "sentAt") },
          debug: DEBUG
        })
      }
    },
    [DEBUG, requestQuotesError]
  )

  useEffect(() => {
    const channelName = `private-encrypted-user.${get(selfServiceData, "id")}`
    const eventName = `4cng.${toLower(get(data, "journey.product_type"))}_quote-response`

    if (
      get(data, "journey.product_type") &&
      get(selfServiceData, "id") &&
      !subscribedToQuotes &&
      !isJourneyLocked
    ) {
      const channels = pusher.allChannels().map(ch => ch.name)

      const channel = channels.includes(channelName)
        ? pusher.allChannels().filter(ch => ch.name === channelName)[0]
        : pusher.subscribe(channelName)

      channel.bind(eventName, onQuoteArrived)

      dispatch({
        type: "UPDATE_VALUE",
        key: "subscribedToQuotes",
        value: true
      })

      if (DEBUG) {
        logToConsole(
          "log",
          `${
            channels.includes(channelName) ? "Already subscribed" : "Subscribed"
          } to Pusher "${channelName}" channel. Bound to "${eventName}" event.`,
          channel
        )
      }
    }

    if (isJourneyLocked) {
      if (DEBUG) {
        logToConsole("log", "The journey is locked, skipping subscription.")
      }
    }

    return () => {
      const channels = pusher.allChannels().map(ch => ch.name)

      const channel = channels.includes(channelName)

      if (channel) {
        pusher.unbind(eventName)

        if (DEBUG) {
          logToConsole("log", `Unbound from "${eventName}" event.`)
        }
      }

      dispatch({
        type: "RESET_QC"
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Provider
      value={{
        globalOptions,
        currentOptions,
        expectedQuotes,
        expectedQuotesArrived,
        arrivedQuotes,
        tempArrivedQuotes,
        getProgress: () =>
          floor(
            expectedQuotes.length > 0
              ? (arrivedQuotes.length / expectedQuotes.length) * 100
              : (arrivedQuotes.length / get(data, "payload.predicated_quotes", 10)) * 100,
            0
          ),
        selectedQuotes,
        onRegenerateQuotes: body => requestGlobalOptions({ body: { current_options: body } }),
        regenerateModal,
        setRegenerateModal: value =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "regenerateModal",
            value
          }),
        providerInfoModal,
        setProviderInfoModal: value =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "providerInfoModal",
            value
          }),
        isOptionsPanelOpen: panelStatus === "wide",
        openOptionsPanel: () => setPanelStatus("wide"),
        onSelectQuote: quote => {
          dispatch({
            type: "UPDATE_VALUE",
            key: "selectedQuotes",
            value: [...selectedQuotes, quote]
          })
        },
        onDeselectQuote: quote => {
          dispatch({
            type: "UPDATE_VALUE",
            key: "selectedQuotes",
            value: selectedQuotes.filter(
              q => !isEqual(get(quote, "quote_request_id"), get(q, "quote_request_id"))
            )
          })
        },
        isLoading,
        isDisabled:
          isLoading ||
          (expectedQuotes.length > 0 && arrivedQuotes.length !== expectedQuotes.length) ||
          panelStatus === "wide",
        initCurrentPolicyOptions,
        isJourneyLocked
      }}
    >
      {children}
      <ApiError error={globalOptionsError || requestQuotesError} />
    </Provider>
  )
}

QuoteComparisonProvider.propTypes = {
  children: PropTypes.any
}

export default QuoteComparisonProvider
