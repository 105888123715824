import { get } from "lodash"
import { number } from "yup"

export const config = data => ({
  title: "Quotation summary",
  navTitle: "Quotation summary",
  sections: [
    {
      key: "quotation_summary",
      components: [
        {
          key: "selected_quote",
          initialValue: get(data, "page.data.selected_quote", null),
          validationSchema: number().positive().required("MISSING_REQUIRED_FIELD"),
          label: "Selected quote"
        }
      ]
    }
  ]
})
