import React from "react"
import PropTypes from "prop-types"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { get } from "lodash"
import { ClientsContext } from "../../clients.context"

// Components
import PolicySummary from "./policy.summary"

const RowActions = ({ data }) => {
  const { policySummaryModal, setPolicySummaryModal, setSelectPolicySummary, selectPolicySummary } =
    React.useContext(ClientsContext)

  const showPolicySummaryModal =
    policySummaryModal && get(data, "id") === get(selectPolicySummary, "id")

  return (
    <>
      <Button
        type="inline-button"
        leadingIcon="arrow-right"
        iconSize="1.5rem"
        onClick={() => {
          setSelectPolicySummary(data)
          setPolicySummaryModal(true)
        }}
        name="view-policy"
      >
        View
      </Button>
      {showPolicySummaryModal && <PolicySummary data={data} />}
    </>
  )
}

RowActions.defaultProps = {
  data: {}
}

RowActions.propTypes = {
  data: PropTypes.object
}

export default RowActions
