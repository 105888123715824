import React from "react"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"

// Components
import NetworkOrganisationView from "./networkOrganisations.panel.view"

const NetworkOrganisationsPanel = () => (
  <FlyOutPanel body={() => <NetworkOrganisationView />} name="network_organisations_panel" />
)

export default NetworkOrganisationsPanel
