import React, { useReducer, useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { get, findIndex, isEmpty } from "lodash"
import { useGet, usePost, usePatch, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { ConfigContext } from "@4cplatform/elements/Config"
import { PageContext } from "@4cplatform/elements/Organisms"
import { addAlert } from "@4cplatform/elements/Alerts"

// Components
import { Provider } from "../../../../../UI/Templates/UserAdmin"

// Helpers
import reducer from "./users.reducer"
import { getOrderBy } from "../../../../../UI/Helpers"
import { ProviderManageContext } from "../../../context/manage.context"

const ProviderUsersProvider = ({ children }) => {
  const { API_SCOPE } = useContext(ConfigContext)
  const { setPanelStatus } = useContext(PageContext)
  const { provider } = useContext(ProviderManageContext)

  const { pathname } = useLocation()
  const t = useTranslations()

  // State
  const [
    {
      page,
      perPage,
      showDeleted,
      search,
      selectedUser,
      sorting,
      total,
      data,
      viewData,
      createUserRoute
    },
    dispatch
  ] = useReducer(reducer, {
    page: 1,
    perPage: 10,
    showDeleted: false,
    search: "",
    selectedUser: null,
    sorting: { direction: "asc", dataKey: "last_name" },
    total: null,
    data: [],
    viewData: {},
    createUserRoute: null
  })

  useEffect(() => {
    if (get(provider, "id", false))
      dispatch({
        type: "UPDATE_VALUE",
        key: "createUserRoute",
        value: `${pathname}/users/add?parent_id=${get(provider, "id")}`
      })
  }, [provider, pathname])

  // Index Users query
  const { loading, error, refetch } = useGet({
    endpoint: "/users",
    query: {
      search,
      page,
      limit: perPage,
      deleted: showDeleted,
      parent_type: "PROVIDER",
      parent_name: get(provider, "name", ""),
      order_by: getOrderBy(sorting),
      with: ["settings"]
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])

      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: "There was an error fetching the users",
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // User's data query
  const { loading: viewLoading, error: viewError } = useGet({
    endpoint: "/users/:slug",
    skip: !selectedUser || !get(selectedUser, "slug"),
    params: {
      slug: get(selectedUser, "slug", "")
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: newData })
    },
    onError: () => {
      addAlert({
        message: t("USER_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Optional function for firing after update actions
  const onPatch = async (user = {}) => {
    if (isEmpty(user)) {
      await refetch()
    } else {
      const i = findIndex(data, { slug: get(user, "slug") })
      dispatch({
        type: "UPDATE_VALUE",
        key: "data",
        value: [...data.slice(0, i), user, ...data.slice(i + 1)]
      })
    }
    setPanelStatus("closed")
  }

  // Edit User mutation
  const [onEditUserSubmit, { loading: editLoading, error: editError }] = usePatch({
    endpoint: "/users/:slug",
    params: {
      slug: get(selectedUser, "slug", "")
    },
    onCompleted: res => {
      // Display success message
      addAlert({
        message: "User successfully edited",
        type: "success",
        dismissible: true,
        timeout: 5
      })
      onPatch(get(res, "data"))
    },
    onError: () => {
      addAlert({
        message: "There was an error updating the user",
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Reset Password Request
  const [
    resetPasswordRequest,
    { loading: resetPasswordRequestLoading, error: resetPasswordRequestError }
  ] = usePost({
    endpoint: "/auth/forgotten-password",
    body: { email: get(selectedUser, "email", ""), scope: API_SCOPE },
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("ADMIN_FORGOTTEN_PASSWORD_SUCCESS")
      })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("ADMIN_FORGOTTEN_PASSWORD_ERROR")
      })
    }
  })

  // Resend Confirmation
  const [
    resendConfirmation,
    { loading: resendConfirmationLoading, error: resendConfirmationError }
  ] = usePost({
    endpoint: "/auth/user-activation/:id/resend",
    params: {
      id: get(viewData, "id", "")
    },
    body: {
      email: get(viewData, "email", ""),
      scope: "4c-api"
    },
    onCompleted: () => {
      // Display success message
      addAlert({
        message: t("USER_ACTIVATION_RESEND_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      addAlert({
        message: t("USER_ACTIVATION_RESEND_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        viewData,
        viewLoading,
        queryLoading: loading,
        editLoading,
        editError,
        error,
        onEditUserSubmit,
        selectedUser,
        resendConfirmation,
        resendConfirmationLoading,
        resendConfirmationError,
        resetPasswordRequest,
        resetPasswordRequestError,
        resetPasswordRequestLoading,
        onUserSelect: row => dispatch({ type: "UPDATE_VALUE", key: "selectedUser", value: row }),
        onUserDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedUser", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "viewData", value: null })
        },
        search,
        setSearch,
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        pagination: { total, page, perPage },
        showDeleted,
        setShowDeleted: val => dispatch({ type: "UPDATE_SHOW_DELETED", value: val }),
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        onPatch,
        createUserRoute,
        sorting
      }}
    >
      {children}
      <ApiError
        error={
          error || viewError || editError || resetPasswordRequestError || resendConfirmationError
        }
      />
    </Provider>
  )
}

ProviderUsersProvider.defaultProps = {
  children: null
}

ProviderUsersProvider.propTypes = {
  children: PropTypes.any
}

export default ProviderUsersProvider
