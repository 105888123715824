import React, { useContext } from "react"
import PropTypes from "prop-types"
import { get, findIndex, isEmpty, find } from "lodash"
import { useGet, usePost, usePatch, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { ConfigContext } from "@4cplatform/elements/Config"
import { PageContext } from "@4cplatform/elements/Organisms"
import { AuthContext } from "@4cplatform/elements/Auth"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../../../UI/Templates/UserAdmin"

// Helpers
import reducer from "./users.reducer"
import { getOrderBy } from "../../../UI/Helpers"

const UsersProvider = ({ children }) => {
  const t = useTranslations()
  const { setPanelStatus } = useContext(PageContext)
  const { API_SCOPE } = useContext(ConfigContext)
  const { canAccess } = useContext(AuthContext)

  // State
  const [
    {
      page,
      perPage,
      hasParentFilter,
      parentType,
      showDeleted,
      search,
      selectedUser,
      sorting,
      total,
      data,
      viewData,
      twoFA,
      role
    },
    dispatch
  ] = React.useReducer(reducer, {
    page: 1,
    perPage: 10,
    showDeleted: false,
    search: "",
    selectedUser: null,
    sorting: { direction: "asc", dataKey: "last_name" },
    total: null,
    data: [],
    viewData: {},
    twoFA: {},
    hasParentFilter: true,
    parentType: "",
    status: "",
    role: ""
  })

  const getQueries = () => {
    if (!canAccess(["SYS_ADMIN", "SUPPORT_ADMIN"])) {
      return {}
    }

    let result = {}

    if (parentType) {
      result = { ...result, parent_type: parentType }
    }

    if (role) {
      result = { ...result, role }
    }

    return result
  }

  // Index Users query
  const { loading, error, refetch } = useGet({
    endpoint: "/users",
    query: {
      search,
      page,
      limit: perPage,
      deleted: showDeleted,
      order_by: getOrderBy(sorting),
      with: ["settings"],
      ...getQueries()
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("USER_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const deletedQuery = get(selectedUser, "deleted_at") ? { deleted: true } : {}

  // User's data query
  const { loading: viewLoading, error: viewError } = useGet({
    endpoint: "/users/:slug",
    skip: !selectedUser || !get(selectedUser, "slug"),
    query: {
      ...deletedQuery
    },
    params: {
      slug: get(selectedUser, "slug", "")
    },
    onCompleted: res => {
      const newData = get(res, "data", {})

      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: newData })
    },
    onError: () => {
      addAlert({
        message: t("USER_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // User's data query
  const { loading: userAuthLoading, error: userAuthError } = useGet({
    endpoint: "/auth/users/:id",
    skip: !canAccess(["SYS_ADMIN", "SUPPORT_ADMIN"]) || !get(selectedUser, "id"),
    query: {
      ...deletedQuery
    },
    params: {
      id: get(selectedUser, "id", "")
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "twoFA", value: newData })
    },
    onError: () => {
      addAlert({
        message: t("USER_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Optional function for firing after update actions
  const onPatch = async (user = {}) => {
    if (isEmpty(user)) {
      await refetch()
    } else {
      const i = findIndex(data, { slug: get(user, "slug") })
      dispatch({
        type: "UPDATE_VALUE",
        key: "data",
        value: [...data.slice(0, i), user, ...data.slice(i + 1)]
      })
    }
    setPanelStatus("closed")
  }

  // Edit User mutation
  const [onEditUserSubmit, { loading: editLoading, error: editError }] = usePatch({
    endpoint: "/users/:slug",
    params: {
      slug: get(selectedUser, "slug", "")
    },
    onCompleted: res => {
      // Display success message
      addAlert({
        message: "User successfully edited",
        type: "success",
        dismissible: true,
        timeout: 5
      })
      onPatch(get(res, "data"))
    },
    onError: res => {
      let message = "There was an error updating the user"
      if (res.status === 422) {
        if (
          res.validation?.email instanceof Array &&
          res.validation?.email.includes("NOT_UNIQUE")
        ) {
          message = "Another user already has this email address"
        }
      }
      addAlert({
        message,
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Reset two-factor authentication for a user
  const [
    resetTwoFactorAuthForAUser,
    { loading: twoFactorResetLoading, error: twoFactorResetError }
  ] = usePatch({
    endpoint: "/auth/two-factor-authentication/:user/reset",
    params: {
      user: get(selectedUser, "slug", "")
    },
    onCompleted: () => {
      // Display success message
      addAlert({
        message: t("TWOFA_RESET_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      addAlert({
        message: t("TWOFA_RESET_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Resend Confirmation
  const [
    resendConfirmation,
    { loading: resendConfirmationLoading, error: resendConfirmationError }
  ] = usePost({
    endpoint: "/auth/user-activation/:id/resend",
    params: {
      id: get(viewData, "id", "")
    },
    body: {
      email: get(viewData, "email", ""),
      scope: "4c-api"
    },
    onCompleted: () => {
      // Display success message
      addAlert({
        message: t("USER_ACTIVATION_RESEND_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
    },
    onError: () => {
      addAlert({
        message: t("USER_ACTIVATION_RESEND_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Reset Password Request
  const [
    resetPasswordRequest,
    { loading: resetPasswordRequestLoading, error: resetPasswordRequestError }
  ] = usePost({
    endpoint: "/auth/forgotten-password",
    body: { email: get(selectedUser, "email", ""), scope: API_SCOPE },
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("ADMIN_FORGOTTEN_PASSWORD_SUCCESS")
      })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("ADMIN_FORGOTTEN_PASSWORD_ERROR")
      })
    }
  })

  const simulationModeId = get(
    find(get(viewData, "settings", []), ["key", "SIMULATION_MODE"]),
    "id",
    ""
  )
  const simulationModeValue = get(
    find(get(viewData, "settings", []), ["key", "SIMULATION_MODE"]),
    "data.value",
    false
  )

  // Toggle simulation mode
  const [toggleSimulation, { loading: toggleSimulationLoading, error: toggleSimulationError }] =
    usePatch({
      endpoint: "/users/:user/settings/:id",
      params: {
        user: get(selectedUser, "slug", ""),
        id: simulationModeId
      },
      body: {
        data: {
          value: !simulationModeValue
        }
      },
      onCompleted: () => {
        // Display success message
        addAlert({
          message: !simulationModeValue
            ? t("SIMULATION_MODE_ENABLE_SUCCESS")
            : t("SIMULATION_MODE_DISABLE_SUCCESS"),
          type: "success",
          dismissible: true,
          timeout: 5
        })
        onPatch()
      },
      onError: () => {
        addAlert({
          message: !simulationModeValue
            ? t("SIMULATION_MODE_ENABLE_ERROR")
            : t("SIMULATION_MODE_DISABLE_ERROR"),
          type: "error",
          dismissible: true,
          timeout: 5
        })
      }
    })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        queryLoading: loading,
        viewLoading,
        editLoading,
        editError,
        error,
        viewData,
        twoFA,
        userAuthLoading,
        userAuthError,
        onEditUserSubmit,
        selectedUser,
        twoFactorResetLoading,
        resetTwoFactorAuthForAUser,
        resendConfirmation,
        resendConfirmationLoading,
        resetPasswordRequest,
        resetPasswordRequestError,
        resetPasswordRequestLoading,
        toggleSimulation,
        toggleSimulationLoading,
        sorting,
        parentType,
        hasParentFilter,
        setParentType: val => dispatch({ type: "UPDATE_VALUE", key: "parentType", value: val }),
        onUserSelect: row => dispatch({ type: "UPDATE_VALUE", key: "selectedUser", value: row }),
        onUserDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedUser", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "viewData", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "twoFA", value: null })
        },
        search,
        setSearch,
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        pagination: { total, page, perPage },
        showDeleted,
        setShowDeleted: val => dispatch({ type: "UPDATE_SHOW_DELETED", value: val }),
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        onPatch,
        setRole: val => dispatch({ type: "UPDATE_VALUE", key: "role", value: val }),
        role
      }}
    >
      {children}
      <ApiError
        error={
          error ||
          viewError ||
          editError ||
          twoFactorResetError ||
          resendConfirmationError ||
          resetPasswordRequestError ||
          toggleSimulationError
        }
      />
    </Provider>
  )
}

UsersProvider.defaultProps = {
  children: null
}

UsersProvider.propTypes = {
  children: PropTypes.any
}

export default UsersProvider
