import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { get } from "lodash"
import { AuthContext } from "@4cplatform/elements/Auth"
import { Button, Skeleton } from "@4cplatform/elements/Molecules"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { JourneysContext } from "./journeys.context"

const JourneyView = () => {
  const { canAccess } = useContext(AuthContext)
  const { viewData, viewLoading } = useContext(JourneysContext)
  const { setPanelStatus } = React.useContext(PageContext)
  const history = useHistory()
  return (
    <PanelBody>
      {viewLoading ? (
        <Skeleton appearance="light" />
      ) : (
        <>
          {get(viewData, "journey.status") !== "COMPLETE" &&
            !canAccess(["NETWORK_ADMIN", "NETWORK_MEMBER_ADMIN"]) && (
              <Button
                appearance="success"
                trailingIcon="chevron-right"
                onClick={() => {
                  setPanelStatus("closed")
                  history.push(get(viewData, "page.route"))
                }}
                isDisabled={viewLoading}
              >
                Resume Journey
              </Button>
            )}
        </>
      )}
    </PanelBody>
  )
}

export default JourneyView
