import styled from "styled-components"
import { get } from "lodash"

export const Wrapper = styled.div`
  margin: ${({ margin }) => margin};
`

export const BankDetailsFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
`

export const Divider = styled.div`
  height: 1px;
  margin: 2rem 0;
  background: ${({ theme }) => get(theme, "faintGrey")};
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`
export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`
export const ManualWrapper = styled.div`
  display: flex;
  align-self: baseline;
  align-items: center;
  height: 5.2rem;
`
