const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_VALUE": {
      return {
        ...state,
        [action.key]: action.value
      }
    }

    case "FETCH_COMPLETE": {
      return {
        ...state,
        data: action.data
      }
    }

    case "DELETE_COMMISSION_RATE": {
      return {
        ...state,
        selectedCommissionRate: null,
        viewData: {},
        deleteModal: false
      }
    }

    default:
      return state
  }
}

export default reducer
