import React, { useContext } from "react"
import { get, isEmpty, isNull } from "lodash"

import { Container } from "@4cplatform/elements/Atoms"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { JourneyContext } from "./journey.context"
import { DASHBOARD, JOURNEY_ADMIN } from "../../../config/pages"

// Components
import {
  JourneyNav,
  JourneyPage,
  SubmitModal,
  PrevModal,
  FaqModal,
  MedicalDictionaryModal
} from "./components"
import { Breadcrumbs } from "../../Molecules"
import { Messaging } from "../../Organisms"
import { JourneyWrapper, StickyNav, JourneyHeaderWrapper } from "./journey.styles"

const Journey = () => {
  const {
    data,
    onPageSubmit,
    onCustomPageSubmit,
    hasSubmitModal,
    setSubmitModal,
    onClickPrevious,
    onClickNext,
    isLoading,
    hidePrevious,
    hideNext,
    disableNext,
    hasPreviousModal,
    setPreviousModal,
    setFaqModal,
    hasFaqModal,
    setMedicalDictionaryModal,
    hasMedicalDictionaryModal,
    setMessagingModal,
    hasMessagingModal,
    messages,
    messagesLoading,
    submitMessage,
    submitMessageLoading,
    formik,
    isNavigationCollapsed,
    auditLoading
  } = useContext(JourneyContext)

  const productType = get(data, "journey.product_type", "PMI")
  const journeyReference = get(data, "journey.reference", false)
  const journeyReferenceText = journeyReference ? ` - ${journeyReference}` : ""

  const isLocked = get(data, "journey.locked", false)

  return (
    <>
      <Container isPageContainer width={isNavigationCollapsed ? "90%" : "80%"}>
        <JourneyHeaderWrapper>
          <Breadcrumbs
            trail={[
              { label: "Dashboard", link: DASHBOARD.path },
              { label: "Journeys", link: `${JOURNEY_ADMIN.path}` },
              { label: `${productType} Journey${journeyReferenceText}` }
            ]}
          />
          <Button
            type="inline-button"
            onClick={() => setMedicalDictionaryModal(true)}
            name="medical_dictionary"
          >
            Medical Dictionary
          </Button>
          <Button
            type="inline-button"
            onClick={() => setFaqModal(true)}
            name="faq_s"
            margin="0 2rem"
          >
            FAQs
          </Button>
          {get(data, "payload.policy.slug", get(data, "journey.policy.slug", false)) && (
            <Button
              type="inline-button"
              onClick={() => setMessagingModal(true)}
              name="messaging"
              margin="0"
              leadingIcon="chat"
            />
          )}
        </JourneyHeaderWrapper>

        <JourneyWrapper>
          <JourneyNav />
          <JourneyPage />
        </JourneyWrapper>
      </Container>
      {!isNull(data) && (
        <StickyNav hasSingleButton={hidePrevious} isVisible={!isNull(data) || !isLoading}>
          {!hidePrevious && (
            <Button
              leadingIcon="chevron-left"
              onClick={() => {
                if (!isLocked && get(formik, "dirty", false)) {
                  setPreviousModal(true)
                } else {
                  onClickPrevious()
                }
              }}
              name="journey_previous"
              isLoading={isLoading}
            >
              Previous
            </Button>
          )}
          {!hideNext && (
            <Button
              onClick={() => {
                if (!isLocked) {
                  if (typeof onCustomPageSubmit === "function") {
                    onCustomPageSubmit()
                  } else if (!isEmpty(get(data, "page.modals.submit", []))) {
                    setSubmitModal(true)
                  } else {
                    onPageSubmit()
                  }
                } else {
                  onClickNext()
                }
              }}
              appearance={isLocked ? "primary" : "success"}
              trailingIcon={isLocked ? "chevron-right" : "check"}
              name="journey_save"
              isLoading={isLoading || auditLoading}
              isDisabled={disableNext}
            >
              {isLocked ? "Next" : "Save and Continue"}
            </Button>
          )}
        </StickyNav>
      )}
      {hasSubmitModal && <SubmitModal />}
      {hasPreviousModal && <PrevModal />}
      {hasFaqModal && <FaqModal />}
      {hasMedicalDictionaryModal && <MedicalDictionaryModal />}
      {hasMessagingModal && (
        <Messaging
          isOpen={hasMessagingModal}
          setIsOpen={setMessagingModal}
          messages={messages}
          isMessagesLoading={messagesLoading}
          submitMessage={submitMessage}
          isSubmitMessageLoading={submitMessageLoading}
        />
      )}
    </>
  )
}

export default Journey
