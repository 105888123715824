import React from "react"
import { get, isEmpty } from "lodash"
import { Button } from "@4cplatform/elements/Molecules"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { H3, SmallText } from "@4cplatform/elements/Typography"
import { PageContext } from "@4cplatform/elements/Organisms"
import { AuthContext } from "@4cplatform/elements/Auth"

// Helpers
import { AgencyCodesContext } from "../agencyCodes.context"
import { formatUsersAsEvents } from "../agencyCodes.helpers"

// Components
import { Tabs, Tab, Timeline } from "../../../Organisms"
import CommissionRates from "../components/CommisionRates"
import DealCodeAssignment from "../components/DealCodeAssignment"

const AgencyCodesPanelBody = () => {
  const { viewLoading, viewData, sharedUsers, type, setRevoke, bodyTabId, setBodyTabId } =
    React.useContext(AgencyCodesContext)
  const { setPanelStatus } = React.useContext(PageContext)
  const { canAccess } = React.useContext(AuthContext)

  return (
    <>
      <PanelBody>
        <Tabs
          type="panel"
          name="agency_codes_panel"
          isLoading={viewLoading}
          margin="0 0 1rem"
          defaultIndex={bodyTabId}
          newActiveID={bodyTabId}
          onTabChange={key => setBodyTabId(key)}
        >
          <Tab header="Commission" isPresent={type === "default" || type === "assigned_to_users"}>
            <CommissionRates shouldHideSecondaryCommissionRate />
          </Tab>
          <Tab
            header="Sharing"
            isPresent={get(viewData, "status", false) === "ACTIVE" && type !== "assigned_to_users"}
          >
            <H3 appearance="light" margin="2rem 0">
              Shared with
            </H3>
            {isEmpty(sharedUsers) && (
              <SmallText appearance="light">
                This agency code has not been shared with any users.
              </SmallText>
            )}
            <Timeline events={formatUsersAsEvents({ users: sharedUsers })} />

            {/* User actions */}
            {!(type === "shared_with_users" || type === "assigned_to_users") && (
              <Button
                appearance="whiteGhost"
                trailingIcon="share-variant-outline"
                onClick={() => setPanelStatus("wide")}
                name="share"
                margin="0 0 2rem"
              >
                Share
              </Button>
            )}
            {type === "shared_with_users" && (
              <Button
                appearance="warning"
                trailingIcon="hand-right"
                onClick={() => setRevoke(true)}
                name="share"
              >
                Revoke sharing
              </Button>
            )}
          </Tab>
          <Tab
            header="Deal code"
            isPresent={canAccess([
              "SYS_ADMIN",
              "SUPPORT_ADMIN",
              "PROVIDER_ADMIN",
              "ORG_ADMIN",
              "SALES_ADVISER",
              "NETWORK_ADMIN",
              "NETWORK_MEMBER_ADMIN"
            ])}
          >
            <DealCodeAssignment />
          </Tab>
        </Tabs>
      </PanelBody>
    </>
  )
}

export default AgencyCodesPanelBody
