import React from "react"
import { P } from "@4cplatform/elements/Typography"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { JourneyContext } from "../../journey.context"

const PrevModal = () => {
  const { setPreviousModal, onClickPrevious } = React.useContext(JourneyContext)
  return (
    <ConfirmationModal
      onConfirm={() => {
        onClickPrevious()
        setPreviousModal(false)
      }}
      onClose={() => setPreviousModal(false)}
      confirmAppearance="success"
      cancelAppearance="error"
    >
      <P>Unsaved content is present on this page. Are you sure you want to go back?</P>
    </ConfirmationModal>
  )
}

export default PrevModal
