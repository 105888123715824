import React from "react"
import { get } from "lodash"
import { boolean } from "yup"
import { P } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import DisclosureNotes from "../../../../../../DisclosureNotes"
import NoteFormTemplate from "./noteFormTemplate"
import NoteDisplayTemplate from "./noteDisplayTemplate"
import Audit from "./Audit"

export const config = data => ({
  title: "Switch disclosures",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          key: "compliance_note",
          component: ComplianceNote,
          componentProps: {
            children: (
              <P margin="0">
                Answering <strong>Yes</strong> to any questions above may result in exclusions being
                applied to the policy, if your client would like information on what will be covered
                before they buy, please call us on 0345 600 0850.
              </P>
            ),
            type: "info"
          },
          skipDataMap: true
        },
        ...[
          {
            key: "applicant_being_covered_cancer_last_5_years",
            label:
              "In the last 5 years have any of the applicants being covered had any form of cancer that's not already excluded on their existing scheme?"
          },
          {
            key: "applicant_had_a_heart_or_circulatory_condition_last_5_years",
            label:
              "In the last 5 years have any of the applicants had a heart or circulatory condition that's not already excluded on their existing scheme (for example coronary artery disease, angina and heart attacks, stroke, abnormal heart rhythms, blood clots or aneurysms, heart valve disorders, uncontrolled high cholesterol/blood pressure etc)?"
          },
          {
            key: "applicant_had_any_arthritic_or_back_and_neck_or_spinal_condition_or_joint_replacements_last_2_years",
            label:
              "In the last two years has the person to be covered had any arthritic conditions, and / or back and neck conditions and / or spinal conditions (including sciatica), or joint replacements?"
          },
          {
            key: "applicants_have_any_planned_or_pending_investigations_treatment_or_surgery",
            label:
              "Do any of the applicants have any planned or pending investigations, treatment or surgery for any condition or symptom that's not already excluded on their existing scheme (this applies whether the treatment is planned privately or under the NHS)?"
          }
        ].map(item => ({
          ...item,
          name: item.key,
          initialValue: get(data, `page.data.${item.key}`, false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          component: DisclosureNotes,
          componentProps: {
            options: [
              { order: 0, label: "No", value: false },
              { order: 1, label: "Yes", value: true }
            ],
            noteFormTemplate: NoteFormTemplate,
            noteDisplayTemplate: NoteDisplayTemplate,
            isHorizontal: true,
            isRequired: true
          }
        }))
      ]
    }
  ]
})
