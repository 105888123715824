import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { H2, SmallText } from "@4cplatform/elements/Typography"
import { PanelHeader } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { ConfigContext } from "@4cplatform/elements/Config"

// Helpers
import { ClientsContext } from "./clients.context"
import { getName } from "../../Helpers"

// Components
import ClientsPanelHeaderDetails from "./clients.panel.header.details"
import ClientsPanelHeaderAddress from "./clients.panel.header.address"
import ClientsPanelHeaderButtons from "./clients.panel.header.buttons"
import { HeaderUserDetailsWrapper } from "./clients.styles"

const ClientsPanelHeader = ({ isEdit, isWidePanel }) => {
  const { viewLoading, viewData, panelBodyContent } = React.useContext(ClientsContext)
  const [title, setTitle] = useState("")
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const name = getName({ data: { ...viewData, title }, hasTitle: true, hideOtherTitle: true })
  const created = get(viewData, "created_at", "-")
  const displayDate = new Date(created).toLocaleString().replace(",", " ")

  useEffect(() => {
    setTitle({ label: GLOBAL_TITLES?.data?.[viewData.title]?.title })
  }, [GLOBAL_TITLES, viewData.title])

  return (
    <PanelHeader>
      <H2 appearance="light" isLoading={viewLoading} margin={`${isEdit ? "0" : "2rem"} 0 1rem`}>
        {name}
      </H2>
      <SmallText appearance="light" isLoading={viewLoading} margin="0">
        Created: {displayDate}
      </SmallText>
      <HeaderUserDetailsWrapper isEdit={isEdit}>
        <ClientsPanelHeaderDetails isEdit={isEdit} />
        <ClientsPanelHeaderAddress />
      </HeaderUserDetailsWrapper>
      {panelBodyContent !== "Edit" && <ClientsPanelHeaderButtons isWidePanel={isWidePanel} />}
    </PanelHeader>
  )
}

ClientsPanelHeader.defaultProps = {
  isEdit: false,
  isWidePanel: false
}

ClientsPanelHeader.propTypes = {
  /**
   * Whether or not to display the Edit and Add note buttons
   */
  isEdit: PropTypes.bool,
  /**
   * Change buttons styling if it is wide panel
   */
  isWidePanel: PropTypes.bool
}

export default ClientsPanelHeader
