import { boolean } from "yup"
import { get } from "lodash"

import { H4, P } from "@4cplatform/elements/Typography"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { Toggle } from "@4cplatform/elements/Forms"

export const config = data => ({
  title: "Explain in-patient/out-patient terminology",
  sections: [
    {
      key: "section_1",
      components: [
        {
          key: "compliance_note_1",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <H4 margin="0 0 1rem">Compliance note</H4>
                <P margin="0">
                  Explain the following terms to the client and confirm they understand each
                  definition.
                </P>
              </>
            ),
            margin: "1rem 0 2rem 0",
            type: "error"
          },
          skipDataMap: true
        },
        {
          key: "compliance_note_2",
          component: ComplianceNote,
          componentProps: {
            children: (
              <>
                <H4 margin="0 0 1rem">Out-Patient:</H4>
                <P margin="0">
                  This is where the patient visits a hospital, clinic or associated facility for
                  diagnosis or treatment however a hospital bed is not required. An example of which
                  would be a blood test or X-Ray.
                </P>
                <H4 margin="1rem 0 1rem">Day-Patient:</H4>
                <P margin="0">
                  This is where the patient visits a hospital, clinic or associated facility for
                  diagnosis or treatment and a hospital bed is required for during the day however
                  is not required for an overnight stay. An example of which would be the client
                  receiving a short course of treatment.
                </P>
                <H4 margin="1rem 0 1rem">In-Patient:</H4>
                <P margin="0">
                  This is where the patient occupies a hospital bed overnight or longer, in order to
                  undergo medical investigations or treatment. An example of which would be the
                  client recovering from surgery.
                </P>
              </>
            ),
            type: "info"
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "understood_inpatient_outpatient",
          initialValue: get(data, "page.data.understood_inpatient_outpatient", false),
          validationSchema: boolean()
            .oneOf([true], "PATIENT_TERMINOLOGY_MUST_CONFIRM_UNDERSTANDING")
            .required("MISSING_REQUIRED_FIELD"),
          label: "Has the client confirmed they understand?",
          component: Toggle,
          componentProps: {
            errorPrefix: "none",
            labelWidth: "50rem",
            isHorizontal: true,
            isRequired: true
          }
        }
      ]
    }
  ]
})
