import { boolean } from "yup"
import { get } from "lodash"

import { Toggle } from "@4cplatform/elements/Forms"

// Components
import SubmitToProviderButtons from "./submitToProviderButtons"

export const config = data => ({
  title: "Submit to provider",
  subtitle:
    "This application must now be submitted to the provider for underwriting. You will not be able to progress beyond this page until they accept the policy, and you will receive a notification on the 4CPlatform when they have done this.",
  sections: [
    {
      key: "section_1",
      components: [
        {
          key: "custom_submit_to_provider_buttons",
          component: SubmitToProviderButtons,
          skipDataMap: true
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "client_happy_to_proceed_with_providers_terms",
          name: "client_happy_to_proceed_with_providers_terms",
          initialValue: get(data, "page.data.client_happy_to_proceed_with_providers_terms'", true),
          validationSchema: boolean()
            .oneOf([true], "MUST_BE_TRUE")
            .required("MISSING_REQUIRED_FIELD"),
          label: "Client happy to proceed?",
          component: Toggle,
          componentProps: {
            isHorizontal: true,
            isRequired: true,
            labelWidth: "39.5rem",
            margin: "2rem 0 2rem"
          }
        }
      ]
    }
  ]
})
