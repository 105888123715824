import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useFormik } from "formik"
import { Button, Modal, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"

// Helpers
import { LeadsContext } from "./leads.context"
import { Typeahead } from "../../Forms"
import { getName } from "../../Helpers"
import { editTransferModel as validationSchema } from "./leads.helpers"

// Components
import { ButtonsWrapper } from "./leads.styles"

const TransferLead = ({ users }) => {
  const {
    selectedLead,
    transferOpen,
    setTransferOpen,
    transferLoading,
    onTransferLead,
    userVal,
    setUserVal,
    suggestionsLoading
  } = useContext(LeadsContext)
  const [confirmOpen, setConfirmOpen] = useState(false)

  // Formik instance
  const formik = useFormik({
    initialValues: {
      user_id: null
    },
    validationSchema,
    onSubmit: () => {
      setConfirmOpen(true)
    }
  })

  const { handleSubmit } = formik

  return (
    <>
      <Button
        appearance="warning"
        trailingIcon="share-variant-outline"
        onClick={() => setTransferOpen(true)}
        name="transfer_lead"
      >
        Transfer lead
      </Button>
      {transferOpen && (
        <Modal onClose={() => setTransferOpen(false)} title="Transfer Lead" overflow="visible">
          <Typeahead
            formik={formik}
            name="user_id"
            label="Choose a new owner"
            onSelect={user => {
              setUserVal(user.label)
              formik.setFieldValue("user_id", user.id)
            }}
            onChange={val => setUserVal(val)}
            onCancel={() => setUserVal("")}
            suggestions={users
              .filter(user => get(user, "id") !== get(selectedLead, "sales_agent.id"))
              .map(user => {
                const { id } = user
                const name = getName({ data: user })

                return { id, label: name }
              })}
            hasCancel={!!userVal}
            isLoading={suggestionsLoading}
            errorPrefix="label"
            errorLabel="A new owner"
          />
          <ButtonsWrapper>
            <Button
              appearance="warning"
              name="transfer_lead_submit"
              trailingIcon="share-variant-outline"
              onClick={handleSubmit}
              isLoading={transferLoading}
            >
              Transfer
            </Button>
            <Button
              appearance="error"
              name="cancel"
              trailingIcon="cancel"
              onClick={() => setTransferOpen(false)}
            >
              Cancel
            </Button>
          </ButtonsWrapper>
          {confirmOpen && (
            <ConfirmationModal
              name="transfer_lead"
              title="Confirm Lead Transfer"
              confirmIcon="check"
              confirmText="Yes"
              confirmAppearance="success"
              isLoadingConfirm={transferLoading}
              cancelAppearance="error"
              cancelText="No"
              onClose={() => {
                setConfirmOpen(false)
                setTransferOpen(false)
                formik.resetForm()
              }}
              onConfirm={() =>
                onTransferLead({
                  user_id: get(formik, "values.user_id")
                })
              }
            >
              <P>
                Are you sure you want to transfer <strong>{getName({ data: selectedLead })}</strong>{" "}
                to <strong>{userVal}</strong>?
              </P>
              {get(selectedLead, "has_active_journeys", false) && (
                <P>
                  This lead has an associated client and the client has one or more journeys in
                  progress. These journeys will also be transfered to the new owner.
                </P>
              )}
            </ConfirmationModal>
          )}
        </Modal>
      )}
    </>
  )
}

TransferLead.defaultProps = {
  users: []
}

TransferLead.propTypes = {
  users: PropTypes.array
}

export default TransferLead
