import React, { useEffect, useContext, useReducer } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get, orderBy, find } from "lodash"
import { useGet, usePost } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { PageContext } from "@4cplatform/elements/Organisms"
import reducer from "./notifications.reducer"

// Components
import { Provider } from "../../../UI/Templates/NotificationAdmin"

const sortNotifications = (notifications = []) =>
  orderBy(notifications, [n => moment(n.sent_at, "YYYY-MM-DDTHH:mm:ssZ").unix()], ["desc"])

const NotificationsProvider = ({ children }) => {
  const t = useTranslations()

  const { unreadNotifications: unread, updateUnreadNotifications } = useContext(PageContext)

  // State
  const [{ toReadNotification, unreadNotifications, readNotifications }, dispatch] = useReducer(
    reducer,
    {
      toReadNotification: null,
      unreadNotifications: unread,
      readNotifications: []
    }
  )

  useEffect(() => {
    dispatch({
      type: "UPDATE_VALUE",
      key: "unreadNotifications",
      value: unread
    })
  }, [unread])

  // All notifications query
  const { loading: notificationsLoading, refetch: notificationsRefetch } = useGet({
    endpoint: "/notifications",
    onCompleted: res => {
      const data = get(res, "data", [])

      dispatch({
        type: "UPDATE_VALUE",
        key: "readNotifications",
        value: sortNotifications(data.filter(notification => notification.read))
      })
    },
    onError: () => {
      addAlert({
        message: t("NOTIFICATIONS_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Mark notification as read
  const [markAsRead, { loading: markAsReadLoading }] = usePost({
    endpoint: "/notification/mark/:id",
    onCompleted: () => {
      const newUnreadNotifications = sortNotifications(
        unreadNotifications.filter(notification => notification.id !== toReadNotification)
      )

      const readNotification = find(unreadNotifications, ["id", toReadNotification])

      dispatch({
        type: "UPDATE_VALUE",
        key: "readNotifications",
        value: sortNotifications([...readNotifications, readNotification])
      })

      updateUnreadNotifications(newUnreadNotifications)

      dispatch({
        type: "UPDATE_VALUE",
        key: "toReadNotification",
        value: null
      })
    },
    onError: () => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "toReadNotification",
        value: null
      })

      addAlert({
        message: t("NOTIFICATION_DELETE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Mark all notifications as read
  const [onMarkAllAsRead, { loading: markAllAsReadLoading }] = usePost({
    endpoint: "/notification/mark",
    onCompleted: () => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "readNotifications",
        value: sortNotifications([...readNotifications, ...unreadNotifications])
      })

      updateUnreadNotifications([])
    },
    onError: () => {
      addAlert({
        message: t("NOTIFICATIONS_DELETE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  useEffect(() => {
    if (toReadNotification && !markAsReadLoading)
      markAsRead({
        params: {
          id: toReadNotification
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toReadNotification])

  return (
    <Provider
      value={{
        notificationsLoading,
        notificationsRefetch,
        unreadNotifications,
        readNotifications,
        onMarkAsRead: id =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "toReadNotification",
            value: id
          }),
        markAsReadLoading,
        toReadNotification,
        onMarkAllAsRead,
        markAllAsReadLoading
      }}
    >
      {children}
    </Provider>
  )
}

NotificationsProvider.defaultProps = {
  children: null
}

NotificationsProvider.propTypes = {
  children: PropTypes.any
}

export default NotificationsProvider
