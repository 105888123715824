import React, { useContext, Fragment } from "react"
import { get, isEmpty } from "lodash"
import { v4 as uuid } from "uuid"
import { colours } from "@4cplatform/elements/Helpers"
import {
  Modal,
  Collapse,
  Skeleton,
  Button,
  RenderHTML,
  Pagination
} from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"
import { Container } from "@4cplatform/elements/Atoms"

// Helpers
import { JourneyContext } from "../../journey.context"

// Components
import { Search } from "../../../../Molecules"
import {
  Divider,
  ResultsContainer,
  CloseButton,
  LoadingLine
} from "./medicalDictionaryModal.styles"

const MedicalDictionaryModal = () => {
  const {
    medicalDictionaryPagination,
    handleMedicalDictionaryPagination,
    setMedicalDictionaryModal,
    medicalDictionaryQuery,
    medicalDictionaryQueryResults,
    medicalDictionaryLoading,
    setMedicalDictionaryQuery
  } = useContext(JourneyContext)

  const search = get(medicalDictionaryQuery, "search", "")

  return (
    <Modal
      title="Medical Dictionary"
      hasPadding={false}
      onClose={() => setMedicalDictionaryModal(false)}
    >
      <Container
        width="100%"
        margin="0"
        padding="2rem"
        backgroundColour={get(colours, "veryFaintGrey")}
      >
        <Search
          width="100%"
          charLimit={1}
          name="search_medical_dictionary"
          label="Search medical condition to cover"
          placeholder="Enter a search term"
          handleChange={setMedicalDictionaryQuery}
          onCancel={() => setMedicalDictionaryQuery("")}
          shouldResetSearchQuery={false}
        />
      </Container>

      {medicalDictionaryLoading ? (
        <Skeleton count={1} lineHeight="4rem" wrapper={LoadingLine} />
      ) : (
        <Pagination
          total={medicalDictionaryPagination.totalItems}
          page={medicalDictionaryPagination.currentPage}
          perPage={medicalDictionaryPagination.limit}
          changePage={handleMedicalDictionaryPagination}
          hasPerPage
          appearance="dark"
          shouldOnlyShowPaginationBar
        />
      )}

      <ResultsContainer
        margin="0"
        height={`${
          isEmpty(medicalDictionaryQueryResults) && !medicalDictionaryLoading ? "8rem" : "40rem"
        }`}
        padding="2rem"
      >
        {medicalDictionaryLoading && <Skeleton count={6} lineHeight="4rem" wrapper={LoadingLine} />}
        {!isEmpty(medicalDictionaryQueryResults) &&
          !medicalDictionaryLoading &&
          medicalDictionaryQueryResults.map(result => (
            <Fragment key={uuid()}>
              <Collapse
                headerContent={
                  <P
                    margin="0.5rem 0"
                    colour={get(colours, "blue")}
                    dangerouslySetInnerHTML={{
                      __html: get(result, "title", "").replace(
                        new RegExp(search, "g"),
                        `<strong>${search}</strong>`
                      )
                    }}
                  />
                }
                bodyContent={
                  <RenderHTML
                    content={get(result, "content", "")}
                    hasRelativeUrls={false}
                    hasTargetBlank
                  />
                }
              />
              {medicalDictionaryQueryResults.length - 1 !==
                medicalDictionaryQueryResults.indexOf(result) && <Divider />}
            </Fragment>
          ))}
        {isEmpty(medicalDictionaryQueryResults) && !medicalDictionaryLoading && (
          <P margin="1rem 0">No data is available for display</P>
        )}
      </ResultsContainer>
      <CloseButton>
        <Button
          trailingIcon="close"
          appearance="error"
          onClick={() => setMedicalDictionaryModal(false)}
        >
          Close
        </Button>
      </CloseButton>
    </Modal>
  )
}

export default MedicalDictionaryModal
