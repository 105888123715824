/* eslint-disable react/display-name */
import React, { useContext, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { get, find, capitalize } from "lodash"
import { v4 as uuid } from "uuid"
import moment from "moment"
import { colours } from "@4cplatform/elements/Helpers"
import { Toggle } from "@4cplatform/elements/Forms"
import { Icon } from "@4cplatform/elements/Atoms"
import { P, SmallText } from "@4cplatform/elements/Typography"
import { Skeleton } from "@4cplatform/elements/Molecules"
import { useTranslations } from "@4cplatform/elements/Translations"
import { usePost } from "@4cplatform/elements/Api/useMutation"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { getWarningMessage } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/quotationSummary.helpers"

// Components
import { JourneyContext } from "../../journey.context"
import { QuotationSummaryContext } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/context/quotationSummary.context"
import {
  StyledButton,
  TableRow,
  TableCell,
  TableColumn,
  Strong,
  ALink,
  ALinkDummy,
  ALabel,
  IndicatorWrapper
} from "./quotationSummaryTable.styles"
import {
  getHospitalListHelperText,
  getPremiumHelperText
} from "../JourneyPage/pages/PMI/Quote/QuoteComparison/quoteComparison.helpers"

const Quote = ({ quote }) => {
  const environment = process.env.NODE_ENV

  const t = useTranslations()
  const { data: journeyData } = useContext(JourneyContext)
  const {
    selectedQuote,
    copyQuoteLoading,
    pushQuoteToClientLoading,
    availableOptionsLoading,
    tableLoading,
    fetchQuoteLoading,
    setDemandsNeedsEdit,
    onQuoteCopy,
    onPushToPortal,
    setDeleteQuoteModal,
    getQuoteLoadingState,
    setSelectedQuote,
    setSelectedEditQuote,
    setQuoteNetworkDocuments
  } = useContext(QuotationSummaryContext)

  const isJourneyLocked = get(journeyData, "journey.locked", false)

  const isVitality = get(quote, "provider.provider_key", false) === "VITALITYHEALTH"
  const isVitalityTravelCoverNotAvailable =
    isVitality &&
    get(quote, "applicants", []).filter(applicant => {
      const dob = moment.utc(get(applicant, "date_of_birth"), "YYYY-MM-DD HH:mm").local()
      const age = moment().diff(dob, "years")
      return age >= 65
    }).length > 0

  const isSwitchSave = get(quote, "quote_type", null) === "switch_save"

  const isLoading =
    getQuoteLoadingState(quote.id) ||
    copyQuoteLoading ||
    pushQuoteToClientLoading ||
    tableLoading ||
    fetchQuoteLoading ||
    availableOptionsLoading
  const isQuoteSelected = selectedQuote?.id === quote.id

  const footerRowProps = {
    bgColour: isQuoteSelected ? "green" : "veryFaintGrey",
    borderColour: isQuoteSelected ? "white" : "faintGrey"
  }

  const bodyRowProps = {
    isQuoteSelected
  }

  // Generate Brochure Document
  const [generateBrochureDocument, { loading: generateBrochureDocumentLoading }] = usePost({
    endpoint: "/journeys/:journey/generate-document/brochure/:quoteId",
    params: {
      journey: get(journeyData, "journey.slug", ""),
      quoteId: get(quote, "id", "")
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      if (filePath) {
        window.open(filePath, "_blank")
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("QUOTATION_DOCUMENTATION_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Generate Demands & Needs Document
  const [generateDemandsDocument, { loading: generateDemandsDocumentLoading }] = usePost({
    endpoint: "/journeys/:journey/generate-document/demands_and_needs/:quoteId",
    params: {
      journey: get(journeyData, "journey.slug", ""),
      quoteId: get(quote, "id", "")
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      if (filePath) {
        window.open(filePath, "_blank")
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("QUOTATION_DOCUMENTATION_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Generate Application Form
  const [generateApplicationFormDocument, { loading: generateApplicationFormDocumentLoading }] =
    usePost({
      endpoint: "/journeys/:journey/generate-document/application_form/:quoteId",
      params: {
        journey: get(journeyData, "journey.slug", ""),
        quoteId: get(quote, "id", "")
      },
      onCompleted: res => {
        const filePath = get(res, "data.path", "")
        if (filePath) {
          window.open(filePath, "_blank")
        }
      },
      onError: () => {
        addAlert({
          type: "error",
          message: t("QUOTATION_DOCUMENTATION_GENERATE_ERROR"),
          dismissible: true,
          timeout: 5
        })
      }
    })

  const hospitalList = useMemo(() => {
    if (
      get(quote, "provider.provider_key") === "AXA" &&
      get(quote, "product_name") === "PERSONALHEALTH" &&
      get(quote, "hospital_list") === "Fixed List" &&
      get(quote, "options", []).find(
        option => get(option, "name") === "Guided Option" && get(option, "value") === "Yes"
      )
    ) {
      return "Guided"
    }
    return get(quote, "hospital_list", "-") || "-"
  }, [quote])

  const ViewDisable = useCallback(
    () => (
      <ALabel data-testid="quotation_summary_table_quote_brochure">
        <Icon
          icon="filedocument"
          backgroundColour={colours.blue}
          colour="#8CBED1"
          margin="0 0.7rem 0 0"
        />
        View
      </ALabel>
    ),
    []
  )

  const PrintDisable = useCallback(
    () => (
      <ALabel
        margin="0"
        name="print_application"
        colour="white"
        data-testid="quotation_summary_table_quote_application_form"
      >
        <Icon icon="printer" colour={colours.white} margin="0 0.4rem 0 0" />{" "}
        {isVitality ? "N/A" : "Print application"}
      </ALabel>
    ),
    [isVitality]
  )

  return (
    <TableColumn key={uuid()} data-testid="quotation_summary_table_quote_body">
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>{t(get(journeyData, "journey.product_type", "-"))}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>
            {get(quote, "applicants", [])
              .map(applicant => `${applicant.first_name} ${applicant.last_name}`)
              .join(", ")}
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>
            {moment
              .utc(get(quote, "start_date"), "YYYY-MM-DDTHH:mm:ssZ")
              .local()
              .format("Do MMMM YYYY")}
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>{capitalize(t(get(quote, "payment_frequency", "-")))}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>
            {isLoading || generateBrochureDocumentLoading ? (
              <ALinkDummy isLoading={isLoading}>
                <Icon
                  icon="filedocument"
                  backgroundColour={colours.blue}
                  colour={colours.white}
                  margin="0 0.7rem 0 0"
                />
                {generateBrochureDocumentLoading ? "Fetching" : "View"}
              </ALinkDummy>
            ) : (
              <>
                {isJourneyLocked ? (
                  <ViewDisable />
                ) : (
                  <ALink
                    onClick={() =>
                      generateBrochureDocument({
                        body: { selected_quote: get(quote, "id", ""), type: "brochure" }
                      })
                    }
                    data-testid="quotation_summary_table_quote_brochure"
                  >
                    <Icon
                      icon="filedocument"
                      backgroundColour={colours.blue}
                      colour={colours.white}
                      margin="0 0.7rem 0 0"
                    />
                    View
                  </ALink>
                )}
              </>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} hasMultiCols>
        <TableCell>
          <StyledButton
            appearance="primaryInline"
            type="inline-button"
            leadingIcon="playlist-edit"
            onClick={() => setDemandsNeedsEdit(quote)}
            iconSize="1rem"
            margin="0"
            name="demands_needs_edit"
            isLoading={isLoading}
            isDisabled={isLoading || isJourneyLocked}
          >
            Edit
          </StyledButton>
        </TableCell>
        <TableCell>
          {isLoading || generateDemandsDocumentLoading ? (
            <ALinkDummy isLoading={isLoading}>
              <Icon
                icon="filedocument"
                backgroundColour={colours.blue}
                colour={colours.white}
                margin="0 0.7rem 0 0"
              />
              {generateDemandsDocumentLoading ? "Fetching" : "View"}
            </ALinkDummy>
          ) : (
            <>
              {isJourneyLocked ? (
                <ViewDisable />
              ) : (
                <ALink
                  onClick={() =>
                    generateDemandsDocument({
                      body: { selected_quote: get(quote, "id", ""), type: "demands_and_needs" }
                    })
                  }
                  data-testid="quotation_summary_table_quote_demands_and_needs"
                >
                  <Icon
                    icon="filedocument"
                    backgroundColour={colours.blue}
                    colour={colours.white}
                    margin="0 0.7rem 0 0"
                  />
                  View
                </ALink>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow {...bodyRowProps}>
        {isLoading && <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />}
        {!isLoading && !isSwitchSave && (
          <TableCell>
            <IndicatorWrapper>
              {getHospitalListHelperText(get(quote, "flags", {})).map(helper => (
                <P helperPosition="left" margin="0" {...helper} />
              ))}
              <P className="quote-data">{hospitalList}</P>
            </IndicatorWrapper>
          </TableCell>
        )}
        {!isLoading && isSwitchSave && (
          <TableCell>{get(quote, "provider_hospital_list", null) || "-"}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading && <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />}
        {!isLoading && !isSwitchSave && (
          <TableCell>
            {find(quote.options, _ => _.name === "Excess", {})?.value === "£0"
              ? "zero"
              : find(quote.options, _ => _.name === "Excess", {})?.value || "-"}
          </TableCell>
        )}
        {!isLoading && isSwitchSave && (
          <TableCell>{get(quote, "variant_options.Excess", null) || "-"}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading && <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />}
        {!isLoading && !isSwitchSave && (
          <TableCell>
            {get(quote, "outpatient_limit") === "Nil"
              ? "None"
              : get(quote, "outpatient_limit", "-")}
          </TableCell>
        )}
        {!isLoading && isSwitchSave && (
          <TableCell>{get(quote, "variant_options.Out-patient", null) || "-"}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading && <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />}
        {!isLoading && !isSwitchSave && <TableCell>{get(quote, "cancer_cover", "-")}</TableCell>}
        {!isLoading && isSwitchSave && (
          <TableCell>{get(quote, "variant_options.Cancer", null) || "-"}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading && <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />}
        {!isLoading && !isSwitchSave && (
          <TableCell>{get(quote, "dental_cover", false) || "None"}</TableCell>
        )}
        {!isLoading && isSwitchSave && (
          <TableCell>
            {get(quote, "variant_options.Optical, Dental & Audiological", null) || "-"}
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading && <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />}
        {!isLoading && !isSwitchSave && <TableCell>{get(quote, "therapies_cover", "-")}</TableCell>}
        {!isLoading && isSwitchSave && (
          <TableCell>{get(quote, "variant_options.Therapies", null) || "-"}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading && <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />}
        {!isLoading && !isSwitchSave && (
          <TableCell>{get(quote, "psychiatric_cover", "-")}</TableCell>
        )}
        {!isLoading && isSwitchSave && (
          <TableCell>{get(quote, "variant_options.Psychiatric", null) || "-"}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading && <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />}
        {!isLoading && !isSwitchSave && (
          <TableCell>
            {isVitalityTravelCoverNotAvailable ? (
              <P
                margin="0"
                fontSize="1.4rem"
                helperPosition="left"
                helperType="warning"
                helperText={getWarningMessage("vitality_travel_cover_travel")?.content}
                helperTitle={getWarningMessage("vitality_travel_cover_travel")?.title}
              >
                N/A
              </P>
            ) : (
              <P
                margin="0"
                fontSize="1.4rem"
                helperPosition="left"
                helperText={
                  (isVitality &&
                    find(quote.options, _ => _.name === "Worldwide Travel", {})?.value === "Yes" &&
                    getWarningMessage("vitality_travel_cover_travel")?.content) ||
                  (get(quote, "provider.provider_key", false) === "AXA" &&
                    find(quote.options, _ => _.name === "Travel Cover", {})?.value !== "No" &&
                    getWarningMessage("axa_travel_cover_travel")?.content) ||
                  ""
                }
                helperTitle={
                  (isVitality &&
                    find(quote.options, _ => _.name === "Worldwide Travel", {})?.value === "Yes" &&
                    getWarningMessage("vitality_travel_cover_travel")?.title) ||
                  (get(quote, "provider.provider_key", false) === "AXA" &&
                    find(quote.options, _ => _.name === "Travel Cover", {})?.value !== "No" &&
                    getWarningMessage("axa_travel_cover_travel")?.title) ||
                  ""
                }
              >
                {find(quote.options, _ => _.name === "Travel Cover", {})?.value ||
                  find(quote.options, _ => _.name === "Travel", {})?.value ||
                  find(quote.options, _ => _.name === "Worldwide Travel", {})?.value ||
                  "N/A"}
              </P>
            )}
          </TableCell>
        )}
        {!isLoading && isSwitchSave && (
          <TableCell>{get(quote, "variant_options.Worldwide Travel", null) || "-"}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>
            {find(quote.options, _ => _.name === "Protected NCD", {})?.value || "N/A"}
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>
            {find(quote.options, _ => _.name === "Six Week Option", {})?.value || "N/A"}
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>{get(quote, "guided_care", "N/A")}</TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps} scrollableCol>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>
            {!get(journeyData, "page.conditionals.skip_affordable_budget", false) ? (
              <Icon
                icon={get(quote, "in_budget", false) ? "check-circle" : "close-circle"}
                colour={get(quote, "in_budget", false) ? colours.green : colours.red}
              />
            ) : (
              "N/A"
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps}>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>
            <IndicatorWrapper>
              {getPremiumHelperText(get(quote, "flags", {}), environment).map(helper => (
                <P helperPosition="left" margin="0" {...helper} />
              ))}
              <Strong className="strong-text">{`£${parseFloat(
                get(quote, "monthly_premium")
              ).toFixed(2)}`}</Strong>
            </IndicatorWrapper>
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps}>
        {isLoading ? (
          <Skeleton appearance="light" lineHeight="2rem" borderRadius="0.5rem" />
        ) : (
          <TableCell>
            <IndicatorWrapper>
              {getPremiumHelperText(get(quote, "flags", {}), environment).map(helper => (
                <P helperPosition="left" margin="0" {...helper} />
              ))}
              <Strong className="strong-text">{`£${parseFloat(get(quote, "annual_premium")).toFixed(
                2
              )}`}</Strong>
            </IndicatorWrapper>
          </TableCell>
        )}
      </TableRow>
      <TableRow {...bodyRowProps}>
        <TableCell>
          <Toggle
            label=""
            margin="0"
            name={`push_to_portal_toggle_${get(quote, "id")}`}
            isActiveState={!!get(quote, "sent_to_client", false)}
            isSwitch
            options={[
              { label: "", value: false },
              { label: "", value: true }
            ]}
            labelWidth="0em"
            value={!!get(quote, "sent_to_client", false)}
            isDisabled={isLoading || isJourneyLocked || get(quote, "sent_to_client", false)}
            onChange={() => onPushToPortal(quote.id)}
            toggleSize="small"
          />
        </TableCell>
      </TableRow>
      <TableRow {...footerRowProps} minHeight={selectedQuote ? "12.35rem" : "5.5rem"}>
        <TableCell flexColumn>
          {isQuoteSelected && (
            <SmallText appearance="light" align="center" margin="0 0 1rem">
              You have selected this quote
            </SmallText>
          )}
          <StyledButton
            appearance={isQuoteSelected ? "whiteInline" : "primaryInline"}
            type="inline-button"
            leadingIcon={isQuoteSelected ? "minus" : "plus"}
            onClick={() => setSelectedQuote(!isQuoteSelected ? quote : null)}
            iconSize="1.25rem"
            margin={isQuoteSelected ? "0 auto 1rem auto" : "0 auto"}
            name="select_quote"
            isLoading={isLoading}
            isDisabled={isLoading || isJourneyLocked}
          >
            {isQuoteSelected ? "Deselect" : "Select"}
          </StyledButton>
          {isQuoteSelected && (
            <>
              {isLoading || generateApplicationFormDocumentLoading ? (
                <ALinkDummy margin="0" colour="white" isLoading={isLoading}>
                  <Icon icon="printer" colour={colours.white} margin="0 0.4rem 0 0" />
                  {generateApplicationFormDocumentLoading ? "Fetching" : "Print application"}
                </ALinkDummy>
              ) : (
                <>
                  {isJourneyLocked || isVitality ? (
                    <PrintDisable />
                  ) : (
                    <ALink
                      onClick={() => generateApplicationFormDocument()}
                      margin="0"
                      name="print_application"
                      colour="white"
                      data-testid="quotation_summary_table_quote_application_form"
                    >
                      <Icon icon="printer" colour={colours.white} margin="0 0.4rem 0 0" /> Print
                      application
                    </ALink>
                  )}
                </>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow {...footerRowProps}>
        <TableCell>
          <StyledButton
            appearance={isQuoteSelected ? "whiteInline" : "primaryInline"}
            type="inline-button"
            leadingIcon="playlist-edit"
            onClick={() => setSelectedEditQuote(quote)}
            iconSize="1.25rem"
            margin="0 auto"
            name="edit_quote"
            isLoading={isLoading}
            isDisabled={isLoading || isJourneyLocked}
          >
            Edit
          </StyledButton>
        </TableCell>
      </TableRow>
      <TableRow {...footerRowProps} minHeight="9.35rem">
        <TableCell noFlex textTransform="none">
          <StyledButton
            appearance={isQuoteSelected ? "whiteInline" : "primaryInline"}
            type="inline-button"
            leadingIcon="plus-circle-multiple-outline"
            onClick={() => onQuoteCopy(quote.id)}
            iconSize="1.25rem"
            margin="0 auto"
            name="copy_quote"
            isLoading={isLoading}
            isDisabled={isLoading || isJourneyLocked}
          >
            Copy
          </StyledButton>
          <SmallText
            colour={isQuoteSelected ? colours.veryFaintGrey : colours.lightGrey}
            align="center"
            margin="1rem 0 0"
          >
            Does not affect the original quote
          </SmallText>
        </TableCell>
      </TableRow>
      <TableRow {...footerRowProps}>
        <TableCell>
          <StyledButton
            appearance={isQuoteSelected ? "whiteInline" : "errorInline"}
            type="inline-button"
            leadingIcon="close"
            onClick={() => setDeleteQuoteModal(true, quote.id)}
            iconSize="1.25rem"
            margin="0 auto"
            name="delete_quote"
            isLoading={isLoading}
            isDisabled={isLoading || isJourneyLocked}
          >
            Delete
          </StyledButton>
        </TableCell>
      </TableRow>
      <TableRow {...footerRowProps}>
        <TableCell noFlex>
          <StyledButton
            appearance={isQuoteSelected ? "whiteInline" : "primaryInline"}
            type="inline-button"
            leadingIcon="filedocument"
            onClick={() => setQuoteNetworkDocuments(quote)}
            iconSize="1.25rem"
            margin="0 auto"
            name="quote_network_documents"
            isLoading={isLoading}
            isDisabled={isLoading || isJourneyLocked}
          >
            View
          </StyledButton>
        </TableCell>
      </TableRow>
    </TableColumn>
  )
}

Quote.propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
      })
    )
  })
}

export default Quote
