import React, { useMemo } from "react"
import PropTypes from "prop-types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { colours } from "@4cplatform/elements/Helpers"
import { BarChartWrapper } from "./miReports.style"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = ({ labels, data, formatter }) => {
  const maxValueIndex = useMemo(() => data.indexOf(Math.max(...data)), [data])
  const options = useMemo(
    () => ({
      responsive: true,
      layout: {
        padding: {
          top: 24
        }
      },
      plugins: {
        legend: { display: false },
        datalabels: {
          anchor: "end",
          align: "end",
          formatter
        }
      },
      scales: {
        y: {
          ticks: {
            callback: formatter
          }
        }
      }
    }),
    [formatter]
  )

  return (
    <BarChartWrapper>
      <Bar
        plugins={[ChartDataLabels]}
        options={options}
        data={{
          labels,
          datasets: [
            {
              data,
              label: "Total",
              backgroundColor: labels.map((_, index) => {
                if (index === maxValueIndex) return colours.orange
                return index % 2 ? colours.adminBlue : colours.paleGreen
              })
            }
          ]
        }}
      />
    </BarChartWrapper>
  )
}

BarChart.defaultProps = {
  labels: [],
  data: [],
  formatter: null
}

BarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.number),
  formatter: PropTypes.func
}

export default BarChart
