import { number } from "yup"
import { get } from "lodash"

export const config = data => ({
  title: "Hospital preference",
  sections: [
    {
      key: "preferred_hospital",
      components: [
        {
          key: "preferred_hospital",
          initialValue: get(data, "page.data.preferred_hospital", null),
          validationSchema: number().integer().positive().nullable(),
          label: "Prefered hospital"
        }
      ]
    }
  ]
})
