const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH": {
      return {
        ...state,
        search: action.sortcode
      }
    }
    case "SET_FRESH": {
      return {
        ...state,
        search: "",
        display: "fresh"
      }
    }
    case "SET_FILLED_IN": {
      return {
        ...state,
        display: "filled_in"
      }
    }
    default:
      return state
  }
}

export default reducer
