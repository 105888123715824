import React, { useContext, useReducer } from "react"
import PropTypes from "prop-types"
import { get, isEmpty } from "lodash"
import { usePost, usePatch, ApiError } from "@4cplatform/elements/Api"
import { AuthContext } from "@4cplatform/elements/Auth"
import { useTranslations } from "@4cplatform/elements/Translations"
import { PageContext } from "@4cplatform/elements/Organisms"
import { Provider } from "@4cplatform/elements/Templates/MyAccountDetails"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import reducer from "./details.reducer"

const SelfServiceProvider = ({ children }) => {
  const { authUserRefetch, logout } = useContext(AuthContext)
  const { selfServiceData, selfServiceLoading, selfServiceUpdate } = useContext(PageContext)

  const t = useTranslations()

  // Manage state, using authUser as the default data value
  const [{ detailsOpen, changePasswordOpen, twofaOpen, twofaGoogle }, dispatch] = useReducer(
    reducer,
    {
      detailsOpen: false,
      changePasswordOpen: false,
      twofaOpen: false,
      twofaGoogle: {}
    }
  )

  // Update authenticated user
  const [onUpdateDetailsSubmit, { loading: updateLoading, error: updateError }] = usePatch({
    endpoint: "/self-service",
    onCompleted: res => {
      // Update the user in the Page state, add a success message, and close the modal
      const newUser = get(res, "data", {})
      selfServiceUpdate(newUser)
      addAlert({
        type: "success",
        message: "Your details have been successfully updated",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "detailsOpen", value: false })
    }
  })

  // Setup 2fa
  const [onTwofaSetup, { loading: twofaSetupLoading, error: twofaSetupError }] = usePost({
    endpoint: "/auth/two-factor-authentication/setup",
    onCompleted: res => {
      const google = get(res, "data", {})
      if (!isEmpty(google)) {
        dispatch({ type: "UPDATE_VALUE", key: "twofaGoogle", value: google })
      }
      authUserRefetch()
      addAlert({
        type: "success",
        message: t("TWOFA_SETUP_SUCCESS")
      })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("TWOFA_SETUP_ERROR")
      })
    }
  })

  // Complete 2fa
  const [onTwofaComplete, { loading: twofaCompleteLoading }] = usePost({
    endpoint: "/auth/two-factor-authentication/complete-setup",
    onCompleted: () => {
      authUserRefetch()
      dispatch({ type: "UPDATE_VALUE", key: "twofaOpen", value: false })
      if (!isEmpty(twofaGoogle)) {
        dispatch({ type: "UPDATE_VALUE", key: "twofaGoogle", value: {} })
      }
      addAlert({
        type: "success",
        message: t("TWOFA_COMPLETE_SUCCESS")
      })
    },
    onError: ({ message, validation }) => {
      if (message === "VALIDATION_FAILED" && validation?.code?.length) {
        const twoFaMessage = validation.code[0]
        addAlert({
          type: "error",
          message: t(twoFaMessage)
        })

        if (twoFaMessage === "TWO_FA_MAX_TRIES_REACHED") {
          logout()
        }
      }
    }
  })

  // Reset 2fa
  const [onTwofaReset, { loading: twofaResetLoading, error: twofaResetError }] = usePost({
    endpoint: "/auth/two-factor-authentication/reset",
    onCompleted: () => {
      authUserRefetch()
      addAlert({
        type: "success",
        message: t("TWOFA_RESET_SUCCESS")
      })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("TWOFA_RESET_ERROR")
      })
    }
  })

  // Change password
  const [onChangePassword, { loading: changePasswordLoading, error: changePasswordError }] =
    usePost({
      endpoint: "/auth/self-service/change-password",
      onCompleted: () => {
        authUserRefetch()
        dispatch({ type: "UPDATE_VALUE", key: "changePasswordOpen", value: false })
        addAlert({
          type: "success",
          message: t("CHANGE_PASSWORD_SUCCESS")
        })
      },
      onError: ({ validation }) => {
        if (get(validation, "password", []).includes("PASSWORD_ALREADY_USED")) {
          addAlert({
            type: "error",
            message: t("PASSWORD_ALREADY_USED")
          })
        } else {
          addAlert({
            type: "error",
            message: t("CHANGE_PASSWORD_ERROR")
          })
        }
      }
    })

  // Resend email change one time link
  const [resendEmailChange, { loading: resendEmailChangeLoading, error: resendEmailChangeError }] =
    usePost({
      endpoint: "/self-service/resend-email-change",
      onCompleted: () => {
        addAlert({
          type: "success",
          message: t("CHANGE_EMAIL_RESEND_SUCCESS")
        })
      },
      onError: () => {
        addAlert({
          type: "error",
          message: t("CHANGE_EMAIL_RESEND_ERROR")
        })
      }
    })

  // Resend password change one time link
  const [
    resendPasswordChange,
    { loading: resendPasswordLoading, error: resendPasswordChangeError }
  ] = usePost({
    endpoint: "/auth/self-service/resend-change-password",
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("CHANGE_PASSWORD_RESEND_SUCCESS")
      })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("CHANGE_PASSWORD_RESEND_ERROR")
      })
    }
  })

  return (
    <Provider
      value={{
        user: selfServiceData,
        userLoading: selfServiceLoading,
        onUpdateDetailsSubmit,
        updateLoading,
        toggleUpdateDetails: value => dispatch({ type: "UPDATE_VALUE", key: "detailsOpen", value }),
        detailsOpen,
        changePasswordOpen,
        toggleChangePassword: value =>
          dispatch({ type: "UPDATE_VALUE", key: "changePasswordOpen", value }),
        onChangePassword,
        changePasswordLoading,
        changePasswordError,
        twofaOpen,
        toggleTwofa: value => dispatch({ type: "UPDATE_VALUE", key: "twofaOpen", value }),
        onTwofaSetup,
        twofaSetupLoading,
        twofaGoogle,
        onTwofaComplete,
        twofaCompleteLoading,
        onTwofaReset,
        twofaResetLoading,
        resendEmailChange,
        resendEmailChangeLoading,
        resendPasswordChange,
        resendPasswordLoading
      }}
    >
      {children}
      <ApiError
        error={
          updateError ||
          twofaSetupError ||
          twofaResetError ||
          resendEmailChangeError ||
          resendPasswordChangeError
        }
      />
    </Provider>
  )
}

SelfServiceProvider.defaultProps = {
  children: null
}

SelfServiceProvider.propTypes = {
  children: PropTypes.any
}

export default SelfServiceProvider
