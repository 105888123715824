import React, { useContext, Fragment } from "react"
import { capitalize, get, isEmpty } from "lodash"
import { v4 as uuid } from "uuid"
import { colours } from "@4cplatform/elements/Helpers"
import { Modal, Collapse, Skeleton, Button } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"
import { Container } from "@4cplatform/elements/Atoms"
import { QuerySelect } from "@4cplatform/elements/Forms"

// Helpers
import { JourneyContext } from "../../journey.context"
import { renderProviderOptions } from "../../../../Helpers"

// Components
import { Search } from "../../../../Molecules"
import { Divider, ResultsContainer, CloseButton, LoadingLine } from "./faqModal.styles"

const FaqModal = () => {
  const { setFaqModal, faqQuery, faqQueryResults, faqsLoading, setFaqQuery } =
    useContext(JourneyContext)

  const search = get(faqQuery, "search", "")

  const handleChange = React.useCallback(
    val => {
      setFaqQuery({ ...faqQuery, search: val })
    },
    [setFaqQuery, faqQuery]
  )

  return (
    <Modal title="FAQs" hasPadding={false} onClose={() => setFaqModal(false)}>
      <Container
        width="100%"
        margin="0"
        padding="2rem"
        backgroundColour={get(colours, "veryFaintGrey")}
      >
        <Search
          width="100%"
          charLimit={1}
          name="search_frequently_asked_questions"
          label="Search frequently asked questions"
          placeholder="Enter a search term (e.g payments)"
          handleChange={handleChange}
          onCancel={() => setFaqQuery({ ...faqQuery, search: "" })}
        />
        <QuerySelect
          name="provider"
          label="Provider"
          noun={{ singular: "provider", plural: "providers" }}
          endpoint="/providers"
          render={data => renderProviderOptions(data, false, true)}
          onChange={val => setFaqQuery({ ...faqQuery, provider: val })}
          value={get(faqQuery, "provider", "")}
        />
      </Container>
      <ResultsContainer
        margin="0"
        height={`${isEmpty(faqQueryResults) && !faqsLoading ? "8rem" : "40rem"}`}
        padding="2rem"
      >
        {faqsLoading && <Skeleton count={6} lineHeight="4rem" wrapper={LoadingLine} />}
        {!isEmpty(faqQueryResults) &&
          !faqsLoading &&
          faqQueryResults.map(result => (
            <Fragment key={uuid()}>
              <Collapse
                headerContent={
                  <>
                    <P
                      margin="0.5rem 0"
                      colour={get(colours, "blue")}
                      dangerouslySetInnerHTML={{
                        __html: get(result, "question", "").replace(
                          new RegExp(search, "g"),
                          `<strong>${search}</strong>`
                        )
                      }}
                    />
                    <>{capitalize(get(result, "provider_key", "General"))}</>
                  </>
                }
                bodyContent={
                  <P
                    margin="1.2rem 0"
                    fonts-zize="1.4rem"
                    dangerouslySetInnerHTML={{
                      __html: get(result, "answer", "")
                    }}
                  />
                }
              />
              {faqQueryResults.length - 1 !== faqQueryResults.indexOf(result) && <Divider />}
            </Fragment>
          ))}
        {isEmpty(faqQueryResults) && !faqsLoading && (
          <P margin="1rem 0">No data is available for display</P>
        )}
      </ResultsContainer>
      <CloseButton>
        <Button trailingIcon="close" appearance="error" onClick={() => setFaqModal(false)}>
          Close
        </Button>
      </CloseButton>
    </Modal>
  )
}

export default FaqModal
