import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import queryString from "query-string"
import { useLocation, useHistory } from "react-router-dom"
import { useGet, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { Provider } from "../../../../../UI/Templates/QuoteAdmin"
import reducer from "./quotes.reducer"
import { NetworkManageContext } from "../../../context/manage.context"
import { getOrderBy, getPolicyStatus } from "../../../../../UI/Helpers"
import useActionHook from "../../../../../UI/Templates/PolicyAdmin/policies.actions.hooks"

const NAMES = ["client_name", "organisation_name", "sales_agent", "product_name"]

const QuotesProvider = ({ children }) => {
  const { network, networkLoading } = React.useContext(NetworkManageContext)
  const t = useTranslations()
  const { search: lSearch } = useLocation()
  const history = useHistory()
  const qs = queryString.parse(lSearch)
  const queryStatus = get(qs, "status", "")
  const statuses = getPolicyStatus(queryStatus)
  // State
  const [
    {
      data,
      viewData,
      page,
      perPage,
      search,
      sorting,
      filter,
      total,
      selectedQuote,
      showSimulated,
      hasShowSimulated,
      status
    },
    dispatch
  ] = React.useReducer(reducer, {
    data: [],
    viewData: {},
    page: 1,
    perPage: 10,
    search: "",
    sorting: { direction: "asc", dataKey: "organisation" },
    filter: "client_name",
    total: null,
    selectedQuote: null,
    showSimulated: get(qs, "simulated", "") === "true",
    hasShowSimulated: true,
    status: statuses
  })

  const location = useLocation()

  const setFilter = val => dispatch({ type: "UPDATE_VALUE", key: "filter", value: val })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  useActionHook(NAMES, location.search, setFilter, setSearch)

  // Set the query search if search is truthy
  let querySearch = {}
  if (search) {
    querySearch = {
      [filter]: search
    }
  }

  // Index Quotes
  const { loading: queryLoading, error: queryError } = useGet({
    endpoint: "/networks/:slug/quotes",
    skip: networkLoading || !get(network, "slug", null),
    params: {
      slug: get(network, "slug")
    },
    query: {
      limit: perPage,
      status,
      page,
      order_by: getOrderBy(sorting),
      simulated: showSimulated,
      ...querySearch
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("QUOTES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Get Quote
  const { loading: viewLoading, error: viewError } = useGet({
    endpoint: "/quotes/:slug",
    skip: !selectedQuote,
    params: {
      slug: get(selectedQuote, "slug", "")
    },
    query: {
      with: ["client", "salesAgent", "provider"]
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: newData })
    }
  })

  return (
    <Provider
      value={{
        data,
        viewData,
        search,
        setSearch,
        showSimulated,
        hasShowSimulated,
        setShowSimulated: val => {
          dispatch({ type: "UPDATE_VALUE", key: "showSimulated", value: val })
          history.replace({ search: queryString.stringify({ ...qs, simulated: val }) })
        },
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        pagination: { total, page, perPage },
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        sorting,
        setFilter,
        filter,
        queryLoading,
        viewLoading,
        selectedQuote,
        onQuoteSelect: val => dispatch({ type: "UPDATE_VALUE", key: "selectedQuote", value: val }),
        onQuoteDeselect: () => dispatch({ type: "UPDATE_VALUE", key: "selectedQuote", value: null })
      }}
    >
      {children}
      <ApiError error={queryError || viewError} />
    </Provider>
  )
}

QuotesProvider.defaultProps = {
  children: null
}

QuotesProvider.propTypes = {
  children: PropTypes.any
}

export default QuotesProvider
