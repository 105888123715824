import React, { useContext } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get, map, pickBy } from "lodash"
import { useFormik } from "formik"
import { Button } from "@4cplatform/elements/Molecules"
import { formatLabelForTestID } from "@4cplatform/elements/Helpers"

// Helpers
import { ApplicantsContext } from "./applicants.context"
import { addEditApplicantModel as validationSchema } from "./applicants.helpers"
// Components
import { Tab, Tabs } from "../../../../../../../../Organisms"
import ApplicantDetails from "./applicants.applicant.form.details"
import ApplicantQuestions from "./applicants.applicant.form.questions"
import { ActionButtons } from "./applicants.styles"

const ApplicantForm = ({ type, isEdit, applicant }) => {
  const {
    addApplicant,
    updateApplicant,
    updateApplicantLoading,
    addApplicantLoading,
    updateActiveTabID,
    activeTabID
  } = useContext(ApplicantsContext)
  // Set initial values for form based on context
  const isDependant = type === "dependant"
  let initialValues = {}
  if (isDependant) {
    initialValues = {
      child: isEdit ? get(applicant, "child", 0) === 1 : true,
      occupation: get(applicant, "child", 0) === 1 ? "OTHER" : get(applicant, "occupation", "OTHER")
    }
  } else {
    initialValues = {
      child: false,
      occupation: isEdit ? get(applicant, "occupation", "OTHER") : "OTHER"
    }
  }

  // Formik instance
  const addEditApplicantFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      applicant: {
        gender_at_birth: isEdit ? get(applicant, "gender_at_birth", "") : "",
        title: isEdit ? get(applicant, "title", "") : "",
        first_name: isEdit ? get(applicant, "first_name", "") : "",
        middle_names: isEdit ? get(applicant, "middle_names", "") : "",
        last_name: isEdit ? get(applicant, "last_name", "") : "",
        email_address: isEdit ? get(applicant, "email_address", "") : "",
        date_of_birth: isEdit
          ? moment
              .utc(get(applicant, "date_of_birth", ""), "YYYY-MM-DDTHH:mmZ")
              .local()
              .format("YYYY-MM-DD")
          : "",
        ...initialValues
      },
      questions: {
        permanent_uk_resident: isEdit ? get(applicant, "data.permanent_uk_resident", "") : "",
        covered_with_a_gp_and_access_to_medical_records: isEdit
          ? get(applicant, "data.covered_with_a_gp_and_access_to_medical_records", "")
          : "",
        pmi_required_to_fulfil_reqs_or_visa: isEdit
          ? get(applicant, "data.pmi_required_to_fulfil_reqs_or_visa", "")
          : "",
        tobacco_products_within_last_2_years: isEdit
          ? get(applicant, "data.tobacco_products_within_last_2_years", "")
          : "",
        permission_to_add_member: isEdit ? get(applicant, "data.permission_to_add_member", "") : ""
      }
    },
    validationSchema,
    onSubmit: args => {
      if (isEdit) {
        updateApplicant({
          ...args,
          applicant: {
            ...get(args, "applicant", {}),
            type
          }
        })
      } else {
        addApplicant(
          pickBy(
            {
              ...args,
              applicant: {
                ...pickBy(
                  get(args, "applicant", {}),
                  v => v !== "" && v !== null && v !== undefined
                ),
                type
              }
            },
            v => v !== "" && v !== null && v !== undefined
          )
        )
      }
    }
  })

  const formik = { ...addEditApplicantFormik, validationSchema }
  const { handleSubmit } = formik

  const keys = Object.keys(formik.errors)
  const onSave = () => {
    handleSubmit()
    if (keys.includes("applicant")) {
      updateActiveTabID(formatLabelForTestID("Personal details"))
    } else if (keys.includes("questions")) {
      updateActiveTabID(formatLabelForTestID("Questions"))
    }
  }

  const onNext = () => {
    if (activeTabID === "personal_details") updateActiveTabID(formatLabelForTestID("Questions"))
  }

  const onPrev = () => {
    if (activeTabID === "questions") updateActiveTabID(formatLabelForTestID("Personal details"))
  }

  const shouldTabBeDisabled = () => {
    const results = map(get(formik, "values.applicant", {}), (v, i) => {
      if (i !== "middle_names" && v === "") return true
      return false
    })
    return results.includes(true)
  }

  return (
    <>
      <Tabs
        type="modal"
        newActiveID={activeTabID}
        onTabChange={tabId => {
          if (tabId !== activeTabID) updateActiveTabID(tabId)
        }}
      >
        <Tab header="Personal details" isDisabled={shouldTabBeDisabled("personal_details")}>
          <ApplicantDetails type={type} formik={formik} />
        </Tab>
        <Tab header="Questions" isDisabled={shouldTabBeDisabled("questions")}>
          <ApplicantQuestions formik={formik} />
        </Tab>
      </Tabs>

      <ActionButtons>
        {activeTabID !== "personal_details" && (
          <Button name="previous" onClick={onPrev} leadingIcon="chevron-left">
            Previous
          </Button>
        )}

        <>
          {activeTabID !== "questions" && (
            <Button
              name="next"
              onClick={onNext}
              isLoading={addApplicantLoading || updateApplicantLoading}
              trailingIcon="chevron-right"
            >
              Next
            </Button>
          )}
          {activeTabID === "questions" && (
            <Button
              name="save"
              onClick={onSave}
              isLoading={addApplicantLoading || updateApplicantLoading}
              trailingIcon="check"
            >
              Save
            </Button>
          )}
        </>
      </ActionButtons>
    </>
  )
}

ApplicantForm.defaultProps = {
  isEdit: false,
  applicant: null
}

ApplicantForm.propTypes = {
  isEdit: PropTypes.bool,
  type: PropTypes.oneOf(["partner", "dependant", "primary"]).isRequired,
  applicant: PropTypes.object
}

export default ApplicantForm
