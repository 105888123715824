import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { ClientsContext } from "./clients.context"

// Components
import { ButtonsWrapper } from "./clients.styles"

const ClientsPanelHeaderButtons = ({ isWidePanel }) => {
  const { queryLoading, setPanelBodyContent } = useContext(ClientsContext)
  return (
    <ButtonsWrapper isWidePanel={isWidePanel}>
      <Button
        appearance="whiteGhost"
        trailingIcon="account-plus"
        margin={isWidePanel ? "0 2rem 0 0" : "2rem 0"}
        onClick={() => {
          setPanelBodyContent("Edit")
        }}
        isDisabled={queryLoading}
        name="edit_client"
      >
        Edit client
      </Button>
    </ButtonsWrapper>
  )
}

ClientsPanelHeaderButtons.defaultProps = {
  isWidePanel: false
}

ClientsPanelHeaderButtons.propTypes = {
  /**
   * Change buttons styling if it is wide panel
   */
  isWidePanel: PropTypes.bool
}

export default ClientsPanelHeaderButtons
