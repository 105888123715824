import React from "react"

// Components
import AgencyCodeRequestsPanelHeader from "./codeRequests.panel.header"
import AgencyCodeRequestsPanelBody from "./codeRequests.panel.body"

const AgencyCodeRequestView = () => (
  <>
    <AgencyCodeRequestsPanelHeader />
    <AgencyCodeRequestsPanelBody />
  </>
)

export default AgencyCodeRequestView
