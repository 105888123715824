import React from "react"
import { Container } from "@4cplatform/elements/Atoms"

// Components
import MyAccountTabs from "./myAccount.tabs"

const MyAccount = () => (
  <Container isPageContainer width="80%">
    <MyAccountTabs />
  </Container>
)

export default MyAccount
