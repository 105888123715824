import { get } from "lodash"

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_VALUE": {
      return {
        ...state,
        [action.key]: action.value
      }
    }
    case "REFETCH": {
      return {
        ...state,
        canQuery: true,
        shouldRefetch: true
      }
    }
    case "FETCH_COMPLETE": {
      return {
        ...state,
        data: action.data,
        canQuery: false,
        shouldRefetch: false
      }
    }
    case "SUBMIT_COMPLETE": {
      return {
        ...state,
        data: action.data,
        hasSubmitModal: false,
        fieldModal: false
      }
    }
    case "CHANGE_PAGE": {
      return {
        ...state,
        hasSubmitModal: false,
        fieldModal: false,
        canQuery: true,
        shouldRefetch: false,
        data: {
          page: null,
          journey: get(state, "data.journey", null)
        }
      }
    }
    default:
      return state
  }
}

export default reducer
