import React, { useContext } from "react"
import moment from "moment"
import { get, isEmpty } from "lodash"
import { AuthContext } from "@4cplatform/elements/Auth"
import { H2, SmallText } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { PanelHeader, Button } from "@4cplatform/elements/Molecules"
import { ConfigContext } from "@4cplatform/elements/Config"

// Helpers
import { getName } from "../../Helpers"
import { JourneysContext } from "./journeys.context"
import { getIconDetails } from "./journeys.helpers"

const QuotesPanelHeader = () => {
  const { viewData, viewLoading, generateAuditLog, generateAuditLogLoading } =
    useContext(JourneysContext)
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { canAccess } = useContext(AuthContext)
  const t = useTranslations()
  const isNotProviderOrUnderWriter = !canAccess(["UNDERWRITER", "PROVIDER_ADMIN"])
  const created = get(viewData, "journey.created_at", "")
    ? moment
        .utc(get(viewData, "journey.created_at", ""), "YYYY-MM-DDTHH:mmZ")
        .local()
        .format("DD/MM/YYYY HH:mm")
    : "-"
  const updated = get(viewData, "journey.updated_at", "")
    ? moment
        .utc(get(viewData, "journey.updated_at", ""), "YYYY-MM-DDTHH:mmZ")
        .local()
        .format("DD/MM/YYYY HH:mm")
    : "-"

  const status = get(viewData, "journey.status", "")
  const iconDetails = getIconDetails(status)
  return (
    <PanelHeader>
      <H2 appearance="light" isLoading={viewLoading} margin="2rem 0 1rem">
        {getName({
          data: get(viewData, "journey.client"),
          hasMiddle: true,
          hasTitle: true,
          globalTitles: GLOBAL_TITLES,
          hideOtherTitle: true
        })}
      </H2>
      <SmallText appearance="light" isLoading={viewLoading} margin="0 0 1rem">
        Created: {created}
      </SmallText>
      <SmallText appearance="light" isLoading={viewLoading} margin="0 0 1rem">
        Updated: {updated}
      </SmallText>
      {!isEmpty(iconDetails) && (
        <IconWithText appearance="light" content={t(status)} {...iconDetails} />
      )}
      <IconWithText
        icon="pound"
        appearance="light"
        content={get(viewData, "journey.reference", "-")}
        isLoading={viewLoading}
      />
      <IconWithText
        icon="briefcase"
        appearance="light"
        content={t(get(viewData, "journey.product_type", "-"))}
        isLoading={viewLoading}
      />
      <IconWithText
        icon="account-box-multiple-outline"
        appearance="light"
        isLoading={viewLoading}
        content={get(viewData, "journey.sales_agent.parent.name", "-")}
        margin="0"
      />
      <IconWithText
        margin="0 0 2rem 3rem"
        isLoading={viewLoading}
        appearance="light"
        icon="subdirectory-arrow-right"
        content={getName({
          data: get(viewData, "journey.sales_agent")
        })}
      />
      {isNotProviderOrUnderWriter && (
        <Button
          appearance="whiteGhost"
          trailingIcon="eye"
          onClick={() => generateAuditLog()}
          name="view_audit_log"
          isLoading={viewLoading || generateAuditLogLoading}
          isDisabled={viewLoading || generateAuditLogLoading}
          margin="1rem 0"
        >
          View Audit Log
        </Button>
      )}
    </PanelHeader>
  )
}

export default QuotesPanelHeader
