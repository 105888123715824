import React from "react"
import { PageContext } from "@4cplatform/elements/Organisms"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { UsersContext } from "./users.context"

// Components
import UsersPanelHeader from "./users.panel.header"
import EditUserForm from "./users.panel.edit.form"

const EditUser = () => {
  const { viewData } = React.useContext(UsersContext)
  const {
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)
  return (
    <>
      <UsersPanelHeader viewData={viewData} context="wide" />
      {!!viewData && panelStatus !== "closed" && (
        <PanelBody>
          <EditUserForm viewData={viewData} />
        </PanelBody>
      )}
    </>
  )
}

export default EditUser
