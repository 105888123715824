import { useEffect } from "react"
import { get } from "lodash"
import queryString from "query-string"

const useActionHook = (filterNames, search, setFilter, setSearch) => {
  const qs = queryString.parse(search)

  useEffect(() => {
    filterNames.forEach(_name => {
      const nameValue = get(qs, _name)
      if (nameValue) {
        setFilter(_name)
        setSearch(nameValue)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(qs, null, 0)])
}

export default useActionHook
