import { string, object } from "yup"
import { isValidPhoneNumber } from "react-phone-number-input"

// Helpers
import { validNameRegex } from "@4cplatform/elements/Helpers"

export const createContactModel = object().shape({
  name: string()
    .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
    .required("MISSING_REQUIRED_FIELD"),
  email_address: string().email().required("MISSING_REQUIRED_FIELD"),
  phone_number: string()
    .required("MISSING_REQUIRED_FIELD")
    .test("Phone invalid", "INVALID_PHONE", val => {
      if (!val) return false
      return isValidPhoneNumber(val, "GB")
    }),
  comment: string(),
  recaptcha: string().required("MISSING_REQUIRED_FIELD").nullable()
})
