import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useHistory } from "react-router-dom"

import { QuerySelect } from "@4cplatform/elements/Forms"
import { Modal, ComplianceNote, Button } from "@4cplatform/elements/Molecules"
import { H3, P } from "@4cplatform/elements/Typography"
import { usePatch } from "@4cplatform/elements/Api"

// Components
import { ButtonsWrapper } from "./startJourney.styles"

const StartJourneyModal = ({
  clientId,
  handleSubmit,
  isJourneyLoading,
  clientJourneys,
  setModal,
  isSimulationMode,
  formik,
  slug,
  settingID,
  updateValue
}) => {
  const history = useHistory()
  const [simModePrompt, setSimModePrompt] = React.useState(false)
  const simRef = React.useRef(null)

  // Off simulation mode
  const [offSimulationMode, { loading: offSimulationModeLoading }] = usePatch({
    endpoint: "/users/:slug/settings/:settingID",
    params: {
      slug,
      settingID
    },
    body: {
      data: {
        value: false
      }
    },
    onCompleted: res => {
      updateValue(res)
      setModal(false)
      handleSubmit()
    }
  })

  React.useEffect(() => {
    if (simModePrompt) {
      simRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [simModePrompt])

  return (
    <Modal title="Commence journey" onClose={() => setModal(false)}>
      {/* If there are incomplete journeys */}
      {clientJourneys > 0 && (
        <ComplianceNote type="error">
          <H3 margin="1rem 0 2rem">You have incomplete journeys</H3>
          <P>
            There {clientJourneys > 1 ? "are" : "is"} {clientJourneys} incomplete journey
            {clientJourneys > 1 ? "s" : ""} associated with this client.
          </P>
          <ButtonsWrapper>
            <Button
              onClick={() => {
                history.push(
                  `/journeys?status=IN_PROGRESS&status=QUOTED${
                    clientId ? `&client_id=${clientId}` : ""
                  }`
                )
              }}
              type="inline-button"
              data-testid="view_incomplete_journeys_link"
            >
              View incomplete journeys
            </Button>
          </ButtonsWrapper>
        </ComplianceNote>
      )}
      <QuerySelect
        name="product_type"
        label="Select a product type"
        noun={{ singular: "product type", plural: "product types" }}
        endpoint="/product-types"
        render={data => {
          const keys = Object.keys(data)

          return keys.map((key, i) => {
            if (data[key].status === "active") {
              return (
                <option
                  value={key}
                  key={`product_type_key-option_${i}`}
                  data-testid={`product_type_key-option_${i}`}
                >
                  {get(data, `[${key}].name`)}
                </option>
              )
            }
            return null
          })
        }}
        formik={formik}
        margin="0 0 3rem"
      />

      {/* If simulation mode is not on, show start/cancel buttons. */}
      {!isSimulationMode && (
        <ButtonsWrapper>
          <Button
            onClick={() => setModal(false)}
            appearance="error"
            isLoading={isJourneyLoading}
            trailingIcon="cancel"
            name="cancel_journey_modal"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            appearance="success"
            isDisabled={isSimulationMode}
            isLoading={isJourneyLoading}
            trailingIcon="chevron-right"
            name="start_journey_modal"
          >
            Start
          </Button>
        </ButtonsWrapper>
      )}

      {/* If simulation mode is on */}
      {isSimulationMode && (
        <ComplianceNote type="error" margin="0 0 3rem">
          <H3 margin="1rem 0 2rem">Simulation mode is currently active</H3>
          <P>
            Simulation mode is for training purposes only and should never be used for quoting or
            advising a client.
          </P>
          <P>
            To ensure accurate pricing, reports and documentation, please switch to the live
            environment before progressing to the next stage.
          </P>
          <H3 margin="1rem 0 2rem">Is this a training exercise?</H3>
          <ButtonsWrapper>
            <Button
              onClick={() => {
                setSimModePrompt(true)
              }}
              appearance="error"
              isLoading={isJourneyLoading}
              trailingIcon="cancel"
              name="cancel_journey_modal"
              isDisabled={simModePrompt}
            >
              No
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={isJourneyLoading}
              trailingIcon="check"
              name="new_journey"
              appearance="success"
              isDisabled={simModePrompt}
            >
              Yes
            </Button>
          </ButtonsWrapper>
        </ComplianceNote>
      )}

      {simModePrompt && (
        <ComplianceNote type="error" margin="0 0 3rem">
          <H3 margin="1rem 0 2rem">Switch Simulation Mode Off</H3>
          <P>
            You have confirmed this is not a training exercise, would you like to switch off
            simulation mode?
          </P>
          <ButtonsWrapper ref={simRef}>
            <Button
              onClick={() => setSimModePrompt(false)}
              appearance="error"
              isLoading={isJourneyLoading}
              trailingIcon="cancel"
              name="cancel_journey_simmode_modal"
            >
              No
            </Button>
            <Button
              onClick={offSimulationMode}
              isLoading={isJourneyLoading || offSimulationModeLoading}
              trailingIcon="check"
              name="submit_journey_simmode"
              appearance="success"
            >
              Yes
            </Button>
          </ButtonsWrapper>
        </ComplianceNote>
      )}
    </Modal>
  )
}

StartJourneyModal.propTypes = {
  /**
   * Selected client id
   */
  clientId: PropTypes.number,
  /**
   * Loading prop for journey
   */
  isJourneyLoading: PropTypes.bool.isRequired,
  /**
   * The submit function for starting the journey
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * The selectedClient journeys
   */
  clientJourneys: PropTypes.number.isRequired,
  setModal: PropTypes.func.isRequired,
  /**
   * Loading data to start a journey
   */
  isSimulationMode: PropTypes.bool.isRequired,
  formik: PropTypes.any.isRequired,
  slug: PropTypes.string.isRequired,
  settingID: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired
}

export default StartJourneyModal
