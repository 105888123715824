import React from "react"
import PropTypes from "prop-types"
import Button from "@4cplatform/elements/Molecules/Button"
import { FlexAlignCenter } from "./miReports.style"

const ReportFooter = ({ isLoading, exportPDF, printPDF }) => (
  <FlexAlignCenter>
    <Button trailingIcon="pdf-box" onClick={exportPDF} isLoading={isLoading} isDisabled={isLoading}>
      Export as PDF
    </Button>
    <Button trailingIcon="printer" isLoading={isLoading} isDisabled={isLoading} onClick={printPDF}>
      Print webpage
    </Button>
  </FlexAlignCenter>
)

ReportFooter.defaultProps = {
  exportPDF: () => {},
  isLoading: false
}

ReportFooter.propTypes = {
  exportPDF: PropTypes.func,
  printPDF: PropTypes.func,
  isLoading: PropTypes.bool
}

export default ReportFooter
