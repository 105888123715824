import React from "react"
import { Helmet } from "react-helmet-async"
import { Container } from "@4cplatform/elements/Atoms"
import { H1, P } from "@4cplatform/elements/Typography"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { DASHBOARD } from "../../config/pages"

const NotFound = () => (
  <>
    <Helmet>
      <title>404 Not Found</title>
    </Helmet>
    <Container isPageContainer width="80%">
      <H1>404 Not Found</H1>
      <P>Something went wrong.</P>
      <Button type="Link" to={DASHBOARD.path} leadingIcon="chevron-left">
        Back home
      </Button>
    </Container>
  </>
)

export default NotFound
