import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useGet, ApiError } from "@4cplatform/elements/Api"

// Helpers
import reducer from "./quickQuoteLog.reducer"
import { getOrderBy } from "../../../UI/Helpers"

// Components
import { Provider } from "../../../UI/Templates/QuickQuoteLog"

const QuickQuoteLogProvider = ({ children }) => {
  // State
  const [{ data, sorting, page, perPage, total, search, filter }, dispatch] = useReducer(reducer, {
    data: [],
    sorting: { direction: "desc", dataKey: "created_at" },
    page: 1,
    perPage: 10,
    total: null,
    acceptModal: false,
    declineModal: false,
    search: "",
    filter: "organisation"
  })

  // Set the query search if search is truthy
  let querySearch = {}
  if (search) {
    querySearch = {
      [filter]: search.trim()
    }
  }

  // Index Quick Quote log query
  const {
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch
  } = useGet({
    endpoint: "/quick-quote-log",
    query: {
      limit: perPage,
      order_by: getOrderBy(sorting),
      page,
      ...querySearch
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    }
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        sorting,
        page,
        perPage,
        total,
        queryLoading,
        queryRefetch,
        search,
        pagination: { total, page, perPage },
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        setSearch,
        filter,
        setFilter: val => dispatch({ type: "UPDATE_VALUE", key: "filter", value: val }),
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting })
      }}
    >
      {children}
      <ApiError error={queryError} />
    </Provider>
  )
}

QuickQuoteLogProvider.defaultProps = {
  children: null
}

QuickQuoteLogProvider.propTypes = {
  children: PropTypes.any
}

export default QuickQuoteLogProvider
