import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get } from "lodash"
import { SmallText } from "@4cplatform/elements/Typography"

// Components
import { DisclosureWrapper, DisclosureBody, DisclosureHeader, FlexRow } from "./policies.styles"

const questions = {
  // FMU Disclosures
  heart_or_cardiovascular_disorders:
    "Heart or cardiovascular disorders eg coronary artery disease, chest pains, circulation problems, varicose veins, high blood pressure, venous ulcers",
  glandular_disorders: "Glandular disorders eg diabetes, thyroid, hormonal problems",
  breathing_or_respiratory_disorders:
    "Breathing or respiratory disorders eg asthma, bronchitis, shortness of breath, chest infections, colds, flu",
  ears_nose_throat_or_eye_problems:
    "Ears, nose, throat, or eye problems eg hayfever, tonsillitis, sinusitis, cataracts, eye infections, deafness, ear infections",
  stomach_intestines_liver_or_gallbladder:
    "Stomach, intestines, liver or gallbladder eg ulcer, colitis, repeated indigestion, irritable bowel, change in bowel habits, hepatitis, piles, rectal bleeding",
  cancer_tumours_growths_cysts_or_moles_that_itch_or_bleed:
    "Cancer, tumours, growths, cysts, or moles that itch or bleed",
  skin_problems: "Skin problems eg eczema, rashes, psoriasis, acne",
  brain_or_nervous_system_disorders:
    "Brain or nervous system disorders eg stroke, migraines, repeated headaches, MS, epilepsy, nerve pain, fits",
  muscle_or_skeletal_problems:
    "Muscle or skeletal problems eg arthritis, cartilage and ligament problems, back and neck problems, sprains, joint replacements, gout, sciatica",
  urinary_problems:
    "Urinary problems eg bladder, kidney or prostate problems, urinary infections, incontinence",
  blood_disorders: "Blood disorders eg anaemia, hepatitis, HIV, abnormal blood tests",
  reproductive_system_problems:
    "Reproductive system problems eg pregnancy and/or childbirth problems, heavy or irregular periods, fibroids, endometriosis, infertility, abnormal smears, menopause, caesarean section, low testosterone, erectile dysfunction, low sperm count",
  dental_problems: "Dental problems eg wisdom teeth, abscess, gingivitis",
  allergies: "Allergies eg hayfever, pet allergies, food allergies",
  psychological_disorders:
    "Psychological disorders eg depression, schizophrenia, anorexia, bulimia, compulsive disorders, stress, anxiety",
  undiagnosed_symptoms:
    "Undiagnosed symptoms eg chest pain, fatigue, weight loss, dizziness, joint pain, change in bowel habit, shortness of breath, abdominal pain, rectal bleeding, lumps",
  applicant_member_taking_any_medicines_prescribed_or_otherwise:
    "Are you or any applicant/member taking any medicines, prescribed or otherwise?",
  covered_experienced_symptoms:
    "Within the last three months has anyone to be covered experienced symptoms of ANY health problems for which medical advice has not yet been sought?",
  past_history_of_joint_replacements_heart_conditions_or_strokes:
    "Has anyone to be covered EVER had any past history of joint replacements, heart conditions, or strokes?",
  other_information_relating:
    "Is there any other information relating to your health that has not yet been prompted by the questions listed 1 to 19?",
  // Switch Disclosures
  applicant_being_covered_cancer_last_5_years:
    "In the last 5 years have any of the applicants being covered had any form of cancer that's not already excluded on their existing scheme?",
  applicant_had_a_heart_or_circulatory_condition_last_5_years:
    "In the last 5 years have any of the applicants had a heart or circulatory condition that's not already excluded on their existing scheme (for example coronary artery disease, angina and heart attacks, stroke, abnormal heart rhythms, blood clots or aneurysms, heart valve disorders, uncontrolled high cholesterol/blood pressure etc)?",
  applicants_have_any_planned_or_pending_investigations_treatment_or_surgery:
    "Do any of the applicants have any planned or pending investigations, treatment or surgery for any condition or symptom that's not already excluded on their existing scheme (this applies whether the treatment is planned privately or under the NHS)?"
}

const BupaDisclosure = ({ data }) => (
  <DisclosureWrapper>
    <DisclosureHeader>
      <SmallText appearance="light" margin="0">
        {get(questions, `${data.field}`)}
      </SmallText>
    </DisclosureHeader>
    <DisclosureBody>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Illness or medical problem:
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.illness_or_medical_problem}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          When did the symptoms begin?
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.date_of_symptoms_began
            ? moment.utc(data.date_of_symptoms_began).local().format("DD/MM/YYYY")
            : "-"}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          When did the symptoms end?
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.date_of_symptoms_ended
            ? moment.utc(data.date_of_symptoms_ended).local().format("DD/MM/YYYY")
            : "-"}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Treatment (prescribed or otherwise):
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.treatment}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Current state of condition / symptom:
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.current_state_of_condition_or_symptom}
        </SmallText>
      </FlexRow>
      <FlexRow margin="0.5rem 0">
        <SmallText appearance="light" margin="0 1rem 0 0" width="calc(50% - 1rem)">
          Number of consultations in past two years?
        </SmallText>
        <SmallText appearance="light" margin="0" width="50%" align="right">
          {data.number_of_consults_in_past_two_years}
        </SmallText>
      </FlexRow>
    </DisclosureBody>
  </DisclosureWrapper>
)

BupaDisclosure.propTypes = {
  data: PropTypes.object.isRequired
}

export default BupaDisclosure
