import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useGet, useDelete, ApiError } from "@4cplatform/elements/Api"
import { PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../../../UI/Templates/NetworkAdmin"

// Helpers
import { getOrderBy } from "../../../UI/Helpers"
import reducer from "./networks.reducer"

const NetworksProvider = ({ children }) => {
  const t = useTranslations()
  const { setPanelStatus } = React.useContext(PageContext)

  // State
  const [
    { page, perPage, showDeleted, search, selectedNetwork, sorting, total, data, viewData },
    dispatch
  ] = React.useReducer(reducer, {
    page: 1,
    perPage: 10,
    showDeleted: false,
    search: "",
    selectedNetwork: null,
    sorting: { direction: "asc", dataKey: "name" },
    total: null,
    data: [],
    viewData: {}
  })

  // Index Networks query
  const { loading, error, refetch } = useGet({
    endpoint: "/networks",
    query: {
      name: search,
      page,
      limit: perPage,
      deleted: showDeleted,
      order_by: getOrderBy(sorting)
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: "There was an error fetching the networks",
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Network data query
  const { loading: viewLoading, error: viewError } = useGet({
    endpoint: "/networks/:network-slug",
    skip: !selectedNetwork || !get(selectedNetwork, "slug"),
    params: {
      "network-slug": get(selectedNetwork, "slug", "")
    },
    query: {
      with: ["address"]
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: newData })
    },
    onError: () => {
      addAlert({
        message: t("USER_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Delete Network mutation
  const [deleteNetwork, { loading: deleteLoading }] = useDelete({
    endpoint: "/networks/:network",
    params: {
      network: get(selectedNetwork, "slug", "")
    },
    onCompleted: () => {
      // Display success message, refetch index query
      addAlert({
        message: "Network has been successfully deleted",
        type: "success",
        dismissible: true,
        timeout: 5
      })
      refetch()
      setPanelStatus("closed")
    },
    onError: err => console.error(err)
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        selectedNetwork,
        viewLoading,
        viewData,
        onNetworkSelect: row =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedNetwork", value: row }),
        onNetworkDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedNetwork", value: null })
          dispatch({ type: "UPDATE_VALUE", key: "viewData", value: {} })
        },
        search,
        setSearch,
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        pagination: { total, page, perPage },
        showDeleted,
        setShowDeleted: val => dispatch({ type: "UPDATE_VALUE", key: "showDeleted", value: val }),
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        sorting,
        queryLoading: loading,
        deleteLoading,
        onDeleteNetwork: () => {
          deleteNetwork()
        }
      }}
    >
      {children}
      <ApiError error={error || viewError} />
    </Provider>
  )
}

NetworksProvider.defaultProps = {
  children: null
}

NetworksProvider.propTypes = {
  children: PropTypes.any
}

export default NetworksProvider
