import React from "react"
import PropTypes from "prop-types"
import { Button } from "@4cplatform/elements/Molecules"

// Helpers
import { HospitalPreferenceContext } from "./hospitalPreference.context"

// Components
import { ZoomButtonsWrapper } from "./hospitalPreference.styles"

const ZoomButtons = ({ isDisableZoom, setZoomLevel }) => {
  const { mapApi } = React.useContext(HospitalPreferenceContext)

  const handleOnClick = operation => {
    const currentZoomLevel = mapApi.getZoom()
    switch (operation) {
      case "out":
        if (currentZoomLevel !== 0) {
          mapApi.setZoom(currentZoomLevel - 1)
          setZoomLevel(currentZoomLevel - 1)
        }
        break
      case "in":
        if (currentZoomLevel !== 21) {
          mapApi.setZoom(currentZoomLevel + 1)
          setZoomLevel(currentZoomLevel + 1)
        }
        break
      default:
        break
    }
  }

  return (
    <ZoomButtonsWrapper index="1">
      <Button
        type="inline-button"
        leadingIcon="minus"
        onClick={() => handleOnClick("out")}
        isDisabled={isDisableZoom}
      />
      <Button type="inline-button" leadingIcon="plus" onClick={() => handleOnClick("in")} />
    </ZoomButtonsWrapper>
  )
}

export default ZoomButtons

ZoomButtons.propTypes = {
  isDisableZoom: PropTypes.bool,
  setZoomLevel: PropTypes.func
}
