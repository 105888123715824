import React from "react"
import { PageContext } from "@4cplatform/elements/Organisms"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { LeadsContext } from "./leads.context"

// Components
import LeadsPanelHeader from "./leads.panel.header"
import EditLeadForm from "./leads.panel.edit.form"

const EditLead = () => {
  const { selectedLead } = React.useContext(LeadsContext)
  const {
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)

  return (
    <>
      <LeadsPanelHeader selectedLead={selectedLead} context="wide" />
      {!!selectedLead && panelStatus !== "closed" && (
        <PanelBody>
          <EditLeadForm selectedLead={selectedLead} />
        </PanelBody>
      )}
    </>
  )
}

export default EditLead
