import styled, { css } from "styled-components"
import { get } from "lodash"
import { P } from "@4cplatform/elements/Typography"
import { colours } from "@4cplatform/elements/Helpers"

export const MessageWrapper = styled.div`
  height: auto;
  width: 100%;
  align-self: center;
  margin: 1rem 1rem 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ isFirst }) =>
    isFirst &&
    css`
      margin-top: 0;
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      margin-bottom: 0;
    `}
`

export const Message = styled.div`
  min-height: 7.4rem;
  height: auto;
  border-radius: ${({ isNoteTab }) =>
    isNoteTab ? "0.3rem 0.3rem 0 0.3rem" : "0.3rem 0.3rem 0.3rem 0"};
  background-color: ${({ theme }) => get(theme, "white", "white")};
  box-shadow: 0 0 0.1rem 0 rgba(0, 34, 43, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  border: 1px solid ${({ theme }) => get(theme, "lightgrey", "lightgrey")};
  color: ${colours.adminBlue};

  ${({ isNoteTab }) =>
    isNoteTab &&
    `
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -1rem;
        right: 0;
        border-top: 1rem solid white;
        border-left: 1rem solid transparent;
        box-shadow: 0.1rem 0 0 0 lightgrey;
      }
  `}
`

export const MessageLoading = styled.div`
  line-height: 10rem;
  width: 100%;
`

export const MessageNoNotes = styled(P)`
  margin: 3rem auto;
`

export const UnderWrapper = styled.div`
  margin-left: 2.5rem;
  justify-content: flex-end;
  width: 100%;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${({ theme, isNoteTab }) =>
    isNoteTab ? get(theme, "white", "white") : colours.adminBlue};
`

export const UnderWrapperLoading = styled.div`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  width: 15rem;
`

export const Content = styled.p`
  margin-top: 1rem;
  padding: 0 10px;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.7rem;
`
export const Timestamp = styled.div`
  height: 2rem;
  margin-top: ${({ isNoteTab }) => (isNoteTab ? "0" : "-0.9rem")};
  width: 100%;
  color: ${({ theme, isNoteTab }) =>
    isNoteTab ? get(theme, "white", "white") : get(theme, "darkBlue", "darkBlue")};
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 3rem;
  text-align: left;
  background-color: ${({ theme, isNoteTab }) =>
    isNoteTab ? "transparent" : get(theme, "veryFaintGrey", "lightgrey")};
  ${({ theme, isNoteTab }) => {
    if (isNoteTab) {
      return "border: none"
    }
    return `
      border-top-right-radius: 1rem;
      border-top: 1px solid ${get(theme, "lightgrey", "lightgrey")};
    `
  }}
`
