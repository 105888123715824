import styled, { css } from "styled-components"
import { get } from "lodash"

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: ${({ hasLeftActions }) => (hasLeftActions ? "space-between" : "flex-end")};
  align-items: center;
  margin-bottom: 1rem;
`

export const TableActionsLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TableActionsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const SectionWrapper = styled.div`
  padding-bottom: ${({ isLast }) => (isLast ? 0 : "2rem")};
  margin-bottom: ${({ isLast }) => (isLast ? 0 : "2rem")};
  border-bottom: ${({ theme, isLast }) =>
    isLast ? "none" : `1px solid ${get(theme, "tints.primary.blue.t20")}`};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &.row-order {
    display: grid;
  }
`

export const FlexRow = styled.div`
  display: flex;
  margin: ${({ margin }) => margin};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const DisclosureWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
`

export const DisclosureHeader = styled.div`
  width: 100%;
  border: 1px solid;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem;
`

export const DisclosureBody = styled.div`
  width: 100%;
  border: 1px solid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: space-between;
`

export const Divider = styled.div`
  background-color: ${({ theme }) => get(theme, "white", "white")};
  margin: ${({ margin }) => margin};
  opacity: 0.3;
  height: 1px;
  width: 100%;
`

export const ALinkDummy = styled.span`
  font-size: 1.6rem;
  display: flex;
  cursor: not-allowed;
  color: ${({ theme, colour }) =>
    colour ? get(theme, colour, "blue") : get(theme, "blue", "blue")};

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        opacity: 0.7;
      `
    }
  }}
`

export const SimulatedWrapper = styled.span`
  padding: 0.5rem;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => get(theme, "white", "white")};
  background: ${({ theme }) => get(theme, "red", "red")};
  text-transform: uppercase;
`
