/* eslint-disable react/display-name */
import React, { memo, forwardRef, useContext } from "react"
import { v4 as uuid } from "uuid"

// Components
import { QuotationSummaryContext } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/context/quotationSummary.context"
import Quote from "./quotationSummaryTable.quote"
import { TableBodyColumnsWrapper } from "./quotationSummaryTable.styles"

const QuotationSummaryTableBody = forwardRef((props, ref) => {
  const { quotes } = useContext(QuotationSummaryContext)

  return (
    <>
      <TableBodyColumnsWrapper ref={ref}>
        {quotes.map(quote => (
          <Quote key={uuid()} quote={quote} />
        ))}
      </TableBodyColumnsWrapper>
    </>
  )
})

export default memo(QuotationSummaryTableBody)
