import React from "react"
import { get } from "lodash"
import { v4 as uuid } from "uuid"
import { Select, TextArea } from "@4cplatform/elements/Forms"
import { Button } from "@4cplatform/elements/Molecules"
import { useFormik } from "formik"
import { H3 } from "@4cplatform/elements/Typography"

// Helpers
import { PoliciesContext } from "../../policies.context"
import { createEditExclusionModel as validationSchema } from "./policyExclusions.helpers"
import { getName } from "../../../../Helpers"

// Components
import { ExclusionBodyWrapper, SectionWrapper } from "./policyExclusions.styles"

const PolicyExclusionsApplicants = () => {
  const { viewData, onCreateExclusion } = React.useContext(PoliciesContext)
  const applicants = get(viewData, "applicants", [])

  const createExclusionFormik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      policy_applicant_id: "",
      exclusion: ""
    },
    onSubmit: body =>
      onCreateExclusion({
        body: { ...body, policy_applicant_id: parseInt(get(body, "policy_applicant_id")) }
      })
  })

  const formik = { ...createExclusionFormik, validationSchema }
  const { handleSubmit } = formik

  return (
    <>
      <H3 margin="0 0 2rem 2rem">Add new exclusion</H3>
      <ExclusionBodyWrapper data-testid="add_exclusions-wrapper">
        <SectionWrapper>
          <Select label="Applicant" formik={formik} name="policy_applicant_id" errorPrefix="label">
            <option value="">Select applicant</option>
            {applicants.map(applicant => {
              const name = getName({ data: applicant, hasMiddle: true })
              return (
                <option key={uuid()} value={get(applicant, "id")}>
                  {name}
                </option>
              )
            })}
          </Select>
          <TextArea name="exclusion" margin="0" padding="1rem" formik={formik} label="Exclusion" />
          <Button
            appearance="success"
            trailingIcon="plus"
            onClick={handleSubmit}
            name="add_policy_exclusion"
            margin="2rem 0 0"
          >
            Add
          </Button>
        </SectionWrapper>
      </ExclusionBodyWrapper>
    </>
  )
}

export default PolicyExclusionsApplicants
