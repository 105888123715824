import { useEffect, useContext } from "react"
import { get } from "lodash"
import moment from "moment"

import { JourneyContext } from "../../../../../../journey.context"

const Audit = () => {
  const { formik, updateJourneyAuditData } = useContext(JourneyContext)

  const signatureData = get(formik, "values.signature_date_on_application", null)

  useEffect(() => {
    if (signatureData) {
      updateJourneyAuditData([
        {
          mode: "custom",
          data: [
            {
              name: "Please confirm the signature date on the application",
              value: moment(signatureData).format("DD/MM/YYYY")
            }
          ]
        },
        {
          mode: "append",
          data: [
            {
              name: "File uploaded",
              value: ""
            }
          ]
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureData])

  return null
}

export default Audit
