import React, { useContext } from "react"
import { Button, ConfirmationModal, Modal } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"

// Helpers
import { LeadsContext } from "./leads.context"

// Components
import { CloseButton } from "./leads.styles"

const DeleteLead = () => {
  const {
    selectedLead,
    noticeModal,
    setNoticeModal,
    deleteOpen,
    setDeleteOpen,
    deleteLoading,
    onDeleteLead
  } = useContext(LeadsContext)

  return (
    <>
      <Button
        appearance="error"
        trailingIcon="delete"
        margin="0 2rem 0 0"
        onClick={() =>
          selectedLead.has_active_journeys ? setNoticeModal(true) : setDeleteOpen(true)
        }
        name="delete_lead"
      >
        Delete lead
      </Button>
      {noticeModal && (
        <Modal title="Notice" onClose={() => setNoticeModal(false)}>
          <P>
            This lead has an associated client and the client has one or more quotes in progress.
            These journeys must be either completed or deleted before the lead can be deleted.
          </P>
          <CloseButton>
            <Button trailingIcon="close" appearance="error" onClick={() => setNoticeModal(false)}>
              Close
            </Button>
          </CloseButton>
        </Modal>
      )}
      {deleteOpen && (
        <ConfirmationModal
          title="Delete Lead"
          confirmIcon="delete"
          confirmText="Delete"
          confirmAppearance="error"
          isLoadingConfirm={deleteLoading}
          cancelAppearance="errorGhost"
          onClose={() => setDeleteOpen(false)}
          onConfirm={onDeleteLead}
        >
          <P>Are you sure you want to delete this Lead?</P>
        </ConfirmationModal>
      )}
    </>
  )
}

export default DeleteLead
