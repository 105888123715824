import React from "react"
import PropTypes from "prop-types"

// Helpers
import { JourneyContext } from "../../../../../../../journey.context"
import { Provider } from "../currentPolicyDetails.context"

const CurrentPolicyDetailsProvider = ({ children }) => {
  const { data } = React.useContext(JourneyContext)

  return <Provider value={{ data }}>{children}</Provider>
}

CurrentPolicyDetailsProvider.propTypes = {
  children: PropTypes.any
}

export default CurrentPolicyDetailsProvider
