import { boolean } from "yup"
import { get } from "lodash"
import { Toggle } from "@4cplatform/elements/Forms"
import { P, H3, H4, List } from "@4cplatform/elements/Typography"
import Audit from "./Audit"

export const config = data => ({
  title: "Switch declaration confirmation",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          component: H3,
          componentProps: {
            children: "Obtaining medical reports from a GP"
          }
        },
        {
          component: P,
          componentProps: {
            children:
              "When you or your dependant needs to request a medical report from your/your dependants doctor, we can do this on your/your dependants behalf with your or their consent. We will always ask for your/your dependants consent before requesting a report from your/your dependants doctor on your/your dependants behalf and we will ask for your/your dependants consent on the telephone when we explain to you/your dependant the need for the report."
          }
        },
        {
          component: P,
          componentProps: {
            children:
              'When we ask you/your dependant for your/your dependants consent to obtain a medical report from your/your dependants doctor, you/your dependants have certain rights under the Access to Medical Reports Act 1988 or the Access to Personal Files and Medical Reports (NI) Order 1991 (the "Acts"). The section below summarises your/your dependants rights under the Acts.'
          }
        },
        {
          component: P,
          componentProps: {
            children:
              "You and your dependants should read this section carefully and if you/your dependants don't understand any point, you/your dependants should ask for further information."
          }
        },
        {
          component: H4,
          componentProps: {
            children: "Your/your dependants rights"
          }
        },
        {
          component: List,
          componentProps: {
            name: "information_text",
            children: (
              <>
                <li>
                  You can authorise the disclosure of your doctors report without asking to see it.
                  The report will then be sent directly to us by your doctor. Should you give your
                  consent to the disclosure of a report without indicating your wish to see it, you
                  can change your mind by contacting your doctor before the report is sent to us, in
                  which case you will have the opportunity to see the report and ask your doctor to
                  change the report if you think it is misleading. If your doctor refuses, you can
                  insist on adding your own comments to the report before it is sent to us or
                  withhold your consent for its release.
                </li>
                <li>
                  You can give your consent but ask to see the report before it is sent to us. If
                  you do this you should contact your doctor within 21 days of sending the request
                  to them. If you do not contact your doctor within the 21 day period you have
                  authorised them to disclose the report to us directly without further notice to
                  you. If you do contact your doctor within the 21 day period you must give them
                  your written consent to disclose the report. You may ask your doctor to change the
                  report if you think it is misleading. If your doctor refuses, you can insist on
                  adding your own comments to the report before it is sent to us or withhold your
                  consent for its release.
                </li>
                <li>
                  You can withhold your consent but, if you do, please bear in mind that we may be
                  unable to process your request. Whether or not you indicate that you wish to see
                  the report before it is sent, you have the right to ask your doctor to let you see
                  a copy, provided you ask them within six months of the report having been supplied
                  to us.
                </li>
              </>
            )
          }
        },
        {
          component: P,
          componentProps: {
            children:
              "Your doctor is entitled to withhold some, or all of the information contained in the report if, in their opinion, this information: "
          }
        },
        {
          component: List,
          componentProps: {
            listType: "unordered",
            name: "information_text",
            children: (
              <>
                <li>
                  might cause serious harm to your physical or mental health or that of another
                  person, or
                </li>
                <li>
                  would reveal the identity of another person without their consent (other than that
                  provided by a healthcare professional in their professional capacity in relation
                  to your care).
                </li>
              </>
            )
          }
        },
        {
          component: P,
          componentProps: {
            children:
              "We may make a contribution to the costs of any medical report that we have requested on your behalf. This will be confirmed at point of telephone consent. If we do make a contribution, you will be responsible for any amount above this. "
          }
        },
        {
          component: H3,
          componentProps: {
            children: "Your legal declaration"
          }
        },
        {
          component: P,
          componentProps: {
            children: "I confirm the following:"
          }
        },
        {
          component: List,
          componentProps: {
            name: "information_text",
            children: (
              <>
                <li>
                  To the best of my knowledge and belief the information given in this form is true,
                  accurate and complete. I understand that Bupa can end a person's policy and treat
                  it as never having existed or refuse to pay a claim in full or part if there is
                  reasonable evidence that I or a dependant did not take reasonable care when
                  providing any information requested in this form.
                </li>
                <li>
                  Where I have provided information on behalf of any other person to be covered on
                  the policy, I confirm that I have checked with them that the information is
                  correct before completing this form and I have their express agreement to submit
                  this form on their behalf, or I am their legal representative.
                </li>
                <li>
                  I understand that my personal information and that of any other person to be
                  covered on this policy will be processed by Bupa for the purposes set out in
                  Bupa's privacy notice. I confirm that I have brought Bupa's privacy notice to the
                  attention of the persons covered.
                </li>
                <li>
                  I agree to be bound by the policy terms and conditions (including in respect of
                  those terms that apply to any other person to be covered on this policy). I agree
                  that English law will apply to the policy terms and conditions.
                </li>
                <li>
                  I understand that should my employment circumstances change prior to the start of
                  this cover, that I will need to notify Bupa of this change. <br />
                  It is essential that you take reasonable care to provide us with full, complete
                  and accurate information when you complete this form. Please be sure to check the
                  entire form.
                </li>
              </>
            )
          }
        },
        {
          component: P,
          componentProps: {
            children:
              "If you do not provide complete information about yourself or any other person covered under the policy, we may have the right to end your policy and treat it as never having existed, or to refuse to pay all or part of a claim. "
          }
        },
        {
          component: P,
          componentProps: {
            children:
              "We recommend that you keep a record of all the information you supply to us in connection with this form, including letters."
          }
        },
        {
          component: P,
          componentProps: {
            children: "If you would like a copy of this form, please ask us."
          }
        },
        {
          component: H4,
          componentProps: {
            children: "Obtaining medical reports from your doctor"
          }
        },
        {
          component: List,
          componentProps: {
            listType: "unordered",
            name: "information_text",
            children: (
              <>
                <li>
                  I have shown this declaration to the proposed dependants on the policy and confirm
                  that they understand and accept that if they need to claim they may be asked on
                  the telephone to confirm their consent to Bupa requesting a medical report on
                  their behalf.
                </li>
                <li>
                  I understand that Bupa may need me to provide a medical report from my doctor to
                  support my application before treatment is authorised or a claim paid.
                </li>
                <li>
                  I consent to Bupa obtaining this information from my doctor on my behalf and I
                  understand that Bupa will gain verbal confirmation from me prior to any medical
                  report being requested in this way.
                </li>
                <li>
                  I acknowledge the rights I have in relation to such reports as explained in
                  section 7.
                </li>
              </>
            )
          }
        }
      ].map((item, index) => ({
        ...item,
        key: `section_1_${index}`,
        skipDataMap: true
      }))
    },
    {
      key: "section_2",
      components: [
        {
          key: "client_confirmed_bupa_can_obtain_medical_reports",
          initialValue: get(
            data,
            "page.data.client_confirmed_bupa_can_obtain_medical_reports",
            false
          ),
          validationSchema: boolean()
            .oneOf(
              [true],
              "It is not possible to continue with the sales journey if the client has not confirmed that Bupa can obtain medical reports on their behalf."
            )
            .required("MISSING_REQUIRED_FIELD"),
          label: "Client confirms Bupa can obtain medical reports on your behalf?",
          component: Toggle,
          componentProps: {
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true,
            labelWidth: "100%"
          }
        },
        {
          key: "client_wished_to_see_medical_report_from_their_doctor",
          initialValue: get(
            data,
            "page.data.client_wished_to_see_medical_report_from_their_doctor",
            false
          ),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label:
            "Does the client wish to see the medical report from their doctor before it is supplied to Bupa?",
          component: Toggle,
          componentProps: {
            isHorizontal: true,
            isRequired: true,
            labelWidth: "100%"
          }
        }
      ]
    }
  ]
})
