import React from "react"
import PropTypes from "prop-types"
import { get, isEmpty } from "lodash"

import { IconWithText } from "@4cplatform/elements/Atoms"
import { Button } from "@4cplatform/elements/Molecules"
import { Table } from "@4cplatform/elements/Organisms"

// Helpers
import { JourneyContext } from "../../../../../../../journey.context"
import { getName } from "../../../../../../../../../Helpers"
import Modal from "./exeter.questions.modal"

const ExeterQuestionsTable = ({ applicants: applicantsRaw, data }) => {
  const [isOpen, setOpen] = React.useState(false)
  const [applicants, setApplicants] = React.useState([])
  const [selectedApplicant, setSelectedApplicant] = React.useState({})
  const [applicantStatus, setApplicantStatus] = React.useState({})
  const [questionAnswered, setQuestionAnswered] = React.useState({})
  const { updateJourneyAuditData } = React.useContext(JourneyContext)
  const underwritingStyle = get(data, "journey.selected_quote.underwriting_style", "NEW")
  const underwritingType = get(data, "journey.selected_quote.underwriting_type", "MORI")
  const isLocked = get(data, "journey.locked", false)
  const payload = get(data, "payload", {})

  React.useEffect(() => {
    if (!isEmpty(payload) || isEmpty(applicants)) {
      setApplicants(
        applicantsRaw.map(applicant => ({
          ...applicant,
          answers: get(payload, applicant.journey_applicant_id, {})
        }))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload])

  React.useEffect(() => {
    if (!isEmpty(payload) && isEmpty(selectedApplicant) && isEmpty(applicantStatus)) {
      setApplicantStatus(previousState => {
        Object.keys(payload).forEach(applicantId => {
          previousState[applicantId] = true
        })
        return previousState
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, selectedApplicant, applicantStatus])

  const handleCloseModal = complete => {
    if (typeof complete === "boolean") {
      setApplicantStatus(previousState => {
        previousState[selectedApplicant.journey_applicant_id] = complete
        return previousState
      })
      if (complete) {
        setApplicants(
          applicants.map(applicant => {
            if (applicant.id === selectedApplicant.id) {
              return {
                ...applicant,
                answers: { ...questionAnswered }
              }
            }

            return applicant
          })
        )
      } else {
        setQuestionAnswered({})
      }
    }
    setSelectedApplicant({})
    setOpen(false)
  }
  const onAnsweredQuestion = question => {
    if (!isEmpty(question)) {
      setQuestionAnswered(question)
    }
  }

  React.useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    updateApplicantsData()

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicants, underwritingStyle, underwritingType])

  const updateApplicantsData = () => {
    if (applicants?.length) {
      const tempAuditData = []

      applicants.forEach(applicant => {
        let answersParts = []
        if (underwritingStyle === "NEW" && underwritingType === "MORI") {
          answersParts = [
            [
              {
                name: "Have you smoked or used nicotine replacement products in the last 12 months? (We may ask to carry out a simple test to verify your answer to this question)",
                value:
                  get(applicant, "answers.9c039e19-034c-4b86-ba17-5782b54ba426") === "Y"
                    ? "Yes"
                    : "No"
              }
            ]
          ]
        }

        if (underwritingStyle === "SWITCH" && ["MORI", "CMORI"].includes(underwritingType)) {
          answersParts = [
            [
              {
                name: "1. Have you smoked or used nicotine replacement products in the last 12 months? (We may ask to carry out a simple test to verify your answer to this question)",
                value:
                  get(applicant, "answers.5e7ee828-0798-404d-a8d1-97275fcf0c84") === "Y"
                    ? "Yes"
                    : "No"
              },
              {
                name: "2.1 Do you have any consultations, investigations or treatment planned or pending in the next 12 months (NHS or private)?",
                value:
                  get(applicant, "answers.1c7ce1a9-bb10-48de-9053-78fe225adb14") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.1c7ce1a9-bb10-48de-9053-78fe225adb14") === "Y"
                ? [
                    {
                      name: "2.1.1 Please provide full details.",
                      value: get(applicant, "answers.3d571e42-d36b-4b89-a40a-36e401b256de", true)
                    }
                  ]
                : []),
              {
                name: "2.2 Have you had any consultations, investigations, symptoms (whether diagnosed or undiagnosed) or treatment in the last 12 months (NHS or Private)?",
                value:
                  get(applicant, "answers.d8fc47ef-9297-47cf-a2eb-d641cbdfb9e8") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.d8fc47ef-9297-47cf-a2eb-d641cbdfb9e8") === "Y"
                ? [
                    {
                      name: "2.2.1 Please provide full details.",
                      value: get(applicant, "answers.5310e104-8812-40cc-a002-dc42b059b7d3", true)
                    }
                  ]
                : [])
            ],
            [
              {
                name: "3. Have you been treated for, diagnosed with, or advised that you have any of the following in the last 5 years",
                value: true
              },
              {
                name: "3.1 Heart Condition?",
                value:
                  get(applicant, "answers.44877949-7f25-4223-b2fa-4542466914fe") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.44877949-7f25-4223-b2fa-4542466914fe") === "Y"
                ? [
                    {
                      name: "3.1.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.d9adb6c7-01a4-48ad-9e9c-8ec625708ad4", true)
                    }
                  ]
                : []),
              {
                name: "3.2 Cancer?",
                value:
                  get(applicant, "answers.4edb61d3-e694-4776-b80c-031892089fb2") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.4edb61d3-e694-4776-b80c-031892089fb2") === "Y"
                ? [
                    {
                      name: "3.2.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.2ab03bf9-dc2b-494e-89b6-80fa4caa2a9c", true)
                    }
                  ]
                : []),
              {
                name: "3.3 Stroke or transient ischaemic attack (TIA)?",
                value:
                  get(applicant, "answers.5a346f78-a306-4dc6-96df-5dc1d9e1ced2") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.5a346f78-a306-4dc6-96df-5dc1d9e1ced2") === "Y"
                ? [
                    {
                      name: "3.3.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.9580c571-266e-4473-9069-0f9450cb0114", true)
                    }
                  ]
                : []),
              {
                name: "3.4 Would you like Mental Health Cover?",
                value:
                  get(applicant, "answers.ff5a2ec2-4f55-4d0e-8c7c-3433526d3d93") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.ff5a2ec2-4f55-4d0e-8c7c-3433526d3d93") === "Y"
                ? [
                    {
                      name: "3.4.1 Anxiety, depression, stress or any other mental health issues (including but not limited to work stress, low mood, depression)?",
                      value:
                        get(applicant, "answers.ef893c65-c60d-4a64-b4a9-da4bbc99e42a") === "Y"
                          ? "Yes"
                          : "No"
                    }
                  ]
                : []),
              ...(get(applicant, "answers.ff5a2ec2-4f55-4d0e-8c7c-3433526d3d93") === "Y" &&
              get(applicant, "answers.ef893c65-c60d-4a64-b4a9-da4bbc99e42a") === "Y"
                ? [
                    {
                      name: "3.4.1.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.e14385ca-2d14-4b5a-b2db-cdd97cf1f0b7", true)
                    }
                  ]
                : []),
              {
                name: "3.5 Joint replacement or joint resurfacing?",
                value:
                  get(applicant, "answers.cd21f566-3c8f-4819-802e-ebccab46ed0e") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.cd21f566-3c8f-4819-802e-ebccab46ed0e") === "Y"
                ? [
                    {
                      name: "3.5.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.b24f6b0c-cb62-4fa0-b522-961868ec7b0d", true)
                    }
                  ]
                : []),
              {
                name: "3.6 Diabetes or pre-diabetes?",
                value:
                  get(applicant, "answers.9488a8d6-d548-40d1-a1c4-5f62897497c5") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.9488a8d6-d548-40d1-a1c4-5f62897497c5") === "Y"
                ? [
                    {
                      name: "3.6.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.4525d9c5-4285-4dda-84d3-5d79af75ff3f", true)
                    }
                  ]
                : [])
            ]
          ]
        }

        if (underwritingStyle === "NEW" && underwritingType === "FMU") {
          answersParts = [
            [
              {
                name: "1. What is your age?",
                value: get(applicant, "answers.b501eb93-6e6f-4bde-bdee-f593d78c0207", true)
              },
              {
                name: "1.1 Have you smoked or used nicotine replacement products in the last 12 months? (We may ask to carry out a simple test to verify your answer to this question)",
                value:
                  get(applicant, "answers.ff92c551-d0f6-4271-8edd-c9698c693783") === "Y"
                    ? "Yes"
                    : "No"
              },
              {
                name: "2.1 Diabetes, pre-diabetes or sugar in the urine?",
                value:
                  get(applicant, "answers.88e8f7cf-cc71-44e9-89b2-4086235469c7") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.88e8f7cf-cc71-44e9-89b2-4086235469c7") === "Y"
                ? [
                    {
                      name: "2.1.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.89d9f984-3d79-447b-9949-148768b9de24", true)
                    }
                  ]
                : []),
              {
                name: "2.2 A malignant condition such as tumour/cancer?",
                value:
                  get(applicant, "answers.21eb06f1-106d-412b-b3fc-b21279acb02b") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.21eb06f1-106d-412b-b3fc-b21279acb02b") === "Y"
                ? [
                    {
                      name: "2.2.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.a6a7130e-9a80-411b-a727-f262350027e9", true)
                    }
                  ]
                : []),
              {
                name: "2.3 Heart problems e.g. any arterial or cardiac surgery, chest pains, angina or irregular heartbeat?",
                value:
                  get(applicant, "answers.485a3600-d028-43ce-910a-69d82d7ca465") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.485a3600-d028-43ce-910a-69d82d7ca465") === "Y"
                ? [
                    {
                      name: "2.3.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.3458072d-d1d5-47f1-b4f4-f326ee6050f0", true)
                    }
                  ]
                : []),
              {
                name: "2.4 Stroke or transient ischaemic attack (TIA)?",
                value:
                  get(applicant, "answers.5f2b39dd-a782-4292-9886-f6b03905f342") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.5f2b39dd-a782-4292-9886-f6b03905f342") === "Y"
                ? [
                    {
                      name: "2.4.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.64365e55-9d97-4719-ae9a-f5358e32f5f4", true)
                    }
                  ]
                : []),
              {
                name: "2.5 Circulation problems such as peripheral vascular disease, claudication or varicose veins?",
                value:
                  get(applicant, "answers.d898c082-7e90-47d3-8b91-c38849c94e84") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.d898c082-7e90-47d3-8b91-c38849c94e84") === "Y"
                ? [
                    {
                      name: "2.5.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.b77b4f9f-25d9-4eaa-895e-c473d2825701", true)
                    }
                  ]
                : []),
              {
                name: "2.6 Surgery as a result of a bone or joint condition?",
                value:
                  get(applicant, "answers.390078b2-bbf7-488b-8b94-a920f2359880") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.390078b2-bbf7-488b-8b94-a920f2359880") === "Y"
                ? [
                    {
                      name: "2.6.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.70dca3ae-2617-458d-96a3-276f9d9e5d38", true)
                    }
                  ]
                : []),
              {
                name: "2.7 HIV, hepatitis B or C",
                value:
                  get(applicant, "answers.e05a38c4-935e-4f1f-bc20-5fcb8955ed8d") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.e05a38c4-935e-4f1f-bc20-5fcb8955ed8d") === "Y"
                ? [
                    {
                      name: "2.7.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.1499f334-93f7-4bcf-801a-3ce6e6ba97bb", true)
                    }
                  ]
                : []),
              {
                name: "2.8 Multiple Sclerosis or Epilepsy?",
                value:
                  get(applicant, "answers.2d37240e-90cb-4ee4-bb98-c9d8672d4f32") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.2d37240e-90cb-4ee4-bb98-c9d8672d4f32") === "Y"
                ? [
                    {
                      name: "2.8.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.42011923-39d6-4883-a7a3-1de6f8922e75", true)
                    }
                  ]
                : []),
              {
                name: "2.9 Stomach, intestine, liver, gall bladder or bowel disorders e.g. Crohn's disease, ulcerative colitis etc?",
                value:
                  get(applicant, "answers.01d765e4-98fa-4339-ab27-8bc1ff73e1dc") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.01d765e4-98fa-4339-ab27-8bc1ff73e1dc") === "Y"
                ? [
                    {
                      name: "2.9.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.cf71e1d0-d573-4505-a8ab-51a75edafe0c", true)
                    }
                  ]
                : []),
              {
                name: "2.10 Would you like Mental Health Cover?",
                value:
                  get(applicant, "answers.7334c41e-f917-4184-8c3b-3cfb3d95ba3a") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.7334c41e-f917-4184-8c3b-3cfb3d95ba3a") === "Y"
                ? [
                    {
                      name: "2.10.1 Any mental health issue which has required a specialist, psychiatric or hospital referral, or inpatient stay?",
                      value:
                        get(applicant, "answers.db45977a-fdaa-407c-9535-bb4705f15730") === "Y"
                          ? "Yes"
                          : "No"
                    }
                  ]
                : []),
              ...(get(applicant, "7334c41e-f917-4184-8c3b-3cfb3d95ba3a") === "Y" &&
              get(applicant, "answers.db45977a-fdaa-407c-9535-bb4705f15730") === "Y"
                ? [
                    {
                      name: "2.10.1.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.cf71e1d0-d573-4505-a8ab-51a75edafe0c", true)
                    }
                  ]
                : [])
            ],
            [
              {
                name: "3. In the last 5 years have you had",
                value: true
              },
              {
                name: "3.1 Migraines?",
                value:
                  get(applicant, "answers.3f6b0443-480f-476d-bd4a-d2a1d57a380") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.3f6b0443-480f-476d-bd4a-d2a1d57a380") === "Y"
                ? [
                    {
                      name: "3.1.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.a3119b3a-fb68-48e2-b75d-27972fda7fc8", true)
                    }
                  ]
                : []),
              {
                name: "3.2 Nerve pain, muscle, bone or joint conditions e.g. fractures, neck, hip, knee, back, tendon, cartilage or ligament problems?",
                value:
                  get(applicant, "answers.ef2361ff-7d84-4aa6-a4a7-212dcd5ae6e7") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.ef2361ff-7d84-4aa6-a4a7-212dcd5ae6e7") === "Y"
                ? [
                    {
                      name: "3.2.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.a164ef0c-76ac-435d-b153-a803c78d1a87", true)
                    }
                  ]
                : []),
              {
                name: "3.3 High blood pressure or high cholesterol?",
                value:
                  get(applicant, "answers.87ac276f-df38-4b21-94c6-efa97f5c6a45") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.87ac276f-df38-4b21-94c6-efa97f5c6a45") === "Y"
                ? [
                    {
                      name: "3.3.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.ac82a6e8-5fcc-4150-9b98-46108c24ed54", true)
                    }
                  ]
                : []),
              {
                name: "3.4 Any disease of the kidney, bladder or urinary system?",
                value:
                  get(applicant, "answers.4194fb92-cf16-45ab-adae-ef61ea5663ca") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.4194fb92-cf16-45ab-adae-ef61ea5663ca") === "Y"
                ? [
                    {
                      name: "3.4.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.80f2f48b-a1bc-407b-8857-3394f3ca3a13", true)
                    }
                  ]
                : []),
              {
                name: "3.5 Asthma, bronchitis or any other lung or respiratory problems?",
                value:
                  get(applicant, "answers.14a4d9ed-a8b2-4b47-96f0-0965f996d3b5") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.14a4d9ed-a8b2-4b47-96f0-0965f996d3b5") === "Y"
                ? [
                    {
                      name: "3.5.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.c8e949e1-418a-4ea2-8e06-efca2f2e1f3f", true)
                    }
                  ]
                : []),
              {
                name: "3.6 Any disease or disorder of the skin e.g. psoriasis, eczema, skin cancers or dermatitis?",
                value:
                  get(applicant, "answers.0d4a550b-84d5-41a3-bcb1-813c3b927cab") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.0d4a550b-84d5-41a3-bcb1-813c3b927cab") === "Y"
                ? [
                    {
                      name: "3.6.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.57d4e06e-5658-4710-aff1-ff15470fe599", true)
                    }
                  ]
                : []),
              {
                name: "3.7 Any disease or disorder of the eyes, ears, nose or throat?",
                value:
                  get(applicant, "answers.b576823c-22a5-4400-bf57-fced2829ab08") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.b576823c-22a5-4400-bf57-fced2829ab08") === "Y"
                ? [
                    {
                      name: "3.7.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.8e54704c-b373-4c64-975e-a119b8bf017f", true)
                    }
                  ]
                : []),
              {
                name: "3.8 Any glandular disorders e.g. thyroid, hormonal problems?",
                value:
                  get(applicant, "answers.5e4cfe4d-9f1f-4606-87d8-b2dcb04b9328") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.5e4cfe4d-9f1f-4606-87d8-b2dcb04b9328") === "Y"
                ? [
                    {
                      name: "3.8.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.67839e9e-4b4f-41db-948d-12693dddb813", true)
                    }
                  ]
                : []),
              {
                name: "3.9 Any blood disorders e.g. abnormal blood tests, anaemia?",
                value:
                  get(applicant, "answers.0204ef77-ea39-4d5c-9679-948f10cf0114") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.0204ef77-ea39-4d5c-9679-948f10cf0114") === "Y"
                ? [
                    {
                      name: "3.9.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.0b822bd7-2957-4ef1-aa92-97a0cfcf7872", true)
                    }
                  ]
                : []),
              {
                name: "3.10 Hernia e.g. umbilical hernia, inguinal hernia, hiatus hernia?",
                value:
                  get(applicant, "answers.0e4f93b9-5e41-4cdc-a334-157858c2c403") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.0e4f93b9-5e41-4cdc-a334-157858c2c403") === "Y"
                ? [
                    {
                      name: "3.10.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.3807c05f-a79c-42d8-a40a-2e7c22aab9da", true)
                    }
                  ]
                : []),
              {
                name: "3.11 Are you male or female?",
                value:
                  get(applicant, "answers.957dd6bb-c663-4272-bebb-66c4d9436075") === "F"
                    ? "Female"
                    : "Male"
              },
              ...(get(applicant, "answers.957dd6bb-c663-4272-bebb-66c4d9436075") === "F"
                ? [
                    {
                      name: "3.11.1 Any gynaecological disorder or breast problem?",
                      value:
                        get(applicant, "answers.b5c79564-f3ab-4362-be68-c144fef30b27") === "Y"
                          ? "Yes"
                          : "No"
                    }
                  ]
                : []),
              ...(get(applicant, "answers.957dd6bb-c663-4272-bebb-66c4d9436075") === "F" &&
              get(applicant, "answers.b5c79564-f3ab-4362-be68-c144fef30b27") === "Y"
                ? [
                    {
                      name: "3.11.1.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.cb064408-7dfe-4c32-9261-8365bbed7285", true)
                    }
                  ]
                : []),
              ...(get(applicant, "answers.957dd6bb-c663-4272-bebb-66c4d9436075") === "M"
                ? [
                    {
                      name: "3.11.1 Any prostate conditions including raised PSA test?",
                      value:
                        get(applicant, "answers.c0412ce3-9a31-432f-b0fb-b015b205a327") === "Y"
                          ? "Yes"
                          : "No"
                    }
                  ]
                : []),
              ...(get(applicant, "answers.957dd6bb-c663-4272-bebb-66c4d9436075") === "M" &&
              get(applicant, "answers.c0412ce3-9a31-432f-b0fb-b015b205a327") === "Y"
                ? [
                    {
                      name: "3.11.1.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.61a2d84a-ba70-45a8-9810-ff646cce9791", true)
                    }
                  ]
                : []),
              {
                name: "3.12 Would you like Mental Health Cover?",
                value:
                  get(applicant, "answers.7c23d62e-11bf-4a12-bf62-db802a5fda14") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.7c23d62e-11bf-4a12-bf62-db802a5fda14") === "Y"
                ? [
                    {
                      name: "3.12.1 Anxiety, depression, stress or any other mental health issues (including but not limited to work stress, low mood, depression)?",
                      value:
                        get(applicant, "answers.2401f569-566b-4e96-a48d-c62e20f631a1") === "Y"
                          ? "Yes"
                          : "No"
                    }
                  ]
                : []),
              ...(get(applicant, "answers.7c23d62e-11bf-4a12-bf62-db802a5fda14") === "Y" &&
              get(applicant, "answers.2401f569-566b-4e96-a48d-c62e20f631a1") === "Y"
                ? [
                    {
                      name: "3.12.1.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.880db25f-55d0-410b-83d0-d038d87d75b9", true)
                    }
                  ]
                : [])
            ],
            [
              {
                name: "4 Do you have any medical conditions or symptoms for which you are receiving treatment, seeing a Doctor or other Health Professional, or for which check-ups, investigations or treatment are considered necessary or advisable?",
                value:
                  get(applicant, "answers.37818f95-1ca4-4e4c-905c-d9dd1545628f") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.37818f95-1ca4-4e4c-905c-d9dd1545628f") === "Y"
                ? [
                    {
                      name: "4.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.7c754c1b-ab74-4405-bee3-af9bdf395712", true)
                    }
                  ]
                : [])
            ],
            [
              {
                name: "5 In the past year have you taken or been advised to take any medicines or drugs or any other type of treatment?",
                value:
                  get(applicant, "answers.4757ce00-16ff-4621-afce-3706f65cd110") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.4757ce00-16ff-4621-afce-3706f65cd110") === "Y"
                ? [
                    {
                      name: "5.1 Please provide full details.",
                      value: get(applicant, "answers.17a79efc-a32f-4448-ad6c-df22624a765e", true)
                    }
                  ]
                : [])
            ]
          ]
        }

        if (underwritingStyle === "SWITCH" && ["FMU", "CME", "CPME"].includes(underwritingType)) {
          answersParts = [
            [
              {
                name: "Have you smoked or used nicotine replacement products in the last 12 months? (We may ask to carry out a simple test to verify your answer to this question)",
                value:
                  get(applicant, "answers.c2cc822e-09a3-4c1d-897c-739581f529ff") === "Y"
                    ? "Yes"
                    : "No"
              },
              {
                name: "2.1 Do you have any consultations, investigations or treatment planned or pending in the next 12 months (NHS or private)?",
                value:
                  get(applicant, "answers.3afec110-8357-42f1-8875-70eacfca2bba") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.3afec110-8357-42f1-8875-70eacfca2bba") === "Y"
                ? [
                    {
                      name: "2.1.1 Please provide full details.",
                      value: get(applicant, "answers.ac9b7fd1-5f19-48a6-8ae6-96ca2f7453c7", true)
                    }
                  ]
                : []),
              {
                name: "2.2 Have you had any consultations, investigations, symptoms (whether diagnosed or undiagnosed) or treatment in the last 12 months (NHS or Private)?",
                value:
                  get(applicant, "answers.90f4c395-3921-448a-936d-73edd9d93037") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.90f4c395-3921-448a-936d-73edd9d93037") === "Y"
                ? [
                    {
                      name: "2.2.1 Please provide full details.",
                      value: get(applicant, "answers.e6e3ca5a-e1df-40cf-b511-6649628f883e", true)
                    }
                  ]
                : [])
            ],
            [
              {
                name: "3. Have you been treated for, diagnosed with, or advised that you have any of the following in the last 5 years",
                value: true
              },
              {
                name: "3.1 Heart condition?",
                value:
                  get(applicant, "answers.fd01c2dd-dc36-43b1-a854-4fc23110dc19") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.fd01c2dd-dc36-43b1-a854-4fc23110dc19") === "Y"
                ? [
                    {
                      name: "3.1.1 Please provide full details.",
                      value: get(applicant, "answers.ae9bec5d-b72a-4d35-92bc-c23e3c7ebcdb", true)
                    }
                  ]
                : []),
              {
                name: "3.2 Cancer?",
                value:
                  get(applicant, "answers.8ef3d6b3-2a8b-4b42-a005-1e718cb1a6ff") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.8ef3d6b3-2a8b-4b42-a005-1e718cb1a6ff") === "Y"
                ? [
                    {
                      name: "3.2.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.9579e30e-c8f8-4982-828c-f3278b1e0b63", true)
                    }
                  ]
                : []),
              {
                name: "3.3 Stroke or transient ischaemic attack (TIA)?",
                value:
                  get(applicant, "answers.db284593-1f60-46a4-9d6f-9acf072e4d00") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.db284593-1f60-46a4-9d6f-9acf072e4d00") === "Y"
                ? [
                    {
                      name: "3.3.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.6e56b670-af84-4d7d-a67c-ed1e48b2e9fe", true)
                    }
                  ]
                : []),
              {
                name: "3.4 Would you like Mental Health Cover?",
                value:
                  get(applicant, "answers.e92ce69c-02f9-4020-9ce3-69d4e0fbdc41") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.e92ce69c-02f9-4020-9ce3-69d4e0fbdc41") === "Y"
                ? [
                    {
                      name: "3.4.1 Anxiety, depression, stress or any other mental health issues (including but not limited to work stress, low mood, depression)?",
                      value:
                        get(applicant, "answers.211d8f00-1f2d-4434-9447-32345763fd34") === "Y"
                          ? "Yes"
                          : "No"
                    }
                  ]
                : []),
              ...(get(applicant, "answers.e92ce69c-02f9-4020-9ce3-69d4e0fbdc41") === "Y" &&
              get(applicant, "answers.211d8f00-1f2d-4434-9447-32345763fd34") === "Y"
                ? [
                    {
                      name: "3.4.1.1 Please provide full details including dates, diagnosis and any medication given.",
                      value: get(applicant, "answers.44431408-c032-4a6a-b148-db278e37534e", true)
                    }
                  ]
                : []),
              {
                name: "3.5 Joint replacement or joint resurfacing?",
                value:
                  get(applicant, "answers.35ba60e3-391a-407b-a501-e82fdf953727") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.35ba60e3-391a-407b-a501-e82fdf953727") === "Y"
                ? [
                    {
                      name: "3.5.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.d439015d-8daf-45c2-bcdf-bfa33aa7c29b", true)
                    }
                  ]
                : []),
              {
                name: "3.6 Diabetes or pre-diabetes?",
                value:
                  get(applicant, "answers.2596eddf-7f11-492a-8a5d-2ffa5dca5eb7") === "Y"
                    ? "Yes"
                    : "No"
              },
              ...(get(applicant, "answers.2596eddf-7f11-492a-8a5d-2ffa5dca5eb7") === "Y"
                ? [
                    {
                      name: "3.6.1 Please provide full details including dates, diagnosis and medication given.",
                      value: get(applicant, "answers.411f803a-a47f-4687-9ec0-a29dbfc50ca6", true)
                    }
                  ]
                : [])
            ]
          ]
        }

        answersParts?.forEach(answers => {
          tempAuditData.push({
            name: `${getName({ data: applicant })}`,
            value: [
              {
                name: "Answers",
                value: answers
              }
            ]
          })
        })
      })

      updateJourneyAuditData([
        {
          mode: "replace",
          data: tempAuditData,
          isMultiCall: true
        }
      ])
    }
  }

  return (
    <>
      <Table
        data={applicants}
        isLoading={false}
        name="exeter"
        hasQueryControls
        columns={[
          {
            label: "Person",
            data: "first_name",
            minWidth: "50%",
            render: row => getName({ data: get(row, "data") })
          },
          {
            label: "",
            data: "answer_action",
            minWidth: "25%",
            render: row => {
              const applicationId = get(row, "data.journey_applicant_id")
              const isAnswered = applicantStatus[applicationId]
              const handleClick = () => {
                setSelectedApplicant(get(row, "data"))
                setOpen(true)
              }

              return (
                <Button type="inline-button" onClick={handleClick} isDisabled={isLocked}>
                  {isAnswered ? "Edit Responses" : "Answer Questions"}
                </Button>
              )
            }
          },
          {
            label: "Underwriting Completed",
            data: "answer_status",
            minWidth: "25%",
            render: row => {
              const applicationId = get(row, "data.journey_applicant_id")
              const isAnswered = applicantStatus[applicationId]

              return (
                <IconWithText
                  icon={isAnswered ? "check" : "alert"}
                  content={isAnswered ? "Questions Answered" : "Not Answered"}
                  iconColour={isAnswered ? "green" : "red"}
                  fontColour={isAnswered ? "green" : "red"}
                  margin="0"
                />
              )
            }
          }
        ]}
      />

      {isOpen && (
        <Modal
          applicant={selectedApplicant}
          data={data}
          handleCloseModal={handleCloseModal}
          underwritingStyle={underwritingStyle}
          underwritingType={underwritingType}
          onAnsweredQuestion={onAnsweredQuestion}
        />
      )}
    </>
  )
}

ExeterQuestionsTable.propTypes = {
  applicants: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.object
}

export default ExeterQuestionsTable
