import React from "react"
import { Helmet } from "react-helmet-async"

// Components
import QuickQuoteProvider from "./context/quickQuote.provider"
import { QuickQuote as QQ } from "../../UI/Templates"
import QuickQuoteAddLead from "./quickQuote.lead"

const QuickQuote = () => (
  <>
    <Helmet>
      <title>Quick Quote</title>
    </Helmet>
    <QuickQuoteProvider>
      <QQ />
      <QuickQuoteAddLead />
    </QuickQuoteProvider>
  </>
)

export default QuickQuote
