import React from "react"
import { get, capitalize, isEmpty } from "lodash"
import { v4 as uuid } from "uuid"
import { IconWithText, LabelWithText } from "@4cplatform/elements/Atoms"
import { Button, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { P, H3, SmallText } from "@4cplatform/elements/Typography"
import { AuthWrapper } from "@4cplatform/elements/Auth"
import { colours } from "@4cplatform/elements/Helpers"

// Helpers
import { renderA } from "../../Helpers"
import { ProvidersContext } from "./providers.context"

// Components
import ProvidersPanelHeader from "./providers.panel.header"
import { PanelBodyWrapper } from "./providers.styles"

const ProviderView = () => {
  const {
    viewData,
    selectedProvider,
    onDeleteProvider,
    deleteLoading,
    viewLoading,
    isContactDetails,
    isHavingUsers
  } = React.useContext(ProvidersContext)
  const [open, setOpen] = React.useState(false)
  const isDeleted = !!get(viewData, "deleted_at", get(selectedProvider, "deleted_at", false))

  return (
    <>
      <ProvidersPanelHeader viewData={viewData} isOpen={open} isDeleted={isDeleted} />
      <PanelBody isDeleted={isDeleted}>
        <PanelBodyWrapper>
          {!isDeleted && !isContactDetails && (
            <LabelWithText
              isLoading={viewLoading}
              appearance="light"
              label="Activation status"
              margin="0 0 3rem"
            >
              <IconWithText
                appearance="light"
                icon="checkbox-marked-circle"
                iconSize="1.8rem"
                margin="1rem 0 0"
                iconColour={colours.green}
                content={capitalize(get(viewData, "status", "active"))}
                isLoading={viewLoading}
              />
            </LabelWithText>
          )}
          {!isContactDetails && (
            <LabelWithText
              appearance="light"
              label="Description"
              margin="0 0 3rem"
              content={get(viewData, "description", "-")}
              loadingLines={2}
              isLoading={viewLoading}
            />
          )}
          <LabelWithText
            appearance="light"
            label="Risk email"
            margin="0 0 3rem"
            isLoading={viewLoading}
          >
            {renderA("email", get(viewData, "risk_email", "-"), "light")}
          </LabelWithText>

          <LabelWithText
            appearance="light"
            label="Underwriting email"
            margin="0 0 3rem"
            isLoading={viewLoading}
          >
            {renderA("email", get(viewData, "underwriting_email", "-"), "light")}
          </LabelWithText>

          <LabelWithText
            appearance="light"
            label="Onboarding email"
            margin="0 0 3rem"
            isLoading={viewLoading}
          >
            {renderA("email", get(viewData, "onboarding_email", "-"), "light")}
          </LabelWithText>

          <LabelWithText
            appearance="light"
            label="Agency codes email"
            margin="0 0 3rem"
            isLoading={viewLoading}
          >
            {renderA("email", get(viewData, "agency_codes_email", "-"), "light")}
          </LabelWithText>
        </PanelBodyWrapper>
        {!isDeleted && !isContactDetails && (
          <AuthWrapper roles={["SYS_ADMIN"]}>
            <Button
              appearance="error"
              trailingIcon="trash-can"
              onClick={() => setOpen(true)}
              isDisabled={viewLoading || isDeleted || isHavingUsers}
              isLoading={viewLoading}
            >
              Delete provider
            </Button>
          </AuthWrapper>
        )}

        {!isEmpty(get(viewData, "additional_contact_details", [])) && isContactDetails && (
          <>
            <H3 margin="0.5rem 0 1.5rem 0" appearance="light">
              Additional contact details
            </H3>
            {get(viewData, "additional_contact_details", []).map(item => {
              const type = get(item, "type", null)
              const contact = get(item, "contact", null)

              if (!type || !contact) return null

              let label = "Phone number"
              if (type === "email") label = "Email address"
              if (type === "url" || type === "website") label = "Website URL"

              return (
                <LabelWithText
                  key={uuid()}
                  margin="0"
                  appearance="light"
                  label={label}
                  loadingLines={3}
                >
                  {renderA(type, contact, "light")}
                  <SmallText appearance="light">{get(item, "description", "-")}</SmallText>
                </LabelWithText>
              )
            })}
          </>
        )}
      </PanelBody>
      {open && (
        <ConfirmationModal
          confirmIcon="trash-can"
          confirmText="Delete Provider"
          confirmAppearance="error"
          cancelAppearance="errorGhost"
          onClose={() => setOpen(false)}
          onConfirm={() => {
            onDeleteProvider(viewData)
          }}
          isLoadingConfirm={deleteLoading}
        >
          <P>Are you sure you want to delete this provider? This action cannot be undone.</P>
        </ConfirmationModal>
      )}
    </>
  )
}

export default ProviderView
