import React from "react"

// Components
import QuoteComparisonProvider from "./context/quoteComparison.provider"
import QuoteCompare from "./quoteComparison.compare"
import QuoteComparisonPanel from "./quoteComparison.panel"

const QuoteComparison = () => (
  <QuoteComparisonProvider>
    <QuoteCompare />
    <QuoteComparisonPanel />
  </QuoteComparisonProvider>
)

export default QuoteComparison
