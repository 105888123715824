import React, { useContext, Fragment } from "react"
import moment from "moment"
import { get, isEmpty, filter } from "lodash"
import { v4 as uuid } from "uuid"
import { IconWithText, LabelWithText } from "@4cplatform/elements/Atoms"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { PoliciesContext } from "./policies.context"
import { getName } from "../../Helpers"

// Components
import { Divider } from "./policies.styles"

const MedicalHistory = () => {
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { viewData, viewLoading } = useContext(PoliciesContext)

  const medicalHistory = get(viewData, "medical_notes", [])
  const applicants = get(viewData, "applicants", [])

  return (
    <LabelWithText appearance="light" label="Medical history" isLoading={viewLoading}>
      {isEmpty(medicalHistory) && (
        <IconWithText
          appearance="light"
          icon="minus"
          content="No history"
          isLoading={viewLoading}
          margin="0"
        />
      )}
      {!isEmpty(medicalHistory) &&
        applicants.map((applicant, i) => {
          const applicantHistory = filter(medicalHistory, {
            journey_applicant_id: get(applicant, "journey_applicant_id")
          })
          const name = getName({
            data: applicant,
            hasTitle: true,
            hasMiddle: true,
            globalTitles: GLOBAL_TITLES
          })
          const age = moment().diff(get(applicant, "date_of_birth"), "years")

          return (
            <Fragment key={uuid()}>
              <LabelWithText
                key={uuid()}
                appearance="light"
                label={`${name}, ${age}`}
                name={`disclosures_person_${i}`}
              >
                {isEmpty(applicantHistory) && (
                  <IconWithText
                    appearance="light"
                    icon="minus"
                    content="No history"
                    isLoading={viewLoading}
                    margin="0"
                  />
                )}
                {!isEmpty(applicantHistory) &&
                  applicantHistory.map(disclosure => (
                    <Fragment key={uuid()}>
                      <IconWithText
                        appearance="light"
                        icon="medical-bag"
                        content={get(disclosure, "condition", "-")}
                        isLoading={viewLoading}
                        margin="0"
                      />
                      <IconWithText
                        appearance="light"
                        icon="comment-text"
                        content={get(disclosure, "note", "-")}
                        isLoading={viewLoading}
                        margin="0"
                      />
                    </Fragment>
                  ))}
              </LabelWithText>
              {i !== applicants.length - 1 && <Divider />}
            </Fragment>
          )
        })}
    </LabelWithText>
  )
}

export default MedicalHistory
