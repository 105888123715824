import React from "react"

// Components
import { Tabs, Tab } from "../../Organisms"
import SalesSettings from "./organisationSettings.sales"
import JourneySettings from "./organisationSettings.journey"

const OrganisationSettings = () => (
  <Tabs margin="0" type="vertical" hasQueryControls name="settings">
    <Tab header="Sales Preferences">
      <SalesSettings />
    </Tab>
    <Tab header="Client Journey">
      <JourneySettings />
    </Tab>
  </Tabs>
)

export default OrganisationSettings
