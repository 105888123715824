import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Skeleton } from "@4cplatform/elements/Molecules"
import { Avatar } from "@4cplatform/elements/Atoms"

// Components
import {
  MessageWrapper,
  Message,
  Content,
  Timestamp,
  UnderWrapper,
  MessageLoading,
  UnderWrapperLoading
} from "./note.styles"

const Note = ({
  body,
  timestamp,
  firstName,
  lastName,
  colour,
  isNoteTab,
  isLoading,
  isFirst,
  isLast
}) => (
  <>
    {isLoading ? (
      <MessageWrapper isFirst={isFirst} isLast={isLast} data-testid="notes-loading_wrapper">
        <Skeleton wrapper={MessageLoading} />
        <UnderWrapper data-testid="test-notes-under-wrapper">
          <Skeleton wrapper={UnderWrapperLoading} />
          <Avatar first="Loading" last="State" margin="0 0.5rem 0 0" isLoading />
        </UnderWrapper>
      </MessageWrapper>
    ) : (
      <MessageWrapper isFirst={isFirst} isLast={isLast} data-testid="test-notes-message-wrapper">
        <Message data-testid="test-notes-message" isNoteTab={isNoteTab}>
          <Content data-testid="test-notes-content">{body}</Content>
        </Message>
        <Timestamp data-testid="test-notes-timestamp" colour={colour} isNoteTab={isNoteTab}>
          {moment.utc(timestamp, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")}
        </Timestamp>
        <UnderWrapper data-testid="test-notes-under-wrapper" isNoteTab={isNoteTab}>
          {firstName} {lastName}
          <Avatar first={firstName} last={lastName} margin="0 0 0 0.5rem" />
        </UnderWrapper>
      </MessageWrapper>
    )}
  </>
)

Note.defaultProps = {
  body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  timestamp: "2022-07-11T09:52:28.000000Z",
  firstName: "John",
  lastName: "Smith",
  colour: "white",
  isNoteTab: false,
  isFirst: false,
  isLast: false
}

Note.propTypes = {
  body: PropTypes.string,
  timestamp: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isNoteTab: PropTypes.bool,
  colour: PropTypes.string,
  isLoading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool
}

export default Note
