import { colours } from "@4cplatform/elements/Helpers"

export const getSystemAdminDash = () => [
  [
    {
      title: "Providers",
      links: [
        {
          title: "Add provider",
          description: "Add a provider to the platform",
          route: "/providers/add"
        },
        {
          title: "Manage providers",
          description: "Manage all providers on the platform",
          route: "/providers"
        }
      ],
      colour: colours.providersTeal,
      icon: "hospital-building",
      route: "/providers"
    },
    {
      title: "Networks",
      links: [
        {
          title: "Add network",
          description: "Add a network to the platform",
          route: "/networks/add"
        },
        {
          title: "Manage networks",
          description: "Manage all networks on the platform",
          route: "/networks"
        }
      ],
      colour: colours.networksRed,
      icon: "domain",
      route: "/networks"
    },
    {
      title: "Organisations",
      links: [
        {
          title: "Manage organisations",
          description: "Manage all organisations on the platform",
          route: "/organisations"
        }
      ],
      colour: colours.organisationsOrange,
      icon: "office-building-outline",
      route: "/organisations"
    },
    {
      title: "People",
      links: [
        {
          title: "Manage leads",
          description: "Manage all leads on the platform",
          route: "/leads"
        },
        {
          title: "Manage clients",
          description: "Manage all clients on the platform",
          route: "/clients"
        }
      ],
      colour: colours.peopleGreen,
      icon: "account-group",
      route: null
    }
  ],
  {
    title: "Policies",
    links: [
      {
        title: "Awaiting terms",
        description: "All quotes awaiting terms",
        route: "/policies?status=AWAITING_TERMS"
      },
      {
        title: "Awaiting acceptance",
        description: "All policies awaiting final terms and policy numbers",
        route: "/policies?status=AWAITING_ACCEPTANCE"
      },
      {
        title: "Failed to onboard",
        description: "All policies that failed onboarding",
        route: "/policies?status=FAILED_ONBOARDING"
      }
    ],
    colour: colours.policiesPurple,
    icon: "format-list-checks",
    route: "/policies"
  },
  {
    title: "Sales",
    links: [
      {
        title: "Journeys in progress",
        description: "Incomplete quotes",
        route: "/journeys?status=IN_PROGRESS"
      },
      {
        title: "Quotes completed",
        description: "Completed quotes",
        route: "/journeys?status=COMPLETE"
      },
      {
        title: "All policies",
        description: "All policies sold to date",
        route: "/policies?tab=ALL_POLICIES&status=SOLD"
      }
    ],
    colour: colours.quotesBlue,
    icon: "map-marker-path",
    route: null
  },
  {
    title: "Reports",
    links: [
      {
        title: "Reports",
        description: "Manage information, screen, print and download",
        route: "/reports"
      },
      {
        title: "Quick Quote log",
        description: "List of Quick Quote requests by client name  and user",
        route: "/quick-quote-log"
      }
    ],
    colour: colours.peopleGreen,
    icon: "account-group",
    route: null
  },
  {
    title: "Admin",
    links: [
      {
        title: "System settings",
        description: "Manage system wide settings",
        route: "/system-settings"
      },
      {
        title: "User administration",
        description: "Manage all users on the platform",
        route: "/users"
      },
      {
        title: "Agency codes",
        description: "Manage and view agency codes and commission rates",
        route: "/agency-codes?agency_codes_type=agency_codes"
      },
      {
        title: "My account",
        description: "Manage your account, username and password, etc.",
        route: "/my-account?my-account=details"
      },
      {
        title: "Quote API Status",
        description: "Current status of all product APIs available on the platform",
        route: "/api-status"
      }
    ],
    colour: colours.adminBlue,
    icon: "text-box-multiple-outline",
    route: "/my-account?my-account=details"
  }
]

export const getSystemSupportAdminDash = () => [
  [
    {
      title: "Providers",
      links: [
        {
          title: "Add provider",
          description: "Add a provider to the platform",
          route: "/providers/add"
        },
        {
          title: "Manage providers",
          description: "Manage all providers on the platform",
          route: "/providers"
        }
      ],
      colour: colours.providersTeal,
      icon: "hospital-building",
      route: "/providers"
    },
    {
      title: "Networks",
      links: [
        {
          title: "Add network",
          description: "Add a network to the platform",
          route: "/networks/add"
        },
        {
          title: "Manage networks",
          description: "Manage all networks on the platform",
          route: "/networks"
        }
      ],
      colour: colours.networksRed,
      icon: "domain",
      route: "/networks"
    },
    {
      title: "Organisations",
      links: [
        {
          title: "Manage organisations",
          description: "Manage all organisations on the platform",
          route: "/organisations"
        }
      ],
      colour: colours.organisationsOrange,
      icon: "office-building-outline",
      route: "/organisations"
    },
    {
      title: "People",
      links: [
        {
          title: "Manage leads",
          description: "Manage all leads on the platform",
          route: "/leads"
        },
        {
          title: "Manage clients",
          description: "Manage all clients on the platform",
          route: "/clients"
        }
      ],
      colour: colours.peopleGreen,
      icon: "account-group",
      route: null
    }
  ],
  {
    title: "Policies",
    links: [
      {
        title: "Awaiting terms",
        description: "All quotes awaiting terms",
        route: "/policies?status=AWAITING_TERMS"
      },
      {
        title: "Awaiting acceptance",
        description: "All policies awaiting final terms and policy numbers",
        route: "/policies?status=AWAITING_ACCEPTANCE"
      },
      {
        title: "Failed to onboard",
        description: "All policies that failed onboarding",
        route: "/policies?status=FAILED_ONBOARDING"
      }
    ],
    colour: colours.policiesPurple,
    icon: "format-list-checks",
    route: "/policies"
  },
  {
    title: "Sales",
    links: [
      {
        title: "Journeys in progress",
        description: "Incomplete quotes",
        route: "/journeys?status=IN_PROGRESS"
      },
      {
        title: "Quotes completed",
        description: "Completed quotes",
        route: "/journeys?status=COMPLETE"
      },
      {
        title: "All policies",
        description: "All policies sold to date",
        route: "/policies?tab=ALL_POLICIES&status=SOLD"
      },
      {
        title: "Reports",
        description: "Manage information, screen, print and download",
        route: "/reports"
      }
    ],
    colour: colours.quotesBlue,
    icon: "map-marker-path",
    route: null
  },
  {
    title: "Admin",
    links: [
      {
        title: "System settings",
        description: "Manage system wide settings",
        route: "/system-settings"
      },
      {
        title: "User administration",
        description: "Manage all users on the platform",
        route: "/users"
      },
      {
        title: "Agency codes",
        description: "Manage and view agency codes and commission rates",
        route: "/agency-codes?agency_codes_type=agency_codes"
      },
      {
        title: "My account",
        description: "Manage your account, username and password, etc.",
        route: "/my-account?my-account=details"
      },
      {
        title: "Quote API Status",
        description: "Current status of all product APIs available on the platform",
        route: "/api-status"
      }
    ],
    colour: colours.adminBlue,
    icon: "text-box-multiple-outline",
    route: "/my-account?my-account=details"
  }
]
