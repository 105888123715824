import { get } from "lodash"

import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"

import ExeterQuestionsTable from "./exeter.questions.table"

export const config = (data, t) => {
  const applicants = get(data, "journey.selected_quote.applicants", [])

  return {
    title: "The Exeter Medical Questions",
    sections: [
      {
        key: "application_info",
        components: [
          {
            component: ComplianceNote,
            componentProps: {
              children: (
                <>
                  <P>
                    Product name:{" "}
                    <strong>{t(get(data, "journey.selected_quote.product_name", "-"))}</strong>
                  </P>
                  <P>
                    Underwriting style:{" "}
                    <strong> {get(data, "journey.selected_quote.underwriting_style")}</strong>
                  </P>
                  <P margin="0">
                    Underwriting type:{" "}
                    <strong>{get(data, "journey.selected_quote.underwriting_type")}</strong>
                  </P>
                </>
              )
            },
            skipDataMap: true
          }
        ]
      },
      {
        key: "exeter_questions",
        components: [
          {
            component: ExeterQuestionsTable,
            componentProps: {
              applicants,
              data
            },
            skipDataMap: true
          }
        ]
      }
    ]
  }
}
