import { colours } from "@4cplatform/elements/Helpers"
import { COMMISSION_RATES } from "../../../config/pages"

export const getNetworkAdminDash = slug => [
  {
    title: "Organisations",
    links: [
      {
        title: "Send invitation",
        description: "Invite an organisation to your network",
        route: `/networks/${slug}?manage=invitations&send_open=true`
      },
      {
        title: "Active organisations",
        description: "Manage all active organisations in your network",
        route: `/networks/${slug}?manage=members`
      },
      {
        title: "Pending invitations",
        description: "View all pending organisation invitations in your network",
        route: `/networks/${slug}?manage=invitations`
      },
      {
        title: "Pending applications",
        description: "View all pending organisation applications in your network",
        route: `/networks/${slug}?manage=applications`
      }
    ],
    colour: colours.networksRed,
    icon: "office-building-outline",
    route: `/networks/${slug}?manage=members`
  },
  {
    title: "Quotes",
    links: [
      {
        title: "Quotes completed",
        description: "All quotes completed to date",
        route: "/journeys?status=COMPLETE"
      },
      {
        title: "Journeys in progress",
        description: "Incomplete quotes",
        route: "/journeys?status=IN_PROGRESS"
      }
    ],
    colour: colours.quotesBlue,
    icon: "map-marker-path",
    route: `/networks/${slug}?manage=quotes`
  },
  {
    title: "Policies",
    links: [
      {
        title: "All policies",
        description: "All policies sold to date",
        route: "/policies?tab=ALL_POLICIES&status=SOLD"
      },
      {
        title: "Awaiting terms",
        description: "All quotes awaiting terms",
        route: "/policies?status=AWAITING_TERMS"
      },
      {
        title: "Awaiting acceptance",
        description: "All policies awaiting final terms and policy numbers",
        route: "/policies?status=AWAITING_ACCEPTANCE"
      },
      {
        title: "Failed to onboard",
        description: "All policies that failed onboarding",
        route: "/policies?status=FAILED_ONBOARDING"
      }
    ],
    colour: colours.policiesPurple,
    icon: "format-list-checks",
    route: "/policies"
  },
  {
    title: "Admin",
    links: [
      {
        title: "Manage network",
        description: "Configure your network's settings",
        route: `/networks/${slug}?manage=details`
      },
      {
        title: "My account",
        description: "Manage your account, username and password, etc.",
        route: "/my-account?my-account=details"
      },
      {
        title: "User administration",
        description: "Manage all users on your network",
        route: `/networks/${slug}?manage=users`
      },
      {
        title: "Documents",
        description: "View all documents from your network",
        route: `/networks/${slug}?manage=documents`
      },
      {
        title: "Reports",
        description: "Manage information, screen, print and download",
        route: "/reports"
      },
      {
        title: "Commission rates",
        description: "Commission rates for networks",
        route: COMMISSION_RATES.path
      }
    ],
    colour: colours.adminBlue,
    icon: "text-box-multiple-outline",
    route: "/my-account?my-account=details"
  }
]

export const getNetworkMemberAdminDash = slug => [
  {
    title: "Organisations",
    links: [
      {
        title: "Send invitation",
        description: "Invite an organisation to your network",
        route: `/networks/${slug}?manage=invitations&send_open=true`
      },
      {
        title: "Active organisations",
        description: "Manage all active organisations in your network",
        route: `/networks/${slug}?manage=members`
      },
      {
        title: "Pending invitations",
        description: "View all pending organisation invitations in your network",
        route: `/networks/${slug}?manage=invitations`
      },
      {
        title: "Pending applications",
        description: "View all pending organisation applications in your network",
        route: `/networks/${slug}?manage=applications`
      }
    ],
    colour: colours.networksRed,
    icon: "office-building-outline",
    route: `/networks/${slug}?manage=members`
  },
  {
    title: "Quotes",
    links: [
      {
        title: "Quotes completed",
        description: "All quotes completed to date",
        route: "/journeys?status=COMPLETE"
      },
      {
        title: "Journeys in progress",
        description: "Incomplete quotes",
        route: "/journeys?status=IN_PROGRESS"
      }
    ],
    colour: colours.quotesBlue,
    icon: "map-marker-path",
    route: `/networks/${slug}?manage=quotes`
  },
  {
    title: "Policies",
    links: [
      {
        title: "All policies",
        description: "All policies sold to date",
        route: "/policies?tab=ALL_POLICIES&status=SOLD"
      },
      {
        title: "Awaiting terms",
        description: "All quotes awaiting terms",
        route: "/policies?status=AWAITING_TERMS"
      },
      {
        title: "Awaiting acceptance",
        description: "All policies awaiting final terms and policy numbers",
        route: "/policies?status=AWAITING_ACCEPTANCE"
      },
      {
        title: "Failed to onboard",
        description: "All policies that failed onboarding",
        route: "/policies?status=FAILED_ONBOARDING"
      }
    ],
    colour: colours.policiesPurple,
    icon: "format-list-checks",
    route: "/policies"
  },
  {
    title: "Admin",
    links: [
      {
        title: "Manage network",
        description: "Configure your network's settings",
        route: `/networks/${slug}?manage=details`
      },
      {
        title: "My account",
        description: "Manage your account, username and password, etc.",
        route: "/my-account?my-account=details"
      },
      {
        title: "Documents",
        description: "View all documents from your network",
        route: `/networks/${slug}?manage=documents`
      },
      {
        title: "Reports",
        description: "Manage information, screen, print and download",
        route: "/reports"
      },
      {
        title: "Commission rates",
        description: "Commission rates for networks",
        route: COMMISSION_RATES.path
      }
    ],
    colour: colours.adminBlue,
    icon: "text-box-multiple-outline",
    route: "/my-account?my-account=details"
  }
]
