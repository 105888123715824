import React, { useEffect } from "react"
import { get } from "lodash"
import moment from "moment"
import { v4 as uuid } from "uuid"

// Components
import { DatePicker, Input, Select } from "@4cplatform/elements/Forms"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"
import { CommissionRatesContext } from "./commissionRates.context"

const CommissionRatesAdd = () => {
  const {
    currentUser,
    users,
    selectedProvider,
    setIsOpenAddModal,
    formik,
    addCommissionRateLoading
  } = React.useContext(CommissionRatesContext)

  const { submitCount, handleSubmit } = formik

  const minEndDate = React.useMemo(() => {
    if (get(formik, "values.start_date", "")) {
      return moment(formik.values.start_date).add(1, "days").format("DD/MM/YYYY")
    }
    return ""
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  const closeAddModal = () => {
    setIsOpenAddModal(false)
  }

  useEffect(() => {
    if (submitCount >= 1) {
      const touchField = ["user_id", "commission_rate", "start_date", "end_date"]
      touchField.forEach(field => formik.setFieldTouched(field, true, true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount])

  const userId = get(formik, "values.user_id")
  const endDateStr = get(formik, "values.end_date")

  const selectedDateRanges =
    userId === "all" || !userId
      ? selectedProvider.commission_rates.map(commissionRate => [
          moment(commissionRate.start_date).format("DD/MM/YYYY"),
          moment(commissionRate.end_date).format("DD/MM/YYYY")
        ])
      : selectedProvider.commission_rates
          .filter(commissionRate => String(commissionRate.user.id) === userId)
          .map(commissionRate => [
            moment(commissionRate.start_date).format("DD/MM/YYYY"),
            moment(commissionRate.end_date).format("DD/MM/YYYY")
          ])

  const dateRangeMax = moment().add(1, "year").subtract(1, "day").format("DD/MM/YYYY")

  const dateRangeMaxStart = React.useMemo(() => {
    const nextYear = moment().add(1, "year").subtract(1, "day")
    if (!endDateStr) {
      return nextYear.format("DD/MM/YYYY")
    }

    const endDate = moment(endDateStr, "YYYY-MM-DD").subtract(1, "day")
    if (endDate.isBefore(nextYear)) {
      return endDate.format("DD/MM/YYYY")
    }

    return nextYear.format("DD/MM/YYYY")
  }, [endDateStr])

  return (
    <ConfirmationModal
      title="Add commission rate"
      confirmIcon="plus"
      confirmText="Add Commission rate"
      confirmAppearance="primary"
      isLoadingConfirm={addCommissionRateLoading}
      onClose={closeAddModal}
      onConfirm={handleSubmit}
      onCancel={closeAddModal}
    >
      <Select
        name="user_id"
        label="Member"
        formik={formik}
        isRequired
        isDisabled={addCommissionRateLoading}
        errorPrefix="none"
      >
        <option value="">Select person</option>
        {get(currentUser, "role.name", "") === "SALES_ADVISER" ? (
          <option key={get(currentUser, "id", "")} value={get(currentUser, "id", "")}>
            {get(currentUser, "first_name", "")} {get(currentUser, "last_name", "")}
          </option>
        ) : (
          <option key={uuid()} value="all">
            Apply to all members
          </option>
        )}
        {users &&
          users.map(user => (
            <option key={user.id} value={user.id}>
              {user.first_name} {user.last_name}
            </option>
          ))}
      </Select>

      <Input
        min={0}
        max={100}
        step={0.1}
        type="number"
        name="commission_rate"
        formik={formik}
        label="Commission Rate"
        leadingIconType="prepend"
        leadingIcon="percent-outline"
        isDisabled={addCommissionRateLoading}
        isRequired
      />

      <DatePicker
        name="start_date"
        formik={formik}
        label="Start date"
        isRequired
        isDisabled={addCommissionRateLoading}
        dateRangeMax={dateRangeMaxStart}
        dateRangeDisableds={selectedDateRanges}
        errorPrefix="none"
      />
      <DatePicker
        name="end_date"
        formik={formik}
        label="End date"
        dateRangeMin={minEndDate}
        isRequired
        isDisabled={addCommissionRateLoading}
        dateRangeMax={dateRangeMax}
        dateRangeDisableds={selectedDateRanges}
        errorPrefix="none"
      />
    </ConfirmationModal>
  )
}

export default CommissionRatesAdd
