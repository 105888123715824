import React from "react"
import { colours } from "@4cplatform/elements/Helpers"
import { P } from "@4cplatform/elements/Typography"
import { Column, IconWithText, Row } from "@4cplatform/elements/Atoms"

// Components
import { PanelContent, Video } from "../../components"
import { HeroPanel as Panel } from "../../landingPage.styles"

const panelProps = {
  title: {
    text: "Simple PMI for brokers & IFAs",
    colour: colours.lightBlue
  },
  subtitle: {
    text: "4C Platform is an online tool that assists in the fact find, application and onboarding of Private Medical Insurance",
    colour: colours.white
  },
  button: {
    text: "Discover the 4C Platform",
    href: "#discover"
  },
  hrColour: colours.white
}

const HeroPanel = () => (
  <Panel id="hero" data-testid="hero-panel">
    <PanelContent {...panelProps}>
      <Row justifyContent="space-between" alignItems="center">
        <Column phone={12} small={12} tablet={12} medium={12} large={6} xLarge={5}>
          <IconWithText
            icon="check"
            iconColour={colours.lightBlue}
            iconSize="2rem"
            margin="0 0 4rem"
          >
            <P colour={colours.white} lineHeight="1.6" margin="0">
              The 4C Platform's guided sales journey has been designed to make selling Private
              Medical Insurance in a compliant manner as straightforward as possible.
            </P>
          </IconWithText>
          <IconWithText
            icon="check"
            iconColour={colours.lightBlue}
            iconSize="2rem"
            margin="0 0 4rem"
          >
            <P colour={colours.white} lineHeight="1.6" margin="0">
              A dynamic selection of PMI policy options, qualifying questions &#38; live rates
              enables you to tailor your clients' quotes and provide the information they need
              instantly.
            </P>
          </IconWithText>
          <IconWithText
            icon="check"
            iconColour={colours.lightBlue}
            iconSize="2rem"
            margin="0 0 4rem"
          >
            <P colour={colours.white} lineHeight="1.6" margin="0">
              The auto-populated application forms, customer facing documentation and electronic
              onboarding direct to the insurers allows you to quickly complete PMI sales online with
              no paperwork and less admin.
            </P>
          </IconWithText>
        </Column>
        <Column phone={12} small={12} tablet={12} medium={12} large={12} xLarge={6}>
          <Video src="https://www.youtube.com/embed/OeRi5GbQDPE" title="YouTube video player" />
        </Column>
      </Row>
    </PanelContent>
  </Panel>
)

export default HeroPanel
