import React, { useContext } from "react"
import { v4 as uuid } from "uuid"
import { Checkbox, Select } from "@4cplatform/elements/Forms"
import { AuthContext } from "@4cplatform/elements/Auth"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { QuotesContext } from "./quotes.context"

// Components
import { Search } from "../../Molecules"
import { TableActionsWrapper, TableActionsLeft, TableActionsRight } from "./quotes.styles"

const Actions = () => {
  const {
    search,
    hasShowSimulated,
    showSimulated,
    hasStatusFilter,
    setFilter,
    filter,
    status,
    quoteStatuses,
    setStatus,
    setShowSimulated,
    setSearch
  } = useContext(QuotesContext)
  const { canAccess } = useContext(AuthContext)
  const t = useTranslations()

  return (
    <TableActionsWrapper
      data-testid="quotes-actions-wrapper"
      hasLeftActions={hasShowSimulated || hasStatusFilter}
    >
      <TableActionsLeft>
        {hasShowSimulated && (
          <Checkbox
            label="Show simulated quotes"
            margin="0 1rem 0 0"
            name="showSimulated"
            value={showSimulated}
            onChange={setShowSimulated}
            data-testid="quote-admin-show-simulated"
          />
        )}

        {hasStatusFilter && canAccess(["PROVIDER_ADMIN", "SYS_ADMIN", "SUPPORT_ADMIN"]) && (
          <Select
            name="filter_status"
            onChange={val => setStatus(val)}
            margin="0 1rem"
            value={status}
            label="Filter status by"
            labelWidth="auto"
            isHorizontal
          >
            <option value="" key={uuid()}>
              {t("ALL_QUOTES")}
            </option>
            {quoteStatuses.map(quoteStatus => (
              <option key={uuid()} value={Object.keys(quoteStatus)}>
                {t(Object.values(quoteStatus))}
              </option>
            ))}
          </Select>
        )}
      </TableActionsLeft>
      <TableActionsRight>
        <Select
          name="filter_search"
          onChange={val => setFilter(val)}
          margin="0 1rem 0 0"
          value={filter}
          label="Search by"
          labelWidth="auto"
          isHorizontal
        >
          <option value="client_name">Client name</option>
          <option value="organisation_name">Organisation name</option>
          <option value="sales_agent">Sales agent</option>
          <option value="product_name">Product</option>
        </Select>
        <Search
          name="search_quotes"
          handleChange={setSearch}
          onCancel={() => setSearch("")}
          margin="0"
          defaultValue={search}
        />
      </TableActionsRight>
    </TableActionsWrapper>
  )
}

export default Actions
