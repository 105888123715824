import styled from "styled-components"

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
`

export const PanelButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
