import styled from "styled-components"
import { Button } from "@4cplatform/elements/Molecules"

export const TableActionsButton = styled(Button)`
  height: 5rem;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
