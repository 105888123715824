import React, { useContext, useReducer, useCallback, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get, isEmpty, find, omit, floor, capitalize } from "lodash"
import { object, string, array, date } from "yup"
import { useFormik } from "formik"
import { ApiError, usePost, useGet, PusherContext } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { PageContext } from "@4cplatform/elements/Organisms"
import { isValidJson } from "@4cplatform/elements/Helpers"
import { validNameRegex, validPostcodeRegex } from "../../../UI/Helpers"
import { Provider } from "../../../UI/Templates/QuickQuote"
import reducer from "./quickQuote.reducer"

const QuickQuoteProvider = ({ children }) => {
  const { client: pusher } = useContext(PusherContext)
  const { DEBUG } = useContext(ConfigContext)
  const { selfServiceData } = useContext(PageContext)
  const t = useTranslations()
  const [
    {
      quickQuoteSettings,
      quotesRequested,
      quotesArrived,
      expectedQuotes,
      arrivedQuotes,
      previousBody,
      isAddLeadOpen
    },
    dispatch
  ] = useReducer(reducer, {
    quickQuoteSettings: {},
    quotesRequested: false,
    quotesArrived: false,
    expectedQuotes: [],
    arrivedQuotes: [],
    previousBody: {},
    isAddLeadOpen: false
  })

  // Quote request
  const [requestQuotes, { loading: requestQuotesLoading, error: requestQuotesError }] = usePost({
    endpoint: "/quick-quote",
    onError: error => {
      dispatch({ type: "UPDATE_VALUE", key: "quotesRequested", value: false })
      dispatch({
        type: "UPDATE_VALUE",
        key: "quotesArrived",
        value: false
      })
      if (error?.__exception.message) {
        addAlert({
          message: t(error.__exception.message),
          type: "error",
          dismissible: true,
          timeout: 5
        })
      }
    },
    onCompleted: res => {
      const data = get(res, "data", [])

      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log(`Expecting ${data.length} quotes:`, data)
      }

      dispatch({
        type: "UPDATE_VALUE",
        key: "expectedQuotes",
        value: data
      })
    }
  })

  const validationSchema = object({
    payment_frequency: string().required("MISSING_REQUIRED_FIELD"),
    applicants: array(
      object({
        type: string().required("MISSING_REQUIRED_FIELD"),
        date_of_birth: date()
          .required("MISSING_REQUIRED_FIELD")
          .when("type", {
            is: val => val === "dependant",
            then: schema =>
              schema.test(
                "notOlderThanPrimary",
                "of a dependant cannot exceed that of the policy holder",
                (value, context) => {
                  const primaryDob = get(
                    find(get(context, "from[1].value.applicants", []), {
                      type: "primary"
                    }),
                    "date_of_birth",
                    null
                  )

                  return !moment(value).isBefore(primaryDob)
                }
              )
          })
          .when("type", {
            is: val => val === "partner",
            then: schema =>
              schema.test(
                "partner18orOver",
                "of the partner must be 18 years old or over",
                value => !moment(value).isSameOrAfter(moment().subtract(18, "years"))
              )
          }),
        first_name: string().when("type", {
          is: val => val === "primary",
          then: schema =>
            schema
              .required("MISSING_REQUIRED_FIELD")
              .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name)),
          otherwise: schema => schema.notRequired().nullable()
        }),
        last_name: string().when("type", {
          is: "primary",
          then: schema =>
            schema
              .required("MISSING_REQUIRED_FIELD")
              .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name)),
          otherwise: schema => schema.notRequired().nullable()
        }),
        postcode: string().when("type", {
          is: "primary",
          then: schema =>
            schema
              .required("MISSING_REQUIRED_FIELD")
              .test("correctUkPostcode", "CORRECT_UK_POSTCODE", no => validPostcodeRegex.test(no)),
          otherwise: schema => schema.notRequired().nullable()
        })
      })
    )
      .min(1)
      .test(
        "primaryApplicantMandatory",
        "true",
        value => !isEmpty(find(value, { type: "primary" }))
      )
  })

  const formikInstance = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      send_as_email: false,
      payment_frequency: "MONTHLY",
      applicants: [
        {
          type: "",
          date_of_birth: ""
        }
      ]
    },
    validationSchema,
    onSubmit: args => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "expectedQuotes",
        value: []
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "arrivedQuotes",
        value: []
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "previousBody",
        value: args
      })
      dispatch({ type: "UPDATE_VALUE", key: "quotesRequested", value: true })
      dispatch({
        type: "UPDATE_VALUE",
        key: "quotesArrived",
        value: false
      })

      const applicants = get(args, "applicants", [])
      const primaryApplicant = find(applicants, { type: "primary" })

      const body = {
        ...omit(args, "send_as_email"),
        postcode: get(primaryApplicant, "postcode"),
        applicants: [
          omit(primaryApplicant, "postcode"),
          ...applicants.filter(applicant => applicant.type !== "primary")
        ]
      }

      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log("Requesting quotes:", body)
      }

      requestQuotes({
        body
      })
    }
  })

  const formik = { ...formikInstance, validationSchema }

  const onQuoteArrived = useCallback(
    res => {
      const quote = isValidJson(get(res, "data")) ? JSON.parse(get(res, "data")) : null
      const quoteType = get(res, "quoteType")

      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log(
          `${get(quote, "data.provider.name")} ${get(quote, "data.product")} ${capitalize(
            quoteType.replace("QQ_", "")
          )} quote ${get(quote, "success", false) ? "arrived:" : "errored out:"}`,
          quote || "Not valid JSON",
          res
        )
      }

      if (quote) {
        dispatch({
          type: "QUOTE_ARRIVED",
          value: { ...quote, sentAt: get(res, "sentAt"), quoteType },
          paymentFrequency: get(formik, "values.payment_frequency", "MONTHLY")
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [DEBUG]
  )

  useEffect(() => {
    const channel = pusher.subscribe(`private-encrypted-user.${get(selfServiceData, "id")}`)

    channel.bind("4cng.pmi_quick_quote-response", onQuoteArrived)

    return () => pusher.unsubscribe(`private-encrypted-user.${get(selfServiceData, "id")}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const paymentFrequency = get(formik, "values.payment_frequency", "MONTHLY")

  useEffect(() => {
    if (paymentFrequency)
      dispatch({
        type: "REORDER_QUOTES",
        paymentFrequency
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentFrequency])

  const roleName = useMemo(() => get(selfServiceData, "role.name", ""), [selfServiceData])

  const slug = useMemo(() => {
    switch (roleName) {
      case "ORG_ADMIN":
      case "SALES_ADVISER":
        return get(selfServiceData, "slug", "")
      default:
        return ""
    }
  }, [roleName, selfServiceData])

  const endpoint = useMemo(() => {
    switch (roleName) {
      case "ORG_ADMIN":
      case "SALES_ADVISER":
        return "/users/:slug/quick-quote-options"
      default:
        return ""
    }
  }, [roleName])

  // Quick quote settings query
  useGet({
    skip: !slug || !endpoint,
    params: { slug },
    endpoint,
    onCompleted: res =>
      dispatch({ type: "UPDATE_VALUE", key: "quickQuoteSettings", value: get(res, "data", {}) })
  })

  const isDisabled = quotesRequested && !quotesArrived

  if (DEBUG && isDisabled) {
    // eslint-disable-next-line no-console
    console.log("Quotes requested", quotesRequested)
    // eslint-disable-next-line no-console
    console.log("All quotes received", quotesArrived)
  }

  return (
    <Provider
      value={{
        formik,
        quotesRequested,
        quotesArrived,
        expectedQuotes,
        arrivedQuotes,
        requestQuotesLoading,
        requestQuotesError,
        isAddLeadOpen,
        setAddLeadOpen: value => dispatch({ type: "UPDATE_VALUE", key: "isAddLeadOpen", value }),
        previousBody,
        getProgress: () =>
          floor(
            expectedQuotes.length > 0
              ? (arrivedQuotes.length / expectedQuotes.length) * 100
              : (arrivedQuotes.length / 30) * 100,
            0
          ),
        quickQuoteSettings,
        isDisabled
      }}
    >
      {children}
      <ApiError error={requestQuotesError} shouldAddAlert={false} />
    </Provider>
  )
}

QuickQuoteProvider.propTypes = {
  children: PropTypes.any
}

export default QuickQuoteProvider
