import {
  DynamicPage,
  Applicants,
  MedicalHistory,
  HospitalPreference,
  QuoteComparison,
  QuotationSummary
} from "./pages"

export const getJourneyPageComponent = key => {
  switch (key) {
    case "APPLICANTS":
      return Applicants
    case "MEDICAL_HISTORY":
      return MedicalHistory
    case "HOSPITAL_PREFERENCE":
      return HospitalPreference
    case "QUOTE_COMPARISON":
      return QuoteComparison
    case "QUOTATION_SUMMARY":
      return QuotationSummary
    default:
      return DynamicPage
  }
}
