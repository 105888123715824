import React from "react"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { Button, ConfirmationModal } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"

// Helpers
import { CommissionRatesContext } from "./commissionRates.context"

// Components
import CommissionRatesPanelHeader from "./commissionRates.panel.header"

const CommissionRatesView = () => {
  const {
    selectedCommissionRate,
    deleteCommissionRate,
    deleteCommissionRateLoading,
    isOpenDeleteModal: isOpen,
    setOpenDeleteModal: setIsOpen
  } = React.useContext(CommissionRatesContext)

  return (
    <>
      <CommissionRatesPanelHeader
        selectedCommissionRate={selectedCommissionRate}
        isLoading={false}
      />
      <PanelBody>
        <Button
          appearance="error"
          trailingIcon="delete"
          onClick={() => setIsOpen(true)}
          isLoading={deleteCommissionRateLoading}
          isDisabled={deleteCommissionRateLoading}
          name="delete_commission_rate"
        >
          Delete
        </Button>
      </PanelBody>
      {isOpen && (
        <ConfirmationModal
          confirmText="Yes"
          confirmAppearance="success"
          onClose={() => setIsOpen(false)}
          onConfirm={() => {
            deleteCommissionRate(selectedCommissionRate)
            setIsOpen(false)
          }}
        >
          <P>
            Are you sure you want to delete this commission rate?
            <br />
            This action cannot be undone.
          </P>
        </ConfirmationModal>
      )}
    </>
  )
}

export default CommissionRatesView
