import React, { useContext } from "react"
import { capitalize, get } from "lodash"
import moment from "moment"
import { Table } from "@4cplatform/elements/Organisms"

// Helpers
import { ApplicantsContext } from "./applicants.context"
import { getName } from "../../../../../../../../Helpers"

const IncludedTable = () => {
  const { included, setIncludedPerPage, setIncludedPage, includedPagination, includedLoading } =
    useContext(ApplicantsContext)

  return (
    <Table
      data={included}
      type="no-lines"
      isLoading={includedLoading}
      name="included"
      columns={[
        {
          label: "Name",
          data: "first_name",
          minWidth: "180px",
          render: row => getName({ data: get(row, "data") })
        },
        {
          label: "Age",
          data: "date_of_birth",
          minWidth: "80px",
          render: row =>
            moment().diff(
              moment.utc(get(row, "data.date_of_birth"), "YYYY-MM-DD HH:mm").local(),
              "years"
            )
        },
        {
          label: "Type",
          data: "type",
          minWidth: "100px",
          render: row => capitalize(get(row, "data.type", "-"))
        }
      ]}
      hasActions={false}
      pagination={includedPagination}
      changePage={e => setIncludedPage(e)}
      changePerPage={setIncludedPerPage}
      perPageOptions={{ max: 20, interval: 5 }}
      hasPerPage={false}
    />
  )
}

export default IncludedTable
