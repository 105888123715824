import styled from "styled-components"

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2.5rem;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FlexWrapper = styled.div`
  margin-bottom: 2.5rem;
`
