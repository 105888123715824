import { get } from "lodash"

// Helpers
import { logToConsole } from "../../../../../../../../../Helpers"
import { sortQuotesByPremium } from "../quoteComparison.helpers"

export const initialState = {
  subscribedToQuotes: false,
  globalOptions: {},
  currentOptions: {},
  expectedQuotes: [],
  expectedQuotesArrived: false,
  arrivedQuotes: [],
  tempArrivedQuotes: [],
  selectedQuotes: [],
  initCurrentPolicyOptions: {},
  regenerateModal: false,
  providerInfoModal: { open: false, data: {} }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_VALUE": {
      return {
        ...state,
        [action.key]: action.value
      }
    }

    case "EXPECTED_QUOTES_ARRIVED": {
      return {
        ...state,
        expectedQuotesArrived: true,
        expectedQuotes: action.value,
        arrivedQuotes: sortQuotesByPremium([
          ...state.arrivedQuotes,
          ...state.tempArrivedQuotes.filter(q => action.value.includes(get(q, "quote_request_id")))
        ])
      }
    }

    case "QUOTE_ARRIVED": {
      if (action.debug) {
        logToConsole(
          "log",
          `${get(action.value, "data.provider.name")} ${get(action.value, "data.product")} quote ${
            get(action.value, "success", false) ? "arrived:" : "errored out:"
          }`,
          action.value || "Not valid JSON",
          !state.expectedQuotesArrived
            ? "Expected quotes request still loading, adding it to temporary array."
            : "Expected quotes request loaded, adding it to quotes array."
        )
      }

      if (
        state.expectedQuotesArrived &&
        state.expectedQuotes.includes(get(action.value, "quote_request_id"))
      ) {
        return {
          ...state,
          arrivedQuotes: sortQuotesByPremium([...state.arrivedQuotes, action.value])
        }
      }
      return {
        ...state,
        tempArrivedQuotes: [...state.tempArrivedQuotes, action.value]
      }
    }

    case "RESET_QC":
      return initialState

    default:
      return state
  }
}

export default reducer
