export const salesSettingsLabels = {
  buy_sales_leads: "Do you currently buy sales leads?",
  critical_illness_cover: "Critical illness cover",
  general_insurance: "General insurance",
  income_protection_insurance: "Income protection insurance",
  investments: "Investments",
  life_insurance: "Life insurance",
  mortgages: "Mortgages",
  pensions: "Pensions",
  group_pmi: "Group private medical insurance",
  individual_pmi: "Individual private medical insurance"
}
