import React, { useContext } from "react"
import { Checkbox } from "@4cplatform/elements/Forms"

// Helpers
import { ClientsContext } from "../../clients.context"

// Components
import { Search } from "../../../../Molecules"
import { TableActionsWrapper, TableActionsLeft, TableActionsRight } from "../../clients.styles"

const Actions = () => {
  const { quotesSearch, quotesShowSimulated, updateQuotesPageValue } = useContext(ClientsContext)

  return (
    <TableActionsWrapper data-testid="clients-quotes-actions-wrapper" hasLeftActions>
      <TableActionsLeft>
        <Checkbox
          label="Show simulated quotes"
          margin="0"
          name="simulation_mode"
          appearance="light"
          value={quotesShowSimulated}
          onChange={value => updateQuotesPageValue("quotesShowSimulated", value)}
          data-testid="simulation_mode"
        />
      </TableActionsLeft>
      <TableActionsRight>
        <Search
          name="search_client-quotes"
          handleChange={value => {
            if (quotesSearch.length > 0 && (value.length < 3 || value === quotesSearch)) {
              updateQuotesPageValue("quotesSearch", "")
            } else if (value.length >= 3) {
              updateQuotesPageValue("quotesSearch", value)
            }
          }}
          onCancel={() => updateQuotesPageValue("quotesSearch", "")}
          margin="0"
          defaultValue={quotesSearch}
        />
      </TableActionsRight>
    </TableActionsWrapper>
  )
}

export default Actions
