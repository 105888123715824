import React, { useContext } from "react"

// Helpers
import { QuickQuoteContext } from "./quickQuote.context"

// Components
import {
  ProgressIndicatorContainer,
  ProgressBarContainer,
  ProgressBar,
  ProgressPercentage
} from "./quickQuote.styles"

const ProgressIndicator = () => {
  const { getProgress, isDisabled } = useContext(QuickQuoteContext)

  const realProgress = getProgress()
  const progress = realProgress >= 100 ? 100 : realProgress

  if (!isDisabled) return null

  return (
    <ProgressIndicatorContainer>
      <ProgressBarContainer>
        <ProgressBar progress={progress} />
      </ProgressBarContainer>
      <ProgressPercentage>{progress}%</ProgressPercentage>
    </ProgressIndicatorContainer>
  )
}

export default ProgressIndicator
