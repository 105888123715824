import React from "react"
import moment from "moment"
import { get } from "lodash"
import { useHistory } from "react-router-dom"
import { IconWithText, LabelWithText } from "@4cplatform/elements/Atoms"
import { Button } from "@4cplatform/elements/Molecules"
import { PanelHeader } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { H2, SmallText, P } from "@4cplatform/elements/Typography"
import { PageContext } from "@4cplatform/elements/Organisms"

// Components
import { PanelButtonsWrapper } from "./networkOrganisations.styles"

// Helpers
import { NetworkOrganisationsContext } from "./networkOrganisations.context"
import { getName } from "../../Helpers"

const NetworkOrganisationsPanelHeader = () => {
  const { resetPanel } = React.useContext(PageContext)
  const {
    viewLoading,
    network,
    viewData: selectedOrganisation
  } = React.useContext(NetworkOrganisationsContext)
  const name = get(selectedOrganisation, "name", "")
  const lastLoggedIn = get(selectedOrganisation, "last_logged_in_at", "")
  const history = useHistory()

  return (
    <PanelHeader>
      <H2 margin="1rem 0" appearance="light" isLoading={viewLoading}>
        {name}
      </H2>
      <SmallText appearance="light" isLoading={viewLoading}>
        Last login:{" "}
        {lastLoggedIn
          ? moment.utc(lastLoggedIn, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
          : "-"}
      </SmallText>

      {/* Contact Info */}
      <IconWithText
        icon="pound"
        iconSize="1.5rem"
        appearance="light"
        content={get(selectedOrganisation, "id", "-")}
        fontSize="1.6rem"
        isLoading={viewLoading}
        margin="0 0 1rem"
      />
      <IconWithText
        icon="star"
        iconSize="1.5rem"
        appearance="light"
        content={get(selectedOrganisation, "phone_number", "-")}
        fontSize="1.6rem"
        isLoading={viewLoading}
        margin="0 0 1rem"
      />
      <IconWithText
        icon="account"
        iconSize="1.5rem"
        appearance="light"
        content={getName({ data: selectedOrganisation, isContact: true })}
        fontSize="1.6rem"
        isLoading={viewLoading}
        margin="0"
      />
      <IconWithText
        icon="subdirectory-arrow-right"
        appearance="light"
        content={get(selectedOrganisation, "contact_email_address", "-")}
        fontSize="1.6rem"
        isLoading={viewLoading}
        margin="0 0 2rem 1rem"
      />
      <LabelWithText label="Address" appearance="light" isLoading={viewLoading}>
        <>
          {!!get(selectedOrganisation, "address.line_one") && (
            <P appearance="light" margin="0">
              {get(selectedOrganisation, "address.line_one")}
            </P>
          )}
          {!!get(selectedOrganisation, "address.line_two") && (
            <P appearance="light" margin="0">
              {get(selectedOrganisation, "address.line_two")}
            </P>
          )}
          {!!get(selectedOrganisation, "address.city") && (
            <P appearance="light" margin="0">
              {get(selectedOrganisation, "address.city")}
            </P>
          )}
          {!!get(selectedOrganisation, "address.county") && (
            <P appearance="light" margin="0">
              {get(selectedOrganisation, "address.county")}
            </P>
          )}
          {!!get(selectedOrganisation, "address.postcode") && (
            <P appearance="light" margin="0">
              {get(selectedOrganisation, "address.postcode")}
            </P>
          )}
        </>
      </LabelWithText>

      {/* Quotes & Policies */}
      <PanelButtonsWrapper>
        <Button
          appearance="whiteGhost"
          trailingIcon="map-marker-path"
          onClick={() => {
            resetPanel()
            history.push(
              `/networks/${get(network, "slug", "")}?manage=quotes&organisation_name=${encodeURI(
                get(selectedOrganisation, "name")
              )}`
            )
          }}
        >
          Quotes
        </Button>
        <Button
          appearance="whiteGhost"
          trailingIcon="script-text"
          onClick={() => {
            resetPanel()
            history.push(
              `/networks/${get(network, "slug", "")}?manage=policies&organisation_name=${encodeURI(
                get(selectedOrganisation, "name")
              )}`
            )
          }}
        >
          Policies
        </Button>
      </PanelButtonsWrapper>
    </PanelHeader>
  )
}

export default NetworkOrganisationsPanelHeader
