import React, { useContext } from "react"
import { get, find } from "lodash"
import { colours, getTwoFactorAuthIcon } from "@4cplatform/elements/Helpers"
import { H4 } from "@4cplatform/elements/Typography"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { Button } from "@4cplatform/elements/Molecules"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { useTranslations } from "@4cplatform/elements/Translations"
import { AuthWrapper } from "@4cplatform/elements/Auth"

// Helpers
import { UsersContext } from "./users.context"

// Components
import { PanelActionsWrapper, PanelActionButton } from "./users.styles"
import UsersPanelHeader from "./users.panel.header"
import SimulationMode from "./users.panel.view.simulation"
import ToggleStanding from "./users.panel.view.standing"
import DeleteUser from "./users.panel.view.delete"

const UserView = () => {
  const {
    viewData,
    twoFA,
    viewLoading,
    resetTwoFactorAuthForAUser,
    twoFactorResetLoading,
    resendConfirmation,
    resendConfirmationLoading,
    resetPasswordRequest,
    resetPasswordRequestLoading
  } = useContext(UsersContext)
  const t = useTranslations()

  const twoFactorAuth = get(twoFA, "two_factor_auth", "TWO_FA_NONE")
  const isLocked = get(viewData, "locked", false)
  const isActive = get(viewData, "active", false)
  const isDeleted = !!get(viewData, "deleted_at", get(viewData, "deleted_at", false))

  const getTwoFaHelper = () => (
    <IconWithText
      appearance="dark"
      content={t(`${twoFactorAuth}_DESC`)}
      fontSize="1.4rem"
      margin="0"
      {...getTwoFactorAuthIcon(twoFactorAuth)}
    />
  )

  return (
    <>
      {/* User Information */}
      <UsersPanelHeader viewData={viewData} context="open" isDeleted={isDeleted} />

      <PanelBody isDeleted={isDeleted}>
        {/* Reset password */}
        <H4 margin="0 0 1rem" appearance="light" isLoading={viewLoading}>
          Reset password
        </H4>
        <PanelActionsWrapper>
          <Button
            type="submit"
            appearance="whiteGhost"
            trailingIcon="lock-reset"
            name="send_password_reset_email"
            onClick={() => resetPasswordRequest()}
            isDisabled={resetPasswordRequestLoading || isLocked || isDeleted || !isActive}
          >
            Send password reset
          </Button>
        </PanelActionsWrapper>

        {/* 2FA Actions */}
        <H4
          margin="0 0 1rem"
          appearance="light"
          isLoading={viewLoading}
          helperText={getTwoFaHelper()}
        >
          Two-factor authentication
        </H4>
        <PanelActionsWrapper>
          <IconWithText
            appearance="light"
            content={t(twoFactorAuth)}
            fontSize="1.4rem"
            margin="0"
            isLoading={viewLoading}
            loadingWidth="15rem"
            {...getTwoFactorAuthIcon(twoFactorAuth)}
          />
          {twoFactorAuth !== "TWO_FA_NONE" && !viewLoading && (
            <PanelActionButton
              appearance="whiteGhost"
              trailingIcon="lock-reset"
              isLoading={twoFactorResetLoading}
              isDisabled={viewLoading}
              onClick={resetTwoFactorAuthForAUser}
            >
              Reset
            </PanelActionButton>
          )}
        </PanelActionsWrapper>

        {/* Account Standing */}
        <H4 margin="0 0 1rem" appearance="light" isLoading={viewLoading}>
          Account standing
        </H4>
        <PanelActionsWrapper>
          <IconWithText
            icon={isLocked ? "lock" : "lock-open"}
            appearance="light"
            content={isLocked ? "Locked" : "Unlocked"}
            fontSize="1.4rem"
            margin="0"
            isLoading={viewLoading}
            loadingWidth="15rem"
          />
        </PanelActionsWrapper>

        {/* Account Status */}
        <H4 margin="0 0 1rem" appearance="light" isLoading={viewLoading}>
          Account status
        </H4>
        <PanelActionsWrapper>
          <IconWithText
            icon={isActive ? "checkbox-marked-circle-outline" : "circle-off-outline"}
            appearance="light"
            content={isActive ? "Active" : "Inactive"}
            fontSize="1.4rem"
            margin="0"
            iconColour={isActive ? colours.green : colours.orange}
            isLoading={viewLoading}
            loadingWidth="15rem"
          />
          {!viewLoading && !isActive && (
            <PanelActionButton
              appearance="whiteGhost"
              trailingIcon="send"
              isLoading={resendConfirmationLoading}
              isDisabled={viewLoading}
              name="resend_confirmation"
              onClick={resendConfirmation}
            >
              Resend activation
            </PanelActionButton>
          )}
        </PanelActionsWrapper>

        {/* Simulation Mode */}
        {get(viewData, "parent.type", null) === "ORGANISATION" && (
          <SimulationMode
            isInSimulationMode={get(
              find(get(viewData, "settings", []), ["key", "SIMULATION_MODE"]),
              "data.value",
              false
            )}
          />
        )}

        {/* Delete/ToggleStanding Actions */}
        <AuthWrapper roles={["SYS_ADMIN", "ORG_ADMIN", "NETWORK_ADMIN", "PROVIDER_ADMIN"]}>
          <DeleteUser />
        </AuthWrapper>
        <ToggleStanding isLocked={get(viewData, "locked", false)} />
      </PanelBody>
    </>
  )
}

export default UserView
