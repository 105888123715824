import React from "react"
import PropTypes from "prop-types"
import { get, isEmpty, capitalize, find, map } from "lodash"
import { useGet, useDelete, usePost, usePatch, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../medicalHistory.context"

// Helpers
import reducer from "./medicalHistory.reducer"
import { JourneyContext } from "../../../../../../../journey.context"
import { getName } from "../../../../../../../../../Helpers"

const MedicalHistoryProvider = ({ children }) => {
  const { data, updateJourneyAuditData, formik } = React.useContext(JourneyContext)
  const t = useTranslations()
  const formikValue = get(formik, "values", "")
  const lastThreeYearsInjured = get(formikValue, "last_three_years_injured", false)
  const axaQuestionsValue = get(formik, "values.axa_questions", "")
  const [
    {
      applicants,
      notes,
      notesModal,
      onCancelEditForm,
      saveChangesForNoteModal,
      deleteNoteModal,
      onEditHandleSubmit,
      isEdit,
      isDelete,
      selectedNote
    },
    dispatch
  ] = React.useReducer(reducer, {
    applicants: [],
    total: null,
    notes: [],
    notesModal: false,
    onCancelEditForm: null,
    saveChangesForNoteModal: false,
    deleteNoteModal: false,
    onEditHandleSubmit: null,
    isEdit: false,
    isDelete: false,
    selectedNote: null
  })

  // Index applicants
  const { loading: applicantsLoading, error: applicantsError } = useGet({
    endpoint: "/journeys/:slug/applicants",
    params: {
      slug: get(data, "journey.slug")
    },
    query: {
      page: 1,
      limit: 100, // number of applicants will never reach 100
      with: ["journeyData"]
    },
    onCompleted: res =>
      dispatch({
        type: "FETCH_APPLICANTS_COMPLETE",
        total: get(res, "pagination.totalItems"),
        data: get(res, "data", [])
      })
  })

  // Index notes
  const { loading, error, refetch } = useGet({
    endpoint: "/journeys/:slug/medical-notes",
    params: {
      slug: get(data, "journey.slug")
    },
    query: {
      with: ["journeyData"]
    },
    onCompleted: res => {
      const notesData = get(res, "data", [])
      dispatch({ type: "FETCH_NOTES_COMPLETE", notes: notesData })
    }
  })

  // Add note
  const [addNote, { loading: addNoteLoading, error: addNoteError }] = usePost({
    endpoint: "/journeys/:slug/medical-notes",
    params: {
      slug: get(data, "journey.slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("MEDICAL_NOTE_CREATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "notesModal", value: false })
      refetch()
    }
  })

  // Update note
  const [updateNote, { loading: updateNoteLoading, error: updateNoteError }] = usePatch({
    endpoint: "/journeys/:slug/medical-notes/:medicalNote",
    onCompleted: () => {
      addAlert({
        message: t("MEDICAL_NOTE_UPDATE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      refetch()
    }
  })

  // Delete note
  const [deleteNote, { loading: deleteNoteLoading, error: deleteNoteError }] = useDelete({
    endpoint: "/journeys/:slug/medical-notes/:medicalNote",
    onCompleted: () => {
      addAlert({
        message: t("MEDICAL_NOTE_DELETE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "deleteNoteModal", value: false })
      dispatch({ type: "UPDATE_VALUE", key: "selectedNote", value: null })
      refetch()
    }
  })

  React.useEffect(() => {
    const auditData = []
    const vitalityQuestions = [
      { Cancer: "last_three_years_cancer" },
      { "Heart Conditions": "last_three_years_heart_conditions" },
      { Prostate: "last_three_years_prostate" },
      { Diabetes: "last_three_years_diabetes" },
      { "Mental Health Issues": "last_three_years_mental_health_issues" },
      { "Back Pain": "last_three_years_back_pain" },
      { Arthritis: "last_three_years_arthritis" },
      {
        "Admission to a hospital, clinic or other healthcare facility, whether NHS or private, for two nights or more for any condition not listed above":
          "last_three_years_admission"
      },
      { "Any other condition not declared above": "last_three_years_other" }
    ]
    const axaQuestions = [
      {
        "Heart condition or heart problem": "last_5_years_heart_condition_or_heart_problem"
      },
      { Stroke: "last_5_years_stroke" },
      { Cancer: "last_5_years_cancer" },
      { Diabetes: "last_5_years_diabetes" },
      { "Mental illness": "last_5_years_mental_illness" }
    ]
    const removeVitalityQuestions = vitalityQuestions.map(question => ({
      name: Object.keys(question)[0]
    }))

    const newAuditVitalityQuestions = vitalityQuestions.map(question => ({
      name: Object.keys(question)[0],
      value: get(formikValue, question[Object.keys(question)[0]])
    }))

    const newAuditAxaQuestions = map(axaQuestionsValue, (applicant, key) => {
      const applicantId = key.split("_")[1]
      const matchingApplicant = find(applicants, { id: parseInt(applicantId) })
      const applicantName = getName({ data: matchingApplicant })
      const applicantType = capitalize(get(matchingApplicant, "type", "-"))

      const translatedAxaQuestions = axaQuestions.map(question => ({
        name: Object.keys(question)[0],
        value: get(applicant, question[Object.keys(question)[0]]) ? "Yes" : "No"
      }))
      return {
        name: `${applicantName} (${applicantType})`,
        value: {
          ...translatedAxaQuestions
        }
      }
    })

    applicants.forEach(applicant => {
      const applicantNotes = notes.filter(note => applicant.id === note.journey_applicant_id)
      if (!isEmpty(applicantNotes)) {
        const applicantName = getName({ data: applicant })
        const applicantType = capitalize(get(applicant, "type", "-"))

        auditData.push({
          name: `Medical Notes ${applicantName} (${applicantType})`,
          value: (() => {
            let tempArrNote = []

            tempArrNote.push({
              name: "Person to whom this note refers",
              value: applicantName
            })
            applicantNotes.forEach(note => {
              const condition = get(note, "condition")
              const noteText = get(note, "note")

              tempArrNote = [
                ...tempArrNote,
                {
                  name: "Medical condition",
                  value: condition
                },
                {
                  name: "Note",
                  value: noteText
                }
              ]
            })

            return tempArrNote
          })()
        })
      }
    })
    updateJourneyAuditData([
      {
        mode: "remove",
        data: removeVitalityQuestions
      },
      {
        mode: "remove",
        data: [
          {
            name: "In the last three years, has your client or any other person to be insured on this plan"
          }
        ]
      },
      {
        mode: "remove",
        data: [
          {
            name: "Experienced symptoms, or Received any advice from healthcare professional, or Received treatment or have treatment planned or expected"
          }
        ]
      },
      {
        mode: "append",
        data: [
          {
            name: "In the last five years have you had or received treatment for",
            value: newAuditAxaQuestions
          }
        ]
      },
      {
        mode: "append",
        data: [
          {
            name: "In the last three years, has your client or any other person to be insured on this plan: Experienced symptoms; Received any advice from a healthcare professional; Received treatment or have treatment planned or expected for any physical, mental health, psychiatric or behavioural problems?",
            value: lastThreeYearsInjured ? "Yes" : "No"
          }
        ]
      },
      lastThreeYearsInjured && {
        mode: "append",
        data: [
          {
            name: "When did you or any other person to be insured last experience symptoms, or receive advice from a healthcare professional or receive treatment relating to the following 9 conditions",
            value: newAuditVitalityQuestions
          }
        ]
      },
      {
        mode: "append",
        data: auditData
      }
    ])
    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicants, notes, formikValue])

  return (
    <Provider
      value={{
        data,
        applicants,
        notes,
        notesRefetch: refetch,
        notesLoading: loading,
        applicantsLoading,
        addNoteLoading,
        updateNoteLoading,
        deleteNoteLoading,
        addNote,
        notesModal,
        isEdit,
        isDelete,
        selectedNote,
        setEdit: val => dispatch({ type: "UPDATE_VALUE", key: "isEdit", value: val }),
        setDelete: val => dispatch({ type: "UPDATE_VALUE", key: "isDelete", value: val }),
        setSelectedNote: val => dispatch({ type: "UPDATE_VALUE", key: "selectedNote", value: val }),
        setNotesModal: val => dispatch({ type: "UPDATE_VALUE", key: "notesModal", value: val }),
        onCancelEditForm,
        setOnCancelEditForm: val =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "onCancelEditForm",
            value: val
          }),
        saveChangesForNoteModal,
        setSaveChangesForNoteModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "saveChangesForNoteModal", value: val }),
        deleteNoteModal,
        setDeleteNoteModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "deleteNoteModal", value: val }),
        onDeleteNote: () => {
          deleteNote({
            params: {
              slug: get(data, "journey.slug", ""),
              medicalNote: get(selectedNote, "slug")
            }
          })
        },
        onUpdateNote: (noteSlug, body) => {
          updateNote({
            params: {
              slug: get(data, "journey.slug", ""),
              medicalNote: noteSlug
            },
            body
          })
        },
        onEditHandleSubmit,
        setOnEditHandleSubmit: val =>
          dispatch({ type: "UPDATE_VALUE", key: "onEditHandleSubmit", value: val })
      }}
    >
      {children}
      <ApiError
        error={error || applicantsError || addNoteError || updateNoteError || deleteNoteError}
      />
    </Provider>
  )
}

MedicalHistoryProvider.propTypes = {
  children: PropTypes.any
}

export default MedicalHistoryProvider
