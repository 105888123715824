import React, { useContext } from "react"
import { Redirect } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { get } from "lodash"
import { Gate, AuthContext } from "@4cplatform/elements/Auth"

const MaintenanceLogin = () => {
  const { status, statusLoading, routeConfigs } = useContext(AuthContext)

  if (statusLoading) return null

  if (status && status !== "MAINTENANCE_MODE")
    return <Redirect to={get(routeConfigs, "login", "/login")} />

  return (
    <>
      <Helmet>
        <title>Maintenance login</title>
      </Helmet>
      <Gate type="login" />
    </>
  )
}

export default MaintenanceLogin
