/* eslint-disable react/display-name */
import React from "react"
import PropTypes from "prop-types"

// Components
import { ProviderLogo } from "./quotationSummaryTable.styles"

function QuoteProviderLogo({ src, alt, size }) {
  return <ProviderLogo key={src} src={src} alt={alt} size={size} />
}

QuoteProviderLogo.defaultProps = {
  src: null,
  alt: null,
  size: "6rem"
}

QuoteProviderLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string
}

export default QuoteProviderLogo
