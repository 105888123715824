import { get } from "lodash"
import { formatLabelForTestID } from "@4cplatform/elements/Helpers"

/**
 * This function evaluates the props passed in and returns the 'appearance' prop for Link components in the Tab
 * For the default Tabs component, anchor tags are used and the active/inactive appearance props are irrelevant.
 * @param {type, isActive, activeAppearance, inactiveAppearance} param0 The type prop on the Tabs component, the isActive prop on the Tab, and the active and inactive appearances for Buttons
 */
export const getLinkAppearance = ({ type, isActive, activeAppearance, inactiveAppearance }) => {
  if (type === "buttons") {
    return isActive ? activeAppearance : inactiveAppearance
  }
  return isActive ? "active" : "inactive"
}

export const getDefaultTab = ({ defaultIndex, hasQueryControls, values, name, children }) => {
  const defaultTabValue = formatLabelForTestID(get(children, `[${defaultIndex}].props.header`))

  // if hasQueryControls
  if (hasQueryControls) return get(values, name, defaultTabValue)

  // if tab is present for defaultIndex
  if (get(children, `[${defaultIndex}].props.isPresent`, false)) return defaultTabValue

  // else find the first tab that is present
  const firstPresentTab = children.find(_ => _.props?.isPresent)
  return formatLabelForTestID(get(firstPresentTab, "props.header"))
}
