import React, { useState } from "react"
import PropTypes from "prop-types"

// Components
import { QuickQuoteSettingsWrapper } from "./quickQuoteSettings.styles"
import QuickQuoteLevel from "./quickQuoteSettings.level"

const levels = ["basic", "standard", "comprehensive"]

const QuickQuoteSettings = ({ formik, quickQuoteData, isLoading, handleSubmit }) => {
  const [isEdit, setIsEdit] = useState({
    basic: false,
    standard: false,
    comprehensive: false
  })

  return (
    <QuickQuoteSettingsWrapper>
      {levels.map(level => (
        <QuickQuoteLevel
          key={level}
          level={level}
          formik={formik}
          isLoading={isLoading}
          levelEditStatus={isEdit}
          quickQuoteData={quickQuoteData}
          handleEdit={() => {
            setIsEdit(current => ({ ...current, [level]: true }))
          }}
          handleSubmit={() => {
            if (handleSubmit) handleSubmit()
            setIsEdit(current => ({ ...current, [level]: false }))
          }}
          handleCancel={() => {
            setIsEdit(current => ({ ...current, [level]: false }))
          }}
        />
      ))}
    </QuickQuoteSettingsWrapper>
  )
}

QuickQuoteSettings.propTypes = {
  formik: PropTypes.object.isRequired,
  quickQuoteData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func
}

export default QuickQuoteSettings
