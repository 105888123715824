import React, { useContext } from "react"

// Helpers
import { PageContext } from "@4cplatform/elements/Organisms"

// Components
import { FlyOutPanel } from "@4cplatform/elements/Molecules"
import QuoteComparisonConfigure from "./quoteComparison.panel.body"

const QuoteComparisonPanel = () => {
  const { setPanelStatus } = useContext(PageContext)

  return (
    <FlyOutPanel
      wideBody={() => <QuoteComparisonConfigure />}
      name="configure_quotes_panel"
      onClose={() => setPanelStatus("closed")}
    />
  )
}

export default QuoteComparisonPanel
