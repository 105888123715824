import { array, number, object } from "yup"

export const config = () => ({
  title: "Quote comparison",
  subtitle:
    "Compare prices from all eligible providers based on the global options available and then select which ones you would like to refine and convert into quotes on the following page.",
  sections: [
    {
      key: "quote_comparison",
      components: [
        {
          key: "global_options",
          initialValue: {},
          validationSchema: object().required("MISSING_REQUIRED_FIELD"),
          label: "Global options"
        },
        {
          key: "selected_quotes",
          initialValue: [],
          validationSchema: array(number().positive()).min(1, "MIN_LENGTH_NOT_MET"),
          label: "Selected quotes"
        }
      ]
    }
  ]
})
