import { boolean } from "yup"
import { get } from "lodash"

import { Toggle } from "@4cplatform/elements/Forms"
import { H4, P } from "@4cplatform/elements/Typography"
import { ComplianceNote, RenderHTML } from "@4cplatform/elements/Molecules"
import Audit from "./Audit"

export const config = data => {
  const providerKey = get(data, "journey.selected_quote.provider.provider_key")
  const lengthOfCoolingOffPeriod = providerKey === "AVIVA" || providerKey === "AXA" ? "14" : "30"

  return {
    title: "Policy exclusions",
    sections: [
      {
        key: "section_1",
        components: [
          {
            component: Audit,
            skipDataMap: true
          },
          {
            key: "instruction_text_prompt",
            component: ComplianceNote,
            componentProps: {
              children: (
                <>
                  <H4 margin="0 0 1rem">Compliance note</H4>
                  <P margin="0">
                    Confirm below that the client understands the policy exclusions and policy
                    information. You can cover all of these by reading the following script or
                    alternatively you can enter your own script within your account settings.
                  </P>
                </>
              ),
              type: "error"
            },
            skipDataMap: true
          },
          ...[
            ...(get(data, "payload.journey_settings.exclusion_text") === undefined
              ? [
                  {
                    key: "paragraph_1",
                    component: P,
                    componentProps: {
                      children:
                        "I have gone over the main features and benefits of the policy and the full details will be provided within the literature that the insurer sends you shortly. Please ensure you read through the literature to ensure you understand exactly what your policy does and does not cover and make sure you query any of the information you are unsure of. If there are any changes to your personal information or your medical history between now and the start date of the policy then you must inform us as this may impact the policy. Whilst the literature covers all the benefits and exclusions of the policy, for clarity, I am going to make you aware of some of the general exclusions that are not covered by any medical insurance policy"
                    },
                    skipDataMap: true
                  },
                  {
                    key: "paragraph_1_1",
                    component: P,
                    componentProps: {
                      margin: "0rem 0rem 2rem",
                      children: (
                        <strong>
                          Accident and Emergency (you should still dial 999 in the event of an
                          emergency), War Riots & Terrorism, HIV or Aids, Injuries sustained while
                          participating in professional sports (paid for or sponsored), Cosmetic
                          treatment, pregnancy and childbirth or any chronic conditions.
                        </strong>
                      )
                    },
                    skipDataMap: true
                  }
                ]
              : [
                  {
                    key: "custom_exclusion_text",
                    component: RenderHTML,
                    componentProps: {
                      content: get(data, "payload.journey_settings.exclusion_text")
                    },
                    skipDataMap: true
                  }
                ])
          ],
          {
            key: "paragraph_3",
            component: P,
            componentProps: {
              margin:
                get(data, "payload.journey_settings.exclusion_text") !== undefined
                  ? "2rem 0"
                  : "0 0 2rem",
              children:
                "Please confirm your client's understanding by answering yes to the policy exclusions below. If the client would like more information on any of these general exclusions, the exact terms can be found by clicking on the exclusion."
            },
            skipDataMap: true
          }
        ]
      },
      {
        key: "section_2",
        components: [
          {
            key: "limit_product_disclosures",
            initialValue: get(data, "page.data.limit_product_disclosures", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "Limited Product Disclosures",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "Limited Product Disclosures",
              helperText: `You need to make the customer aware that you have covered off the main features and benefits of the policy but the full literature is provided. <br /><br />'I have gone over the main features and benefits of the policy and the full details of the policy is provided within the literature. You have a ${lengthOfCoolingOffPeriod} day cooling off period to make any amendments to the policy and this starts from the date you receive your policy documents. Please ensure you read through the literature to ensure you understand exactly what your policy does and does not cover and make sure you query any of the information you are unsure of. Whilst the literature covers all the benefits and exclusions of the policy I am going to make you aware of some of the general exclusions.'`,
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          },
          {
            key: "accident_and_emergency",
            initialValue: get(data, "page.data.accident_and_emergency", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "Accident and Emergency",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "Accident and Emergency",
              helperText:
                "In an A&E situation you must continue to call 999. The policy does not provide cover for this situation and A&E are best equipped to help you in this scenario.",
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          },
          {
            key: "war_riots_and_terrorism",
            initialValue: get(data, "page.data.war_riots_and_terrorism", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "War, riots and terrorism",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "War, riots and terrorism",
              helperText:
                "No cover is provided for treatment required as a direct or indirect result of war, military, paramilitary or terrorist activity.",
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          },
          {
            key: "professional_sports",
            initialValue: get(data, "page.data.professional_sports", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "Professional Sports",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "Professional Sports",
              helperText:
                "No insurer will provide cover for any injury that occurs directly for or as a result of participating in a paid or funded sport.",
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          },
          {
            key: "hiv_aids_and_addiction",
            initialValue: get(data, "page.data.hiv_aids_and_addiction", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "HIV/Aids and addictions",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "HIV/Aids and addictions",
              helperText:
                "No cover is provided for treatment of HIV/Aids. Also there is no cover for treatment of any addiction or substance abuse eg. Related to alcohol or drugs. Any condition directly or indirectly related to the addiction/abuse is also not covered.",
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          },
          {
            key: "chronic_conditions",
            initialValue: get(data, "page.data.chronic_conditions", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "Chronic conditions",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "Chronic conditions",
              helperText:
                "A Chronic condition is a condition which is controlled with medication however has no known cure. Typical examples of these would include asthma, psoriasis and diabetes. If you suffer from a chronic condition prior to the start of the policy then this will never be covered. If you are diagnosed with a chronic condition after the policy starts, the policy may cover acute flare-ups of the condition however any day to day medication or monitoring will not be.",
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          },
          {
            key: "cosmetic_treatment",
            initialValue: get(data, "page.data.cosmetic_treatment", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "Cosmetic treatment",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "Cosmetic treatment",
              helperText:
                "Cosmetic surgery intended to change your appearance (tummy tuck or facelift etc.) will not be covered whether it be for psychological or medical reasons or not. However, the policy will cover a surgical procedure to restore your appearance if it is medically needed after eligible Cancer treatment or after an accident.",
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          },
          {
            key: "pregnancy_and_child_birth",
            initialValue: get(data, "page.data.pregnancy_and_child_birth", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "Pregnancy and childbirth",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "Pregnancy and childbirth",
              helperText:
                "<strong>Pregnancy and childbirth</strong> If the customer has reduced / Nil Out-Patient cover then there is no cover for pregnancy or childbirth under any circumstances. If the customer has full Out-Patient cover then pregnancy and childbirth is not covered, however, some specific conditions will be. The appropriate statement will depend on which level of cover you have sold:<br/><br/><strong>Reduced / Nil Out-Patient</strong> Pregnancy and childbirth is not covered in any form under this policy.<br/><br/><strong>Full Out-patient -</strong> Pregnancy and childbirth is not covered however there are some specific complications which will be covered. There is usually a 10 month qualifying period in respect of this.",
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          },
          {
            key: "change_of_details",
            initialValue: get(data, "page.data.change_of_details", false),
            validationSchema: boolean()
              .oneOf(
                [true],
                "It is not possible to continue with the sales journey if the client has not confirmed that they understand and accept the policy exclusions and policy information"
              )
              .required("MISSING_REQUIRED_FIELD"),
            label: "Change of details",
            component: Toggle,
            componentProps: {
              errorPrefix: "none",
              helperTitle: "Change of details",
              helperText:
                "If there are any changes to your personal information or your medical history between now and the start date of the policy then you must inform us as this may impact the policy.",
              helperPosition: "left",
              isHorizontal: true,
              isRequired: true
            }
          }
        ]
      }
    ]
  }
}
