import styled from "styled-components"
import { get } from "lodash"
import { CollapseBody } from "@4cplatform/elements/Molecules/Collapse/collapse.styles"

export const Divider = styled.span`
  display: flex;
  padding: 0 1rem;
  height: 0.1rem;
  width: 100%;
  margin: 1rem 0 1.5rem;
  background: ${({ theme }) => get(theme, "faintGrey")};
`

export const ResultsContainer = styled.div`
  height: ${({ height }) => height};
  overflow-y: auto;
  padding: 2rem;
  transition: height 0.2s;

  ${CollapseBody} {
    overflow: auto;
  }
`
export const CloseButton = styled.div`
  display: flex;
  padding: 0 2rem 0 3rem;
  height: 8rem;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #efefef;
`

export const LoadingLine = styled.div`
  margin-bottom: 2rem;
`
