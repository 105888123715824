import React, { useContext, useEffect } from "react"
import moment from "moment"
import { get, capitalize, isEmpty } from "lodash"
import { Table } from "@4cplatform/elements/Organisms"
import { Select } from "@4cplatform/elements/Forms"
import { SmallText } from "@4cplatform/elements/Typography"
import { colours } from "@4cplatform/elements/Helpers"

// Helpers
import { getName } from "../../../../../../../../Helpers"
import { JourneyContext } from "../../../../../../journey.context"

// Components
import { SelectWrapper, StyledLabel } from "./claimsHistory.styles"

const yearsCoveredArray = [
  "0 years",
  "1 year",
  "2 years",
  "3 years",
  "4 years",
  "5 years",
  "6 years",
  "7 years",
  "8 years",
  "9 years",
  "10 years",
  "11 years",
  "12 years",
  "13 years",
  "14 years",
  "15 years"
]

const ClaimsHistoryTable = () => {
  const { data, isLoading, formik, updateJourneyAuditData } = useContext(JourneyContext)

  const applicants = get(data, "journey.applicants", [])
  const isLocked = get(data, "journey.locked", false)

  useEffect(() => {
    if (!isEmpty(formik?.values)) {
      updateJourneyAuditData([
        {
          mode: "replace",
          data: [
            ...(get(data, "page.conditionals.has_access_to_axa_agency_codes")
              ? [
                  {
                    name: "Do you or anyone to be insured on this policy have any treatment, consultations, investigations or diagnostic tests, planned or pending?",
                    value: get(formik, "values.axa_questions.axa_anyone_planned_or_pending", false)
                      ? "Yes"
                      : "No"
                  },
                  {
                    name: "Have you or anyone to be insured on this policy had treatment in hospital or consulted a specialist in the last 12 months?",
                    value: get(
                      formik,
                      "values.axa_questions.axa_anyone_received_treatment_or_consultation_in_last_12_months",
                      false
                    )
                      ? "Yes"
                      : "No"
                  }
                ]
              : []),
            {
              name: "Member Claims History",
              value: (applicants || []).map(row => {
                const applicantName = getName({
                  data: row
                })
                const applicantType = capitalize(get(row, "type", "-"))

                return {
                  name: `${applicantName} (${applicantType})`,
                  value: [
                    {
                      name: "Name",
                      value: getName({
                        data: row
                      })
                    },
                    {
                      name: "Years covered",
                      value: (() => {
                        const applicantId = get(row, "id", false)
                        const formikApplicantIndex = applicants.findIndex(a => a.id === applicantId)
                        const fieldName = `applicants.${formikApplicantIndex}.years_covered`

                        return yearsCoveredArray[get(formik, `values.${fieldName}`, "")]
                      })()
                    },
                    {
                      name: "Claims last 5 years",
                      value: (() => {
                        const applicantId = get(row, "id", false)
                        const formikApplicantIndex = applicants.findIndex(a => a.id === applicantId)
                        const fieldName = `applicants.${formikApplicantIndex}.claims_last_five_years`

                        return {
                          None: "None",
                          "1 Claim": "1 claim",
                          "2 Claims": "2 claims",
                          "3+ Claims": "3+ claims"
                        }[get(formik, `values.${fieldName}`, "None")]
                      })()
                    },
                    {
                      name: "Date of last claim",
                      value: (() => {
                        const applicantId = get(row, "id", false)
                        const formikApplicantIndex = applicants.findIndex(a => a.id === applicantId)
                        const fieldName = `applicants.${formikApplicantIndex}.date_of_last_claim`

                        return {
                          never: "Never",
                          last_12_months: "Within 12 months",
                          last_2_years: "Within 2 years",
                          last_3_years: "Within 3 years",
                          last_4_years: "Within 4 years",
                          last_5_years: "Within 5 years",
                          last_5_plus_years: "5+ years ago"
                        }[get(formik, `values.${fieldName}`, "never")]
                      })()
                    }
                  ]
                }
              })
            }
          ]
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicants, formik?.values])

  const handleChange = (name, value) => {
    if (name.includes("years_covered") && value > 0) {
      formik.setFieldValue(name.replace("years_covered", "claims_last_five_years"), "")
      formik.setFieldValue(name.replace("years_covered", "date_of_last_claim"), "never")
      formik.setFieldValue(name, value)
    } else if (name.includes("years_covered") && value === 0) {
      formik.setFieldValue(name.replace("years_covered", "claims_last_five_years"), "None")
      formik.setFieldValue(name.replace("years_covered", "date_of_last_claim"), "never")
      formik.setFieldValue(name, value)
    } else if (name.includes("claims_last_five_years")) {
      if (value) {
        if (value === "None") {
          formik.setFieldValue(
            name.replace("claims_last_five_years", "date_of_last_claim"),
            "never"
          )
          formik.setFieldValue(name, value)
          return
        }
        if (value !== "None") {
          formik.setFieldValue(name.replace("claims_last_five_years", "date_of_last_claim"), "")
          formik.setFieldValue(name, value)
        }
      }
    } else if (value && value !== -1) {
      formik.setFieldValue(name, value)
    }
  }

  return (
    <Table
      data={applicants}
      name="applicants"
      isLoading={isLoading}
      columns={[
        [
          {
            label: "Name",
            data: "first_name",
            minWidth: "120px",
            render: row => getName({ data: get(row, "data") })
          },
          {
            minWidth: "120px",
            data: "date_of_birth",
            render: row => {
              const dob = moment.utc(get(row, "data.date_of_birth"), "YYYY-MM-DD HH:mm").local()
              const age = moment().diff(dob, "years")
              return (
                <SmallText margin="0">
                  Age {age}
                  &nbsp;&nbsp;
                  {capitalize(get(row, "data.type", "-"))}
                </SmallText>
              )
            }
          }
        ],
        {
          label: (
            <StyledLabel colours={colours}>
              Years covered <sup>&#42;</sup>
            </StyledLabel>
          ),
          minWidth: "120px",
          render: row => {
            const applicantId = get(row, "data.id", false)
            const formikApplicantIndex = applicants.findIndex(a => a.id === applicantId)
            const fieldName = `applicants.${formikApplicantIndex}.years_covered`
            const dob = moment.utc(get(row, "data.date_of_birth"), "YYYY-MM-DD HH:mm").local()
            const age = moment().diff(dob, "years")

            const error = get(formik, `errors.${fieldName}`)

            return (
              <SelectWrapper hasError={!isEmpty(error)}>
                <Select
                  name={fieldName}
                  onChange={val => handleChange(fieldName, yearsCoveredArray.indexOf(val))}
                  error={error}
                  value={yearsCoveredArray[get(formik, `values.${fieldName}`, "")]}
                  margin="0 2rem 0 0"
                  isDisabled={isLocked}
                  isRequired
                >
                  <option value="">Please select</option>
                  {yearsCoveredArray.slice(0, age + 1).map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </SelectWrapper>
            )
          }
        },
        {
          label: (
            <StyledLabel colours={colours}>
              Claims last 5 years <sup>&#42;</sup>
            </StyledLabel>
          ),
          minWidth: "120px",
          render: row => {
            const applicantId = get(row, "data.id", false)
            const formikApplicantIndex = applicants.findIndex(a => a.id === applicantId)
            const fieldName = `applicants.${formikApplicantIndex}.claims_last_five_years`

            const yearsCoveredValue = get(
              formik,
              `values.applicants.${formikApplicantIndex}.years_covered`,
              ""
            )

            const isRequired =
              !yearsCoveredValue ||
              yearsCoveredValue === yearsCoveredArray[0] ||
              yearsCoveredValue === -1

            const isDisabled = isRequired || isLocked

            const error = get(formik, `errors.${fieldName}`)

            return (
              <SelectWrapper hasError={!isEmpty(error)}>
                <Select
                  name={fieldName}
                  onChange={val => handleChange(fieldName, val)}
                  error={error}
                  value={get(formik, `values.${fieldName}`, "None")}
                  isDisabled={isDisabled}
                  isRequired={isRequired}
                  margin="0 2rem 0 0"
                >
                  <option value="">Please select</option>
                  <option value="None">None</option>
                  <option value="1 Claim">1 claim</option>
                  <option value="2 Claims">2 claims</option>
                  <option value="3+ Claims">3+ claims</option>
                </Select>
              </SelectWrapper>
            )
          }
        },
        {
          label: (
            <StyledLabel colours={colours}>
              Date of last claim <sup>&#42;</sup>
            </StyledLabel>
          ),
          data: "data.applicants.date_of_last_claim",
          minWidth: "120px",
          render: row => {
            const applicantId = get(row, "data.id", false)
            const formikApplicantIndex = applicants.findIndex(a => a.id === applicantId)
            const fieldName = `applicants.${formikApplicantIndex}.date_of_last_claim`

            const yearsCoveredValue = get(
              formik,
              `values.applicants.${formikApplicantIndex}.years_covered`,
              ""
            )
            const claimsLastFiveYearsValue = get(
              formik,
              `values.applicants.${formikApplicantIndex}.claims_last_five_years`,
              "None"
            )

            const isRequired =
              !yearsCoveredValue ||
              yearsCoveredValue === yearsCoveredArray[0] ||
              yearsCoveredValue === -1 ||
              claimsLastFiveYearsValue === "None" ||
              claimsLastFiveYearsValue === -1 ||
              claimsLastFiveYearsValue === "" ||
              isLocked

            const isDisabled = isRequired || isLocked

            const error = get(formik, `errors.${fieldName}`)

            return (
              <SelectWrapper hasError={!isEmpty(error)}>
                <Select
                  name={fieldName}
                  onChange={val => handleChange(fieldName, val)}
                  error={error}
                  value={get(formik, `values.${fieldName}`, "never")}
                  isDisabled={isDisabled}
                  isRequired={isRequired}
                  margin="0 2rem 0 0"
                >
                  <option value="">Please select</option>
                  {(claimsLastFiveYearsValue === "None" || claimsLastFiveYearsValue === "") && (
                    <option value="never">Never</option>
                  )}
                  {yearsCoveredValue >= 1 && (
                    <option value="last_12_months">Within 12 months</option>
                  )}
                  {yearsCoveredValue >= 2 && <option value="last_2_years">Within 2 years</option>}
                  {yearsCoveredValue >= 3 && <option value="last_3_years">Within 3 years</option>}
                  {yearsCoveredValue >= 4 && <option value="last_4_years">Within 4 years</option>}
                  {yearsCoveredValue >= 5 && <option value="last_5_years">Within 5 years</option>}
                  {yearsCoveredValue > 5 && <option value="last_5_plus_years">5+ years ago</option>}
                </Select>
              </SelectWrapper>
            )
          }
        }
      ]}
    />
  )
}

export default ClaimsHistoryTable
