import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { useGet, usePatch } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { PageContext } from "@4cplatform/elements/Organisms"
import { addAlert } from "@4cplatform/elements/Alerts"

// Components
import { Provider } from "../../../../../UI/Templates/AgencyCodes"

// Helpers
import { getOrderBy } from "../../../../../UI/Helpers"
import { preserveProvider } from "../../../../../UI/Templates/AgencyCodes/agencyCodes.helpers"
import reducer from "./codeRequests.reducer"

const AgencyCodeRequestsProvider = ({ isPending, children }) => {
  const { setPanelStatus } = React.useContext(PageContext)
  const t = useTranslations()

  // State
  const [
    {
      data,
      selectedRequest,
      sorting,
      page,
      perPage,
      total,
      acceptModal,
      declineModal,
      search,
      filter
    },
    dispatch
  ] = React.useReducer(reducer, {
    data: [],
    selectedRequest: null,
    sorting: { direction: "desc", dataKey: "created_at" },
    page: 1,
    perPage: 10,
    total: null,
    acceptModal: false,
    declineModal: false,
    search: "",
    filter: "search"
  })

  // Set the query search if search is truthy
  let querySearch = {}
  if (search) {
    querySearch = {
      [filter]: preserveProvider(search)
    }
  }

  // Index Agency Code Requests query
  const {
    loading: queryLoading,
    /* eslint-disable no-unused-vars */
    error: queryError,
    refetch: queryRefetch
  } = useGet({
    endpoint: "/agency-codes",
    query: {
      limit: perPage,
      order_by: getOrderBy(sorting),
      pending: "pending_request",
      page,
      ...querySearch
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("AGENCY_CODE_REQUESTS_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Accept Agency Code Request mutation
  const [onAccept, { loading: acceptLoading }] = usePatch({
    endpoint: "/agency-code-requests/:slug/accept",
    params: {
      slug: get(selectedRequest, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("AGENCY_CODE_REQUEST_ACCEPT_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      queryRefetch()
      setPanelStatus("closed")
      dispatch({ type: "REQUEST_ACCEPT" })
    },
    onError: ({ message }) => {
      addAlert({
        type: "error",
        message: t(message || "AGENCY_CODE_REQUEST_DECLINE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Decline Agency Code Request mutation
  const [onDecline, { loading: declineLoading }] = usePatch({
    endpoint: "/agency-code-requests/:slug/decline",
    params: {
      slug: get(selectedRequest, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        message: t("AGENCY_CODE_REQUEST_DECLINE_SUCCESS"),
        type: "success",
        dismissible: true,
        timeout: 5
      })
      queryRefetch()
      setPanelStatus("closed")
      dispatch({ type: "REQUEST_DECLINE" })
    },
    onError: ({ message }) => {
      addAlert({
        type: "error",
        message: t(message || "AGENCY_CODE_REQUEST_DECLINE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  const setSearch = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        selectedRequest,
        sorting,
        page,
        perPage,
        total,
        queryLoading,
        queryRefetch,
        onRequestSelect: row =>
          dispatch({ type: "UPDATE_VALUE", key: "selectedRequest", value: row }),
        onRequestDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedRequest", value: null })
        },
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        pagination: { total, page, perPage },
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        isPending,
        acceptModal,
        setAcceptModal: val => dispatch({ type: "UPDATE_VALUE", key: "acceptModal", value: val }),
        onAccept,
        acceptLoading,
        declineModal,
        setDeclineModal: val => dispatch({ type: "UPDATE_VALUE", key: "declineModal", value: val }),
        onDecline,
        declineLoading,
        setSearch,
        filter,
        setFilter: val => dispatch({ type: "UPDATE_VALUE", key: "filter", value: val })
      }}
    >
      {children}
    </Provider>
  )
}

AgencyCodeRequestsProvider.defaultProps = {
  isPending: false,
  children: null
}

AgencyCodeRequestsProvider.propTypes = {
  isPending: PropTypes.bool,
  children: PropTypes.any
}

export default AgencyCodeRequestsProvider
