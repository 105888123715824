import React, { useContext } from "react"
import { get } from "lodash"
import moment from "moment"
import { Table } from "@4cplatform/elements/Organisms"
import { Icon } from "@4cplatform/elements/Atoms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { SharedAgencyCodesContext } from "./sharedAgencyCodes.context"
import { getIconDetails } from "../agencyCodes.helpers"

// Components
import Actions from "./sharedAgencyCodes.actions"
import { StatusWrapper } from "../components/DealCodeAssignment/assignment.styles"

const MyAccountSharedAgencyCodes = () => {
  const {
    data,
    sharedAgencyCodesLoading,
    pagination,
    setPage,
    setPerPage,
    onSort,
    sorting,
    isPending
  } = useContext(SharedAgencyCodesContext)

  const t = useTranslations()

  const statusColumn = !isPending
    ? [
        {
          label: "Status",
          dataKey: "status",
          minWidth: "50px",
          // eslint-disable-next-line react/display-name
          render: row => {
            const statusIcon = getIconDetails(get(row, "data.status", null))
            const isShared = get(row, "data.shared_with_count", 0) > 0
            return (
              <StatusWrapper appearance={row.isOpen ? "light" : "dark"}>
                <Icon {...statusIcon} />
                {isShared && <Icon icon="account-multiple" colour={colours.blue} />}
              </StatusWrapper>
            )
          },
          sortable: true
        }
      ]
    : []
  return (
    <>
      <Actions />
      <Table
        data={data}
        isLoading={sharedAgencyCodesLoading}
        columns={[
          {
            label: "Product Type",
            dataKey: "product_type",
            minWidth: "60px",
            sortable: true
          },
          {
            label: "Provider",
            dataKey: "provider",
            minWidth: "60px",
            sortable: true,
            render: row => get(row, "data.provider.name")
          },
          {
            label: "Product",
            dataKey: "product",
            render: row => t(get(row, "data.product", "-")),
            minWidth: "60px",
            sortable: true
          },
          {
            label: "Agency code",
            dataKey: "agency_code",
            minWidth: "60px",
            sortable: true
          },
          ...statusColumn,
          {
            label: "Shared Date",
            dataKey: "shared_at",
            minWidth: "60px",
            render: row => {
              const activated = get(row, "data.shared_at", "")
              return activated
                ? moment.utc(activated, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          }
        ]}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        perPageOptions={{ max: 50, interval: 5 }}
        sorting={sorting}
      />
    </>
  )
}

export default MyAccountSharedAgencyCodes
