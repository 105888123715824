import { boolean, string, object, number } from "yup"
import { validNameRegex } from "../../Helpers"

export const createUserModel = object().shape({
  title: string().required("MISSING_REQUIRED_FIELD"),
  first_name: string()
    .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
    .required("MISSING_REQUIRED_FIELD"),
  middle_names: string().test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name)),
  last_name: string()
    .test("validNameTest", "INVALID_NAME", name => validNameRegex.test(name))
    .required("MISSING_REQUIRED_FIELD"),
  email: string().email().required("MISSING_REQUIRED_FIELD"),
  role: string().required("MISSING_REQUIRED_FIELD"),
  simulation_mode: boolean()
    .nullable()
    .when("role", {
      is: value => ["ORG_ADMIN", "SALES_ADVISER"].includes(value),
      then: schema => schema.required("MISSING_REQUIRED_FIELD"),
      otherwise: schema => schema.notRequired().nullable()
    }),
  parent_id: number()
    .nullable()
    .when("role", {
      is: value =>
        [
          "ORG_ADMIN",
          "SALES_ADVISER",
          "NETWORK_ADMIN",
          "NETWORK_MEMBER_ADMIN",
          "PROVIDER_ADMIN",
          "UNDERWRITER"
        ].includes(value),
      then: schema => schema.required("MISSING_REQUIRED_FIELD"),
      otherwise: schema => schema.notRequired()
    })
})
