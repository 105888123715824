import { capitalize, startCase } from "lodash"

export const resolveValueNames = name => {
  let output = name
  if (output.slice(0, 6) === "GLOBAL") {
    const [, , newName] = output.split("_")
    output = newName

    if (output === "GUIDED") {
      output += " Option"
    }
  }
  return startCase(capitalize(output))
}
