/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { get, isEmpty } from "lodash"
import { useGet, useDelete, usePatch, usePost, ApiError } from "@4cplatform/elements/Api"
import { PageContext } from "@4cplatform/elements/Organisms"
import { AuthContext } from "@4cplatform/elements/Auth"
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../../../UI/Templates/LeadAdmin"

// Helpers
import { getOrderBy } from "../../../UI/Helpers"
import reducer from "./leads.reducer"

const LeadsProvider = ({ children }) => {
  const { canAccess } = useContext(AuthContext)
  const { selfServiceData, setPanelStatus } = React.useContext(PageContext)

  const history = useHistory()
  const t = useTranslations()

  // State
  const [
    {
      page,
      perPage,
      search,
      selectedLead,
      sorting,
      noticeModal,
      deleteOpen,
      transferOpen,
      userVal,
      total,
      data,
      leadNotes,
      showDeleted,
      notesModal,
      dispositionModal,
      config,
      hasShowDeleted,
      notesTotal,
      notesPage,
      notesPerPage,
      totalPages,
      apiErrors
    },
    dispatch
  ] = React.useReducer(reducer, {
    page: 1,
    perPage: 5,
    search: "",
    selectedLead: null,
    sorting: { direction: "desc", dataKey: "created_at" },
    noticeModal: false,
    deleteOpen: false,
    transferOpen: false,
    userVal: "",
    total: null,
    data: [],
    leadNotes: [],
    showDeleted: false,
    notesModal: false,
    dispositionModal: false,
    config: {},
    hasShowDeleted: canAccess(["SYS_ADMIN", "SUPPORT_ADMIN", "ORG_ADMIN"]),
    notesTotal: null,
    notesPage: 1,
    notesPerPage: 10,
    totalPages: 0,
    apiErrors: {}
  })

  // Leads Config
  const { loading: configLoading, error: configError } = useGet({
    endpoint: "/lead-config",
    onCompleted: res => {
      const cfg = get(res, "data", {})

      dispatch({ type: "UPDATE_VALUE", key: "config", value: cfg })
    }
  })

  // Index leads
  const {
    loading: queryLoading,
    error: queryError,
    refetch: refetchQuery
  } = useGet({
    endpoint: "/leads",
    query: {
      name: search,
      page,
      limit: perPage,
      deleted: showDeleted === true ? 1 : 0,
      order_by: getOrderBy(sorting)
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])

      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("LEADS_INDEX_ERROR"),
        dismissible: true,
        timeout: 5
      })
    },
    skip: isEmpty(config)
  })

  // Get lead
  const {
    loading: selectLoading,
    error: selectError,
    refetch: refetchSelect
  } = useGet({
    endpoint: "/leads/:slug",
    params: {
      slug: get(selectedLead, "slug")
    },
    query: {
      with: ["organisation", "salesAgent", "client", "phoneNumbers", "notes", "address"],
      deleted: showDeleted === true ? 1 : 0
    },
    onCompleted: res => {
      const newData = get(res, "data", null)
      dispatch({ type: "UPDATE_VALUE", key: "selectedLead", value: newData })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("LEAD_GET_ERROR"),
        dismissible: true,
        timeout: 5
      })
    },
    skip: !get(selectedLead, "slug")
  })

  // Delete lead
  const [deleteLead, { loading: deleteLoading, error: deleteError }] = useDelete({
    endpoint: "/leads/:slug",
    params: {
      slug: get(selectedLead, "slug")
    },
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("LEAD_DELETE_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "deleteOpen", value: false })
      setPanelStatus("closed")
      refetchQuery()
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("LEAD_DELETE_ERROR"),
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "deleteOpen", value: false })
    }
  })

  // Update lead
  const [updateLead, { loading: updateLoading, error: updateError }] = usePatch({
    endpoint: "/leads/:slug",
    params: {
      slug: get(selectedLead, "slug")
    },
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("LEAD_UPDATE_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "apiErrors", value: {} })
      setPanelStatus("open")
      refetchSelect()
      refetchQuery()
    },
    onError: err => {
      if (isEmpty(err.validation)) {
        addAlert({
          type: "error",
          message: t("LEAD_UPDATE_ERROR"),
          dismissible: true,
          timeout: 5
        })
      } else {
        dispatch({ type: "UPDATE_VALUE", key: "apiErrors", value: err })
      }
    }
  })

  // Get lead notes
  const {
    loading: notesLoading,
    error: notesError,
    refetch: notesRefetch
  } = useGet({
    endpoint: "/leads/:slug/notes",
    params: {
      slug: get(selectedLead, "slug")
    },
    query: {
      page: notesPage,
      limit: notesPerPage
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      dispatch({ type: "UPDATE_VALUE", key: "leadNotes", value: get(res, "data", []) })
      dispatch({ type: "UPDATE_VALUE", key: "notesTotal", value: newTotal })
      dispatch({
        type: "UPDATE_VALUE",
        key: "totalPages",
        value: get(res, "pagination.totalPages", "1")
      })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("NOTES_INDEX_ERROR"),
        dismissible: true,
        timeout: 5
      })
    },
    skip: !get(selectedLead, "slug") || !!get(selectedLead, "deleted_at")
  })

  useEffect(() => {
    // ensures last page of notes shown by default
    if (!notesLoading && selectedLead !== null) {
      dispatch({ type: "UPDATE_VALUE", key: "notesPage", value: totalPages })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLead])

  // Create lead note
  const [createNote, { loading: noteCreateLoading, error: noteCreateError }] = usePost({
    endpoint: "/leads/:slug/notes",
    params: {
      slug: get(selectedLead, "slug")
    },
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("NOTE_ADD_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
      notesRefetch()
      dispatch({ type: "UPDATE_VALUE", key: "notesModal", value: false })
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("NOTE_ADD_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Update lead disposition
  const [updateDisposition, { loading: updateDispositionLoading, error: updateDispositionError }] =
    usePatch({
      endpoint: "/leads/:slug/update-disposition",
      params: {
        slug: get(selectedLead, "slug", "")
      },
      onCompleted: () => {
        addAlert({
          type: "success",
          message: t("LEAD_DISPOSITION_UPDATE_SUCCESS"),
          dismissible: true,
          timeout: 5
        })
        dispatch({ type: "UPDATE_VALUE", key: "dispositionModal", value: false })
        refetchSelect()
        refetchQuery()
      },
      onError: () => {
        addAlert({
          type: "error",
          message: t("LEAD_DISPOSITION_UPDATE_ERROR"),
          dismissible: true,
          timeout: 5
        })
      }
    })

  // Get organisation users
  const {
    data: users,
    loading: suggestionsLoading,
    error: suggestionsError
  } = useGet({
    endpoint: "/users-organisations",
    query: {
      search: userVal,
      limit: 5,
      deleted: false,
      parent_type: "ORGANISATION",
      parent_slug: [get(selectedLead, "organisation.slug", "")],
      include_current_user: true
    },
    skip: !userVal
  })

  // Transfer lead to user
  const [transfer, { loading: transferLoading, error: transferError }] = usePatch({
    endpoint: "/leads/:slug/transfer",
    params: {
      slug: get(selectedLead, "slug", "")
    },
    onCompleted: () => {
      addAlert({
        type: "success",
        message: t("LEAD_TRANSFER_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
      dispatch({ type: "UPDATE_VALUE", key: "transferOpen", value: false })
      setPanelStatus("open")
      refetchSelect()
      refetchQuery()
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("LEAD_TRANSFER_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Start journey from lead
  const [startJourney, { loading: startJourneyLoading, error: startJourneyError }] = usePost({
    endpoint: "/leads/:slug/journeys",
    params: {
      slug: get(selectedLead, "slug")
    },
    onCompleted: res => {
      addAlert({
        type: "success",
        message: t("JOURNEY_START_SUCCESS"),
        dismissible: true,
        timeout: 5
      })
      setPanelStatus("closed")
      history.push(
        get(res, "data.page.route", `/journeys/${get(res, "data.journey.slug")}/consent`)
      )
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("JOURNEY_START_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })
  const filterError = useCallback(error => {
    const emailError = get(error, "validation.email_address", [])
    if (!emailError) {
      return error
    }
  }, [])

  const setSearch = useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "search", value: val })
      dispatch({ type: "UPDATE_VALUE", key: "page", value: 1 })
    },
    [dispatch]
  )

  return (
    <Provider
      value={{
        data,
        selectedLead,
        onLeadSelect: row => dispatch({ type: "UPDATE_VALUE", key: "selectedLead", value: row }),
        onLeadDeselect: () => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedLead", value: undefined })
          dispatch({ type: "UPDATE_VALUE", key: "apiErrors", value: {} })
        },
        search,
        setSearch,
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        notesPagination: { total: notesTotal, page: notesPage, perPage: notesPerPage },
        setNotesPage: val => dispatch({ type: "UPDATE_VALUE", key: "notesPage", value: val }),
        notesPage,
        pagination: { total, page, perPage },
        showDeleted,
        setShowDeleted: val => dispatch({ type: "UPDATE_VALUE", key: "showDeleted", value: val }),
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        sorting,
        queryLoading: queryLoading || configLoading,
        noticeModal,
        setNoticeModal: val => dispatch({ type: "UPDATE_VALUE", key: "noticeModal", value: val }),
        deleteOpen,
        setDeleteOpen: val => dispatch({ type: "UPDATE_VALUE", key: "deleteOpen", value: val }),
        onDeleteLead: () => deleteLead(),
        transferOpen,
        setTransferOpen: val => dispatch({ type: "UPDATE_VALUE", key: "transferOpen", value: val }),
        userVal,
        setUserVal: val => dispatch({ type: "UPDATE_VALUE", key: "userVal", value: val }),
        onTransferLead: body => {
          transfer({ body })
        },
        transferLoading,
        selectLoading,
        deleteLoading,
        onUpdateLeadSubmit: body => updateLead(body),
        updateLoading,
        notesLoading,
        leadNotes,
        notesModal,
        setNotesModal: val => dispatch({ type: "UPDATE_VALUE", key: "notesModal", value: val }),
        onAddNote: val =>
          createNote({
            body: {
              body: val,
              type: "GENERAL"
            }
          }),
        noteCreateLoading,
        dispositionModal,
        setDispositionModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "dispositionModal", value: val }),
        onSubmitDisposition: body => updateDisposition({ body }),
        updateDispositionLoading,
        configLoading,
        config,
        users,
        suggestionsLoading,
        onStartJourney: () =>
          startJourney({
            body: {
              product_type: get(selectedLead, "type", "PMI"),
              user_id: selfServiceData.id
            }
          }),
        startJourneyLoading,
        hasShowDeleted,
        totalPages,
        apiErrors
      }}
    >
      {children}
      <ApiError
        error={
          queryError ||
          selectError ||
          deleteError ||
          filterError(updateError) ||
          notesError ||
          noteCreateError ||
          updateDispositionError ||
          configError ||
          suggestionsError ||
          transferError ||
          startJourneyError
        }
      />
    </Provider>
  )
}

LeadsProvider.propTypes = {
  children: PropTypes.any
}

export default LeadsProvider
