/* eslint-disable camelcase */
import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { get } from "lodash"
import { Icon } from "@4cplatform/elements/Atoms"
import { P } from "@4cplatform/elements/Typography"
import { Table, PageContext } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { AgencyCodesContext } from "../agencyCodes.context"
import { getIconDetails, getOrganisationAccess } from "../agencyCodes.helpers"

// Components
import Actions from "./networkAgencyCodes.actions"
import CheckedItemModal from "../components/CheckedItemModal"
import { StatusWrapper } from "../components/DealCodeAssignment/assignment.styles"

const NetworkAgencyCodes = () => {
  const {
    data,
    queryLoading,
    onAgencyCodeSelect,
    onAgencyCodeDeselect,
    pagination,
    setPage,
    setPerPage,
    onSort,
    sorting,
    isSharedWith,
    revokeModal,
    setRevoke,
    revokeLoading,
    onRevokeShares,
    sharedOrganisations
  } = React.useContext(AgencyCodesContext)
  const {
    setPanelStatus,
    panelStatusControls: { panelStatus }
  } = React.useContext(PageContext)

  const t = useTranslations()

  const sharedWithColumns = isSharedWith
    ? [
        {
          label: "Commission Rate",
          dataKey: "primary_commission_rate",
          minWidth: "50px",
          render: row =>
            get(row, "data.primary_commission_rate")
              ? `${get(row, "data.primary_commission_rate")}%`
              : "0%",
          sortable: true
        },
        {
          label: "Organisation(s)",
          dataKey: "shared_with_count",
          minWidth: "100px",
          sortable: true,
          render: row => getOrganisationAccess(get(row, "data"))
        }
      ]
    : []

  return (
    <>
      <Actions />
      <Table
        type="contained"
        data={data}
        isLoading={queryLoading}
        name="network_agency_codes"
        columns={[
          {
            label: "Product Type",
            dataKey: "product_type",
            minWidth: "70px",
            sortable: true
          },
          {
            label: "Provider",
            dataKey: "provider",
            minWidth: "180px",
            sortable: true,
            render: row => get(row, "data.provider.name")
          },
          {
            label: "Product",
            dataKey: "product",
            render: row => t(get(row, "data.product", "-")),
            minWidth: "180px",
            sortable: true
          },
          {
            label: "Agency Code",
            dataKey: "agency_code",
            minWidth: "180px",
            sortable: true
          },
          {
            label: "Status",
            dataKey: "status",
            minWidth: "50px",
            render: row => {
              const statusIcon = getIconDetails(get(row, "data.status", null))
              const isShared = get(row, "data.shared_with_count", 0) > 0
              return (
                <StatusWrapper appearance={row.isOpen ? "light" : "dark"}>
                  <Icon {...statusIcon} />
                  {isShared && <Icon icon="account-multiple" colour={colours.blue} />}
                </StatusWrapper>
              )
            },
            sortable: true
          },
          ...sharedWithColumns,
          {
            label: !isSharedWith ? "Activation Date" : "Shared Date",
            dataKey: !isSharedWith ? "activated_at" : "shared_at",
            minWidth: "80px",
            render: row => {
              const date = get(row, !isSharedWith ? "data.activated_at" : "data.shared_at")
              return date
                ? moment.utc(date, "YYYY-MM-DDTHH:mmZ").local().format("DD/MM/YYYY HH:mm")
                : "-"
            },
            sortable: true
          }
        ]}
        hasActions
        onClick={row => {
          onAgencyCodeSelect(row)
          setPanelStatus("open")
        }}
        onClose={() => {
          if (panelStatus !== "closed") {
            setPanelStatus("closed")
          }
          onAgencyCodeDeselect()
        }}
        isClosed={panelStatus === "closed"}
        pagination={pagination}
        changePage={e => setPage(e)}
        changePerPage={setPerPage}
        hasPerPage
        onSort={newSorting => onSort(newSorting)}
        sorting={sorting}
      />
      {revokeModal && (
        <CheckedItemModal
          title="Revoke shares"
          isLoading={revokeLoading}
          onClose={() => setRevoke(false)}
          label={{
            name: "Organisation",
            date: "Shared at",
            submit: "Revoke selected"
          }}
          data={sharedOrganisations?.map(({ id, name, shared_at }) => ({
            id,
            name,
            date: shared_at
          }))}
          onSubmit={checkedItem => {
            onRevokeShares(Object.keys(checkedItem).filter(key => !!checkedItem[key]))
          }}
          confirmationProps={{
            confirmIcon: "delete",
            renderContent: checkedItem => (
              <P>
                {`Are you sure you want to revoke ${
                  Object.values(checkedItem).filter(val => val === true).length > 1 &&
                  Object.values(checkedItem).every(isChecked => isChecked)
                    ? "all"
                    : "selected"
                } shares for this agency code? This action cannot be undone.`}
              </P>
            )
          }}
        />
      )}
    </>
  )
}

NetworkAgencyCodes.propTypes = {
  isSharedWith: PropTypes.bool
}

export default NetworkAgencyCodes
