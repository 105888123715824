import { useEffect, useContext } from "react"

import { JourneyContext } from "../../../../../../../journey.context"

const Audit = () => {
  const { updateJourneyAuditData } = useContext(JourneyContext)

  useEffect(() => {
    updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Audit
