import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { get, isEmpty, find } from "lodash"
import { Input, Select, QuerySelect, TextArea, Checkbox } from "@4cplatform/elements/Forms"
import { Button } from "@4cplatform/elements/Molecules"
import { H3 } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { nullFunc } from "@4cplatform/elements/Helpers"
import {
  renderProductTypeOptions,
  renderProviderOptions,
  renderProviderProductOptions
} from "../../Helpers"
import { createDealCodeModel } from "./dealCreate.helpers"

// Components
import { Wrapper, SectionWrapper, FieldRow, ButtonsWrapper } from "./dealCreate.styles"

const DealCodeCreate = ({ onSubmit, providers, isLoading, onCancel, initialValues }) => {
  const t = useTranslations()

  // Define fields for Create Deal Code
  const createDealCodeFormik = useFormik({
    initialValues: {
      product_type: "",
      provider_id: "",
      product: "",
      name: "",
      description: "",
      style_new: true,
      style_switch: true,
      underwriting_fmu: true,
      underwriting_mori: true,
      quoting: true,
      onboarding: true,
      details: {
        incentive_parameter: "",
        incentive_type: "",
        onboard_deal_code: ""
      },
      ...initialValues
    },
    validationSchema: createDealCodeModel(providers),
    onSubmit: body => onSubmit(body)
  })

  const { handleSubmit } = createDealCodeFormik
  const formik = { ...createDealCodeFormik, validationSchema: createDealCodeModel(providers) }
  const isAviva = useMemo(() => {
    const providerKey =
      find(providers, { id: parseInt(formik.values.provider_id ?? "") })?.provider_key ?? ""
    return providerKey.toLowerCase() === "aviva"
  }, [providers, formik.values.provider_id])

  useEffect(() => {
    if (isAviva && get(formik, "values.onboarding") === false) {
      formik.setFieldValue("details.onboard_deal_code", undefined)
    }

    if (
      get(formik, "values.onboarding") === true &&
      get(formik, "values.details.onboard_deal_code") === undefined
    ) {
      formik.setFieldValue("details.onboard_deal_code", "")
    }

    if (get(formik, "values.quoting") === false) {
      formik.setFieldValue("details.incentive_parameter", undefined)
      formik.setFieldValue("details.incentive_type", undefined)
    }

    if (
      get(formik, "values.quoting") === true &&
      get(formik, "values.details.incentive_parameter") === undefined
    ) {
      formik.setFieldValue("details.incentive_parameter", "")
      formik.setFieldValue("details.incentive_type", "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(formik, "values")])

  const renderDetails = () => {
    if (isAviva) {
      return (
        <>
          {get(formik, "values.quoting") && (
            <>
              <Input
                name="details.incentive_parameter"
                label="Incentive parameter"
                formik={formik}
              />
              <Input name="details.incentive_type" label="Incentive type" formik={formik} />
            </>
          )}
          {get(formik, "values.onboarding") && (
            <Input name="details.onboard_deal_code" label="Onboard deal code" formik={formik} />
          )}
        </>
      )
    }
    if (find(providers, { id: parseInt(get(formik, "values.provider_id", "")) })) {
      return <Input name="details.onboard_deal_code" label="Onboard deal code" formik={formik} />
    }
    return ""
  }

  return (
    <Wrapper>
      <SectionWrapper>
        <QuerySelect
          name="product_type"
          label="Product type"
          noun={{ singular: "product type", plural: "product types" }}
          endpoint="/product-types"
          render={renderProductTypeOptions}
          formik={formik}
        />
        <Input name="name" label="Name" formik={formik} />
        <TextArea name="description" label="Description" formik={formik} rows={5} />
        <Select
          name="provider_id"
          label="Provider"
          formik={formik}
          isDisabled={isEmpty(providers)}
          errorPrefix="none"
        >
          <option value="">Select provider</option>
          {renderProviderOptions(providers, true, false)}
        </Select>
      </SectionWrapper>

      {get(formik, "values.provider_id", "") && (
        <>
          <Select
            name="product"
            label="Product"
            formik={formik}
            isDisabled={!get(formik, "values.provider_id")}
          >
            <option value="">Select product</option>
            {renderProviderProductOptions(providers, formik, "provider_id", true, t)}
          </Select>

          {/* Service Type */}
          <H3 margin="2rem 0 2rem 0">Service Type(s)</H3>
          <FieldRow>
            <Checkbox label="Quoting" name="quoting" formik={formik} margin="0 2rem 0 0" />
            <Checkbox label="Onboarding" name="onboarding" formik={formik} margin="0" />
          </FieldRow>

          {/* The Details section */}
          {renderDetails()}

          {/* Journey Types */}
          <H3 margin="0 0 2rem">Journey type(s)</H3>
          <FieldRow>
            <Checkbox label="New" name="style_new" formik={formik} margin="0 2rem 0 0" />
            <Checkbox label="Switch" name="style_switch" formik={formik} margin="0" />
          </FieldRow>

          {/* Underwriting Types */}
          <H3 margin="0 0 2rem">Underwriting type(s)</H3>
          <FieldRow>
            <Checkbox
              label="Moratorium"
              name="underwriting_mori"
              formik={formik}
              margin="0 2rem 0 0"
            />
            <Checkbox label="FMU" name="underwriting_fmu" formik={formik} margin="0" />
          </FieldRow>
        </>
      )}

      <ButtonsWrapper>
        <Button appearance="error" trailingIcon="cancel" name="cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          appearance="success"
          trailingIcon="check"
          onClick={handleSubmit}
          margin="0 0 2rem"
          isLoading={isLoading}
          name="create_deal_code"
        >
          Add Deal Code
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

DealCodeCreate.defaultProps = {
  onSubmit: nullFunc,
  providers: [],
  isLoading: false,
  onCancel: nullFunc,
  initialValues: {}
}

DealCodeCreate.propTypes = {
  onSubmit: PropTypes.func,
  providers: PropTypes.array,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  initialValues: PropTypes.object
}

export default DealCodeCreate
