/* eslint-disable react/display-name */
import React, { memo, forwardRef, useContext } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { v4 as uuid } from "uuid"
import { Icon } from "@4cplatform/elements/Atoms"
import { P, SmallText } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { colours, formatLabelForTestID } from "@4cplatform/elements/Helpers"

// Components
import { QuotationSummaryContext } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/context/quotationSummary.context"
import QuoteProviderLogo from "./quotationSummaryTable.providerLogo"
import {
  TableHeaderColumnsWrapper,
  TableColumn,
  TableHeaderRows,
  TableRow,
  TableCell,
  QuoteReference,
  QuoteProviderWrapper,
  IndicatorWrapper,
  QuoteProviderInfo,
  IconWrapper
} from "./quotationSummaryTable.styles"
import { getUnderwritingHelperText } from "../JourneyPage/pages/PMI/Quote/QuoteComparison/quoteComparison.helpers"
import { getInfoMessage } from "../JourneyPage/pages/PMI/Quote/QuotationSummary/quotationSummary.helpers"

const QuotationSummaryTableHeader = forwardRef(({ isHeaderFixed }, ref) => {
  const { quotes, selectedQuote, setProviderInfoModal } = useContext(QuotationSummaryContext)
  const t = useTranslations()

  return (
    <TableHeaderColumnsWrapper ref={ref} isHeaderFixed={isHeaderFixed}>
      {quotes.map(quote => {
        const providerLogoSrc = get(quote, "provider.logo_file_path", "")
        const providerLogoAltTxt = get(quote, "provider.name", "")
        const isQuoteSelected = selectedQuote?.id === quote.id
        const isVitality = get(quote, "provider.provider_key", false) === "VITALITYHEALTH"
        const isSwitchSave = get(quote, "quote_type", null) === "switch_save"
        const isMoriPlus = get(quote, "flags.VITALITY_MORI_PLUS", false)

        const rowProps = {
          bgColour: isQuoteSelected ? "green" : "veryFaintGrey",
          noBorder: isQuoteSelected
        }

        const cellProps = {
          colour: isQuoteSelected ? "white" : null
        }

        return (
          <TableColumn key={uuid()} data-testid="quotation_summary_table_quote_header">
            <TableHeaderRows>
              <TableRow {...rowProps}>
                <TableCell {...cellProps}>
                  {isSwitchSave && (
                    <div
                      className="tag"
                      style={{
                        position: "absolute",
                        right: "5px",
                        top: "5px",
                        background: "#197DA4",
                        padding: "5px",
                        "border-radius": "5px",
                        color: "#fff"
                      }}
                    >
                      Switch & save up to 10%
                    </div>
                  )}
                  {isMoriPlus && (
                    <div
                      className="tag"
                      style={{
                        position: "absolute",
                        right: "5px",
                        top: "5px",
                        background: "#197DA4",
                        padding: "5px",
                        "border-radius": "5px",
                        color: "#fff"
                      }}
                    >
                      Mori+ Discount Applied
                    </div>
                  )}
                  <QuoteProviderWrapper>
                    <IconWrapper>
                      <Icon
                        icon="help-circle-outline"
                        colour={colours.blue}
                        onClick={() =>
                          setProviderInfoModal({
                            open: true,
                            data: quote
                          })
                        }
                        role="button"
                        transition="none"
                        margin="0"
                        data-testid={`${formatLabelForTestID(providerLogoAltTxt)}-helper_text-icon`}
                      />
                    </IconWrapper>
                    <QuoteProviderLogo src={providerLogoSrc} alt={providerLogoAltTxt} />
                    <QuoteProviderInfo>
                      <P margin="0" colour={isQuoteSelected ? "white" : ""}>
                        <strong> {t(get(quote, "provider.name", "-"))}</strong>
                      </P>
                      <SmallText margin="0" colour={isQuoteSelected ? "white" : ""}>
                        {t(get(quote, "product_name", "-"))}
                      </SmallText>
                    </QuoteProviderInfo>
                  </QuoteProviderWrapper>
                </TableCell>
              </TableRow>
              <TableRow {...rowProps}>
                <TableCell {...cellProps}>
                  <QuoteReference>{get(quote, "reference", "-")}</QuoteReference>
                </TableCell>
              </TableRow>
              <TableRow {...rowProps}>
                <TableCell {...cellProps} scrollableCol>
                  <IndicatorWrapper>
                    {isVitality && isSwitchSave && (
                      <P
                        className="quote-data"
                        helperPosition="right"
                        helperText={getInfoMessage("vitality_switch_save")?.content || ""}
                        helperTitle={getInfoMessage("vitality_switch_save")?.title || ""}
                      >
                        {t(get(quote, "underwriting_style"))} {t(get(quote, "underwriting_type"))}
                      </P>
                    )}
                    {isVitality && isMoriPlus && (
                      <P
                        className="quote-data"
                        helperPosition="right"
                        helperText={getInfoMessage("vitality_mori_plus")?.content || ""}
                        helperTitle={getInfoMessage("vitality_mori_plus")?.title || ""}
                      >
                        {t(get(quote, "underwriting_style"))} Moratorium+
                      </P>
                    )}
                    {!isSwitchSave && !isMoriPlus && (
                      <>
                        {getUnderwritingHelperText(get(quote, "flags", {})).map(helper => (
                          <P helperPosition="left" margin="0" {...helper} />
                        ))}
                        <P className="quote-data">
                          {t(get(quote, "underwriting_style"))} {t(get(quote, "underwriting_type"))}
                        </P>
                      </>
                    )}
                  </IndicatorWrapper>
                </TableCell>
              </TableRow>
            </TableHeaderRows>
          </TableColumn>
        )
      })}
    </TableHeaderColumnsWrapper>
  )
})

QuotationSummaryTableHeader.defaultProps = {
  isHeaderFixed: false
}

QuotationSummaryTableHeader.propTypes = {
  isHeaderFixed: PropTypes.bool
}

export default memo(QuotationSummaryTableHeader)
